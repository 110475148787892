import styled from 'styled-components';

interface GridItemProps {
  // grid size
  gridSize: number;
  gridGap: number;
  gridOffset?: number;
}

export const GridItem = styled.div<GridItemProps>`
  ${(props) => {
    const cols: number = 100 / props.gridSize;
    const gap: number = (props.gridGap * (props.gridSize - 1)) / props.gridSize;
    const offset: number = props.gridOffset ? props.gridOffset : 0;

    return `
      flex: 0 0 calc(${cols}% - ${gap}px);
      max-width: calc(${cols}% - ${gap}px);
      width: 100%;
      margin-right: ${props.gridGap}px;
      margin-bottom: ${props.gridGap}px;

      &:nth-child(${props.gridSize + offset}n) {
        margin-right: 0;
      }`;
  }};
`;
