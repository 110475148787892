import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImageEnlargeIcon } from 'assets/icons/image-enlarge.svg';

import { lynch } from 'shared/config/Colors';
import { PopupPosition } from 'shared/interface/PopupPosition';
import UtilService from 'shared/services/util.service';

import { Modal } from './Modal';
import { ModalWrapper } from './ModalWrapper';
import { P } from './Typography';
import { Tooltip } from './Tooltip';
import { Wrapper } from './Wrapper';
import Img from './Img';
import SVG from './SVG';

interface ImgModalProps {
  alt?: string;
  height: number;
  hover?: boolean;
  nested?: boolean;
  src: string;
  width: number;
  label?: string;
  position?: PopupPosition | PopupPosition[];
}

const Thumbnail = styled(Img)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
  padding: 4px;
  transition: 300ms ease;

  ~ .icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    pointer-events: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.poloBlue};

    ~ .icon {
      color: ${({ theme }) => theme.lynch};
    }

    ~ ${Tooltip} {
      opacity: 1;
    }
  }
`;

const Image = styled(Img)`
  margin: 0 auto;
`;

const ModalStyles = styled(Modal)``;

const TooltipStyles = styled.div`
  padding: 0 24px;

  ${Image} {
    max-width: 100%;
    height: auto;
    margin: 20px auto 20px auto;
  }

  ${P} {
    margin-bottom: 35px;
  }
`;

const ImgModal: FC<ImgModalProps> = ({
  src,
  nested,
  alt,
  width,
  height,
  hover,
  label,
  position,
}) => {
  const multiplyer = 3.5;

  return (
    <ModalWrapper
      modal={!hover || undefined}
      position={position}
      on={hover ? ['hover'] : undefined}
      nested={nested}
      mouseEnterDelay={hover ? 300 : undefined}
      className={hover ? 'radio-button-modal' : ''}
      trigger={
        <Wrapper position="relative" maxWidth={width}>
          <Thumbnail
            src={src}
            width={width}
            height={height}
            contain
            alt={alt}
          />

          {!hover && (
            <>
              <SVG
                icon={<ImageEnlargeIcon />}
                animateTransform={['color']}
                color={UtilService.colorWithOpacity(lynch, 0.2)}
              />

              <Tooltip maxWidth={110} minWidth={110} position="bottom">
                Enlarge Image
              </Tooltip>
            </>
          )}
        </Wrapper>
      }
    >
      {(close: () => void) =>
        !hover ? (
          <ModalStyles close={close} noHeader withoutFooter={1}>
            <Image
              src={src}
              width={width * multiplyer}
              height={height * multiplyer}
              contain
              alt={alt}
            />
          </ModalStyles>
        ) : (
          <TooltipStyles>
            <Image src={src} contain alt={alt} />
            {label && (
              <P fontSize={14} bold>
                {label}
              </P>
            )}
          </TooltipStyles>
        )
      }
    </ModalWrapper>
  );
};

ImgModal.defaultProps = {
  hover: false,
};

export default ImgModal;
