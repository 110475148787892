import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

const SelectCSS = css`
  .select {
    &__control {
      min-height: 25px;
      width: 130px;
      cursor: pointer;
      border: none;
      font-size: 14px;
      font-weight: 600;
      justify-content: flex-start;
      background-color: currentColor;

      &--is-focused {
        box-shadow: none;
      }
    }

    &__value-container {
      padding: 8px 2px 8px 8px;
      justify-content: flex-end;
    }

    &__single-value,
    &__placeholder {
      color: ${({ theme }) => theme.nevada};
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      path {
        fill: ${({ theme }) => theme.lynch};
      }
    }

    &__menu {
      margin-top: 0px;
      border-radius: 8px;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      color: ${({ theme }) => theme.nevada};

      &-list {
        padding: 0;
      }
    }

    &__option {
      transition: 300ms;
      cursor: pointer;
      padding: 8px;
      border: 1px solid ${({ theme }) => theme.pigeonPost};
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: 1px solid ${({ theme }) => theme.pigeonPost};
      }

      &:active,
      &--is-focused,
      &--is-selected {
        background: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.nevada};
      }

      &:hover {
        background: ${({ theme }) => theme.catskillWhite};
        color: ${({ theme }) => theme.lynch};
      }
    }
  }
`;

interface SelectBorderlessProps {
  bgColor?: string;
}

export const SelectBorderless = styled(ReactSelect)<SelectBorderlessProps>`
  ${({ bgColor }) => bgColor && `color: ${bgColor};`}
  ${SelectCSS}
`;

SelectBorderless.defaultProps = {
  classNamePrefix: 'select',
};
