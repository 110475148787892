import { call, put, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import { Warnings } from 'order/warnings/interface/Wranings';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { setWarnings, WarningsActions } from './warningsActions';

function* getWarnings(action: Action<string>) {
  try {
    const response: Warnings = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/warnings`
    );

    yield put(setWarnings(response));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* warningsSagas() {
  yield takeEvery(WarningsActions.GET_WARNINGS, getWarnings);
}
