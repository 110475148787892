import { useState } from 'react';
import styled from 'styled-components';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

const LoaderContainer = styled.div`
  height: 530px;
  padding: 215px 0;
`;

const ImageContainer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.mischka}`};
  margin-bottom: 32px;
  text-align: center;
  padding-bottom: 58px;
  height: 400px;
`;

const ModalActions = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`;

export const CropImageModal = ({
  image,
  onSave,
  isLoading,
  onClose,
}: {
  image?: string;
  onSave: (croppedImageData: File) => void;
  isLoading: boolean;
  onClose: () => void;
}) => {
  // eslint-disable-next-line
  const [cropper, setCropper] = useState<any>(null);

  const urlToFile = async (url: string, fileName: string, mimeType: string) => {
    const res = await fetch(url);
    const buff = await res.arrayBuffer();
    return new File([buff], fileName, { type: mimeType });
  };

  const saveTheCroppedImage = () => {
    if (cropper != null) {
      const croppedImageData = cropper.getCroppedCanvas().toDataURL();
      const mimeType = UtilService.getMimeTypeFromImageURL(croppedImageData);
      urlToFile(
        croppedImageData,
        `profilePhoto${UtilService.getFileExtensionForGivenMimeType(mimeType)}`,
        mimeType
      ).then((file) => onSave(file));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoaderContainer>
          <Loader size={50} />
        </LoaderContainer>
      ) : (
        <ImageContainer>
          <Cropper
            src={image}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            guides
            background={false}
            viewMode={1}
            modal={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </ImageContainer>
      )}

      <ModalActions flex middle>
        <ButtonSecondary type="button" onClick={onClose}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary onClick={saveTheCroppedImage}>Save</ButtonPrimary>
      </ModalActions>
    </>
  );
};

CropImageModal.defaultProps = {
  image: '',
};
