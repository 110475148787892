import { FC } from 'react';

import { H2 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';

import { ACKTypeSheetContainer } from '../ACKTypeSheetContainer';
import { ACKTypeSheetProps } from '../../interface/ACKTypeSheetProps';
import ApprovedACKIcon from '../ApprovedACKIcon';
import { ACKTypeEnum } from '../../enums/ACKTypeEnum';

const QuoteSheet: FC<ACKTypeSheetProps> = ({ approved, version }) => {
  return (
    <ACKTypeSheetContainer data-testid={`ack-sheet--${ACKTypeEnum.Quote}}`}>
      <Wrapper inlineFlex>
        <H2 fontSize={24}>
          Quote Acknowledgment{(version ?? 0) > 1 ? ` v${version}` : ''}
        </H2>

        {approved && (
          <ApprovedACKIcon
            data-testid={`ack-sheet-approved--${ACKTypeEnum.Quote}`}
          />
        )}
      </Wrapper>
    </ACKTypeSheetContainer>
  );
};

export default QuoteSheet;
