export enum AdjustmentsTestInputEnum {
  ADJUSTMENTS_NET_DISCOUNT = 'adjustments-net-discount',
  ADJUSTMENTS_PROMO_CODE = 'adjustments-promo-code',
}

export enum AdjustmentsTestCheckboxEnum {
  ADJUSTMENTS_REPLACEMENT_CHECKBOX = 'adjustments-replacement-checkbox',
  ADJUSTMENTS_NO_CHARGE_CHECKBOX = 'adjustments-no-charge-checkbox',
  ADJUSTMENTS_NO_FREIGHT_CHECKBOX = 'adjustments-no-freight-checkbox',
  ADJUSTMENTS_APPLY_TO_ALL_EXISTING_STYLES_CHECKBOX = 'adjustments-apply-to-all-existing-styles-checkbox',
}

export enum AdjustmentsTestButtonTitleEnum {
  FINISH = 'Finish',
  SAVE_CHANGES = 'Save Changes',
}

export enum AdjustmentsTestButtonEnum {
  SUBMIT = 'submit-btn',
  BACK = 'back',
}
