import { AnyAction } from 'redux';
import { User } from 'shared/interface/User';

import { manageUsersActions } from './manageUsersActions';

interface CurrentUserReducer {
  user: User | null;
}

const initialState: CurrentUserReducer = {
  user: null,
};

export const currentUserReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case manageUsersActions.SET_USER:
      return {
        ...state,
        user: action.payload as User,
      };
    default:
      return state;
  }
};
