import { Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import { History, Location } from 'history';
import { OrderCollaborator } from 'order/interfaces/OrderCollaborator';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import HTMLReactParser from 'html-react-parser';

import {
  DeepMap,
  FormState,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from 'react-hook-form';

import { Collaborator } from 'order/interfaces/Collaborator';
import { Job } from 'order/interfaces/Job';
import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { MAPLE_VENEER_CABINET_BOX } from 'order/wizard/orderStyles/enums/MapleVeneerCabinetBox';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { ScheduleType } from 'order/enums/scheduleType';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { StyleFieldOption } from 'order/wizard/orderStyles/interface/StyleFieldOption';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';
import { allSalesAidsOptions } from 'order/wizard/orderForm/components/OrderTypeOptionSets';

import {
  QuantityAdderTypeWithHardwareId,
  QuantityAdderTypeWithHingeTypeId,
  StyleDoorBuilder,
  StyleDoorBuilderRequest,
  StyleDoorBuilderRequestMapper,
  StyleDoorBuilderUpchargeMapper,
} from 'order/wizard/orderStyles/interface/StyleDoorBuilder';

import {
  IDoorCode,
  IDoorCodeField,
} from 'order/wizard/orderStyles/interface/DoorCodes';

import {
  AddressType,
  CatalogOrderType,
  OrderStylizationTypeEnums,
  OrderTypeEnums,
  DefaultAddressesLabels,
} from 'order/enums/orderEnums';

import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { DealerTabsEnum } from 'shared/enum/DealerTabsEnum';
import { ErrorMessagesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { IAttachment } from 'shared/interface/IAttachment';
import { IFileData } from 'shared/interface/IFile';
import { OrderInStatusOptions } from 'shared/hooks/useIsOrderInStatus';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { Sides } from 'shared/interface/CSSTypes';
import { UndefinedOptionEnum } from 'shared/enum/undefinedOptionEnum';
import { User } from 'shared/interface/User';
import { UserPermissions } from 'shared/interface/UserPermissions';
import { UserRole } from 'shared/interface/UserRole';
import { fileTypes } from 'shared/enum/fileTypes';
import { CsrQuotesTabsEnum } from 'shared/enum/CsrQuotesTabsEnum';

import {
  globalDateFormat,
  globalMaxFileSize,
  globalTimeFormat,
  globalTimeWithSecondsFormat,
} from 'shared/config/Variables';

import { contentType } from 'mime-types';
import { CsrOrderTableRequest } from '../interface/OrderRow';

import {
  OrderFilterValues,
  OrderFilterValuesRipped,
} from '../../order/interfaces/OrderFilterValues';

interface FormatDateOptions {
  date?: string | null;
  dateFormat?: string;
  withTime?: boolean;
  asElement?: boolean;
  isUtc?: boolean;
}

interface HandleErrorOptions {
  err: ServerErrorResponse;
  errType?: keyof Pick<ServerErrorResponse, 'status' | 'type'>;
  callback?: () => void;
  fallbackErrorToastMessage?: string;
}

interface CalcTooltipPositionOptions {
  itemPositionIndex: number;
  numberOfColumns: number;
}

interface LineItemSizes {
  width: number;
  height: number;
  depth: number;
}

interface LineItemNameWithDimensions extends LineItemSizes {
  code: string;
  acknowledgementName: string;
  squareFootTotal?: null | number;
  lineItemDetails?: {
    code: string;
    description: string;
    id: string;
    imageUrl: string;
  };
}

interface StyleOnStepOptions {
  styleStep?: number;
  step: StylesStepsEnum;
  greaterThen?: boolean;
}

interface GetShipWeekLabelOptions {
  scheduleType?: ScheduleType;
  shipWeekDate?: string | null;
  shipWeekDescription?: string;
  defaultValue?: string;
}

interface ShouldShowEnds {
  productLineName?: string;
  cabinetBoxMaterialName?: string;
}

class UtilService {
  static getLocalStorageItem(name: string) {
    return localStorage.getItem(name);
  }

  static setLocalStorageItem(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  static removeLocalStorageItem(name: string) {
    localStorage.removeItem(name);
  }

  static getRoleForGivenUser(user: User) {
    return (user.roles?.[0] as UserRole)?.description ?? null;
  }

  static hasPermission(
    userPermissions: UserPermissions[],
    permissionToCheck: string
  ) {
    return userPermissions.some(
      (permission) => permission.name === permissionToCheck
    );
  }

  static getInitials(firstName: string, lastName: string) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }

  static sortBy(
    a: string | number,
    b: string | number,
    asc: boolean = true
  ): number {
    const first = typeof a === 'string' ? a.toLowerCase() : a;
    const second = typeof b === 'string' ? b.toLowerCase() : b;

    if (first < second) {
      return asc ? -1 : 1;
    }

    if (first > second) {
      return asc ? 1 : -1;
    }

    return 0;
  }

  static getMimeTypeFromImageURL(base64: string) {
    const result = base64.match(/image\/\w+/)![0];
    return result;
  }

  static getFileExtensionForGivenMimeType(mimeType: string) {
    switch (mimeType) {
      case 'image/png':
        return fileTypes.PNG;
      case 'image/jpg':
        return fileTypes.JPG;
      case 'image/jpeg':
        return fileTypes.JPEG;
      default:
        return fileTypes.JPG;
    }
  }

  static trimStartEndSpaces<T>(data: T) {
    const d = data;

    Object.entries(d as unknown as ArrayLike<T>).forEach(([key, value]) => {
      if (typeof value === 'string') {
        d[key as keyof T] = value.trim() as unknown as T[keyof T];
      }
    });

    return d;
  }

  static getErrorMessage(responseStatus: number) {
    switch (responseStatus) {
      case 403:
        return 'Access denied.';
      case 404:
        return "Couldn' find the resource.";
      default:
        return "Something wen't wrong.";
    }
  }

  static createOption(label: string) {
    return {
      label,
      value: label.toLowerCase().replace(/\W/g, ''),
    };
  }

  static mapJobsToSelectOptions(
    data: Job[] | Job
  ): SelectOptionProps[] | SelectOptionProps {
    return Array.isArray(data)
      ? (data.map((job) => ({
          value: job.id,
          label: job.name,
          hasCreated: true,
        })) as SelectOptionProps[])
      : ({
          value: data.id,
          label: data.name,
          hasCreated: true,
        } as SelectOptionProps);
  }

  static removeExtensionFromFileName(fileName: string) {
    const lastOccurrence = fileName.lastIndexOf('.');
    return fileName.substring(0, lastOccurrence);
  }

  static mapAddresstoSelectOption(
    data: ShippingAddress[] | ShippingAddress,
    isCustom?: boolean
  ): SelectOptionProps[] | SelectOptionProps {
    return Array.isArray(data)
      ? data.map((item) => ({
          value: item.id,
          label: isCustom
            ? item.shipTo
            : UtilService.mapAddressTypeToLabel(item.type),
        }))
      : {
          value: data.id,
          label: UtilService.mapAddressTypeToLabel(data.type),
        };
  }

  static mapAddressTypeToLabel(type: AddressType): string {
    switch (type) {
      case AddressType.Mailing:
        return DefaultAddressesLabels.Mailing;
      case AddressType.ShowRoom:
        return DefaultAddressesLabels.ShowRoom;
      case AddressType.TruckShipping:
        return DefaultAddressesLabels.TruckShipping;
      case AddressType.UpsOrFedEx:
        return DefaultAddressesLabels.UpsOrFedEx;
      case AddressType.CustomShipping:
        return DefaultAddressesLabels.CustomShipping;
      default:
        return '';
    }
  }

  static scrollToBottom<T extends { scrollTop: number; scrollHeight: number }>(
    element: T
  ) {
    /* eslint-disable no-param-reassign */
    element.scrollTop = element.scrollHeight;
    /* eslint-enable no-param-reassign */
  }

  static withDecimal<T>(
    field: Path<T> | null,
    value: string,
    setValue?: UseFormSetValue<T> | Dispatch<SetStateAction<T>>
  ): void | string {
    const isNumber = /^-?\d+(\.\d+)?$/.test(value);
    const hasDecimals = /\.\d+?$/.test(value);

    if (!isNumber && setValue) {
      (setValue as UseFormSetValue<T>)(
        field as Path<T>,
        value.toString() as UnpackNestedValue<PathValue<T, Path<T>>>
      );

      return value;
    }

    if ((!isNumber && !setValue) || hasDecimals) {
      return value;
    }

    const decimalValue = parseFloat(value).toFixed(2);

    // just return formated value if there is no setter
    if (!setValue) {
      return decimalValue;
    }

    // call setter for react-hook-form
    if (field) {
      return (setValue as UseFormSetValue<T>)(
        field as Path<T>,
        decimalValue.toString() as UnpackNestedValue<PathValue<T, Path<T>>>
      );
    }

    // call setter for useStat e
    return (setValue as Dispatch<SetStateAction<T>>)(
      decimalValue.toString() as unknown as SetStateAction<T>
    );
  }

  static hexToRgb(color: string) {
    return color
      .match(/\w{1,2}/g)!
      .map((c) => parseInt(c, 16))
      .join(', ');
  }

  static mapObjectToSelectOptions<T>(
    data: T[] | T,
    value: keyof T = 'id' as keyof T,
    label: keyof T = 'name' as keyof T,
    options?: {
      customParams?: Array<keyof T>;
      toCapitalize?: boolean;
    }
  ): SelectOptionProps[] | SelectOptionProps | null {
    if (!data) {
      return null;
    }
    return Array.isArray(data)
      ? (data.map((option) => ({
          value: option[value] as unknown as string,
          label: options?.toCapitalize
            ? this.toCapitalize(option[label] as unknown as string)
            : (option[label] as unknown as string).toString(),
          ...(options?.customParams &&
            options.customParams.reduce((acc, curr) => {
              acc[curr] = option[curr] as unknown as string;
              return acc;
            }, {} as Record<keyof T, string>)),
        })) as SelectOptionProps[])
      : ({
          value: data[value] as unknown as string,
          label: options?.toCapitalize
            ? this.toCapitalize(data[label] as unknown as string)
            : data[label],
          ...(options?.customParams &&
            options.customParams.reduce((acc, curr) => {
              acc[curr] = data[curr] as unknown as string;
              return acc;
            }, {} as Record<keyof T, string>)),
        } as SelectOptionProps);
  }

  static parseQueryString = (query: string, queryParam: string) => {
    return query
      .replace('?', '')
      .split('&')
      .reduce<Record<string, string | null>>((acc, curr) => {
        const [key, value] = curr.split('=');

        acc[key] = value ?? null;
        return acc;
      }, {})[queryParam];
  };

  static bytesToMB(size: number) {
    return (size / 1000000).toFixed(2);
  }

  static mbToBytes(mb: number) {
    return mb * 1048576;
  }

  static toCapitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }

  static displayNotAvailableIfEmpty(title: string | undefined) {
    return title || 'N/A';
  }

  static findOption(
    options: SelectOptionProps[] | null,
    optionId: string
  ): SelectOptionProps | null {
    return options?.find((option) => option.value === optionId) ?? null;
  }

  static mapStringValueToSelectOptionObject(value: string) {
    return {
      label: this.toCapitalize(value),
      value,
    };
  }

  static mapSelectOptionToObject(option: SelectOptionProps | null) {
    return option
      ? ({
          id: option.value,
          name: option.label,
          ...(option.imageUrl && { imageUrl: option.imageUrl }),
          ...(option.isDefault && { isDefault: option.isDefault }),
          ...(option.upcharge && { upcharge: option.upcharge }),
          ...(option.code && { code: option.code }),
          ...(option.isComplete && { isComplete: option.isComplete }),
          ...(option.hasCreated && { hasCreated: option.hasCreated }),
        } as StyleFieldOption)
      : null;
  }

  static mapDoorBuilderUpcharges = ({
    key,
    upchargeField,
    doorCodes,
  }: StyleDoorBuilderUpchargeMapper) => {
    const mappedFields: IDoorCodeField[] = [];

    if (doorCodes) {
      const [doorCodesKey] = key.split(/[DU]/g);

      const fieldDoorCodes = doorCodes[
        doorCodesKey as keyof IDoorCode
      ] as IDoorCodeField[];

      if (upchargeField) {
        Object.keys(upchargeField).forEach((key2) => {
          const foundDoorCodeField = fieldDoorCodes.find(
            (fdc) => fdc.code.trim() === key2
          );

          const doorCodeIndex = fieldDoorCodes.findIndex(
            (fdc) => fdc.code.trim() === key2
          );

          const data = upchargeField[key2];

          if (foundDoorCodeField) {
            mappedFields[doorCodeIndex] = {
              ...foundDoorCodeField,
              reason: data.reason,
              upcharge: +data.upcharge,
              isAutomatic: data.isAutomatic ?? false,
            };
          }
        });
      }
    }

    return mappedFields;
  };

  static mapDoorBuilderFormToStyleDoorBuilderRequest({
    data,
    orderId,
    styleId,
    styleStep,
    doorCodes,
    productLine,
    overrideReason,
    isOverriden,
  }: StyleDoorBuilderRequestMapper): StyleDoorBuilderRequest {
    return {
      orderId,
      styleId: styleId!,
      styleStep: (styleStep?.toString() as StylesStepsEnum) ?? null,
      doorOverlayId: data.doorOverlay.id,
      wallDoorStyleId: data.doorStyleWall.value,
      baseDoorStyleId: data.doorStyleBase.value,
      wallDoorConfigurationId: data.configurationWall.value,
      baseDoorConfigurationId: data.configurationBase.value,
      wallDoorInsertPanelId: data.insertPanelWall.value,
      baseDoorInsertPanelId: data.insertPanelBase.value,
      wallDoorEdgeId: data.doorEdgeWall.value,
      baseDoorEdgeId: data.doorEdgeBase.value,
      faceFrameId: data.faceFrame.id,
      frameStyleId: data.frameStyle.value,
      frameSizeId:
        !data.customFrameSize && data.frameSize ? data.frameSize.value : null,
      hingeColorId: data.hingeColor.value,
      metalFinish: data.metalFinish
        ? {
            metalFinishId: data.metalFinish.value,
            upcharge: +data.metalFinishUpcharge,
          }
        : null,
      archStyleId: data.archStyle ? data.archStyle.value : null,
      drawerBoxId: data.drawerBox.value,
      drawerStyleId: data.drawerStyle.value,
      drawerInsertPanelId: data.drawerInsertPanel.value,
      drawerEdgeId: data.drawerEdge.value,
      topInsertPanelId: data.topDrawerInsertPanel
        ? data.topDrawerInsertPanel?.value
        : null,
      noMidrails: data.midrails,
      hdfPanels: data.hdfPanels,
      doorNotes: data.doorNotes,
      closetHardwareColorId: data.closetHardwareColor
        ? data.closetHardwareColor.value
        : null,
      doorHardware: {
        hardwareId: data.doorHardware.value,
        quantity: +data.doorHardwareQty,
        adder: +data.doorHardwareAdd,
      } as QuantityAdderTypeWithHardwareId,
      drawerHardware: {
        hardwareId: data.drawerHardware.value,
        quantity: +data.drawerHardwareQty,
        adder: +data.drawerHardwareAdd,
      } as QuantityAdderTypeWithHardwareId,
      hardwareNotes: data.hardwareNotes,
      hingeType: {
        hingeTypeId: data.hingeType.value,
        quantity: +data.hingeTypeQty,
        adder: +data.hingeTypeAdd,
      } as QuantityAdderTypeWithHingeTypeId,
      wallDoorUpcharges: this.mapDoorBuilderUpcharges({
        key: 'wallDoorUpcharges',
        upchargeField: data.wallDoorUpcharges,
        doorCodes,
      }),
      baseDoorUpcharges: this.mapDoorBuilderUpcharges({
        key: 'baseDoorUpcharges',
        upchargeField: data.baseDoorUpcharges,
        doorCodes,
      }),
      drawerUpcharges: this.mapDoorBuilderUpcharges({
        key: 'drawerUpcharges',
        upchargeField: data.drawerUpcharges,
        doorCodes,
      }),
      productLine,
      toeSpaceTypeId: data.toeSpaceType ? +data.toeSpaceType.value : null,
      oneInchDoor: data.oneInchDoor,
      oneInchFaceFrame: data.oneInchFaceFrame,
      oneInchThickDoorUpcharge: +data.oneInchThickDoorUpcharge || 0,
      note: data.styleNote,
      isOverriden,
      overrideReason,
      customFrameSize: data.customFrameSize,
    };
  }

  static mapDoorBuilderDataToStyleDoorBuilder = (data: StyleDoorBuilder) => {
    return {
      archStyle: UtilService.mapSelectOptionToObject(data.archStyle),
      baseDoorConfiguration: UtilService.mapSelectOptionToObject(
        data.configurationBase
      ),
      baseDoorEdge: UtilService.mapSelectOptionToObject(data.doorEdgeBase),
      baseDoorInsertPanel: UtilService.mapSelectOptionToObject(
        data.insertPanelBase
      ),
      baseDoorStyle: UtilService.mapSelectOptionToObject(data.doorStyleBase),
      doorNotes: data.doorNotes,
      doorOverlay: data.doorOverlay,
      drawerBox: UtilService.mapSelectOptionToObject(data.drawerBox),
      drawerEdge: UtilService.mapSelectOptionToObject(data.drawerEdge),
      drawerInsertPanel: UtilService.mapSelectOptionToObject(
        data.drawerInsertPanel
      ),
      drawerFrontStyle: UtilService.mapSelectOptionToObject(data.drawerStyle),
      faceFrame: data.faceFrame,
      frameSize: {
        id: data.frameSize?.value,
        size: +data.frameSize?.label,
      },
      frameStyle: UtilService.mapSelectOptionToObject(data.frameStyle),
      metalFinish: UtilService.mapSelectOptionToObject(data.metalFinish),
      noMidrails: data.midrails,
      topInsertPanel: UtilService.mapSelectOptionToObject(
        data.topDrawerInsertPanel
      ),
      wallDoorConfiguration: UtilService.mapSelectOptionToObject(
        data.configurationWall
      ),
      wallDoorEdge: UtilService.mapSelectOptionToObject(data.doorEdgeWall),
      wallDoorInsertPanel: UtilService.mapSelectOptionToObject(
        data.insertPanelWall
      ),
      wallDoorStyle: UtilService.mapSelectOptionToObject(data.doorStyleWall),

      // hardware
      hingeColor: UtilService.mapSelectOptionToObject(data.hingeColor),
      closetHardwareColor: UtilService.mapSelectOptionToObject(
        data.closetHardwareColor
      ),
      hardwareNotes: data.hardwareNotes,
      hingeType: {
        adder: +UtilService.withDecimal(null, data.hingeTypeAdd),
        id: data.hingeType.value,
        name: data.hingeType.label,
        quantity: +data.hingeTypeQty,
      },
      doorHardware: {
        adder: +UtilService.withDecimal(null, data.doorHardwareAdd),
        id: data.doorHardware.value,
        name: data.doorHardware.label,
        quantity: +data.doorHardwareQty,
      },
      drawerHardware: {
        adder: +UtilService.withDecimal(null, data.drawerHardwareAdd),
        id: data.drawerHardware.value,
        name: data.drawerHardware.label,
        quantity: +data.drawerHardwareQty,
      },
      metalFinishUpcharge: data.metalFinishUpcharge,
      interiorNotes: data.interiorNotes,
      toeSpaceType: UtilService.mapSelectOptionToObject(data.toeSpaceType),
      doorEdgeBand: UtilService.mapSelectOptionToObject(data.doorEdgeBand),
      oneInchDoor: data.oneInchDoor,
      materialDrawer: UtilService.mapSelectOptionToObject(data.materialDrawer),
      hdfPanels: data.hdfPanels,
      oneInchThickDoorUpcharge: +UtilService.withDecimal(
        null,
        data.oneInchThickDoorUpcharge?.toString() ?? '0'
      ),
      customFrameSize: data.customFrameSize,
    } as Style;
  };

  static onPopupOpen() {
    const { body } = document;
    if (!body.classList.contains('noScroll')) {
      body.classList.add('noScroll');
    }
  }

  static onPopupClose() {
    const { body } = document;
    setTimeout(() => {
      if (document.querySelectorAll('.popup-overlay').length === 0) {
        body.classList.remove('noScroll');
        body.style.overflow = 'auto';
      }
    }, 1);
  }

  static omit = <T>(obj: T, items: (keyof T)[]) => {
    const updatedObj = { ...obj } as T;

    items.forEach((key) => {
      delete updatedObj[key];
    });

    return updatedObj;
  };

  static pick<T>(obj: T, items: (keyof T)[]) {
    return items.reduce((acc, record) => {
      if (obj[record] !== undefined) {
        acc[record] = obj[record];
      }

      return acc;
    }, {} as T);
  }

  static mapFilesToIAttachment(files: File[]) {
    return files.map(
      (file) =>
        ({
          note: '',
          section: null,
          file,
          id: uuidv4(),
        } as IAttachment)
    );
  }

  static validateFiles(files: FileList) {
    const newFiles: File[] = [];

    Array.from(files).forEach((file) => {
      if (file.size > UtilService.mbToBytes(globalMaxFileSize)) {
        toast.error(
          `${file.name} cannot be selected because it's larger then ${globalMaxFileSize}MB.`
        );
      } else {
        newFiles.push(file);
      }
    });

    return newFiles;
  }

  static mapUsersToSelectOptions = (users: User[] | null) => {
    if (!users) return null;
    return users.map(
      (user) =>
        ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        } as SelectOptionProps)
    );
  };

  static mapProductLineShortName = (productLine: string) => {
    switch (productLine) {
      case ProductLineEnums.PRODUCT_LINE_CUSTOM:
        return 'Cust';
      case ProductLineEnums.PRODUCT_LINE_PRELUDE:
        return 'Prel';
      case ProductLineEnums.PRODUCT_LINE_INOVAE:
        return 'Inov';
      case ProductLineEnums.PRODUCT_LINE_REVOLAE:
        return 'Rev';
      case ProductLineEnums.PRODUCT_LINE_ICS:
        return 'Cur';
      case ProductLineEnums.PRODUCT_LINE_INOVAE2O:
        return '2.O';
      default:
        return '';
    }
  };

  static colorWithOpacity(color: string, opacity: number) {
    const alpha = parseInt((opacity * 255).toString(16), 16).toString(16);
    return `${color}${alpha.toUpperCase()}`;
  }

  static isOrderInStatus = (options: OrderInStatusOptions) => {
    return options.greaterThen
      ? (options.orderStatus?.id ?? 0) > options.status
      : (options.orderStatus?.id ?? 0) < options.status;
  };

  static displayLineItemSizes({ width, height, depth }: LineItemSizes) {
    if (width || height || depth) {
      return `${width} ${height} ${depth}`;
    }
    return '';
  }

  static formatDate({
    date,
    dateFormat = globalDateFormat,
    withTime = true,
    asElement = true,
    isUtc = false,
  }: FormatDateOptions) {
    if (!date) return undefined;

    const dateFormatWithTime = `${dateFormat}${
      withTime ? ` ${globalTimeFormat}` : ''
    }`;

    const dateFormatWithTimeAndSeconds = `${dateFormat}${
      withTime ? ` ${globalTimeWithSecondsFormat}` : ''
    }`;

    let utcDate = date;
    if (!date.endsWith('Z') && !isUtc) {
      utcDate = `${date}Z`;
    }

    return asElement
      ? HTMLReactParser(
          `<span title="${format(
            new Date(utcDate),
            dateFormatWithTimeAndSeconds
          )}">${format(new Date(utcDate), dateFormatWithTime)}</span>`
        )
      : format(new Date(utcDate), dateFormatWithTime);
  }

  static getSpecialDimensions = (dimup: string) => {
    const dimUpCharges = [];

    if (dimup.includes('W')) {
      dimUpCharges.push('Width');
    }

    if (dimup.includes('H')) {
      dimUpCharges.push('Height');
    }

    if (dimup.includes('D')) {
      dimUpCharges.push('Depth');
    }

    return dimUpCharges.join(', ');
  };

  static reorder<T>(list: T[], startIndex: number, endIndex: number) {
    const l = [...list];

    const [removed] = l.splice(startIndex, 1);
    l.splice(endIndex, 0, removed);

    // Change list items numbers with new positions
    return l.map((listItem, index) => {
      return { ...listItem, number: index + 1 };
    });
  }

  static orderCollaboratorsMapper(
    collaborators: Collaborator[]
  ): OrderCollaborator[] {
    return collaborators.map((collaborator) => ({
      userId: collaborator.id,
      collaboratorAccess: collaborator.collaboratorType,
    }));
  }

  static catchErrorHandler = (
    e: unknown,
    onFailed?: (data?: unknown) => void
  ) => {
    if (onFailed) {
      onFailed(e as ServerErrorResponse);
    }
  };

  static handleError = ({
    err,
    errType = 'type',
    callback,
    fallbackErrorToastMessage,
  }: HandleErrorOptions) => {
    const message = ErrorMessagesEnum[+err[errType]];

    const hasDotAtTheEnd = /\w+\.{1,1}$/g.test(err.title);

    toast.error(
      message ??
        fallbackErrorToastMessage ??
        `${err.title}${hasDotAtTheEnd ? '' : '.'}`
    );

    if (callback) {
      callback();
    }
  };

  static shouldShowTooltip = <
    // eslint-disable-next-line
    T extends any[] | null | undefined
  >(
    options: T,
    // eslint-disable-next-line
    selectedOption: any
  ) => {
    if (!Array.isArray(options) || !selectedOption) return false;

    // eslint-disable-next-line
    const copiedOptions: any[] = [...(options ?? [])];

    const hasSelectedOptionInside = selectedOption
      ? Object.prototype.hasOwnProperty.call(selectedOption, 'selectedOption')
      : false;

    const selectedOptionIsNotNull = !!(hasSelectedOptionInside
      ? selectedOption.selectedOption
      : selectedOption);

    return (
      !!copiedOptions?.length &&
      selectedOptionIsNotNull &&
      !copiedOptions.some(
        (option) =>
          !option.isDisabled &&
          (option?.value ?? option?.id)?.toString() ===
            (
              (selectedOption?.selectedOption ?? selectedOption).value ??
              selectedOption?.id
            )?.toString()
      )
    );
  };

  static styleNavigationActionsLables(
    direction: 'Back' | 'Next' | 'Convert' | 'Finish',
    shouldSave: boolean
  ) {
    return `${shouldSave ? 'Save and ' : ''}${direction}`;
  }

  static getRestOfTheFinishEffects(
    finishEffects: StyleFieldOption[],
    orderNumber: number
  ) {
    return finishEffects.reduce((agg, curr) => {
      if (curr.orderNumber! < orderNumber) {
        agg.push(curr.id);
      }
      return agg;
    }, [] as string[]);
  }

  static calcTooltipPosition({
    itemPositionIndex,
    numberOfColumns,
  }: CalcTooltipPositionOptions): Sides {
    if (itemPositionIndex % numberOfColumns === 0) {
      return 'right';
    }

    if ((itemPositionIndex + 1) % numberOfColumns === 0) {
      return 'left';
    }

    if (itemPositionIndex > 0 && itemPositionIndex < 4) {
      return 'bottom';
    }

    return 'top';
  }

  static removeQueryParams<T>(
    history: History,
    location: Location,
    param: keyof T
  ) {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(param as string);

    history.replace(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      location.state
    );
  }

  static isSalesAids(orderType?: OrderTypeEnums) {
    return allSalesAidsOptions.includes(orderType!);
  }

  static getCatalogOrderType(
    orderType?: OrderTypeEnums,
    orderStylizationType?: OrderStylizationTypeEnums
  ) {
    const salesAidsSelected = this.isSalesAids(orderType);

    if (salesAidsSelected && orderStylizationType) {
      if (orderStylizationType === OrderStylizationTypeEnums.DOOR_SAMPLE_BASE) {
        return CatalogOrderType.DOOR_SAMPLE_BASE.toString();
      }

      if (orderStylizationType === OrderStylizationTypeEnums.SALES_MATERIAL) {
        return CatalogOrderType.SALES_MATERIAL.toString();
      }
    }

    return CatalogOrderType.NON_SALES_AIDS.toString();
  }

  static getUsersConcatenatedNameOrEmail(user: User) {
    const firstLastName = [
      this.toCapitalize(user.firstName),
      this.toCapitalize(user.lastName),
    ]
      .filter((x) => x)
      .join(' ');

    return firstLastName || user.email;
  }

  static dirtyOrTouched<T>(formState: FormState<T>, field: keyof T) {
    return formState.dirtyFields[field] || formState.touchedFields[field];
  }

  static dirtyOrTouchedFields<T>(
    formState: FormState<T>,
    fields: (keyof DeepMap<T, true>)[]
  ) {
    return fields.some(
      (dirtyKey) =>
        formState.dirtyFields[dirtyKey] || formState.touchedFields[dirtyKey]
    );
  }

  static generateLineItemNameWithDimensions = (
    lineItem: LineItemNameWithDimensions
  ) => {
    const labelStrings = lineItem.acknowledgementName
      ? [lineItem.acknowledgementName]
      : [
          lineItem.lineItemDetails?.code ?? lineItem.code,
          UtilService.displayLineItemSizes(lineItem),
        ];

    return labelStrings.filter((x) => x).join(' ');
  };

  static generateWoodMaterialOption(woodMaterialId: string) {
    return {
      label: 'Custom Wood Material Option',
      value: woodMaterialId,
    } as SelectOptionProps;
  }

  static trimStringToNumberOfChars(
    string: string | undefined | null,
    numberOfChars: number
  ) {
    if (typeof string !== 'string' || string.length <= numberOfChars)
      return string;

    const reg = new RegExp(`^.{${numberOfChars}}`, 'gs');
    return `${string.match(reg)}...`;
  }

  static isNotSubmittedTab = (
    isUserCSR: boolean | undefined,
    search: OrderFilterValues | OrderFilterValuesRipped
  ) => {
    return isUserCSR
      ? search.tab === CsrTabsEnum['Not Submitted']
      : search.tab === DealerTabsEnum['Not Submitted'];
  };

  static canSeeTargetDateFilter = (
    isUserCSR: boolean | undefined,
    search: OrderFilterValues | OrderFilterValuesRipped
  ) => {
    return (
      isUserCSR &&
      search.tab !== CsrTabsEnum['Not Submitted'] &&
      search.tab !== CsrTabsEnum['All Orders'] &&
      search.tab !== CsrTabsEnum['Orders to Approve'] &&
      search.tab !== CsrTabsEnum['Approved Orders'] &&
      search.tab !== CsrQuotesTabsEnum['All Quotes']
    );
  };

  static prepareQueryParamsForLineItemModal(
    lineItem: LineItem,
    replace: boolean = false
  ) {
    const params = new URLSearchParams();

    if (lineItem.style) {
      params.append('styleId', lineItem.style.styleId);
    }

    params.append('productLineId', lineItem.productLineId);
    params.append('lineItemId', lineItem.lineItemId);

    if (replace) {
      params.append('replacementLineItemId', lineItem.lineItemId);
      params.append('selectedLineItemDetailsId', lineItem.lineItemDetails?.id);
    } else {
      params.append('catalogLineItemId', lineItem.catalogLineItemId);
    }

    params.sort();

    return params;
  }

  static getQueryParams = (payload: CsrOrderTableRequest) => {
    const {
      searchTerm,
      type,
      status,
      productLineId,
      shipWeekStartDate,
      shipWeekEndDate,
      page,
      itemsPerPage,
      sortColumn,
      sortDirection,
      csrTab,
      assigneeId,
      dealershipId,
      targetDateEndDate,
      targetDateFilterType,
      targetDateStartDate,
      redTag,
      collaboratingOn,
      shipWeekFilterType,
      excludeOrdersWithoutStyle,
      showCancelledOrders,
      showNewCollaboration,
    } = payload;

    const queryParams = new URLSearchParams();
    queryParams.append('searchTerm', searchTerm);
    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('sortColumn', sortColumn);
    queryParams.append('sortDirection', sortDirection);
    if (csrTab) {
      queryParams.append('csrTab', csrTab.toString());
    }
    queryParams.append('collaboratingOn', collaboratingOn.toString());

    if (excludeOrdersWithoutStyle) {
      queryParams.append(
        'excludeOrdersWithoutStyle',
        excludeOrdersWithoutStyle.toString()
      );
    }

    if (status) {
      queryParams.append('status', status.toString());
    }

    if (type) {
      queryParams.append('type', type.toString());
    }

    if (productLineId) {
      queryParams.append('productLineId', productLineId.toString());
    }

    if (shipWeekStartDate) {
      queryParams.append('shipWeekStartDate', shipWeekStartDate.toDateString());
    }

    if (shipWeekEndDate) {
      queryParams.append('shipWeekEndDate', shipWeekEndDate.toDateString());
    }

    if (assigneeId) {
      queryParams.append('assigneeId', assigneeId);
    }

    if (dealershipId) {
      queryParams.append('dealershipId', dealershipId);
    }

    if (targetDateEndDate) {
      queryParams.append('targetDateEndDate', targetDateEndDate.toDateString());
    }

    if (targetDateStartDate) {
      queryParams.append(
        'targetDateStartDate',
        targetDateStartDate.toDateString()
      );
    }

    if (targetDateFilterType) {
      queryParams.append(
        'targetDateFilterType',
        targetDateFilterType.toString()
      );
    }

    if (redTag) {
      queryParams.append('redTag', redTag.toString());
    }

    if (shipWeekFilterType) {
      queryParams.append('shipWeekFilterType', shipWeekFilterType.toString());
    }

    queryParams.append('showCancelledOrders', showCancelledOrders.toString());
    queryParams.append(
      'showNewCollaboration',
      (showNewCollaboration ?? false).toString()
    );

    return queryParams;
  };

  static styleOnStep({ styleStep, step, greaterThen }: StyleOnStepOptions) {
    if (greaterThen === true) {
      return (styleStep ?? 0) > +step;
    }

    if (greaterThen === false) {
      return (styleStep ?? 0) < +step;
    }

    return (styleStep ?? 0) === +step;
  }

  static getShipWeekLabel = ({
    defaultValue = '',
    scheduleType,
    shipWeekDate,
    shipWeekDescription,
  }: GetShipWeekLabelOptions) => {
    switch (scheduleType) {
      case ScheduleType.None:
        return defaultValue;
      case ScheduleType.Regular:
        return shipWeekDate ? shipWeekDescription : defaultValue;
      case ScheduleType.Asap:
        return 'ASAP';

      case ScheduleType['Out Of Schedule']:
        return '000';
      default:
        return defaultValue;
    }
  };

  static shouldShowEnds = ({
    cabinetBoxMaterialName,
    productLineName,
  }: ShouldShowEnds) => {
    const isInovae = productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE;

    const isInovae20OrRevolae =
      productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE2O ||
      productLineName === ProductLineEnums.PRODUCT_LINE_REVOLAE;

    const mapleVeneerCabinetBox =
      cabinetBoxMaterialName === MAPLE_VENEER_CABINET_BOX;

    return {
      enableExposedEnds:
        (isInovae && !mapleVeneerCabinetBox) || isInovae20OrRevolae,
    };
  };

  static roundToTwoDecimals(num: number, returnAsString: boolean = false) {
    const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100;

    if (returnAsString) {
      return roundedNum.toString();
    }

    return roundedNum;
  }

  static setUndefinedAsFirstOptionInList = (label: string) => {
    return label === UndefinedOptionEnum.UNDEFINED ? -1 : 0;
  };

  static convertFileToBlob(
    fileData: IFileData,
    loading?: (isLoading: boolean) => void
  ) {
    const byteCharacters = atob(fileData.content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileData.contentType });

    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileData.name;

    // open window with selected file instead downloading it directly from page
    // window.open(link.href, '_blank');

    loading?.(false);

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click'));

    // Remove link from body
    document.body.removeChild(link);
  }

  static async openBlob(url: string, extension: string) {
    const buffer = await fetch(url).then((r) => r.arrayBuffer());
    const file = new Blob([buffer], {
      type: contentType(extension).toString(),
    });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';

    a.click();
  }
}

export default UtilService;
