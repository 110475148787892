import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useIsUserLoggedIn } from 'shared/hooks/useIsUserLoggedIn';

export const NonAuthRoute = (props: RouteProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();

  if (isUserLoggedIn) {
    return <Redirect to="/overview/dashboard" />;
  }

  return <Route {...props} />;
};
