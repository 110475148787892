import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import styled, { createGlobalStyle, css } from 'styled-components';

import CalendarIcon from 'assets/icons/date.svg';

import { lynch, nevada, white } from 'shared/config/Colors';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';

interface DatePickerProps {
  iconposition?: 'left' | 'right';
}

const DatePickerIconCSS = (iconposition: 'left' | 'right' = 'right') => css`
  &::before {
    content: '';
    ${`${iconposition}: 12px`};
    background-image: url(${CalendarIcon});
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    z-index: 1;
  }
`;

const paddings = {
  left: '12px 16px 12px 48px',
  right: '12px 48px 12px 16px',
};

const DatePickerCSS = css<DatePickerProps>`
  width: 100%;

  ${({ iconposition }) => DatePickerIconCSS(iconposition)}

  // icon
  .MuiInputAdornment {
    &-root {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .MuiFormHelperText {
    &-root {
      &.Mui-error {
        color: ${({ theme }) => theme.valencia};
      }
    }
  }

  .MuiInput {
    &-input {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.alto};
      padding: ${({ iconposition }) => paddings[iconposition ?? 'right']};
      transition: 300ms ease;
      color: ${({ theme }) => theme.nevada};
      background-color: ${({ theme }) => theme.white};

      &:not([aria-invalid='true']):focus {
        background-color: ${({ theme }) => theme.catskillWhite};
        border-color: ${({ theme }) => theme.pigeonPost};
      }

      &[aria-invalid='true'] {
        border-color: ${({ theme }) => theme.valencia};
        background-color: ${({ theme }) => theme.fairPink};
        color: ${({ theme }) => theme.valencia};

        &::placeholder {
          color: ${({ theme }) => theme.silverSand};
        }
      }

      &:disabled {
        background-color: ${({ theme }) => theme.wildSand};
        color: ${({ theme }) => theme.silverSandTwo};
      }
    }

    &-formControl {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiIconButton-root {
    color: ${({ theme }) => theme.mineShaft};

    &:hover {
      background: none;
    }
  }
`;

export const FormDatePicker = styled(DatePicker)<DatePickerProps>`
  ${DatePickerCSS}
`;

export const FormDateAndTimePicker = styled(DateTimePicker)<DatePickerProps>`
  ${DatePickerCSS}
`;

export const DatePickerModalStyles = createGlobalStyle`

    .MuiPaper-root {
      &.MuiPaper-root {
        color: ${nevada};
      }
    }

    .MuiPickersClockNumber-clockNumber {
      &.MuiPickersClockNumber-clockNumber:not(.MuiPickersClockNumber-clockNumberSelected) {
        color: ${nevada};
      }
    }

    .MuiTabs-indicator {
      display: none;
    }


    .MuiPickersYear-root {
      &.MuiPickersYear-yearSelected {
        color: ${lynch}
      }
    }

    .MuiPickerDTTabs-tabs {
      &.MuiPickerDTTabs-tabs {
        background-color: ${lynch};
      }
    }


    .MuiPickersModal-dialogRoot {
      .MuiTypography-body2 {
        font-family: "Open sans", sans-serif;
      }
    }

    .MuiPaper-rounded {
      &.MuiPaper-rounded {
        border-radius: 16px;
        ${BoxShadowCSS}
      }
    }

    .MuiPickersToolbar-toolbar {
      &.MuiPickersToolbar-toolbar {
        background-color: ${lynch};
      }
    }

    .MuiPickersBasePicker-pickerView {
      .MuiPickersDay-day {
        color: ${lynch};
      }

      .MuiPickersDay-current {
        .MuiTypography-root {
          font-weight: bold;
        }
      }

      .MuiPickersDay-dayDisabled:not(.MuiPickersDay-hidden) {
        opacity: 0.5;  
      }
  
      .MuiPickersDay-daySelected {
        background-color: ${lynch};
        color: ${white};

        &:hover {
          background-color: ${lynch};

        }
      } 

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .MuiPickersModal-withAdditionalAction {
      .MuiButton-root {
        font-family: "Open sans", sans-serif;

      }

      .MuiButton-textPrimary {
        color: ${lynch};

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
`;
