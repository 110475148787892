import { FC } from 'react';

import { ButtonSecondary } from 'shared/components/Button';
import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';

interface SubmitErrorModalProps {
  opened: boolean;
  errorType: ErrorCodesEnum | null;
  onClose: (close: () => void) => void;
}

const SubmitErrorModal: FC<SubmitErrorModalProps> = ({
  opened,
  onClose,
  errorType,
}) => {
  const getOrderSubmitError = () => {
    const isOrderSalesMaterial = useOrderProductType(
      OrderStylizationTypeEnums.SALES_MATERIAL
    );

    switch (errorType) {
      case ErrorCodesEnum.OrderDoesNotHaveAnyStyles:
      case ErrorCodesEnum.OrderDoesNotHaveAnyLineItems:
      case ErrorCodesEnum.OrderHasIncompleteStyle:
      case ErrorCodesEnum.OrderHasStyleWithoutLineItems:
        return isOrderSalesMaterial
          ? 'This order has no line items. Please make sure your order contains all the necessary items.'
          : 'This order has some incomplete styles or styles with no line items inside them. Please make sure your order contains all the necessary items.';
      case ErrorCodesEnum.OrderIsInvalid:
        return 'The order is in an invalid state. Make sure the order has valid styles and contains valid order items or modifications.';
      case ErrorCodesEnum.OrderHasInvalidLineItemsOrModifications:
        return 'This order contains some incompatible line items or modifications.';
      default:
        return '';
    }
  };

  return (
    <ModalWrapper
      open={opened}
      onClose={UtilService.onPopupClose}
      closeOnDocumentClick={false}
    >
      {(close: () => void) => (
        <Modal
          title={
            errorType === ErrorCodesEnum.OrderIsInvalid
              ? 'Invalid Order'
              : 'Incomplete Order'
          }
          small
          withoutFooter={1}
          confirm
          close={() => onClose(close)}
        >
          <P color={nevada}>{getOrderSubmitError()}</P>

          <Spacer h="50px" />

          <Wrapper flex justifyEnd>
            <ButtonSecondary onClick={() => onClose(close)}>
              Dismiss
            </ButtonSecondary>
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};

export default SubmitErrorModal;
