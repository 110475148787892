import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

const SelectCSS = css`
  .select {
    width: 100%;

    &__control {
      min-height: 44px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.mischka};
      transition: 300ms ease;

      &:hover:not(.select__control--is-focused) {
        border: 1px solid ${({ theme }) => theme.mischka};
        box-shadow: none;
      }

      &--is-disabled {
        opacity: 1;
        background-color: ${({ theme }) => theme.wildSand};
        border-color: ${({ theme }) => theme.mischka};
        pointer-events: none;
      }

      &--is-focused,
      &--is-focused:hover {
        background-color: ${({ theme }) => theme.catskillWhite};
        border-color: ${({ theme }) => theme.pigeonPost};
        box-shadow: none;
      }
    }

    &__clear-indicator {
      svg {
        border-radius: 50%;
        background: ${({ theme }) => theme.lynch};
        path {
          fill: ${({ theme }) => theme.white};
        }
      }
    }

    &__value-container {
      padding: 6px 15px;
    }

    &__single-value {
      color: ${({ theme }) => theme.nevada};
      &--is-disabled {
        color: ${({ theme }) => theme.silverSandTwo};
      }
    }

    &__placeholder {
      color: ${({ theme }) => theme.silverSand};
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      path {
        fill: ${({ theme }) => theme.lynch};
      }
    }

    &__menu {
      margin-top: 6px;
      border-radius: 6px;
      cursor: pointer;
      color: ${({ theme }) => theme.nevada};

      &-list {
        padding: 0;
        overflow-x: hidden;
      }
    }

    &__option {
      transition: 300ms;
      cursor: pointer;
      padding: 10px 15px;
      border: 1px solid ${({ theme }) => theme.pigeonPost};
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom: 1px solid ${({ theme }) => theme.pigeonPost};
      }

      &:active:not(.select__option--is-disabled),
      &--is-focused:not(.select__option--is-disabled),
      &--is-selected:not(.select__option--is-disabled) {
        background: ${({ theme }) => theme.catskillWhite};
        color: ${({ theme }) => theme.lynch};
      }

      &--is-disabled {
        background-color: transparent;
      }
    }
  }

  &.invalid {
    .select {
      &__control {
        border-color: ${({ theme }) => theme.valencia};
        background-color: ${({ theme }) => theme.fairPink};
      }

      &__placeholder {
        color: ${({ theme }) => theme.valencia};
      }
    }
  }
`;

export const Select = styled(ReactSelect).attrs((props) => ({
  className: props['aria-invalid'] === 'true' ? 'invalid' : null,
}))`
  ${SelectCSS};
`;

export const CreatableStyledSelect = styled(AsyncCreatableSelect)`
  ${SelectCSS}
`;

Select.defaultProps = {
  classNamePrefix: 'select',
};

CreatableStyledSelect.defaultProps = {
  classNamePrefix: 'select',
};
