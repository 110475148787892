import { FC } from 'react';

import { H2, P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { ACKTypeSheetContainer } from '../ACKTypeSheetContainer';
import { ACKTypeSheetProps } from '../../interface/ACKTypeSheetProps';
import ApprovedACKIcon from '../ApprovedACKIcon';

const SpecialOrderApprovalSheet: FC<ACKTypeSheetProps> = ({ approved }) => {
  return (
    <ACKTypeSheetContainer data-testid="ack-sheet--special-order-approval">
      <Wrapper inlineFlex>
        <H2 fontSize={24}>Special Order Approval</H2>
        {approved && (
          <ApprovedACKIcon data-testid="ack-sheet-approved--special-order-approval" />
        )}
      </Wrapper>

      <Spacer h="24px" />

      <P>
        You have specified doors or drawers which must be ordered from an
        outside source. Your approval must be received within 2 working days.
        Upon receipt of your approval, Plato will place an order for the
        outsourced item(s).
      </P>

      <P>
        Any delay in returning this signed form will delay ordering the
        outsourced item(s) which may result in an extended delivery. Once the
        order for the approved outsourced item(s) has been placed by Plato, any
        change orders requiring a change/deletion of the outsourced item(s) will
        be the responsibility of the dealer.
      </P>

      <Spacer h="20px" />
    </ACKTypeSheetContainer>
  );
};

export default SpecialOrderApprovalSheet;
