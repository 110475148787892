import { RootState } from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { setSpecsOptionsByOptionsKey } from 'order/wizard/orderStyles/productLines/store/specifications/orderStylesSpecificationActions';
import { StyleFieldOption } from 'order/wizard/orderStyles/interface/StyleFieldOption';

import {
  ConfigOptions,
  HingeColorOptions,
  setDoorBuilderOptionsByOptionsKey,
} from 'order/wizard/orderStyles/productLines/store/doorBuilder/orderStylesDoorBuilderActions';

import {
  DoorBuilderOptions,
  SpecificationsOptions,
} from 'order/wizard/orderStyles/productLines/store/orderStylesReducer';

import UtilService from 'shared/services/util.service';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { UndefinedOptionEnum } from 'shared/enum/undefinedOptionEnum';

import { OrderFlowsEnum, useIsOrderFlow } from './useIsOrderFlow';
import { useAppDispatch } from './useAppDispatch';

export const useUndefinedOptionAsFirst = () => {
  const dispatch = useAppDispatch();

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const { specificationsOptions, doorBuilderOptions } = useSelector(
    (state: RootState) => state.orderStylesReducer
  );

  useEffect(() => {
    // we are creating array of keys based on the options list in the reducer
    // - SPECIFICATIONS OPTIONS
    const specificationOptionsKeys = Object.keys(
      specificationsOptions
    ) as Array<keyof SpecificationsOptions>;

    // we are creating array of keys based on the options list in the reducer
    // - DOOR BUILDER ARRAY OPTIONS
    const doorBuilderArrayOptionsKeys = Object.keys(
      UtilService.omit(doorBuilderOptions, [
        'configurationOptions',
        'doorCodes',
        'hardwareQuantities',
        'hingeColorOptions',
        'midrailsOptions',
      ])
    ) as Array<keyof DoorBuilderOptions>;

    // we are creating array of keys based on the options list in the reducer
    // - DOOR BUILDER OBJECT OPTIONS - for now we only have two listed below
    const doorBuilderObjectOptions = Object.keys(
      UtilService.pick(doorBuilderOptions, [
        'configurationOptions',
        'hingeColorOptions',
      ])
    ) as Array<keyof DoorBuilderOptions>;

    // we are gonna run thorugh every options on specifications tab
    specificationOptionsKeys.forEach((key) => {
      const options = specificationsOptions[key];

      // attaching useEffect that will listen options changes

      if (
        // both conditions needs to be true:
        // if options pulled from reducer are not null or undefined
        // if options contains Undefined option
        options &&
        (isQuoteFlow
          ? options[0]?.label !== UndefinedOptionEnum.UNDEFINED
          : true) &&
        options.some((option) => option.label === UndefinedOptionEnum.UNDEFINED)
      ) {
        // dispatching action to update the options in reducer
        dispatch(
          setSpecsOptionsByOptionsKey({
            // sorting options to set Undefined option as first option in the list
            options: options
              .sort((option) =>
                UtilService.setUndefinedAsFirstOptionInList(option.label)
              )
              // we are removing "Undefined" option for any type that is not quote.
              .filter((option) =>
                isQuoteFlow
                  ? true
                  : option.label !== UndefinedOptionEnum.UNDEFINED
              ),
            optionsKey: key,
          })
        );
      }
    });

    // same logic as above
    doorBuilderArrayOptionsKeys.forEach((key) => {
      const options = doorBuilderOptions[key] as SelectOptionProps<
        unknown,
        unknown
      >[] &
        StyleFieldOption[];

      // attaching useEffect that will listen options changes

      if (
        options &&
        (isQuoteFlow
          ? options[0]?.label !== UndefinedOptionEnum.UNDEFINED
          : true) &&
        options.some((option) => option.label === UndefinedOptionEnum.UNDEFINED)
      ) {
        dispatch(
          setDoorBuilderOptionsByOptionsKey({
            options: options
              .sort((option) =>
                UtilService.setUndefinedAsFirstOptionInList(option.label)
              )
              // we are removing "Undefined" option for any type that is not quote.
              .filter((option) =>
                isQuoteFlow
                  ? true
                  : option.label !== UndefinedOptionEnum.UNDEFINED
              ),
            optionsKey: key,
          })
        );
      }
    });

    // we are creating array of keys based on the options list in the reducer
    // - DOOR BUILDER OBJECT OPTIONS
    doorBuilderObjectOptions.forEach((key) => {
      const options = doorBuilderOptions[key] as
        | ConfigOptions
        | HingeColorOptions;

      // once we get the options for each provided key
      // we iterate thought them to get the options for each key
      Object.keys(options ?? {}).forEach((key2) => {
        const objectOptions = options[key2];

        if (
          objectOptions &&
          objectOptions.some(
            (option) => option.label === UndefinedOptionEnum.UNDEFINED
          )
        ) {
          // we are sorting options to set Undefined option as first in the list for each options for given key

          const sortedObjectOptions = objectOptions
            .sort((option) =>
              UtilService.setUndefinedAsFirstOptionInList(option.label)
            )
            // we are removing "Undefined" option for any type that is not quote.
            .filter((option) =>
              isQuoteFlow
                ? true
                : option.label !== UndefinedOptionEnum.UNDEFINED
            );

          // overriding sorted options
          options[key2] = sortedObjectOptions;
        }
      });

      // attaching useEffect that will listen options changes
      // all three conditions needs to be true (we need to go one level deeper):
      // if options pulled from reducer are not null or undefined
      // if options for given key are not null or undefined
      // if options for given key contains Undefined option
      if (
        options &&
        options[key] &&
        options[key].some(
          (option) => option.label === UndefinedOptionEnum.UNDEFINED
        )
      ) {
        dispatch(
          setDoorBuilderOptionsByOptionsKey({
            options,
            optionsKey: key,
          })
        );
      }
    });
  }, [specificationsOptions, doorBuilderOptions]);
};
