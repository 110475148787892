import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { AnyAction } from 'redux';
import { CheckAsapItemProps, OrderAsapActions } from './OrderAsapActions';
import { IAttachment } from '../../../../shared/interface/IAttachment';

interface OrderAsapReducer {
  asapStep: number;
  styles: Style[] | null;
  lineItems: LineItem[] | null;
  checkedAttachments: IAttachment[] | null;
  checkedStyles: string[];
  checkedLineItems: string[];
  checkedModifications: string[];
}

const initialState: OrderAsapReducer = {
  asapStep: 0,
  styles: null,
  lineItems: null,
  checkedAttachments: null,
  checkedStyles: [] as string[],
  checkedLineItems: [] as string[],
  checkedModifications: [] as string[],
};

export const orderAsapReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OrderAsapActions.CLEAR_ASAP_REDUCER:
      return { ...initialState };
    case OrderAsapActions.SET_ASAP_STEP:
      return { ...state, asapStep: action.payload as number };
    case OrderAsapActions.SET_ASAP_ITEMS:
      return {
        ...state,
        styles: action.payload.styles as Style[],
        lineItems: action.payload.lineItems as LineItem[],
      };
    case OrderAsapActions.SET_CHECKED_ATTACHMENTS:
      return {
        ...state,
        checkedAttachments: action.payload as IAttachment[],
      };
    case OrderAsapActions.ADD_CHECKED_ATTACHMENT: {
      const newAttachment = action.payload as IAttachment;
      const currentlyCheckedAttachments = state.checkedAttachments;
      return {
        ...state,
        checkedAttachments: currentlyCheckedAttachments
          ? [...currentlyCheckedAttachments, newAttachment]
          : [newAttachment],
      };
    }
    case OrderAsapActions.REMOVE_CHECKED_ATTACHMENT:
      return {
        ...state,
        checkedAttachments: state.checkedAttachments
          ? state.checkedAttachments?.filter(
              (attachment) => attachment.id !== action.payload
            )
          : [],
      };
    case OrderAsapActions.CHECK_ASAP_ITEM: {
      const { checked, itemId, itemType } =
        action.payload as CheckAsapItemProps;

      const itemsKey = `checked${itemType}` as keyof OrderAsapReducer;

      const items = state[itemsKey] as string[];

      return {
        ...state,
        [`checked${itemType}`]: checked
          ? Array.from(new Set([...items, itemId]))
          : items.filter((item) => item !== itemId),
      };
    }
    default:
      return state;
  }
};
