import { call, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import UtilService from 'shared/services/util.service';

import { attachmentsModalActions } from './attachmentsModalActions';

function* exampleAttachments(action: Action<unknown>) {
  try {
    yield console.log('bla');

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* attachmentsModalSagas() {
  yield takeEvery(
    attachmentsModalActions.CLEAR_ATTACHMENTS_MODAL,
    exampleAttachments
  );
}
