import { RootState } from 'store';

import {
  all,
  call,
  CallEffect,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { StyleFieldOption } from 'order/wizard/orderStyles/interface/StyleFieldOption';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

import { Style } from '../../../interface/Style';
import { StylesStepsEnum } from '../../../enums/StylesStepsEnum';

import {
  AllFinishEffectsByFinishEffectsRequest,
  FinishColorsRequest,
  FinishEffectsByFinishColorRequest,
  FinishEffectsByFinishEffectRequest,
  GetMaterialColorsRequest,
  GetMaterialGroupsRequest,
  GetSpecsOptionsByWoodOrMaterialForICSRequest,
  GetSpecsOptionsByWoodOrMaterialRequest,
  orderStylesSpecificationActions,
  setCabinetBoxMaterials,
  setFinishColors,
  setFinishEffectOptions,
  setFinishImpressionData,
  setFinishImpressionOptions,
  setGrainDirections,
  setMaterialColors,
  setMaterialGroups,
  setSpecNotes,
  setSpecsFinishImpressionOption,
  setSpecsOptions,
  setSpecsOptionsForICS,
  setStylesSpecifications,
  setWoodEffectOptions2,
  setWoodOrMaterialOptions,
  updateSpecOption,
  ValidateInovae2OStyleSpecificationsRequest,
  ValidateStyleSpecificationsRequest,
  WoodEffectsByWoodEffectRequest,
} from './orderStylesSpecificationActions';

import {
  Inovae2OStyleSpecificationsRequest,
  StyleSpecificationsRequest,
  ValidateStyleSpecificationsResponse,
} from '../../../interface/StyleSpecifications';

import {
  FinishImpressionRequest,
  ImpressionData,
} from '../../../interface/FinishImpression';

import { SpecificationsOptionsEnum } from '../../../enums/SpecificationsOptionsEnum';

function* getWoodOrMaterial(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', action.payload!);

    const woodOrMaterialResponse: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/woodmaterials`,
      {
        params: queryParams,
      }
    );

    const woodOrMaterialOptions = UtilService.mapObjectToSelectOptions(
      woodOrMaterialResponse,
      'id',
      'name',
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    yield put(setWoodOrMaterialOptions(woodOrMaterialOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, woodOrMaterialOptions);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFinishEffectsByColor(
  action: Action<FinishEffectsByFinishColorRequest>
) {
  try {
    const queryParams = new URLSearchParams();

    const {
      finishColorId,
      woodMaterialId,
      productLineId,
      specialFinishSample,
      showOnlyAvailable,
    } = action.payload!;

    queryParams.append('finishColorId', finishColorId);
    queryParams.append('woodMaterialId', woodMaterialId);
    queryParams.append('productLineId', productLineId);

    if (showOnlyAvailable) {
      queryParams.append('showOnlyAvailable', showOnlyAvailable.toString());
    }

    if (specialFinishSample) {
      queryParams.append('specialFinishSample', specialFinishSample);
    }

    const finishEffects: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/finisheffects`,
      { params: queryParams }
    );

    const finishEffectOptions = UtilService.mapObjectToSelectOptions(
      finishEffects,
      'id',
      'name',
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    yield put(
      setFinishEffectOptions({
        finishEffectOptions,
        optionNumber: '1',
      })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getWoodEffectsByWoodEffect(
  action: Action<WoodEffectsByWoodEffectRequest>
) {
  try {
    const { productLineId, woodMaterialId, woodEffectId, isOverriden } =
      action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);
    queryParams.append('woodMaterialId', woodMaterialId);

    if (isOverriden) {
      queryParams.append('isOverriden', isOverriden.toString());
    }

    const woodEffects: StyleFieldOption[] = yield call(
      ApiService.get,
      `api/catalog/woodeffects/${woodEffectId}/dependents`,
      { params: queryParams }
    );

    const woodEffectOptions2 = UtilService.mapObjectToSelectOptions(
      woodEffects,
      'id',
      'name',
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    yield put(setWoodEffectOptions2(woodEffectOptions2));

    if (action.onSuccess) {
      yield call(action.onSuccess, woodEffectOptions2);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFinishEffectsByFinishEffect(
  action: Action<FinishEffectsByFinishEffectRequest>
) {
  try {
    const {
      finishColorId,
      woodMaterialId,
      productLineId,
      specialFinishSample,
      finishEffectIds,
      optionNumber,
      showOnlyAvailable,
    } = action.payload!;

    if (+optionNumber >= 4) {
      return;
    }

    const queryParams = new URLSearchParams();
    queryParams.append('finishColorId', finishColorId);
    queryParams.append('woodMaterialId', woodMaterialId);
    queryParams.append('productLineId', productLineId);

    if (specialFinishSample && showOnlyAvailable) {
      queryParams.append('specialFinishSample', specialFinishSample);
    }

    if (showOnlyAvailable) {
      queryParams.append('showOnlyAvailable', showOnlyAvailable.toString());
    }

    finishEffectIds
      .filter((_, index) => index + 1 < +optionNumber)
      .forEach((el) => {
        queryParams.append('finishEffectIds', el);
      });

    const finishEffects: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/finisheffects/dependents`,
      { params: queryParams }
    );

    const finishEffectOptions = UtilService.mapObjectToSelectOptions(
      finishEffects,
      'id',
      'name',
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    // reset or set finish effect depended options
    for (let i = +optionNumber; i <= 3; i += 1) {
      const index = i.toString();

      yield put(
        setFinishEffectOptions({
          finishEffectOptions:
            index === optionNumber && finishEffectOptions.length > 0
              ? finishEffectOptions
              : null,
          optionNumber: index,
        })
      );
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getAllFinishEffectsByFinishEffects(
  action: Action<AllFinishEffectsByFinishEffectsRequest>
) {
  try {
    const {
      finishColorId,
      woodMaterialId,
      productLineId,
      specialFinishSample,
      finishEffects,
    } = action.payload!;

    const storedStyle: Style = yield select(
      (state: RootState) => state.orderStylesReducer.style
    );

    const dependingFinishEffects = finishEffects.filter(
      (finishEffect) => finishEffect.orderNumber! > 1
    );

    const requests: CallEffect[] = yield dependingFinishEffects.map(
      (finishEffect) => {
        const queryParams = new URLSearchParams();
        queryParams.append('finishColorId', finishColorId);
        queryParams.append('woodMaterialId', woodMaterialId);
        queryParams.append('productLineId', productLineId);

        if (specialFinishSample && finishEffect.showAvailableOnly) {
          queryParams.append('specialFinishSample', specialFinishSample);
        }

        const finishEffectIds = UtilService.getRestOfTheFinishEffects(
          storedStyle.finishEffects,
          finishEffect.orderNumber!
        );

        finishEffectIds.forEach((el) => {
          queryParams.append('finishEffectIds', el);
        });

        if (finishEffect.showAvailableOnly) {
          queryParams.append(
            'showOnlyAvailable',
            finishEffect.showAvailableOnly.toString()
          );
        }

        return call(ApiService.get, `/api/catalog/finisheffects/dependents`, {
          params: queryParams,
        });
      }
    );

    const responses: StyleFieldOption[][] = yield all(requests);

    const { 0: finishEffectOptions2, 1: finishEffectOptions3 } = responses;

    if (finishEffectOptions2) {
      yield put(
        setFinishEffectOptions({
          optionNumber: '2',
          finishEffectOptions: UtilService.mapObjectToSelectOptions(
            finishEffectOptions2,
            'id',
            'name',
            { customParams: ['upcharge'] }
          ) as SelectOptionProps[],
        })
      );
    }

    if (finishEffectOptions3) {
      yield put(
        setFinishEffectOptions({
          optionNumber: '3',
          finishEffectOptions: UtilService.mapObjectToSelectOptions(
            finishEffectOptions3,
            'id',
            'name',
            { customParams: ['upcharge'] }
          ) as SelectOptionProps[],
        })
      );
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getSpecsOptionsByWoodOrMaterial(
  action: Action<GetSpecsOptionsByWoodOrMaterialRequest>
) {
  try {
    const {
      productLineId,
      woodMaterialId,
      showAvailableFinishesOnly,
      specialFinishSample,
      isOverriden,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId);
    queryParams.append('woodMaterialId', woodMaterialId);

    if (showAvailableFinishesOnly) {
      queryParams.append(
        'showOnlyAvailable',
        showAvailableFinishesOnly.toString()
      );
    }

    if (specialFinishSample) {
      queryParams.append('specialFinishSample', specialFinishSample);
    }

    const finishColors: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/finishcolors`,
      {
        params: queryParams,
      }
    );

    yield queryParams.delete('showOnlyAvailable');

    const varnish: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/varnishes`,
      { params: queryParams }
    );

    const woodInteriors: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/woodinteriors`,
      { params: queryParams }
    );

    // add isOverriden just for woodEffects
    if (isOverriden) {
      yield queryParams.append('isOverriden', isOverriden.toString());
    }

    const woodEffects: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/woodeffects`,
      { params: queryParams }
    );

    yield put(
      setSpecsOptions({
        woodEffectOptions1: UtilService.mapObjectToSelectOptions(
          woodEffects,
          undefined,
          undefined,
          { customParams: ['upcharge'] }
        ) as SelectOptionProps[],
        varnishSheenOptions: UtilService.mapObjectToSelectOptions(
          varnish,
          undefined,
          undefined,
          { customParams: ['upcharge'] }
        ) as SelectOptionProps[],
        finishColorOptions: UtilService.mapObjectToSelectOptions(
          finishColors,
          undefined,
          undefined,
          { customParams: ['imageUrl', 'upcharge'] }
        ) as SelectOptionProps[],
        woodInteriorOptions: UtilService.mapObjectToSelectOptions(
          woodInteriors,
          undefined,
          undefined,
          { customParams: ['upcharge'] }
        ) as SelectOptionProps[],
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFinishColors(action: Action<FinishColorsRequest>) {
  try {
    const {
      productLineId,
      woodMaterialId,
      specialFinishSample,
      showOnlyAvailable,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId);
    queryParams.append('woodMaterialId', woodMaterialId);

    if (showOnlyAvailable) {
      queryParams.append('showOnlyAvailable', showOnlyAvailable.toString());
    }

    if (specialFinishSample) {
      queryParams.append('specialFinishSample', specialFinishSample);
    }

    const finishColorsResponse: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/finishcolors`,
      {
        params: queryParams,
      }
    );

    const finishColorOptions = UtilService.mapObjectToSelectOptions(
      finishColorsResponse,
      undefined,
      undefined,
      { customParams: ['imageUrl', 'upcharge'] }
    ) as SelectOptionProps[];

    yield put(setFinishColors(finishColorOptions));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* getFinishImpressionOptions(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', action.payload!);

    const finishImpressions: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/finishimpressions`,
      { params: queryParams }
    );

    yield put(
      setFinishImpressionOptions(
        UtilService.mapObjectToSelectOptions(
          finishImpressions,
          undefined,
          undefined,
          { customParams: ['imageUrl', 'upcharge'] }
        ) as SelectOptionProps[]
      )
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* getFinishImpressionData(
  action: Action<FinishImpressionRequest>
) {
  try {
    const {
      productLineId,
      finishImpressionId,
      cabinetBoxMaterial,
      cabinetBoxMaterialUpcharge,
      specialCabinetBoxMaterial,
      naturalInterior,
      woodTape,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId);

    const finishImpressionData: ImpressionData = yield call(
      ApiService.get,
      `/api/catalog/finishimpressions/${finishImpressionId}`,
      { params: queryParams }
    );

    yield put(setStylesSpecifications(null));

    yield put(
      setFinishImpressionData({
        ...finishImpressionData,
        id: finishImpressionId,
        cabinetBoxMaterial,
        cabinetBoxMaterialUpcharge,
        specialCabinetBoxMaterial,
        naturalInterior,
        woodTape,
      })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* validateStyleSpecifications(
  action: Action<ValidateStyleSpecificationsRequest>
) {
  try {
    const { orderId, styleId, woodMaterialId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('woodMaterialId', woodMaterialId);

    const validationStatus: ValidateStyleSpecificationsResponse = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}/specifications/validate`,
      {
        params: queryParams,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, validationStatus?.isValid);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* validateInovae2OStyleSpecifications(
  action: Action<ValidateInovae2OStyleSpecificationsRequest>
) {
  try {
    const { orderId, styleId, materialGroupId, materialColorId } =
      action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('materialGroupId', materialGroupId);
    queryParams.append('materialColorId', materialColorId);

    const validationStatus: ValidateStyleSpecificationsResponse = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}/inovae2o/specifications/validate`,
      {
        params: queryParams,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, validationStatus?.isValid);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveStyleSpecs(action: Action<StyleSpecificationsRequest>) {
  try {
    const { orderId, styleId, ...rest } = action.payload!;

    const specsExist: Style = yield select(
      (state: RootState) =>
        state.orderStylesReducer.style?.materialGroup !== null ||
        state.orderStylesReducer.style?.woodMaterial !== null
    );

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/styles/${styleId}/specifications/${
        specsExist ? 'update' : 'add'
      }`,
      rest
    );

    yield put(setFinishImpressionData(null));
    yield put(updateSpecOption(SpecificationsOptionsEnum.CONFIGURE_WOOD));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveStyleSpecsImpressions(
  action: Action<StyleSpecificationsRequest>
) {
  try {
    const { orderId, styleId, ...rest } = action.payload!;

    const specsExist: Style = yield select(
      (state: RootState) =>
        state.orderStylesReducer.style?.woodMaterial !== null
    );

    yield call(
      specsExist ? ApiService.put : ApiService.post,
      `/api/order/orders/${orderId}/styles/${styleId}/specifications/finish-impression/${
        specsExist ? 'update' : 'add'
      }`,
      rest
    );

    yield put(setStylesSpecifications(null));
    yield put(setSpecsFinishImpressionOption(rest.finishImpressionId!));
    yield put(updateSpecOption(SpecificationsOptionsEnum.SELECT_IMPRESSION));

    yield put(
      setSpecNotes({
        finishNotes: rest.finishNotes ?? '',
        woodNotes: rest.woodNotes ?? '',
      })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCabinetBoxMaterials(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', action.payload!);

    const cabinetBoxMaterials: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/cabinetboxmaterials`,
      { params: queryParams }
    );

    const cabinetBoxOptions = UtilService.mapObjectToSelectOptions(
      cabinetBoxMaterials,
      'id',
      'name',
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    yield put(setCabinetBoxMaterials(cabinetBoxOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMaterialGroups(action: Action<GetMaterialGroupsRequest>) {
  try {
    const { productLineId } = action.payload!;

    const queryParams = new URLSearchParams();

    if (productLineId) {
      queryParams.append('productLineId', productLineId);
    }

    const materialGroups: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/materialgroups`,
      { params: queryParams }
    );

    const materialGroupOptions = UtilService.mapObjectToSelectOptions(
      materialGroups,
      undefined,
      undefined,
      { customParams: ['description', 'upcharge', 'woodMaterialId'] }
    ) as SelectOptionProps[];

    yield put(setMaterialGroups(materialGroupOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMaterialColors(action: Action<GetMaterialColorsRequest>) {
  try {
    const { materialGroupId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('materialGroupId', materialGroupId);
    queryParams.append('productLineId', productLineId);

    const materialColorsResponse: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/materialcolors`,
      {
        params: queryParams,
      }
    );

    const materialColorOptions = UtilService.mapObjectToSelectOptions(
      materialColorsResponse,
      undefined,
      undefined,
      {
        customParams: [
          'imageUrl',
          'upcharge',
          'grainPattern',
          'edgebandColor',
          'doorSquareFootPrice',
          'miDoorSquareFootPrice',
        ],
      }
    ) as SelectOptionProps[];

    yield put(setMaterialColors(materialColorOptions));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getSpecsOptionsByWoodOrMaterialForICS(
  action: Action<GetSpecsOptionsByWoodOrMaterialForICSRequest>
) {
  try {
    const {
      productLineId,
      materialGroupId,
      woodMaterialId,
      showAvailableFinishesOnly,
      specialFinishSample,
      isOverriden,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId);
    queryParams.append('materialGroupId', materialGroupId);
    queryParams.append('woodMaterialId', woodMaterialId);

    if (showAvailableFinishesOnly) {
      queryParams.append(
        'showOnlyAvailable',
        showAvailableFinishesOnly.toString()
      );
    }

    if (specialFinishSample && showAvailableFinishesOnly) {
      queryParams.append('specialFinishSample', specialFinishSample);
    }

    yield call(getMaterialColors, {
      payload: { materialGroupId, productLineId },
      type: '',
    });

    yield call(getFinishColors, {
      payload: {
        woodMaterialId,
        showOnlyAvailable: showAvailableFinishesOnly,
        specialFinishSample,
        productLineId,
      },
      type: '',
    });

    yield queryParams.delete('showOnlyAvailable');
    yield queryParams.delete('materialGroupId');

    const varnish: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/varnishes`,
      { params: queryParams }
    );

    // add isOverriden just for woodEffects
    if (isOverriden) {
      yield queryParams.append('isOverriden', isOverriden.toString());
    }

    const woodEffects: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/woodeffects`,
      { params: queryParams }
    );

    yield put(
      setSpecsOptionsForICS({
        varnishSheenOptions: UtilService.mapObjectToSelectOptions(
          varnish,
          undefined,
          undefined,
          { customParams: ['upcharge'] }
        ) as SelectOptionProps[],
        woodEffectOptions1: UtilService.mapObjectToSelectOptions(
          woodEffects,
          undefined,
          undefined,
          { customParams: ['upcharge'] }
        ) as SelectOptionProps[],
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getGrainDirections(action: Action<unknown>) {
  try {
    const grainDirections: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/graindirections`
    );

    const grainDirectionOptions = UtilService.mapObjectToSelectOptions(
      grainDirections,
      undefined,
      undefined,
      { customParams: ['upcharge'] }
    ) as SelectOptionProps[];

    yield put(setGrainDirections(grainDirectionOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveInovae2OStyleSpecs(
  action: Action<Inovae2OStyleSpecificationsRequest>
) {
  try {
    const { orderId, styleId, styleStep, ...rest } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/styles/${styleId}/inovae2o/specifications/${
        styleStep === StylesStepsEnum.SPECIFICATIONS ? 'add' : 'update'
      }`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderStylesSpecificationSagas() {
  yield takeEvery(
    orderStylesSpecificationActions.VALIDATE_STYLE_SPECIFICATIONS,
    validateStyleSpecifications
  );
  yield takeEvery(
    orderStylesSpecificationActions.VALIDATE_INOVAE2O_STYLE_SPECIFICATIONS,
    validateInovae2OStyleSpecifications
  );
  yield takeEvery(orderStylesSpecificationActions.SAVE_SPECS, saveStyleSpecs);
  yield takeEvery(
    orderStylesSpecificationActions.SAVE_SPECS_IMPRESSIONS,
    saveStyleSpecsImpressions
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_FINISH_EFFECTS_BY_COLOR,
    getFinishEffectsByColor
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_FINISH_COLORS,
    getFinishColors
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_WOOD_OR_MATERIAL_OPTIONS,
    getWoodOrMaterial
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_SPECS_BY_WOOD_OR_MATERIAL,
    getSpecsOptionsByWoodOrMaterial
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS,
    getSpecsOptionsByWoodOrMaterialForICS
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_WOOD_EFFECTS_BY_WOOD_EFFECT,
    getWoodEffectsByWoodEffect
  );

  yield takeEvery(
    orderStylesSpecificationActions.GET_FINISH_EFFECTS_BY_FINISH_EFFECT,
    getFinishEffectsByFinishEffect
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_ALL_FINISH_EFFECTS_BY_FINISH_EFFECTS,
    getAllFinishEffectsByFinishEffects
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_FINISH_IMPRESSION_OPTIONS,
    getFinishImpressionOptions
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_FINISH_IMPRESSION_DATA,
    getFinishImpressionData
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_CABINETBOX_MATERIALS,
    getCabinetBoxMaterials
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_MATERIAL_GROUPS,
    getMaterialGroups
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_MATERIAL_COLORS,
    getMaterialColors
  );
  yield takeEvery(
    orderStylesSpecificationActions.GET_GRAIN_DIRECTIONS,
    getGrainDirections
  );
  yield takeEvery(
    orderStylesSpecificationActions.SAVE_INOVAE2O_SPECS,
    saveInovae2OStyleSpecs
  );
}
