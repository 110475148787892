import { FC, useRef } from 'react';
import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ReactComponent as NoteIcon } from 'assets/icons/edit-note.svg';

import SVG from 'shared/components/SVG';
import { Checkbox } from 'shared/components/Checkbox';
import { SPAN } from 'shared/components/Typography';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch, nevada } from 'shared/config/Colors';
import { useScrollToElement } from 'shared/hooks/useScrollToElement';

import { ACKTestCheckboxEnum } from 'tests/enums/ACKTestEnums';
import { testId } from 'tests/utils';

import { AckConsentForm } from '../Acknowledgment';
import { ACKStatusEnum } from '../enums/ACKStatusEnum';
import { SpecialOrderApprovalStatusEnum } from '../enums/SpecialOrderApprovalStatusEnum';

interface CoverSheetProps {
  consentTitle?: string;
  consentNeeded?: boolean;
  isSpecialOrderApproval?: boolean;
}

const CoverSheetContainer = styled(WhiteBox)``;

const CoverSheetBody = styled.div``;

const CoverSheetNote = styled(Wrapper)`
  background-color: ${({ theme }) => theme.wildSandTwo};
  border-radius: 5px;
  padding: 20px 30px;

  > ${SPAN} {
    padding-top: 2px;
  }
`;

const CoverSheetConsent = styled.div<{ isInvalid: boolean }>`
  ${({ isInvalid }) =>
    isInvalid &&
    css`
      position: relative;
      box-shadow: 0 0 0 15px ${({ theme }) => theme.wildSandTwo};
      border-radius: 3px;
      background-color: ${({ theme }) => theme.wildSandTwo};
    `}
`;

const CoverSheet: FC<CoverSheetProps> = ({
  children,
  consentTitle,
  consentNeeded,
  isSpecialOrderApproval,
}) => {
  const methodsContext = useFormContext<AckConsentForm>();

  const converSheetRef = useRef<HTMLDivElement | null>(null);

  const latestACK = useSelector(
    (state: RootState) => state.ACKReducer.latestACK
  );

  const isCurrentUserViewer = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserViewer
  );

  const specialOrderApproval = useSelector(
    (state: RootState) => state.orderReducer.order?.specialOrderApproval
  );

  useScrollToElement({
    errors: methodsContext.formState.errors,
    error: isSpecialOrderApproval
      ? methodsContext.formState.errors.consentGivenSpecialOrderApproval
      : methodsContext.formState.errors.consentGiven,
    ref: converSheetRef,
    fieldName: isSpecialOrderApproval
      ? 'consentGivenSpecialOrderApproval'
      : 'consentGiven',
  });

  return (
    <CoverSheetContainer ref={converSheetRef} padding="25px">
      <CoverSheetBody>{children}</CoverSheetBody>

      {isSpecialOrderApproval && specialOrderApproval && (
        <CoverSheetNote flex middle>
          <SVG icon={<NoteIcon />} color={lynch} />

          <Spacer w="20px" />

          <SPAN color={lynch} fontWeight={600}>
            Note from the CSR:
          </SPAN>

          <Spacer w="5px" />

          <SPAN color={nevada}>{specialOrderApproval.note}</SPAN>
        </CoverSheetNote>
      )}

      {!isSpecialOrderApproval && latestACK && latestACK.note && (
        <CoverSheetNote flex middle>
          <SVG icon={<NoteIcon />} color={lynch} />

          <Spacer w="20px" />

          <SPAN color={lynch} fontWeight={600}>
            Note from the CSR:
          </SPAN>

          <Spacer w="5px" />

          <SPAN color={nevada}>{latestACK.note}</SPAN>
        </CoverSheetNote>
      )}

      {consentNeeded && (
        <>
          <Spacer h="30px" />

          <CoverSheetConsent
            isInvalid={
              isSpecialOrderApproval
                ? !!methodsContext.formState.errors
                    .consentGivenSpecialOrderApproval
                : !!methodsContext.formState.errors.consentGiven
            }
          >
            <Checkbox
              {...methodsContext.register(
                isSpecialOrderApproval
                  ? 'consentGivenSpecialOrderApproval'
                  : 'consentGiven',
                {
                  required: isSpecialOrderApproval
                    ? specialOrderApproval?.status !==
                      SpecialOrderApprovalStatusEnum.APPROVED
                    : latestACK?.status !== ACKStatusEnum.APPROVED,
                }
              )}
              disabled={
                isSpecialOrderApproval
                  ? specialOrderApproval?.status ===
                      SpecialOrderApprovalStatusEnum.APPROVED ||
                    isCurrentUserViewer
                  : latestACK?.status === ACKStatusEnum.APPROVED ||
                    isCurrentUserViewer
              }
              title={
                consentTitle ?? 'I agree that this acknowledgment is approved.'
              }
              id={
                isSpecialOrderApproval ? 'sorConsentGiven' : 'ackConsentGiven'
              }
              {...testId(
                isSpecialOrderApproval
                  ? ACKTestCheckboxEnum.SOR_CONSENT_CHECKBOX
                  : ACKTestCheckboxEnum.ACK_CONSENT_CHECKBOX
              )}
              aria-invalid={
                (
                  isSpecialOrderApproval
                    ? methodsContext.formState.errors
                        .consentGivenSpecialOrderApproval
                    : methodsContext.formState.errors.consentGiven
                )
                  ? 'true'
                  : 'false'
              }
            />
          </CoverSheetConsent>
        </>
      )}
    </CoverSheetContainer>
  );
};

CoverSheet.defaultProps = {
  consentNeeded: true,
  isSpecialOrderApproval: false,
};

export default CoverSheet;
