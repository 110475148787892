import StaticField from 'order/components/StaticField/StaticField';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { useSelector } from 'react-redux';
import { Spacer } from 'shared/components/Layout';
import { H3 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { RootState } from 'store';

const OverviewDoorBuilder = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const config = useProductLineConfiguration();

  return (
    <Wrapper flexGrow>
      <H3>Door style</H3>
      <Spacer h="30px" />

      <StaticField field="Door overlay" value={storedStyle?.doorOverlay.name} />

      {config.doorBuilder.showFaceFrame && (
        <StaticField field="Face frame" value={storedStyle?.faceFrame.name} />
      )}

      <StaticField field="Frame style" value={storedStyle?.frameStyle?.name} />

      {config.doorBuilder.showOneInchDoor && (
        <StaticField
          field="1” Door"
          value={storedStyle?.oneInchDoor ? 'YES' : 'NO'}
        />
      )}

      {config.doorBuilder.showNoMidrails && (
        <StaticField
          field="Midrails"
          value={storedStyle?.noMidrails ? 'No Midrails' : 'Midrails'}
        />
      )}

      <StaticField
        field="Door Style"
        value={`Wall: ${storedStyle?.wallDoorStyle?.name || ''}<br/>Base: ${
          storedStyle?.baseDoorStyle?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerFrontStyle?.name || ''}`}
      />

      {storedStyle?.customFrameSize ? (
        <StaticField
          field="Frame size"
          value={
            selectedProductLine?.name === ProductLineEnums.PRODUCT_LINE_PRELUDE
              ? `${storedStyle?.customFrameSize} + $21`
              : storedStyle?.customFrameSize.toString()
          }
        />
      ) : (
        <StaticField
          field="Frame size"
          value={
            storedStyle?.frameSize?.upcharge
              ? `${storedStyle?.frameSize?.size} + ${storedStyle?.frameSize?.upcharge}`
              : storedStyle?.frameSize?.size?.toString()
          }
        />
      )}

      <StaticField
        field="Configuration"
        value={`Wall: ${
          storedStyle?.wallDoorConfiguration?.name || ''
        }<br/>Base: ${storedStyle?.baseDoorConfiguration?.name || ''}`}
      />

      <StaticField field="Arch style" value={storedStyle?.archStyle?.name} />

      <StaticField
        field="Inset panel"
        value={`Wall: ${
          storedStyle?.wallDoorInsertPanel?.name || ''
        }<br/>Base: ${
          storedStyle?.baseDoorInsertPanel?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerInsertPanel?.name || ''}`}
      />

      <StaticField
        field="Door edge"
        value={`Wall: ${storedStyle?.wallDoorEdge?.name || ''}<br/>Base: ${
          storedStyle?.baseDoorEdge?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerEdge?.name || ''}`}
      />

      {config.doorBuilder.showMetalFinish && (
        <StaticField
          field="Metal finish"
          value={storedStyle?.metalFinish?.name}
        />
      )}

      {config.doorBuilder.showDrawerTopPanel && (
        <StaticField
          field="Top drawer insert panel"
          value={storedStyle?.topInsertPanel?.name}
        />
      )}

      <StaticField field="Door notes" value={storedStyle?.doorNotes || ''} />

      <StaticField
        field="Drawer box"
        value={storedStyle?.drawerBox.name || ''}
      />

      <StaticField
        field="Hdf panels"
        value={storedStyle?.hdfPanels ? 'Yes' : 'No'}
      />

      {config.doorBuilder.showOneInchFaceFrame && (
        <StaticField
          field='1" FACE FRAME'
          value={storedStyle?.oneInchFaceFrame ? 'Yes' : 'No'}
        />
      )}

      {config.doorBuilder.showToeSpaceType && (
        <StaticField
          field="Toe Space Type"
          value={storedStyle?.toeSpaceType?.name || ''}
        />
      )}

      <Spacer h="20px" />
      <H3>Hardware</H3>
      <Spacer h="30px" />

      <StaticField field="Hinge Type" value={storedStyle?.hingeType?.name} />

      <StaticField field="Hinge Color" value={storedStyle?.hingeColor?.name} />

      <StaticField
        field="Door Hardware"
        value={storedStyle?.doorHardware?.name}
      />

      <StaticField
        field="Drawer Hardware"
        value={storedStyle?.drawerHardware?.name}
      />

      {config.doorBuilder.showClosetHardwareColor && (
        <StaticField
          field="Closet Hardware Color"
          value={storedStyle?.closetHardwareColor?.name}
        />
      )}

      <StaticField field="Hardware notes" value={storedStyle?.hardwareNotes} />
    </Wrapper>
  );
};

export default OverviewDoorBuilder;
