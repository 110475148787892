import styled from 'styled-components';
import { ButtonIcon } from './Button';

export const PrintButton = styled(ButtonIcon)`
  display: flex;
  align-items: center;

  * {
    transition: 300ms;
    color: ${({ theme }) => theme.silverChaliceTwo};
  }

  &:hover {
    * {
      color: ${({ theme }) => theme.kashmirBlue};
    }
  }
`;
