import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  AckChangeLogItem,
  EntityType,
  FieldName,
} from 'order/shared/ackchangelog/interface/AckChangeLogItem';

import {
  GetAckChangeLogRequest,
  getAckChangeLog,
  resetAckChangeLog,
} from 'order/shared/ackchangelog/store/ackchangelogActions';

import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { Thead, Tr, Th, Tbody, Td, ScrollTable } from 'shared/components/Table';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInfiniteScroll } from 'shared/hooks/useInfiniteScroll';

const TheadStyled = styled(Thead)<{ scrolledFromTop: boolean }>`
  transition: box-shadow 300ms ease;

  ${({ scrolledFromTop, theme }) =>
    scrolledFromTop && `box-shadow: -1px 2px 3px 0 ${theme.alto}`};
`;

const TrStyled = styled(Tr)`
  border: none !important;
  vertical-align: top;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.wildSandTwo};
  }
`;

const TdStyled = styled(Td)`
  font-size: 12px;
`;

const ThStyled = styled(Th)`
  font-size: 12px;
`;

interface AckChangeLogTableProps {
  ackOnly?: boolean;
}

const AckChangeLogTable: FC<AckChangeLogTableProps> = ({ ackOnly }) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const [tableDivRef, setTableDivRef] = useState<Scrollbars | null>(null);
  const [loading, setLoading] = useState(true);

  const ackChangeLog = useSelector(
    (state: RootState) =>
      state.ackChangeLogReducer.ackChangeLog as PaginatedItems<AckChangeLogItem>
  );

  const getAckChangeLogData = (pageNum: number) => {
    setLoading(true);

    const request: GetAckChangeLogRequest = {
      orderId,
      itemsPerPage: 15,
      page: pageNum,
      acknowledgementOnly: ackOnly ?? true,
    };

    dispatch(getAckChangeLog(request, setLoading));
  };

  const getFieldValue = (item: AckChangeLogItem) => {
    const field = FieldName[item.fieldName]
      .replace(EntityType[item.entityType], '')
      .split(/(?=[A-Z])/)
      .join(' ');

    return `${
      field &&
      (item.entityType === EntityType.LineItem ||
        item.entityType === EntityType.Modification)
        ? `${item.description} - `
        : ''
    } ${field || EntityType[item.entityType]}`;
  };

  const { page, handleUpdate, setScrollInProgress, scrolledFromTop } =
    useInfiniteScroll({
      hasNextPage: ackChangeLog?.hasNextPage ?? false,
      loadList: () => getAckChangeLogData(page),
      loading,
      setLoading,
      scrollToTop: () => tableDivRef?.scrollTop(0),
    });

  useEffect(() => {
    if (ackChangeLog) {
      dispatch(resetAckChangeLog());
    }
    getAckChangeLogData(1);
  }, []);

  return !ackChangeLog && loading ? (
    <Loader />
  ) : (
    <>
      <ScrollbarsCustom
        autoHeight
        autoHeightMax={470}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        onScrollStart={() => setScrollInProgress(true)}
        onScrollStop={() => setScrollInProgress(false)}
        onUpdate={handleUpdate}
        ref={(ref) => setTableDivRef(ref)}
      >
        <ScrollTable>
          <TheadStyled scrolledFromTop={scrolledFromTop > 0}>
            <Tr>
              {ackOnly && (
                <ThStyled data-name="1" width={120}>
                  Ack. V#
                </ThStyled>
              )}

              <ThStyled data-name="2" width={80}>
                #
              </ThStyled>

              <ThStyled data-name="3" width={ackOnly ? 120 : 150}>
                Field
              </ThStyled>

              <ThStyled data-name="4" width={ackOnly ? 89.5 : 134.5}>
                Old value
              </ThStyled>

              <ThStyled data-name="5" width={ackOnly ? 89.5 : 134.5}>
                New value
              </ThStyled>

              <ThStyled data-name="6" width={130}>
                Date
              </ThStyled>

              <ThStyled data-name="7" width={100}>
                User
              </ThStyled>
            </Tr>
          </TheadStyled>

          <Tbody>
            {ackChangeLog && ackChangeLog.items.length > 0 && (
              <>
                {ackChangeLog.items.map((item: AckChangeLogItem) => (
                  <TrStyled key={item.id}>
                    {ackOnly && <TdStyled width={120}>{item.version}</TdStyled>}

                    <TdStyled width={80}>{item.number ?? ''}</TdStyled>

                    <TdStyled width={ackOnly ? 120 : 150}>
                      {getFieldValue(item)}
                    </TdStyled>

                    <TdStyled>{item.oldValue}</TdStyled>

                    <TdStyled>{item.newValue}</TdStyled>

                    <TdStyled width={130}>
                      {UtilService.formatDate({
                        date: item.createdOnUtc,
                        asElement: false,
                        withTime: true,
                      })}
                    </TdStyled>
                    <TdStyled width={100}>{item.changedBy}</TdStyled>
                  </TrStyled>
                ))}
              </>
            )}

            {loading && (
              <TrStyled>
                <Td colSpan={7}>
                  <Loader noSpacing />
                </Td>
              </TrStyled>
            )}

            {!loading && ackChangeLog && !ackChangeLog.items.length && (
              <TrStyled>
                <Td colSpan={7}>No changes.</Td>
              </TrStyled>
            )}
          </Tbody>
        </ScrollTable>
      </ScrollbarsCustom>
    </>
  );
};

AckChangeLogTable.defaultProps = {
  ackOnly: true,
};

export default AckChangeLogTable;
