import { FC, SetStateAction, useEffect, useState } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ManageUsersIcon } from 'assets/icons/manage-users.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import {
  getCSRUserList,
  getUserList,
  manageUsersActions,
} from 'overview/manageUsers/store/manageUsersActions';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { H2 } from 'shared/components/Typography';
import { lynch, manatee } from 'shared/config/Colors';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { SortEnums } from 'shared/enum/sortEnums';
import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { TableColumnIdEnums } from 'shared/enum/tableEnums';
import { ToggleButton } from 'shared/components/ToggleButton';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import ElipsisText from 'shared/components/ElipsisText';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import {
  CustomTD,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'shared/components/Table';
import EmptyState from 'shared/components/EmptyState';
import { UserRole, UserRoleDescription } from 'shared/interface/UserRole';

interface StatusIconProps {
  online: boolean;
}

const Container = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 16px;
  ${BoxShadowCSS};
  margin: 0 auto;
  max-width: 1122px;
  padding: 31px 0px 16px 0px;
  width: 100%;
`;

const Header = styled(Wrapper)`
  margin: 0px 29px 28px 29px;
`;

const UserRoleAnnotation = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.gray};
  letter-spacing: 0.01em;
`;

const NameAndRoleContainer = styled.div`
  flex: 1;
  margin-left: 18px;
`;

const StatusIcon = styled.div<StatusIconProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ online, theme }) => (online ? theme.fern : theme.mischka)};
`;

interface ManageUsersTableProps {
  usersLoading: boolean;
  setUsersLoading: (isLoading: boolean) => void;
}

export const ManageUsersTable: FC<ManageUsersTableProps> = ({
  usersLoading,
  setUsersLoading,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location: { state: { successMessage: string } } = useLocation();

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const isUserDealer = useHasPermissions(
    userPermissionsValues.DEALER_VIEW_ORDERBOARD
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canReadCSRUserList = useHasPermissions(
    userPermissionsValues.CSR_USER_READ
  );

  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  const userList = useSelector(
    (state: RootState) => state.manageUsersReducer.users
  );

  const loggedInUser = useSelector(
    (state: RootState) => state.authReducer.user
  );

  const [sortColumn, setSortColumn] = useState(TableColumnIdEnums.NAME);
  const [sortDir, setSortDir] = useState(SortEnums.ASC);

  const toggleLoading = () => {
    setUsersLoading(false);
  };

  const handleEnableDisable = (userId: string, userEnabled: boolean) => {
    dispatch({
      type: manageUsersActions.ENABLE_USER,
      payload: userId,
      onSuccess: () => {
        toast.success(
          `You have successfully ${!userEnabled ? 'enabled' : 'disabled'} user.`
        );
      },
      onFailed: () => {
        toast.error(`User cannot be disabled or enabled at this moment.`);
      },
    });
  };

  const onSortHandle = (columnName: SetStateAction<TableColumnIdEnums>) => {
    setSortColumn(columnName);

    if (columnName === sortColumn && sortDir === SortEnums.ASC) {
      setSortDir(SortEnums.DESC);
    } else {
      setSortDir(SortEnums.ASC);
    }
  };

  const onGetUserListFailedHandler = (err: ServerErrorResponse) => {
    // In case of 403, redirect to 403 page
    if (err.status === 403) {
      history.push('/forbidden');
    }
  };

  // dispatch sort action when sort column is changed
  useEffect(() => {
    if (userList) {
      dispatch({
        type: manageUsersActions.SORT_USER_LIST,
        payload: { sortColumn, asc: sortDir === SortEnums.ASC },
      });
    }
  }, [sortColumn, sortDir]);

  useEffect(() => {
    if (dealership) {
      setUsersLoading(true);
      dispatch(
        getUserList(dealership.id, toggleLoading, onGetUserListFailedHandler)
      );
    }
  }, [dealership]);

  useEffect(() => {
    if (canReadCSRUserList && !dealership) {
      setUsersLoading(true);
      dispatch(getCSRUserList(toggleLoading));
    }
  }, [canReadCSRUserList, dealership]);

  // we need to clear state after redirecting
  useEffect(() => {
    if (location.state?.successMessage) {
      history.push('/overview/manage-users', null);
    }
  }, []);

  const filterUsers = () => {
    if (isUserSalesRep || isUserDealer) {
      return userList?.filter((u) =>
        u.roles.some(
          (r) =>
            (r as UserRole).description === UserRoleDescription.DEALER_USER ||
            (r as UserRole).description === UserRoleDescription.DEALER_ADMIN
        )
      );
    }

    return userList;
  };

  return (
    <div>
      <Container>
        {location.state?.successMessage &&
          toast.success(location.state.successMessage)}

        <Header flex middle>
          <H2>User List</H2>
        </Header>

        {usersLoading && (
          <LoaderFullScreen top={64} left={244} flex center middle>
            <Loader size={120} platoLogo={1} />
          </LoaderFullScreen>
        )}

        {!usersLoading && !dealership && isUserSalesRep && (
          <EmptyState
            iconSize={80}
            marginBottom={70}
            marginTop={50}
            icon={<ManageUsersIcon />}
            title="Select Dealership"
            message="Please select one of the available dealerships"
          />
        )}

        {userList &&
          ((isUserSalesRep && dealership) || isUserDealer || isUserCSR) && (
            <Table>
              <Thead>
                <Tr>
                  <Th
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumnIdEnums.NAME}
                    onClick={() => onSortHandle(TableColumnIdEnums.NAME)}
                  >
                    Name
                  </Th>
                  <Th
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumnIdEnums.EMAIL}
                    onClick={() => onSortHandle(TableColumnIdEnums.EMAIL)}
                  >
                    Email
                  </Th>
                  <Th>Number</Th>
                  <Th align="center">Status</Th>

                  {canCreateDealershipUsers && (
                    <>
                      <Th align="center">Enable Disable</Th>
                      <Th align="center">Edit</Th>
                    </>
                  )}
                </Tr>
              </Thead>

              <Tbody>
                {filterUsers()?.map((user) => (
                  <Tr key={user.id} disabled={loggedInUser?.id === user.id}>
                    <Td bold>
                      <CustomTD flex alignStart>
                        <div>
                          <Spacer h="7px" />
                          <AvatarCircle user={user} size={29} />
                        </div>
                        <NameAndRoleContainer>
                          <div>
                            <ElipsisText maxWidth={200}>
                              {user.firstName} {user.lastName}
                            </ElipsisText>
                          </div>
                          <div>
                            <UserRoleAnnotation>
                              {UtilService.getRoleForGivenUser(user)}
                            </UserRoleAnnotation>
                          </div>
                        </NameAndRoleContainer>
                      </CustomTD>
                    </Td>

                    <Td>
                      <ElipsisText maxWidth={250}>{user.email}</ElipsisText>
                    </Td>

                    <Td>{user.phoneNumber ?? '-not set-'}</Td>

                    <Td>
                      <CustomTD flex center>
                        <StatusIcon online={user.online!} />
                      </CustomTD>
                    </Td>

                    {canCreateDealershipUsers && (
                      <>
                        <Td>
                          <CustomTD flex center>
                            <ToggleButton
                              onEnableChange={handleEnableDisable}
                              userEnabled={user.enabled}
                              userID={user.id}
                              disabled={loggedInUser?.id === user.id}
                            />
                          </CustomTD>
                        </Td>

                        <Td>
                          <CustomTD flex center>
                            <TabbableButton
                              disabled={loggedInUser?.id === user.id}
                              onClick={() =>
                                history.push(
                                  `/overview/manage-users/${user.id}/edit-user`
                                )
                              }
                            >
                              <SVG
                                icon={<EditIcon />}
                                color={manatee}
                                hoverColor={lynch}
                              />
                            </TabbableButton>
                          </CustomTD>
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
      </Container>
    </div>
  );
};
