import { useSelector } from 'react-redux';
import {
  ButtonPrimary,
  ButtonSecondary,
  TabbableButton,
} from 'shared/components/Button';
import { Spacer } from 'shared/components/Layout';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/Table';
import { H2, P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch, nevada } from 'shared/config/Colors';
import { RootState } from 'store';
import { IAttachment } from 'shared/interface/IAttachment';
import UtilService from 'shared/services/util.service';
import {
  AdditionalFieldEntityType,
  AdditionalFieldType,
} from '../enums/AdditionalFieldsEnums';

interface AdditionalFieldAttachment {
  style: string;
  lineItem: string;
  modification?: string;
  attachments: IAttachment[];
  relevantForm: string;
}
const storageUrl =
  'https://plato-dev-files.s3.us-east-1.amazonaws.com/additionalfields/';

interface AdditionalAttachmentModalProps {
  continueToSubmit: () => void;
  opened: boolean;
  closeModal: () => void;
}

const useAdditionalAttachmentModal = ({
  continueToSubmit,
  opened,
  closeModal,
}: AdditionalAttachmentModalProps) => {
  const additionalFields = useSelector(
    (state: RootState) => state.aditionalFieldReducer.aditionalFields
  );

  const orderAttachments = useSelector(
    (state: RootState) => state.orderReducer.attachments
  );

  const orderLineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  const getAdditionalAttachments = () => {
    const attachmentFields: AdditionalFieldAttachment[] = [];

    if (orderAttachments && additionalFields && orderLineItems && styles) {
      styles
        .filter((s) => s.isDeleted === false)
        .forEach((style) => {
          orderLineItems
            .filter((li) => li.styleId === style.id)
            .forEach((li) => {
              const additionalField = additionalFields.find(
                (af) =>
                  af.entityType === AdditionalFieldEntityType.LineItem &&
                  af.type === AdditionalFieldType.Attachment &&
                  af.entityId === li.catalogLineItemId &&
                  af.productLineId === style.productLine.id
              );

              if (additionalField) {
                const attachments = orderAttachments.filter(
                  (att) =>
                    att.lineItem !== null && att.lineItem!.id === li.lineItemId
                );

                const item: AdditionalFieldAttachment = {
                  style: `#${style.number} ${style.name}`,
                  lineItem: `${li.number},${li.lineItemDetails.code}`,
                  modification: '',
                  attachments,
                  relevantForm: additionalField.name,
                };

                attachmentFields.push(item);
              }

              li.modifications.forEach((mod, index) => {
                const modAdditionalField = additionalFields.find(
                  (af) =>
                    af.entityType === AdditionalFieldEntityType.Modification &&
                    af.type === AdditionalFieldType.Attachment &&
                    af.entityId === mod.catalogModificationId &&
                    af.productLineId === style.productLine.id
                );

                if (modAdditionalField) {
                  const attachments = orderAttachments.filter(
                    (att) =>
                      att.lineItem !== null &&
                      att.lineItem!.id === li.lineItemId
                  );

                  const modItem: AdditionalFieldAttachment = {
                    style: `#${style.number} ${style.name}`,
                    lineItem: `${li.number},${li.lineItemDetails.code}`,
                    modification: `${index + 1},${
                      mod.modificationDetails?.name
                    }`,
                    attachments,
                    relevantForm: modAdditionalField.name,
                  };

                  attachmentFields.push(modItem);
                }
              });
            });
        });
    }

    return attachmentFields;
  };

  const AdditionalAttachmentModal = () => {
    return (
      <ModalWrapper closeOnDocumentClick={false} open={opened}>
        {() => (
          <Modal close={closeModal} noHeader medium>
            <H2 fontSize={24}>Additional Info</H2>

            <Spacer h="32px" />

            <Table>
              <Thead>
                <Tr>
                  <Th>Style</Th>
                  <Th>Line #</Th>
                  <Th>Mod #</Th>
                  <Th>Your Attachments</Th>
                  <Th>Relevant Form URL</Th>
                </Tr>
              </Thead>

              <Tbody>
                {getAdditionalAttachments().map((item) => (
                  <Tr>
                    <Td>{item.style}</Td>
                    <Td>{item.lineItem}</Td>
                    <Td>{item.modification}</Td>
                    <Td>
                      {item.attachments.map((att) => (
                        <>
                          <TabbableButton
                            color={lynch}
                            type="button"
                            onClick={async () => {
                              await UtilService.openBlob(
                                att.url!,
                                att.extension!
                              );
                            }}
                          >
                            {att.name}
                            {att.extension}
                          </TabbableButton>
                          <Spacer h="5px" />
                        </>
                      ))}
                    </Td>
                    <Td>
                      <TabbableButton
                        color={lynch}
                        type="button"
                        onClick={() => {
                          window.open(
                            `${storageUrl}${item.relevantForm}`,
                            '_blank'
                          );
                        }}
                      >
                        {item.relevantForm}
                      </TabbableButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Spacer h="20px" />

            <P fontSize={16} color={nevada}>
              All forms are required for accurate and timely order processing.
              Please add the relevant form. If any forms are omitted, they will
              be requested at order confirmation prior to ship week assignment.
            </P>
            <Spacer h="32px" />

            <Wrapper flex middle justifyEnd>
              <ButtonSecondary onClick={closeModal}>Cancel</ButtonSecondary>

              <Spacer w="15px" />

              <ButtonPrimary
                onClick={() => {
                  continueToSubmit();
                }}
              >
                Continue to Submit
              </ButtonPrimary>
            </Wrapper>
          </Modal>
        )}
      </ModalWrapper>
    );
  };

  return {
    AdditionalAttachmentModal,
    additionalAttachments: getAdditionalAttachments(),
  };
};

export default useAdditionalAttachmentModal;
