import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { CheckedLineItem, CheckedStyle } from '../interface/CheckedItems';
import { IAttachment } from '../../../../shared/interface/IAttachment';

export enum OrderAsapActions {
  CLEAR_ASAP_REDUCER = 'CLEAR_ASAP_REDUCER',
  SET_ASAP_STEP = 'SET_ASAP_STEP',
  GET_ASAP_ITEMS = 'GET_ASAP_ITEMS',
  SET_ASAP_ITEMS = 'SET_ASAP_ITEMS',
  CHECK_ASAP_ITEM = 'CHECK_ASAP_ITEM',
  COPY_SELECTED_ITEMS = 'COPY_SELECTED_ITEMS',
  SET_CHECKED_ATTACHMENTS = 'SET_CHECKED_ATTACHMENTS',
  ADD_CHECKED_ATTACHMENT = 'ADD_CHECKED_ATTACHMENT',
  REMOVE_CHECKED_ATTACHMENT = 'REMOVE_CHECKED_ATTACHMENT',
  COPY_ATTACHMENTS = 'COPY_ATTACHMENTS',
}

export const clearAsapReducer = () => ({
  type: OrderAsapActions.CLEAR_ASAP_REDUCER,
});

export const setAsapStep = (step: number) => ({
  type: OrderAsapActions.SET_ASAP_STEP,
  payload: step,
});

export interface GetAsapItemsRequest {
  isOrderSalesMaterial: boolean;
  orderId: string;
  productLineId: string;
}

export const getAsapItems = (
  payload: GetAsapItemsRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: OrderAsapActions.GET_ASAP_ITEMS,
  payload,
  loading,
});

export interface GetAsapAttachmentsRequest {
  orderId: string;
}

interface SetAsapItemsProps {
  lineItems: LineItem[];
  styles: Style[];
}

export const setAsapItems = (payload: SetAsapItemsProps) => ({
  type: OrderAsapActions.SET_ASAP_ITEMS,
  payload,
});

export const setCheckedAttachments = (payload: IAttachment[] | null) => ({
  type: OrderAsapActions.SET_CHECKED_ATTACHMENTS,
  payload,
});

export const addCheckedAttachment = (payload: IAttachment) => ({
  type: OrderAsapActions.ADD_CHECKED_ATTACHMENT,
  payload,
});
export const removeCheckedAttachment = (payload: string) => ({
  type: OrderAsapActions.REMOVE_CHECKED_ATTACHMENT,
  payload,
});

export interface CheckAsapItemProps {
  itemId: string;
  checked: boolean;
  itemType: 'Styles' | 'LineItems' | 'Modifications';
}

export const checkAsapItem = (payload: CheckAsapItemProps) => ({
  type: OrderAsapActions.CHECK_ASAP_ITEM,
  payload,
});

export interface CopySelectedItemsRequest {
  isOrderSalesMaterial: boolean;
  newOrderId: string;
  orderId: string;
  styles?: CheckedStyle[];
  lineItems?: CheckedLineItem[];
}

export interface CopySelectedAttachmentsRequest {
  orderId: string;
  copyOrderId: string;
  attachmentIds?: string[];
}

export const copySelectedItems = (
  payload: CopySelectedItemsRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: OrderAsapActions.COPY_SELECTED_ITEMS,
  payload,
  onSuccess,
  loading,
});

export const copySelectedAttachments = (
  payload: CopySelectedAttachmentsRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: OrderAsapActions.COPY_ATTACHMENTS,
  payload,
  onSuccess,
  loading,
});
