import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';

import {
  OrderPriceReview,
  OrderPriceReviewSM,
} from 'order/wizard/orderStyles/interface/OrderPriceReview';

import UtilService from 'shared/services/util.service';
import { ContainerBox, ContainerMedium } from 'shared/components/Container';
import { H6, PMedium } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada } from 'shared/config/Colors';
import { useGetDealershipInfo } from 'shared/hooks/useGetDealershipInfo';
import { useGetShipViaOption } from 'shared/hooks/useGetShipViaOption';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';

export const BasicInfo = () => {
  const order = useSelector((state: RootState) => state.orderReducer.order);

  const shipViaOption = useGetShipViaOption(order?.shipOptionId);

  useGetDealershipInfo(order?.dealershipId);

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const dealershipAddress = dealership?.addresses[0];

  const salesRep = useSelector(
    (state: RootState) => state.dealershipReducer.salesRepresentative
  );

  const address = useSelector(
    (state: RootState) =>
      state.orderReducer.nonExistingAddress ??
      state.orderReducer.selectedShippingAddress
  );

  const priceReview: OrderPriceReview | OrderPriceReviewSM | null = useSelector(
    (state: RootState) =>
      state.orderReducer.priceReview ?? state.orderReducer.priceReviewSM
  );

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );

  const stylesWithNote = orderStyles?.filter((style) => style.note);

  const { highlightOrderField } = useHighlightHelper(true);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const getPWWUsernames = () => {
    return (
      order?.assigneeResponses
        ?.map((assignee) => assignee.pwwUsername)
        .join('/') || '-'
    );
  };

  return (
    <Wrapper flex>
      <Wrapper flex column flexGrow maxWidth="calc(50% - 8px);">
        <ContainerBox flex between>
          <ContainerMedium flexGrow>
            <H6>SHIP TO</H6>

            <Spacer h="32px" />

            {address ? (
              <PMedium
                color={nevada}
                fontSize={14}
                className={highlightOrderField(FieldName.OrderShippingAddress)}
              >
                {address.phoneNumber}
                <Spacer h="16px" />
                {address.shipTo}
                <Spacer h="16px" />
                {address.street}
                <Spacer h="16px" />
                {address.number}
                <Spacer h="16px" />
                {address.city}, {address.state} {address.zipCode}
              </PMedium>
            ) : (
              <PMedium color={nevada}>Will Call</PMedium>
            )}
          </ContainerMedium>

          <ContainerMedium flexGrow>
            <H6>SOLD TO</H6>

            <Spacer h="32px" />

            <PMedium color={nevada}>#{dealership?.accountNumber}</PMedium>

            <Spacer h="16px" />

            {dealershipAddress ? (
              <PMedium color={nevada}>
                {dealershipAddress.shipTo}
                <Spacer h="16px" />
                {dealershipAddress.number} {dealershipAddress.street}
                <Spacer h="16px" />
                {dealershipAddress.city}, {dealershipAddress.state}{' '}
                {dealershipAddress.zipCode}
              </PMedium>
            ) : (
              <PMedium color={nevada}>-</PMedium>
            )}
          </ContainerMedium>
        </ContainerBox>

        <Spacer h="16px" />

        <ContainerBox flexGrow>
          {isOrderSalesMaterial && (
            <>
              <ContainerMedium flexGrow>
                <H6>ORDER NOTES</H6>
              </ContainerMedium>

              <Spacer h="16px" />
              <ContainerMedium flexGrow>
                <PMedium color={nevada}>
                  {order?.note || 'No notes for this order.'}
                </PMedium>
              </ContainerMedium>
              <Spacer h="16px" />
            </>
          )}

          {!isOrderSalesMaterial && (
            <>
              <ContainerMedium flexGrow>
                <H6>NOTES ON STYLES</H6>
              </ContainerMedium>

              <Spacer h="16px" />

              {stylesWithNote?.map((style) => (
                <>
                  <ContainerMedium flexGrow>
                    <H6>
                      #{style.number} {style.name}
                    </H6>
                    <Wrapper flex middle>
                      <Spacer w="10px" />

                      <PMedium color={nevada}>{style.note}</PMedium>
                    </Wrapper>
                  </ContainerMedium>

                  <Spacer h="15px" />
                </>
              ))}

              {stylesWithNote && (stylesWithNote?.length ?? 0) === 0 && (
                <ContainerMedium flexGrow>
                  <PMedium color={nevada}>
                    No notes on this order or any of the styles.
                  </PMedium>
                </ContainerMedium>
              )}
            </>
          )}
        </ContainerBox>
      </Wrapper>

      <Spacer w="16px" />

      <ContainerBox flexGrow maxWidth="calc(50% - 8px);">
        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>ROOM NAME</H6>
          </ContainerMedium>

          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderName)}
          >
            <PMedium color={nevada}>{order?.name}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>JOB NAME</H6>
          </ContainerMedium>

          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderJob)}
          >
            <PMedium color={nevada}>{order?.job?.name}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>ORDER DATE</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>
              {UtilService.formatDate({
                date: order?.submittedOnUtc,
                withTime: false,
              })}
            </PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>ORDERED BY</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>
              {order?.orderedBy
                ? `${UtilService.getUsersConcatenatedNameOrEmail(
                    order.orderedBy
                  )}`
                : '-'}
            </PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>P.O.#</H6>
          </ContainerMedium>
          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderPostOfficeNumber)}
          >
            <PMedium color={nevada}>{order?.postOfficeNumber || '-'}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>SALES REP</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>{salesRep?.companyName ?? '-'}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>SHIP WEEK</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>
              {UtilService.getShipWeekLabel({
                defaultValue: '-',
                scheduleType: order?.scheduleType,
                shipWeekDate: order?.shipWeekDate,
                shipWeekDescription: order?.shipWeekDescription,
              })}
            </PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>SHIP VIA</H6>
          </ContainerMedium>
          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderShipVia)}
          >
            <PMedium color={nevada}>{shipViaOption?.label ?? '-'}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>TERMS</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>
              {priceReview?.paymentTermsCode || '-'}
            </PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        {isOrderSalesMaterial && (
          <>
            <Wrapper flex middle between>
              <ContainerMedium flexGrow>
                <H6>ORIGINAL WO#</H6>
              </ContainerMedium>
              <ContainerMedium
                flexGrow
                align="right"
                className={highlightOrderField(FieldName.OrderWorkOrderNumber)}
              >
                <PMedium color={nevada}>
                  {order?.workOrderNumber || '-'}
                </PMedium>
              </ContainerMedium>
            </Wrapper>

            <Spacer h="16px" />
          </>
        )}

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>CEILING HEIGHT</H6>
          </ContainerMedium>
          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderCeilingHeight)}
          >
            <PMedium color={nevada}>{order?.ceilingHeight}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>COUNTERTOP</H6>
          </ContainerMedium>
          <ContainerMedium
            flexGrow
            align="right"
            className={highlightOrderField(FieldName.OrderCountertopThickness)}
          >
            <PMedium color={nevada}>{order?.countertopThickness}</PMedium>
          </ContainerMedium>
        </Wrapper>

        <Spacer h="16px" />

        <Wrapper flex middle between>
          <ContainerMedium flexGrow>
            <H6>PWW</H6>
          </ContainerMedium>
          <ContainerMedium flexGrow align="right">
            <PMedium color={nevada}>{getPWWUsernames()}</PMedium>
          </ContainerMedium>
        </Wrapper>
      </ContainerBox>
    </Wrapper>
  );
};
