import styled from 'styled-components';

import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';

import { anzac, silverChaliceTwo } from 'shared/config/Colors';

import { FC } from 'react';
import SVG from './SVG';
import { Tooltip } from './Tooltip';

const FlashContainer = styled.div`
  position: relative;

  .icon {
    display: block;

    &:hover {
      + ${Tooltip} {
        opacity: 1;
      }
    }
  }
`;
interface FlashProps {
  type: 'Order' | 'Style';
}

const Flash: FC<FlashProps> = ({ type, ...rest }) => {
  return (
    <FlashContainer {...rest}>
      <SVG
        icon={<FlashIcon width={15} height={15} />}
        color={silverChaliceTwo}
        hoverColor={anzac}
      />
      <Tooltip position="bottom">{type} cancellation requested</Tooltip>
    </FlashContainer>
  );
};

export default Flash;
