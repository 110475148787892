import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import UtilService from 'shared/services/util.service';

export enum TopicFilterEnums {
  UNRESOLVED = 'unresolved',
  RESOLVED = 'resolved',
  BOTH = 'both',
}

export const topicFlagOptions: SelectOptionProps[] = [
  UtilService.mapStringValueToSelectOptionObject(TopicFilterEnums.UNRESOLVED),
  UtilService.mapStringValueToSelectOptionObject(TopicFilterEnums.RESOLVED),
  UtilService.mapStringValueToSelectOptionObject(TopicFilterEnums.BOTH),
];
