import { FC } from 'react';
import styled from 'styled-components';

import { ButtonPrimary } from './Button';
import { Wrapper } from './Wrapper';

interface SwitchButtonProps {
  isActive: boolean;
  onChange: (isActive: boolean) => void;
  leftButtonText: string;
  rightButtonText: string;
}

const SwitchButtonContainer = styled(Wrapper)``;

export const SwitchButtonStyled = styled(ButtonPrimary)`
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  border: none;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &.not-active {
    background: ${({ theme }) => theme.mischka};
  }
`;

const SwitchButton: FC<SwitchButtonProps> = ({
  isActive,
  onChange,
  leftButtonText,
  rightButtonText,
}) => {
  return (
    <SwitchButtonContainer flex middle>
      <SwitchButtonStyled
        onClick={() => onChange(!isActive)}
        className={!isActive ? 'not-active' : ''}
      >
        {leftButtonText}
      </SwitchButtonStyled>

      <SwitchButtonStyled
        onClick={() => onChange(!isActive)}
        className={isActive ? 'not-active' : ''}
      >
        {rightButtonText}
      </SwitchButtonStyled>
    </SwitchButtonContainer>
  );
};

export default SwitchButton;
