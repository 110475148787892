import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { anzac } from 'shared/config/Colors';
import { Checkbox } from 'shared/components/Checkbox';
import { Info } from 'shared/components/Info';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';
import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';

const ShowAvailableFinishes = () => {
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const finishColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishColorOptions
  );

  const materialColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.materialColorOptions
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const methodsContext = useFormContext<StyleSpecifications>();

  const woodOrMaterialWatched = methodsContext.watch('woodOrMaterial');
  const finishColorWatched = methodsContext.watch('finishColor');
  const showAvailableFinishesOnlyWatched = methodsContext.watch(
    'showAvailableFinishesOnly'
  );

  const specialFinishSampleWatched = methodsContext.watch(
    'specialFinishSample'
  );

  // for ics or inovae 2.0
  const materialColorWatched = methodsContext.watch('materialColor');

  return (
    <>
      <Wrapper flex middle between>
        <OrderFinishLabel
          style={{
            productLine: selectedProductLine ?? undefined,
            ...(woodOrMaterialWatched && {
              woodMaterial: {
                id: woodOrMaterialWatched.value,
                name: woodOrMaterialWatched.label,
              },
            }),
          }}
          render={(label) => (
            <WarningTooltip
              fieldName={label}
              withTooltip={UtilService.shouldShowTooltip(
                finishColorOptions || materialColorOptions,
                finishColorWatched || materialColorWatched
              )}
            >
              <Checkbox
                {...methodsContext.register('showAvailableFinishesOnly')}
                id="availableFinishColors"
                title="Available Only"
                readOnly={!canEdit}
              />
            </WarningTooltip>
          )}
        />

        {(showAvailableFinishesOnlyWatched ||
          specialFinishSampleWatched.length > 0) && (
          <Info type="warning">
            <Tooltip position="left" bgColor={anzac}>
              A door only sample is required
            </Tooltip>
          </Info>
        )}
      </Wrapper>

      <Spacer h="16px" />
    </>
  );
};

export default ShowAvailableFinishes;
