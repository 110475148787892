import { FC } from 'react';
import { Spacer } from 'shared/components/Layout';
import { H3, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import styled from 'styled-components';

const OrderAsapOrDuplicateHeader = styled.div`
  margin: 40px 0;
`;

interface OrderAsapHeaderProps {
  title: string;
  desc?: string;
}

const OrderAsapHeader: FC<OrderAsapHeaderProps> = ({ title, desc }) => {
  return (
    <OrderAsapOrDuplicateHeader>
      <H3>{title}</H3>

      {desc && (
        <>
          <Spacer h="10px" />

          <P fontSize={14} color={nevada}>
            {desc}
          </P>
        </>
      )}
    </OrderAsapOrDuplicateHeader>
  );
};

export default OrderAsapHeader;
