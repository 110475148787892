import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { Wrapper } from './Wrapper';
import { H2 } from './Typography';

interface EmptyStateProps {
  height?: number;
  icon?: ReactElement;
  iconSize?: number;
  marginBottom?: number;
  marginTop?: number;
  message?: string;
  title?: string;
  titleColorName?: string;
  titleSize?: number;
  width?: number;
}

interface EmptyStateContainerProps {
  marginTop: number;
  marginBottom: number;
}

type TitleProps = Pick<EmptyStateProps, 'titleColorName' | 'titleSize'>;

type IconProps = Pick<EmptyStateProps, 'iconSize' | 'width' | 'height'>;

const EmptyStateContainer = styled.div<EmptyStateContainerProps>`
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  text-align: center;
`;

const Icon = styled(Wrapper)<IconProps>`
  width: ${({ width }) => width ?? 94}px;
  height: ${({ height }) => height ?? 94}px;
  margin: 0 auto 15px auto;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.wildSand};

  > svg {
    width: ${({ iconSize }) => `${iconSize}px` || '36px'};
    height: auto;
    display: block;
  }
`;

const Title = styled(H2)<TitleProps>`
  color: ${({ theme, titleColorName }) =>
    titleColorName ? theme[titleColorName] : theme.nevada};

  font-size: ${({ titleSize }) => (titleSize ? `${titleSize}px` : undefined)};
`;

const Message = styled.div`
  margin-top: 5px;
`;

const Actions = styled(Wrapper)`
  margin-top: 25px;
`;

const EmptyState: FC<EmptyStateProps> = ({
  marginTop,
  marginBottom,
  title,
  titleColorName,
  icon,
  iconSize,
  width,
  height,
  message,
  children,
  titleSize,
}) => {
  return (
    <EmptyStateContainer marginTop={marginTop!} marginBottom={marginBottom!}>
      {icon && (
        <Icon
          flex
          middle
          center
          iconSize={iconSize}
          width={width}
          height={height}
        >
          {icon}
        </Icon>
      )}

      {title && (
        <Title titleColorName={titleColorName} titleSize={titleSize}>
          {title}
        </Title>
      )}
      {message && <Message>{parse(message)}</Message>}

      {children && (
        <Actions flex center>
          {children}
        </Actions>
      )}
    </EmptyStateContainer>
  );
};

EmptyState.defaultProps = {
  marginTop: 30,
  marginBottom: 0,
};

export default EmptyState;
