import styled from 'styled-components';
import { FC } from 'react';

import UtilService from 'shared/services/util.service';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf-file.svg';
import { ReactComponent as JPGIcon } from 'assets/icons/jpg-file.svg';
import { ReactComponent as PNGIcon } from 'assets/icons/png-file.svg';
import { ReactComponent as CADIcon } from 'assets/icons/cad-file.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { H6 } from 'shared/components/Typography';
import { TabbableButton } from 'shared/components/Button';
import ElipsisText from './ElipsisText';

interface CommentAttachmentProps {
  fileName: string;
  fileType: string;
  deleteAttachment?: (() => void) | null;
}

const CommentAttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  height: 46px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.pigeonPost};
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AttachmentIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: 6px;

  > svg path {
    fill: ${({ theme }) => theme.chambray};
  }
`;

const AttachmentFileName = styled.div``;

const CustomButton = styled(TabbableButton)`
  position: absolute;
  top: 7px;
  right: 7px;

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const CommentAttachment: FC<CommentAttachmentProps> = ({
  fileName,
  fileType,
  deleteAttachment,
}) => {
  const getFileIcon = () => {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return <JPGIcon />;
      case 'image/png':
        return <PNGIcon />;
      case 'application/pdf':
        return <PDFIcon />;
      case 'image/vnd.dwg':
      case 'image/vnd.dxf':
        return <CADIcon />;
      default:
        return <JPGIcon />;
    }
  };

  const formatFileName = () => {
    if (fileName.includes('.')) {
      return UtilService.removeExtensionFromFileName(fileName);
    }
    return fileName;
  };

  return (
    <CommentAttachmentContainer>
      <AttachmentIconContainer>{getFileIcon()}</AttachmentIconContainer>
      <AttachmentFileName>
        <H6>
          <ElipsisText noTooltip maxWidth={273}>
            {formatFileName()}
          </ElipsisText>
        </H6>
      </AttachmentFileName>
      {deleteAttachment && (
        <CustomButton onClick={deleteAttachment}>
          <CloseCircle />
        </CustomButton>
      )}
    </CommentAttachmentContainer>
  );
};

CommentAttachment.defaultProps = {
  deleteAttachment: null,
};
