import { AnyAction } from 'redux';
import { CurtainWorkablesTabEnums } from '../enums/CurtainWorkablesTabEnums';
import { IWorkable } from '../interface/Workable';
import { CurtainWorkablesActions } from './curtainWorkablesActions';

interface CurtainWorkablesReducerProps {
  workables: IWorkable[] | null;
  workable: IWorkable | null;
  mode: CurtainWorkablesTabEnums;
  numberOfUnresolvedWorkables: number | null;
}

const initialState: CurtainWorkablesReducerProps = {
  workables: null,
  workable: null,
  mode: CurtainWorkablesTabEnums.LIST_MODE,
  numberOfUnresolvedWorkables: null,
};

export const curtainWorkablesReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case CurtainWorkablesActions.CLEAR_WORKABLES:
      return {
        ...state,
        workables: null,
        workable: null,
        mode: CurtainWorkablesTabEnums.LIST_MODE,
      };
    case CurtainWorkablesActions.SET_WORKABLES:
      return {
        ...state,
        workables: action.payload as IWorkable[],
      };
    case CurtainWorkablesActions.SET_WORKABLE:
      return {
        ...state,
        workable: action.payload as IWorkable,
      };
    case CurtainWorkablesActions.SET_WORKABLES_MODE:
      return {
        ...state,
        mode: action.payload as CurtainWorkablesTabEnums,
      };
    case CurtainWorkablesActions.UPDATE_WORKABLE_RESOLVE_STATUS:
      return {
        ...state,
        workables: state.workables!.map((workable) => {
          if (workable.id === action.payload) {
            return {
              ...workable,
              resolved: !workable.resolved,
            } as IWorkable;
          }

          return workable;
        }),
      };
    case CurtainWorkablesActions.SET_UNRESOLVED_WORKABLES_COUNT:
      return {
        ...state,
        numberOfUnresolvedWorkables: action.payload as number,
      };
    default:
      return state;
  }
};
