import { FC, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const ToggleLabel = styled.label`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.silverSand};
  transition: 300ms ease;

  &:before {
    position: absolute;
    content: '';
    left: 4px;
    bottom: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 300ms;
    background-color: ${({ theme }) => theme.white};
  }
`;

const ToggleWrapper = styled.div<{ readOnly?: boolean }>`
  position: relative;
  display: block;
  transition: 300ms;
  width: 40px;
  height: 20px;

  ${({ readOnly }) => readOnly && `cursor: not-allowed;`}

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${ToggleLabel} {
      background-color: ${({ theme }) => theme.kashmirBlue};

      &:before {
        transform: translateX(20px);
      }
    }

    &[readonly] + ${ToggleLabel} {
      opacity: 0.35;
      pointer-events: none;
    }

    &[area-invalid='true'] + ${ToggleLabel} {
      background-color: ${({ theme }) => theme.fairPink};
      box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.valencia};

      &::before {
        background-color: ${({ theme }) => theme.valencia};
      }
    }
  }
`;

export const ToggleCheckbox: FC<InputHTMLAttributes<HTMLInputElement>> =
  forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <ToggleWrapper readOnly={props.readOnly}>
        <input ref={ref} {...props} type="checkbox" />
        <ToggleLabel htmlFor={props.id} />
      </ToggleWrapper>
    );
  });
