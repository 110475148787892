import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import imagePlaceholder from 'assets/images/portrait_placeholder.png';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { ImageResponse } from 'shared/interface/serverResponses/ImageResponse';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Wrapper } from 'shared/components/Wrapper';
import { Form } from 'shared/components/Form';
import { toast } from 'react-toastify';
import { editUserDetailsActions } from '../store/editUserDetailsActions';
import { CropImageModal } from './CropImageModal';

interface FormInputs {
  selectedImage: FileList;
}

const MainContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
`;
const Image = styled.img`
  width: 90px;
  border-radius: 50%;
  &:before {
    display: inline-block;
    color: transparent;
    background-image: url(${imagePlaceholder});
    height: 80px;
    width: 90px;
    background-size: 90px 80px;
    background-repeat: no-repeat;
  }
`;

const BrowseImageButton = styled.input`
  display: none;
`;

const LabelForBrowseButton = styled.label`
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 4px;
  right: 0px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.lynch};
  border: 2px solid ${({ theme }) => theme.wildSand};
  cursor: pointer;
  text-align: center;

  > svg {
    width: 10px;
    height: 10px;

    > path {
      fill: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.lynch};
    }
  }
`;

const ImageWrapper = styled(Wrapper)`
  position: relative;
  max-width: 90px;
`;

export const ProfilePhotoContainer = ({ image }: { image?: string }) => {
  const dispatch = useAppDispatch();
  const [isLoadingUpdatedImage, setIsLoadingUpdatedImage] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [imageForCropping, setImageForCropping] = useState('');
  const [shouldDisplayToastSuccess, setShouldDisplayToastSuccess] =
    useState(false);
  const { register, reset } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const closeModal = () => {
    setOpenCropModal(false);
    reset();
  };

  const onSuccessImageUpload = (imageResponse: ImageResponse) => {
    dispatch({
      type: editUserDetailsActions.UPDATE_USER_WITH_PROFILE_PHOTO,
      payload: imageResponse,
      onSuccess: () => {
        setShouldDisplayToastSuccess(true);
      },
      onFailed: () => {
        toast.error("Wasn't able to save the image on the server.");
        setIsLoadingUpdatedImage(false);
      },
    });
  };

  const checkFileSizeAndType = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const { size, type } = event.target.files[0];

      const maxAllowedSizeInMB = 5;
      const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

      const imageSizeInMB = size / 1048576;

      if (!allowedFileTypes.includes(type)) {
        toast.error('Only JPG, JPGEG or PNG formats are supported.');
        setIsLoadingUpdatedImage(false);
      } else if (imageSizeInMB > maxAllowedSizeInMB) {
        toast.error('Images over 5 megabytes are not supported.');
        setIsLoadingUpdatedImage(false);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          // eslint-disable-next-line
          setImageForCropping(reader.result as any);
          setOpenCropModal(true);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  };

  const sendImageDataToTheServer = (imageData: File) => {
    setIsLoadingUpdatedImage(true);

    const data = new FormData();
    data.append('imageFile', imageData);

    dispatch({
      type: editUserDetailsActions.UPLOAD_PROFILE_PHOTO,
      payload: data,
      onSuccess: (imageResponse: ImageResponse) => {
        onSuccessImageUpload(imageResponse);
      },
      onFailed: () => {
        toast.error("Couldn't upload the photo to the server.");
        setIsLoadingUpdatedImage(false);
      },
    });
  };

  const onLoadedImage = () => {
    setIsLoadingUpdatedImage(false);
    closeModal();
    if (shouldDisplayToastSuccess) {
      toast.success('Your profile photo has been successfully updated.');
      setShouldDisplayToastSuccess(false);
    }
  };

  return (
    <>
      <MainContainer>
        <Wrapper flex column>
          <Form>
            <Wrapper flex center>
              <ImageWrapper>
                <Image
                  src={image || imagePlaceholder}
                  alt="Profile Photo"
                  onLoad={onLoadedImage}
                />
                <LabelForBrowseButton>
                  <BrowseImageButton
                    type="file"
                    {...register('selectedImage')}
                    onChange={checkFileSizeAndType}
                  />
                  <ImageIcon />
                </LabelForBrowseButton>
              </ImageWrapper>
            </Wrapper>
          </Form>
        </Wrapper>

        <ModalWrapper
          open={openCropModal}
          modal
          closeOnDocumentClick
          onClose={closeModal}
          closeOnEscape={false}
        >
          <Modal close={closeModal} withoutFooter={1} title="Profile Photo">
            <CropImageModal
              image={imageForCropping}
              onSave={sendImageDataToTheServer}
              isLoading={isLoadingUpdatedImage}
              onClose={closeModal}
            />
          </Modal>
        </ModalWrapper>
      </MainContainer>
    </>
  );
};

ProfilePhotoContainer.defaultProps = {
  image: '',
};
