import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { getWarnings, setWarnings } from 'order/warnings/store/warningsActions';
import { StyleWarning } from 'order/warnings/interface/Wranings';

import { PSmall } from 'shared/components/Typography';
import { StyleHeader } from 'shared/components/StyleHeader';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';

import StyleWarnings from './StyleWarnings';
import { ItemRow } from '../ItemRow';

const WarningsContainer = styled.div`
  height: calc(100% - 95px);
`;

const Warnings = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [warningsLoading, setWarningsLoading] = useState(true);

  const warnings = useSelector(
    (state: RootState) => state.warningsReducer.warnings
  );

  const filterEmptyStyles = (styleWarning: StyleWarning) => {
    const hasStyleWarnings = styleWarning.warnings.length > 0;

    const hasLineItemWarnings = styleWarning.lineItemWarnings.some(
      (lineItemWarning) =>
        lineItemWarning.reason.length > 0 ||
        lineItemWarning.warnings.length > 0 ||
        lineItemWarning.modificationWarnings.some(
          (modWarning) =>
            modWarning.reason.length > 0 || modWarning.warnings.length > 0
        )
    );

    return hasStyleWarnings || hasLineItemWarnings;
  };

  useEffect(() => {
    dispatch(getWarnings(orderId, setWarningsLoading));

    return () => {
      dispatch(setWarnings(null));
    };
  }, []);

  return warningsLoading ? (
    <Loader />
  ) : (
    <WarningsContainer>
      <ScrollbarsCustom
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        dataEmptyState="No Warnings."
      >
        {warnings && warnings?.warnings?.length > 0 && (
          <div>
            <StyleHeader fontWeight={600} fontSize={12}>
              Order Warnings
            </StyleHeader>
          </div>
        )}

        {warnings?.warnings.map((orderWarning) => (
          <ItemRow key={`${orderWarning.entityId}`}>
            <PSmall fontWeight={600}>{orderWarning.message}</PSmall>
          </ItemRow>
        ))}

        {warnings?.styleWarnings
          .filter(filterEmptyStyles)
          .map((styleWraning) => (
            <StyleWarnings key={styleWraning.id} styleWraning={styleWraning} />
          ))}
      </ScrollbarsCustom>
    </WarningsContainer>
  );
};

export default Warnings;
