export enum PopupEntityType {
  LineItem = 10,
  Modification = 20,
  InsertPanel = 30,
  DoorEdge = 40,
  DrawerStyle = 50,
  Door = 60,
  Wood = 70,
  FinishColor = 80,
  SpecialFinishSample = 90,
  ToeSpace = 100,
  FinishedEnd = 110,
  HingeType = 120,
}
