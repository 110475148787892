import { FC } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info-outlined.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/info-warning.svg';

import { Tooltip } from 'shared/components/Tooltip';
import { altoTwo, anzac, black, silverChaliceTwo } from 'shared/config/Colors';

import { testId } from 'tests/utils';

import SVG from './SVG';
import { Spacer } from './Layout';
import { Wrapper } from './Wrapper';

type InfoType = 'warning' | 'info' | 'danger';

interface InfoProps {
  type?: InfoType;
  fieldTestId?: string;
  withBackground?: boolean;
}

const InfoStyled = styled(Wrapper)<InfoProps>`
  display: inline-flex;
  position: relative;
  color: ${({ theme }) => theme.silverChaliceTwo};
  margin: -2px;
  font-size: 14px;
  font-weight: 600;

  ${({ withBackground }) =>
    withBackground &&
    css`
      background-color: ${({ theme }) => theme.wildSandTwo};
      padding: 8px 10px;
      border-radius: 4px;
    `}

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const getInfoIconAndColor = (iconType?: InfoType) => {
  if (iconType) {
    switch (iconType) {
      case 'warning':
        return { icon: <WarningIcon />, color: anzac, textColor: anzac };
      case 'info':
        return {
          icon: <InfoIcon />,
          color: silverChaliceTwo,
          textColor: black,
        };
      default:
        return { icon: <InfoIcon />, color: altoTwo, textColor: altoTwo };
    }
  }

  return null;
};

const InfoBody = styled.div`
  ${({ color }) => color && `color: ${color}`}
`;

const Info: FC<InfoProps> = ({
  children,
  type,
  fieldTestId,
  withBackground,
}) => {
  return (
    <InfoStyled
      {...(fieldTestId && testId(fieldTestId))}
      type={type}
      flex
      middle
      withBackground={withBackground}
    >
      <SVG
        icon={getInfoIconAndColor(type)?.icon}
        color={getInfoIconAndColor(type)?.color}
      />

      {children && type && (
        <>
          <Spacer w="10px" />
          <InfoBody color={getInfoIconAndColor(type)?.textColor}>
            {children}
          </InfoBody>
        </>
      )}
    </InfoStyled>
  );
};

Info.defaultProps = {
  type: 'info',
};

export { Info };
