import { getNotifsCounts } from 'notifications/store/notificationsActions';
import { useEffect, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';

interface NotifsCountCheckOptions {
  interval: number;
}

export const useNotifsCountCheck = (options: NotifsCountCheckOptions) => {
  const [intervalId, setIntervalId] = useState<null | number>(null);
  const [subscribed, setSubscribed] = useState(false);

  const dispatch = useAppDispatch();

  const getNotificationsCount = () => {
    dispatch(getNotifsCounts());
  };

  const unsubscribe = () => {
    if (intervalId) window.clearInterval(intervalId);
    setSubscribed(() => false);
  };

  useEffect(() => {
    getNotificationsCount();
  }, []);

  useEffect(() => {
    if (!subscribed) {
      const interval = window.setInterval(
        getNotificationsCount,
        options.interval
      );

      setIntervalId(interval);
    }

    return () => {
      unsubscribe();
    };
  }, [getNotificationsCount, subscribed]);

  useEffect(() => {
    if (intervalId) {
      setSubscribed(() => true);
    }
  }, [intervalId]);

  return undefined;
};
