import { FC, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { IFile } from 'shared/interface/IFile';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';

import { AttachmentsModalStepsEnums } from './enums/AttachmentsModalStepsEnums';
import { clearAttachmentsModal } from './store/attachmentsModalActions';
import { IAttachment } from '../../interface/IAttachment';
import AttachmentForm from './AttachmentForm';
import AttachmentsComplete from './AttachmentComplete';
import AttachmentsModalHeader from './AttachmentModalHeader';

interface AttachmentsModalProps {
  closeModal: () => void;
  onModalClose: (files: IFile[], selectedAttachments: IAttachment[]) => void;
  lineItemConfiguration?: boolean;
}

const AttachmentsModal: FC<AttachmentsModalProps> = ({
  closeModal,
  onModalClose,
  lineItemConfiguration,
}) => {
  const dispatch = useAppDispatch();

  const activeStep = useSelector(
    (state: RootState) => state.attachmentsModalReducer.activeStep
  );

  useEffect(() => {
    return () => {
      dispatch(clearAttachmentsModal());
    };
  }, []);

  return (
    <Wrapper>
      {/* TODO for every step render component */}
      <AttachmentsModalHeader />

      {(activeStep === AttachmentsModalStepsEnums.ADD_ATTACHMENTS ||
        activeStep === AttachmentsModalStepsEnums.UPDATE_ATTACHMENT) && (
        <AttachmentForm lineItemConfiguration={lineItemConfiguration} />
      )}

      {activeStep === AttachmentsModalStepsEnums.COMPLETE_ATTACHMENTS && (
        <AttachmentsComplete
          onModalClose={onModalClose}
          closeModal={closeModal}
          lineItemConfiguration={lineItemConfiguration}
        />
      )}
    </Wrapper>
  );
};

AttachmentsModal.defaultProps = {
  lineItemConfiguration: false,
};

export default AttachmentsModal;
