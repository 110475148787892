import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import FormError from 'shared/components/FormError';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import {
  firstNameValidation,
  lastNameValidation,
  phoneNumberValidation,
  pwwUsernameValidation,
} from 'shared/validations/validations';

import { editUserDetailsActions } from '../store/editUserDetailsActions';
import { ProfilePhotoContainer } from './ProfilePhotoContainer';

interface FormInputs {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  pwwUsername?: string;
}

const FormContainer = styled.div`
  max-width: 570px;
  margin: 0 auto;
`;

export const EditUserDetailsForm = () => {
  const [resetButtonDisabled, setResetButtonDisabled] = useState(true);
  const dispatch = useAppDispatch();

  const userData = useSelector((state: RootState) => state.authReducer.user);
  const canCreateCSRUser = useHasPermissions(
    userPermissionsValues.CSR_USER_CREATE
  );

  const defaultValues = {
    firstName: userData?.firstName ?? '',
    lastName: userData?.lastName ?? '',
    phoneNumber: userData?.phoneNumber ?? '',
    ...(canCreateCSRUser && { pwwUsername: userData?.pwwUsername ?? '' }),
  };

  const {
    getValues,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const watchAllFields = watch();

  const afterRequestCallBack = (status: string) => {
    if (status === 'SUCCESS') {
      toast.success('Your details have been successfully updated.');
    } else {
      toast.error('There has been error while trying to update your details.');
    }
  };

  const onSubmit = (formData: FormInputs) => {
    dispatch({
      type: editUserDetailsActions.UPDATE_USER_DETAILS,
      payload: formData,
      onSuccess: () => afterRequestCallBack('SUCCESS'),
      onFailed: () => afterRequestCallBack('ERROR'),
    });
  };

  useEffect(() => {
    /* Enable reset button if the form data is different from the data we recieved from the server and disable it if it's the same */
    const formValues = getValues();
    if (userData != null) {
      Object.entries(formValues).every(([key]) => {
        if (
          userData[key as keyof FormInputs] !==
          formValues[key as keyof FormInputs]
        ) {
          setResetButtonDisabled(false);
          return false;
        }
        setResetButtonDisabled(true);
        return true;
      });
    }
  }, [watchAllFields]);

  useEffect(() => {
    /* Populate fields from the redux store with user data */
    if (userData) {
      reset(defaultValues);
    }
  }, [userData, canCreateCSRUser]);

  return (
    <>
      <Spacer h="70px" />
      <FormContainer>
        <WhiteBox>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper flex center>
              <ProfilePhotoContainer
                image={userData != null ? userData.imageUrl : ''}
              />
            </Wrapper>
            <FormElement>
              <FormLabel>First name</FormLabel>
              <Input
                type="text"
                data-test="input-firstNameEdit"
                aria-invalid={errors.firstName ? 'true' : 'false'}
                placeholder="Enter your first name here..."
                {...register(
                  'firstName',
                  firstNameValidation({ required: true })
                )}
              />
              <FormError
                label="First name"
                error={errors.firstName}
                validationSchema={firstNameValidation({ required: true })}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Last name</FormLabel>
              <Input
                type="text"
                data-test="input-lastNameEdit"
                aria-invalid={errors.lastName ? 'true' : 'false'}
                placeholder="Enter your last name here..."
                {...register(
                  'lastName',
                  lastNameValidation({ required: true })
                )}
              />
              <FormError
                label="Last name"
                error={errors.lastName}
                validationSchema={lastNameValidation({ required: true })}
              />
            </FormElement>
            {canCreateCSRUser && (
              <FormElement>
                <FormLabel>PWW Username</FormLabel>
                <Input
                  placeholder="Enter your PWW username here..."
                  data-test="input-pww-username"
                  autoComplete="off"
                  aria-invalid={errors.pwwUsername ? 'true' : 'false'}
                  {...register(
                    'pwwUsername',
                    pwwUsernameValidation({ required: false })
                  )}
                  disabled
                />
                <FormError
                  label="PWW Username"
                  error={errors.pwwUsername}
                  validationSchema={pwwUsernameValidation({ required: false })}
                />
              </FormElement>
            )}
            <FormElement>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="text"
                data-test="input-phoneEdit"
                aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                placeholder="Enter your phone..."
                {...register(
                  'phoneNumber',
                  phoneNumberValidation({ required: true })
                )}
              />
              <FormError
                label="Phone number"
                error={errors.phoneNumber}
                validationSchema={phoneNumberValidation({ required: true })}
              />
            </FormElement>
            <Wrapper flex justifyEnd>
              <ButtonSecondary
                data-test="button-Reset"
                onClick={() => userData && reset(userData)}
                disabled={resetButtonDisabled}
                type="button"
              >
                Revert
              </ButtonSecondary>
              <Spacer w="20px" />
              <ButtonPrimary
                data-test="button-Edit"
                type="submit"
                disabled={!isDirty}
              >
                Update
              </ButtonPrimary>
            </Wrapper>
          </Form>
        </WhiteBox>
      </FormContainer>
    </>
  );
};
