export enum SpecificationsOptionsEnum {
  CONFIGURE_WOOD = '1',
  SELECT_IMPRESSION = '2',
  TYPE_SW = 'SW',
  WOOD_TYPE_PAINT = 'Paint',
  WOOD_TYPE_CHAR_CHERRY = 'Character Cherry',
  WOOD_EFFECT_CHAR_CHERRY_DIST = 'Char Cherry Dist',
  COMP = 'COMP',
  TYPE_SWP = 'SWP',
}
