import { call, takeEvery, put } from 'redux-saga/effects';

import { PriceSchedule } from 'order/wizard/orderStyles/interface/PriceSchedule';
import { setAdjustmentsData } from 'order/store/orderActions';

import {
  orderStylesAdjustmentsActions,
  setStyleAdjustmentsData,
} from 'order/wizard/orderStyles/productLines/store/adjustments/orderStylesAdjustmentsActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

import {
  OrderStylesAdjustments,
  OrderStylesAdjustmentsRequest,
} from '../../../interface/Adjustments';

function* saveAdjustments(action: Action<OrderStylesAdjustmentsRequest>) {
  try {
    const {
      orderId,
      styleId,
      operationType,
      orderWithoutStyle,
      goBack,
      ...rest
    } = action.payload!;

    const adjustmentsData = {
      ...rest,
    } as OrderStylesAdjustments;

    if (orderWithoutStyle) {
      yield call(
        ApiService.put,
        `/api/order/orders/${orderId}/adjustment/${operationType}`,
        adjustmentsData
      );

      yield put(setAdjustmentsData(adjustmentsData));
    } else {
      yield call(
        ApiService.put,
        `/api/order/orders/${orderId}/styles/${styleId}/adjustment/${operationType}`,
        adjustmentsData
      );

      if (goBack) {
        yield put(setStyleAdjustmentsData(adjustmentsData));
      }
    }

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getPriceShedules(action: Action<unknown>) {
  try {
    const priceSchedules: PriceSchedule[] = yield call(
      ApiService.get,
      '/api/catalog/priceschedules'
    );

    const priceScheduleOptions = UtilService.mapObjectToSelectOptions(
      priceSchedules,
      'dateEffective',
      'version'
    ) as SelectOptionProps[];

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, priceScheduleOptions);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderStylesAdjustmentsSagas() {
  yield takeEvery(
    orderStylesAdjustmentsActions.CREATE_ADJUSTMENT,
    saveAdjustments
  );
  yield takeEvery(
    orderStylesAdjustmentsActions.GET_PRICE_SCHEDULES,
    getPriceShedules
  );
}
