import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';

import { OrderFilterValuesRipped } from 'order/interfaces/OrderFilterValues';
import { OrderTypeEnums } from 'order/enums/orderEnums';

import {
  SortDirection,
  TableColumns,
  TableTabsEnums,
} from 'order/enums/orderTableEnums';

import { OrdersTable } from 'overview/components/OrdersTable';
import { getOrdersForImport } from 'overview/orders/store/ordersActions';

import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import SearchInput from 'shared/components/SearchInput';
import SwitchButtonDynamic from 'shared/components/SwitchButtonDynamic';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInputSearch } from 'shared/hooks/useInputSearch';

import {
  CsrOrderTableRequest,
  OrderTableRequest,
} from 'shared/interface/OrderRow';

export const ImportContainer = styled(Wrapper)`
  height: 640px;
`;

const ImportSearchOrders = () => {
  const dispatch = useAppDispatch();

  const switchButtons = [
    { label: 'Orders', value: '' },
    {
      label: 'Quotes',
      value: OrderTypeEnums.Quote.toString(),
    },
  ] as SelectOptionProps[];

  const [search, setSearch] = useState<OrderFilterValuesRipped>({
    order: '',
    sortColumn: TableColumns.NUMBER,
    sortDirection: SortDirection.DESC,
    page: 1,
    excludeOrdersWithoutStyle: true,
    orderType: switchButtons[0],
  });

  const [tableLoading, setTableLoading] = useState(false);
  const [tableFullscreenLoading, setTableFullscreenLoading] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [query, onChange, loading, setLoading] = useInputSearch();

  const onOrdersLoadSuccessHandler = () => {
    setLoading(false);
    setTableLoading(false);
    setTableFullscreenLoading(false);
  };

  useEffect(() => {
    if (search.page !== 1) {
      setTableFullscreenLoading(true);
      setSearch((prevState) => ({
        ...prevState,
        page: 1,
      }));
    }
  }, [query]);

  useEffect(() => {
    setTableLoading(true);

    const payload: OrderTableRequest | CsrOrderTableRequest = {
      page: search.page,
      itemsPerPage: 10,
      searchTerm: query,
      sortColumn: search.sortColumn,
      sortDirection: search.sortDirection,
      collaboratingOn: false,
      excludeOrdersWithoutStyle: search.excludeOrdersWithoutStyle,
      ...(search.orderType && { type: +search.orderType.value }),
      showCancelledOrders: true,
      showNewCollaboration: false,
    };

    dispatch(
      getOrdersForImport(payload, undefined, onOrdersLoadSuccessHandler)
    );
  }, [search, query]);

  return (
    <ImportContainer>
      <SearchInput
        data-role="search"
        data-loading={loading}
        data-test="input-stylesSearch"
        placeholder="Search for orders ..."
        ref={searchInputRef}
        onSearchQueryChanged={(e) => onChange(e.target.value)}
      />

      <Spacer h="15px" />

      <SwitchButtonDynamic
        setSearch={setSearch}
        search={search}
        buttons={switchButtons}
      />

      <Spacer h="15px" />

      <Wrapper position="relative">
        <OrdersTable
          isLoading={tableLoading || loading}
          setSearch={setSearch}
          search={search}
          tab={TableTabsEnums.IMPORT_STYLE}
        />

        {tableFullscreenLoading && (
          <LoaderFullScreen flex middle center>
            <Loader size={40} />
          </LoaderFullScreen>
        )}
      </Wrapper>
    </ImportContainer>
  );
};

export default ImportSearchOrders;
