import { FC } from 'react';

import { H2, P, SPAN } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { ACKSheetHint, ACKTypeSheetContainer } from '../ACKTypeSheetContainer';
import { ACKTypeSheetProps } from '../../interface/ACKTypeSheetProps';
import ApprovedACKIcon from '../ApprovedACKIcon';
import { ACKTypeEnum } from '../../enums/ACKTypeEnum';

const ACKTypeSheet2: FC<ACKTypeSheetProps> = ({ approved, version }) => {
  return (
    <ACKTypeSheetContainer
      data-testid={`ack-sheet--${ACKTypeEnum['Special Order Approval With Acknowledgement']}`}
    >
      <Wrapper inlineFlex>
        <H2 fontSize={24}>
          Special Order Approval With Acknowledgment
          {(version ?? 0) > 1 ? ` v${version}` : ''}
        </H2>

        {approved && (
          <ApprovedACKIcon
            data-testid={`ack-sheet-approved--${ACKTypeEnum['Special Order Approval With Acknowledgement']}`}
          />
        )}
      </Wrapper>

      <Spacer h="24px" />

      <P>
        Please review the acknowledgement immediately. <br />
        Your approval will be implied 2 working days from the date of this
        acknowledgment.
      </P>

      <Spacer h="12px" />

      <ACKSheetHint>
        Note: Any delay in returning this signed form will delay ordering the
        outsourced item(s) which may result in an extended delivery. Once the
        order for the approved outsourced item(s) has been placed by Plato, any
        change orders requiring a change/deletion of the outsourced item(s) will
        be the responsibility of the dealer.
      </ACKSheetHint>

      <Spacer h="12px" />

      <P>
        <SPAN>Changes before order processing has begun</SPAN> In most cases, no
        charge
      </P>

      <P>
        <SPAN>Changes after order processing has begun</SPAN>$100 list
      </P>

      <P>
        <SPAN>Changes after drawing process has begun</SPAN> $200 list minimum
      </P>

      <P>
        <SPAN>Changes after door chart has been released</SPAN> 25% of the basic
        cabinets with doors or drawers
      </P>

      <P>
        <SPAN>Changes after the mill parts have been released</SPAN> 50% of the
        basic cabinets
      </P>

      <Spacer h="24px" />

      <P>
        Changes may not be made after the cabinets are assembled. You may choose
        to have the cabinets <br />
        delivered unfinished to use at a later date.
      </P>
    </ACKTypeSheetContainer>
  );
};

export default ACKTypeSheet2;
