import { v4 as uuidv4 } from 'uuid';
import { FC } from 'react';

import { DoorCodePrice } from 'order/wizard/orderStyles/interface/OrderPriceReview';

import { ContainerSmall, ContainerXSmall } from 'shared/components/Container';
import { H6, PSmall } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import ElipsisText from 'shared/components/ElipsisText';

interface DoorCodesRowProps {
  codes?: DoorCodePrice[];
  isShowCatalogChecked: boolean;
  isListPriceSelected: boolean;
  title: string;
  isRevolae: boolean;
}

const DoorCodesRow: FC<DoorCodesRowProps> = ({
  codes,
  isShowCatalogChecked,
  isListPriceSelected,
  title,
  isRevolae,
}) => {
  const getTotalUpcharge = (doorCodePriceReview: DoorCodePrice) => {
    const qty =
      doorCodePriceReview.quantity || doorCodePriceReview.catalogQuantity;

    return (doorCodePriceReview.upcharge ?? 0) * qty;
  };

  return (
    <>
      {codes
        ?.filter(
          (doorCodePriceReview) =>
            doorCodePriceReview.quantity + doorCodePriceReview.catalogQuantity >
            0
        )
        ?.map((doorCodePriceReview, index) => (
          <Wrapper
            key={`${doorCodePriceReview.code}--${uuidv4()}`}
            flex
            stretch
          >
            <ContainerXSmall minWidth={50} isTableCell align="left">
              {index === 0 ? <H6>{title.toUpperCase()}</H6> : <H6>&nbsp;</H6>}
            </ContainerXSmall>

            <ContainerXSmall
              isTableCell
              border={['right']}
              minWidth={59}
              maxWidth={59}
            >
              <PSmall wordBreak="break-all">{doorCodePriceReview.code}</PSmall>
            </ContainerXSmall>

            {isShowCatalogChecked && (
              <ContainerXSmall isTableCell border={['right']}>
                <PSmall>{doorCodePriceReview.catalogQuantity}</PSmall>
              </ContainerXSmall>
            )}

            <ContainerXSmall isTableCell border={['right']}>
              <PSmall>{doorCodePriceReview.quantity}</PSmall>
            </ContainerXSmall>

            <ContainerXSmall isTableCell border={['right']}>
              <PSmall>${doorCodePriceReview.upcharge}</PSmall>
            </ContainerXSmall>

            {/* <ContainerXSmall isTableCell border={['right']} maxWidth={40}>
              <Wrapper
                flex
                flexWrap
                middle
                justifyEnd
                withTooltip={
                  (doorCodePriceReview.upcharge ?? 0) > 0 &&
                  !!doorCodePriceReview.upchargeReason?.length
                }
                position="relative"
              >
                <PSmall>
                  ${doorCodePriceReview.upcharge}
                  {(doorCodePriceReview.upcharge ?? 0) > 0 &&
                    doorCodePriceReview.upchargeReason?.length && (
                      <Wrapper position="absolute" top={-9} right={-11} flex>
                        <SVG
                          icon={<InfoIcon width={12} height={12} />}
                          color={silverChalice}
                        />
                      </Wrapper>
                    )}
                </PSmall>

                <Tooltip position="right">
                  {doorCodePriceReview.upchargeReason}
                </Tooltip>
              </Wrapper>
            </ContainerXSmall> */}

            <ContainerXSmall isTableCell border={['right']}>
              <PSmall>${getTotalUpcharge(doorCodePriceReview)}</PSmall>
            </ContainerXSmall>

            <ContainerXSmall
              isTableCell
              border={['right']}
              maxWidth={isShowCatalogChecked && isRevolae ? 43 : 120}
              minWidth={isShowCatalogChecked && isRevolae ? 43 : 120}
              align="left"
            >
              <PSmall>
                <ElipsisText>{doorCodePriceReview.upchargeReason}</ElipsisText>
              </PSmall>
            </ContainerXSmall>

            {isRevolae && (
              <ContainerSmall flexGrow isTableCell border={['right']}>
                <PSmall>
                  {(doorCodePriceReview.squareFootTotal ?? 0).toFixed(2)}
                </PSmall>
              </ContainerSmall>
            )}

            {/* catalog */}
            {isShowCatalogChecked && (
              <>
                <ContainerSmall flexGrow isTableCell border={['right']}>
                  <PSmall>
                    {isListPriceSelected
                      ? doorCodePriceReview.catalogUnitPrice.toFixed(2)
                      : doorCodePriceReview.catalogNetUnitPrice.toFixed(2)}
                  </PSmall>
                </ContainerSmall>

                <ContainerSmall isTableCell border={['right']}>
                  <PSmall>
                    {isListPriceSelected
                      ? doorCodePriceReview.catalogListPrice.toFixed(2)
                      : doorCodePriceReview.catalogNetPrice.toFixed(2)}
                  </PSmall>
                </ContainerSmall>
              </>
            )}
            {/* catalog */}

            {/* dealer */}
            <ContainerSmall
              flexGrow={isRevolae ? undefined : !isShowCatalogChecked}
              isTableCell
              border={['right']}
            >
              <PSmall>
                {isListPriceSelected
                  ? doorCodePriceReview.unitPrice.toFixed(2)
                  : doorCodePriceReview.netUnitPrice.toFixed(2)}
              </PSmall>
            </ContainerSmall>

            <ContainerSmall isTableCell>
              <PSmall>
                {isListPriceSelected
                  ? doorCodePriceReview.listPrice?.toFixed(2) ??
                    doorCodePriceReview.catalogListPrice.toFixed(2)
                  : doorCodePriceReview.netPrice?.toFixed(2) ??
                    doorCodePriceReview.catalogNetPrice.toFixed(2)}
              </PSmall>
            </ContainerSmall>
            {/* dealer */}
          </Wrapper>
        ))}
    </>
  );
};

export default DoorCodesRow;
