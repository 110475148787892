import styled, { css } from 'styled-components';

import {
  Position,
  Sides,
  TextAlign,
  WordBreak,
} from 'shared/interface/CSSTypes';

import { Tooltip } from './Tooltip';

export interface WrapperProps {
  // flex props
  flex?: boolean;
  inlineFlex?: boolean;
  middle?: boolean;
  center?: boolean;
  justifyStart?: boolean;
  between?: boolean;
  around?: boolean;
  evenly?: boolean;
  justifyEnd?: boolean;
  alignEnd?: boolean;
  alignStart?: boolean;
  flexWrap?: boolean;
  column?: boolean;
  stretch?: boolean;
  flexGrow?: boolean;
  gap?: number;
  order?: number;

  // width props
  wFull?: boolean;
  wThird?: boolean;
  wHalf?: boolean;
  maxWidth?: number | string;
  minWidth?: number;
  height?: number;
  width?: number;
  // typography props
  wordBreak?: WordBreak;

  // position props
  position?: Position;

  // margin props
  mrAuto?: boolean;
  mlAuto?: boolean;
  mtAuto?: boolean;
  mbAuto?: boolean;

  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;

  right?: number;
  left?: number;
  top?: number;
  bottom?: number;

  disabled?: boolean;

  heightOfScreen?: string | number;

  last?: boolean;

  border?: Sides[];

  isTableCell?: boolean;
  isTableHeadCell?: boolean;

  align?: TextAlign;

  noVertialSpacing?: boolean;

  withTooltip?: boolean;
  tooltipDelay?: number;

  pointer?: boolean;
  cursorDefault?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ onClick, pointer }) => (onClick || pointer) && 'cursor: pointer;'}
  ${({ cursorDefault }) => cursorDefault && 'cursor: default;'}

  ${({ color }) => color && `color: ${color};`}

  // flex setup
  ${({ flex }) => flex && 'display: flex;'}
  ${({ inlineFlex }) => inlineFlex && 'display: inline-flex;'}
  ${({ middle }) => middle && 'align-items: center;'}
  ${({ center }) => center && 'justify-content: center;'}
  ${({ justifyStart }) => justifyStart && 'justify-content: flex-start;'}
  ${({ around }) => around && 'justify-content: space-around;'}
  ${({ evenly }) => evenly && 'justify-content: space-evenly;'}
  ${({ between }) => between && 'justify-content: space-between;'}
  ${({ justifyEnd }) => justifyEnd && 'justify-content: flex-end;'}
  ${({ alignStart }) => alignStart && 'align-items: flex-start;'}
  ${({ alignEnd }) => alignEnd && 'align-items: flex-end;'}
  ${({ flexWrap }) => flexWrap && 'flex-wrap: wrap;'}
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ stretch }) => stretch && 'align-items: stretch;'}
  ${({ flexGrow }) => flexGrow && 'flex-grow: 1;'}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ order }) => order && `order: ${order};`}

  // width setup
  ${({ wFull }) => wFull && 'width: 100%;'}
  ${({ wHalf }) => wHalf && 'width: 50%;'}
  ${({ wThird }) => wThird && 'width: 33%;'}

  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}%;`}
  ${({ maxWidth }) =>
    maxWidth &&
    `max-width: ${maxWidth}${typeof maxWidth === 'number' && 'px'};`}
    
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}

  // typography setup
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak};`}

  // position setup
  ${({ position }) => (position ? `position: ${position};` : null)}

  // position setup in pixels
  ${({ right }) => right && `right: ${right}px;`}
  ${({ left }) => left && `left: ${left}px;`}
  ${({ top }) => top && `top: ${top}px;`}
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}

  // margin setup
  ${({ mrAuto }) => mrAuto && `margin-right: auto;`}
  ${({ mlAuto }) => mlAuto && `margin-left: auto;`}
  ${({ mtAuto }) => mtAuto && `margin-top: auto;`}
  ${({ mbAuto }) => mbAuto && `margin-bottom: auto;`}

  ${({ marginRight }) =>
    marginRight &&
    css`
      &:not(:last-child):not(:only-child) {
        margin-right: ${marginRight}px;
      }
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      &:not(:last-child):not(:only-child) {
        margin-left: ${marginLeft}px;
      }
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      &:not(:last-child):not(:only-child) {
        margin-top: ${marginTop}px;
      }
    `}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      &:not(:last-child):not(:only-child) {
        margin-bottom: ${marginBottom}px;
      }
    `}

  // disabled
  ${({ disabled }) => disabled && `pointer-events:none; opacity:0.4;`}

  // heihgt setup
  ${({ heightOfScreen }) => heightOfScreen && heightOfScreen}

  // align
  ${({ align, isTableCell, isTableHeadCell }) =>
    !isTableCell && !isTableHeadCell && align ? `text-align: ${align};` : ''}

  ${({
    border,
    isTableCell,
    isTableHeadCell,
    theme,
    align,
    noVertialSpacing,
  }) =>
    (isTableCell || isTableHeadCell) &&
    css`
      box-sizing: content-box;
      min-height: 16px;

      text-align: ${align ?? 'end'};

      ${(border ?? []).map((b) => `border-${b}: 1px solid ${theme.alabaster};`)}
      ${isTableCell &&
      `padding: ${noVertialSpacing ? '0px 16px' : '8px 16px'}`};
      ${isTableHeadCell && `padding: 5px 16px 8px;`}
    `}

  ${({ withTooltip, tooltipDelay }) =>
    withTooltip &&
    css`
      position: relative;

      &:hover {
        > ${Tooltip} {
          transition: opacity 300ms ${tooltipDelay ?? 0}ms ease;
          opacity: 1;
        }
      }
    `}


    &[hidden] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
