import { FC } from 'react';

import { ReactComponent as MoreInfoIcon } from 'assets/icons/vertical-dots.svg';

import { ButtonIcon } from 'shared/components/Button';
import { kashmirBlue, silverChaliceTwo } from 'shared/config/Colors';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { MoreInfoButton, MoreInfoContainer } from 'shared/components/MoreInfo';

import { ConfirmationModal } from './ConfirmationModal';
import { Wrapper } from './Wrapper';
import { Tooltip } from './Tooltip';

export interface MenuItem {
  label: string;
  action: (data?: unknown) => void;
  disabled?: boolean;
  requestCancellation?: boolean;
  color?: string;
  tooltipMessage?: string;
}

interface ThreeDotMenuProps {
  menuItems: MenuItem[];
}

const ThreeDotMenu: FC<ThreeDotMenuProps> = ({ menuItems }) => {
  return (
    <MenuWithDropdown
      nested
      trigger={
        <ButtonIcon iconColor={silverChaliceTwo} hoverIconColor={kashmirBlue}>
          <MoreInfoIcon />
        </ButtonIcon>
      }
    >
      {(close: () => void) => (
        <MoreInfoContainer flex column alignStart>
          {menuItems.map((menuItem) => {
            return menuItem.requestCancellation ? (
              <ConfirmationModal
                key={menuItem.label}
                trigger={
                  <MoreInfoButton disabled={menuItem.disabled}>
                    {menuItem.label}
                  </MoreInfoButton>
                }
                nested
                title="Are you sure?"
                buttonText={menuItem.label}
                message="This cancellation request will affect the Order process, the CSR still needs to determine the purpose of the cancellation."
                confirm={(closeChildModal: () => void) =>
                  menuItem.action(() => {
                    closeChildModal();
                    close();
                  })
                }
                cancel={() => close()}
              />
            ) : (
              <Wrapper key={menuItem.label} withTooltip={menuItem.disabled}>
                <MoreInfoButton
                  disabled={menuItem.disabled ?? false}
                  color={menuItem.color}
                  aria-atomic={menuItem.disabled}
                  key={menuItem.label}
                  onClick={() => {
                    menuItem.action();
                    close();
                  }}
                >
                  {menuItem.label}
                </MoreInfoButton>

                {menuItem.disabled && (
                  <Tooltip position="left">{menuItem.tooltipMessage}</Tooltip>
                )}
              </Wrapper>
            );
          })}
        </MoreInfoContainer>
      )}
    </MenuWithDropdown>
  );
};

export default ThreeDotMenu;
