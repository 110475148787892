import styled from 'styled-components';
import { FC, useEffect, useRef, useState } from 'react';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import PlatoLogoIcon from 'assets/icons/plato-logo.svg';

import { TabbableButton } from './Button';
import { Wrapper } from './Wrapper';
import Img from './Img';
import SVG from './SVG';

export interface Slide {
  id: string;
  url: string;
}

export interface SliderProps {
  activeSlideHeight?: number;
  items: Slide[];
  width: number;
}

type SliderStyledProps = Pick<SliderProps, 'width'>;

const StyledSlider = styled.div<SliderStyledProps>`
  width: ${({ width }) => width}px;
`;

const SliderNavigation = styled(Wrapper)``;

const SliderNavigationItems = styled(Wrapper)`
  flex: 1;
  overflow: hidden;
  width: 100%;
`;

const SliderNavigationItem = styled.img`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.mischka};
  cursor: pointer;
  display: block;
  margin: 8px;
  object-fit: cover;
  object-position: center;
  padding: 5px;
  transition: 300ms ease;

  &[data-hidden='true'] {
    opacity: 0;
  }

  &.active {
    border-color: ${({ theme }) => theme.nevada};
  }
`;

type ActiveSlideProps = Pick<SliderProps, 'activeSlideHeight'>;

const ActiveSlide = styled(Img)<ActiveSlideProps>`
  ${({ activeSlideHeight }) =>
    activeSlideHeight && `height: ${activeSlideHeight}px;`}

  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.mischka};
  display: block;
  margin-bottom: 8px;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  padding: 50px 20px;
  width: 100%;
`;

const TabbableButtonStyled = styled(TabbableButton)`
  align-items: center;
  color: ${({ theme }) => theme.lynch};
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;

  svg {
    height: 20px;
    width: 11px;
  }
`;

const Slider: FC<SliderProps> = ({ width, activeSlideHeight, items }) => {
  const [activeItem, setActiveItem] = useState<Slide>(items[0]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const navigationRef = useRef<HTMLDivElement>(null);

  const triggerScroll = (nextIndex: number) => {
    if (navigationRef) {
      const nav = navigationRef.current;

      nav?.scrollTo({
        left: nextIndex * 83 + 16 * nextIndex,
        behavior: 'smooth',
      });
    }
  };

  const onSlideChangeHandler = (item: Slide, index: number) => {
    setActiveItemIndex(index);
    triggerScroll(index);
    setActiveItem(item);
  };

  const onPrevHandler = () => {
    setActiveItemIndex((prev) => {
      const prevSlide = items.find((_, index) => index === prev - 1);

      if (prevSlide) {
        setActiveItem(prevSlide);
      }

      triggerScroll(prev - 1);

      return prev - 1;
    });
  };

  const onNextHandler = () => {
    setActiveItemIndex((next) => {
      const nextSlide = items.find((_, index) => index === next + 1);

      if (nextSlide) {
        setActiveItem(nextSlide);
      }

      triggerScroll(next + 1);

      return next + 1;
    });
  };

  useEffect(() => {
    if (items.length === 1) {
      setActiveItem(items[0]);
    }
  }, [items]);

  return (
    <StyledSlider width={width}>
      <ActiveSlide
        alt={activeItem.id}
        activeSlideHeight={activeSlideHeight}
        src={activeItem.url !== '' ? activeItem.url : PlatoLogoIcon}
      />

      {items.length > 1 && (
        <SliderNavigation flex middle>
          <TabbableButtonStyled
            onClick={onPrevHandler}
            disabled={activeItemIndex === 0}
          >
            <SVG icon={<ArrowLeftIcon />} color="inherit" />
          </TabbableButtonStyled>

          <SliderNavigationItems flex ref={navigationRef}>
            {items.map((item, index) => (
              <SliderNavigationItem
                src={item.url}
                key={item.id}
                data-hidden={index > activeItemIndex + 3}
                className={index === activeItemIndex ? 'active' : ''}
                width={83}
                height={83}
                alt=""
                onClick={() => onSlideChangeHandler(item, index)}
              />
            ))}
          </SliderNavigationItems>
          <TabbableButtonStyled
            onClick={onNextHandler}
            disabled={activeItemIndex === items.length - 1}
          >
            <SVG icon={<ArrowRightIcon />} color="inherit" />
          </TabbableButtonStyled>
        </SliderNavigation>
      )}
    </StyledSlider>
  );
};

export default Slider;
