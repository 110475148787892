import { FC } from 'react';
import styled from 'styled-components';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { H6, P } from 'shared/components/Typography';
import { mineShaft, nevada } from 'shared/config/Colors';
import { Wrapper } from 'shared/components/Wrapper';
import { Spacer } from 'shared/components/Layout';
import UtilService from 'shared/services/util.service';

const LineItemDimupContainer = styled(Wrapper)`
  ${BoxShadowCSS}
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  padding: 16px 32px;
  position: relative;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

interface LineItemDimupProps {
  dimUpPrice: number;
  dimUp: string;
}

const LineItemDimup: FC<LineItemDimupProps> = ({ dimUpPrice, dimUp }) => {
  return (
    <LineItemDimupContainer flex middle>
      <Spacer w="120px" />

      <Wrapper minWidth={165}>
        <P fontSize={12} color={mineShaft} bold>
          Special {UtilService.getSpecialDimensions(dimUp)}
        </P>
      </Wrapper>

      <Spacer w="238px" />

      <Wrapper flex column middle>
        <H6>Unit Price</H6>
        <P color={nevada} fontSize={10}>
          ${dimUpPrice}
        </P>
      </Wrapper>
    </LineItemDimupContainer>
  );
};

export default LineItemDimup;
