import { OrderStylePriceReview } from 'order/wizard/orderStyles/interface/OrderPriceReview';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useCalculateOrderTotals = () => {
  const priceReview = useSelector(
    (state: RootState) => state.orderReducer.priceReview
  );

  const getCatalogTotalSum = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.catalogTotalListSum
      : orderStylePriceReviewData?.catalogTotalNetSum;
  };

  const getTotalSum = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.totalListSum
      : orderStylePriceReviewData?.totalNetSum;
  };

  const getCatalogPremiumSelectionsSubtotal = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.catalogPremiumSelectionListTotal
      : orderStylePriceReviewData?.catalogPremiumSelectionNetTotal;
  };

  const getPremiumSelectionsSubtotal = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.premiumSelectionListTotal
      : orderStylePriceReviewData?.premiumSelectionNetTotal;
  };

  const getCatalogDoorsSubtotal = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.catalogDoorCodesListTotal
      : orderStylePriceReviewData?.catalogDoorCodesNetTotal;
  };

  const getDoorsSubtotal = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.doorCodesListTotal
      : orderStylePriceReviewData?.doorCodesNetTotal;
  };

  const getCatalogTotalPrice = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.catalogTotalListPrice
      : orderStylePriceReviewData?.catalogTotalNetPrice;
  };

  const getTotalPrice = (
    orderStylePriceReviewData: OrderStylePriceReview,
    isListPriceSelected: boolean
  ) => {
    return isListPriceSelected
      ? orderStylePriceReviewData?.totalListPriceForList
      : orderStylePriceReviewData?.totalListPriceForNet;
  };

  const calculateTotals = (styleId: string, isListPriceSelected: boolean) => {
    if (!priceReview) {
      return {
        catalogTotalSum: 0,
        totalSum: 0,
        catalogPremiumSelectionsSubtotal: 0,
        premiumSelectionsSubtotal: 0,
        catalogDoorsSubtotal: 0,
        doorsSubtotal: 0,
        catalogTotalPrice: 0,
        totalPrice: 0,
        excludedTotal: 0,
        excludedSum: 0,
        totalFreight: 0,
      };
    }

    const orderStylePriceReviewData = priceReview.orderStylePriceReview.find(
      (x) => x.styleId === styleId
    )!;

    return {
      catalogTotalSum: getCatalogTotalSum(
        orderStylePriceReviewData,
        isListPriceSelected
      ),
      totalSum: getTotalSum(orderStylePriceReviewData, isListPriceSelected),
      catalogPremiumSelectionsSubtotal: getCatalogPremiumSelectionsSubtotal(
        orderStylePriceReviewData,
        isListPriceSelected
      ),
      premiumSelectionsSubtotal: getPremiumSelectionsSubtotal(
        orderStylePriceReviewData,
        isListPriceSelected
      ),
      catalogDoorsSubtotal: getCatalogDoorsSubtotal(
        orderStylePriceReviewData,
        isListPriceSelected
      ),
      doorsSubtotal: getDoorsSubtotal(
        orderStylePriceReviewData,
        isListPriceSelected
      ),
      catalogTotalPrice:
        getCatalogTotalPrice(orderStylePriceReviewData, isListPriceSelected) ||
        0,
      totalPrice:
        getTotalPrice(orderStylePriceReviewData, isListPriceSelected) || 0,
      excludedTotal: orderStylePriceReviewData?.catalogExcludedListTotal,
      excludedSum: orderStylePriceReviewData?.catalogExcludedListSum,
      totalFreight: orderStylePriceReviewData?.totalFreight,
    };
  };

  return calculateTotals;
};
