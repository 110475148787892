import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as RemoveShoppingCartIcon } from 'assets/icons/removeShoppingCart.svg';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';

import {
  exportAsPDF,
  getOrderLineItemCLValues,
  getOrderPriceReviewSM,
  getOrderStatus,
  getUnresolvedTopics,
  submitOrder,
} from 'order/store/orderActions';

import { setCurtainTab } from 'curtain/store/curtainActions';
import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import UtilService from 'shared/services/util.service';
import { RadioButton } from 'shared/components/RadioButton';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { IFileData } from 'shared/interface/IFile';

import {
  ContainerMedium,
  ContainerSmall,
  ContainerXSmall,
} from 'shared/components/Container';
import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { H2, H6, PMedium, PSmall } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { PrintButton } from 'shared/components/PrintButton';
import { PrintModal } from 'shared/components/PrintModal';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Divider, Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { usePriorityChange } from 'shared/hooks/usePriorityChange';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';

import { ExpandableBox } from 'shared/components/ExpandableBox';
import OrderReviewPricingSMRow from './OrderReviewPricingSalesMaterialRow';
import TotalPriceRow from './components/TotalPriceRow';
import SubmitErrorModal from './components/SubmitErrorModal';
import { LineItemPriceReview } from '../orderStyles/interface/OrderPriceReview';

export const SwitchButton = styled(ButtonPrimary)`
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  border: none;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &.not-active {
    background: ${({ theme }) => theme.mischka};
  }
`;

const WhiteBox = styled.div`
  ${BoxShadowCSS};
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
  padding: 32px;
`;

const TotalPriceContainer = styled(Wrapper)`
  padding: 0 36px;
`;

interface OrderReviewPricingProps {
  isReviewPricing?: boolean;
}

const OrderReviewPricingSalesMaterial: FC<OrderReviewPricingProps> = ({
  isReviewPricing = true,
}) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<{ orderId: string }>();

  const [priceReviewLoading, setPriceReviewSMLoading] = useState(false);

  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [exportToPDFLoading, setExportToPDFLoading] = useState(false);
  const [openPDFExportModal, setOpenPDFExportModal] = useState<boolean>(false);
  const [noPricingSelected, setNoPricingSelected] = useState(false);

  const [submitErrorCodeModal, setSubmitErrorCodeModal] =
    useState<ErrorCodesEnum | null>(null);

  const { order, priceReviewSM, canEdit, numberOfUnresolvedTopics } =
    useSelector((state: RootState) => state.orderReducer);

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const { updateOrderPriority } = usePriorityChange();

  const loadOrderPriceReviewSM = () => {
    setPriceReviewSMLoading(true);
    dispatch(
      getOrderPriceReviewSM(
        { orderId, productLineId: customProductLine!.id },
        setPriceReviewSMLoading
      )
    );
  };

  const getTotalModPrice = (lineItem: LineItemPriceReview) => {
    return lineItem.modificationsPrice.reduce((prev, curr) => {
      return prev + curr.netPrice;
    }, 0);
  };

  const getTotalNetPrice = () => {
    return priceReviewSM?.lineItems
      ?.filter((x) => !x.isDeleted)
      .reduce((prev, curr) => {
        return prev + curr.netPrice + getTotalModPrice(curr);
      }, 0);
  };

  const print = (printOption: string, printType: string) => {
    console.log(printOption, printType);
  };

  const onSubmitOrderSuccessHandler = () => {
    updateOrderPriority();
    setOpenConfirmation(false);

    toast.success('You have successfully submitted the order.');

    // get latest order status
    dispatch(getOrderStatus(orderId));

    if (customProductLine) {
      dispatch(
        getOrderLineItemCLValues({
          orderId,
          isSalesMaterial: true,
          productLineId: customProductLine.id,
        })
      );
    }
  };

  const onExportToPDFSuccessHandler = (pdfSnapshot: IFileData) => {
    UtilService.convertFileToBlob(pdfSnapshot);
  };

  const onExportToPDFErrorHandler = (error: ServerErrorResponse) => {
    setExportToPDFLoading(false);
    UtilService.handleError({
      err: error,
    });
  };

  const onExportToPDFClickHandler = () => {
    if (!order?.id) return;

    setExportToPDFLoading(true);

    dispatch(
      exportAsPDF(
        { noPricing: noPricingSelected, orderId: order.id! },
        onExportToPDFSuccessHandler,
        onExportToPDFErrorHandler,
        setExportToPDFLoading
      )
    );
    setOpenPDFExportModal(false);
  };

  const onSubmitOrderFailedHandler = (err: ServerErrorResponse) => {
    setOpenConfirmation(false);
    setSubmitErrorCodeModal(err.type);
  };

  const submitOrderHandler = () => {
    dispatch(
      submitOrder(
        { orderId, isAsap: false },
        onSubmitOrderSuccessHandler,
        onSubmitOrderFailedHandler
      )
    );
  };

  const cancelSubmitOrder = () => {
    if (numberOfUnresolvedTopics > 0) {
      dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
    }
    setOpenConfirmation(false);
  };

  useEffect(() => {
    if (customProductLine) {
      loadOrderPriceReviewSM();

      if (order?.status?.id === OrderStatusEnums['Not Submitted']) {
        dispatch(getUnresolvedTopics(orderId));
      }
    }
  }, [customProductLine]);

  const submitOrderMessage =
    numberOfUnresolvedTopics > 0
      ? '<p>You have unresolved topics in collaboration. Would you like to resolve them first or make the submission anyway?</p>'
      : '<p>This order will be submitted to Plato Woodwork. An order confirmation will be sent to you.</p><br /><p><b style="color: #677D9E;">Reminder:</b> Incomplete or inaccurate information on orders will affect what ship week you receive. </p>';

  return (
    <>
      {isReviewPricing && (
        <Wrapper>
          <H2>Review Pricing</H2>
          <Spacer h="6px" />
          <PMedium color={nevada}>See the following categories.</PMedium>
        </Wrapper>
      )}
      {isReviewPricing && isUserDealerOrSalesRep && (
        <Wrapper flex justifyEnd middle>
          <ButtonPrimary
            onClick={() => setOpenPDFExportModal(true)}
            disabled={exportToPDFLoading}
          >
            <Wrapper flex middle>
              Export to PDF
              <Loader
                hidden={!exportToPDFLoading}
                size={16}
                insideButton
                noSpacing
              />
            </Wrapper>
          </ButtonPrimary>
        </Wrapper>
      )}
      <Spacer h="16px" />
      <Divider />

      {priceReviewLoading && <Loader size={40} />}

      {!priceReviewLoading &&
        priceReviewSM &&
        !priceReviewSM.lineItems?.length && (
          <EmptyState
            title="No line items."
            icon={<RemoveShoppingCartIcon />}
            message="Please add some line items to the order."
          />
        )}

      {!priceReviewLoading &&
        priceReviewSM &&
        priceReviewSM.lineItems?.length > 0 && (
          <>
            {/* PLAT-2822 | not implemented yet */}
            {isReviewPricing && false && (
              <Wrapper flex justifyEnd middle>
                <PrintButton onClick={() => setOpenPrintModal(true)}>
                  <PrintIcon />
                  <Spacer w="8px" />
                  <PMedium>Print</PMedium>
                </PrintButton>
              </Wrapper>
            )}

            <Spacer h="32px" />

            <WhiteBox>
              <Wrapper flex>
                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>PWW#</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>CL#</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>L#</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>Qty</PSmall>
                </ContainerSmall>

                <ContainerMedium
                  align="left"
                  isTableHeadCell
                  flexGrow
                  border={['bottom', 'right']}
                >
                  <PSmall bold>LINE ITEMS</PSmall>
                </ContainerMedium>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>Unit$</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom']}>
                  <PSmall bold>Net$</PSmall>
                </ContainerSmall>
              </Wrapper>

              {priceReviewSM.lineItems
                .filter((x) => !isReviewPricing || !x.isDeleted)
                .map((lineItem) => (
                  <OrderReviewPricingSMRow
                    lineItem={lineItem}
                    isReviewPricing={isReviewPricing}
                  />
                ))}

              <TotalPriceRow title="total" netTotal={getTotalNetPrice()} />
            </WhiteBox>

            {priceReviewSM && priceReviewSM?.totalNetDiscountForNet !== 0 && (
              <>
                <Spacer h="32px" />
                <WhiteBox>
                  <Wrapper flex middle>
                    <Wrapper
                      flex
                      middle
                      isTableHeadCell
                      minWidth={150}
                      border={['bottom']}
                      flexGrow
                    >
                      <H6>NET UPCHARGES/DISCOUNTS</H6>
                    </Wrapper>
                    <ContainerXSmall
                      isTableHeadCell
                      border={['bottom', 'right']}
                    >
                      <PSmall bold>%</PSmall>
                    </ContainerXSmall>

                    <ContainerSmall isTableHeadCell border={['bottom']}>
                      <PSmall bold>Net$</PSmall>
                    </ContainerSmall>
                  </Wrapper>
                  <Wrapper flex middle>
                    <Wrapper align="left" minWidth={150} isTableCell flexGrow>
                      <H6>NET DISCOUNT</H6>
                    </Wrapper>

                    <ContainerXSmall isTableCell border={['right']}>
                      <PSmall>
                        {priceReviewSM?.netDiscountPercentageForNet ?? 0}%
                      </PSmall>
                    </ContainerXSmall>

                    <ContainerSmall isTableCell>
                      <PSmall>
                        {priceReviewSM?.totalNetDiscountForNet.toFixed(2) ??
                          '0.00'}
                      </PSmall>
                    </ContainerSmall>
                  </Wrapper>

                  <Wrapper flex middle>
                    <Wrapper
                      align="left"
                      minWidth={150}
                      isTableCell
                      flexGrow
                      border={['top']}
                    >
                      <H6>SUBTOTAL</H6>
                    </Wrapper>

                    <ContainerSmall isTableCell border={['top']}>
                      <PSmall>
                        {priceReviewSM?.totalNetDiscountForNet.toFixed(2) ??
                          '0.00'}
                      </PSmall>
                    </ContainerSmall>
                  </Wrapper>
                </WhiteBox>
              </>
            )}

            <TotalPriceContainer>
              <Spacer h="60px" />

              <Divider />

              {priceReviewSM?.noCharge ? (
                <>
                  <Spacer h="16px" />
                  <Wrapper flex middle between>
                    <H6>NO CHARGE</H6>
                    <H6>
                      {priceReviewSM?.totalListPriceNoChargeForNet.toFixed(2) ??
                        '0.00'}
                    </H6>
                  </Wrapper>
                </>
              ) : undefined}
              <Spacer h="16px" />
              <Wrapper flex middle between>
                <H6>TOTAL SALES MATERIALS</H6>
                <H6>
                  {priceReviewSM?.totalListPriceForNet.toFixed(2) ?? '0.00'}
                </H6>
              </Wrapper>
              <Spacer h="24px" />
            </TotalPriceContainer>

            <Spacer h="32px" />

            <Divider />

            <ExpandableBox
              title="Total Pricing"
              description="Summary of prices"
            >
              <WhiteBox>
                <Wrapper flex middle>
                  <ContainerMedium
                    isTableHeadCell
                    flexGrow
                    border={['bottom']}
                  />

                  <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                    <PSmall bold>Net$</PSmall>
                  </ContainerSmall>
                </Wrapper>

                <Wrapper flex middle>
                  <ContainerMedium isTableCell mrAuto>
                    <H6>TOTAL SALES MATERIALS</H6>
                  </ContainerMedium>

                  <ContainerSmall isTableCell border={['right']}>
                    <PSmall> </PSmall>
                  </ContainerSmall>

                  <ContainerSmall isTableCell>
                    <PSmall>
                      {priceReviewSM?.totalListPriceForNet.toFixed(2) ?? '0.00'}
                    </PSmall>
                  </ContainerSmall>
                </Wrapper>

                {/* <Wrapper flex middle>
                  <ContainerMedium mrAuto isTableCell>
                    <H6>
                      FREIGHT {priceReviewSM.noFreight ? '(NO FREIGHT)' : ''}{' '}
                    </H6>
                  </ContainerMedium>

                  <ContainerSmall isTableCell border={['right']}>
                    <PSmall> </PSmall>
                  </ContainerSmall>

                  <ContainerSmall isTableCell border={['right']}>
                    <PSmall> </PSmall>
                  </ContainerSmall>

                  <ContainerSmall isTableCell>
                    <PSmall>{priceReviewSM.totalNetFreight.toFixed(2)}</PSmall>
                  </ContainerSmall>
                </Wrapper> */}
                <Wrapper flex middle>
                  <ContainerMedium isTableCell mrAuto>
                    <H6>*FREIGHT CHARGES APPLIED AT INVOICING</H6>
                  </ContainerMedium>
                </Wrapper>
                <TotalPriceRow
                  title="total dealer cost"
                  netTotal={priceReviewSM.totalNetCost}
                />
              </WhiteBox>
            </ExpandableBox>

            {priceReviewSM &&
              isUserDealerOrSalesRep &&
              (order?.status?.id ?? 0) >= OrderStatusEnums.Received &&
              order?.acknowledgementId === null &&
              order?.specialOrderApproval === null && (
                <>
                  <Spacer h="32px" />
                  <PMedium>
                    This order has been submitted and awaits the response from
                    the CSR.
                  </PMedium>
                </>
              )}
          </>
        )}

      {order?.status?.id === OrderStatusEnums['Not Submitted'] && (
        <>
          <OrderLogs />

          <OrderFooter>
            <Wrapper flex middle justifyEnd>
              <ButtonPrimary
                onClick={() => setOpenConfirmation(true)}
                disabled={priceReviewLoading || !canEdit}
              >
                Submit
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </>
      )}

      <PrintModal
        opened={openPrintModal}
        onClick={print}
        cancel={() => setOpenPrintModal(false)}
      />

      <SubmitErrorModal
        errorType={submitErrorCodeModal}
        opened={submitErrorCodeModal !== null}
        onClose={(close) => {
          setSubmitErrorCodeModal(null);
          close();
        }}
      />

      <ConfirmationModal
        opened={openConfirmation}
        title={
          numberOfUnresolvedTopics > 0 ? 'Unresolved topics' : 'Submit Order'
        }
        message=""
        htmlMessage={submitOrderMessage}
        confirm={submitOrderHandler}
        buttonText="Submit"
        cancelButtonText={numberOfUnresolvedTopics > 0 ? 'Resolve' : 'Cancel'}
        cancel={cancelSubmitOrder}
      />
      <ConfirmationModal
        opened={openPDFExportModal}
        title="Export"
        confirm={onExportToPDFClickHandler}
        cancelButtonText="Cancel"
        buttonText="Export"
        cancel={() => setOpenPDFExportModal(false)}
        bottomSpacing={0}
      >
        <Wrapper flex column gap={32}>
          <RadioButton
            checked={!noPricingSelected}
            id="select-pricing"
            name="pricing"
            onChange={() => setNoPricingSelected(!noPricingSelected)}
            title="Export to PDF with Pricing"
          />

          <RadioButton
            checked={noPricingSelected}
            id="select-without-pricing"
            name="pricing"
            onChange={() => setNoPricingSelected(!noPricingSelected)}
            title="Export to PDF without Pricing"
          />
          <Spacer h="8px" />
        </Wrapper>
      </ConfirmationModal>
    </>
  );
};

export default OrderReviewPricingSalesMaterial;
