import { toast, ToastOptions } from 'react-toastify';

import CloseButton from 'shared/components/CloseButton';
import { kashmirBlue } from 'shared/config/Colors';

export const toastNotifOptions: ToastOptions = {
  type: toast.TYPE.DEFAULT,
  className: 'notif',
  autoClose: 5000,
  closeButton: <CloseButton color={kashmirBlue} />,
};
