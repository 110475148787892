import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { InvalidLineItems } from 'shared/components/InvalidLineItems';

import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import UtilService from 'shared/services/util.service';
import EmptyState from '../../../../shared/components/EmptyState';
import Loader from '../../../../shared/components/Loader';
import OrderStyle from '../../../components/OrderStyle/OrderStyle';
import ScrollbarsCustom from '../../../../shared/components/ScrollbarsCustom';
import SearchInput from '../../../../shared/components/SearchInput';
import { ButtonPrimary } from '../../../../shared/components/Button';
import { ConfirmationModal } from '../../../../shared/components/ConfirmationModal';
import { ImportContainer } from '../ImportOrders/ImportSearchOrders';
import { ImportLineItemsFlow } from '../../orderStyles/enums/ImportLineItemsFlow';
import { OrderLineItem } from '../../orderLineItems/OrderLineItem';
import { OrderPageParams } from '../../../interfaces/OrderPageParams';
import { P } from '../../../../shared/components/Typography';
import { ReactComponent as RemoveShoppingCartIcon } from '../../../../assets/icons/removeShoppingCart.svg';
import { RootState } from '../../../../store';
import { Spacer } from '../../../../shared/components/Layout';
import { Wrapper } from '../../../../shared/components/Wrapper';
import { getOrderStyles } from '../../../store/orderActions';
import { lynch } from '../../../../shared/config/Colors';
import { setStylesModalOpened } from '../../orderStyles/productLines/store/orderStylesActions';
import { useAppDispatch } from '../../../../shared/hooks/useAppDispatch';
import { useInputSearch } from '../../../../shared/hooks/useInputSearch';

import {
  addSelectedLineItem,
  copyLineItemsForImport,
  getLineItems,
  moveLineItemsForImport,
  resetSelectedLineItems,
  selectSingleStyleForImport,
} from '../store/ImportActions';

import {
  LineItem,
  LineItemDetailsImportError,
} from '../../orderStyles/interface/LineItem';

const ImportLineItems = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [filteredLineItems, setFilteredLineItems] = useState<LineItem[] | null>(
    null
  );

  const [importLineItemsLoading, setImportLineItemsLoading] = useState(false);

  const [invalidImportLineItemsModal, setInvalidImportLineItemModal] =
    useState<{
      lineItems?: LineItemDetailsImportError[];
      open: boolean;
    }>({
      open: false,
    });

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [query, onChange, loading, setLoading] = useInputSearch();

  const importOrderId = useSelector(
    (state: RootState) => state.importStylesReducer.orderId
  );

  const importStyle = useSelector(
    (state: RootState) => state.importStylesReducer.style
  );

  const lineItems = useSelector(
    (state: RootState) => state.importStylesReducer.lineItems
  );

  const selectedLineItemsToImport = useSelector(
    (state: RootState) => state.importStylesReducer.selectedLineItems
  );

  const selectedOrderToImportFrom = useSelector(
    (state: RootState) => state.importStylesReducer.orderId
  );

  const selectedStyle = useSelector(
    (state: RootState) => state.importStylesReducer.selectedStyle
  );

  const { importLineItemsFlow } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const handleLineItemClick = (lineItem: LineItem) => {
    dispatch(addSelectedLineItem(lineItem));
  };

  const checkIfLineItemIncludesQuery = (lineItem: LineItem) =>
    lineItem.lineItemDetails.code.toLowerCase().includes(query.toLowerCase());

  useEffect(() => {
    setLoading(true);

    if (lineItems) {
      const filtered = lineItems?.filter((lineItem) =>
        checkIfLineItemIncludesQuery(lineItem)
      );

      setFilteredLineItems(filtered);
    }

    setLoading(false);
  }, [query, lineItems]);

  useEffect(() => {
    setLoading(true);

    if (importOrderId && importStyle) {
      dispatch(
        getLineItems(
          {
            orderId: importOrderId,
            styleId: importStyle.id,
          },
          () => setLoading(false),
          setLoading
        )
      );
    } else {
      setLoading(false);
    }
  }, [importOrderId, importStyle?.id]);

  const clearSelectedLineItems = () => {
    dispatch(resetSelectedLineItems());
  };

  const onCloseImportLineItemsModalSuccess = () => {
    toast.success(
      <>
        Line Item(s) importing finished.
        <Spacer h="10px" />
        Selected Line Items(s): {(selectedLineItemsToImport ?? []).length}
        <Spacer h="5px" />
        Imported Line Item(s):{' '}
        {(selectedLineItemsToImport ?? []).length -
          (invalidImportLineItemsModal.lineItems ?? []).length ?? 0}
        <Spacer h="5px" />
        {invalidImportLineItemsModal.lineItems && (
          <>
            Non Imported Line Item(s):{' '}
            {invalidImportLineItemsModal.lineItems.length ?? 0}
          </>
        )}
      </>
    );

    dispatch(setStylesModalOpened(null));
    dispatch(selectSingleStyleForImport(null));

    if (orderId === importOrderId) {
      dispatch(getOrderStyles(orderId));
    }
  };

  const onImportStyleSuccessHandler = (
    notImportedLineItems: LineItemDetailsImportError[]
  ) => {
    if (notImportedLineItems?.length) {
      setInvalidImportLineItemModal({
        open: true,
        lineItems: notImportedLineItems,
      });
    } else {
      onCloseImportLineItemsModalSuccess();
    }
  };

  const onCloseInvalidLineItemsModal = () => {
    onCloseImportLineItemsModalSuccess();
    setInvalidImportLineItemModal({ open: false });
  };

  const onImportStyleFailHandler = (err: ServerErrorResponse) => {
    UtilService.handleError({
      err,
      fallbackErrorToastMessage:
        'There was an error while trying to import Line Items.',
    });
  };

  const handleImportLineItems = () => {
    setImportLineItemsLoading(true);
    if (selectedLineItemsToImport && selectedStyle && orderId) {
      const lineItemIds = selectedLineItemsToImport.map(
        (lineItem) => lineItem.lineItemId
      );
      const styleId = selectedLineItemsToImport?.[0]?.styleId;
      if (importLineItemsFlow === ImportLineItemsFlow.MOVE_LINE_ITEMS) {
        dispatch(
          moveLineItemsForImport(
            {
              lineItemIds,
              orderId,
              styleId,
              newStyleId: selectedStyle.id!,
            },
            onImportStyleSuccessHandler,
            onImportStyleFailHandler,
            setImportLineItemsLoading
          )
        );
      } else if (selectedOrderToImportFrom) {
        dispatch(
          copyLineItemsForImport(
            {
              lineItemIds,
              orderId: selectedOrderToImportFrom,
              newOrderId: orderId,
              styleId,
              newStyleId: selectedStyle.id!,
            },
            onImportStyleSuccessHandler,
            onImportStyleFailHandler,
            setImportLineItemsLoading
          )
        );
      }
    }
  };

  const onSelectClearAllItemsClickHandler = () => {
    if (lineItems) {
      lineItems.forEach((lineItem) => {
        dispatch(addSelectedLineItem(lineItem));
      });
    }
  };

  return (
    <>
      <ImportContainer>
        {importStyle && (
          <OrderStyle style={importStyle} expandable={false} noActions />
        )}
        <Spacer h="10px" />
        <SearchInput
          data-role="search"
          data-loading={loading}
          data-test="input-stylesSearch"
          placeholder="Search for line items..."
          ref={searchInputRef}
          onSearchQueryChanged={(e) => onChange(e.target.value)}
        />

        <Spacer h="15px" />
        {lineItems && lineItems.length > 0 && (
          <P
            fontSize={16}
            color={lynch}
            bold
            textAlign="left"
            pointer={!importLineItemsLoading}
            onClick={onSelectClearAllItemsClickHandler}
          >
            Select All
          </P>
        )}

        {(selectedLineItemsToImport?.length ?? 0) > 0 ? (
          <>
            <P
              fontSize={16}
              color={lynch}
              bold
              textAlign="right"
              pointer={!importLineItemsLoading}
              onClick={
                !importLineItemsLoading ? clearSelectedLineItems : undefined
              }
            >
              Clear Selected ({selectedLineItemsToImport?.length})
            </P>

            <Spacer h="15px" />
          </>
        ) : (
          <Spacer h="37.4px" />
        )}
        <ScrollbarsCustom
          autoHide
          autoHideDuration={300}
          autoHideTimeout={500}
          autoHeight
          autoHeightMax={370}
          autoHeightMin={370}
        >
          {filteredLineItems?.map((lineItem) => (
            <OrderLineItem
              key={lineItem.lineItemId}
              lineItem={lineItem}
              fromImport
              onLineItemClick={handleLineItemClick}
              selectable={!importLineItemsLoading}
            />
          ))}

          {!lineItems?.length && (
            <EmptyState
              title="No line items added yet!"
              titleSize={15}
              icon={<RemoveShoppingCartIcon />}
              iconSize={40}
              width={40}
              height={40}
              marginTop={30}
            />
          )}
        </ScrollbarsCustom>

        <Spacer h="20px" />

        <Wrapper flex justifyEnd>
          <ButtonPrimary
            disabled={
              !selectedLineItemsToImport?.length || importLineItemsLoading
            }
            onClick={handleImportLineItems}
          >
            {importLineItemsFlow === ImportLineItemsFlow.MOVE_LINE_ITEMS
              ? 'Move Line Items'
              : 'Import Line Items'}
            <Loader
              size={16}
              insideButton
              noSpacing
              hidden={!importLineItemsLoading}
            />
          </ButtonPrimary>
        </Wrapper>
      </ImportContainer>

      <ConfirmationModal
        title="List of non imported Line Item(s)"
        opened={invalidImportLineItemsModal.open}
        confirm={onCloseInvalidLineItemsModal}
        buttonText="Close"
        shouldNotRenderCancelButton
      >
        <InvalidLineItems
          reason="The following Line Item(s) can not be imported to the selected Style:"
          invalidLineItems={invalidImportLineItemsModal.lineItems}
        />
      </ConfirmationModal>
    </>
  );
};

export default ImportLineItems;
