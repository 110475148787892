import { AnyAction } from 'redux';
import { BaseField } from 'shared/interface/BaseField';
import { IAttachment } from 'shared/interface/IAttachment';
import { ACKStatusEnum } from '../enums/ACKStatusEnum';
import { CollectDrawingsStatusEnum } from '../enums/CollectDrawingsStatusEnum';
import {
  Acknowledgement,
  ACKSnapshot,
  CollectDrawings,
} from '../interface/Acknowledgement';
import { ACKActions } from './ACKActions';

interface ACKReducerInitial {
  latestACK: Acknowledgement | null;
  ackVersions: BaseField[] | null;
  latestACKSnapshot: ACKSnapshot | null;
  collectDrawings: CollectDrawings | null;
  attachments: IAttachment[] | null;
}

const initialState: ACKReducerInitial = {
  latestACK: null,
  ackVersions: null,
  latestACKSnapshot: null,
  collectDrawings: null,
  attachments: null,
};

export const ACKReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ACKActions.CLEAR_ACK_REDUCER:
      return { ...initialState };
    case ACKActions.SET_LATEST_ACK:
      return {
        ...state,
        latestACK: action.payload as Acknowledgement,
      };
    case ACKActions.SET_ACK_VERSIONS:
      return {
        ...state,
        ackVersions: action.payload as BaseField[],
      };
    case ACKActions.SET_LATEST_ACK_SNAPSHOT:
      return {
        ...state,
        latestACKSnapshot: action.payload as ACKSnapshot,
      };
    case ACKActions.UPDATE_ACK:
      return {
        ...state,
        latestACK: {
          ...state.latestACK,
          status: action.payload! as ACKStatusEnum,
          modifiedOnUtc: new Date().toISOString(),
        } as Acknowledgement,
      };
    case ACKActions.CHANGE_COLLECT_DRAWINGS_STATUS:
      return {
        ...state,
        collectDrawings: {
          ...state.collectDrawings,
          collectDrawingsStatus: action.payload! as CollectDrawingsStatusEnum,
        } as CollectDrawings,
      };
    case ACKActions.SET_COLLECT_DRAWINGS_STATUS:
      return {
        ...state,
        collectDrawings: action.payload as CollectDrawings,
      };
    case ACKActions.SET_ACK_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload as IAttachment[],
      };
    default:
      return state;
  }
};
