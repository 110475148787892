import { AnyAction } from 'redux';
import { Warnings } from 'order/warnings/interface/Wranings';
import { WarningsActions } from './warningsActions';

interface WarningsInitialState {
  warnings: Warnings | null;
}

const initialState: WarningsInitialState = {
  warnings: null,
};

export const warningsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case WarningsActions.SET_WARNINGS:
      return { ...state, warnings: action.payload as Warnings };
    default:
      return state;
  }
};
