import { CSSProperties, forwardRef } from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { mischka, valencia, white, fairPink } from 'shared/config/Colors';
import { RootState } from 'store';
import styled from 'styled-components';

interface ScrollbarsCustomProps extends ScrollbarProps {
  paddingRight?: number;
  asInput?: boolean;
  dataEmptyState?: string;
  hasError?: boolean;
}

const ScrollbarsCustomContainer = styled.div<
  Pick<ScrollbarsCustomProps, 'paddingRight'>
>`
  padding-right: ${({ paddingRight }) => paddingRight ?? 15}px;

  &[data-empty-state]:empty {
    &::before {
      content: attr(data-empty-state);
      padding: 5px 0 5px 32px;
    }
  }
`;

const ScrollbarsCustom = forwardRef<Scrollbars, ScrollbarsCustomProps>(
  (
    {
      children,
      paddingRight,
      asInput,
      hasError,
      dataEmptyState,
      style,
      ...rest
    },
    ref
  ) => {
    const canEdit = useSelector(
      (state: RootState) => state.orderReducer.canEdit
    );

    const getFieldBackground = () => {
      if (asInput && canEdit && hasError) return fairPink;
      if (asInput && !canEdit) return 'transparent';

      return white;
    };

    const asInputCss: CSSProperties = {
      backgroundColor: getFieldBackground(),
      border: `1px solid ${hasError ? valencia : mischka}`,
      borderRadius: '8px',
      transition: '300ms ease',
    };

    return (
      <Scrollbars
        ref={ref}
        {...rest}
        style={{ ...style, ...(asInput ? asInputCss : undefined) }}
      >
        <ScrollbarsCustomContainer
          paddingRight={paddingRight}
          data-empty-state={dataEmptyState}
        >
          {children}
        </ScrollbarsCustomContainer>
      </Scrollbars>
    );
  }
);

export default ScrollbarsCustom;
