import { useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import SelectDealership from 'overview/dealership/components/SelectDealership';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import { ButtonLink } from 'shared/components/Button';
import { ContentWrapper } from 'shared/components/Layout';
import { H1 } from 'shared/components/Typography';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import MetaTitle from 'shared/components/MetaTitle';

import { ManageUsersTable } from './components/ManageUsersTable';

export const ManageUsersPage = () => {
  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  const [usersLoading, setUsersLoading] = useState(false);

  return (
    <>
      <MetaTitle title="Manage Users | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft justifyStart between={false}>
          <H1>{canCreateDealershipUsers ? 'Manage ' : ''}Users</H1>

          <SelectDealership setIsLoading={setUsersLoading} />

          {canCreateDealershipUsers && (
            <Wrapper mlAuto>
              <ButtonLink
                to="/overview/manage-users/add-user"
                data-test="button-add-user"
              >
                <PlusIcon />
                <span>Add User</span>
              </ButtonLink>
            </Wrapper>
          )}
        </OverviewHeaderLeft>
      </OverviewHeader>

      <ContentWrapper>
        <ManageUsersTable
          usersLoading={usersLoading}
          setUsersLoading={setUsersLoading}
        />
      </ContentWrapper>
    </>
  );
};
