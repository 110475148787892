import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { zonedTimeToUtc } from 'date-fns-tz';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { FormDatePicker } from 'shared/components/FormDatePicker';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { globalDateFormat } from 'shared/config/Variables';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { workablesDescriptionValidation } from 'shared/validations/validations';

import { Select } from 'shared/components/Select';
import { getOrderStyles } from 'order/store/orderActions';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { IWorkable } from '../../interface/Workable';

import {
  createWorkable,
  getUnresolvedWorkablesCount,
  setWorkable,
  setWorkablesMode,
  updateWorkable,
} from '../../store/curtainWorkablesActions';

import { CurtainWorkablesTabEnums } from '../../enums/CurtainWorkablesTabEnums';

const CurtainWorkableFormContainer = styled.div`
  padding: 15px 30px;
`;

const CurtainWorkableFormActions = styled(Wrapper)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
`;

interface WorkableForm {
  dueDate: MaterialUiPickersDate;
  description: string;
  assignedToMe: boolean;
  styleId: string;
}

const CurtainWorkableForm = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [dueDateError, setDueDateError] = useState('');

  const [createWorkableLoading, setCreateOrUpdateWorkableLoading] =
    useState(false);

  const workable = useSelector(
    (state: RootState) => state.curtainWorkablesReducer.workable
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  const mapWorkableToWorkableForm = (workableData: IWorkable) => {
    return {
      description: workableData.description,
      dueDate: new Date(workableData.dueDate),
      assignedToMe: workableData.assignedToMe,
      styleId: workableData.styleId,
    } as WorkableForm;
  };

  const methods = useForm<WorkableForm>({
    defaultValues: workable
      ? mapWorkableToWorkableForm(workable)
      : {
          assignedToMe: false,
          description: '',
          dueDate: new Date(),
          styleId: '',
        },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onCreateOrUpdateWorkableSuccessHandler = () => {
    toast.success(
      `You have successfully ${!workable ? 'created' : 'updated'} a workable.`
    );
    dispatch(setWorkablesMode(CurtainWorkablesTabEnums.LIST_MODE));
    dispatch(getUnresolvedWorkablesCount({ orderId }));
  };

  const onSubmit = (data: WorkableForm) => {
    if (!dueDateError) {
      setCreateOrUpdateWorkableLoading(true);
      let dueDateFormated = '';

      if (data.dueDate) {
        const newDate = new Date(data.dueDate).toISOString();
        const zoned = zonedTimeToUtc(newDate, 'UTC');
        dueDateFormated = zoned.toISOString();
      }

      if (!workable) {
        dispatch(
          createWorkable(
            {
              orderId,
              description: data.description,
              dueDate: dueDateFormated,
              styleId: data.styleId,
            },
            onCreateOrUpdateWorkableSuccessHandler,
            setCreateOrUpdateWorkableLoading
          )
        );
      } else {
        dispatch(
          updateWorkable(
            {
              orderId,
              workableId: workable.id!,
              description: data.description,
              dueDate: dueDateFormated,
              styleId: data.styleId,
            },
            onCreateOrUpdateWorkableSuccessHandler,
            setCreateOrUpdateWorkableLoading
          )
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getOrderStyles(orderId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setWorkable(null));
    };
  }, []);

  const mapToStyleOptions = () =>
    (styles ?? []).map((style) => {
      return {
        value: style.id,
        label: style.name,
      };
    });

  return (
    <CurtainWorkableFormContainer>
      <FormElement>
        <FormLabel>Due Date</FormLabel>

        <Controller
          control={methods.control}
          name="dueDate"
          rules={{
            required: {
              message: 'Due Date is required.',
              value: true,
            },
          }}
          render={({ field }) => {
            return (
              <FormDatePicker
                {...UtilService.omit(field, ['ref'])}
                placeholder="Select Due Date..."
                clearable
                minDate={new Date()}
                helperText={dueDateError}
                error={!!methods.formState.errors.dueDate || !!dueDateError}
                format={globalDateFormat}
                onError={(error) =>
                  methods.formState.dirtyFields.dueDate &&
                  setDueDateError(
                    (methods.formState.errors.dueDate as FieldErrors)
                      ?.message ?? error!.toString()
                  )
                }
              />
            );
          }}
        />
      </FormElement>
      <FormElement>
        <FormLabel>Description</FormLabel>
        <Textarea
          aria-invalid={methods.formState.errors.description ? 'true' : 'false'}
          {...methods.register('description', workablesDescriptionValidation())}
        />
        <FormError
          error={methods.formState.errors.description}
          label="Description"
          validationSchema={workablesDescriptionValidation()}
        />
      </FormElement>

      <FormElement>
        <FormLabel>Style</FormLabel>
        <Controller
          control={methods.control}
          name="styleId"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              ref={ref}
              options={mapToStyleOptions()}
              value={mapToStyleOptions().find(
                (option) => option.value === value
              )}
              onChange={(val: SelectOptionProps) => onChange(val.value)}
              aria-invalid={methods.formState.errors.styleId ? 'true' : 'false'}
            />
          )}
        />
        <FormError
          error={methods.formState.errors.styleId}
          label="Style"
          validationSchema={{ required: true }}
        />
      </FormElement>

      {/* <FormElement>
        <Checkbox
          {...methods.register('assignedToMe')}
          id="assignedToMe"
          title="My Workable"
        />
      </FormElement> */}

      <CurtainWorkableFormActions flex middle justifyEnd>
        <ButtonSecondary
          onClick={() =>
            dispatch(setWorkablesMode(CurtainWorkablesTabEnums.LIST_MODE))
          }
        >
          Cancel
        </ButtonSecondary>
        <Spacer w="20px" />
        <ButtonPrimary
          disabled={createWorkableLoading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          Save
          <Loader
            hidden={!createWorkableLoading}
            insideButton
            noSpacing
            size={16}
          />
        </ButtonPrimary>
      </CurtainWorkableFormActions>
    </CurtainWorkableFormContainer>
  );
};

export default CurtainWorkableForm;
