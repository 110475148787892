import { RootState } from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { clearImportStyles } from '../import/store/ImportActions';
import { ImportStylesModalStepsEnums } from '../import/enums/ImportStylesModalStepsEnums';
import ImportSearchOrders from '../import/ImportOrders/ImportSearchOrders';
import ImportStyleSelect from '../import/ImportSelect/ImportStyleSelect';
import ImportModalHeader from '../import/ImportModalHeader/ImportModalHeader';
import ImportLineItem from '../../components/ImportLineItem/ImportLineItem';
import ImportLineItems from '../import/ImportLineItems/ImportLineItems';

const OrderImportStylesModalContainer = styled.div`
  width: 1100px;
  padding: 19px 25px;
`;

const OrderImportModal = () => {
  const dispatch = useAppDispatch();

  const step = useSelector(
    (state: RootState) => state.importStylesReducer.step
  );

  useEffect(() => {
    return () => {
      dispatch(clearImportStyles());
    };
  }, []);

  return (
    <OrderImportStylesModalContainer>
      <ImportModalHeader />

      {step === ImportStylesModalStepsEnums.SELECT_IMPORT_LINE_ITEMS_FLOW && (
        <ImportLineItem />
      )}

      {step === ImportStylesModalStepsEnums.SEARCH_ORDERS && (
        <ImportSearchOrders />
      )}

      {step === ImportStylesModalStepsEnums.SELECT_STYLES && (
        <ImportStyleSelect />
      )}

      {step === ImportStylesModalStepsEnums.SELECT_LINE_ITEMS && (
        <ImportLineItems />
      )}
    </OrderImportStylesModalContainer>
  );
};

export default OrderImportModal;
