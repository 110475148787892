import { FC } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary, TabbableButton } from 'shared/components/Button';
import { lynch } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';

import { ModificationBox } from './ModificationBox';
import { ModificationModalStepsEnums } from './enums/ModificationModalStepsEnums';
import { addModifications } from '../../store/orderLineItemsActions';

import {
  setSelectedModification,
  updateModificationsModal,
} from './store/lineItemModificationsActions';

const ModificationsCompleteContainer = styled(Wrapper)`
  min-height: 395px;
`;

const ModificationsCompleteActions = styled(Wrapper)`
  margin-top: auto;
`;

const ModificationsInner = styled.div`
  margin: 5px 0 5px 5px;
`;

interface ModificationsCompleteProps {
  closeModal: () => void;
}

const ModificationsComplete: FC<ModificationsCompleteProps> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();

  const selectedMods = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.selectedModifications
  );

  const lineItemMods = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemModifications
  );

  const onAddAnotherModClickHandler = () => {
    dispatch(
      updateModificationsModal(
        ModificationModalStepsEnums.SEARCH_MODIFICATIONS,
        ModificationModalStepsEnums.SEARCH_MOD_TITLE,
        ModificationModalStepsEnums.SEARCH_MOD_DESC
      )
    );

    dispatch(setSelectedModification(null));
  };

  const onModificationModalFinishClickHandler = () => {
    dispatch(
      addModifications(
        selectedMods.map((mod, index) => ({
          ...mod,
          number: lineItemMods.length + index + 1,
        }))
      )
    );
    closeModal();
  };

  return (
    <ModificationsCompleteContainer flex column>
      <Wrapper>
        <ScrollbarsCustom
          autoHeight
          autoHeightMax={327}
          autoHeightMin={327}
          autoHide
          autoHideDuration={300}
          autoHideTimeout={500}
        >
          <ModificationsInner>
            {!selectedMods.length && (
              <EmptyState title="No selected modifications" />
            )}

            {selectedMods.map((mod) => (
              <ModificationBox
                editModeEnabled
                hoverModeEnabled
                key={mod.id}
                modification={mod}
              />
            ))}
          </ModificationsInner>
        </ScrollbarsCustom>
      </Wrapper>

      <Spacer h="30px" />

      <ModificationsCompleteActions flex between middle>
        <TabbableButton color={lynch} onClick={onAddAnotherModClickHandler}>
          + Add another modification
        </TabbableButton>
        <ButtonPrimary onClick={onModificationModalFinishClickHandler}>
          Finish
        </ButtonPrimary>
      </ModificationsCompleteActions>
    </ModificationsCompleteContainer>
  );
};

export default ModificationsComplete;
