import { FC, useState } from 'react';
import { RootState } from 'store';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import { Badge } from 'shared/components/Badge';
import { ButtonIcon } from 'shared/components/Button';
import { H6, PMedium } from 'shared/components/Typography';
import { IAttachment } from 'shared/interface/IAttachment';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import {
  AttachmentAlteredSections,
  AttachmentModalSectionEnums,
  generateAttachmentSections,
} from 'shared/components/AttachmentsModal/enums/AttachmentModalSectionEnums';

import {
  kashmirBlue,
  lynch,
  nevada,
  poloBlue,
  silverChaliceTwo,
} from 'shared/config/Colors';

import {
  ContainerBox,
  ContainerMedium,
  ContainerSmall,
  GridContainer,
} from 'shared/components/Container';

import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import styled from 'styled-components';

interface AttachmentProps {
  attachment: IAttachment;
  isLineItemSection: boolean;
}

const GridContainerOverriden = styled(GridContainer)`
  &:empty {
    &:before {
      content: 'No attachments on this order yet.';
    }
  }
`;

const AttachmentContainer = styled(Wrapper)`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Attachment: FC<AttachmentProps> = ({
  attachment,
  isLineItemSection,
}) => {
  const history = useHistory();

  const navigateToLineItems = () => {
    history.push('line-items', {
      openLineItemToAttachments: attachment.lineItem?.id,
    });
  };

  return (
    <AttachmentContainer flex column>
      <Wrapper flex middle between>
        {isLineItemSection && (
          <ContainerMedium minWidth={450}>
            <PMedium color={nevada}>
              #{attachment.lineItem?.number}{' '}
              {attachment.lineItem?.code || attachment.lineItem?.description}
            </PMedium>
          </ContainerMedium>
        )}

        <Wrapper flex middle flexGrow>
          <PMedium
            onClick={isLineItemSection ? navigateToLineItems : undefined}
            color={isLineItemSection ? lynch : undefined}
            textDecoration={isLineItemSection ? 'underline' : undefined}
            pointer={isLineItemSection}
          >
            {attachment.name}
            {attachment.extension}
          </PMedium>

          <Spacer w="20px" />

          {attachment.isPerFactory && (
            <Badge bgColor={poloBlue}>Per Factory Drawing</Badge>
          )}
        </Wrapper>

        <ContainerSmall align="end">
          <PMedium color={nevada}>
            {UtilService.formatDate({
              date: attachment.createdOnUtc,
              isUtc: true,
            })}
          </PMedium>
        </ContainerSmall>
      </Wrapper>
    </AttachmentContainer>
  );
};

export const Attachments = () => {
  const [lineItemSectionExpanded, setLineItemSectionExpanded] = useState(true);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const ackAttachments = useSelector(
    (state: RootState) => state.ACKReducer.attachments
  );

  const isSectionCheck = (
    sectionLabel: string,
    sectionName: keyof typeof AttachmentModalSectionEnums
  ) => {
    return (
      sectionLabel ===
      AttachmentModalSectionEnums[AttachmentModalSectionEnums[sectionName]]
    );
  };

  const onExpandLineItemSectionHandler = () => {
    setLineItemSectionExpanded((prevState) => !prevState);
  };

  return (
    <GridContainerOverriden className="ack-attachments">
      {generateAttachmentSections()
        .filter(
          (section) =>
            ackAttachments?.some((att) => att.section === +section.value) &&
            (section.value ===
              AttachmentModalSectionEnums['Line Items'].toString() ||
              section.value ===
                AttachmentModalSectionEnums.Acknowledgment.toString() ||
              section.value ===
                AttachmentModalSectionEnums['Merged PDF'].toString())
        )
        .map((section) => {
          const isLineItemSection = isSectionCheck(section.label, 'Line Items');

          return (
            <ContainerBox
              key={section.label}
              flexGrow
              wFull={isLineItemSection}
              order={isLineItemSection ? -1 : undefined}
            >
              <ContainerMedium
                flex
                middle
                onClick={
                  isLineItemSection ? onExpandLineItemSectionHandler : undefined
                }
              >
                {isLineItemSection && (
                  <>
                    <ButtonIcon
                      className={lineItemSectionExpanded ? 'expanded' : ''}
                      iconColor={silverChaliceTwo}
                      hoverIconColor={kashmirBlue}
                    >
                      <SVG
                        animateTransform={['transform', 'borderRadius']}
                        icon={<ArrowDownIcon />}
                      />
                    </ButtonIcon>
                    <Spacer w="15px" />
                  </>
                )}

                <H6>
                  {AttachmentAlteredSections[+section.value].toUpperCase()}
                </H6>
              </ContainerMedium>

              {(isLineItemSection ? lineItemSectionExpanded : true) && (
                <>
                  <Spacer h="32px" />
                  {ackAttachments
                    ?.filter(
                      (attachment) =>
                        attachment.section === +section.value &&
                        (isUserCSR ? true : !attachment.isPerFactory)
                    )
                    ?.sort((a, b) =>
                      a.lineItem && b.lineItem
                        ? UtilService.sortBy(
                            a.lineItem.number,
                            b.lineItem.number,
                            true
                          )
                        : 0
                    )
                    ?.map((att) => (
                      <Attachment
                        attachment={att}
                        key={att.id}
                        isLineItemSection={isLineItemSection}
                      />
                    ))}
                </>
              )}
            </ContainerBox>
          );
        })}
    </GridContainerOverriden>
  );
};
