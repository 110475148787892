import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import {
  setActiveLineItemID,
  setCurtainTab,
} from 'curtain/store/curtainActions';

import { Order } from 'order/interfaces/Order';

import { PriorityBadge } from 'shared/components/PriorityBadge';
import { Wrapper } from 'shared/components/Wrapper';
import { taupe } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setOrderPriority } from '../../../store/orderActions';

const PriorityContainer = styled(Wrapper)`
  margin-right: 15px;
`;

interface OrderPriorityProps {
  order: Order;
}

const OrderPriority: FC<OrderPriorityProps> = ({ order }) => {
  const dispatch = useAppDispatch();

  const { styleChannels, lineItemChannels, otherTopics } = useSelector(
    (state: RootState) => state.topicReducer
  );

  useEffect(() => {
    const noChannelsOrTopics =
      !styleChannels?.length &&
      !lineItemChannels?.length &&
      !otherTopics?.length;

    let maxPriority = noChannelsOrTopics && order ? order.priority ?? 0 : 0;

    styleChannels?.forEach((channel) => {
      if (channel.priority > maxPriority) {
        maxPriority = channel.priority;
      }
    });

    lineItemChannels?.forEach((channel) => {
      if (channel.priority > maxPriority) {
        maxPriority = channel.priority;
      }
    });

    otherTopics?.forEach((topic) => {
      if (topic.priority > maxPriority) {
        maxPriority = topic.priority;
      }
    });

    dispatch(setOrderPriority(maxPriority));
  }, [styleChannels, lineItemChannels, otherTopics, order.priority]);

  const handleSetCurtainTab = () => {
    dispatch(setActiveLineItemID(null));
    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
  };

  return (order.priority ?? 0) > 0 ? (
    <PriorityContainer flex middle onClick={handleSetCurtainTab}>
      <PriorityBadge priority={order.priority!} hoverColor={taupe} />
    </PriorityContainer>
  ) : null;
};

export default OrderPriority;
