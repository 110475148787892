import { call, put, takeEvery } from 'redux-saga/effects';

import {
  AckChangeLogItem,
  AcknowledgmentDifference,
} from 'order/shared/ackchangelog/interface/AckChangeLogItem';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  AckChangeLogActions,
  GetAckChangeLogRequest,
  setAckChangeLog,
  setAckChangeLogDiff,
} from './ackchangelogActions';

function* getAckChangeLog(action: Action<GetAckChangeLogRequest>) {
  try {
    const { page, itemsPerPage, orderId, acknowledgementOnly } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('acknowledgementOnly', acknowledgementOnly.toString());

    const response: PaginatedItems<AckChangeLogItem> | null = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/edit-logs`,
      { params: queryParams }
    );

    yield put(setAckChangeLog(response, page > 1));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getAcknowledgmentDifferences(action: Action<string>) {
  try {
    const response: AcknowledgmentDifference[] | null = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/acknowledgements/differences`
    );

    yield put(setAckChangeLogDiff(response));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* ackChangeLogSagas() {
  yield takeEvery(AckChangeLogActions.GET_ACKCHANGELOG, getAckChangeLog);
  yield takeEvery(
    AckChangeLogActions.GET_ACKCHANGELOG_DIFF,
    getAcknowledgmentDifferences
  );
}
