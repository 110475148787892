import styled from 'styled-components';

import { Wrapper } from 'shared/components/Wrapper';

interface ItemRowProps {
  borderTop?: boolean;
  noteRow?: boolean;
}

export const ItemRow = styled(Wrapper)<ItemRowProps>`
  border-bottom: 1px solid ${({ theme }) => theme.athensGray};

  ${({ borderTop, theme }) =>
    borderTop && `border-top: 1px solid ${theme.athensGray};`}

  display: flex;
  flex-wrap: wrap;
  padding: 16px 32px;

  > p:nth-child(1) {
    min-width: ${({ noteRow }) => (noteRow ? '130px' : '50px')};
  }

  > p:last-child {
    flex: 1;
  }
`;
