/**
 * Branko Stancevic - React Testing Library
 * This file contain util methods that will be used in various tests
 */

/**
 * Returns the generated data attr with provided id
 * @param {string} id - test id which will be attached to the element
 * @returns {string} { 'data-testid': id; }
 */

export const testId = (id: string) => {
  return {
    'data-testid': id,
  };
};
