import { useEffect, useState } from 'react';

import {
  // HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

import { Token } from 'shared/interface/Token';

import env_config from 'env_config';

import { useBrowserStorage } from './useBrowserStorage';

interface ConnectionOpenOptions {
  onReceiveData: (data: unknown) => void;
  url: string;
  event: string;
  shouldOpenConnection?: boolean;
}

interface ConnectionOpenReturn {
  connection?: HubConnection;
}

export const useConnectionOpen = (
  options: ConnectionOpenOptions
): ConnectionOpenReturn => {
  const [signalrConnection, setSignalrConnection] = useState<HubConnection>();
  const [connectionOpened, setConnectionOpened] = useState(false);

  const { value } = useBrowserStorage<Token>({
    storageType: 'localStorage',
    key: 'token',
  });

  const onConnectionStartHandler = () => {
    setConnectionOpened(true);
  };

  useEffect(() => {
    if (
      value &&
      !connectionOpened &&
      !signalrConnection &&
      options.shouldOpenConnection === true
    ) {
      // open signal r connection
      const connection = new HubConnectionBuilder()
        .withUrl(`${env_config.apiAppUrl}/${options.url}`, {
          accessTokenFactory: () => value.access_token,
          // transport: HttpTransportType.WebSockets,
          // skipNegotiation: true,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Trace)
        .build();

      // set connection to the local store
      setSignalrConnection(connection);
    }
  }, [
    options.shouldOpenConnection,
    value,
    connectionOpened,
    signalrConnection,
  ]);

  useEffect(() => {
    if (!connectionOpened && signalrConnection) {
      // start connection
      signalrConnection.start().then(onConnectionStartHandler);

      // handle incoming data
      signalrConnection.on(options.event, options.onReceiveData);
    }
  }, [connectionOpened, signalrConnection]);

  return { connection: signalrConnection };
};
