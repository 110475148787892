import { RegisterOptions } from 'react-hook-form';

export const onlyDigitValidation = (
  options: RegisterOptions = {},
  label?: string
) => {
  return {
    ...(options && options),
    pattern: label
      ? {
          value: /^\d+(\.\d+)?$/,
          message: `${label} can only be a positive number.`,
        }
      : /^\d+(\.\d+)?$/,
  };
};

export const onlyNumberValidation = (
  options: RegisterOptions = {},
  label?: string
) => {
  return {
    ...(options && options),
    pattern: label
      ? {
          value: /^-?\d+(\.\d+)?$/,
          message: `${label} can only be a number.`,
        }
      : /^-?\d+(\.\d+)?$/,
  };
};

export const digitWithSingleDotValidation = (
  options: RegisterOptions = {},
  label?: string
) => {
  return {
    ...(options && options),
    pattern: label
      ? {
          value: /^((\d+)|(\.)\d+$)+$/,
          message: `${label} can only be a number.`,
        }
      : /^\d+(\.\d+)?$/,
  };
};

export const onlyWholeDigitValidation = (
  options: RegisterOptions = {},
  label?: string
) => {
  return {
    ...(options && options),
    pattern: label
      ? {
          value: /^\d+$/,
          message: `${label} can only be a whole number.`,
        }
      : /^\d+$/,
  };
};

export const emailValidation = (options: RegisterOptions) => {
  return {
    ...(options && options),
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: 'Please enter a valid email address.',
    },
  };
};

export const firstNameValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    minLength: 2,
    maxLength: 40,
    pattern: /^[A-Za-z.,\s\-–—']+$/,
  };
};

export const descriptionValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 50,
  };
};

export const lastNameValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    minLength: 2,
    maxLength: 40,
    pattern: /^[A-Za-z.,\s\-–—']+$/,
  };
};

export const phoneNumberValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    pattern: {
      value: /^\d{3} \d{3} \d{4}$/,
      message:
        'Phone number needs to be in a correct format: XXX XXX XXXX (numbers).',
    },
  };
};

export const upchargeValidation = (
  options: RegisterOptions = {},
  label?: string,
  allowPositiveOnly?: boolean
) => {
  return {
    ...(options && options),
    pattern: {
      value: allowPositiveOnly
        ? /^\d{0,2}(\.\d{1,2})?$/
        : /^-?\d{0,2}(\.\d{1,2})?$/,
      message: `${
        label ?? 'Upcharge'
      } needs to be between 0 and 99.99 and in a correct format: XX.XX (numbers).`,
    },
  };
};

export const passwordValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const userTypeValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const zipCodeValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLenght: 10,
    pattern: {
      value: /^\d{5}(?:-\d{4})?$/,
      message:
        'Zip Code needs to be in a correct format: XXXXX or XXXXX-XXXX (numbers).',
    },
  };
};

export const orderJobValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 200,
  };
};

export const orderNameValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 200,
  };
};

export const ceilingHeightValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 200,
  };
};

export const countertopThicknessValidation = (
  options: RegisterOptions = {}
) => {
  return {
    ...(options && options),
    maxLength: 200,
  };
};

export const postOfficeNumberValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 200,
  };
};

export const depositPaidValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    ...onlyDigitValidation(),
    min: 0,
    maxLength: 200,
  };
};

export const depositPaidDateValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const dateRequestedValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 30,
  };
};

export const workOrderNumberValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 25,
  };
};

export const specialFinishSampleValidation = (
  options: RegisterOptions = {}
) => {
  return {
    ...(options && options),
    maxLength: 10,
  };
};

export const descriptionTypeValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const transportationTypeValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const topicDescriptionValidation = (
  options: RegisterOptions = {},
  label: string = 'Description'
) => {
  return {
    ...(options && options),
    maxLength: 1000,
    pattern: {
      value: /^[^\s]/,
      message: `${label} cannot begin with or be the empty space.`,
    },
  };
};

export const topicCommentValidation = (
  options: RegisterOptions = {},
  label: string = 'Comment'
) => {
  return {
    ...(options && options),
    maxLength: 1000,
    pattern: {
      value: /^[^\s]/,
      message: `${label} cannot begin with or be the empty space.`,
    },
  };
};

export const styleNameValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
  };
};

export const noteValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 400,
  };
};

export const modificationDescriptionValidation = (
  options: RegisterOptions = {}
) => {
  return {
    ...(options && options),
    maxLength: 60,
  };
};

export const lineItemNumberValidation = (
  options: RegisterOptions = {},
  max: number
) => {
  return {
    ...(options && options),
    min: 1,
    max,
    pattern: {
      value: /^[0-9]*$/,
      message: 'Line item number can only be a number.',
    },
  };
};

export const dimUpValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    validate: (value: string) => {
      return (
        /^D{0,2}H?W{0,2}$/g.test(value.split('').sort().join('')) ||
        'DimUp should only have values W(W), H or D(D).'
      );
    },
  };
};

export const pwwUsernameValidation = (options: RegisterOptions = {}) => {
  return {
    ...(options && options),
    maxLength: 15,
  };
};

export const workablesDescriptionValidation = (
  options: RegisterOptions = {}
) => {
  return {
    ...(options && options),
    required: true,
    maxLength: 255,
  };
};

export const specialCharactersNotAllowedValidation = (
  options: RegisterOptions = {}
) => {
  return {
    ...(options && options),
    pattern: {
      value: /^[a-zA-Z0-9 ]*$/,
      message: 'No special characters allowed.',
    },
  };
};
