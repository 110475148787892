import { AnyAction } from 'redux';

import { CSR } from 'shared/interface/CSR';

import {
  orderAssigneesActions,
  UpdateCSRPrimaryPayload,
} from './orderAssigneesActions';

interface OrderAssignees {
  searchedCSRs: CSR[] | null;
  selectedCSRs: CSR[] | null;
}

const initialState: OrderAssignees = {
  searchedCSRs: null,
  selectedCSRs: [],
};

export const orderAssigneesReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case orderAssigneesActions.CLEAR_ORDER_ASSIGNEES:
      return { ...initialState };

    case orderAssigneesActions.SET_SEARCHED_CSRS:
      return {
        ...state,
        searchedCSRs: action.payload as CSR[],
      };
    case orderAssigneesActions.SELECT_CSR: {
      const selectedCollabs = state.selectedCSRs;

      const updatedCollabs = selectedCollabs
        ? [...selectedCollabs, action.payload as CSR]
        : [action.payload as CSR];

      return {
        ...state,
        selectedCSRs: updatedCollabs,
      };
    }
    case orderAssigneesActions.DESELECT_CSR: {
      const filteredCollaborators = state.selectedCSRs!.filter(
        (collaborator) => collaborator.id !== action.payload
      );

      return {
        ...state,
        selectedCSRs: filteredCollaborators.length
          ? filteredCollaborators
          : null,
      };
    }
    case orderAssigneesActions.UPDATE_CSR_PRIMARY: {
      const { primary, userId } = action.payload as UpdateCSRPrimaryPayload;

      return {
        ...state,
        selectedCSRs: state.selectedCSRs!.map((selectedCSR) => {
          if (selectedCSR.id === userId) {
            return {
              ...selectedCSR,
              primary,
            } as CSR;
          }

          return {
            ...selectedCSR,
            primary: false,
          } as CSR;
        }) as CSR[],
      };
    }

    case orderAssigneesActions.SET_ORDER_ASSIGNEES:
      return {
        ...state,
        selectedCSRs: action.payload as CSR[],
      };
    default:
      return state;
  }
};
