import styled from 'styled-components';

interface FloatingCheckboxProps {
  top?: number;
  relative?: boolean;
}

export const FloatingCheckbox = styled.div<FloatingCheckboxProps>`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  left: -40px;
  top: ${({ top }) => (top ? `${top}px` : '30px')};
`;
