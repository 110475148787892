import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

import { ButtonIcon } from 'shared/components/Button';
import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';

import { setStylesModalOpened } from '../../orderStyles/productLines/store/orderStylesActions';
import {
  resetSelectedLineItems,
  selectSingleStyleForImport,
  setImportStylesModalMeta,
  setLineItemsForImport,
} from '../store/ImportActions';
import { ImportStylesModalHeaderEnums } from '../../orderStyles/enums/ImportStylesModalEnum';
import { ImportStylesModalStepsEnums } from '../enums/ImportStylesModalStepsEnums';
import { ImportLineItemsFlow } from '../../orderStyles/enums/ImportLineItemsFlow';
import { useHasPermissions } from '../../../../shared/hooks/useHasPermissions';
import { userPermissionsValues } from '../../../../shared/enum/userPermissionsEnum';

const ImportStylesModalHeaderContainer = styled.div``;

const ImportModalHeader = () => {
  const dispatch = useAppDispatch();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const { title, previousTitle, desc, step } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const { selectedStyle: selectedStyleForImport } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const { importLineItemsFlow } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const onBackToOrderSearchClickHandler = () => {
    dispatch(
      setImportStylesModalMeta({
        desc: ImportStylesModalHeaderEnums.ORDER_SEARCH_DESC,
        orderId: null,
        step: ImportStylesModalStepsEnums.SEARCH_ORDERS,
        title: ImportStylesModalHeaderEnums.ORDER_SEARCH_TITLE,
      })
    );
  };

  const onBackToStyleSearchClickHandler = () => {
    dispatch(setLineItemsForImport(null));
    dispatch(resetSelectedLineItems());
    dispatch(
      setImportStylesModalMeta({
        desc: ImportStylesModalHeaderEnums.SELECT_STYLE_DESC,
        orderId: null,
        step: ImportStylesModalStepsEnums.SELECT_STYLES,
        title: previousTitle as ImportStylesModalHeaderEnums,
      })
    );
  };

  const onBackToSelectImportFlowHandler = () => {
    dispatch(
      setImportStylesModalMeta({
        desc: ImportStylesModalHeaderEnums.IMPORT_LINE_ITEM_DESC,
        orderId: null,
        step: ImportStylesModalStepsEnums.SELECT_IMPORT_LINE_ITEMS_FLOW,
        title: ImportStylesModalHeaderEnums.IMPORT_LINE_ITEM,
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(setStylesModalOpened(null));
    dispatch(selectSingleStyleForImport(null));
  };

  return (
    <ImportStylesModalHeaderContainer>
      <Wrapper flex middle>
        <Wrapper flex middle mrAuto>
          {step === ImportStylesModalStepsEnums.SEARCH_ORDERS &&
            selectedStyleForImport &&
            isUserCSR && (
              <>
                <ButtonIcon onClick={onBackToSelectImportFlowHandler}>
                  <SVG icon={<ArrowLeftIcon />} />
                </ButtonIcon>

                <Spacer w="15px" />
              </>
            )}

          {step === ImportStylesModalStepsEnums.SELECT_STYLES && (
            <>
              <ButtonIcon
                onClick={
                  importLineItemsFlow !== ImportLineItemsFlow.MOVE_LINE_ITEMS
                    ? onBackToOrderSearchClickHandler
                    : onBackToSelectImportFlowHandler
                }
              >
                <SVG icon={<ArrowLeftIcon />} />
              </ButtonIcon>

              <Spacer w="15px" />
            </>
          )}

          {step === ImportStylesModalStepsEnums.SELECT_LINE_ITEMS && (
            <>
              <ButtonIcon onClick={onBackToStyleSearchClickHandler}>
                <SVG icon={<ArrowLeftIcon />} />
              </ButtonIcon>

              <Spacer w="15px" />
            </>
          )}

          <H2>{title}</H2>
        </Wrapper>

        <ButtonIcon onClick={handleCloseClick}>
          <SVG icon={<CloseIcon />} />
        </ButtonIcon>
      </Wrapper>

      <Spacer h="5px" />
      <P color={nevada}>{desc}</P>
      <Spacer h="20px" />
    </ImportStylesModalHeaderContainer>
  );
};

export default ImportModalHeader;
