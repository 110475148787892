import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import {
  ButtonDefault,
  ButtonPrimaryCSS,
  ButtonSecondaryCSS,
} from 'shared/components/Button';
import { H4, PSmall } from 'shared/components/Typography';
import Img from 'shared/components/Img';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { ILineItemCatalogItem } from '../interface/ILineItemCatalog';

interface LineItemListViewProps extends HTMLAttributes<HTMLDivElement> {
  lineItem: ILineItemCatalogItem;
  highlighted: boolean;
  onLineItemConfigureHandler: (lineItem: ILineItemCatalogItem) => void;
  isLineItemAlreadyAdded?: boolean;
}

const LineItemListViewContainer = styled(Wrapper)<
  Pick<LineItemListViewProps, 'highlighted'>
>`
  ${BoxShadowCSS}
  background: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid transparent;
  height: 70px;
  margin-bottom: 16px;
  padding: 11px 32px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ highlighted, theme }) => highlighted && `border-color: ${theme.lynch}`};

  ${ButtonDefault} {
    ${({ highlighted }) =>
      highlighted ? ButtonPrimaryCSS : ButtonSecondaryCSS}
  }
`;

const ImageContainer = styled(Wrapper)`
  width: 48px;
  height: 48px;
  padding: 4px 5px;
  border: 1px solid ${({ theme }) => theme.mischka};

  > .list-view-image {
    width: 100%;
  }
`;

export const LineItemListView = forwardRef<
  HTMLDivElement,
  LineItemListViewProps
>(
  (
    {
      lineItem,
      highlighted,
      onLineItemConfigureHandler,
      isLineItemAlreadyAdded,
      ...rest
    },
    ref
  ) => {
    return (
      <LineItemListViewContainer
        {...rest}
        ref={ref}
        highlighted={highlighted}
        flex
        middle
      >
        <ImageContainer flex>
          <Img
            className="list-view-image"
            contain
            src={lineItem.imageUrl}
            alt={`${lineItem.code}`}
          />
        </ImageContainer>
        <Spacer w="32px" />
        <Wrapper flex>
          <H4>{lineItem.code}</H4>
        </Wrapper>
        <Spacer w="16px" />
        <Wrapper flex>
          <PSmall>{lineItem.description}</PSmall>
        </Wrapper>
        <Wrapper flex flexGrow justifyEnd>
          <ButtonDefault
            tabIndex={-1}
            type="button"
            onClick={() => onLineItemConfigureHandler(lineItem)}
            disabled={isLineItemAlreadyAdded}
          >
            Add
          </ButtonDefault>
        </Wrapper>
      </LineItemListViewContainer>
    );
  }
);
