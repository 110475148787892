import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Action } from '../../../../shared/interface/Action';
import ApiService from '../../../../shared/services/api.service';
import UtilService from '../../../../shared/services/util.service';
import {
  setLineItemChannels,
  setLineItemTopics,
  setOtherTopics,
  setStyleChannels,
  setStyleTopics,
  TopicActions,
} from './TopicActions';
import { IChannel } from '../../../../curtain/interfaces/IChannel';
import { ChannelTypeEnum } from '../../../../curtain/enums/ChannelTypeEnum';
import { ICurtainTopic } from '../../../../curtain/interfaces/ICurtainTopic';

export interface GetChannelsAndTopicsParams {
  orderId: string;
  getStyleChannels?: boolean;
  getLineItemChannels?: boolean;
  getStyleTopics?: boolean;
  getLineItemTopics?: boolean;
  getOtherTopics?: boolean;
}

function* getChannelsAndTopics(action: Action<GetChannelsAndTopicsParams>) {
  try {
    const {
      orderId,
      getStyleChannels,
      getLineItemChannels,
      getStyleTopics,
      getLineItemTopics,
      getOtherTopics,
    } = action.payload!;

    if (getStyleChannels || getStyleTopics) {
      const styleChannels: IChannel[] = yield call(
        ApiService.get,
        `/api/collaboration/channels?orderId=${orderId}&type=${ChannelTypeEnum.STYLE}`
      );
      if (styleChannels) {
        yield put(setStyleChannels(styleChannels));
        if (getStyleTopics) {
          const styleTopics: ICurtainTopic[] = yield all(
            styleChannels.map((channel) =>
              call(
                ApiService.get,
                `/api/collaboration/topics?orderId=${orderId}&channelId=${channel.id}`
              )
            )
          );
          if (styleTopics) {
            yield put(setStyleTopics(styleTopics));
          }
        }
      }
    }
    if (getLineItemChannels || getLineItemTopics) {
      const lineItemChannels: IChannel[] = yield call(
        ApiService.get,
        `/api/collaboration/channels?orderId=${orderId}&type=${ChannelTypeEnum.LINE_ITEM}`
      );
      if (lineItemChannels) {
        yield put(setLineItemChannels(lineItemChannels));
        if (getLineItemTopics) {
          const lineItemTopics: ICurtainTopic[] = yield all(
            lineItemChannels.map((channel) =>
              call(
                ApiService.get,
                `/api/collaboration/topics?orderId=${orderId}&channelId=${channel.id}`
              )
            )
          );
          if (lineItemTopics) {
            yield put(setLineItemTopics(lineItemTopics));
          }
        }
      }
    }

    if (getOtherTopics) {
      const otherTopics: ICurtainTopic[] = yield call(
        ApiService.get,
        `/api/collaboration/topics/other?orderId=${orderId}`
      );
      if (otherTopics) {
        yield put(setOtherTopics(otherTopics));
      }
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  } finally {
    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

export function* topicSagas() {
  yield takeEvery(
    TopicActions.TOPIC_GET_CHANNELS_AND_TOPICS,
    getChannelsAndTopics
  );
}
