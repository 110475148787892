import { AttachmentsModalStepsEnums } from '../enums/AttachmentsModalStepsEnums';
import { IAttachment } from '../../../interface/IAttachment';

export enum attachmentsModalActions {
  CLEAR_ATTACHMENTS_MODAL = 'CLEAR_ATTACHMENTS_MODAL',
  SET_ATTACHMENTS_MODAL_ACTIVE_STEP = 'SET_ATTACHMENTS_MODAL_ACTIVE_STEP',

  SET_SELECTED_ATTACHMENTS = 'SET_SELECTED_ATTACHMENTS',

  UPDATE_MODAL_ATTACHMENT = 'UPDATE_MODAL_ATTACHMENT',
  DELETE_MODAL_ATTACHMENT = 'DELETE_MODAL_ATTACHMENT',

  SET_SELECTED_ATTACHMENT = 'SET_SELECTED_ATTACHMENT',

  UPDATE_ATTACHMENT_MODAL = 'UPDATE_ATTACHMENT_MODAL',

  UPLOAD_FILES = 'UPLOAD_FILES',
}

export const clearAttachmentsModal = () => ({
  type: attachmentsModalActions.CLEAR_ATTACHMENTS_MODAL,
});

export const setSelectedAttachments = (selectedAttachments: IAttachment[]) => ({
  type: attachmentsModalActions.SET_SELECTED_ATTACHMENTS,
  payload: selectedAttachments,
});

export const updateModalAttachment = (attachment: IAttachment) => ({
  type: attachmentsModalActions.UPDATE_MODAL_ATTACHMENT,
  payload: attachment,
});

export const deleteModalAttachment = (attachmentId: string) => ({
  type: attachmentsModalActions.DELETE_MODAL_ATTACHMENT,
  payload: attachmentId,
});

export const setSelectedAttachment = (attachment: IAttachment | null) => ({
  type: attachmentsModalActions.SET_SELECTED_ATTACHMENT,
  payload: attachment,
});

export const setAttachmentsModalActiveStep = (
  value: AttachmentsModalStepsEnums
) => ({
  type: attachmentsModalActions.SET_ATTACHMENTS_MODAL_ACTIVE_STEP,
  payload: value,
});

export const updateAttachmentsModal = (
  step: AttachmentsModalStepsEnums,
  title: AttachmentsModalStepsEnums,
  desc: AttachmentsModalStepsEnums
) => ({
  type: attachmentsModalActions.UPDATE_ATTACHMENT_MODAL,
  payload: { step, title, desc },
});
