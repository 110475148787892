import styled, { css } from 'styled-components';

interface BadgeProps {
  bgColor?: string;
  bgColorHover?: string;
  txtColor?: string;
  marginBottom?: number;
}

export const Badge = styled.div<BadgeProps>`
  padding: 3px 10px;
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.kashmirBlue};
  border-radius: 6px;
  font-weight: 600;
  font-size: 10px;
  display: inline-flex;
  color: ${({ theme, txtColor }) => txtColor ?? theme.white};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0'}px;
  transition: background-color 300ms ease;

  ${({ bgColorHover }) =>
    bgColorHover &&
    css`
      &:hover {
        background-color: ${bgColorHover};
      }
    `}
`;
