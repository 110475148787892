import styled from 'styled-components';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';

import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { Wrapper } from 'shared/components/Wrapper';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { TabbableButton } from 'shared/components/Button';
import { mineShaft, nevada, silverChaliceTwo } from 'shared/config/Colors';
import SVG from 'shared/components/SVG';
import { Spacer } from 'shared/components/Layout';
import { PMedium } from 'shared/components/Typography';
import { FC } from 'react';
import { MoreInfoButton, MoreInfoContainer } from 'shared/components/MoreInfo';

const FilterButton = styled(TabbableButton)`
  display: flex;
  align-items: center;
  padding: 10px 0;

  > * {
    transition: 300ms;
    color: ${({ theme }) => theme.nevada};
  }

  &:hover * {
    color: ${({ theme }) => theme.kashmirBlue};
  }
`;

interface SortedByProps {
  sortedBySequence: boolean;
  onSortBySequence: (sequence: boolean) => void;
}

const SortedBy: FC<SortedByProps> = ({
  sortedBySequence,
  onSortBySequence,
}) => {
  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const onSortBySequenceHandler = (sequence: boolean, close: () => void) => {
    onSortBySequence(sequence);
    close();
  };

  return isOrderSalesMaterial ? null : (
    <>
      <Wrapper flex justifyEnd>
        <MenuWithDropdown
          trigger={
            <FilterButton>
              <SVG icon={<FilterIcon />} color={silverChaliceTwo} />
              <Spacer w="10px" />
              <PMedium>
                Sorted by:{' '}
                <strong>{sortedBySequence ? 'Sequence' : 'Style'}</strong>
              </PMedium>
            </FilterButton>
          }
        >
          {(close: () => void) => (
            <MoreInfoContainer flex column alignStart>
              <MoreInfoButton
                onClick={() => onSortBySequenceHandler(false, close)}
                color={!sortedBySequence ? mineShaft : nevada}
              >
                Style
              </MoreInfoButton>
              <MoreInfoButton
                onClick={() => onSortBySequenceHandler(true, close)}
                color={sortedBySequence ? mineShaft : nevada}
              >
                Sequence
              </MoreInfoButton>
            </MoreInfoContainer>
          )}
        </MenuWithDropdown>
      </Wrapper>
    </>
  );
};

export default SortedBy;
