import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { ReactComponent as DropboxIcon } from 'assets/icons/dropbox.svg';
import { ReactComponent as LaptopIcon } from 'assets/icons/laptop.svg';

import useDropboxPicker from 'shared/hooks/useDropboxPicker';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { H5 } from 'shared/components/Typography';

interface FormInputs {
  attachedFile: FileList;
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 25px 14px 15px 14px;
  background-color: ${({ theme }) => theme.white};
  ${BoxShadowCSS};
  border: 1px solid ${({ theme }) => theme.silver};
  border-radius: 16px;
`;

const MenuHeading = styled.div`
  margin: 0px 26px 12px 14px;
`;

const MenuSelectOption = styled.div`
  display: flex;
  align-items: center;
  height: 49px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.mineShaft};

  :hover {
    background-color: ${({ theme }) => theme.wildSand};
    border-radius: 8px;
    color: ${({ theme }) => theme.chambray};
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.chambray};
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin: 0px 13px;

    path {
      fill: ${({ theme }) => theme.mineShaft};
    }
  }
`;

const LabelForBrowseButton = styled.label`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  cursor: pointer;
`;

const BrowseFilesButton = styled.input`
  display: none;
`;

const SelectCommentAttachmentMenu = ({
  handleFileInput,
  closeDropdown,
  onDropboxFileSelected,
}: {
  handleFileInput: (event: ChangeEvent<HTMLInputElement>) => void;
  closeDropdown: () => void;
  onDropboxFileSelected: (file: File) => void;
}) => {
  const { register } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [openDropboxPicker, dropboxFile, filesCount] = useDropboxPicker();
  const [trackedSelectedCount, setTrackedSelectedCount] = useState(0);

  useEffect(() => {
    if (dropboxFile) {
      onDropboxFileSelected(dropboxFile);

      if (trackedSelectedCount <= filesCount!) {
        setTrackedSelectedCount((prevState) => prevState + 1);
      }
    }
  }, [dropboxFile]);

  useEffect(() => {
    if (filesCount) {
      if (trackedSelectedCount >= filesCount!) {
        closeDropdown();
      }
    }
  }, [trackedSelectedCount]);

  return (
    <MenuContainer>
      <MenuHeading>
        <H5>Add a file from</H5>
      </MenuHeading>
      <MenuSelectOption
        onClick={() => {
          openDropboxPicker();
        }}
      >
        <DropboxIcon />
        Upload from Dropbox
      </MenuSelectOption>
      <MenuSelectOption>
        <LabelForBrowseButton>
          <BrowseFilesButton
            type="file"
            {...register('attachedFile')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleFileInput(e);
              closeDropdown();
            }}
            multiple
          />
          <LaptopIcon />
          Upload from your computer
        </LabelForBrowseButton>
      </MenuSelectOption>
    </MenuContainer>
  );
};

export { SelectCommentAttachmentMenu };
