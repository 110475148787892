import { Style } from 'order/wizard/orderStyles/interface/Style';
import { IFinishEffectField } from 'order/wizard/orderStyles/interface/FinishEffectField';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';

import {
  ICSStyleSpecificationsRequest,
  Inovae2OStyleSpecificationsRequest,
  StyleSpecifications,
  StyleSpecificationsRequest,
} from 'order/wizard/orderStyles/interface/StyleSpecifications';

import {
  FinishImpressionRequest,
  ImpressionData,
} from 'order/wizard/orderStyles/interface/FinishImpression';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import {
  OrderStylesInitialStore,
  SpecificationsOptions,
} from '../orderStylesReducer';

export enum orderStylesSpecificationActions {
  VALIDATE_STYLE_SPECIFICATIONS = 'VALIDATE_STYLE_SPECIFICATIONS',

  SET_STYLES_SPECIFICATIONS = 'SET_STYLES_SPECIFICATIONS',
  CLEAR_STYLE_SPECS_OPTIONS = 'CLEAR_STYLE_SPECS_OPTIONS',

  GET_WOOD_OR_MATERIAL_OPTIONS = 'GET_WOOD_OR_MATERIAL_OPTIONS',
  SET_WOOD_OR_MATERIAL_OPTIONS = 'SET_WOOD_OR_MATERIAL_OPTIONS',
  GET_SPECS_BY_WOOD_OR_MATERIAL = 'GET_SPECS_BY_WOOD_OR_MATERIAL',
  SET_SPECS_BY_WOOD_OR_MATERIAL = 'SET_SPECS_BY_WOOD_OR_MATERIAL',
  SET_WOOD_OR_MATERIAL_SPEC_VALUE = 'SET_WOOD_OR_MATERIAL_SPEC_VALUE',

  SET_SPECS_OPTIONS_BY_OPTIONS_KEY = 'SET_SPECS_OPTIONS_BY_OPTIONS_KEY',

  SAVE_SPECS = 'SAVE_SPECS',
  SAVE_SPECS_IMPRESSIONS = 'SAVE_SPECS_IMPRESSIONS',
  GET_FINISH_EFFECTS_BY_COLOR = 'GET_FINISH_EFFECTS_BY_COLOR',
  GET_FINISH_EFFECTS = 'GET_FINISH_EFFECTS',
  SET_FINISH_EFFECTS = 'SET_FINISH_EFFECTS',
  GET_FINISH_COLORS = 'GET_FINISH_COLORS',
  SET_FINISH_COLORS = 'SET_FINISH_COLORS',
  SET_STYLE_SPECS_DATA = 'SET_STYLE_SPECS_DATA',
  CLEAR_FINISH_COLORS = 'CLEAR_FINISH_COLORS',
  UPDATE_SPEC_OPTION = 'UPDATE_SPEC_OPTION',

  // get set second set of options
  GET_WOOD_EFFECTS_BY_WOOD_EFFECT = 'GET_WOOD_EFFECTS_BY_WOOD_EFFECT',
  SET_WOOD_EFFECT_OPTIONS_2 = 'SET_WOOD_EFFECT_OPTIONS_2',

  // get set second third set of options
  GET_FINISH_EFFECTS_BY_FINISH_EFFECT = 'GET_FINISH_EFFECTS_BY_FINISH_EFFECT',
  GET_ALL_FINISH_EFFECTS_BY_FINISH_EFFECTS = 'GET_ALL_FINISH_EFFECTS_BY_FINISH_EFFECTS',
  SET_FINISH_EFFECT_OPTIONS = 'SET_FINISH_EFFECT_OPTIONS',

  // get finish impressions
  GET_FINISH_IMPRESSION_OPTIONS = 'GET_FINISH_IMPRESSION_OPTIONS',
  SET_FINISH_IMPRESSION_OPTIONS = 'SET_FINISH_IMPRESSION_OPTIONS',

  // get se finish impression data
  GET_FINISH_IMPRESSION_DATA = 'GET_FINISH_IMPRESSION_DATA',
  SET_FINISH_IMPRESSION_DATA = 'SET_FINISH_IMPRESSION_DATA',

  SET_SPECS_FINISH_IMPRESSION_OPTION = 'SET_SPECS_FINISH_IMPRESSION_OPTION',

  SET_SPECS_NOTES = 'SET_SPECS_NOTES',

  // inovae
  GET_CABINETBOX_MATERIALS = 'GET_CABINETBOX_MATERIALS',
  SET_CABINETBOX_MATERIALS = 'SET_CABINETBOX_MATERIALS',

  // inovae 2.O
  GET_MATERIAL_GROUPS = 'GET_MATERIAL_GROUPS',
  SET_MATERIAL_GROUPS = 'SET_MATERIAL_GROUPS',
  GET_MATERIAL_COLORS = 'GET_MATERIAL_COLORS',
  SET_MATERIAL_COLORS = 'SET_MATERIAL_COLORS',
  GET_GRAIN_DIRECTIONS = 'GET_GRAIN_DIRECTIONS',
  SET_GRAIN_DIRECTIONS = 'SET_GRAIN_DIRECTIONS',

  SAVE_INOVAE2O_SPECS = 'SAVE_INOVAE2O_SPECS',

  SET_STYLE_ADJUSTMENT_DATA = 'SET_STYLE_ADJUSTMENT_DATA',

  VALIDATE_INOVAE2O_STYLE_SPECIFICATIONS = 'VALIDATE_INOVAE2O_STYLE_SPECIFICATIONS',

  // ics
  SAVE_ICS_SPECS = 'SAVE_ICS_SPECS',
  GET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS = 'GET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS',
  SET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS = 'SET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS',
}

export interface ValidateStyleSpecificationsRequest {
  orderId: string;
  styleId: string;
  woodMaterialId: string;
}

export const validateStyleSpecifications = (
  payload: ValidateStyleSpecificationsRequest,
  onSuccess: (isValid: boolean) => void,
  loading?: (isLoaded: false) => void
) => ({
  type: orderStylesSpecificationActions.VALIDATE_STYLE_SPECIFICATIONS,
  payload,
  loading,
  onSuccess,
});

export interface ValidateInovae2OStyleSpecificationsRequest {
  orderId: string;
  styleId: string;
  materialGroupId: string;
  materialColorId: string;
}

export const validateInovae2OStyleSpecifications = (
  payload: ValidateInovae2OStyleSpecificationsRequest,
  onSuccess: (isValid: boolean) => void,
  loading?: (isLoaded: false) => void
) => ({
  type: orderStylesSpecificationActions.VALIDATE_INOVAE2O_STYLE_SPECIFICATIONS,
  payload,
  loading,
  onSuccess,
});

export const setStylesSpecifications = (
  specifications: StyleSpecifications | null
) => ({
  type: orderStylesSpecificationActions.SET_STYLES_SPECIFICATIONS,
  payload: specifications,
});

export const clearStyleSpecificationsOptions = () => ({
  type: orderStylesSpecificationActions.CLEAR_STYLE_SPECS_OPTIONS,
});

export const setStyleSpecsData = () => ({
  type: orderStylesSpecificationActions.SET_STYLE_SPECS_DATA,
});

export const getWoodOrMaterial = (
  id: string,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_WOOD_OR_MATERIAL_OPTIONS,
  payload: id,
  loading,
});

export const setWoodOrMaterialOptions = (
  woodOrMaterialOptions: SelectOptionProps[]
) => ({
  type: orderStylesSpecificationActions.SET_WOOD_OR_MATERIAL_OPTIONS,
  payload: woodOrMaterialOptions,
});

export interface GetSpecsOptionsByWoodOrMaterialRequest {
  productLineId: string;
  woodMaterialId: string;
  showAvailableFinishesOnly?: boolean;
  specialFinishSample?: string;
  isOverriden?: boolean;
}

export const getSpecsOptionsByWoodOrMaterial = (
  payload: GetSpecsOptionsByWoodOrMaterialRequest,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_SPECS_BY_WOOD_OR_MATERIAL,
  payload,
  loading,
});

export const setSpecsOptions = (payload: SpecificationsOptions) => ({
  type: orderStylesSpecificationActions.SET_SPECS_BY_WOOD_OR_MATERIAL,
  payload,
});

export const setSpecsFinishImpressionOption = (payload: string | null) => ({
  type: orderStylesSpecificationActions.SET_SPECS_FINISH_IMPRESSION_OPTION,
  payload,
});

export type StyleFields = Pick<
  OrderStylesInitialStore,
  'productLine' | 'name' | 'styleId'
>;

export const saveStyleSpecs = (
  payload: StyleSpecificationsRequest,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.SAVE_SPECS,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const saveStyleSpecsImpressions = (
  payload: StyleSpecificationsRequest,
  onSuccess?: () => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.SAVE_SPECS_IMPRESSIONS,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface FinishEffectsByFinishColorRequest {
  finishColorId: string;
  productLineId: string;
  specialFinishSample?: string;
  woodMaterialId: string;
  showOnlyAvailable?: boolean;
}

export const getFinishEffectsByColor = (
  payload: FinishEffectsByFinishColorRequest
) => ({
  type: orderStylesSpecificationActions.GET_FINISH_EFFECTS_BY_COLOR,
  payload,
});

export interface WoodEffectsByWoodEffectRequest {
  productLineId: string;
  woodMaterialId: string;
  woodEffectId: string;
  isOverriden?: boolean;
}

export const getWoodEffectsByWoodEffect = (
  payload: WoodEffectsByWoodEffectRequest,
  onSuccess?: (woodEffectOptions2: SelectOptionProps[]) => void
) => ({
  type: orderStylesSpecificationActions.GET_WOOD_EFFECTS_BY_WOOD_EFFECT,
  payload,
  onSuccess,
});

export const setWoodEffectOptions2 = (
  woodEffectOptions2: SelectOptionProps[] | null
) => ({
  type: orderStylesSpecificationActions.SET_WOOD_EFFECT_OPTIONS_2,
  payload: woodEffectOptions2,
});

export interface FinishEffectsByFinishEffectRequest {
  finishColorId: string;
  woodMaterialId: string;
  productLineId: string;
  specialFinishSample?: string;
  finishEffectIds: string[];
  optionNumber: string;
  showOnlyAvailable?: boolean;
}

export const getFinishEffectsByFinishEffect = (
  payload: FinishEffectsByFinishEffectRequest
) => ({
  type: orderStylesSpecificationActions.GET_FINISH_EFFECTS_BY_FINISH_EFFECT,
  payload,
});

export interface AllFinishEffectsByFinishEffectsRequest
  extends Omit<
    FinishEffectsByFinishEffectRequest,
    'optionNumber' | 'finishEffectIds' | 'showOnlyAvailable'
  > {
  finishEffects: IFinishEffectField[];
}

export const getAllFinishEffectsByFinishEffects = (
  payload: AllFinishEffectsByFinishEffectsRequest
) => ({
  type: orderStylesSpecificationActions.GET_ALL_FINISH_EFFECTS_BY_FINISH_EFFECTS,
  payload,
});

export interface FinishColorsRequest {
  productLineId: string;
  woodMaterialId: string;
  showOnlyAvailable?: boolean;
  specialFinishSample?: string;
}

export const getFinishColors = (
  payload: FinishColorsRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_FINISH_COLORS,
  payload,
  loading,
});

export const setFinishColors = (payload: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_FINISH_COLORS,
  payload,
});

interface FinishEffectOptions {
  finishEffectOptions: SelectOptionProps[] | null;
  optionNumber: string;
}

export const setFinishEffectOptions = (
  payload: FinishEffectOptions | null
) => ({
  type: orderStylesSpecificationActions.SET_FINISH_EFFECTS,
  payload,
});

export const getFinishImpressionOptions = (productLineId: string) => ({
  type: orderStylesSpecificationActions.GET_FINISH_IMPRESSION_OPTIONS,
  payload: productLineId,
});

export const setFinishImpressionOptions = (payload: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_FINISH_IMPRESSION_OPTIONS,
  payload,
});

export const getFinishImpressionData = (payload: FinishImpressionRequest) => ({
  type: orderStylesSpecificationActions.GET_FINISH_IMPRESSION_DATA,
  payload,
});

export const setFinishImpressionData = (payload: ImpressionData | null) => ({
  type: orderStylesSpecificationActions.SET_FINISH_IMPRESSION_DATA,
  payload,
});

export const clearFinishColors = () => ({
  type: orderStylesSpecificationActions.CLEAR_FINISH_COLORS,
});

export const updateSpecOption = (specOption: SpecificationsOptionsEnum) => ({
  type: orderStylesSpecificationActions.UPDATE_SPEC_OPTION,
  payload: specOption,
});

type SpecNotes = Pick<Style, 'finishNotes' | 'woodNotes'>;

export const setSpecNotes = (payload: SpecNotes) => ({
  type: orderStylesSpecificationActions.SET_SPECS_NOTES,
  payload,
});

export interface UpdateStyleNameRequest {
  orderId: string;
  styleId: string;
  name: string;
}

export const getCabinetBoxMaterials = (payload: string) => ({
  type: orderStylesSpecificationActions.GET_CABINETBOX_MATERIALS,
  payload,
});

export const setCabinetBoxMaterials = (options: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_CABINETBOX_MATERIALS,
  payload: options,
});

export interface GetMaterialGroupsRequest {
  productLineId: string;
}

export const getMaterialGroups = (
  payload: GetMaterialGroupsRequest,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_MATERIAL_GROUPS,
  payload,
  loading,
});

export const setMaterialGroups = (options: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_MATERIAL_GROUPS,
  payload: options,
});

export interface GetMaterialColorsRequest {
  materialGroupId: string;
  productLineId: string;
}

export const getMaterialColors = (
  payload: GetMaterialColorsRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_MATERIAL_COLORS,
  payload,
  loading,
});

export const setMaterialColors = (options: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_MATERIAL_COLORS,
  payload: options,
});

export const getGrainDirections = () => ({
  type: orderStylesSpecificationActions.GET_GRAIN_DIRECTIONS,
});

export const setGrainDirections = (options: SelectOptionProps[]) => ({
  type: orderStylesSpecificationActions.SET_GRAIN_DIRECTIONS,
  payload: options,
});

export const saveInovae2OStyleSpecs = (
  payload: Inovae2OStyleSpecificationsRequest,
  onSuccess: () => void,
  onFailed: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.SAVE_INOVAE2O_SPECS,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface SetSpecsOptionsByOptionsKeyPayload {
  optionsKey: keyof SpecificationsOptions;
  options: SelectOptionProps[];
}

export const setSpecsOptionsByOptionsKey = (
  payload: SetSpecsOptionsByOptionsKeyPayload
) => ({
  type: orderStylesSpecificationActions.SET_SPECS_OPTIONS_BY_OPTIONS_KEY,
  payload,
});

export const saveICSStyleSpecs = (
  payload: ICSStyleSpecificationsRequest,
  onSuccess: () => void,
  onFailed: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.SAVE_ICS_SPECS,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface GetSpecsOptionsByWoodOrMaterialForICSRequest {
  finishColorId: string;
  materialGroupId: string;
  productLineId: string;
  showAvailableFinishesOnly?: boolean;
  specialFinishSample?: string;
  woodMaterialId: string;
  isOverriden?: boolean;
}

export const getSpecsOptionsByWoodOrMaterialForICS = (
  payload: GetSpecsOptionsByWoodOrMaterialForICSRequest,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesSpecificationActions.GET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS,
  payload,
  loading,
});

export const setSpecsOptionsForICS = (payload: SpecificationsOptions) => ({
  type: orderStylesSpecificationActions.SET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS,
  payload,
});
