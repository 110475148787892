export enum userPermissionsValues {
  DEALERSHIP_READ = 'DealershipRead',
  DEALERSHIP_USERS_READ = 'DealershipUsersRead',
  DEALERSHIP_USER_CREATE = 'DealershipUserCreate',
  USER_READ = 'UserRead',
  USER_UPDATE = 'UserUpdate',
  USER_RESET_PASSWORD = 'UserResetPassword',
  USER_CHANGE_STATUS = 'UserChangeStatus',
  PRIVATE_COLLABORATION_CREATE = 'PrivateCollaborationCreate',
  PER_DRAWING_UPDATE = 'PerDrawingUpdate',
  INTERNAL_DOCS_READ = 'InternalDocsRead',
  INTERNAL_DOCS_CREATE = 'InternalDocsCreate',
  TOPIC_CHANGE_PRIORITY = 'TopicChangePriority',
  WORKABLE_READ = 'WorkableRead',
  READ_ASSIGNEE = 'ReadAssignee',
  CAN_VIEW_DEALERSHIP_PREFERENCES = 'ReadDealershipPreferences',
  DEALER_VIEW_PREFERENCES = 'DealerViewPreferences',
  ORDER_CAN_REQUEST_CANCELLATION = 'OrderCanRequestCancellation',
  STYLE_CAN_REQUEST_CANCELLATION = 'StyleCanRequestCancellation',

  // assigness on order
  DELETE_ASSIGNEE = 'DeleteAssignee',
  CREATE_ASSIGNEE = 'CreateAssignee',

  // order
  ORDER_CREATE_DRAFT = 'OrderCreateDraft',
  ORDER_UPDATE = 'OrderUpdate',
  ORDER_DELETE = 'OrderDelete',

  ORDER_GET_SHIP_VIA_OPTIONS = 'OrderGetShipViaOptions',
  ORDER_UPDATE_GRADING_OPTIONS = 'OrderUpdateGradingOptions',
  ORDER_UPDATE_SHIP_VIA_OPTIONS = 'OrderUpdateShipViaOptions',
  ORDER_UPDATE_STATUS = 'OrderUpdateStatus',
  ORDER_VIEW_SCHEDULING = 'OrderViewScheduling',
  ORDER_VIEW_STATUS_LOG = 'OrderViewStatusEditLog',

  // order board,
  DEALER_VIEW_ORDERBOARD = 'DealerViewOrderBoard',
  CSR_VIEW_ORDERBOARD = 'CsrViewOrderBoard',

  CSR_USER_READ = 'CsrUserRead',
  CSR_USER_CREATE = 'CsrUserCreate',

  // ack
  ACKNOWLEDGEMENT_SEND = 'AcknowledgementSend',
  OVERRIDE_MIDRAIL = 'OverrideMidrail',

  CSR_VIEW_SYNCHRONIZATION_STATUS = 'CsrViewSynchronizationStatus',

  ORDER_CREATE_DRAFT_ON_BEHALF_OF_DEALER = 'OrderCreateDraftOnBehalfOfDealer',
  ORDER_UPDATE_ON_BEHALF_OF_DEALER = 'OrderUpdateOnBehalfOfDealer',

  SALES_REPRESENTATIVE_VIEW_ORDER_BOARD = 'SalesRepresentativeViewOrderBoard',
}
