import styled from 'styled-components';
import { FC } from 'react';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { H6 } from 'shared/components/Typography';
import { useFileDownload } from 'shared/hooks/useFileDownload';
import Loader from './Loader';
import ElipsisText from './ElipsisText';

interface CommentAttachmentDownloadProps {
  fileName: string;
  fileId: string;
}

const A = styled.a`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.wildSand};
  color: ${({ theme }) => theme.mineShaft};
  padding: 7px 10px;
  margin-bottom: 6px;
  border-radius: 4px;
  font-weight: 600;
  :hover {
    text-decoration: none;

    svg > path {
      fill: ${({ theme }) => theme.chambray};
    }
  }
`;

export const CommentAttachmentDownload: FC<CommentAttachmentDownloadProps> = ({
  fileName,
  fileId,
}) => {
  const { fileDownloadHandler, fileDownloading } = useFileDownload();

  return (
    <A
      onClick={(e) => {
        e.preventDefault();
        fileDownloadHandler(fileId);
      }}
    >
      <H6>
        <ElipsisText maxWidth={280}>{fileName}</ElipsisText>
      </H6>

      {fileDownloading ? <Loader noSpacing size={16} /> : <DownloadIcon />}
    </A>
  );
};
