import 'react-toastify/dist/ReactToastify.css';
import DateFnsUtils from '@date-io/date-fns';
import env from 'env_config';
import { Location } from 'history';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RootState } from 'store';
import { Slide, toast } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { NonAuthRoute } from 'routes/NonAuthRoute';
import { PrivateRoute } from 'routes/PrivateRoute';
import { RenderPageNotFound } from 'routes/RenderPageNotFound';

import Auth from 'auth/pages/Auth';
import { ForbiddenPage } from 'auth/pages/ForbiddenPage';
import { NotFoundPage } from 'auth/pages/NotFoundPage';
import { getUserSagaAction } from 'auth/store/authActions';

import { Notification } from 'notifications/Notification';
import {
  getNotifsCounts,
  setSyncToolFailNotification,
} from 'notifications/store/notificationsActions';

import {
  INotification,
  NotificationTypeEnums,
} from 'overview/interface/Notification';
import { OverviewPage } from 'overview/pages/OverviewPage';

import OrderTickContainer from 'order/pages/OrderTickContainer';

import * as colors from 'shared/config/Colors';
import CloseButton from 'shared/components/CloseButton';
import { DatePickerModalStyles } from 'shared/components/FormDatePicker';
import { GlobalStyles } from 'shared/config/GlobalStyles';
import { Toast } from 'shared/components/Toast';
import { toastNotifOptions } from 'shared/options/ToasterNotifOptions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useConnectionOpen } from 'shared/hooks/useConnectionOpen';
import { useIsUserLoggedIn } from 'shared/hooks/useIsUserLoggedIn';

import './GlobalFonts.css';
import { finishActivateDealership } from 'overview/store/overviewActions';

const optimizely = createInstance({
  sdkKey: env.optiSKDKey,
});

function App() {
  const history = useHistory();

  const location = useLocation();

  const isUserLoggedIn = useIsUserLoggedIn();

  const dispatch = useAppDispatch();

  const me = useSelector((state: RootState) => state.authReducer.user);

  const onNotifReceive = (notif: unknown) => {
    const notification = notif as INotification;

    if (
      notification.type === NotificationTypeEnums.ACTIVATE_DEALERSHIP_SUCCESS ||
      notification.type === NotificationTypeEnums.ACTIVATE_DEALERSHIP_FAILED
    ) {
      dispatch(finishActivateDealership(notification));
      return;
    }
    if (
      notification.type === NotificationTypeEnums.ORDER_SYNCHRONIZATION_FAILED
    ) {
      dispatch(setSyncToolFailNotification(notification));
    }

    toast(
      <Notification
        notification={notification}
        inToast
        showCollaborationOnly={
          notification.type === NotificationTypeEnums.COMMENT_CREATED
        }
      />,
      toastNotifOptions
    );
    dispatch(getNotifsCounts());
  };

  useConnectionOpen({
    onReceiveData: onNotifReceive,
    event: 'ReceiveNotification',
    url: 'notifications',
    shouldOpenConnection: true,
  });

  const [isOrderPage, setIsOrderPage] = useState(
    location.pathname.includes('/order/')
  );

  const focusEl = (e: KeyboardEvent) => {
    const focusEvent = new Event('focus');
    e.target?.dispatchEvent(focusEvent);
  };

  useEffect(() => {
    window.addEventListener('keyup', focusEl);

    if (isUserLoggedIn) {
      dispatch(getUserSagaAction());
    }

    const historyUnsubscribe = history.listen((update) => {
      const loc = update as unknown as Location;
      setIsOrderPage(loc.pathname.includes('/order/'));
    });

    return () => {
      historyUnsubscribe();
    };
  }, []);

  return (
    <OptimizelyProvider optimizely={optimizely} user={me ?? undefined}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={colors}>
          <Toast
            position="bottom-left"
            transition={Slide}
            style={{ bottom: isOrderPage ? '4.5em' : '1em' }}
            hideProgressBar
            closeButton={<CloseButton />}
          />

          <GlobalStyles />
          <DatePickerModalStyles />

          <Switch>
            <NonAuthRoute path="/authentication" component={Auth} />

            <PrivateRoute path="/overview" component={OverviewPage} />

            <PrivateRoute
              exact
              path={[
                '/order',
                '/order/new',
                '/order/asap/',
                '/order/duplicate/',
                '/order/:orderId/overview',
                '/order/:orderId/adjustments',
                '/order/:orderId/styles',
                '/order/:orderId/line-items',
                '/order/:orderId/attachments',
                '/order/:orderId/order-overview',
                '/order/:orderId/review-pricing',
                '/order/:orderId/acknowledgment',
                '/quote/new',
              ]}
              component={OrderTickContainer}
            />

            <Route exact path="/">
              {isUserLoggedIn ? (
                <Redirect to="/overview" />
              ) : (
                <Redirect to="/authentication/login" />
              )}
            </Route>

            <Route path="/forbidden">
              <ForbiddenPage />
            </Route>

            <Route path="/page-not-found">
              <NotFoundPage />
            </Route>

            <RenderPageNotFound />
          </Switch>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </OptimizelyProvider>
  );
}

export default App;
