import { RootState } from 'store';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { Order } from 'order/interfaces/Order';
import { OrderLineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { ProductLine } from 'order/interfaces/ProductLine';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import { setOrderPriority } from 'order/store/orderActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { IAttachment } from 'shared/interface/IAttachment';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  AutomaticModificationsRequest,
  CategoriesRequest,
  GetLineItemStyleRequest,
  LineItemCatalogRequest,
  LineItemChangeNumbersRequest,
  LineItemClientNumberRequest,
  LineItemDefaultDataRequest,
  LineItemDeleteRequest,
  LineItemDuplicateRequest,
  LineItemMetaByWHDRequest,
  LineItemSMDefaultDataRequest,
  ModificationPricesRequest,
  OrderLineItemDataRequest,
  SaveLineItemAttachmentsRequest,
  SubcategoriesRequest,
  ValidModsRequest,
  clearAttachmentsForDeleteList,
  orderLineItemActions,
  setAutomaticModifications,
  setCategories,
  setLineItemAttachments,
  setLineItemCatalog,
  setLineItemDefaultData,
  setLineItemDoorCodesByWHD,
  setLineItemMetaByWHD,
  setLineItemStyle,
  setModifications,
  setOrderLineItemData,
  setSubcategories,
  updateModificationPrices,
} from './orderLineItemsActions';

import { ILineItemCatalogItem } from '../interface/ILineItemCatalog';
import { ILineItemCategory } from '../interface/IlineItemCategory';

import {
  ILineItemDefaultData,
  ILineItemDefaultDataSM,
} from '../interface/ILineItemData';

import {
  ConfigureLineItemRequest,
  ConfigureLineItemSMRequest,
  IConfigureLineItemMeta,
} from '../interface/IConfigureLineItem';

import { IModification } from '../components/Modifications/interface/IModification';

function* getLineItemCatalog(action: Action<LineItemCatalogRequest>) {
  try {
    const {
      itemsPerPage,
      productLineId,
      page,
      searchTerm,
      styleId,
      categoryId,
      subCategoryId,
      orderType,
      appendItems,
      doorOverlayId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('productLineId', productLineId);
    queryParams.append('orderType', orderType);

    if (doorOverlayId) {
      queryParams.append('doorOverlayId', doorOverlayId);
    }

    if (styleId) {
      queryParams.append('styleId', styleId);
    }

    if (searchTerm) {
      queryParams.append('searchTerm', searchTerm);
    }

    if (categoryId) {
      queryParams.append('categoryId', categoryId);
    }

    if (subCategoryId) {
      queryParams.append('subCategoryId', subCategoryId);
    }

    const lineItemCatalog: PaginatedItems<ILineItemCatalogItem> = yield call(
      ApiService.get,
      `api/catalog/lineitems`,
      { params: queryParams }
    );

    yield put(setLineItemCatalog({ lineItemCatalog, appendItems }));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCategories(action: Action<CategoriesRequest>) {
  try {
    const { doorOverlayId, orderType, productLineId, styleId } =
      action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);
    queryParams.append('orderType', orderType);
    queryParams.append('doorOverlayId', doorOverlayId);
    queryParams.append('styleId', styleId);

    const categories: ILineItemCategory[] = yield call(
      ApiService.get,
      `api/catalog/lineitems/categories`,
      { params: queryParams }
    );

    const allProductsCategory: ILineItemCategory = {
      id: '0',
      name: 'All products',
      lineItemCount: 0,
    };

    categories.unshift(allProductsCategory);

    yield put(
      setCategories(
        categories.filter((cat) => {
          return cat.id === '0' || cat.lineItemCount > 0;
        })
      )
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getSubcategories(action: Action<SubcategoriesRequest>) {
  try {
    const { productLineId, categoryId, doorOverlayId, orderType, styleId } =
      action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);
    queryParams.append('orderType', orderType);
    queryParams.append('doorOverlayId', doorOverlayId);
    queryParams.append('styleId', styleId);

    const subcategories: ILineItemCategory[] = yield call(
      ApiService.get,
      `api/catalog/lineitems/categories/${categoryId}/subcategories`,
      { params: queryParams }
    );

    yield put(
      setSubcategories(
        subcategories.filter((subcat) => subcat.lineItemCount > 0)
      )
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLineItemDefaultData(action: Action<LineItemDefaultDataRequest>) {
  try {
    const { lineItemId, productLineId, styleId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);

    if (styleId) {
      queryParams.append('styleId', styleId);
    }

    const lineItemData: ILineItemDefaultData = yield call(
      ApiService.get,
      `/api/catalog/lineitems/${lineItemId}`,
      { params: queryParams }
    );

    yield put(setLineItemDefaultData(lineItemData));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLineItemSMDefaultData(
  action: Action<LineItemSMDefaultDataRequest>
) {
  try {
    const { lineItemId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);

    const lineItemData: ILineItemDefaultDataSM = yield call(
      ApiService.get,
      `/api/catalog/lineitems/${lineItemId}/salesmaterial`,
      { params: queryParams }
    );

    yield put(setLineItemDefaultData(lineItemData));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderLineItemData(action: Action<OrderLineItemDataRequest>) {
  try {
    const { orderId, orderLineItemId } = action.payload!;

    const orderLineItemData: OrderLineItem = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/lineitems/${orderLineItemId}`
    );

    yield put(setOrderLineItemData(orderLineItemData));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderLineItemSMData(action: Action<OrderLineItemDataRequest>) {
  try {
    const { orderId, orderLineItemId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();

    if (productLineId) queryParams.append('productLineId', productLineId);

    const orderLineItemData: OrderLineItem = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/salesmaterial-lineitems/${orderLineItemId}`,
      {
        params: queryParams,
      }
    );

    yield put(setOrderLineItemData(orderLineItemData));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveLineItemAttachments(
  action: Action<SaveLineItemAttachmentsRequest>
) {
  try {
    const { orderId, orderLineItemId, attachments } = action.payload!;

    // pull out attachments for deletion
    const attachmentsForDeletion: IAttachment[] = yield select(
      (state: RootState) =>
        state.orderLineItemsReducer.lineItemAttachmentsForDelete
    );

    // delete attachments
    for (let i = 0; i < attachmentsForDeletion.length; i += 1) {
      yield call(
        ApiService.delete,
        `/api/order/orders/${orderId}/attachments/${attachmentsForDeletion[i].id}`
      );
    }

    // pull out saved attachments
    const savedAttachments: IAttachment[] | undefined = yield select(
      (state: RootState) =>
        state.orderLineItemsReducer.orderLineItemData?.attachments
    );

    // check if there is a new attachments for saving
    const attachmentsForSaving = attachments?.filter(
      (filterAtt) =>
        !savedAttachments?.some((someAtt) => filterAtt.id === someAtt.id)
    );

    if (attachmentsForSaving?.length) {
      // save only new attachments
      yield call(ApiService.post, `/api/order/orders/${orderId}/attachments`, {
        attachments: attachmentsForSaving.map((att) => ({
          ...att,
          lineItemId: orderLineItemId,
        })),
      });

      const orderData: OrderLineItem = yield call(
        ApiService.get,
        `/api/order/orders/${orderId}/lineitems/${orderLineItemId}`
      );

      yield put(
        setLineItemAttachments(
          orderData.attachments.map((att) => ({ ...att, completed: true }))
        )
      );
    }

    yield put(clearAttachmentsForDeleteList());

    if (action.onSuccess) {
      yield call(action.onSuccess, attachmentsForSaving);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateLineItem(action: Action<ConfigureLineItemRequest>) {
  try {
    const { orderId, orderLineItemId, attachments, modifications, ...rest } =
      action.payload!;

    const newOrderLineItemId: string = yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/lineitems/${orderLineItemId ?? 'replace'}`,
      rest
    );

    const mods =
      (modifications ?? []).map((mod) => {
        return {
          ...mod,
          id: mod.withTempId ? undefined : mod.id,
        } as IModification;
      }) ?? [];

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/lineitems/${
        orderLineItemId ?? newOrderLineItemId
      }/modifications`,
      { modifications: mods }
    );

    // orderLineItemId === undefined meaning replacing line item is on progress
    if (orderLineItemId === undefined) {
      yield call(saveLineItemAttachments, {
        type: orderLineItemActions.SAVE_LINE_ITEM_ATTACHMENTS,
        payload: {
          attachments,
          orderId,
          orderLineItemId: newOrderLineItemId,
        },
      } as Action<SaveLineItemAttachmentsRequest>);
    }

    const orderLineItemData: OrderLineItem = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/lineitems/${
        orderLineItemId ?? newOrderLineItemId
      }`
    );

    yield put(setOrderLineItemData(orderLineItemData));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, newOrderLineItemId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateLineItemSM(action: Action<ConfigureLineItemSMRequest>) {
  try {
    const { orderId, orderLineItemId, modifications, ...rest } =
      action.payload!;

    const newOrderLineItemId: string = yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/salesmaterial-lineitems/${
        orderLineItemId ?? 'replace'
      }`,
      rest
    );

    const mods =
      (modifications ?? []).map((mod) => {
        return {
          ...mod,
          id: mod.withTempId ? undefined : mod.id,
        } as IModification;
      }) ?? [];

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/lineitems/${
        orderLineItemId ?? newOrderLineItemId
      }/modifications`,
      { modifications: mods }
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getAutomaticModifications(
  action: Action<AutomaticModificationsRequest>
) {
  try {
    const {
      productLineId,
      lineitemId,
      lineItemPrice,
      width,
      height,
      depth,
      styleId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('depth', depth);
    queryParams.append('height', height);
    queryParams.append('lineitemId', lineitemId);
    queryParams.append('lineItemPrice', lineItemPrice);
    queryParams.append('productLineId', productLineId);
    queryParams.append('styleId', styleId);
    queryParams.append('width', width);

    const mods: IModification[] = yield call(
      ApiService.get,
      `/api/catalog/modifications/automatic`,
      {
        params: queryParams,
      }
    );

    yield put(
      setAutomaticModifications(
        mods.map(
          (mod, index) =>
            ({
              ...mod,
              catalogModificationId: mod.id,
              id: uuidv4(),
              withTempId: true,
              note: '',
              additionalNote: '',
              isAutomatic: true,
              completed: true,
              quantity: 1,
              number: index + 1,
            } as IModification)
        )
      )
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getModificationPrices(action: Action<ModificationPricesRequest>) {
  try {
    const {
      modificationIds,
      productLineId,
      lineitemId,
      lineItemPrice,
      width,
      height,
      depth,
      styleId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    modificationIds.forEach((modId) => {
      queryParams.append('modificationIds', modId);
    });

    queryParams.append('lineItemDepth', depth);
    queryParams.append('lineItemHeight', height);
    queryParams.append('lineItemId', lineitemId);
    queryParams.append('lineItemPrice', lineItemPrice);
    queryParams.append('lineItemWidth', width);
    queryParams.append('productLineId', productLineId);
    if (styleId) {
      queryParams.append('styleId', styleId);
    }

    const modPrices: Pick<IModification, 'id' | 'price'>[] = yield call(
      ApiService.get,
      `/api/catalog/modifications/prices`,
      {
        params: queryParams,
      }
    );

    yield put(updateModificationPrices(modPrices));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveLineItem(action: Action<ConfigureLineItemRequest>) {
  try {
    const { orderId, modifications, attachments, ...rest } = action.payload!;

    const savedLineItemId: string = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/lineitems`,
      rest
    );

    if (modifications && modifications.length > 0) {
      const mods = modifications.map((mod) => {
        return {
          ...mod,
          id: mod.withTempId ? undefined : mod.id,
        } as IModification;
      });

      yield call(
        ApiService.post,
        `/api/order/orders/${orderId}/lineitems/${savedLineItemId}/modifications`,
        { modifications: mods }
      );
    }

    if (attachments?.length) {
      yield call(ApiService.post, `/api/order/orders/${orderId}/attachments`, {
        attachments: attachments.map((att) => ({
          ...att,
          lineItemId: savedLineItemId,
        })),
      });
    }

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveLineItemSM(action: Action<ConfigureLineItemSMRequest>) {
  try {
    const { orderId, modifications, ...rest } = action.payload!;

    const savedLineItemId: string = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/salesmaterial-lineitems`,
      rest
    );

    if (modifications && modifications.length > 0) {
      const mods = modifications.map((mod) => {
        return {
          ...mod,
          id: mod.withTempId ? undefined : mod.id,
        } as IModification;
      });

      yield call(
        ApiService.post,
        `/api/order/orders/${orderId}/lineitems/${savedLineItemId}/modifications`,
        { modifications: mods }
      );
    }

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, savedLineItemId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteLineItem(action: Action<LineItemDeleteRequest>) {
  try {
    const { orderId, lineItemId } = action.payload!;

    yield call(
      ApiService.delete,
      `/api/order/orders/${orderId}/lineitems/${lineItemId}`
    );

    const order: Order = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}`
    );

    yield put(setOrderPriority(order.priority!));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* duplicateLineItem(action: Action<LineItemDuplicateRequest>) {
  const { orderId, lineItemId } = action.payload!;
  try {
    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/lineitems/${lineItemId}/duplicate`,
      {}
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changeLineItemClientNumber(
  action: Action<LineItemClientNumberRequest>
) {
  try {
    const { orderId, lineItemId, clientNumber } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/lineitems/${lineItemId}/clientnumber`,
      { clientNumber }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}
function* changeLineItemNumbers(action: Action<LineItemChangeNumbersRequest>) {
  try {
    const { orderId, lineItemChangeNumbers } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/lineitems/numbers`,
      [...lineItemChangeNumbers]
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLineItemMetaByWHD(action: Action<LineItemMetaByWHDRequest>) {
  try {
    const {
      lineItemId,
      productLineId,
      width,
      height,
      depth,
      styleId,
      rightDepth,
      rightWidth,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);
    queryParams.append('width', width.toString());
    queryParams.append('height', height.toString());
    queryParams.append('depth', depth.toString());

    queryParams.append('rightWidth', (rightWidth ?? 0).toString());
    queryParams.append('rightDepth', (rightDepth ?? 0).toString());

    if (styleId) {
      queryParams.append('styleId', styleId);
    }

    const lineItemMeta: IConfigureLineItemMeta = yield call(
      ApiService.get,
      `/api/catalog/lineitems/${lineItemId}/price`,
      {
        params: queryParams,
      }
    );

    yield put(setLineItemMetaByWHD(lineItemMeta));

    // call door codes if style id provided
    if (styleId) {
      const productLines: ProductLine[] | null = yield select(
        (state: RootState) => state.sharedReducer.productLines
      );

      const productLine = productLines?.find((pl) => pl.id === productLineId);

      const apiUrl =
        productLine?.name !== ProductLineEnums.PRODUCT_LINE_INOVAE2O &&
        productLine?.name !== ProductLineEnums.PRODUCT_LINE_REVOLAE
          ? `/api/catalog/lineitems/${lineItemId}/doorcodes`
          : `/api/catalog/lineitems/${lineItemId}/doorcodes/material`;
      const doorCodes: IDoorCode = yield call(ApiService.get, apiUrl, {
        params: queryParams,
      });

      yield put(setLineItemDoorCodesByWHD(doorCodes));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);

    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

function* getLineItemStyle(action: Action<GetLineItemStyleRequest>) {
  try {
    const { orderId, styleId } = action.payload!;

    const style: Style = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}`
    );

    yield put(setLineItemStyle(style));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);

    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

function* getValidMods(action: Action<ValidModsRequest>) {
  try {
    const { orderId, existingLineItemId, catalogLineItemId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('existingLineItemId', existingLineItemId);
    queryParams.append('catalogLineItemId', catalogLineItemId);

    const validMods: string[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/lineitems/valid-modifications`,
      {
        params: queryParams,
      }
    );

    const lineItemMods: IModification[] = yield select(
      (state: RootState) => state.orderLineItemsReducer.lineItemModifications
    );

    yield put(
      setModifications(
        lineItemMods
          .filter((mod) => validMods.includes(mod.id))
          ?.map((mod, modIndex) => ({ ...mod, number: modIndex + 1 }))
      )
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);

    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

export function* orderLineItemsSagas() {
  yield takeEvery(
    orderLineItemActions.GET_LINE_ITEM_CATALOG,
    getLineItemCatalog
  );
  yield takeEvery(orderLineItemActions.GET_CATEGORIES, getCategories);
  yield takeEvery(orderLineItemActions.GET_SUBCATEGORIES, getSubcategories);
  yield takeEvery(
    orderLineItemActions.GET_LINE_ITEM_DEFAULT_DATA,
    getLineItemDefaultData
  );
  yield takeEvery(
    orderLineItemActions.GET_LINE_ITEM_SM_DEFAULT_DATA,
    getLineItemSMDefaultData
  );
  yield takeEvery(
    orderLineItemActions.GET_ORDER_LINE_ITEM_DATA,
    getOrderLineItemData
  );
  yield takeEvery(
    orderLineItemActions.GET_ORDER_LINE_ITEM_SM_DATA,
    getOrderLineItemSMData
  );

  yield takeEvery(orderLineItemActions.SAVE_LINE_ITEM, saveLineItem);
  yield takeEvery(orderLineItemActions.UPDATE_LINE_ITEM, updateLineItem);

  yield takeEvery(orderLineItemActions.SAVE_LINE_ITEM_SM, saveLineItemSM);
  yield takeEvery(orderLineItemActions.UPDATE_LINE_ITEM_SM, updateLineItemSM);

  yield takeEvery(orderLineItemActions.DELETE_LINE_ITEM, deleteLineItem);
  yield takeEvery(orderLineItemActions.DUPLICATE_LINE_ITEM, duplicateLineItem);
  yield takeEvery(
    orderLineItemActions.SAVE_LINE_ITEM_ATTACHMENTS,
    saveLineItemAttachments
  );
  yield takeEvery(
    orderLineItemActions.CHANGE_LINE_ITEM_CLIENT_NUMBER,
    changeLineItemClientNumber
  );
  yield takeEvery(
    orderLineItemActions.CHANGE_LINE_ITEM_NUMBERS,
    changeLineItemNumbers
  );

  yield takeEvery(
    orderLineItemActions.GET_LINE_ITEM_META_BY_WHD,
    getLineItemMetaByWHD
  );
  yield takeEvery(orderLineItemActions.GET_LINE_ITEM_STYLE, getLineItemStyle);
  yield takeEvery(
    orderLineItemActions.GET_AUTOMATIC_MODS,
    getAutomaticModifications
  );
  yield takeEvery(orderLineItemActions.GET_MOD_PRICES, getModificationPrices);
  yield takeEvery(orderLineItemActions.GET_VALID_MODS, getValidMods);
}
