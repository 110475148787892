import { FC, ReactElement, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';

import {
  ButtonSecondary,
  ButtonDefault,
  ButtonPrimaryCSS,
  ButtonSecondaryCSS,
  TabbableButton,
} from 'shared/components/Button';

import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { lynch, nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';

import { ConfirmationModalTestEnum } from 'tests/enums/ConfirmationModalTestEnums';
import { testId } from 'tests/utils';

import Loader from './Loader';

interface ConfirmationModalProps {
  opened?: boolean;
  trigger?: ReactElement;
  title: string;
  message?: string;
  confirm: (
    close: () => void,
    loading: (isLoading: boolean) => void
  ) => unknown;
  cancel?: () => void;
  additionalAction?: () => void;
  buttonText?: string;
  cancelButtonText?: string;
  htmlMessage?: string;
  nested?: boolean;
  confirmBgColor?: string;
  shouldNotRenderCancelButton?: boolean;
  shouldNotRenderSubmitButton?: boolean;
  confirmAsSecondary?: boolean;
  className?: string;
  bottomSpacing?: number;
  buttonDisabled?: boolean;
  disableLoading?: boolean;
}

interface ConfirmButtonProps {
  confirmAsSecondary: boolean;
  bgColor?: string;
}

const ConfirmButton = styled(ButtonDefault)<ConfirmButtonProps>`
  ${({ confirmAsSecondary }) =>
    !confirmAsSecondary ? ButtonPrimaryCSS : ButtonSecondaryCSS}
`;

const ChildrenContainer = styled(Wrapper)`
  > p {
    color: inherit;
  }
`;

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  buttonText,
  cancel,
  additionalAction,
  cancelButtonText,
  confirm,
  htmlMessage,
  message,
  nested,
  opened,
  title,
  trigger,
  confirmBgColor,
  shouldNotRenderCancelButton,
  shouldNotRenderSubmitButton,
  confirmAsSecondary,
  children,
  className,
  bottomSpacing,
  buttonDisabled,
  disableLoading,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [isSecondaryLoading, setSecondaryIsloading] = useState(false);

  const [modalIsOpened, setModalIsOpened] = useState(false);

  const onClickHandler = (close: () => void) => {
    setIsloading(!disableLoading);

    const returnFunction = confirm(close, setIsloading);

    if (typeof returnFunction === 'function') {
      (returnFunction as () => void)();
    }
  };

  const onSecondaryClickHandler = (close: () => void) => {
    setSecondaryIsloading(true);
    close();
    if (cancel) {
      cancel();
    }
  };

  const onAdditionalActionClickHandler = (
    callback: () => void,
    close: () => void
  ) => {
    close();
    callback();
  };

  useEffect(() => {
    return () => {
      if (modalIsOpened) {
        setIsloading(false);
        setSecondaryIsloading(false);
      }
    };
  }, [modalIsOpened]);
  return (
    <ModalWrapper
      modal
      trigger={trigger ?? undefined}
      lockScroll
      nested={nested}
      closeOnDocumentClick={false}
      className={className}
      onClose={() => {
        UtilService.onPopupClose();
        setModalIsOpened(false);
      }}
      onOpen={() => {
        UtilService.onPopupOpen();
        setModalIsOpened(true);
      }}
      open={opened}
      closeOnEscape={false}
    >
      {(close: () => void) => (
        <Modal
          title={title}
          confirm
          withoutFooter={0}
          onSetLoading={setIsloading}
        >
          {message && (
            <Wrapper color={nevada} {...testId(ConfirmationModalTestEnum.BODY)}>
              {message}
            </Wrapper>
          )}

          {htmlMessage && (
            <Wrapper color={nevada} {...testId(ConfirmationModalTestEnum.BODY)}>
              {parse(htmlMessage)}
            </Wrapper>
          )}

          {children && (
            <ChildrenContainer
              color={nevada}
              {...testId(ConfirmationModalTestEnum.BODY)}
            >
              {children}
            </ChildrenContainer>
          )}

          <Spacer h={`${bottomSpacing}px`} />

          <Wrapper flex middle justifyEnd>
            {additionalAction && (
              <Wrapper mrAuto>
                <TabbableButton
                  color={lynch}
                  onClick={() =>
                    onAdditionalActionClickHandler(additionalAction, close)
                  }
                >
                  Cancel Replacement
                </TabbableButton>
              </Wrapper>
            )}

            {!shouldNotRenderCancelButton && (
              <ButtonSecondary
                onClick={() => onSecondaryClickHandler(close)}
                disabled={isSecondaryLoading}
                {...testId(ConfirmationModalTestEnum.CANCEL_BTN)}
              >
                {cancelButtonText}
                <Loader
                  hidden={!isSecondaryLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>
            )}

            <Spacer w="16px" />
            {!shouldNotRenderSubmitButton && (
              <ConfirmButton
                onClick={() => onClickHandler(close)}
                disabled={isLoading || buttonDisabled}
                bgColor={confirmBgColor}
                confirmAsSecondary={confirmAsSecondary ?? false}
                {...testId(ConfirmationModalTestEnum.CONFIRM_BTN)}
              >
                {buttonText}
                <Loader hidden={!isLoading} insideButton noSpacing size={16} />
              </ConfirmButton>
            )}
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};

ConfirmationModal.defaultProps = {
  opened: undefined,
  cancel: undefined,
  trigger: undefined,
  buttonText: 'Delete',
  cancelButtonText: 'Cancel',
  shouldNotRenderCancelButton: false,
  confirmAsSecondary: false,
  bottomSpacing: 110,
  buttonDisabled: false,
};

export { ConfirmationModal };
