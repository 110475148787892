import { all, call } from 'redux-saga/effects';

import authSagas from 'auth/store/authSagas';

import { curtainSagas } from 'curtain/store/curtainSagas';
import { curtainWorkablesSagas } from 'curtain/tabs/CurtainWorkablesTab/store/curtainWorkablesSagas';

import { dealershipSagas } from 'overview/dealership/store/dealershipSagas';
import { editUserDetailsSagas } from 'overview/editUserDetails/store/editUserDetailsSagas';
import { manageUsersSaga } from 'overview/manageUsers/store/manageUsersSagas';
import { notificationsSagas } from 'notifications/store/notificationsSagas';
import { ordersSagas } from 'overview/orders/store/ordersSagas';
import { overviewSagas } from 'overview/store/overviewSagas';
import { quotesSagas } from 'overview/quotes/store/quotesSagas';

import orderAsapSagas from 'order/wizard/orderASAP/store/OrderAsapSagas';
import { ACKSagas } from 'order/wizard/orderAcknowledgement/store/ACKSagas';
import { ackChangeLogSagas } from 'order/shared/ackchangelog/store/ackchangelogSagas';
import { lineItemModificationsSagas } from 'order/wizard/orderLineItems/components/Modifications/store/lineItemModificationsSagas';
import { orderAssigneesSagas } from 'order/components/OrderAssignees/store/orderAssigneesSagas';
import { orderAttachmentsSagas } from 'order/wizard/orderAttachments/store/orderAttachmentsSagas';
import { orderLineItemsSagas } from 'order/wizard/orderLineItems/store/orderLineItemsSagas';
import { orderSagas } from 'order/store/orderSagas';
import { orderStylesAdjustmentsSagas } from 'order/wizard/orderStyles/productLines/store/adjustments/orderStylesAdjustmentsSagas';
import { orderStylesDoorBuilderSagas } from 'order/wizard/orderStyles/productLines/store/doorBuilder/orderStylesDoorBuilderSagas';
import { orderStylesSagas } from 'order/wizard/orderStyles/productLines/store/orderStylesSagas';
import { orderStylesSpecificationSagas } from 'order/wizard/orderStyles/productLines/store/specifications/orderStylesSpecificationSagas';
import { popupSagas } from 'order/popups/store/popupSagas';
import { warningsSagas } from 'order/warnings/store/warningsSagas';
import { writingHintsSagas } from 'order/writingHints/store/writingHintsSagas';

import { attachmentsModalSagas } from 'shared/components/AttachmentsModal/store/attachmentsModalSagas';
import { sharedSagas } from 'shared/store/sharedSagas';

import { additionalFieldsSagas } from 'order/additonalFields/store/additionalFieldsSagas';
import { importSagas } from './order/wizard/import/store/importSagas';
import { topicSagas } from './order/wizard/topic/store/TopicSagas';

export function* appSagas() {
  yield all([
    call(authSagas),
    call(curtainSagas),
    call(dealershipSagas),
    call(editUserDetailsSagas),
    call(manageUsersSaga),
    call(ordersSagas),
    call(orderAttachmentsSagas),
    call(orderLineItemsSagas),
    call(lineItemModificationsSagas),
    call(orderSagas),
    call(orderStylesDoorBuilderSagas),
    call(orderStylesSpecificationSagas),
    call(orderStylesSagas),
    call(orderStylesAdjustmentsSagas),
    call(sharedSagas),
    call(curtainWorkablesSagas),
    call(attachmentsModalSagas),
    call(orderAsapSagas),
    call(orderAssigneesSagas),
    call(popupSagas),
    call(overviewSagas),
    call(notificationsSagas),
    call(ACKSagas),
    call(warningsSagas),
    call(writingHintsSagas),
    call(ackChangeLogSagas),
    call(importSagas),
    call(topicSagas),
    call(quotesSagas),
    call(additionalFieldsSagas),
  ]);
}
