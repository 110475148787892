import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { ButtonIcon } from './Button';
import { Tooltip } from './Tooltip';

const MenuWithDropdown = styled(Popup)`
  &-content[role='tooltip'] {
    min-width: 200px;
    width: auto;
    padding: 0;
    border: none;
    box-shadow: none;

    &.position-fixed {
      position: fixed !important;
    }
  }
`;

interface MenuWithDropdownIconProps {
  active?: boolean;
}

export const MenuWithDropdownIcon = styled(
  ButtonIcon
)<MenuWithDropdownIconProps>`
  border-radius: 50%;
  height: 32px;
  position: relative;
  width: 32px;

  background-color: ${({ theme, active }) =>
    active ? theme.poloBlue : theme.mercury};

  &:hover {
    background-color: ${({ theme }) => theme.poloBlue};

    .icon {
      color: ${({ theme }) => theme.white};
    }

    ${Tooltip} {
      opacity: 1;
    }
  }
`;

MenuWithDropdown.defaultProps = {
  arrow: false,
  position: 'bottom right',
  className: 'menu',
};

export { MenuWithDropdown };
