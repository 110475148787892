import { InputHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import RadioButtonIconActive from 'assets/icons/radiobutton_active.svg';
import RadioButtonIconActiveWhite from 'assets/icons/radiobutton_active_white.svg';
import RadioButtonIconInactive from 'assets/icons/radiobutton_inactive.svg';

import UtilService from 'shared/services/util.service';

import { Input } from './Input';

interface LabelProps {
  circleBg?: string;
}

const Label = styled.label<LabelProps>`
  color: ${({ theme }) => theme.nevada};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding-left: 25px;
  position: relative;
  user-select: none;
  transition: background-color 300ms ease;

  &::before,
  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
    transition: 300ms ease;
    background-color: ${({ theme }) => theme.white};
  }

  &::before {
    background-image: url(${RadioButtonIconInactive});
    opacity: 1;
  }

  &::after {
    background-image: url(${RadioButtonIconActive});
    opacity: 0;
  }

  &.boxed {
    align-items: center;
    border: 1px solid ${({ theme }) => theme.heather};
    display: flex;
    font-size: 14px;
    height: 100%;
    padding: 17px 27px 17px 51px;
    width: 100%;
    border-radius: 8px;

    &::before,
    &::after {
      transform: translateY(-50%);
      left: 27px;
      top: 50%;
    }

    &::after {
      background-image: url(${RadioButtonIconActiveWhite});
      background-color: ${({ theme }) => theme.lynch};
    }
  }

  &:hover {
    &::before {
      background-color: ${({ theme }) => theme.blackSqueeze};
    }
  }
`;

const RadioInput = styled(Input)`
  display: none;

  &:disabled,
  &[readonly] {
    + ${Label} {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &:checked {
    + ${Label} {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      // override when radio is boxed
      &.boxed {
        background-color: ${({ theme }) => theme.lynch};
        border-color: ${({ theme }) => theme.lynch};
        color: ${({ theme }) => theme.white};
      }
    }
  }

  // Override for flow option modal since radio button label is custom.
  &[data-flow-option] {
    + ${Label} {
      display: flex;
      padding: 20px;

      &:hover {
        background-color: ${({ theme }) =>
          UtilService.colorWithOpacity(theme.silverSand, 0.2)};
        border-radius: 8px;
        cursor: pointer;
      }

      &::before,
      &::after {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &[aria-invalid='true'] {
    + ${Label} {
      color: ${({ theme }) => theme.valencia};

      &::before {
        border-color: ${({ theme }) => theme.valencia};
      }
    }
  }
`;

interface RadioButtonContainerProps {
  flexOne?: boolean;
}

const RadioButtonContainer = styled.div<RadioButtonContainerProps>`
  ${({ flexOne }) => flexOne && 'flex: 1;'}
`;

RadioButtonContainer.defaultProps = {
  flexOne: false,
};

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  flexOne?: boolean;
  circleBg?: string;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, children, title, className, flexOne, circleBg, ...rest }, ref) => {
    return (
      <RadioButtonContainer flexOne={flexOne} hidden={rest.hidden}>
        <RadioInput {...rest} ref={ref} type="radio" id={id} />

        <Label className={className} htmlFor={id} circleBg={circleBg}>
          {title || children}
        </Label>
      </RadioButtonContainer>
    );
  }
);

RadioButton.defaultProps = {
  flexOne: false,
};

export { RadioButton };
