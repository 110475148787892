import { FC, ReactElement } from 'react';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { Style } from 'order/wizard/orderStyles/interface/Style';

interface OrderFinishLabelProps {
  style?: Partial<Style> | null;
  render: (label: string) => ReactElement;
}

const OrderFinishLabel: FC<OrderFinishLabelProps> = ({ render, style }) => {
  const {
    woodMaterial,
    productLine,
    materialColor,
    finishColor,
    finishImpression,
  } = style ?? {};

  const getFinishLabel = () => {
    if (finishImpression) return 'Impression Name';

    if (
      productLine?.name === ProductLineEnums.PRODUCT_LINE_INOVAE2O ||
      productLine?.name === ProductLineEnums.PRODUCT_LINE_REVOLAE
    )
      return 'Material Color';

    if (productLine?.name === ProductLineEnums.PRODUCT_LINE_ICS) {
      return finishColor && !materialColor ? 'Color' : 'Material';
    }

    if (woodMaterial?.name === SpecificationsOptionsEnum.WOOD_TYPE_PAINT) {
      return 'Finish Color';
    }

    return 'Finish Stain';
  };

  return render(getFinishLabel());
};

export default OrderFinishLabel;
