import { RootState } from 'store';
import { uniqueId } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as FindIcon } from 'assets/icons/find_in_page.svg';

import { getOrder, getStatusEditLog } from 'order/store/orderActions';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { MenuWithDropdownIcon } from 'shared/components/MenuWithDropdown';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { SkeletonItem } from 'shared/components/SkeletonGrid';
import { Table, Thead, Th, Tr, Td, Tbody } from 'shared/components/Table';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { silverChaliceTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { P } from 'shared/components/Typography';
import {
  orderCancelRequest,
  setOrderCancelRequested,
} from 'overview/orders/store/ordersActions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { ButtonPrimary } from 'shared/components/Button';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

const TableStyled = styled(Table)`
  td {
    font-size: 14px;
  }
`;

interface ItemRef {
  id: string;
  height: number;
}

export const OrderStatusEditLog: FC = (props) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [itemRefs, setItemRefs] = useState<ItemRef[]>([]);

  const statusEditLog = useSelector(
    (state: RootState) => state.orderReducer.statusEditLog
  );

  const currentOrder = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (opened) {
      setIsLoading(true);
      dispatch(getStatusEditLog(orderId, (loading) => setIsLoading(loading)));
    }
  }, [opened]);

  const renderSkeleton = () => {
    return Array.from({ length: 9 }, () => (
      <Tr key={uniqueId()}>
        {Array.from({ length: 3 }, () => (
          <Td key={uniqueId()}>
            <SkeletonItem padding={10} borderRadius={5} h={5} w={100} />
          </Td>
        ))}
      </Tr>
    ));
  };

  const checkIfElementHeightExists = (itemRef: ItemRef[], itemDate: string) => {
    return itemRef.some((itemR) => itemR.id === itemDate);
  };

  const setItemRefsCallback = (
    prevState: ItemRef[],
    itemDate: string,
    height: number
  ) => {
    return checkIfElementHeightExists(prevState, itemDate)
      ? prevState
      : [
          ...prevState,
          {
            height,
            id: itemDate,
          },
        ];
  };

  const findHeightOfTheItem = (itemDate: string) => {
    return itemRefs.find((itemR) => itemR.id === itemDate)?.height;
  };

  const onOrderCancelRequestClickHandler = () => {
    if (orderId)
      dispatch(
        orderCancelRequest({ orderId }, () => {
          dispatch(setOrderCancelRequested(orderId));
          setIsModalOpen(false);
          dispatch(getOrder({ orderId }));
        })
      );
  };

  return (
    <Wrapper {...props}>
      <MenuWithDropdownIcon onClick={() => setOpened(true)}>
        <SVG
          icon={<FindIcon width="18px" height="18px" />}
          color={silverChaliceTwo}
        />
        <Tooltip position="bottom">Order Status Edit Log</Tooltip>
      </MenuWithDropdownIcon>

      <ModalWrapper
        modal
        lockScroll
        closeOnDocumentClick={false}
        open={opened}
        closeOnEscape={false}
      >
        {() => (
          <Modal
            title="Order status log"
            confirm
            withoutFooter={1}
            close={() => setOpened(false)}
          >
            <TableStyled>
              <Thead>
                <Tr>
                  <Th width={200}>Status</Th>
                  <Th width={150}>Date</Th>
                  <Th>User</Th>
                </Tr>
              </Thead>

              <Tbody>
                {isLoading && renderSkeleton()}

                {!isLoading && statusEditLog && (
                  <>
                    {statusEditLog.map((log) => (
                      <Tr key={uniqueId()}>
                        <Td width={200}>{log.orderStatus.name}</Td>

                        <Td width={150}>
                          <Wrapper flex column flexGrow>
                            {log.orderStatusEditLogs.map((item) => (
                              <Wrapper
                                key={item.changedDate}
                                flex
                                middle
                                marginBottom={5}
                                height={findHeightOfTheItem(item.changedDate)}
                              >
                                {UtilService.formatDate({
                                  date: item.changedDate,
                                  asElement: false,
                                })}
                              </Wrapper>
                            ))}
                          </Wrapper>
                        </Td>

                        <Td width={200}>
                          {log.orderStatusEditLogs.map((item) => (
                            <Wrapper
                              key={`${item.changedDate}--${item.changedByUserId}`}
                              marginBottom={5}
                              maxWidth={200}
                              ref={(el) => {
                                setItemRefs((prevState) =>
                                  setItemRefsCallback(
                                    prevState,
                                    item.changedDate,
                                    el?.getBoundingClientRect().height ?? 0
                                  )
                                );
                              }}
                            >
                              <P wordBreak="break-word" fontSize="inherit">
                                {item.changedByUserName}
                              </P>
                            </Wrapper>
                          ))}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </Tbody>
            </TableStyled>
            {isUserCSR && (
              <Wrapper flex justifyEnd>
                <ButtonPrimary
                  onClick={() => setIsModalOpen(true)}
                  disabled={
                    currentOrder!.status!.id === OrderStatusEnums.Canceled
                  }
                >
                  {currentOrder!.cancellationRequested
                    ? 'Remove Cancellation Request'
                    : 'Request Cancellation'}
                </ButtonPrimary>
              </Wrapper>
            )}
          </Modal>
        )}
      </ModalWrapper>
      <ConfirmationModal
        cancel={() => setIsModalOpen(false)}
        confirm={onOrderCancelRequestClickHandler}
        htmlMessage="This cancellation request will affect the Order process, the CSR still needs to determine the purpose of the cancellation."
        opened={isModalOpen}
        title="Are you sure?"
        buttonText={
          currentOrder!.cancellationRequested
            ? 'Remove Cancellation Request'
            : 'Request Cancellation'
        }
      />
    </Wrapper>
  );
};
