import { WritingHints } from 'order/writingHints/interface/WritingHints';

export enum WritingHintsActions {
  GET_WRITING_HINTS = 'GET_WRITING_HINTS',
  SET_WRITING_HINTS = 'SET_WRITING_HINTS',
}

export const getWritingHints = (
  orderId: string,
  loading: (isLoading: boolean) => void
) => ({
  type: WritingHintsActions.GET_WRITING_HINTS,
  payload: orderId,
  loading,
});

export const setWritingHints = (writingHints: WritingHints | null) => ({
  type: WritingHintsActions.SET_WRITING_HINTS,
  payload: writingHints,
});
