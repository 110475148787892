import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { getOrderStatus } from 'order/store/orderActions';

import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { testId } from 'tests/utils';

import {
  ACKTestButtonEnum,
  ACKTestContainerEnum,
} from 'tests/enums/ACKTestEnums';

import { FormElement } from 'shared/components/FormElement';
import { Textarea } from 'shared/components/Textarea';
import { FormLabel } from 'shared/components/FormLabel';
import { Spacer } from 'shared/components/Layout';
import SendACKModal from './SendACKModal';
import SpecialOrderApprovalModal from './SpecialOrderApprovalModal';
import { ACKTypeEnum } from '../enums/ACKTypeEnum';
import { SpecialOrderApprovalStatusEnum } from '../enums/SpecialOrderApprovalStatusEnum';
import { createACK } from '../store/ACKActions';

const CSRACKActionsContainer = styled(Wrapper)``;

const CSRACKActions = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const latestAck = useSelector(
    (state: RootState) => state.ACKReducer.latestACK
  );

  const [invalidModalOpened, setInvalidModalOpened] = useState(false);
  const [sendACKModalOpened, setSendACKModalOpened] = useState(false);
  const [note, setNote] = useState('');

  const onCreateQuoteACKSuccessHandler = (close: () => void) => {
    toast.success('Acknowledgment has been successfully created.');

    close();

    setTimeout(() => {
      window.scrollTo(0, 0);
      UtilService.onPopupClose();
      close();
    }, 100);

    // get latest order status
    dispatch(getOrderStatus(orderId));
  };

  const onSendQuoteACKClickHandler = (close: () => void) => {
    dispatch(
      createACK(
        {
          orderId,
          type: ACKTypeEnum.Quote,
          note,
        },
        () => onCreateQuoteACKSuccessHandler(close)
      )
    );
  };

  return (
    <CSRACKActionsContainer
      flex
      middle
      between
      {...testId(ACKTestContainerEnum.CSR_ACTIONS)}
    >
      {(order?.specialOrderApproval === null ||
        (order?.specialOrderApproval?.status ?? 0) ===
          SpecialOrderApprovalStatusEnum.DISAPPROVED) &&
        !latestAck &&
        !isQuoteFlow && (
          <ModalWrapper
            modal
            lockScroll
            onClose={UtilService.onPopupClose}
            onOpen={UtilService.onPopupOpen}
            trigger={
              <ButtonSecondary
                {...testId(ACKTestButtonEnum.SPECIAL_ORDER_APPROVAL_SEND_BTN)}
                disabled={
                  order?.status?.id === OrderStatusEnums.Canceled ||
                  order?.status?.id === OrderStatusEnums.Invoiced
                }
              >
                Send Special Order Approval
              </ButtonSecondary>
            }
          >
            {(close: () => void) => (
              <Modal close={close} noHeader>
                <SpecialOrderApprovalModal close={close} />
              </Modal>
            )}
          </ModalWrapper>
        )}

      <Wrapper mlAuto>
        {isQuoteFlow ? (
          <ConfirmationModal
            nested
            title="Acknowledgment Request"
            buttonText="Send"
            message="Send an Quote Acknowledgment request to the dealer for the confirmation."
            confirm={onSendQuoteACKClickHandler}
            trigger={<ButtonPrimary>Send Acknowledgment</ButtonPrimary>}
          >
            <Spacer h="32px" />
            <FormElement noMarginBottom>
              <FormLabel>Note</FormLabel>
              <Textarea onChange={(e) => setNote(e.target.value)} />
            </FormElement>
          </ConfirmationModal>
        ) : (
          <ButtonPrimary
            onClick={() =>
              order?.isValid
                ? setSendACKModalOpened(true)
                : setInvalidModalOpened(true)
            }
            {...testId(ACKTestButtonEnum.ACK_SEND_BTN)}
            disabled={
              order?.status?.id === OrderStatusEnums.Canceled ||
              order?.status?.id === OrderStatusEnums.Invoiced
            }
          >
            Send Acknowledgment
          </ButtonPrimary>
        )}
      </Wrapper>

      <ConfirmationModal
        title="This order is invalid."
        message="Are you sure you want to send acknowledgment?"
        confirm={() => setSendACKModalOpened(true)}
        buttonText="Send Acknowledgment"
        cancel={() => setInvalidModalOpened(false)}
        opened={invalidModalOpened}
      />

      <ModalWrapper
        modal
        nested
        onClose={() => {
          UtilService.onPopupClose();
          setSendACKModalOpened(false);
        }}
        onOpen={() => {
          UtilService.onPopupOpen();
          setInvalidModalOpened(false);
        }}
        lockScroll
        open={sendACKModalOpened}
      >
        {(close: () => void) => (
          <Modal close={close} noHeader>
            <SendACKModal close={close} />
          </Modal>
        )}
      </ModalWrapper>
    </CSRACKActionsContainer>
  );
};

export default CSRACKActions;
