type TBreakpoint = 320 | 375 | 425 | 768 | 1024 | 1440 | 2560;
type TDirection = 'min' | 'max';

export const mediaQuery = (
  direction: TDirection,
  breakpoint: TBreakpoint
): string => {
  let offset: number = 0;

  if (direction === 'max') {
    offset = 1;
  }

  return `@media only screen and (${direction}-width: ${
    breakpoint - offset
  }px)`;
};
