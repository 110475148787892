import { FC } from 'react';

import { FinishedEndsPriceReview } from 'order/wizard/orderStyles/interface/OrderPriceReview';

import { ContainerMedium, ContainerSmall } from 'shared/components/Container';
import { H6, PSmall } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';

interface FinishedEndRowProps {
  row?: FinishedEndsPriceReview;
  isListPriceSelected: boolean;
  rowTitle: string;
  isShowCatalogChecked: boolean;
}

const FinishedEndRow: FC<FinishedEndRowProps> = ({
  row,
  isListPriceSelected,
  isShowCatalogChecked,
  rowTitle,
}) => {
  return (
    <Wrapper flex middle>
      <ContainerMedium
        minWidth={135}
        align="left"
        isTableCell
        border={['right']}
      >
        <H6>{rowTitle.toUpperCase()}</H6>
      </ContainerMedium>

      <ContainerSmall isTableCell border={['right']} flexGrow>
        <PSmall>{row?.quantity ?? 0}</PSmall>
      </ContainerSmall>

      <ContainerSmall isTableCell border={['right']}>
        <PSmall>
          {isListPriceSelected
            ? row?.unitPrice.toFixed(2) ?? '0.00'
            : row?.netUnitPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>

      <ContainerSmall
        isTableCell
        border={isShowCatalogChecked ? ['right'] : []}
      >
        <PSmall>
          {isListPriceSelected
            ? row?.listPrice.toFixed(2) ?? '0.00'
            : row?.netPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>

      {isShowCatalogChecked && (
        <>
          <ContainerSmall isTableCell border={['right']}>
            <PSmall>
              {isListPriceSelected
                ? row?.unitPrice.toFixed(2) ?? '0.00'
                : row?.netUnitPrice.toFixed(2) ?? '0.00'}
            </PSmall>
          </ContainerSmall>

          <ContainerSmall isTableCell>
            <PSmall>
              {isListPriceSelected
                ? row?.listPrice.toFixed(2) ?? '0.00'
                : row?.netPrice.toFixed(2) ?? '0.00'}
            </PSmall>
          </ContainerSmall>
        </>
      )}
    </Wrapper>
  );
};

export default FinishedEndRow;
