import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

export enum AttachmentModalSectionEnums {
  'Floor Plans & Elevation' = 1,
  'Appliance Specs.',
  'Misc. Documents',
  'Plato internal Docs.',
  'Line Items',
  'Acknowledgment',
  'Snapshot',
  'Merged PDF',
  'Manual Snapshot',
  'Style Conversion Snapshot',
  'Blue Slips',
  'Exception Reports',
}

export enum AttachmentAlteredSections {
  'Latest Line Item Drawings' = AttachmentModalSectionEnums['Line Items'],
  'Acknowledgment' = AttachmentModalSectionEnums.Acknowledgment,
  'Latest Merged Drawings' = AttachmentModalSectionEnums['Merged PDF'],
}

export const generateAttachmentSections = () => {
  const attachmentSections = [] as SelectOptionProps[];

  Object.keys(AttachmentModalSectionEnums)
    .filter((key) => Number.isNaN(+key))
    .forEach((key) => {
      attachmentSections.push({
        label:
          AttachmentModalSectionEnums[
            AttachmentModalSectionEnums[
              key as keyof typeof AttachmentModalSectionEnums
            ]
          ],
        value:
          AttachmentModalSectionEnums[
            key as keyof typeof AttachmentModalSectionEnums
          ].toString(),
      });
    });

  return attachmentSections;
};
