import { FC, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';

import { clearModsModal } from './store/lineItemModificationsActions';
import { ModificationModalStepsEnums } from './enums/ModificationModalStepsEnums';
import ModificationForm from './ModificationForm';
import ModificationsComplete from './ModificationsComplete';
import ModificationsModalHeader from './ModificationsModalHeader';
import ModificationsSearch from './ModificationsSearch';

interface ModificationsModalProps {
  closeModal: () => void;
}

export const ModificationsModal: FC<ModificationsModalProps> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();

  const activeStep = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.activeStep
  );

  useEffect(() => {
    return () => {
      dispatch(clearModsModal());
    };
  }, []);

  return (
    <Wrapper>
      {/* TODO for every step render component */}
      <ModificationsModalHeader />

      {activeStep === ModificationModalStepsEnums.SEARCH_MODIFICATIONS && (
        <ModificationsSearch />
      )}

      {(activeStep === ModificationModalStepsEnums.ADD_MODIFICATIONS ||
        activeStep === ModificationModalStepsEnums.UPDATE_MODIFICATION) && (
        <ModificationForm />
      )}

      {activeStep === ModificationModalStepsEnums.COMPLETE_MODIFICATIONS && (
        <ModificationsComplete closeModal={closeModal} />
      )}
    </Wrapper>
  );
};
