import { RootState } from 'store';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ProductLine } from 'order/interfaces/ProductLine';

import { Action } from 'shared/interface/Action';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { setProductLines, sharedActions } from './sharedActions';

function* uploadFiles(action: Action<File[]>) {
  try {
    const uploadFilesPromises = [];

    for (let i = 0; i < action.payload!.length; i += 1) {
      const data = new FormData();

      data.append('file', action.payload![i]);

      uploadFilesPromises.push(ApiService.post(`/api/storage/files`, data));
    }

    const uploadedFiles: FileUploadResponse[] = yield Promise.all(
      uploadFilesPromises
    ).then((files) => {
      return files;
    });

    if (action.onSuccess) {
      yield call(action.onSuccess, uploadedFiles);

      if (action.loading) {
        yield call(action.loading!, false);
      }
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteFile(action: Action<string>) {
  try {
    yield call(ApiService.delete, `/api/storage/files/${action.payload}`);

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* downloadFile(action: Action<string, File>) {
  try {
    const file: File = yield call(
      ApiService.get,
      `/api/storage/files/${action.payload!}`
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, file);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getProductLines(action: Action<unknown>) {
  try {
    const selectedProductLines: ProductLine[] | null = yield select(
      (state: RootState) => state.sharedReducer.productLines
    );

    // get product lines only when they are not loaded
    if (selectedProductLines === null) {
      const productLines: ProductLine[] = yield call(
        ApiService.get,
        `/api/catalog/productlines`
      );

      yield put(setProductLines(productLines));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* sharedSagas() {
  yield takeEvery(sharedActions.UPLOAD_FILES, uploadFiles);
  yield takeEvery(sharedActions.DELETE_FILE, deleteFile);
  yield takeEvery(sharedActions.DOWNLOAD_FILE, downloadFile);
  yield takeEvery(sharedActions.GET_PRODUCT_LINES, getProductLines);
}
