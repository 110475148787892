import styled, { css } from 'styled-components';

import { P } from './Typography';

interface StyleHeaderProps {
  collapsable?: boolean;
}

const StyleHeaderCollapsableCss = css`
  display: flex;
  justify-content: space-between;
  transition: 300ms ease;
  &:hover {
    background-color: ${({ theme }) => theme.wildSand};
  }
`;

export const StyleHeader = styled(P)<StyleHeaderProps>`
  background-color: ${({ theme }) => theme.athensGray};
  padding: 16px 32px;
  ${({ collapsable }) => collapsable && StyleHeaderCollapsableCss}
`;
