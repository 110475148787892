import { OrderItem } from 'shared/interface/OrderRow';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  QuoteTableRequest,
  CsrQuoteTableRequest,
} from 'shared/interface/QuoteRow';

export enum QuotesActions {
  RESET_QUOTES = 'RESET_QUOTES',

  GET_DEALER_QUOTES = 'GET_DEALER_QUOTES',
  GET_CSR_QUOTES = 'GET_CSR_QUOTES',

  SET_BOARD_QUOTES = 'SET_BOARD_QUOTES',

  GET_QUOTES_FOR_IMPORT = 'GET_QUOTES_FOR_IMPORT',
}

export const resetQuotes = () => ({
  type: QuotesActions.RESET_QUOTES,
});

export const getDealerQuotes = (
  payload: QuoteTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: QuotesActions.GET_DEALER_QUOTES,
  payload,
  loading,
  onSuccess,
});

export const getCsrQuotes = (
  payload: CsrQuoteTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: QuotesActions.GET_CSR_QUOTES,
  payload,
  loading,
  onSuccess,
});

export const getQuotesForImport = (
  payload: CsrQuoteTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: QuotesActions.GET_QUOTES_FOR_IMPORT,
  payload,
  loading,
  onSuccess,
});

export const setBoardQuotes = (
  quotes: PaginatedItems<OrderItem>,
  append: boolean
) => ({
  type: QuotesActions.SET_BOARD_QUOTES,
  payload: {
    quotes,
    append,
  },
});
