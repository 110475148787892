import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { ReactComponent as IconSearch } from 'assets/icons/search.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { CSR } from 'shared/interface/CSR';
import { H4 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInputSearch } from 'shared/hooks/useInputSearch';
import EmptyState from 'shared/components/EmptyState';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SearchInput from 'shared/components/SearchInput';

import Assignee from '../Assignee/Assignee';

import {
  addOrderAssignee,
  searchCSRS,
  setSearchedCSRS,
} from '../../store/orderAssigneesActions';

const SearchAssigneesContainer = styled.div``;

const SearchAssignees = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchQuery, onSearchCSRS, loading, setLoading, setSearchQuery] =
    useInputSearch({
      minChars: 1,
    });

  const [addingAssignee, setAddingAssignee] = useState<string | null>(null);

  const searchedCSRs = useSelector(
    (state: RootState) => state.orderAssigneesReducer.searchedCSRs
  );

  const selectedCSRs = useSelector(
    (state: RootState) => state.orderAssigneesReducer.selectedCSRs
  );

  const resetSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
  };

  const onSelectAssigneeSuccessHandler = (user: CSR) => {
    toast.success(
      `You have seccessfully added ${user.firstName} as assignee to the order.`
    );

    setSearchQuery('');
    setAddingAssignee(null);
    dispatch(setSearchedCSRS(null));
    resetSearchInput();
  };

  const isCSRSelected = (userId: string) => {
    return selectedCSRs?.some((selectedCSR) => selectedCSR.id === userId);
  };

  const onSelectAssigneeClickHandler = (user: CSR) => {
    setAddingAssignee(user.id);

    dispatch(
      addOrderAssignee(
        { user, orderId },
        () => onSelectAssigneeSuccessHandler(user),
        setAddingAssignee
      )
    );
  };

  useEffect(() => {
    if (searchQuery.length >= 1) {
      dispatch(searchCSRS({ searchTerm: searchQuery }, setLoading));
    } else {
      dispatch(setSearchedCSRS(null));
    }
  }, [searchQuery]);

  return (
    <SearchAssigneesContainer>
      <H4>Add an Assignee</H4>
      <Spacer h="15px" />

      <SearchInput
        data-role="search"
        data-loading={loading}
        data-test="input-assigneesSearch"
        placeholder="Search for an employee..."
        onSearchQueryChanged={(e) => onSearchCSRS(e.target.value)}
        ref={searchInputRef}
        width={390}
      />

      <Spacer h="15px" />

      <ScrollbarsCustom
        autoHeight
        autoHeightMax={197}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
      >
        {!loading && searchedCSRs?.length === 0 && (
          <EmptyState
            title="No CSRs found"
            icon={<IconSearch />}
            message="Try searching for someone else."
          />
        )}

        {searchedCSRs?.map((user) => (
          <Assignee
            key={user.id}
            user={user}
            addingOrRemovingInProgress={addingAssignee === user.id}
            disabled={
              (selectedCSRs?.length ?? 0) >= 6 ||
              isCSRSelected(user.id) ||
              !!addingAssignee
            }
            onSelect={onSelectAssigneeClickHandler}
          />
        ))}
      </ScrollbarsCustom>
    </SearchAssigneesContainer>
  );
};

export default SearchAssignees;
