import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import OrderStyleNote from 'order/wizard/orderStyles/shared/OrderStyleNote';
import { DoorBuilderBreakingChange } from 'order/wizard/orderStyles/interface/DoorBuilderBreakingChange';
import { DoorBuilderOptions } from 'order/wizard/orderStyles/productLines/store/orderStylesReducer';
import { DoorBuilderOptionsEnum } from 'order/wizard/orderStyles/enums/DoorBuilderOptionsEnum';
import { DoorOverlayEnum } from 'order/wizard/orderStyles/enums/DoorOverlayEnum';
import { FaceFrameEnum } from 'order/wizard/orderStyles/enums/FaceFrameEnum';
import { LineItemHardware } from 'order/wizard/orderLineItems/interface/ILineItemData';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';
import { StyleModalEnum } from 'order/wizard/orderStyles/enums/StyleModalEnum';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import {
  changeStylesStep,
  getStyle,
  setIsStylesStepDirty,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import {
  clearOptions,
  DoorBuilderPayload,
  getClosetHardwareColors,
  getDoorBuilderOptions,
  getHardware,
  getHardwareQuantities,
  getToeSpaceTypes,
  orderStylesDoorBuilderActions,
  saveDoorBuilderData,
  setDoorBuilderData,
  validateStyleDoorBuilder,
} from 'order/wizard/orderStyles/productLines/store/doorBuilder/orderStylesDoorBuilderActions';

import FormError from 'shared/components/FormError';
import ImgModal from 'shared/components/ImgModal';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { Divider, Spacer } from 'shared/components/Layout';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, H3, P } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { RadioButton } from 'shared/components/RadioButton';
import { CreatableStyledSelect, Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Textarea } from 'shared/components/Textarea';
import { ToggleCheckbox } from 'shared/components/ToggleCheckbox';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanSubmit } from 'shared/hooks/useCanSubmit';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { useUndefinedOptionAsFirst } from 'shared/hooks/useUndefinedOptionAsFirst';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  noteValidation,
  onlyDigitValidation,
  upchargeValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';

import {
  OrderStylesDoorBuilderTestTextAreaEnum,
  OrderStylesDoorBuilderTestCheckboxEnum,
} from 'tests/enums/OrderStylesDoorBuilderTestEnums';

import { OrderTypeEnums } from 'order/enums/orderEnums';
import { useScrollToElement } from '../../../../../../shared/hooks/useScrollToElement';
import ClosetHardwareColor from './ClosetHardwareColor/ClosetHardwareColor';
import DoorBuilderBreakingChanges from './DoorBuilderBreakingChanges/DoorBuilderBreakingChanges';
import DoorUpcharges from './DoorUpcharges/DoorUpcharges';
import HingeColor from './HingeColor/HingeColor';

const ContainerLarge = styled(Wrapper)`
  width: 100%;
  max-width: 640px;
`;

const ContainerMedium = styled(Wrapper)`
  width: 100%;
  max-width: 340px;
`;

const ContainerSmall = styled.div`
  width: 100%;
  max-width: 162px;
  flex: 1;
`;

const ContainerXSmall = styled.div`
  width: 100%;
  max-width: 80px;
`;

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  flex: 1;
  width: 100%;
`;

interface StyledLabelProps {
  bold?: boolean;
  invalid?: boolean;
}

const StyledLabel = styled.label<StyledLabelProps>`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, invalid }) => (invalid ? theme.valencia : theme.nevada)};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 35px;
`;

const DoorBuilderUpcharges = styled(Wrapper)`
  border: 1px solid ${({ theme }) => theme.mischka};
  padding: 25px 40px;
  border-radius: 20px;
`;

interface InputWithPrefixProps {
  content: string;
  width?: string;
}

const InputWithPrefix = styled.div<InputWithPrefixProps>`
  position: relative;

  &::before {
    align-items: center;
    color: ${({ theme }) => theme.nevada};
    content: '${({ content }) => content}';
    display: block;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    padding-left: 19px;
    line-height: 20px;
    position: absolute;
    right: 100%;
    top: 13px;
    width: ${({ width }) => width};
  }
`;

const DrawerBoxContainer = styled(Wrapper)`
  margin-left: 30px;
`;

export const OrderStylesDoorBuilder = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isQuoteFlow = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.type === OrderTypeEnums.Quote
  );
  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const styleStep = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.step
  );

  const isStyleComplete = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.isComplete
  );

  const storedOneInchThickDoorUpcharge = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.style?.oneInchThickDoorUpcharge
  );

  const storedDoorBuilder = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilder
  );

  const storedStyleNote = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.note
  );

  const woodOrMaterial = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specifications?.woodOrMaterial
  );

  const impressionWoodOrMaterial = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.finishImpressionData?.woodMaterial
  );

  const cabinetBoxMaterialId = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.style?.cabinetBoxMaterial?.id ?? null
  );

  const productLineId = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine?.id
  );

  const productLineName = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine?.name
  );

  const [doorOverlayOptionsLoading, setDoorOverlayOptionsLoading] =
    useState(false);

  const doorOverlayOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorOverlayOptions
  );

  const [faceFrameOptionsLoading, setFaceFrameOptionsLoading] = useState(false);

  const faceFrameOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.faceFrameOptions
  );

  const [frameStyleOptionsLoading, setFrameStyleOptionsLoading] =
    useState(false);

  const frameStyleOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.frameStyleOptions
  );

  const [midrailsOptionsLoading, setMidrailsOptionsLoading] = useState(false);

  const midrailsOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.midrailsOptions
  );

  const [doorStyleOptionsLoading, setDoorStyleOptionsLoading] = useState(false);

  const doorStyleWallOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorStyleWallOptions
  );

  const doorStyleBaseOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorStyleBaseOptions
  );

  const [frameSizeOptionsLoading, setFrameSizeOptionsLoading] = useState(false);

  const frameSizeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.frameSizeOptions
  );

  const configurationOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.configurationOptions
  );

  const [archStyleOptionsLoading, setArchStyleOptionsLoading] = useState(false);

  const archStyleOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.archStyleOptions
  );

  const [insertPanelWallOptionsLoading, setInsertPanelWallOptionsLoading] =
    useState(false);

  const insertPanelWallOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.insertPanelWallOptions
  );

  const [insertPanelBaseOptionsLoading, setInsertPanelBaseOptionsLoading] =
    useState(false);

  const insertPanelBaseOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.insertPanelBaseOptions
  );

  const [doorEdgeWallOptionsLoading, setDoorEdgeWallOptionsLoading] =
    useState(false);

  const doorEdgeWallOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorEdgeWallOptions
  );

  const [doorEdgeBaseOptionsLoading, setDoorEdgeBaseOptionsLoading] =
    useState(false);

  const doorEdgeBaseOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorEdgeBaseOptions
  );

  const [metalFinishOptionsLoading, setMetalFinishOptionsLoading] =
    useState(false);

  const metalFinishOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.metalFinishOptions
  );

  const [drawerBoxOptionsLoading, setDrawerBoxOptionsLoading] = useState(false);

  const drawerBoxOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.drawerBoxOptions
  );

  const [hingeTypeOptionsLoading, setHingeTypeOptionsLoading] = useState(false);

  const hingeTypeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hingeTypeOptions
  );

  const hingeColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hingeColorOptions
  );

  const [drawerStyleOptionsLoading, setDrawerStyleOptionsLoading] =
    useState(false);

  const drawerStyleOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.drawerStyleOptions
  );

  const [drawerEdgeOptionsLoading, setDrawerEdgeOptionsLoading] =
    useState(false);

  const drawerEdgeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.drawerEdgeOptions
  );

  const [drawerInsertPanelOptionsLoading, setDrawerInsertPanelOptionsLoading] =
    useState(false);

  const drawerInsertPanelOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.drawerInsertPanelOptions
  );

  const [
    topDrawerInsertPanelOptionsLoading,
    setTopDrawerInsertPanelOptionsLoading,
  ] = useState(false);

  const topDrawerInsertPanelOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.topDrawerInsertPanelOptions
  );

  const [hardwareLoading, setHardwareLoading] = useState(false);

  const hardwareOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hardwareOptions
  );

  const [
    closetHardwareColorOptionsLoading,
    setClosetHardwareColorOptionsLoading,
  ] = useState(false);

  const closetHardwareColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.closetHardwareColorOptions
  );

  const doorCodes = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilderOptions.doorCodes
  );

  const [saveDoorBuilderLoading, setSaveDoorBuilderLoading] = useState(false);

  const [saveDoorBuilderLoadingOnBack, setSaveDoorBuilderLoadingOnBack] =
    useState(false);

  const hardwareQuantities = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hardwareQuantities
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isConvertFlow = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.modalOpenedForStyleId ===
      StyleModalEnum.CONVERT_STYLE
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const [toeSpaceTypeOptionsLoading, setToeSpaceTypeOptionsLoading] =
    useState(false);

  const toeSpaceTypeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.toeSpaceTypeOptions
  );

  const [shouldGoBack, setShouldGoBack] = useState(false);

  const editMode = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.doorOverlay !== null
  );

  const [breakingChanges, setBreakingChanges] = useState<
    DoorBuilderBreakingChange[] | null
  >(null);

  const [validationLoading, setValidationLoading] = useState(false);

  const [selectedFrameSize, setSelectedFrameSize] =
    useState<SelectOptionProps | null>(null);

  const config = useProductLineConfiguration();

  const methods = useForm<StyleDoorBuilder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      midrails: false,
      hingeTypeAdd: UtilService.withDecimal(null, '0') as string,
      doorHardwareAdd: UtilService.withDecimal(null, '0') as string,
      drawerHardwareAdd: UtilService.withDecimal(null, '0') as string,
      styleNote: storedStyleNote ?? '',
    },
  });

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const { onFormChange, PopupModal } = usePopupMessage();

  useEffect(() => {
    onFormChange(methods);
  }, [methods.formState]);

  useEffect(() => {
    if (storedDoorBuilder) {
      methods.reset(storedDoorBuilder);
      setSelectedFrameSize(
        storedDoorBuilder.customFrameSize
          ? ({
              label: storedDoorBuilder.customFrameSize.toString(),
              value: storedDoorBuilder.customFrameSize.toString(),
            } as SelectOptionProps)
          : storedDoorBuilder.frameSize
      );
      if (storedDoorBuilder.customFrameSize) {
        methods.clearErrors('frameSize');
      }
    }
  }, [storedDoorBuilder]);

  const doorOverlayWatched = methods.watch('doorOverlay');
  const faceFrameWatched = methods.watch('faceFrame');
  const frameStyleWatched = methods.watch('frameStyle');

  const doorStyleWallWatched = methods.watch('doorStyleWall');
  const configurationWallWatched = methods.watch('configurationWall');
  const configurationBaseWatched = methods.watch('configurationBase');
  const archStyleWatched = methods.watch('archStyle');
  const insertPanelWallWatched = methods.watch('insertPanelWall');
  const insertPanelBaseWatched = methods.watch('insertPanelBase');
  const doorEdgeWallWatched = methods.watch('doorEdgeWall');
  const doorEdgeBaseWatched = methods.watch('doorEdgeBase');

  const metalFinishWatched = methods.watch('metalFinish');

  const drawerStyleWatched = methods.watch('drawerStyle');
  const drawerInsertPanelWatched = methods.watch('drawerInsertPanel');
  const drawerEdgeWatched = methods.watch('drawerEdge');
  const topDrawerInsertPanelWatched = methods.watch('topDrawerInsertPanel');

  const drawerBoxWatched = methods.watch('drawerBox');

  const closetHardwareColorWatched = methods.watch('closetHardwareColor');
  const doorHardwareWatched = methods.watch('doorHardware');
  const drawerHardwareWatched = methods.watch('drawerHardware');
  const hingeColorWatched = methods.watch('hingeColor');
  const hingeTypeWatched = methods.watch('hingeType');
  const oneInchDoorWatched = methods.watch('oneInchDoor');
  const toeSpaceTypeWatched = methods.watch('toeSpaceType');

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.STYLE_OVERVIEW));
    }
  };

  const onDoorBuilderSaveSuccessHandler = (
    data: StyleDoorBuilder,
    goBack: boolean = false,
    doorBuilderBreakingChanges: DoorBuilderBreakingChange[]
  ) => {
    dispatch(setDoorBuilderData(data));

    if (doorBuilderBreakingChanges.length) {
      setShouldGoBack(goBack);
      setBreakingChanges(doorBuilderBreakingChanges);
      return;
    }

    dispatch(
      getStyle(
        { orderId, styleId: styleId! },
        () => redirect(goBack),
        (isLoading) =>
          goBack
            ? setSaveDoorBuilderLoadingOnBack(isLoading)
            : setSaveDoorBuilderLoading(isLoading)
      )
    );
  };

  const onDoorBuilderSaveErrorHandler = (err: ServerErrorResponse) => {
    setSaveDoorBuilderLoading(false);
    setSaveDoorBuilderLoadingOnBack(false);

    if (err.status === 403) {
      toast.error("You don't have permission to update this style.");
    } else {
      toast.error('Something went wrong.');
    }
  };

  const onSubmitHandler = (data: StyleDoorBuilder, goBack: boolean = false) => {
    if (!canEdit) {
      redirect(goBack);
      return;
    }

    // call save door builder
    const mappedData = UtilService.mapDoorBuilderFormToStyleDoorBuilderRequest({
      data,
      orderId,
      styleId: styleId!,
      doorCodes: doorCodes!,
      productLine: selectedProductLine!.name,
      ...(styleOverride && {
        isOverriden: true,
        overrideReason: styleOverride.overridenReason,
      }),
    });

    if (goBack) {
      setSaveDoorBuilderLoadingOnBack(true);
    } else {
      setSaveDoorBuilderLoading(true);
    }

    dispatch(
      saveDoorBuilderData(
        mappedData,
        (doorBuilderBreakingChanges) =>
          onDoorBuilderSaveSuccessHandler(
            data,
            goBack,
            doorBuilderBreakingChanges
          ),
        onDoorBuilderSaveErrorHandler
      )
    );
  };

  const resetFields = (fields: (keyof StyleDoorBuilder)[]) => {
    let formData = methods.getValues();

    fields.forEach((field) => {
      switch (field) {
        case 'midrails':
          formData = { ...formData, [field]: false };
          break;
        case 'hingeTypeAdd':
        case 'doorHardwareAdd':
        case 'drawerHardwareAdd':
          formData = {
            ...formData,
            [field]: UtilService.withDecimal(null, '0') as string,
          };
          break;
        default:
          formData = { ...formData, [field]: '' };
          break;
      }
    });

    methods.reset(formData, {
      keepTouched: true,
      keepDirty: true,
    });

    methods.clearErrors();
  };

  const resetOptions = (options: (keyof DoorBuilderOptions)[]) => {
    dispatch(clearOptions(options));
  };

  const validationResponse = (
    breakingChangeItems: DoorBuilderBreakingChange[],
    goBack: boolean
  ) => {
    setValidationLoading(false);

    if (breakingChangeItems.length > 0) {
      setBreakingChanges(breakingChangeItems);
      setShouldGoBack(goBack);
      return;
    }

    methods.handleSubmit((data) => onSubmitHandler(data, goBack))();
  };

  const handleValidateOrSubmit = (goBack: boolean) => {
    if (editMode && !styleOverride && styleId && doorCodes) {
      setValidationLoading(true);

      if (
        !doorOverlayWatched.id ||
        !faceFrameWatched.id ||
        !frameStyleWatched.value ||
        !drawerBoxWatched.value
      ) {
        setValidationLoading(false);
        methods.handleSubmit((data) => onSubmitHandler(data, goBack))();
        return;
      }

      dispatch(
        validateStyleDoorBuilder(
          {
            orderId,
            styleId,
            cabinetBoxMaterialId,
            doorOverlayId: doorOverlayWatched.id,
            drawerBoxId: drawerBoxWatched.value,
            faceFrameId: faceFrameWatched.id,
            frameStyleId: frameStyleWatched.value,
          },
          (breakingChangeItems) =>
            validationResponse(breakingChangeItems, goBack)
        )
      );

      return;
    }

    methods.handleSubmit((data) => onSubmitHandler(data, goBack))();
  };

  const populateQuantityFields = () => {
    const qtyFields = [
      'hingeTypeQty',
      'doorHardwareQty',
      'drawerHardwareQty',
    ] as (keyof StyleDoorBuilder)[];

    qtyFields.forEach((field) => {
      const fieldNameUpdated = field
        .replace('Type', '')
        .replace('Qty', 'Count');

      methods.setValue(
        field,
        (
          hardwareQuantities?.[fieldNameUpdated as keyof LineItemHardware] ?? 0
        ).toString() ?? '0'
      );
    });
  };

  const canSubmit = useCanSubmit({
    isFormDity: methods.formState.isDirty,
    editMode,
    isStyleComplete,
    step: StylesStepsEnum.DOOR_BUILDER,
    styleStep,
  });

  const goNextAndSaveForFirstTime = () => {
    return !methods.formState.isDirty && !isStyleComplete && !editMode;
  };

  const onDoorBuilderBreakingChangesModalDismissHandler = (
    close: () => void
  ) => {
    setBreakingChanges(null);
    close();
  };

  const validateForm = () => {
    setTimeout(() => {
      methods.trigger();
    }, 0);
  };

  useEffect(() => {
    setHardwareLoading(true);

    if (productLineId) {
      dispatch(getHardware({ productLineId }, setHardwareLoading));
    }

    setClosetHardwareColorOptionsLoading(true);
    dispatch(getClosetHardwareColors(setClosetHardwareColorOptionsLoading));

    if (styleId) {
      dispatch(getHardwareQuantities({ styleId: styleId!, orderId }));
    }

    return () => {
      dispatch(clearOptions(null));
    };
  }, []);

  useEffect(() => {
    if (!methods.formState.isDirty && storedOneInchThickDoorUpcharge) {
      UtilService.withDecimal(
        'oneInchThickDoorUpcharge',
        storedOneInchThickDoorUpcharge.toString(),
        methods.setValue
      );
    }
  }, [storedOneInchThickDoorUpcharge]);

  useEffect(() => {
    populateQuantityFields();
  }, [hardwareQuantities]);

  useEffect(() => {
    if (productLineId) {
      setDoorOverlayOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_DOOR_OVERLAYS,
          setDoorOverlayOptionsLoading
        )
      );
    }

    if (storedDoorBuilder) {
      methods.setValue('hdfPanels', storedDoorBuilder?.hdfPanels);
    } else if (woodOrMaterial?.label === 'Paint') {
      methods.setValue('hdfPanels', true);
    }
  }, [productLineId]);

  useEffect(() => {
    if (
      !methods.formState.isDirty &&
      doorOverlayOptions &&
      productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE &&
      !editMode
    ) {
      methods.setValue('doorOverlay', doorOverlayOptions[0], {
        shouldValidate: true,
      });
    }
  }, [doorOverlayOptions, styleStep]);

  useEffect(() => {
    if (doorOverlayWatched && productLineId) {
      setFaceFrameOptionsLoading(true);

      dispatch(
        getDoorBuilderOptions(
          { doorOverlayId: doorOverlayWatched.id, productLineId },
          orderStylesDoorBuilderActions.GET_FACE_FRAMES,
          setFaceFrameOptionsLoading
        )
      );
    }

    if (methods.formState.dirtyFields.doorOverlay) {
      resetFields([
        'archStyle',
        'configurationBase',
        'configurationWall',
        'doorEdgeBase',
        'doorEdgeWall',
        'doorStyleBase',
        'doorStyleWall',
        'drawerEdge',
        'drawerInsertPanel',
        'drawerStyle',
        'faceFrame',
        'frameSize',
        'frameStyle',
        'hingeColor',
        'hingeType',
        'insertPanelBase',
        'insertPanelWall',
        'metalFinish',
        'metalFinishUpcharge',
        'midrails',
        'topDrawerInsertPanel',
      ]);

      resetOptions([
        'archStyleOptions',
        'configurationOptions',
        'doorEdgeWallOptions',
        'doorEdgeBaseOptions',
        'doorStyleBaseOptions',
        'doorStyleWallOptions',
        'drawerEdgeOptions',
        'drawerInsertPanelOptions',
        'drawerStyleOptions',
        'faceFrameOptions',
        'frameSizeOptions',
        'frameStyleOptions',
        'hingeColorOptions',
        'hingeTypeOptions',
        'insertPanelWallOptions',
        'insertPanelBaseOptions',
        'metalFinishOptions',
        'midrailsOptions',
        'topDrawerInsertPanelOptions',
      ]);
    }
  }, [doorOverlayWatched]);

  // preselect face frame if it is only one in the list
  useEffect(() => {
    if (
      (methods.formState.dirtyFields.doorOverlay ||
        (productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE &&
          UtilService.styleOnStep({
            styleStep,
            step: StylesStepsEnum.DOOR_BUILDER,
          }))) &&
      faceFrameOptions?.length === 1
    ) {
      methods.setValue('faceFrame', faceFrameOptions[0]);
    }
  }, [faceFrameOptions]);

  // preselect frame style if only one in the list
  useEffect(() => {
    if (
      ((methods.formState.dirtyFields.doorOverlay &&
        methods.formState.dirtyFields.faceFrame) ||
        (productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE &&
          !editMode)) &&
      frameStyleOptions?.length === 1
    ) {
      methods.setValue('frameStyle', frameStyleOptions[0], {
        shouldValidate: true,
      });
    }
  }, [frameStyleOptions, styleStep, doorOverlayWatched]);

  const setDefaultHingeColor = () => {
    const hingeColorList = hingeColorOptions![hingeTypeWatched!.value];

    const defaultHingeColor = hingeColorList?.find(
      (hingeColorOption) => hingeColorOption.isDefault
    );

    if (defaultHingeColor && hingeColorList.length > 1) {
      methods.setValue(
        'hingeColor',
        methods.formState.isDirty
          ? defaultHingeColor
          : storedDoorBuilder?.hingeColor ?? defaultHingeColor
      );
    } else if ((hingeColorList ?? []).length === 1) {
      methods.setValue(
        'hingeColor',
        methods.formState.isDirty
          ? hingeColorList[0]
          : storedDoorBuilder?.hingeColor ?? hingeColorList[0]
      );
    } else if (methods.formState.isDirty) {
      resetFields(['hingeColor']);
    }
  };

  const setDefaultFrameSize = () => {
    const frameSizeDefault = frameSizeOptions?.find((fs) => fs.isDefault);

    if (frameSizeDefault && methods.formState.isDirty) {
      methods.setValue('frameSize', frameSizeDefault, {
        shouldValidate: true,
      });
    } else if (storedDoorBuilder && !methods.formState.isDirty) {
      methods.setValue('frameSize', storedDoorBuilder.frameSize, {
        shouldValidate: true,
      });
    } else {
      resetFields(['frameSize']);
    }
  };

  const shouldDisableNoMidrails = () => {
    const noMidrailDisabled =
      doorOverlayWatched?.name === DoorOverlayEnum.INSET && midrailsOptions
        ? !canEdit
        : !midrailsOptions || !midrailsOptions?.enabled || !canEdit;

    return noMidrailDisabled;
  };

  useEffect(() => {
    if (
      productLineId &&
      (woodOrMaterial || impressionWoodOrMaterial) &&
      doorOverlayWatched &&
      faceFrameWatched
    ) {
      setFrameStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameWatched.id,
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_FRAME_STYLES,
          setFrameStyleOptionsLoading
        )
      );

      setHingeTypeOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            productLineId,
            doorStyleId: '',
            faceFrameId: faceFrameWatched.id,
          },
          orderStylesDoorBuilderActions.GET_HINGE_TYPE,
          setHingeTypeOptionsLoading
        )
      );

      setDoorStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameWatched.id,
            woodMaterialId: impressionWoodOrMaterial
              ? impressionWoodOrMaterial.id
              : woodOrMaterial?.value,
            oneInchDoor: oneInchDoorWatched || false,
            ...(styleOverride && { isOverriden: true }),
            isQuoteFlow,
          },
          orderStylesDoorBuilderActions.GET_DOOR_STYLE,
          setDoorStyleOptionsLoading
        )
      );
    }

    if (
      faceFrameWatched?.name === FaceFrameEnum.ONEINCH ||
      faceFrameWatched?.name === FaceFrameEnum.ICON
    ) {
      methods.setValue('oneInchFaceFrame', true);
    } else {
      methods.setValue('oneInchFaceFrame', false);
    }

    if (methods.formState.dirtyFields.faceFrame) {
      resetFields([
        'frameStyle',
        'midrails',
        'doorStyleWall',
        'doorStyleBase',
        'metalFinish',
        'insertPanelBase',
        'insertPanelWall',
        'doorEdgeBase',
        'doorEdgeWall',
        'metalFinishUpcharge',
        'hingeType',
        'hingeColor',
        'archStyle',
        'frameSize',
        'configurationWall',
        'configurationBase',
        'drawerInsertPanel',
        'drawerEdge',
      ]);

      resetOptions([
        'frameStyleOptions',
        'frameSizeOptions',
        'midrailsOptions',
        'doorStyleWallOptions',
        'doorStyleBaseOptions',
        'metalFinishOptions',
        'doorEdgeWallOptions',
        'doorEdgeBaseOptions',
        'insertPanelWallOptions',
        'insertPanelBaseOptions',
        'archStyleOptions',
        'configurationOptions',
        'drawerInsertPanelOptions',
        'drawerEdgeOptions',
      ]);
    }
  }, [faceFrameWatched]);

  useEffect(() => {
    if (
      productLineId &&
      (woodOrMaterial || impressionWoodOrMaterial) &&
      doorOverlayWatched &&
      faceFrameWatched &&
      methods.formState.isDirty
    ) {
      setDoorStyleOptionsLoading(true);

      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameWatched.id,
            woodMaterialId: impressionWoodOrMaterial
              ? impressionWoodOrMaterial.id
              : woodOrMaterial?.value,
            oneInchDoor: oneInchDoorWatched || false,
            ...(styleOverride && { isOverriden: true }),
            isQuoteFlow,
          },
          orderStylesDoorBuilderActions.GET_DOOR_STYLE,
          setDoorStyleOptionsLoading
        )
      );
    }

    if (
      methods.formState.dirtyFields.oneInchDoor ||
      methods.formState.touchedFields.oneInchDoor
    ) {
      resetFields(['doorStyleWall', 'doorStyleBase']);
      resetOptions(['doorStyleWallOptions', 'doorStyleBaseOptions']);
    }
  }, [oneInchDoorWatched]);

  const setDefaultHingeType = () => {
    const defaultHingeType = hingeTypeOptions?.find((hingeTypeOption) =>
      oneInchDoorWatched
        ? hingeTypeOption.label === DoorBuilderOptionsEnum.BLUM_1INCH
        : hingeTypeOption.isDefault
    );

    const hingeTypeValue = methods.formState.isDirty
      ? defaultHingeType
      : storedDoorBuilder?.hingeType ?? defaultHingeType;

    if (hingeTypeValue) {
      methods.setValue('hingeType', hingeTypeValue);
    }

    if (
      productLineName !== ProductLineEnums.PRODUCT_LINE_PRELUDE &&
      hingeTypeValue &&
      (doorOverlayWatched?.name === DoorOverlayEnum.FULL ||
        doorOverlayWatched?.name === DoorOverlayEnum.STANDARD)
    ) {
      methods.setValue('hingeType', hingeTypeValue);
    }

    if (
      hingeTypeValue &&
      doorOverlayWatched?.name === DoorOverlayEnum.INSET &&
      faceFrameWatched?.name === FaceFrameEnum.ICON
    ) {
      methods.setValue('hingeType', hingeTypeValue);
    }

    if (
      hingeTypeValue &&
      productLineName === ProductLineEnums.PRODUCT_LINE_PRELUDE &&
      doorOverlayWatched?.name === DoorOverlayEnum.FULL &&
      faceFrameWatched?.name === FaceFrameEnum['3/4']
    ) {
      methods.setValue('hingeType', hingeTypeValue);
    }
  };

  // if inovae - get face frame options when doorOverlay is preselected
  useEffect(() => {
    if (
      !methods.formState.isDirty &&
      doorOverlayWatched &&
      faceFrameWatched &&
      productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE &&
      !editMode
    ) {
      setFrameStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameWatched.id,
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_FRAME_STYLES,
          setFrameStyleOptionsLoading
        )
      );
    }
  }, [doorOverlayWatched, faceFrameWatched, styleStep]);

  useEffect(() => {
    if (frameStyleWatched && config.doorBuilder.showNoMidrails) {
      setMidrailsOptionsLoading(true);

      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameWatched.id,
            frameStyleId: frameStyleWatched.value,
            ...(styleOverride && { isOverriden: true }),
          },
          orderStylesDoorBuilderActions.GET_MIDRAILS,
          setMidrailsOptionsLoading
        )
      );
    }
    if (methods.formState.dirtyFields.frameStyle) {
      resetFields(['midrails']);
      resetOptions(['midrailsOptions']);
    }
  }, [frameStyleWatched]);

  useEffect(() => {
    if (methods.formState.dirtyFields.frameStyle && midrailsOptions) {
      methods.setValue('midrails', midrailsOptions.checked);
    }
  }, [midrailsOptions]);

  useEffect(() => {
    if (doorStyleWallWatched && doorStyleWallOptions) {
      const doorStyleBaseDefault = doorStyleWallOptions.find(
        (doorStyleBaseOption) =>
          doorStyleBaseOption.value === doorStyleWallWatched.value
      );

      if (doorStyleBaseDefault) {
        methods.setValue('doorStyleBase', doorStyleBaseDefault);
      }
    }
  }, [doorStyleWallWatched, doorStyleWallOptions]);

  useEffect(() => {
    if (doorStyleWallWatched && productLineId) {
      setMetalFinishOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorStyleId: doorStyleWallWatched.value,
          },
          orderStylesDoorBuilderActions.GET_METAL_FINISH,
          setMetalFinishOptionsLoading
        )
      );

      setFrameSizeOptionsLoading(true);
      if (methods.formState.isDirty) {
        setSelectedFrameSize(null);
      }
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            doorStyleId: doorStyleWallWatched.value,
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_FRAME_SIZE,
          setFrameSizeOptionsLoading
        )
      );

      setHingeTypeOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            productLineId,
            doorStyleId: doorStyleWallWatched.value,
            faceFrameId: faceFrameWatched.id,
          },
          orderStylesDoorBuilderActions.GET_HINGE_TYPE,
          setHingeTypeOptionsLoading
        )
      );
    }

    if (
      methods.formState.dirtyFields.doorStyleWall ||
      methods.formState.dirtyFields.oneInchDoor
    ) {
      resetFields([
        'archStyle',
        'configurationBase',
        'configurationWall',
        'doorEdgeBase',
        'doorEdgeWall',
        'drawerEdge',
        'drawerInsertPanel',
        'drawerStyle',
        'frameSize',
        'hingeColor',
        'hingeType',
        'hingeTypeAdd',
        'insertPanelBase',
        'insertPanelWall',
        'metalFinish',
        'metalFinishUpcharge',
        'topDrawerInsertPanel',
      ]);

      resetOptions([
        'archStyleOptions',
        'doorEdgeBaseOptions',
        'doorEdgeWallOptions',
        'drawerEdgeOptions',
        'drawerInsertPanelOptions',
        'drawerStyleOptions',
        'frameSizeOptions',
        'insertPanelBaseOptions',
        'insertPanelWallOptions',
        'metalFinishOptions',
        'topDrawerInsertPanelOptions',
        'hingeTypeOptions',
        'hingeColorOptions',
      ]);
    }
  }, [doorStyleWallWatched]);

  // ONLY CONF WALL
  useEffect(() => {
    if (
      productLineId &&
      doorOverlayWatched &&
      doorStyleWallWatched &&
      configurationWallWatched
    ) {
      const wallConfigurationAR = configurationWallWatched.label.includes('AR');

      if (!configurationBaseWatched && !wallConfigurationAR) {
        methods.setValue('configurationBase', configurationWallWatched, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }

      const request: DoorBuilderPayload = {
        doorConfigurationId: configurationWallWatched.value,
        doorOverlayId: doorOverlayWatched.id,
        doorStyleId: doorStyleWallWatched.value,
        productLineId,
        faceFrameId: faceFrameWatched.id,
      };

      setInsertPanelWallOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          request,
          orderStylesDoorBuilderActions.GET_INSERT_PANEL_WALL,
          setInsertPanelWallOptionsLoading
        )
      );

      setDoorEdgeWallOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          request,
          orderStylesDoorBuilderActions.GET_DOOR_EDGE_WALL,
          setDoorEdgeWallOptionsLoading
        )
      );

      if (
        productLineName !== ProductLineEnums.PRODUCT_LINE_PRELUDE ||
        !(
          productLineName === ProductLineEnums.PRODUCT_LINE_PRELUDE &&
          doorOverlayWatched?.name === DoorOverlayEnum.FULL &&
          faceFrameWatched?.name === FaceFrameEnum['3/4']
        )
      ) {
        setHingeTypeOptionsLoading(true);
        dispatch(
          getDoorBuilderOptions(
            {
              doorOverlayId: doorOverlayWatched.id,
              productLineId,
              doorStyleId: doorStyleWallWatched.value,
              faceFrameId: faceFrameWatched.id,
            },
            orderStylesDoorBuilderActions.GET_HINGE_TYPE,
            setHingeTypeOptionsLoading
          )
        );
      }
    }

    if (
      methods.formState.dirtyFields.configurationWall ||
      methods.formState.touchedFields.configurationWall
    ) {
      resetFields([
        'doorEdgeWall',
        'insertPanelWall',
        // 'drawerInsertPanel',
        // 'drawerEdge',
        // 'topDrawerInsertPanel',
      ]);

      resetOptions([
        'insertPanelWallOptions',
        'doorEdgeWallOptions',
        // 'drawerInsertPanelOptions',
        // 'drawerEdgeOptions',
        // 'topDrawerInsertPanelOptions',
      ]);
    }
  }, [configurationWallWatched]);

  // ONLY CONF BASE
  useEffect(() => {
    if (
      productLineId &&
      doorOverlayWatched &&
      doorStyleWallWatched &&
      configurationBaseWatched
    ) {
      const request: DoorBuilderPayload = {
        doorConfigurationId: configurationBaseWatched.value,
        doorOverlayId: doorOverlayWatched.id,
        doorStyleId: doorStyleWallWatched.value,
        productLineId,
      };

      // door edge base
      setDoorEdgeBaseOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          { ...request, faceFrameId: faceFrameWatched.id },
          orderStylesDoorBuilderActions.GET_DOOR_EDGE_BASE,
          setDoorEdgeBaseOptionsLoading
        )
      );

      // insert panel base
      setInsertPanelBaseOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          request,
          orderStylesDoorBuilderActions.GET_INSERT_PANEL_BASE,
          setInsertPanelBaseOptionsLoading
        )
      );
    }

    if (
      methods.formState.dirtyFields.configurationBase ||
      methods.formState.touchedFields.configurationBase
    ) {
      resetFields(['insertPanelBase', 'doorEdgeBase']);
      resetOptions(['insertPanelBaseOptions', 'doorEdgeBaseOptions']);
    }
  }, [configurationBaseWatched]);

  // BOTH WALL AND BAASE
  useEffect(() => {
    if (doorOverlayWatched && doorStyleWallWatched) {
      const request: DoorBuilderPayload = {
        doorOverlayId: doorOverlayWatched.id,
        doorStyleId: doorStyleWallWatched.value,
        productLineId,
      };

      if (configurationWallWatched) {
        const wallConfigurationAR =
          configurationWallWatched.label.includes('AR');
        if (wallConfigurationAR) {
          request.doorConfigurationId = configurationWallWatched.value;
        }
      }

      if (configurationBaseWatched) {
        const baseConfigurationAR =
          configurationBaseWatched.label.includes('AR');
        if (baseConfigurationAR) {
          request.doorConfigurationId = configurationBaseWatched.value;
        }
      }

      if (request.doorConfigurationId) {
        setArchStyleOptionsLoading(true);
        dispatch(
          getDoorBuilderOptions(
            request,
            orderStylesDoorBuilderActions.GET_ARCH_STYLE,
            setArchStyleOptionsLoading
          )
        );
      }
      if (
        methods.formState.dirtyFields.configurationBase ||
        methods.formState.dirtyFields.configurationWall
      ) {
        resetFields(['archStyle']);
        resetOptions(['archStyleOptions']);
      }
    }
  }, [configurationWallWatched, configurationBaseWatched]);

  useEffect(() => {
    if (doorEdgeWallWatched) {
      if (
        !doorEdgeBaseWatched &&
        doorEdgeBaseOptions?.some(
          (doorEdgeBaseOption) =>
            doorEdgeBaseOption.value === doorEdgeWallWatched.value
        )
      ) {
        methods.setValue('doorEdgeBase', doorEdgeWallWatched, {
          shouldValidate: true,
        });
      }
    }
  }, [doorEdgeWallWatched]);

  useEffect(() => {
    if (doorEdgeBaseWatched && productLineId) {
      // get drawer styles
      setDrawerStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorConfigurationId: configurationWallWatched.value,
            doorEdgeId: doorEdgeBaseWatched.value,
            doorOverlayId: doorOverlayWatched.id,
            doorStyleId: doorStyleWallWatched.value,
            productLineId,
            faceFrameId: faceFrameWatched.id,
          },
          orderStylesDoorBuilderActions.GET_DRAWER_STYLES,
          setDrawerStyleOptionsLoading
        )
      );
    }

    if (methods.formState.isDirty && drawerStyleWatched) {
      resetFields(['drawerStyle']);
      resetOptions(['drawerStyleOptions']);
    }
  }, [doorEdgeBaseWatched]);

  useEffect(() => {
    if (productLineId) {
      setDrawerBoxOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          { productLineId },
          orderStylesDoorBuilderActions.GET_DRAWER_BOX,
          setDrawerBoxOptionsLoading
        )
      );
    }
  }, [productLineId]);

  // set default drawer box if exists
  useEffect(() => {
    if (
      !methods.formState.isDirty &&
      drawerBoxOptions &&
      !storedDoorBuilder &&
      !editMode
    ) {
      const defautlDrawerBox = drawerBoxOptions.find(
        (drawerBox) => drawerBox.isDefault
      );

      if (defautlDrawerBox) {
        methods.setValue('drawerBox', defautlDrawerBox, {
          shouldValidate: true,
        });
      }
    }
  }, [drawerBoxOptions]);

  // preselect if hinge type is default
  useEffect(() => {
    if (hingeTypeOptions) {
      setDefaultHingeType();
    }
  }, [
    hingeTypeOptions,
    doorOverlayWatched,
    faceFrameWatched,
    oneInchDoorWatched,
  ]);

  // preselect if hinge color is default
  useEffect(() => {
    if (hingeColorOptions && hingeTypeWatched) {
      setDefaultHingeColor();
    }
  }, [hingeColorOptions, hingeTypeWatched]);

  useEffect(() => {
    if (frameSizeOptions) {
      setDefaultFrameSize();
    }
  }, [frameSizeOptions]);

  useEffect(() => {
    if (drawerStyleWatched && productLineId) {
      setDrawerInsertPanelOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          { drawerFrontStyleId: drawerStyleWatched.value, productLineId },
          orderStylesDoorBuilderActions.GET_DRAWER_INSERT_PANEL,
          setDrawerInsertPanelOptionsLoading
        )
      );

      if (insertPanelBaseWatched) {
        setTopDrawerInsertPanelOptionsLoading(true);
        dispatch(
          getDoorBuilderOptions(
            {
              doorOverlayId: doorOverlayWatched.id,
              drawerFrontStyleId: drawerStyleWatched.value,
              insertPanelBaseId: insertPanelBaseWatched.value,
            },
            orderStylesDoorBuilderActions.GET_TOP_DRAWER_INSERT_PANEL,
            setTopDrawerInsertPanelOptionsLoading
          )
        );
      }

      if (doorEdgeBaseWatched) {
        setDrawerEdgeOptionsLoading(true);
        dispatch(
          getDoorBuilderOptions(
            {
              drawerFrontStyleId: drawerStyleWatched.value,
              productLineId,
              faceFrameId: faceFrameWatched.id,
            },
            orderStylesDoorBuilderActions.GET_DRAWER_EDGES,
            setDrawerEdgeOptionsLoading
          )
        );
      }
    }

    if (
      methods.formState.dirtyFields.drawerStyle ||
      methods.formState.dirtyFields.configurationBase
    ) {
      resetFields(['drawerEdge', 'drawerInsertPanel', 'topDrawerInsertPanel']);

      resetOptions([
        'drawerEdgeOptions',
        'drawerInsertPanelOptions',
        'topDrawerInsertPanelOptions',
      ]);
    }
  }, [drawerStyleWatched]);

  useEffect(() => {
    if (
      methods.formState.isDirty &&
      insertPanelBaseWatched &&
      doorOverlayWatched &&
      drawerStyleWatched
    ) {
      setTopDrawerInsertPanelOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            drawerFrontStyleId: drawerStyleWatched.value,
            insertPanelBaseId: insertPanelBaseWatched.value,
          },
          orderStylesDoorBuilderActions.GET_TOP_DRAWER_INSERT_PANEL,
          setTopDrawerInsertPanelOptionsLoading
        )
      );
    }

    if (methods.formState.dirtyFields.insertPanelBase) {
      resetFields(['topDrawerInsertPanel']);
      resetOptions(['topDrawerInsertPanelOptions']);
    }
  }, [insertPanelBaseWatched]);

  useEffect(() => {
    if (hardwareOptions?.length) {
      const doNotDrillOption = hardwareOptions.find(
        (hardwareOption) =>
          hardwareOption.label === DoorBuilderOptionsEnum.DO_NOT_DRILL
      );

      if (!storedDoorBuilder && doNotDrillOption) {
        methods.setValue('doorHardware', doNotDrillOption);
        methods.setValue('drawerHardware', doNotDrillOption);
      }

      if (
        storedDoorBuilder &&
        storedDoorBuilder.doorHardware &&
        storedDoorBuilder.drawerHardware
      ) {
        const storedDoorHardwareOption = hardwareOptions.find(
          (hardwareOption) =>
            hardwareOption.value === storedDoorBuilder.doorHardware.value
        );

        const storedDrawerHardwareOption = hardwareOptions.find(
          (hardwareOption) =>
            hardwareOption.value === storedDoorBuilder.drawerHardware.value
        );

        if (storedDoorHardwareOption) {
          methods.setValue('doorHardware', storedDoorHardwareOption);
        }

        if (storedDrawerHardwareOption) {
          methods.setValue('drawerHardware', storedDrawerHardwareOption);
        }
      }
    }
  }, [hardwareOptions]);

  // prepopulate drawer insert panel based on insert panel base
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      drawerInsertPanelOptions?.length &&
      insertPanelBaseWatched
    ) {
      const drawerInsertPanelOption = drawerInsertPanelOptions.find(
        (dip) => dip.label === insertPanelBaseWatched.label
      );

      if (drawerInsertPanelOption) {
        methods.setValue('drawerInsertPanel', drawerInsertPanelOption, {
          shouldValidate: true,
        });
      }
    }
  }, [drawerInsertPanelOptions]);

  // prepopulate drawer edge based on door edge base
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      drawerEdgeOptions?.length &&
      doorEdgeBaseWatched
    ) {
      const drawerEdgeOption = drawerEdgeOptions.find(
        (de) => de.label === doorEdgeBaseWatched.label
      );

      if (drawerEdgeOption) {
        methods.setValue('drawerEdge', drawerEdgeOption, {
          shouldValidate: true,
        });
      }
    }
  }, [drawerEdgeOptions]);

  useEffect(() => {
    if (insertPanelWallWatched) {
      if (
        !insertPanelBaseWatched &&
        insertPanelBaseOptions?.some(
          (insertPanelBaseOption) =>
            insertPanelBaseOption.value === insertPanelWallWatched.value
        )
      ) {
        methods.setValue('insertPanelBase', insertPanelWallWatched, {
          shouldValidate: true,
        });
      }
    }
  }, [insertPanelWallWatched]);

  // populate upcharge for door hardware
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      (doorHardwareWatched?.upcharge ?? 0) >= 0
    ) {
      UtilService.withDecimal(
        'doorHardwareAdd',
        doorHardwareWatched.upcharge!.toString(),
        methods.setValue
      );
    }
  }, [doorHardwareWatched]);

  // populate upcharge for drawer hardware
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      (drawerHardwareWatched?.upcharge ?? 0) >= 0
    ) {
      UtilService.withDecimal(
        'drawerHardwareAdd',
        drawerHardwareWatched.upcharge!.toString(),
        methods.setValue
      );
    }
  }, [drawerHardwareWatched]);

  useEffect(() => {
    if (methods.formState.isDirty && metalFinishWatched?.upcharge != null) {
      methods.setValue(
        'metalFinishUpcharge',
        UtilService.withDecimal(
          null,
          metalFinishWatched.upcharge.toString()
        ) as string,
        { shouldValidate: true }
      );
    }
  }, [metalFinishWatched]);

  useEffect(() => {
    if (config.doorBuilder.showToeSpaceType) {
      setToeSpaceTypeOptionsLoading(true);
      dispatch(getToeSpaceTypes(() => setToeSpaceTypeOptionsLoading(false)));
    }
  }, [config.doorBuilder.showToeSpaceType]);

  // this useeffect is used for selecting options only when list of the options is made from one option. xD
  useEffect(() => {
    if (!methods.formState.isDirty) return;

    if (doorOverlayOptions?.length === 1) {
      methods.setValue('doorOverlay', doorOverlayOptions[0]);
    }

    if (faceFrameOptions?.length === 1) {
      methods.setValue('faceFrame', faceFrameOptions[0]);
    }

    if (faceFrameWatched && frameStyleOptions?.length === 1) {
      methods.setValue('frameStyle', frameStyleOptions[0]);
    }

    if (doorStyleWallOptions?.length === 1) {
      methods.setValue('doorStyleWall', doorStyleWallOptions[0]);
    }

    if (doorStyleBaseOptions?.length === 1) {
      methods.setValue('doorStyleBase', doorStyleBaseOptions[0]);
    }

    if (frameSizeOptions?.length === 1) {
      methods.setValue('frameSize', frameSizeOptions[0]);
    }

    if (
      doorStyleWallWatched &&
      configurationOptions?.[doorStyleWallWatched.value]?.length === 1
    ) {
      methods.setValue(
        'configurationBase',
        configurationOptions[doorStyleWallWatched.value][0]
      );
      methods.setValue(
        'configurationWall',
        configurationOptions[doorStyleWallWatched.value][0]
      );
    }

    if (archStyleOptions?.length === 1) {
      methods.setValue('archStyle', archStyleOptions[0]);
    }

    if (insertPanelWallOptions?.length === 1) {
      methods.setValue('insertPanelWall', insertPanelWallOptions[0]);
    }

    if (insertPanelBaseOptions?.length === 1) {
      methods.setValue('insertPanelBase', insertPanelBaseOptions[0]);
    }

    if (doorEdgeWallOptions?.length === 1) {
      methods.setValue('doorEdgeWall', doorEdgeWallOptions[0]);
    }

    if (doorEdgeBaseOptions?.length === 1) {
      methods.setValue('doorEdgeBase', doorEdgeBaseOptions[0]);
    }

    if (metalFinishOptions?.length === 1) {
      methods.setValue('metalFinish', metalFinishOptions[0]);
    }

    if (drawerStyleOptions?.length === 1) {
      methods.setValue('drawerStyle', drawerStyleOptions[0]);
    }

    if (drawerStyleWatched && drawerInsertPanelOptions?.length === 1) {
      methods.setValue('drawerInsertPanel', drawerInsertPanelOptions[0]);
    }

    if (drawerStyleWatched && drawerEdgeOptions?.length === 1) {
      methods.setValue('drawerEdge', drawerEdgeOptions[0]);
    }

    if (drawerStyleWatched && topDrawerInsertPanelOptions?.length === 1) {
      methods.setValue('topDrawerInsertPanel', topDrawerInsertPanelOptions[0]);
    }

    if (hardwareOptions?.length === 1) {
      methods.setValue('doorHardware', hardwareOptions[0]);
      methods.setValue('drawerHardware', hardwareOptions[0]);
    }

    if (closetHardwareColorOptions?.length === 1) {
      methods.setValue('closetHardwareColor', closetHardwareColorOptions[0]);
    }

    if (drawerBoxOptions?.length === 1) {
      methods.setValue('drawerBox', drawerBoxOptions[0]);
    }
  }, [
    doorOverlayOptions,
    faceFrameOptions,
    frameStyleOptions,
    doorStyleWallOptions,
    doorStyleBaseOptions,
    frameSizeOptions,
    configurationOptions,
    archStyleOptions,
    insertPanelWallOptions,
    insertPanelBaseOptions,
    doorEdgeWallOptions,
    doorEdgeBaseOptions,
    metalFinishOptions,
    drawerStyleOptions,
    drawerInsertPanelOptions,
    drawerEdgeOptions,
    topDrawerInsertPanelOptions,
    hingeTypeOptions,
    hardwareOptions,
    closetHardwareColorOptions,
    drawerBoxOptions,
  ]);

  useEffect(() => {
    if (isConvertFlow) {
      validateForm();
    }
  }, [isConvertFlow]);

  const displayOneInchDoorCheckbox = () => {
    return (
      <Wrapper>
        {productLineName === ProductLineEnums.PRODUCT_LINE_CUSTOM && (
          <Spacer h="24px" />
        )}

        <Checkbox
          {...methods.register('oneInchDoor')}
          id="oneinchdoor"
          readOnly={!canEdit}
          title="1” Door"
          {...testId(OrderStylesDoorBuilderTestCheckboxEnum.ONE_INCH_DOOR_CHB)}
        />
      </Wrapper>
    );
  };

  const displayOneInchThickDoorUpchargeField = () => {
    return (
      <Wrapper>
        {faceFrameWatched?.name === FaceFrameEnum.ONEINCH && (
          <Spacer h="48px" />
        )}

        <FormElement>
          <FormLabel>1&quot; Thick Door Upcharge %</FormLabel>

          <Spacer h="4px" />

          <Input
            {...methods.register(
              'oneInchThickDoorUpcharge',
              upchargeValidation()
            )}
            type="text"
            readOnly
            placeholder="0.00"
            data-test="input-oneInchThickDoorUpcharge"
            aria-invalid={
              methods.formState.errors.oneInchThickDoorUpcharge
                ? 'true'
                : 'false'
            }
          />
          <FormError
            label="One InchT hick Door Upcharge"
            error={
              methods.formState.errors.oneInchThickDoorUpcharge as FieldError
            }
            validationSchema={upchargeValidation()}
          />
        </FormElement>
      </Wrapper>
    );
  };

  const doorOverlayRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.doorOverlay,
    ref: doorOverlayRef,
    fieldName: 'doorOverlay',
  });

  const faceFrameRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.faceFrame,
    ref: faceFrameRef,
    fieldName: 'faceFrame',
  });

  const drawerBoxRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.drawerBox,
    ref: drawerBoxRef,
    fieldName: 'drawerBox',
  });

  const styleLoaderContainer = document.getElementById(
    'style-loader-container'
  ) as HTMLElement;

  useUndefinedOptionAsFirst();

  const onFrameSizeChange = (
    option: SelectOptionProps | null,
    triggeredAction: { action: string },
    onChange: (value: SelectOptionProps | null) => void
  ) => {
    onChange(option);
    setSelectedFrameSize(option);
    methods.setValue('customFrameSize', undefined);
  };

  const onFrameSizeCreate = (inputValue: string) => {
    const pattern = /^\d+(\.\d+)?$/;
    if (!inputValue.match(pattern)) {
      methods.setError('frameSize', {
        message: 'Frame size can only be a number.',
        shouldFocus: true,
      });
    } else {
      methods.clearErrors('frameSize');
    }
    const newOption = {
      label: inputValue,
      value: inputValue,
      upcharge:
        productLineName === ProductLineEnums.PRODUCT_LINE_PRELUDE ? 21 : null,
    };
    methods.setValue('customFrameSize', +inputValue);
    methods.setValue('frameSize', newOption, {
      shouldDirty: true,
    });
    setSelectedFrameSize(newOption);
  };

  return (
    <>
      <Spacer h="49px" />
      <H2>Door Builder</H2>
      <Spacer h="32px" />

      <FormProvider {...methods}>
        <Form>
          <Wrapper flex>
            <Wrapper minWidth={450}>
              <H3>Door style</H3>
              <Spacer h="24px" />

              <StyledLabel>
                <Wrapper flex middle>
                  Door Overlay
                  <Spacer w="15px" />
                  {doorOverlayOptionsLoading && <Loader size={16} noSpacing />}
                </Wrapper>
              </StyledLabel>
              <Spacer h="18px" />

              <ContainerLarge ref={doorOverlayRef}>
                <Wrapper flex middle>
                  {!doorOverlayOptions?.length && (
                    <P color={nevada}>
                      Door overlay options are being loaded...
                    </P>
                  )}

                  <Controller
                    control={methods.control}
                    name="doorOverlay"
                    rules={{ required: true }}
                    render={() => (
                      <>
                        {doorOverlayOptions?.map((doorOverlayOption) => (
                          <StyledRadioButton
                            aria-invalid={
                              methods.formState.errors.doorOverlay
                                ? 'true'
                                : 'false'
                            }
                            key={doorOverlayOption.id}
                            checked={
                              doorOverlayOption.id === doorOverlayWatched?.id
                            }
                            name="doorOverlay"
                            id={`doorOverlay--${doorOverlayOption.id}`}
                            onChange={() => {
                              methods.setValue(
                                'doorOverlay',
                                doorOverlayOption,
                                {
                                  shouldDirty: true,
                                }
                              );
                            }}
                            title={UtilService.toCapitalize(
                              doorOverlayOption.name!
                            )}
                            disabled={!canEdit}
                          />
                        ))}
                      </>
                    )}
                  />
                </Wrapper>

                <FormError
                  label="Door overlay"
                  error={methods.formState.errors.doorOverlay as FieldError}
                  validationSchema={{ required: true }}
                />

                {config.doorBuilder.showFaceFrame && (
                  <Wrapper flex middle ref={faceFrameRef}>
                    <Wrapper>
                      <Spacer h="18px" />
                      <StyledLabel>
                        <Wrapper flex middle>
                          Face Frame
                          <Spacer w="15px" />
                          {faceFrameOptionsLoading && (
                            <Loader size={16} noSpacing />
                          )}
                        </Wrapper>
                      </StyledLabel>

                      <Spacer h="15px" />

                      {faceFrameOptionsLoading && !faceFrameOptions?.length && (
                        <Spacer h="21.5px" />
                      )}

                      {!faceFrameOptionsLoading &&
                        !faceFrameOptions?.length && (
                          <P color={nevada}>
                            Please select Door overlay in order to get face
                            frames.
                          </P>
                        )}

                      <Controller
                        control={methods.control}
                        name="faceFrame"
                        rules={{ required: true }}
                        render={({ field: { onBlur, name } }) => (
                          <Wrapper flex middle>
                            {faceFrameOptions?.map((faceFrameOption) => (
                              <StyledRadioButton
                                id={`faceFrame--${faceFrameOption.id}`}
                                key={faceFrameOption.id}
                                onBlur={onBlur}
                                name={name}
                                aria-invalid={
                                  methods.formState.errors.faceFrame
                                    ? 'true'
                                    : 'false'
                                }
                                checked={
                                  faceFrameOption.id === faceFrameWatched?.id
                                }
                                onChange={() => {
                                  methods.setValue(
                                    'faceFrame',
                                    faceFrameOption,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                title={faceFrameOption.name}
                                disabled={!canEdit}
                              />
                            ))}
                          </Wrapper>
                        )}
                      />

                      <FormError
                        label="Face Frame"
                        error={methods.formState.errors.faceFrame as FieldError}
                        validationSchema={{ required: true }}
                      />
                    </Wrapper>

                    <Spacer w="20px" />

                    {config.doorBuilder.showOneInchThickDoorUpcharge &&
                      faceFrameWatched?.name === FaceFrameEnum.ONEINCH &&
                      isUserCSR &&
                      displayOneInchThickDoorUpchargeField()}

                    <Spacer w="20px" />

                    {doorOverlayWatched &&
                      config.doorBuilder.showOneInchFaceFrame &&
                      isUserCSR && (
                        <Wrapper>
                          <Spacer h="57px" />
                          <Checkbox
                            title='1" Face Frame'
                            id="oneInchFaceFrame"
                            {...methods.register('oneInchFaceFrame')}
                            readOnly
                          />
                        </Wrapper>
                      )}
                  </Wrapper>
                )}

                <Spacer h="30px" />

                <Wrapper flex middle>
                  <Wrapper maxWidth={220} flexGrow>
                    <StyledLabel>
                      <Wrapper flex middle>
                        Frame Style
                        <Spacer w="10px" />
                        {frameStyleOptionsLoading && (
                          <Loader size={16} noSpacing />
                        )}
                      </Wrapper>
                    </StyledLabel>

                    <Spacer h="6px" />

                    <WarningTooltip
                      fieldName="Frame Style"
                      withTooltip={UtilService.shouldShowTooltip(
                        frameStyleOptions,
                        frameStyleWatched
                      )}
                    >
                      <Controller
                        control={methods.control}
                        name="frameStyle"
                        rules={{ required: true }}
                        render={({ field: { onChange, ref, value } }) => (
                          <Select
                            ref={ref}
                            value={value}
                            onChange={(val: SelectOptionProps) => onChange(val)}
                            aria-invalid={
                              methods.formState.errors.frameStyle
                                ? 'true'
                                : 'false'
                            }
                            isDisabled={!frameStyleOptions?.length || !canEdit}
                            options={frameStyleOptions}
                          />
                        )}
                      />
                    </WarningTooltip>

                    <FormError
                      label="Frame Style"
                      error={methods.formState.errors.frameStyle as FieldError}
                      validationSchema={{ required: true }}
                    />
                  </Wrapper>

                  <Spacer w="48px" />

                  {config.doorBuilder.showOneInchDoor &&
                    productLineName === ProductLineEnums.PRODUCT_LINE_CUSTOM &&
                    displayOneInchDoorCheckbox()}
                </Wrapper>
              </ContainerLarge>

              <Spacer h="30px" />

              {config.doorBuilder.showNoMidrails && (
                <Wrapper flex middle>
                  <StyledLabel>Midrails</StyledLabel>
                  <Spacer w="10px" />

                  <Controller
                    control={methods.control}
                    name="midrails"
                    rules={{
                      required: midrailsOptions?.required ?? false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ToggleCheckbox
                        onChange={onChange}
                        id="noMidrailCheckbox"
                        checked={value}
                        readOnly={shouldDisableNoMidrails()}
                        area-invalid={
                          methods.formState.errors.midrails ? 'true' : 'false'
                        }
                      />
                    )}
                  />

                  <Spacer w="10px" />
                  <StyledLabel>No Midrails</StyledLabel>
                  <Spacer w="20px" />
                  {midrailsOptionsLoading && <Loader size={16} noSpacing />}
                </Wrapper>
              )}

              {config.doorBuilder.showOneInchDoor &&
                productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE && (
                  <Wrapper flex middle>
                    {displayOneInchDoorCheckbox()}

                    <Spacer w="20px" />

                    {config.doorBuilder.showOneInchThickDoorUpcharge &&
                      oneInchDoorWatched &&
                      isUserCSR &&
                      displayOneInchThickDoorUpchargeField()}
                  </Wrapper>
                )}
            </Wrapper>

            <Wrapper maxWidth={450} flexGrow>
              <OrderStyleNote />
            </Wrapper>
          </Wrapper>

          <Spacer h="48px" />
          <Divider />
          <Spacer h="32px" />

          {isUserCSR && (
            <Wrapper flex>
              <Spacer w="816px" />

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    <Checkbox
                      title="HDF Panels"
                      id="hdfPanels"
                      {...methods.register('hdfPanels')}
                      disabled={!canEdit}
                    />
                  </Wrapper>
                </StyledLabel>
              </ContainerSmall>
            </Wrapper>
          )}

          <Spacer h="32px" />

          <Wrapper flex>
            {/* Create space for prefix */}
            <Spacer w="88px" />

            <ContainerGrid>
              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Door Style
                    <Spacer w="15px" />
                    {doorStyleOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>
                <Spacer h="6px" />
                <InputWithPrefix content="Wall" width="88px">
                  <WarningTooltip
                    fieldName="Door Style"
                    withTooltip={UtilService.shouldShowTooltip(
                      doorStyleWallOptions,
                      doorStyleWallWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      name="doorStyleWall"
                      rules={{ required: true }}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          options={doorStyleWallOptions}
                          aria-invalid={
                            methods.formState.errors.doorStyleWall
                              ? 'true'
                              : 'false'
                          }
                          isDisabled={!doorStyleWallOptions?.length || !canEdit}
                        />
                      )}
                    />
                  </WarningTooltip>
                </InputWithPrefix>

                <Spacer h="6px" />

                <InputWithPrefix content="Base" width="88px">
                  <Controller
                    control={methods.control}
                    name="doorStyleBase"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        aria-invalid={
                          methods.formState.errors.doorStyleBase
                            ? 'true'
                            : 'false'
                        }
                        options={doorStyleBaseOptions}
                        isDisabled
                      />
                    )}
                  />
                </InputWithPrefix>

                <Spacer h="16px" />

                {doorStyleWallWatched?.imageUrl && (
                  <ImgModal
                    nested
                    width={162}
                    height={162}
                    alt="Door Style Thumbnail"
                    src={doorStyleWallWatched.imageUrl}
                  />
                )}
              </ContainerSmall>

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Frame Size
                    <Spacer w="15px" />
                    {frameSizeOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Frame Size"
                  withTooltip={UtilService.shouldShowTooltip(
                    frameSizeOptions,
                    selectedFrameSize
                  )}
                >
                  <Controller
                    control={methods.control}
                    name="frameSize"
                    rules={{
                      required:
                        !!frameSizeOptions?.length && !selectedFrameSize,
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <CreatableStyledSelect
                        ref={ref}
                        value={selectedFrameSize}
                        onChange={(
                          option: SelectOptionProps | null,
                          triggeredAction: { action: string }
                        ) =>
                          onFrameSizeChange(option, triggeredAction, onChange)
                        }
                        defaultOptions={frameSizeOptions}
                        className={
                          methods.formState.errors.frameSize ? 'invalid' : ''
                        }
                        isDisabled={!frameSizeOptions?.length || !canEdit}
                        onCreateOption={onFrameSizeCreate}
                        isSearchable={isUserCSR}
                      />
                    )}
                  />
                </WarningTooltip>
              </ContainerSmall>

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Configuration
                    <Spacer w="15px" />
                    {doorStyleOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>
                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Wall Configuration"
                  withTooltip={UtilService.shouldShowTooltip(
                    configurationOptions?.[doorStyleWallWatched?.value],
                    configurationWallWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="configurationWall"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        aria-invalid={
                          methods.formState.errors.configurationWall
                            ? 'true'
                            : 'false'
                        }
                        isDisabled={
                          !configurationOptions?.[doorStyleWallWatched?.value]
                            ?.length ||
                          doorStyleOptionsLoading ||
                          !doorStyleWallWatched ||
                          !canEdit
                        }
                        options={
                          doorStyleWallWatched &&
                          configurationOptions &&
                          configurationOptions[doorStyleWallWatched?.value]
                        }
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Base Configuration"
                  withTooltip={UtilService.shouldShowTooltip(
                    configurationOptions?.[doorStyleWallWatched?.value],
                    configurationBaseWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="configurationBase"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        isDisabled={
                          !configurationOptions?.[doorStyleWallWatched?.value]
                            ?.length ||
                          doorStyleOptionsLoading ||
                          !doorStyleWallWatched ||
                          !canEdit
                        }
                        aria-invalid={
                          methods.formState.errors.configurationBase
                            ? 'true'
                            : 'false'
                        }
                        options={
                          doorStyleWallWatched &&
                          configurationOptions &&
                          configurationOptions[doorStyleWallWatched.value]
                        }
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="16px" />

                {configurationWallWatched &&
                  configurationWallWatched.imageUrl && (
                    <ImgModal
                      alt="Configuration Thumbnail"
                      height={162}
                      nested
                      src={configurationWallWatched.imageUrl}
                      width={162}
                    />
                  )}
              </ContainerSmall>

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Arch Style
                    <Spacer w="15px" />
                    {archStyleOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Arch Style"
                  withTooltip={UtilService.shouldShowTooltip(
                    archStyleOptions,
                    archStyleWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    name="archStyle"
                    rules={{ required: !!archStyleOptions?.length }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        aria-invalid={
                          methods.formState.errors.archStyle ? 'true' : 'false'
                        }
                        options={archStyleOptions}
                        isDisabled={!archStyleOptions?.length || !canEdit}
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="66px" />

                {archStyleWatched?.imageUrl && (
                  <ImgModal
                    height={162}
                    nested
                    width={162}
                    alt="Arch Style Thumbnail"
                    src={archStyleWatched.imageUrl}
                  />
                )}
              </ContainerSmall>

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Insert Panel
                    <Spacer w="15px" />
                    {(insertPanelWallOptionsLoading ||
                      insertPanelBaseOptionsLoading) && (
                      <Loader size={16} noSpacing />
                    )}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Wall Insert Panel"
                  withTooltip={UtilService.shouldShowTooltip(
                    insertPanelWallOptions,
                    insertPanelWallWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    name="insertPanelWall"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        aria-invalid={
                          methods.formState.errors.insertPanelBase
                            ? 'true'
                            : 'false'
                        }
                        options={insertPanelWallOptions}
                        isDisabled={!insertPanelWallOptions?.length || !canEdit}
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Base Insert Panel"
                  withTooltip={UtilService.shouldShowTooltip(
                    insertPanelBaseOptions,
                    insertPanelBaseWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="insertPanelBase"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        options={insertPanelBaseOptions}
                        aria-invalid={
                          methods.formState.errors.insertPanelBase
                            ? 'true'
                            : 'false'
                        }
                        isDisabled={!insertPanelBaseOptions?.length || !canEdit}
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="16px" />

                {insertPanelWallWatched?.imageUrl && (
                  <ImgModal
                    alt="Insert Panel Thumbnail"
                    height={162}
                    nested
                    src={insertPanelWallWatched.imageUrl}
                    width={162}
                  />
                )}
              </ContainerSmall>

              <ContainerSmall>
                <StyledLabel>
                  <Wrapper flex middle>
                    Door Edge
                    <Spacer w="15px" />
                    {(doorEdgeBaseOptionsLoading ||
                      doorEdgeWallOptionsLoading) && (
                      <Loader size={16} noSpacing />
                    )}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Wall Door Edge"
                  withTooltip={UtilService.shouldShowTooltip(
                    doorEdgeWallOptions,
                    doorEdgeWallWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="doorEdgeWall"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        options={doorEdgeWallOptions}
                        aria-invalid={
                          methods.formState.errors.doorEdgeWall
                            ? 'true'
                            : 'false'
                        }
                        isDisabled={!doorEdgeWallOptions?.length || !canEdit}
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="6px" />

                <WarningTooltip
                  fieldName="Base Door Edge"
                  withTooltip={UtilService.shouldShowTooltip(
                    doorEdgeBaseOptions,
                    doorEdgeBaseWatched
                  )}
                >
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="doorEdgeBase"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        aria-invalid={
                          methods.formState.errors.doorEdgeBase
                            ? 'true'
                            : 'false'
                        }
                        options={doorEdgeBaseOptions}
                        isDisabled={!doorEdgeBaseOptions?.length || !canEdit}
                      />
                    )}
                  />
                </WarningTooltip>

                <Spacer h="16px" />

                {doorEdgeWallWatched?.imageUrl && (
                  <ImgModal
                    src={doorEdgeWallWatched.imageUrl}
                    alt="Door Edge Thumbnail"
                    height={162}
                    nested
                    width={162}
                  />
                )}
              </ContainerSmall>
            </ContainerGrid>
          </Wrapper>

          <Spacer h="32px" />

          <Wrapper flex flexWrap alignStart>
            <Wrapper flexGrow>
              {config.doorBuilder.showMetalFinish && (
                <>
                  <Wrapper flex>
                    <Spacer w="88px" />
                    <ContainerMedium>
                      <Wrapper flexGrow>
                        <StyledLabel>
                          <Wrapper flex middle>
                            Metal Finish
                            <Spacer w="15px" />
                            {metalFinishOptionsLoading && (
                              <Loader size={16} noSpacing />
                            )}
                          </Wrapper>
                        </StyledLabel>

                        <Spacer h="6px" />

                        <WarningTooltip
                          fieldName="Metal Finish"
                          withTooltip={UtilService.shouldShowTooltip(
                            metalFinishOptions,
                            metalFinishWatched
                          )}
                        >
                          <Controller
                            control={methods.control}
                            name="metalFinish"
                            rules={{ required: !!metalFinishOptions?.length }}
                            render={({ field: { onChange, ref, value } }) => (
                              <Select
                                ref={ref}
                                value={value}
                                onChange={(val: SelectOptionProps) =>
                                  onChange(val)
                                }
                                aria-invalid={
                                  methods.formState.errors.metalFinish
                                    ? 'true'
                                    : 'false'
                                }
                                options={metalFinishOptions}
                                isDisabled={
                                  !metalFinishOptions?.length || !canEdit
                                }
                              />
                            )}
                          />
                        </WarningTooltip>

                        <FormError
                          label="Metal finish"
                          error={
                            methods.formState.errors.metalFinish as FieldError
                          }
                          validationSchema={{
                            required: !!metalFinishOptions?.length,
                          }}
                        />
                      </Wrapper>
                    </ContainerMedium>
                    <Spacer w="16px" />
                    <ContainerSmall>
                      <StyledLabel>
                        <Wrapper flex middle>
                          Add $
                        </Wrapper>
                      </StyledLabel>
                      <Spacer h="6px" />
                      <Input
                        {...methods.register(
                          'metalFinishUpcharge',
                          onlyDigitValidation({}, 'Metal Finish Upcharge')
                        )}
                        onBlur={(e) =>
                          UtilService.withDecimal(
                            'metalFinishUpcharge',
                            e.target.value || '0',
                            methods.setValue
                          )
                        }
                        aria-invalid={
                          methods.formState.errors.metalFinishUpcharge
                            ? 'true'
                            : 'false'
                        }
                        readOnly
                      />
                      <FormError
                        label="Metal Finish Upcharge"
                        error={
                          methods.formState.errors
                            .metalFinishUpcharge as FieldError
                        }
                        validationSchema={onlyDigitValidation(
                          {},
                          'Metal Finish Upcharge'
                        )}
                      />
                    </ContainerSmall>
                  </Wrapper>
                  <Spacer h="64px" />
                </>
              )}

              <Wrapper flex flexGrow>
                <Spacer w="88px" />

                {/* Create space for prefix */}

                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Drawer Style
                      <Spacer w="15px" />
                      {drawerStyleOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <InputWithPrefix content="Drawer" width="88px">
                    <WarningTooltip
                      fieldName="Drawer Style"
                      withTooltip={UtilService.shouldShowTooltip(
                        drawerStyleOptions,
                        drawerStyleWatched
                      )}
                    >
                      <Controller
                        control={methods.control}
                        rules={{ required: true }}
                        name="drawerStyle"
                        render={({ field: { onChange, ref, value } }) => (
                          <Select
                            ref={ref}
                            value={value}
                            onChange={(val: SelectOptionProps) => onChange(val)}
                            aria-invalid={
                              methods.formState.errors.drawerStyle
                                ? 'true'
                                : 'false'
                            }
                            options={drawerStyleOptions}
                            isDisabled={!drawerStyleOptions?.length || !canEdit}
                          />
                        )}
                      />
                    </WarningTooltip>
                  </InputWithPrefix>

                  <Spacer h="16px" />

                  {drawerStyleWatched?.imageUrl && (
                    <ImgModal
                      src={drawerStyleWatched.imageUrl}
                      alt="Drawer Style Thumbnail"
                      height={162}
                      nested
                      width={162}
                    />
                  )}
                </ContainerSmall>

                <Spacer w="16px" />

                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Drawer Insert Panel
                      <Spacer w="15px" />
                      {drawerInsertPanelOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <WarningTooltip
                    fieldName="Drawer Insert Panel"
                    withTooltip={UtilService.shouldShowTooltip(
                      drawerInsertPanelOptions,
                      drawerInsertPanelWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      rules={{ required: true }}
                      name="drawerInsertPanel"
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          aria-invalid={
                            methods.formState.errors.drawerInsertPanel
                              ? 'true'
                              : 'false'
                          }
                          options={drawerInsertPanelOptions}
                          isDisabled={
                            !drawerInsertPanelOptions?.length || !canEdit
                          }
                        />
                      )}
                    />
                  </WarningTooltip>

                  <Spacer h="16px" />

                  {drawerInsertPanelWatched?.imageUrl && (
                    <ImgModal
                      alt="Drawer Insert Panel Thumbnail"
                      height={162}
                      nested
                      src={drawerInsertPanelWatched.imageUrl}
                      width={162}
                    />
                  )}
                </ContainerSmall>

                <Spacer w="16px" />

                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Drawer Edge
                      <Spacer w="15px" />
                      {drawerEdgeOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <WarningTooltip
                    fieldName="Drawer Edge"
                    withTooltip={UtilService.shouldShowTooltip(
                      drawerEdgeOptions,
                      drawerEdgeWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      rules={{ required: true }}
                      name="drawerEdge"
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          aria-invalid={
                            methods.formState.errors.drawerEdge
                              ? 'true'
                              : 'false'
                          }
                          options={drawerEdgeOptions}
                          isDisabled={!drawerEdgeOptions?.length || !canEdit}
                        />
                      )}
                    />
                  </WarningTooltip>

                  <Spacer h="16px" />

                  {drawerEdgeWatched?.imageUrl && (
                    <ImgModal
                      src={drawerEdgeWatched.imageUrl}
                      alt="Drawer Edge Thumbnail"
                      height={162}
                      nested
                      width={162}
                    />
                  )}
                </ContainerSmall>
              </Wrapper>

              {config.doorBuilder.showDrawerTopPanel && (
                <Wrapper flex alignStart>
                  <Spacer w="267px" />

                  <ContainerSmall>
                    <Spacer h="20px" />

                    <StyledLabel>
                      <Wrapper flex middle>
                        Top Drawer Insert Panel
                        <Spacer w="15px" />
                        {topDrawerInsertPanelOptionsLoading && (
                          <Loader size={16} noSpacing />
                        )}
                      </Wrapper>
                    </StyledLabel>

                    <Spacer h="6px" />

                    <WarningTooltip
                      fieldName="Top Drawer Insert Panel"
                      withTooltip={UtilService.shouldShowTooltip(
                        topDrawerInsertPanelOptions,
                        topDrawerInsertPanelWatched
                      )}
                    >
                      <Controller
                        control={methods.control}
                        name="topDrawerInsertPanel"
                        render={({ field: { onChange, ref, value } }) => (
                          <Select
                            ref={ref}
                            value={value}
                            onChange={(val: SelectOptionProps) => onChange(val)}
                            options={topDrawerInsertPanelOptions}
                            isDisabled={
                              !topDrawerInsertPanelOptions?.length || !canEdit
                            }
                          />
                        )}
                      />
                    </WarningTooltip>
                  </ContainerSmall>
                </Wrapper>
              )}

              <Spacer h="48px" />

              <ContainerMedium flex>
                <Spacer w="30px" />

                <Wrapper flexGrow>
                  <StyledLabel>Door Notes</StyledLabel>
                  <Spacer h="8px" />

                  <Textarea
                    placeholder="Door note..."
                    aria-invalid={
                      methods.formState.errors.doorNotes ? 'true' : 'false'
                    }
                    {...methods.register('doorNotes', noteValidation())}
                    readOnly={!canEdit}
                    {...testId(
                      OrderStylesDoorBuilderTestTextAreaEnum.DOOR_NOTES_TA
                    )}
                  />

                  <FormError
                    label="Door Notes"
                    error={methods.formState.errors.doorNotes}
                    validationSchema={noteValidation()}
                  />
                </Wrapper>
              </ContainerMedium>

              <Spacer h="38px" />

              <DrawerBoxContainer ref={drawerBoxRef}>
                <StyledLabel>
                  <Wrapper flex middle>
                    Drawer Box
                    <Spacer w="15px" />
                    {drawerBoxOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="18px" />

                {drawerBoxOptionsLoading && (
                  <P>Drawer boxes are being loaded...</P>
                )}

                {!drawerBoxOptionsLoading && !drawerBoxOptions?.length && (
                  <P>There are no drawer boxes for selected product line.</P>
                )}

                <Wrapper flex>
                  <Controller
                    control={methods.control}
                    name="drawerBox"
                    rules={{ required: true }}
                    render={() => (
                      <>
                        {drawerBoxOptions?.map((drawerBoxOption) => (
                          <StyledRadioButton
                            checked={
                              drawerBoxOption.value === drawerBoxWatched?.value
                            }
                            key={drawerBoxOption.value}
                            onChange={() => {
                              methods.setValue('drawerBox', drawerBoxOption, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            id={`drawerBox--${drawerBoxOption.value}`}
                            title={drawerBoxOption.label}
                            aria-invalid={
                              methods.formState.errors.drawerBox
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                          />
                        ))}
                      </>
                    )}
                  />
                </Wrapper>

                <FormError
                  label="Drawer Box"
                  error={methods.formState.errors.drawerBox as FieldError}
                  validationSchema={{ required: true }}
                />
              </DrawerBoxContainer>
              <Spacer h="38px" />
              {config.doorBuilder.showToeSpaceType && (
                <Wrapper flex middle>
                  <Spacer w="30px" />
                  <ContainerSmall>
                    <StyledLabel>
                      <Wrapper flex middle>
                        Toe Space Type
                        <Spacer w="15px" />
                        {toeSpaceTypeOptionsLoading && (
                          <Loader size={16} noSpacing />
                        )}
                      </Wrapper>
                    </StyledLabel>

                    <Spacer h="6px" />

                    <WarningTooltip
                      fieldName="Toe Space Type"
                      withTooltip={UtilService.shouldShowTooltip(
                        toeSpaceTypeOptions,
                        toeSpaceTypeWatched
                      )}
                    >
                      <Controller
                        control={methods.control}
                        rules={{
                          required: config.doorBuilder.showToeSpaceType,
                        }}
                        name="toeSpaceType"
                        render={({ field: { onChange, ref, value } }) => (
                          <Select
                            ref={ref}
                            value={value}
                            onChange={(val: SelectOptionProps) => onChange(val)}
                            aria-invalid={
                              methods.formState.errors.toeSpaceType
                                ? 'true'
                                : 'false'
                            }
                            options={toeSpaceTypeOptions}
                            isDisabled={
                              !toeSpaceTypeOptions?.length || !canEdit
                            }
                          />
                        )}
                      />
                    </WarningTooltip>

                    <FormError
                      label="Toe Space Type"
                      error={
                        methods.formState.errors.toeSpaceType as FieldError
                      }
                      validationSchema={{
                        required: config.doorBuilder.showToeSpaceType,
                      }}
                    />
                    <Spacer h="16px" />
                    {toeSpaceTypeWatched?.imageUrl && (
                      <ImgModal
                        src={toeSpaceTypeWatched.imageUrl}
                        alt="Toe Space Type Thumbnail"
                        height={162}
                        nested
                        width={162}
                      />
                    )}
                  </ContainerSmall>
                </Wrapper>
              )}
            </Wrapper>

            <DoorBuilderUpcharges flexGrow maxWidth={512}>
              <DoorUpcharges />
            </DoorBuilderUpcharges>
          </Wrapper>
          <Spacer h="16px" />
          <Divider />
          <Spacer h="26px" />

          <H3>Hardware</H3>
          <Spacer h="24px" />

          <Wrapper flex between>
            <Wrapper maxWidth={380} flexGrow>
              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Hinge Type
                      <Spacer w="15px" />
                      {hingeTypeOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <WarningTooltip
                    fieldName="Hinge Type"
                    withTooltip={UtilService.shouldShowTooltip(
                      hingeTypeOptions,
                      hingeTypeWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      name="hingeType"
                      rules={{ required: true }}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          aria-invalid={
                            methods.formState.errors.hingeType
                              ? 'true'
                              : 'false'
                          }
                          options={hingeTypeOptions}
                          isDisabled={!hingeTypeOptions?.length || !canEdit}
                        />
                      )}
                    />
                  </WarningTooltip>

                  <FormError
                    label="Hinge Type"
                    error={methods.formState.errors.hingeType as FieldError}
                    validationSchema={{ required: true }}
                  />
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('hingeTypeQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'hingeTypeAdd',
                      onlyDigitValidation({}, 'Hinge Type Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'hingeTypeAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.hingeTypeAdd ? 'true' : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Hinge Type Add"
                    error={methods.formState.errors.hingeTypeAdd as FieldError}
                    validationSchema={onlyDigitValidation({}, 'Hinge Type Add')}
                  />
                </ContainerXSmall>
              </Wrapper>

              <Spacer h="16px" />

              <Wrapper flex>
                {hingeTypeWatched && hingeTypeWatched.imageUrl && (
                  <ImgModal
                    alt="Hinge Type Thumbnail"
                    height={162}
                    nested
                    src={hingeTypeWatched.imageUrl}
                    width={162}
                  />
                )}
              </Wrapper>
              <Spacer h="16px" />

              <StyledLabel>Hinge Color</StyledLabel>

              <Spacer h="2px" />

              <StyledLabel bold invalid={!!methods.formState.errors.hingeColor}>
                {hingeColorWatched ? hingeColorWatched.label : 'N/A'}
              </StyledLabel>

              <Spacer h="16px" />

              <HingeColor />
            </Wrapper>

            <Wrapper maxWidth={400} flexGrow>
              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Door Hardware
                      <Spacer w="15px" />
                      {hardwareLoading && <Loader size={16} noSpacing />}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <WarningTooltip
                    fieldName="Door Hardware"
                    withTooltip={UtilService.shouldShowTooltip(
                      hardwareOptions,
                      doorHardwareWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      name="doorHardware"
                      rules={{ required: true }}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          aria-invalid={
                            methods.formState.errors.doorHardware
                              ? 'true'
                              : 'false'
                          }
                          options={hardwareOptions}
                          isDisabled={!hardwareOptions?.length || !canEdit}
                        />
                      )}
                    />
                  </WarningTooltip>
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('doorHardwareQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'doorHardwareAdd',
                      onlyDigitValidation({}, 'Door Hardware Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'doorHardwareAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.doorHardwareAdd
                        ? 'true'
                        : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Door Hardware Add"
                    error={
                      methods.formState.errors.doorHardwareAdd as FieldError
                    }
                    validationSchema={onlyDigitValidation(
                      {},
                      'Door Hardware Add'
                    )}
                  />
                </ContainerXSmall>
              </Wrapper>

              <FormError
                label="Door Hardware"
                error={methods.formState.errors.doorHardware as FieldError}
                validationSchema={{ required: true }}
              />

              <Spacer h="32px" />

              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Drawer Hardware
                      <Spacer w="15px" />
                      {hardwareLoading && <Loader size={16} noSpacing />}
                    </Wrapper>
                  </StyledLabel>

                  <Spacer h="6px" />

                  <WarningTooltip
                    fieldName="Drawer Hardware"
                    withTooltip={UtilService.shouldShowTooltip(
                      hardwareOptions,
                      drawerHardwareWatched
                    )}
                  >
                    <Controller
                      control={methods.control}
                      rules={{ required: true }}
                      name="drawerHardware"
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          aria-invalid={
                            methods.formState.errors.drawerHardware
                              ? 'true'
                              : 'false'
                          }
                          options={hardwareOptions}
                          isDisabled={!hardwareOptions?.length || !canEdit}
                        />
                      )}
                    />
                  </WarningTooltip>

                  <FormError
                    label="Drawer Hardware"
                    error={
                      methods.formState.errors.drawerHardware as FieldError
                    }
                    validationSchema={{ required: true }}
                  />
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('drawerHardwareQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'drawerHardwareAdd',
                      onlyDigitValidation({}, 'Drawer Hardware Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'drawerHardwareAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.drawerHardwareAdd
                        ? 'true'
                        : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Drawer Hardware Add"
                    error={
                      methods.formState.errors.drawerHardwareAdd as FieldError
                    }
                    validationSchema={onlyDigitValidation(
                      {},
                      'Drawer Hardware Add'
                    )}
                  />
                </ContainerXSmall>
              </Wrapper>

              <Spacer h="30px" />

              {config.doorBuilder.showClosetHardwareColor && (
                <>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Closet Hardware Color
                      <Spacer w="15px" />
                      {closetHardwareColorOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="2px" />

                  <StyledLabel bold>
                    {closetHardwareColorWatched
                      ? closetHardwareColorWatched.label
                      : 'N/A'}
                  </StyledLabel>

                  <Spacer h="16px" />

                  <ClosetHardwareColor />

                  <Spacer h="24px" />
                </>
              )}

              <Wrapper>
                <StyledLabel>Hardware Notes</StyledLabel>
                <Spacer h="10px" />
                <Textarea
                  placeholder="Hardware note..."
                  aria-invalid={
                    methods.formState.errors.hardwareNotes ? 'true' : 'false'
                  }
                  {...methods.register('hardwareNotes', noteValidation())}
                  readOnly={!canEdit}
                  {...testId(
                    OrderStylesDoorBuilderTestTextAreaEnum.HARDWARE_NOTES_TA
                  )}
                />

                <FormError
                  label="Hardware Notes"
                  error={methods.formState.errors.hardwareNotes}
                  validationSchema={noteValidation()}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>

          <OrderFooter>
            <Wrapper flex middle between>
              <ButtonSecondary
                type="button"
                onClick={() =>
                  canSubmit ? handleValidateOrSubmit(true) : redirect(true)
                }
                disabled={saveDoorBuilderLoadingOnBack || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Back',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!saveDoorBuilderLoadingOnBack && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>
              <ButtonPrimary
                data-test="button-StylesDoorBuilderSubmit"
                type="button"
                onClick={() =>
                  canSubmit || goNextAndSaveForFirstTime()
                    ? handleValidateOrSubmit(false)
                    : redirect(false)
                }
                disabled={saveDoorBuilderLoading || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Next',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!saveDoorBuilderLoading && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </Form>
      </FormProvider>

      <ModalWrapper
        open={breakingChanges !== null}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        nested
        onClose={UtilService.onPopupClose}
      >
        {(close: () => void) => (
          <DoorBuilderBreakingChanges
            breakingChanges={breakingChanges}
            loading={saveDoorBuilderLoading || saveDoorBuilderLoadingOnBack}
            dismiss={() =>
              onDoorBuilderBreakingChangesModalDismissHandler(close)
            }
            confirm={methods.handleSubmit((data) =>
              onSubmitHandler(data, shouldGoBack)
            )}
          />
        )}
      </ModalWrapper>

      {false &&
        styleLoaderContainer &&
        createPortal(
          <LoaderFullScreen flex center middle bottom={71} top={132}>
            <Loader size={40} />
          </LoaderFullScreen>,
          styleLoaderContainer
        )}
      <Spacer h="40px" />
      {PopupModal}
    </>
  );
};
