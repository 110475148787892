import { FC, memo, MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import AckChangeLogTable from './components/AckChangeLogTable/AckChangeLogTable';
import OrderLogsHeader from './components/OrderLogsHeader/OrderLogsHeader';
import OrderLogsSubHeader from './components/OrderLogsSubHeader/OrderLogsSubHeader';
import Warnings from './components/Warnings/Warnings';
import WritingHints from './components/WritingHints/WritingHints';
import { OrderLogsSubTabEnum } from './enums/OrderLogsSubTabEnum';
import { OrderLogsTabEnum } from './enums/OrderLogsTabEnum';

interface OrderLogsProps {
  bottomPosition?: boolean;
}

const footerHeight = '72px';

const OrderLogsWrapper = styled.div<OrderLogsProps>`
  ${BoxShadowCSS}
  background: ${({ theme }) => theme.white};
  border-radius: 16px 16px 0px 0px;
  bottom: ${({ bottomPosition }) => (bottomPosition ? 0 : footerHeight)};
  height: 550px;
  left: 0;
  margin: auto;
  max-width: 744px;
  position: fixed;
  right: 0;
  transform: translateY(473px);
  transition: 500ms;
  z-index: 6;

  svg {
    transition: transform 500ms;
  }

  &.open {
    transform: translateY(0);

    svg {
      transform: rotateZ(180deg);
    }
  }
`;

const ListOfChangesBody = styled.div`
  padding: 32px 0;
  height: 100%;
`;

interface OrderLogsTabs {
  tab: OrderLogsTabEnum | null;
  subTab: OrderLogsSubTabEnum | null;
}

const initialTabs = {
  tab: null,
  subTab: null,
};

const OrderLogs: FC<OrderLogsProps> = memo(({ bottomPosition }) => {
  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const [tabs, setTabs] = useState<OrderLogsTabs>(initialTabs);

  const resetAllTabs = () => {
    setTabs(initialTabs);
  };

  const handleArrowClick = () => {
    if (isUserDealerOrSalesRep) {
      setTabs((prevState) => ({
        ...prevState,
        tab: OrderLogsTabEnum.ACK_CHANGE_LOG,
      }));
    } else {
      setTabs({
        tab: OrderLogsTabEnum.HINTS,
        subTab: OrderLogsSubTabEnum.WARNINGS,
      });
    }

    if (tabs.tab !== null) {
      resetAllTabs();
    }
  };

  const handleChangeCurrentTab = (
    e: MouseEvent<HTMLHeadingElement>,
    tab: OrderLogsTabEnum
  ) => {
    e.stopPropagation();

    const sameTab = tabs.tab === tab;

    if (sameTab) {
      resetAllTabs();
      return;
    }

    const subTab =
      tab === OrderLogsTabEnum.HINTS ? OrderLogsSubTabEnum.WARNINGS : null;

    setTabs({ tab, subTab: sameTab ? null : subTab });
  };

  return (
    <OrderLogsWrapper
      className={
        tabs.tab !== null
          ? 'open order-list-of-changes'
          : 'order-list-of-changes'
      }
      bottomPosition={bottomPosition}
    >
      <OrderLogsHeader
        handleArrowClick={handleArrowClick}
        handleChangeCurrentTab={handleChangeCurrentTab}
        currentTab={tabs.tab}
      />

      {tabs.tab === OrderLogsTabEnum.HINTS && (
        <OrderLogsSubHeader
          subTab={tabs.subTab}
          setSubTab={(subTab) =>
            setTabs((prevState) => ({ ...prevState, subTab }))
          }
        />
      )}

      {tabs.tab === OrderLogsTabEnum.ACK_CHANGE_LOG && <AckChangeLogTable />}

      {tabs.tab === OrderLogsTabEnum.ORDER_CHANGE_LOG && (
        <AckChangeLogTable ackOnly={false} />
      )}

      {tabs.tab !== OrderLogsTabEnum.ACK_CHANGE_LOG && (
        <ListOfChangesBody>
          {tabs.subTab === OrderLogsSubTabEnum.WARNINGS && <Warnings />}

          {tabs.subTab === OrderLogsSubTabEnum.WRITING_HINTS && (
            <WritingHints />
          )}
        </ListOfChangesBody>
      )}
    </OrderLogsWrapper>
  );
});

export default OrderLogs;
