import { RootState } from 'store';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { CurtainCreateNewTopicForm } from 'curtain/components/CurtainCreateNewTopicForm';
import {
  clearCurtain,
  setCreateTopic,
  setUpdatedTopic,
} from 'curtain/store/curtainActions';

import { ButtonIcon } from 'shared/components/Button';
import { H3 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { CurtainCheckbox } from 'shared/components/CurtainCheckbox';

const TopicContainer = styled.div`
  height: 100%;
`;

const TopicHeader = styled(Wrapper)`
  padding: 14px;
  min-height: 65px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
`;

const CloseButton = styled(ButtonIcon)`
  path {
    fill: ${({ theme }) => theme.mineShaftVariation};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

interface CurtainTopicNewSectionProps {
  topic: ICurtainTopic | null;
}

export const CurtainTopicNewSection = memo(
  ({ topic }: CurtainTopicNewSectionProps) => {
    const [isPrivateTopicChecked, setIsPrivateTopicChecked] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (topic) {
        setIsPrivateTopicChecked(topic.private);
      }
    }, [topic]);

    const addingTopic = useSelector(
      (state: RootState) => state.curtainReducer.addingTopic
    );

    const canCreatePrivateCollaboration = useHasPermissions(
      userPermissionsValues.PRIVATE_COLLABORATION_CREATE
    );

    const activeChannel = useSelector(
      (state: RootState) => state.curtainReducer.activeChannel
    );

    const goBack = () => {
      dispatch(setCreateTopic(false));
      dispatch(setUpdatedTopic(null));
      window.sessionStorage.removeItem(`newtopic_${activeChannel?.id ?? '0'}`);
    };

    return (
      <TopicContainer>
        <TopicHeader flex middle between>
          <Wrapper flex middle>
            <ButtonIcon onClick={goBack} disabled={addingTopic}>
              <ArrowLeftIcon />
            </ButtonIcon>
            <Spacer w="10px" />
            <H3>{topic ? 'Edit Topic' : 'Create a New Topic'}</H3>
          </Wrapper>

          <Wrapper flex middle>
            {canCreatePrivateCollaboration && (
              <>
                <CurtainCheckbox
                  title="Private"
                  id="private-topic"
                  checked={isPrivateTopicChecked}
                  onChange={() =>
                    setIsPrivateTopicChecked(!isPrivateTopicChecked)
                  }
                />
                <Spacer w="10px" />{' '}
              </>
            )}
            <CloseButton onClick={() => dispatch(clearCurtain())}>
              <CloseIcon />
            </CloseButton>
          </Wrapper>
        </TopicHeader>

        <Spacer h="16px" />

        <CurtainCreateNewTopicForm
          isPrivateTopicChecked={isPrivateTopicChecked}
          topic={topic}
        />
      </TopicContainer>
    );
  }
);
