import { FC, useState } from 'react';
import { kashmirBlue, silverChaliceTwo } from 'shared/config/Colors';

import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';

import { H5, H6, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import StyleDetailsField from './StyleDetailsField';
import { DoorCodesTableRow, DoorCodesTableRowContent } from './DoorCodesTable';
import { StyleDetailsFieldWrapper } from './StyleDetailsFieldWrapper';
import { ExpandButton } from '../OrderStyleContainers';

interface ICSOrderStyleDetailsProps {
  styleDetails: Style;
  highlight?: boolean;
}

const ICSOrderStyleDetails: FC<ICSOrderStyleDetailsProps> = ({
  styleDetails,
  highlight,
}) => {
  const config = useProductLineConfiguration(styleDetails.productLine);

  const { highlightStyleField, highlightDeletedStyleField } =
    useHighlightHelper(highlight);

  const [doorExpandebleOpen, setDoorExpandebleOpen] = useState(true);
  const [hardwareExpandableOpen, setHardwareExpandableOpen] = useState(true);
  const [finsihExpandableOpen, setFinishExpandableOpen] = useState(true);
  const [woodTypeExpandableOpen, setWoodTypeExpandableOpen] = useState(true);

  return (
    <>
      <Spacer h="16px" />

      {styleDetails.originalWorkOrderNumber && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="W.O.#"
            fieldName={FieldName.OrderWorkOrderNumber}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.originalWorkOrderNumber}
          />
          <Spacer h="16px" />
        </Wrapper>
      )}
      {styleDetails.note && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="STYLE NOTES"
            fieldName={FieldName.StyleNote}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.note}
            asNote
          />
          <Spacer h="32px" />
        </Wrapper>
      )}

      <Wrapper flex>
        <StyleDetailsFieldWrapper flex between middle>
          <Wrapper flex gap={5}>
            <ExpandButton
              onClick={() => setWoodTypeExpandableOpen(!woodTypeExpandableOpen)}
              iconColor={silverChaliceTwo}
              hoverIconColor={kashmirBlue}
              className={
                woodTypeExpandableOpen
                  ? 'expandable-box-button open'
                  : 'expandable-box-button'
              }
            >
              <ArrowUpIcon />
            </ExpandButton>
            <H5>WOOD</H5>
          </Wrapper>
        </StyleDetailsFieldWrapper>
      </Wrapper>
      {woodTypeExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            {styleDetails.materialGroup?.name && (
              <StyleDetailsField
                fieldLabel="WOOD/MATERIAL"
                fieldName={FieldName.StyleWoodMaterial}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.materialGroup?.name}
              />
            )}

            {!!styleDetails.woodEffects.length && (
              <StyleDetailsField
                fieldLabel="WOOD EFFECT(S)"
                fieldName={FieldName.StyleWoodEffect}
                highlightStyleField={highlightStyleField}
                highlightDeletedStyleField={highlightDeletedStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.woodEffects
                  .reduce((agg, woodEffect) => {
                    agg.push(woodEffect.name ?? '');
                    return agg;
                  }, [] as string[])
                  .filter((x) => x)
                  .join(', ')}
              />
            )}
          </Wrapper>

          {styleDetails.woodNotes && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="WOOD NOTES"
                fieldName={FieldName.StyleWoodNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.woodNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}

      <Spacer h="32px" />
      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setFinishExpandableOpen(!finsihExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            finsihExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>FINISH</H5>
      </Wrapper>

      {finsihExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            {styleDetails.materialColor?.name && (
              <StyleDetailsField
                fieldLabel="MATERIAL COLOR"
                fieldName={FieldName.StyleMaterialColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.materialColor?.name}
              />
            )}

            {styleDetails.finishColor?.name && (
              <StyleDetailsField
                fieldLabel="FINISH COLOR"
                fieldName={FieldName.StyleFinishColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.finishColor?.name}
              />
            )}
          </Wrapper>

          {(styleDetails.materialColor?.name ||
            styleDetails.finishColor?.name) && <Spacer h="16px" />}

          <Wrapper flex>
            {styleDetails.varnish?.name && (
              <StyleDetailsField
                fieldLabel="VARNISH SHEEN"
                fieldName={FieldName.StyleVarnish}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.varnish?.name}
              />
            )}
            {!!styleDetails.finishEffects.length && (
              <StyleDetailsField
                fieldLabel="FINISH EFFECT(S)"
                fieldName={FieldName.StyleFinishEffect}
                highlightStyleField={highlightStyleField}
                highlightDeletedStyleField={highlightDeletedStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.finishEffects
                  .reduce((agg, finishEffect) => {
                    agg.push(finishEffect.name ?? '');
                    return agg;
                  }, [] as string[])
                  .filter((x) => x)
                  .join(', ')}
              />
            )}
          </Wrapper>
          {styleDetails.specialFinishSample && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="SPECIAL FINISH SAMPLE"
                fieldName={FieldName.StyleSpecialFinishSample}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.specialFinishSample}
              />
            </>
          )}

          {styleDetails.finishNotes && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="FINISH NOTES"
                fieldName={FieldName.StyleFinishNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.finishNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}

      <Spacer h="32px" />

      <Wrapper flex>
        <StyleDetailsFieldWrapper>
          <Wrapper flex gap={5}>
            <ExpandButton
              onClick={() => setDoorExpandebleOpen(!doorExpandebleOpen)}
              iconColor={silverChaliceTwo}
              hoverIconColor={kashmirBlue}
              className={
                doorExpandebleOpen
                  ? 'expandable-box-button open'
                  : 'expandable-box-button'
              }
            >
              <ArrowUpIcon />
            </ExpandButton>
            <H5>DOOR &amp; DRAWER FRONT</H5>
          </Wrapper>

          {doorExpandebleOpen && (
            <Wrapper style={{ marginLeft: '25px' }}>
              <Spacer h="32px" />

              {styleDetails.doorOverlay?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DOOR OVERLAY"
                    fieldName={FieldName.StyleDoorOverlay}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.doorOverlay?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}
              {styleDetails.faceFrame?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="FACE FRAME"
                    fieldName={FieldName.StyleFaceFrame}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.faceFrame?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {styleDetails.frameStyle?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="FRAME STYLE"
                    fieldName={FieldName.StyleFrameStyle}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.frameStyle?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {styleDetails.drawerBox?.name && (
                <StyleDetailsField
                  fieldLabel="DRAWER BOX"
                  fieldName={FieldName.StyleDrawerBox}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.drawerBox?.name}
                />
              )}
            </Wrapper>
          )}
        </StyleDetailsFieldWrapper>

        <Wrapper flex column>
          <DoorCodesTableRow flex between>
            <div />
            <DoorCodesTableRowContent flex between>
              <H5>WALL</H5>

              <Spacer w="15px" />

              <H5>BASE</H5>

              <Spacer w="15px" />

              <H5>DRAWER</H5>
            </DoorCodesTableRowContent>
          </DoorCodesTableRow>

          {doorExpandebleOpen && (
            <>
              <Spacer h="32px" />

              <DoorCodesTableRow flex between>
                <H6>DOOR STYLE</H6>
                <DoorCodesTableRowContent flex between>
                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleWallDoorStyle,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.wallDoorStyle?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleBaseDoorStyle,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.baseDoorStyle?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleDrawerStyle,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.drawerFrontStyle?.name || ''}
                  </PSmall>
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>CONFIGURATION</H6>
                <DoorCodesTableRowContent flex between>
                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleWallDoorConfiguration,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.wallDoorConfiguration?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleBaseDoorConfiguration,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.baseDoorConfiguration?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall />
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>INSERT PANEL</H6>
                <DoorCodesTableRowContent flex between>
                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleWallDoorInsertPanel,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.wallDoorInsertPanel?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleBaseDoorInsertPanel,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.baseDoorInsertPanel?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleDrawerInsertPanel,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.drawerInsertPanel?.name || ''}
                  </PSmall>
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>DOOR EDGE</H6>
                <DoorCodesTableRowContent flex between>
                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleWallDoorEdge,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.wallDoorEdge?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleBaseDoorEdge,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.baseDoorEdge?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleDrawerEdge,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.drawerEdge?.name || ''}
                  </PSmall>
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>
            </>
          )}
        </Wrapper>
      </Wrapper>

      {styleDetails.doorNotes && doorExpandebleOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="16px" />
          <StyleDetailsField
            fieldLabel="DOOR NOTES"
            fieldName={FieldName.StyleDoorNotes}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.doorNotes}
            asNote
          />
        </Wrapper>
      )}

      <Spacer h="32px" />
      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setHardwareExpandableOpen(!hardwareExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            hardwareExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>HARDWARE</H5>
      </Wrapper>

      {hardwareExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            {styleDetails.doorHardware?.name && (
              <StyleDetailsField
                fieldLabel="DOOR HARDWARE"
                fieldName={FieldName.StyleDoorHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.doorHardware?.name}
              />
            )}

            {styleDetails.hingeType?.name && (
              <StyleDetailsField
                fieldLabel="HINGE TYPE"
                fieldName={FieldName.StyleHingeType}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeType?.name}
              />
            )}
          </Wrapper>

          {(styleDetails.doorHardware?.name ||
            styleDetails.hingeType?.name) && <Spacer h="16px" />}

          <Wrapper flex>
            {styleDetails.drawerHardware?.name && (
              <StyleDetailsField
                fieldLabel="DRAWER HARDWARE"
                fieldName={FieldName.StyleDrawerHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.drawerHardware?.name}
              />
            )}

            {styleDetails.hingeColor?.name && (
              <StyleDetailsField
                fieldLabel="HINGE COLOR"
                fieldName={FieldName.StyleHingeColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeColor?.name}
              />
            )}
          </Wrapper>

          {config.doorBuilder.showClosetHardwareColor &&
            styleDetails.closetHardwareColor?.name && (
              <>
                <Spacer h="16px" />

                <StyleDetailsField
                  fieldLabel="CLOSET HARDWARE COLOR"
                  fieldName={FieldName.StyleClosetHardwareColor}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.closetHardwareColor?.name}
                />
              </>
            )}

          {styleDetails.hardwareNotes && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="HARDWARE NOTES"
                fieldName={FieldName.StyleHardwareNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hardwareNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}
      <Spacer h="16px" />
    </>
  );
};

export default ICSOrderStyleDetails;
