import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { LineItemHardware } from 'order/wizard/orderLineItems/interface/ILineItemData';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import FormError from 'shared/components/FormError';
import ImgModal from 'shared/components/ImgModal';
import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Divider, Spacer } from 'shared/components/Layout';
import { Form } from 'shared/components/Form';
import { H2, H3, P } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { RadioButton } from 'shared/components/RadioButton';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Textarea } from 'shared/components/Textarea';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada, silverSandTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAutoSetSingleOptionList } from 'shared/hooks/useAutoSetSingleOptionList';
import { useCanSubmit } from 'shared/hooks/useCanSubmit';
import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { useUndefinedOptionAsFirst } from 'shared/hooks/useUndefinedOptionAsFirst';

import {
  noteValidation,
  onlyDigitValidation,
} from 'shared/validations/validations';

import ClosetHardwareColor from '../steps/OrderStylesDoorBuilder/ClosetHardwareColor/ClosetHardwareColor';
import DoorUpcharges from '../steps/OrderStylesDoorBuilder/DoorUpcharges/DoorUpcharges';
import OrderStyleNote from '../../shared/OrderStyleNote';
import { DoorBuilderOptionsEnum } from '../../enums/DoorBuilderOptionsEnum';
import { StyleSpecifications } from '../../interface/StyleSpecifications';
import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { useScrollToElement } from '../../../../../shared/hooks/useScrollToElement';

import {
  changeStylesStep,
  setIsStylesStepDirty,
} from '../store/orderStylesActions';

import {
  Inovae2OStyleDoorBuilderRequest,
  QuantityAdderTypeWithHardwareId,
  QuantityAdderTypeWithHingeTypeId,
  StyleDoorBuilder,
} from '../../interface/StyleDoorBuilder';

import {
  getClosetHardwareColors,
  getDoorBuilderOptions,
  getDoorEdgeBands,
  getHardware,
  getHardwareQuantities,
  getMaterialDrawers,
  getToeSpaceTypes,
  orderStylesDoorBuilderActions,
  saveInovae2ODoorBuilder,
  setDoorBuilderData,
} from '../store/doorBuilder/orderStylesDoorBuilderActions';

import { DooredgeBandOptionsEnum } from '../../enums/DooredgeBandOptionsEnum';

const StyledLabel = styled.label<{ bold?: boolean; invalid?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, invalid }) => (invalid ? theme.valencia : theme.nevada)};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

const ContainerXSmall = styled.div`
  width: 100%;
  max-width: 80px;
`;

const ContainerSmall = styled.div`
  width: 100%;
  max-width: 162px;
  flex: 1;
`;

const ContainerMedium = styled(Wrapper)`
  width: 100%;
  max-width: 340px;
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 30px;
`;

const InputWithPrefix = styled.div<{ content: string; width?: string }>`
  position: relative;

  &::before {
    align-items: center;
    color: ${({ theme }) => theme.nevada};
    content: '${({ content }) => content}';
    display: block;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    padding-left: 19px;
    line-height: 20px;
    position: absolute;
    right: 100%;
    top: 13px;
    width: ${({ width }) => width};
  }
`;
const RadioColor = styled.button<{ isDisabled: boolean }>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 32px;
  margin-bottom: 5px;
  margin-right: 16px;
  position: relative;
  width: 32px;

  .icon {
    align-items: center;
    display: flex;
    justify-content: center;

    > svg {
      height: auto;
      width: 50%;
    }
  }

  img {
    border-radius: 50%;
  }

  &.active {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    border-radius: 50%;
    bottom: 2px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.lynch},
      0 0 0 2px ${({ theme }) => theme.lynch};
    content: '';
    left: 2px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: 300ms;
  }
  ${({ isDisabled }) => isDisabled && `pointer-events:none; opacity:0.4;`}
`;

const DoorBuilderUpcharges = styled(Wrapper)`
  border: 1px solid ${({ theme }) => theme.mischka};
  padding: 25px 40px;
  border-radius: 20px;
`;
export const Inovae2ODoorBuilder = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const config = useProductLineConfiguration();

  const storedDoorBuilder = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilder
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const storedStyleNote = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.note
  );

  const methods = useForm<StyleDoorBuilder>({
    defaultValues: storedDoorBuilder ?? {
      midrails: false,
      hingeTypeAdd: UtilService.withDecimal(null, '0') as string,
      doorHardwareAdd: UtilService.withDecimal(null, '0') as string,
      drawerHardwareAdd: UtilService.withDecimal(null, '0') as string,
      styleNote: storedStyleNote ?? '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const productLineId = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine?.id
  );

  const isStyleComplete = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.isComplete
  );

  const styleStep = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.step
  );

  const { materialGroup, materialColor, grainDirection } = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specifications ?? ({} as StyleSpecifications)
  );

  const [doorOverlayOptionsLoading, setDoorOverlayOptionsLoading] =
    useState(false);

  const doorOverlayOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorOverlayOptions
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const editMode = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.doorOverlay !== null
  );

  const faceFrameOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.faceFrameOptions
  );

  const [frameStyleOptionsLoading, setFrameStyleOptionsLoading] =
    useState(false);

  const frameStyleOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.frameStyleOptions
  );

  const [doorStyleOptionsLoading, setDoorStyleOptionsLoading] = useState(false);

  const doorStyleWallOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorStyleWallOptions
  );

  const doorStyleBaseOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorStyleBaseOptions
  );

  const [doorEdgeBandOptionsLoading, setDoorEdgeBandOptionsLoading] =
    useState(false);

  const doorEdgeBandOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.doorEdgeBandOptions
  );

  const [toeSpaceTypeOptionsLoading, setToeSpaceTypeOptionsLoading] =
    useState(false);
  const toeSpaceTypeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.toeSpaceTypeOptions
  );

  const [materialDrawerOptionsLoading, setDrawerStyleOptionsLoading] =
    useState(false);

  const materialDrawerOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.materialDrawerOptions
  );

  const [drawerBoxOptionsLoading, setDrawerBoxOptionsLoading] = useState(false);

  const drawerBoxOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.drawerBoxOptions
  );

  const [hingeTypeOptionsLoading, setHingeTypeOptionsLoading] = useState(false);

  const hingeTypeOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hingeTypeOptions
  );

  const hingeColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hingeColorOptions
  );

  const [hardwareLoading, setHardwareLoading] = useState(false);

  const hardwareOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hardwareOptions
  );

  const hardwareQuantities = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hardwareQuantities
  );

  const [
    closetHardwareColorOptionsLoading,
    setClosetHardwareColorOptionsLoading,
  ] = useState(false);

  const closetHardwareColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.closetHardwareColorOptions
  );

  const [saveDoorBuilderLoading, setSaveDoorBuilderLoading] = useState(false);

  const [saveDoorBuilderLoadingOnBack, setSaveDoorBuilderLoadingOnBack] =
    useState(false);

  const doorCodes = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilderOptions.doorCodes
  );

  const doorOverlayWatched = methods.watch('doorOverlay');
  const doorStyleWallWatched = methods.watch('doorStyleWall');
  const doorStyleBaseWatched = methods.watch('doorStyleBase');
  const doorEdgeBandWatched = methods.watch('doorEdgeBand');
  const materialDrawerWatched = methods.watch('materialDrawer');
  const drawerBoxWatched = methods.watch('drawerBox');
  const hingeTypeWatched = methods.watch('hingeType');
  const hingeColorWatched = methods.watch('hingeColor');
  const toeSpaceTypeWatched = methods.watch('toeSpaceType');
  const doorHardwareWatched = methods.watch('doorHardware');
  const drawerHardwareWatched = methods.watch('drawerHardware');
  const closetHardwareColorWatched = methods.watch('closetHardwareColor');

  const { onFormChange, PopupModal } = usePopupMessage();

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.STYLE_OVERVIEW));
    }
  };

  const styleDoorBuilderSavedHandler = (
    data: StyleDoorBuilder,
    goBack: boolean = false
  ) => {
    dispatch(setDoorBuilderData(data));
    redirect(goBack);
    (goBack ? setSaveDoorBuilderLoadingOnBack : setSaveDoorBuilderLoading)(
      false
    );
  };

  const saveStyleDoorBuilderErrorHandler = () => {
    setSaveDoorBuilderLoading(false);
    setSaveDoorBuilderLoadingOnBack(false);
  };

  const mapToDoorBuilderRequest = (
    data: StyleDoorBuilder,
    getStyleAfterUpdate: boolean
  ): Inovae2OStyleDoorBuilderRequest => {
    return {
      doorOverlayId: data.doorOverlay?.id,
      frameStyleId: data.frameStyle?.value,
      wallDoorStyleId: data.doorStyleWall?.value,
      baseDoorStyleId: data.doorStyleBase?.value,
      doorEdgeBandId: +data.doorEdgeBand?.value,
      materialDrawerId: data.materialDrawer?.value,
      doorNotes: data.doorNotes,
      drawerBoxId: data.drawerBox?.value,
      toeSpaceTypeId: +data.toeSpaceType?.value,
      hingeType: {
        hingeTypeId: data.hingeType.value,
        quantity: +data.hingeTypeQty,
        adder: +data.hingeTypeAdd,
      } as QuantityAdderTypeWithHingeTypeId,
      hingeColorId: data.hingeColor?.value,
      doorHardware: {
        hardwareId: data.doorHardware.value,
        quantity: +data.doorHardwareQty,
        adder: +data.doorHardwareAdd,
      } as QuantityAdderTypeWithHardwareId,
      drawerHardware: {
        hardwareId: data.drawerHardware.value,
        quantity: +data.drawerHardwareQty,
        adder: +data.drawerHardwareAdd,
      } as QuantityAdderTypeWithHardwareId,
      hardwareNotes: data.hardwareNotes,
      orderId,
      styleId: styleId!,
      styleStep: (styleStep?.toString() as StylesStepsEnum) ?? null,
      wallDoorUpcharges: UtilService.mapDoorBuilderUpcharges({
        key: 'wallDoorUpcharges',
        upchargeField: data.wallDoorUpcharges,
        doorCodes: doorCodes!,
      }),
      baseDoorUpcharges: UtilService.mapDoorBuilderUpcharges({
        key: 'baseDoorUpcharges',
        upchargeField: data.baseDoorUpcharges,
        doorCodes: doorCodes!,
      }),
      drawerUpcharges: UtilService.mapDoorBuilderUpcharges({
        key: 'drawerUpcharges',
        upchargeField: data.drawerUpcharges,
        doorCodes: doorCodes!,
      }),
      note: data.styleNote,
      getStyleAfterUpdate,
      ...(styleOverride && {
        isOverriden: true,
        overrideReason: styleOverride.overridenReason,
      }),
      ...(selectedProductLine?.name !==
        ProductLineEnums.PRODUCT_LINE_REVOLAE && {
        interiorNotes: data.interiorNotes,
      }),
      ...(selectedProductLine?.name ===
        ProductLineEnums.PRODUCT_LINE_REVOLAE && {
        closetHardwareColorId: data.closetHardwareColor?.value,
      }),
    };
  };

  const onSubmit = ({
    data,
    goBack = false,
    getStyleAfterUpdate = false,
  }: {
    data: StyleDoorBuilder;
    goBack: boolean;
    getStyleAfterUpdate: boolean;
  }) => {
    if (!canEdit) {
      redirect(goBack);
      return;
    }

    if (goBack) {
      setSaveDoorBuilderLoadingOnBack(true);
    } else {
      setSaveDoorBuilderLoading(true);
    }

    dispatch(
      saveInovae2ODoorBuilder(
        mapToDoorBuilderRequest(data, getStyleAfterUpdate),
        () => styleDoorBuilderSavedHandler(data, goBack),
        saveStyleDoorBuilderErrorHandler
      )
    );
  };

  const canSubmit = useCanSubmit({
    isFormDity: methods.formState.isDirty,
    editMode,
    isStyleComplete,
    step: StylesStepsEnum.DOOR_BUILDER,
    styleStep,
  });

  const goNextAndSaveForFirstTime = () => {
    return !methods.formState.isDirty && !isStyleComplete && !editMode;
  };

  const populateQuantityFields = () => {
    const qtyFields = [
      'hingeTypeQty',
      'doorHardwareQty',
      'drawerHardwareQty',
    ] as (keyof StyleDoorBuilder)[];

    qtyFields.forEach((field) => {
      const fieldNameUpdated = field
        .replace('Type', '')
        .replace('Qty', 'Count');

      methods.setValue(
        field,
        (
          hardwareQuantities?.[fieldNameUpdated as keyof LineItemHardware] ?? 0
        ).toString() ?? '0'
      );
    });
  };

  const setDefaultHingeType = () => {
    const defaultHingeType = hingeTypeOptions?.find(
      (hingeTypeOption) => hingeTypeOption.isDefault
    );

    const hingeTypeValue = methods.formState.isDirty
      ? defaultHingeType
      : storedDoorBuilder?.hingeType ?? defaultHingeType;

    if (hingeTypeValue) {
      methods.setValue('hingeType', hingeTypeValue);
    }
  };

  const setDefaultHingeColor = () => {
    const hingeColorList = hingeColorOptions![hingeTypeWatched!.value];

    const defaultHingeColor = hingeColorList?.find(
      (hingeColorOption) => hingeColorOption.isDefault
    );

    if (defaultHingeColor && hingeColorList.length > 1) {
      methods.setValue(
        'hingeColor',
        methods.formState.isDirty
          ? defaultHingeColor
          : storedDoorBuilder?.hingeColor ?? defaultHingeColor
      );
    } else if ((hingeColorList ?? []).length === 1) {
      methods.setValue(
        'hingeColor',
        methods.formState.isDirty
          ? hingeColorList[0]
          : storedDoorBuilder?.hingeColor ?? hingeColorList[0]
      );
    }
  };

  useEffect(() => {
    if (config.doorBuilder.showClosetHardwareColor) {
      setClosetHardwareColorOptionsLoading(true);
      dispatch(getClosetHardwareColors(setClosetHardwareColorOptionsLoading));
    }
  }, []);

  useEffect(() => {
    onFormChange(methods);
  }, [methods.formState]);

  useEffect(() => {
    if (productLineId) {
      setDoorOverlayOptionsLoading(true);
      setDrawerBoxOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_DOOR_OVERLAYS,
          setDoorOverlayOptionsLoading
        )
      );
      dispatch(
        getDoorBuilderOptions(
          { productLineId },
          orderStylesDoorBuilderActions.GET_DRAWER_BOX,
          setDrawerBoxOptionsLoading
        )
      );
      setHardwareLoading(true);
      dispatch(getHardware({ productLineId }, setHardwareLoading));
      dispatch(getHardwareQuantities({ styleId: styleId!, orderId }));
    }
  }, [productLineId]);

  useEffect(() => {
    if (
      !storedDoorBuilder &&
      doorOverlayOptions &&
      doorOverlayOptions?.length
    ) {
      methods.setValue('doorOverlay', doorOverlayOptions[0]);
    }
  }, [doorOverlayOptions]);

  useEffect(() => {
    if (!storedDoorBuilder && frameStyleOptions && frameStyleOptions?.length) {
      methods.setValue('frameStyle', frameStyleOptions[0]);
    }
  }, [frameStyleOptions]);

  useEffect(() => {
    if (doorOverlayWatched && materialGroup && materialColor) {
      setHingeTypeOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          { doorOverlayId: doorOverlayWatched.id, productLineId },
          orderStylesDoorBuilderActions.GET_FACE_FRAMES,
          () => null
        )
      );

      setDoorStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
            doorOverlayId: doorOverlayWatched.id,
            materialColorId: materialColor.value,
            materialGroupId: materialGroup.value,
          },
          orderStylesDoorBuilderActions.GET_PRODUCT_LINE_DOOR_STYLES,
          setDoorStyleOptionsLoading
        )
      );
    }
  }, [doorOverlayWatched, materialGroup, materialColor]);

  useEffect(() => {
    if (doorOverlayWatched && faceFrameOptions?.length) {
      setFrameStyleOptionsLoading(true);
      dispatch(
        getDoorBuilderOptions(
          {
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameOptions[0].id,
            productLineId,
          },
          orderStylesDoorBuilderActions.GET_FRAME_STYLES,
          setFrameStyleOptionsLoading
        )
      );
    }
  }, [faceFrameOptions, doorOverlayWatched]);

  useEffect(() => {
    if (!storedDoorBuilder && hardwareOptions?.length) {
      const doNotDrillOption = hardwareOptions.find(
        (hardwareOption) =>
          hardwareOption.label === DoorBuilderOptionsEnum.DO_NOT_DRILL
      );

      if (doNotDrillOption) {
        methods.setValue('doorHardware', doNotDrillOption);
        methods.setValue('drawerHardware', doNotDrillOption);
      }
    }
  }, [hardwareOptions]);

  useEffect(() => {
    if (materialGroup && materialColor) {
      setToeSpaceTypeOptionsLoading(true);
      dispatch(getToeSpaceTypes(() => setToeSpaceTypeOptionsLoading(false)));
      setDoorEdgeBandOptionsLoading(true);
      dispatch(
        getDoorEdgeBands(
          {
            materialColorId: materialColor.value,
            materialGroupId: materialGroup.value,
          },
          () => setDoorEdgeBandOptionsLoading(false)
        )
      );
    }
  }, [materialGroup, materialColor]);

  useEffect(() => {
    if (
      doorEdgeBandOptions &&
      !methods.formState.isDirty &&
      selectedProductLine?.name === ProductLineEnums.PRODUCT_LINE_REVOLAE
    ) {
      const matchOption = doorEdgeBandOptions.find(
        (doorEdgeBandOption) =>
          doorEdgeBandOption.label === DooredgeBandOptionsEnum.MATCH
      );

      if (!matchOption) return;

      methods.setValue('doorEdgeBand', matchOption);
    }
  }, [doorEdgeBandOptions]);

  useEffect(() => {
    if (
      !storedDoorBuilder &&
      doorStyleWallOptions &&
      doorStyleWallOptions?.length
    ) {
      methods.setValue('doorStyleWall', doorStyleWallOptions[0], {
        shouldDirty: true,
      });
    }
  }, [doorStyleWallOptions]);

  useEffect(() => {
    if (doorStyleWallWatched) {
      setDrawerStyleOptionsLoading(true);
      dispatch(
        getMaterialDrawers(
          {
            doorOverlayId: doorOverlayWatched.id,
            doorStyleId: doorStyleWallWatched.value,
            materialGroupId: materialGroup!.value,
            grainDirectionId: grainDirection ? +grainDirection.value : null,
            productLineId: productLineId!,
          },
          () => setDrawerStyleOptionsLoading(false)
        )
      );

      if (!doorStyleBaseWatched) {
        methods.setValue('doorStyleBase', doorStyleWallWatched);
      }
    }
  }, [doorStyleWallWatched]);

  useEffect(() => {
    if (materialDrawerOptions && materialDrawerOptions?.length) {
      methods.setValue('materialDrawer', materialDrawerOptions[0]);
    }
  }, [materialDrawerOptions]);

  useEffect(() => {
    if (doorStyleWallWatched && faceFrameOptions && doorOverlayWatched) {
      dispatch(
        getDoorBuilderOptions(
          {
            productLineId,
            doorOverlayId: doorOverlayWatched.id,
            faceFrameId: faceFrameOptions[0].id,
            doorStyleId: doorStyleWallWatched.value,
          },
          orderStylesDoorBuilderActions.GET_HINGE_TYPE,
          setHingeTypeOptionsLoading
        )
      );
    }
  }, [doorStyleWallWatched, faceFrameOptions, doorOverlayWatched]);

  // populate upcharge for door hardware
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      (doorHardwareWatched?.upcharge ?? 0) >= 0
    ) {
      UtilService.withDecimal(
        'doorHardwareAdd',
        doorHardwareWatched.upcharge!.toString(),
        methods.setValue
      );
    }
  }, [doorHardwareWatched]);

  // populate upcharge for drawer hardware
  useEffect(() => {
    if (
      methods.formState.isDirty &&
      (drawerHardwareWatched?.upcharge ?? 0) >= 0
    ) {
      UtilService.withDecimal(
        'drawerHardwareAdd',
        drawerHardwareWatched.upcharge!.toString(),
        methods.setValue
      );
    }
  }, [drawerHardwareWatched]);

  useEffect(() => {
    populateQuantityFields();
  }, [hardwareQuantities]);

  // preselect if hinge type is default
  useEffect(() => {
    if (hingeTypeOptions) {
      setDefaultHingeType();
    }
  }, [hingeTypeOptions, doorOverlayWatched]);

  // preselect if hinge color is default
  useEffect(() => {
    if (hingeColorOptions && hingeTypeWatched) {
      setDefaultHingeColor();
    }
  }, [hingeColorOptions, hingeTypeWatched]);

  // set default drawer box if exists
  useEffect(() => {
    if (!methods.formState.isDirty && drawerBoxOptions && !storedDoorBuilder) {
      const defautlDrawerBox = drawerBoxOptions.find(
        (drawerBox) => drawerBox.isDefault
      );

      if (defautlDrawerBox) {
        methods.setValue('drawerBox', defautlDrawerBox);
      }
    }
  }, [drawerBoxOptions]);

  const doorOverlayRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.doorOverlay,
    ref: doorOverlayRef,
    fieldName: 'doorOverlay',
  });

  const drawerBoxRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.drawerBox,
    ref: drawerBoxRef,
    fieldName: 'drawerBox',
  });

  useAutoSetSingleOptionList(methods, {
    closetHardwareColor: config.doorBuilder.showClosetHardwareColor
      ? closetHardwareColorOptions
      : undefined,
  });

  useUndefinedOptionAsFirst();

  return (
    <>
      <Spacer h="49px" />
      <H2>Door Configurations</H2>
      <Spacer h="32px" />

      <FormProvider {...methods}>
        <Form>
          <Wrapper flex>
            <Wrapper minWidth={450}>
              <H3>Door style</H3>
              <Spacer h="24px" />

              <StyledLabel>
                <Wrapper flex middle>
                  Door Overlay
                  <Spacer w="15px" />
                  {doorOverlayOptionsLoading && <Loader size={16} noSpacing />}
                </Wrapper>
              </StyledLabel>
              <Spacer h="18px" />

              <ContainerMedium>
                <Wrapper flex middle ref={doorOverlayRef}>
                  {!doorOverlayOptions?.length && (
                    <P color={nevada}>
                      Door overlay options are being loaded...
                    </P>
                  )}

                  <Controller
                    control={methods.control}
                    name="doorOverlay"
                    rules={{ required: true }}
                    render={() => (
                      <>
                        {doorOverlayOptions?.map((doorOverlayOption) => (
                          <StyledRadioButton
                            aria-invalid={
                              methods.formState.errors.doorOverlay
                                ? 'true'
                                : 'false'
                            }
                            key={doorOverlayOption.id}
                            checked={
                              doorOverlayOption.id === doorOverlayWatched?.id
                            }
                            name="doorOverlay"
                            id={`doorOverlay--${doorOverlayOption.id}`}
                            onChange={() => {
                              methods.setValue(
                                'doorOverlay',
                                doorOverlayOption,
                                {
                                  shouldDirty: true,
                                }
                              );
                            }}
                            title={UtilService.toCapitalize(
                              doorOverlayOption.name!
                            )}
                            disabled
                          />
                        ))}
                      </>
                    )}
                  />
                </Wrapper>

                <FormError
                  label="Door overlay"
                  error={methods.formState.errors.doorOverlay as FieldError}
                  validationSchema={{ required: true }}
                />

                <Spacer h="30px" />

                <StyledLabel>
                  <Wrapper flex middle>
                    Frame Style
                    <Spacer w="10px" />
                    {frameStyleOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="6px" />

                <Controller
                  control={methods.control}
                  name="frameStyle"
                  rules={{ required: true }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Select
                      onChange={(val: SelectOptionProps) => onChange(val)}
                      value={value}
                      ref={ref}
                      aria-invalid={
                        methods.formState.errors.frameStyle ? 'true' : 'false'
                      }
                      isDisabled
                      options={frameStyleOptions}
                    />
                  )}
                />

                <FormError
                  label="Frame Style"
                  error={methods.formState.errors.frameStyle as FieldError}
                  validationSchema={{ required: true }}
                />
              </ContainerMedium>

              {config.doorBuilder.showInteriorNotes && (
                <>
                  <Spacer h="48px" />

                  <ContainerMedium>
                    <Wrapper flexGrow>
                      <StyledLabel>Interior Notes</StyledLabel>

                      <Spacer h="8px" />

                      <Textarea
                        placeholder="Interior note..."
                        aria-invalid={
                          methods.formState.errors.interiorNotes
                            ? 'true'
                            : 'false'
                        }
                        {...methods.register('interiorNotes', noteValidation())}
                        readOnly={!canEdit}
                      />

                      <FormError
                        label="Door Notes"
                        error={methods.formState.errors.interiorNotes}
                        validationSchema={noteValidation()}
                      />
                    </Wrapper>
                  </ContainerMedium>
                </>
              )}
            </Wrapper>

            <Wrapper maxWidth={450} flexGrow>
              <OrderStyleNote />
            </Wrapper>
          </Wrapper>

          <Spacer h="48px" />

          <Divider />

          <Spacer h="48px" />

          <Wrapper flex>
            <Wrapper>
              <Wrapper flex>
                <Spacer w="88px" />
                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Door Style
                      <Spacer w="15px" />
                      {doorStyleOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <InputWithPrefix content="Wall" width="88px">
                    <Controller
                      control={methods.control}
                      name="doorStyleWall"
                      rules={{ required: true }}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          value={value}
                          ref={ref}
                          options={doorStyleWallOptions}
                          isDisabled={!canEdit}
                          aria-invalid={
                            methods.formState.errors.doorStyleWall
                              ? 'true'
                              : 'false'
                          }
                        />
                      )}
                    />
                  </InputWithPrefix>
                  <Spacer h="6px" />
                  <InputWithPrefix content="Base" width="88px">
                    <Controller
                      control={methods.control}
                      name="doorStyleBase"
                      rules={{ required: true }}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          onChange={(val: SelectOptionProps) => onChange(val)}
                          value={value}
                          isDisabled={!canEdit}
                          ref={ref}
                          aria-invalid={
                            methods.formState.errors.doorStyleBase
                              ? 'true'
                              : 'false'
                          }
                          options={doorStyleBaseOptions}
                        />
                      )}
                    />
                  </InputWithPrefix>

                  <Spacer h="16px" />

                  {doorStyleWallWatched?.imageUrl && (
                    <ImgModal
                      nested
                      width={162}
                      height={162}
                      alt="Door Style Thumbnail"
                      src={doorStyleWallWatched.imageUrl}
                    />
                  )}
                </ContainerSmall>
                <Spacer w="16px" />
                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Door Edge Band
                      <Spacer w="15px" />
                      {doorEdgeBandOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="doorEdgeBand"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        value={value}
                        ref={ref}
                        options={doorEdgeBandOptions}
                        aria-invalid={
                          methods.formState.errors.doorEdgeBand
                            ? 'true'
                            : 'false'
                        }
                        isDisabled={!doorEdgeBandOptions?.length || !canEdit}
                      />
                    )}
                  />
                  <Spacer h="16px" />
                  {doorEdgeBandWatched?.imageUrl && (
                    <ImgModal
                      src={doorEdgeBandWatched.imageUrl}
                      alt="Door Edge Thumbnail"
                      height={162}
                      nested
                      width={162}
                    />
                  )}
                </ContainerSmall>
              </Wrapper>
              <Spacer h="32px" />

              <Wrapper flex>
                <Spacer w="88px" />
                <Wrapper flexGrow>
                  <ContainerMedium>
                    <StyledLabel>
                      <Wrapper flex middle>
                        Drawer Style
                        <Spacer w="15px" />
                        {materialDrawerOptionsLoading && (
                          <Loader size={16} noSpacing />
                        )}
                      </Wrapper>
                    </StyledLabel>
                    <Spacer h="6px" />
                    <InputWithPrefix content="Drawer" width="88px">
                      <Controller
                        control={methods.control}
                        rules={{ required: true }}
                        name="materialDrawer"
                        render={({ field: { onChange, ref, value } }) => (
                          <Select
                            onChange={(val: SelectOptionProps) => onChange(val)}
                            value={value}
                            ref={ref}
                            aria-invalid={
                              methods.formState.errors.materialDrawer
                                ? 'true'
                                : 'false'
                            }
                            options={materialDrawerOptions}
                            isDisabled
                          />
                        )}
                      />
                    </InputWithPrefix>

                    <Spacer h="16px" />

                    {materialDrawerWatched?.imageUrl && (
                      <ImgModal
                        src={materialDrawerWatched.imageUrl}
                        alt="Drawer Style Thumbnail"
                        height={162}
                        nested
                        width={162}
                      />
                    )}
                  </ContainerMedium>
                  <Spacer h="32px" />
                  <ContainerMedium flex>
                    <Wrapper flexGrow>
                      <StyledLabel>Door Notes</StyledLabel>

                      <Spacer h="8px" />

                      <Textarea
                        placeholder="Door note..."
                        aria-invalid={
                          methods.formState.errors.doorNotes ? 'true' : 'false'
                        }
                        {...methods.register('doorNotes', noteValidation())}
                        readOnly={!canEdit}
                      />

                      <FormError
                        label="Door Notes"
                        error={methods.formState.errors.doorNotes}
                        validationSchema={noteValidation()}
                      />
                    </Wrapper>
                  </ContainerMedium>
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Spacer w="120px" />
            <Wrapper>
              <DoorBuilderUpcharges flexGrow maxWidth={512}>
                <DoorUpcharges />
              </DoorBuilderUpcharges>
              <Spacer h="38px" />

              <Wrapper ref={drawerBoxRef}>
                <StyledLabel>
                  <Wrapper flex middle>
                    Drawer Box
                    <Spacer w="15px" />
                    {drawerBoxOptionsLoading && <Loader size={16} noSpacing />}
                  </Wrapper>
                </StyledLabel>

                <Spacer h="18px" />

                {drawerBoxOptionsLoading && (
                  <P>Drawer boxes are being loaded...</P>
                )}

                {!drawerBoxOptionsLoading && !drawerBoxOptions?.length && (
                  <P>There are no drawer boxes for selected product line.</P>
                )}

                <Wrapper flex middle>
                  <Controller
                    control={methods.control}
                    name="drawerBox"
                    rules={{ required: true }}
                    render={() => (
                      <>
                        {drawerBoxOptions?.map((drawerBoxOption) => (
                          <StyledRadioButton
                            checked={
                              drawerBoxOption.value === drawerBoxWatched?.value
                            }
                            key={drawerBoxOption.value}
                            onChange={() => {
                              methods.setValue('drawerBox', drawerBoxOption, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                            id={`drawerBox--${drawerBoxOption.value}`}
                            title={drawerBoxOption.label}
                            aria-invalid={
                              methods.formState.errors.drawerBox
                                ? 'true'
                                : 'false'
                            }
                            disabled={!canEdit}
                          />
                        ))}
                      </>
                    )}
                  />

                  <FormError
                    label="Drawer Box"
                    error={methods.formState.errors.drawerBox as FieldError}
                    validationSchema={{ required: true }}
                  />
                </Wrapper>
              </Wrapper>
              <Spacer h="38px" />
              <Wrapper>
                <Spacer w="30px" />
                <ContainerSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Toe Space Type
                      <Spacer w="15px" />
                      {toeSpaceTypeOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Controller
                    control={methods.control}
                    name="toeSpaceType"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        value={value}
                        ref={ref}
                        aria-invalid={
                          methods.formState.errors.toeSpaceType
                            ? 'true'
                            : 'false'
                        }
                        options={toeSpaceTypeOptions}
                        isDisabled={!toeSpaceTypeOptions?.length || !canEdit}
                      />
                    )}
                  />
                  <FormError
                    label="Toe Space Type"
                    error={methods.formState.errors.toeSpaceType as FieldError}
                    validationSchema={{
                      required: true,
                    }}
                  />
                  <Spacer h="16px" />
                  {toeSpaceTypeWatched?.imageUrl && (
                    <ImgModal
                      src={toeSpaceTypeWatched.imageUrl}
                      alt="Toe Space Type Thumbnail"
                      height={162}
                      nested
                      width={162}
                    />
                  )}
                </ContainerSmall>
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Spacer h="16px" />
          <Divider />
          <Spacer h="26px" />

          <H3>Hardware</H3>
          <Spacer h="24px" />
          <Wrapper flex between>
            <Wrapper maxWidth={380} flexGrow>
              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Hinge Type
                      <Spacer w="15px" />
                      {hingeTypeOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Controller
                    control={methods.control}
                    name="hingeType"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        value={value}
                        ref={ref}
                        aria-invalid={
                          methods.formState.errors.hingeType ? 'true' : 'false'
                        }
                        options={hingeTypeOptions}
                        isDisabled={!hingeTypeOptions?.length || !canEdit}
                      />
                    )}
                  />
                  <FormError
                    label="Hinge Type"
                    error={methods.formState.errors.hingeType as FieldError}
                    validationSchema={{ required: true }}
                  />
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('hingeTypeQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'hingeTypeAdd',
                      onlyDigitValidation({}, 'Hinge Type Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'hingeTypeAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.hingeTypeAdd ? 'true' : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Hinge Type Add"
                    error={methods.formState.errors.hingeTypeAdd as FieldError}
                    validationSchema={onlyDigitValidation({}, 'Hinge Type Add')}
                  />
                </ContainerXSmall>
              </Wrapper>

              <Spacer h="16px" />

              <Wrapper flex>
                {hingeTypeWatched && hingeTypeWatched.imageUrl && (
                  <ImgModal
                    alt="Hinge Type Thumbnail"
                    height={162}
                    nested
                    src={hingeTypeWatched.imageUrl}
                    width={162}
                  />
                )}
              </Wrapper>
              <Spacer h="16px" />

              <StyledLabel>Hinge Color</StyledLabel>

              <Spacer h="2px" />

              <StyledLabel bold invalid={!!methods.formState.errors.hingeColor}>
                {hingeColorWatched ? hingeColorWatched.label : 'N/A'}
              </StyledLabel>

              <Spacer h="16px" />

              <Wrapper flex middle flexWrap>
                {!hingeTypeWatched && (
                  <P color={nevada}>
                    Please select hinge type to get hinge color options.
                  </P>
                )}

                {hingeTypeWatched &&
                  !hingeColorOptions?.[hingeTypeWatched.value]?.length && (
                    <P color={nevada} fontSize={14}>
                      There are no Hinge Colors for selected Hinge Type.
                    </P>
                  )}

                {hingeTypeWatched &&
                  hingeColorOptions &&
                  hingeColorOptions[hingeTypeWatched.value]?.map(
                    (hingeColorOption) => (
                      <Controller
                        control={methods.control}
                        rules={{ required: true }}
                        key={hingeColorOption.value}
                        name="hingeColor"
                        render={({ field: { ref } }) => (
                          <RadioColor
                            type="button"
                            id={hingeColorOption.value}
                            name={hingeColorOption.label}
                            aria-invalid={
                              methods.formState.errors.hingeColor
                                ? 'true'
                                : 'false'
                            }
                            ref={ref}
                            className={
                              hingeColorWatched?.value ===
                              hingeColorOption.value
                                ? 'active'
                                : ''
                            }
                            onClick={() =>
                              methods.setValue('hingeColor', hingeColorOption, {
                                shouldValidate: true,
                                shouldDirty: true,
                              })
                            }
                            isDisabled={!canEdit}
                          >
                            {/* if has image */}
                            {hingeColorOption.imageUrl && (
                              <ImgModal
                                height={32}
                                hover
                                position="right center"
                                key={hingeColorOption.value}
                                label={hingeColorOption.label}
                                nested
                                src={hingeColorOption.imageUrl}
                                width={32}
                                alt={`Hinge Color - ${hingeColorOption.label}`}
                              />
                            )}

                            {/* if has not image */}
                            {!hingeColorOption.imageUrl && (
                              <SVG
                                icon={<PlatoLogoIcon />}
                                color={silverSandTwo}
                              />
                            )}
                          </RadioColor>
                        )}
                      />
                    )
                  )}
              </Wrapper>

              <FormError
                label="Hinge color"
                error={methods.formState.errors.hingeColor as FieldError}
                validationSchema={{ required: true }}
              />
            </Wrapper>

            <Wrapper maxWidth={400} flexGrow>
              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Door Hardware
                      <Spacer w="15px" />
                      {hardwareLoading && <Loader size={16} noSpacing />}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Controller
                    control={methods.control}
                    name="doorHardware"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        value={value}
                        ref={ref}
                        aria-invalid={
                          methods.formState.errors.doorHardware
                            ? 'true'
                            : 'false'
                        }
                        options={hardwareOptions}
                        isDisabled={!hardwareOptions?.length || !canEdit}
                      />
                    )}
                  />
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('doorHardwareQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'doorHardwareAdd',
                      onlyDigitValidation({}, 'Door Hardware Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'doorHardwareAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.doorHardwareAdd
                        ? 'true'
                        : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Door Hardware Add"
                    error={
                      methods.formState.errors.doorHardwareAdd as FieldError
                    }
                    validationSchema={onlyDigitValidation(
                      {},
                      'Door Hardware Add'
                    )}
                  />
                </ContainerXSmall>
              </Wrapper>

              <FormError
                label="Door Hardware"
                error={methods.formState.errors.doorHardware as FieldError}
                validationSchema={{ required: true }}
              />

              <Spacer h="32px" />

              <Wrapper flex>
                <Wrapper flexGrow>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Drawer Hardware
                      <Spacer w="15px" />
                      {hardwareLoading && <Loader size={16} noSpacing />}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="drawerHardware"
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        onChange={(val: SelectOptionProps) => onChange(val)}
                        value={value}
                        ref={ref}
                        aria-invalid={
                          methods.formState.errors.drawerHardware
                            ? 'true'
                            : 'false'
                        }
                        options={hardwareOptions}
                        isDisabled={!hardwareOptions?.length || !canEdit}
                      />
                    )}
                  />

                  <FormError
                    label="Drawer Hardware"
                    error={
                      methods.formState.errors.drawerHardware as FieldError
                    }
                    validationSchema={{ required: true }}
                  />
                </Wrapper>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Qty
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input {...methods.register('drawerHardwareQty')} readOnly />
                </ContainerXSmall>
                <Spacer w="24px" />
                <ContainerXSmall>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Add $
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="6px" />
                  <Input
                    {...methods.register(
                      'drawerHardwareAdd',
                      onlyDigitValidation({}, 'Drawer Hardware Add')
                    )}
                    onBlur={(e) =>
                      UtilService.withDecimal(
                        'drawerHardwareAdd',
                        e.target.value || '0',
                        methods.setValue
                      )
                    }
                    aria-invalid={
                      methods.formState.errors.drawerHardwareAdd
                        ? 'true'
                        : 'false'
                    }
                    disabled={!canEdit}
                  />
                  <FormError
                    label="Drawer Hardware Add"
                    error={
                      methods.formState.errors.drawerHardwareAdd as FieldError
                    }
                    validationSchema={onlyDigitValidation(
                      {},
                      'Drawer Hardware Add'
                    )}
                  />
                </ContainerXSmall>
              </Wrapper>

              <Spacer h="30px" />

              {config.doorBuilder.showClosetHardwareColor && (
                <>
                  <StyledLabel>
                    <Wrapper flex middle>
                      Closet Hardware Color
                      <Spacer w="15px" />
                      {closetHardwareColorOptionsLoading && (
                        <Loader size={16} noSpacing />
                      )}
                    </Wrapper>
                  </StyledLabel>
                  <Spacer h="2px" />

                  <StyledLabel bold>
                    {closetHardwareColorWatched
                      ? closetHardwareColorWatched.label
                      : 'N/A'}
                  </StyledLabel>

                  <Spacer h="16px" />

                  <ClosetHardwareColor />

                  <Spacer h="24px" />
                </>
              )}

              <Wrapper>
                <StyledLabel>Hardware Notes</StyledLabel>

                <Spacer h="10px" />

                <Textarea
                  placeholder="Hardware note..."
                  aria-invalid={
                    methods.formState.errors.hardwareNotes ? 'true' : 'false'
                  }
                  {...methods.register('hardwareNotes', noteValidation())}
                  readOnly={!canEdit}
                />

                <FormError
                  label="Hardware Notes"
                  error={methods.formState.errors.hardwareNotes}
                  validationSchema={noteValidation()}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>

          <OrderFooter>
            <Wrapper flex middle between>
              <ButtonSecondary
                type="button"
                onClick={
                  canSubmit
                    ? methods.handleSubmit((data) =>
                        onSubmit({
                          data,
                          goBack: true,
                          getStyleAfterUpdate: true,
                        })
                      )
                    : () => redirect(true)
                }
                disabled={saveDoorBuilderLoadingOnBack}
              >
                {UtilService.styleNavigationActionsLables(
                  'Back',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!saveDoorBuilderLoadingOnBack}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>
              <ButtonPrimary
                data-test="button-StylesDoorBuilderSubmit"
                type="button"
                onClick={
                  canSubmit || goNextAndSaveForFirstTime()
                    ? methods.handleSubmit((data) =>
                        onSubmit({
                          data,
                          goBack: false,
                          getStyleAfterUpdate: true,
                        })
                      )
                    : () => redirect(false)
                }
                disabled={saveDoorBuilderLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Next',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!saveDoorBuilderLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </Form>
      </FormProvider>
      <Spacer h="40px" />
      {PopupModal}
    </>
  );
};
