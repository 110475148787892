import { Location } from 'history';
import { RootState } from 'store';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { IChannel } from 'curtain/interfaces/IChannel';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';

import {
  setActiveChannel,
  setActiveLineItemID,
  setActiveStyleID,
  setActiveTopic,
  setBackToChanel,
  setCurtainTab,
} from 'curtain/store/curtainActions';

import {
  INotificationDetals,
  NotificationVariationEnums,
} from 'overview/interface/Notification';

import { useAppDispatch } from './useAppDispatch';

export const useApplyNotifLocationState = () => {
  const location: Location<INotificationDetals> = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const styleChannels = useSelector(
    (state: RootState) => state.curtainReducer.styleChannels
  );

  const lineItemChannels = useSelector(
    (state: RootState) => state.curtainReducer.lineItemChannels
  );

  // const activeChannel = useSelector(
  //   (state: RootState) => state.curtainReducer.activeChannel
  // );

  const otherTopics = useSelector(
    (state: RootState) => state.curtainReducer.otherTopics
  );

  const resetLocationState = () => {
    if (location.state.redirectedFromNotif) {
      // return;
      history.replace(location.pathname, { dontClearCurtain: true });
    }
  };

  const selectColabEntity = (channel: IChannel) => {
    dispatch(setActiveChannel(channel));
    dispatch(setBackToChanel(channel));

    dispatch(
      location.state.type === 1
        ? setActiveStyleID(channel?.id)
        : setActiveLineItemID(channel?.id)
    );

    resetLocationState();
  };

  const selectColabTopic = (topic: ICurtainTopic) => {
    dispatch(setActiveTopic(topic));
    resetLocationState();
  };

  const apply = () => {
    switch (location.state.variation) {
      case NotificationVariationEnums.COMMENT_CREATED:
        // dispatch stuff for collaboration location state
        dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
        break;
      case NotificationVariationEnums.COLLABORATOR_ADDED:
      case NotificationVariationEnums.ASSIGNEE_ADDED:
      case NotificationVariationEnums.ORDER_CANCELLATION_REQUEST:
        // nothing - user has been already redirected to basic info page of the order
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.state) {
      apply();
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && styleChannels.length) {
      const entity = styleChannels.find(
        (styleChannel: IChannel) =>
          styleChannel.entityId === location.state.entityId
      );

      if (entity) {
        selectColabEntity(entity);
      }
    }
  }, [styleChannels]);

  useEffect(() => {
    if (location.state && lineItemChannels.length) {
      const entity = lineItemChannels.find(
        (lineItemChannel: IChannel) =>
          lineItemChannel.entityId === location.state.entityId
      );

      if (entity) {
        selectColabEntity(entity);
      }
    }
  }, [lineItemChannels]);

  useEffect(() => {
    if (location.state && otherTopics) {
      const foundTopic = otherTopics.find(
        (topic: ICurtainTopic) => topic.id === location.state.topicId
      );

      if (foundTopic) {
        selectColabTopic(foundTopic);
      }
    }
  }, [otherTopics]);

  return undefined;
};
