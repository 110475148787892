import { useState, useEffect } from 'react';
import { useBrowserStorage } from './useBrowserStorage';

export const useIsUserLoggedIn = () => {
  const { value } = useBrowserStorage({
    key: 'token',
    storageType: 'localStorage',
  });

  const [loggedIn, setLoggedIn] = useState(!!value);

  useEffect(() => {
    setLoggedIn(!!value);
  }, [value]);

  return loggedIn;
};
