import { Popup } from 'order/popups/interface/Popup';
import { PopupEntityType } from 'order/popups/enums/PopupEntityTypeEnum';

export enum PopupActiontTypes {
  GET_POPUPS = 'GET_POPUPS',
  SET_POPUPS = 'SET_POPUPS',
}

export interface GetPopupsRequest {
  entityTypes: PopupEntityType[];
}

export const getPopups = (payload?: GetPopupsRequest) => ({
  type: PopupActiontTypes.GET_POPUPS,
  payload,
});

export const setPopups = (data: Popup[] | null) => ({
  type: PopupActiontTypes.SET_POPUPS,
  payload: data,
});
