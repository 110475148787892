import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { unlockOrderForEdit } from 'order/store/orderActions';

import { ButtonPrimary } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderEditSessionTrack } from 'shared/hooks/useOrderEditSessionTrack';
import { Wrapper } from 'shared/components/Wrapper';

import { OrderPage } from './OrderPage';

const OrderTickContainerContainer = styled.div``;

const OrderExtendModalContent = styled.div``;

const OrderTickContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [mounted, setMounted] = useState(false);

  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const loggedInUserId = useSelector(
    (state: RootState) => state.authReducer.user?.id
  );

  const orderLockInfo = useSelector(
    (state: RootState) => state.orderReducer.lockInfo
  );

  const {
    sessionTrackSubscribe,
    sessionTrackUnsubscribe,
    resetSession,
    showDialog,
    kickOut,
    subscribed,
  } = useOrderEditSessionTrack({
    interval: 1000,
    showKickDialogAfter: 3000,
    kickAfter: 600,
  });

  // set mountd and unmounted
  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  // subscribe if edit mode
  useEffect(() => {
    if (mounted) {
      if (
        !subscribed &&
        editMode &&
        orderLockInfo &&
        orderLockInfo.lockedByUserId === loggedInUserId
      ) {
        sessionTrackSubscribe();
      }

      if (subscribed && orderLockInfo === null) {
        sessionTrackUnsubscribe();
      }
    }
  }, [subscribed, orderLockInfo]);

  useEffect(() => {
    if (mounted) {
      if (kickOut) {
        history.push('/overview/dashboard', {
          ...location.state,
          kickedOut: true,
        });

        if (orderLockInfo) {
          dispatch(
            unlockOrderForEdit({ orderId, orderLockId: orderLockInfo.id })
          );
        }
      }
    }
  }, [kickOut]);

  return (
    <OrderTickContainerContainer>
      <ModalWrapper
        closeOnDocumentClick={false}
        lockScroll
        modal
        closeOnEscape={false}
        open={showDialog}
      >
        <Modal title="Extend order edit session?" withoutFooter={1}>
          <OrderExtendModalContent>
            <P>
              Order session is about to expire. Do you want to continue editing
              the order?
            </P>

            <Spacer h="50px" />

            <Wrapper flex justifyEnd>
              <ButtonPrimary onClick={resetSession}>
                Continue Editing
              </ButtonPrimary>
            </Wrapper>
          </OrderExtendModalContent>
        </Modal>
      </ModalWrapper>

      <OrderPage />
    </OrderTickContainerContainer>
  );
};

export default OrderTickContainer;
