import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useIsUserLoggedIn } from 'shared/hooks/useIsUserLoggedIn';

export function PrivateRoute(props: RouteProps) {
  const isUserLoggedIn = useIsUserLoggedIn();

  if (!isUserLoggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <Route {...props} />;
}
