import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools, EnhancerOptions } from 'redux-devtools-extension';

import rootReducer from './reducers';
import { appSagas } from './sagas';

const sagaMiddleware = createSagaMiddleware();

// needs to be done because of typescript.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
} as EnhancerOptions);

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(appSagas);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
