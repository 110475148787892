import { Fragment, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useDecision } from '@optimizely/react-sdk';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  overrideUpcharges,
  setUpchargeDifferencesModalOpened,
} from 'order/store/orderActions';

import Loader from 'shared/components/Loader';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { H2, P } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Spacer } from 'shared/components/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/Table';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Checkbox } from 'shared/components/Checkbox';
import { useUpchargeAvailableOrders } from 'shared/hooks/useUpchargeAvailableOrders';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';

const UpchargeDifferencesModal = () => {
  const dispatch = useAppDispatch();

  const [upcharge_differences] = useDecision('upcharge_differences');

  const [
    checkIsOrderInLocalStorage,
    removeOrderInLocalStorage,
    saveOrderInLocalStorage,
  ] = useUpchargeAvailableOrders();

  const { orderId } = useParams<OrderPageParams>();

  const [overrideUpchargesLoading, setOverrideUpchargesLoading] = useState(
    checkIsOrderInLocalStorage()
  );

  const upchargeDifferences = useSelector(
    (state: RootState) => state.orderReducer.upchargeDifferences
  );

  const upchargeDifferencesModalOpened = useSelector(
    (state: RootState) => state.orderReducer.upchargeDifferencesModalOpened
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const [isAsap] = useOrderFlow('asap');

  const [isDuplicate] = useOrderFlow('duplicate');

  const isAsapOrDuplicate = isAsap || isDuplicate;

  const [futureMsgShowChecked, setFutureMsgShowChecked] =
    useState<boolean>(false);

  const closeModal = (close: () => void) => {
    close();
    dispatch(setUpchargeDifferencesModalOpened({ opened: false }));
  };

  const onUpdateUpchargesClickHandler = (close: () => void) => {
    setOverrideUpchargesLoading(true);
    dispatch(
      overrideUpcharges(
        orderId,
        () => closeModal(close),
        setOverrideUpchargesLoading
      )
    );
  };

  const onChangeHandler = (checked: boolean) => {
    if (!checked) {
      removeOrderInLocalStorage();
    } else {
      saveOrderInLocalStorage();
    }
    setFutureMsgShowChecked(checked);
  };

  const isOrderInLocalStorage = useMemo(
    () => checkIsOrderInLocalStorage() && isUserCSR,
    [orderId, isUserCSR]
  );

  const onSubmit = () => {
    if (upchargeDifferencesModalOpened?.onSubmit)
      upchargeDifferencesModalOpened.onSubmit();
    dispatch(setUpchargeDifferencesModalOpened({ opened: false }));
  };

  return (
    <ModalWrapper
      open={
        !isAsapOrDuplicate &&
        upcharge_differences.enabled &&
        upchargeDifferencesModalOpened.opened &&
        !isOrderInLocalStorage
      }
      closeOnDocumentClick={false}
    >
      {(close: () => void) => (
        <Modal close={() => closeModal(close)} noHeader confirm>
          <H2 fontSize={24}>New Upcharges Available</H2>

          <Spacer h="20px" />

          <P fontSize={16} color={nevada}>
            This order contain some new upcharges, are you willing to update the
            order with these changes?
          </P>

          <Spacer h="32px" />

          <Table>
            <Thead>
              <Tr>
                <Th>Style</Th>
                <Th>Property</Th>
                <Th>New Value</Th>
                <Th>Old Value</Th>
              </Tr>
            </Thead>

            <Tbody>
              {upchargeDifferences?.map(
                ({
                  id,
                  number,
                  name,
                  finishColorUpchargeDifferenceResponse,
                  woodMaterialUpchargeDifferenceResponse,
                }) => (
                  <Fragment key={id}>
                    {woodMaterialUpchargeDifferenceResponse && (
                      <Tr>
                        <Td>
                          #{number} {name}
                        </Td>
                        <Td>
                          {woodMaterialUpchargeDifferenceResponse.fieldName}
                        </Td>
                        <Td>
                          {
                            woodMaterialUpchargeDifferenceResponse.latestUpcharge
                          }
                          %
                        </Td>
                        <Td>
                          {
                            woodMaterialUpchargeDifferenceResponse.currentUpcharge
                          }
                          %
                        </Td>
                      </Tr>
                    )}

                    {finishColorUpchargeDifferenceResponse && (
                      <Tr>
                        <Td>
                          #{number} {name}
                        </Td>
                        <Td>
                          {finishColorUpchargeDifferenceResponse.fieldName}
                        </Td>
                        <Td>
                          {finishColorUpchargeDifferenceResponse.latestUpcharge}
                          %
                        </Td>
                        <Td>
                          {
                            finishColorUpchargeDifferenceResponse.currentUpcharge
                          }
                          %
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                )
              )}
            </Tbody>
          </Table>

          <Spacer h="32px" />

          {isUserCSR && (
            <Checkbox
              checked={futureMsgShowChecked}
              onChange={(e) => onChangeHandler(e.target.checked)}
              title="Don’t show this in the future"
              id="futureMsgShow"
            />
          )}
          <Wrapper flex middle justifyEnd>
            <ButtonSecondary
              onClick={() =>
                upchargeDifferencesModalOpened.onSubmit
                  ? onSubmit()
                  : closeModal(close)
              }
            >
              {upchargeDifferencesModalOpened.onSubmit
                ? 'Continue to Submit'
                : 'Cancel'}
            </ButtonSecondary>

            <Spacer w="15px" />

            <ButtonPrimary
              onClick={() => onUpdateUpchargesClickHandler(close)}
              disabled={overrideUpchargesLoading}
            >
              Update
              <Loader
                hidden={!overrideUpchargesLoading}
                insideButton
                size={16}
                noSpacing
              />
            </ButtonPrimary>
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};

export default UpchargeDifferencesModal;
