import { searchCSRS } from 'order/components/OrderAssignees/store/orderAssigneesActions';
import { Order } from 'order/interfaces/Order';
import { getUserList } from 'overview/manageUsers/store/manageUsersActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { CSR } from 'shared/interface/CSR';
import { User } from 'shared/interface/User';
import { RootState } from 'store';

interface MentionItem {
  id: string;
  display: string;
}
export const useMentionInput = () => {
  const dispatch = useAppDispatch();

  const [selectedUsers, setSelectedUsers] = useState<MentionItem[]>([]);
  const [dealershipUsers, setDealershipUsers] = useState<MentionItem[]>([]);
  const [csrs, setCsrs] = useState<MentionItem[]>([]);

  const { dealershipId } = useSelector(
    (state: RootState) => state.orderReducer.order ?? ({} as Order)
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const loadUserList = (userList: User[]) => {
    const mentions: MentionItem[] = userList
      .filter((user) => user.enabled)
      .map(
        (user) =>
          ({
            id: user.id,
            display: `${user.firstName}${user.lastName}`,
          } as MentionItem)
      );

    setDealershipUsers([...dealershipUsers, ...mentions]);
  };

  const loadCSRs = (assignees: CSR[]) => {
    const assigneeList: MentionItem[] = assignees.map(
      (user) =>
        ({
          id: user.id,
          display: `${user.firstName}${user.lastName}`,
        } as MentionItem)
    );

    setCsrs([...csrs, ...assigneeList]);
  };

  useEffect(() => {
    if (dealershipId) {
      dispatch(getUserList(dealershipId, loadUserList, () => false));
    }

    if (isUserCSR) {
      dispatch(searchCSRS({ searchTerm: '' }, undefined, loadCSRs));
    }
  }, [dealershipId]);

  useEffect(() => {
    setSelectedUsers([...selectedUsers, ...csrs]);
  }, [csrs]);

  useEffect(() => {
    setSelectedUsers([...selectedUsers, ...dealershipUsers]);
  }, [dealershipUsers]);

  return selectedUsers;
};
