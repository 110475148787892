import { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { FormElement } from './FormElement';
import { Spacer } from './Layout';
import { RadioButton } from './RadioButton';
import { H4 } from './Typography';

const RadioButtonWithDescContainer = styled(FormElement)`
  display: flex;

  > div {
    flex: 1;
  }
`;

const FlowOptionText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
`;

const FlowOptionTextHeading = styled.div`
  color: ${({ theme }) => theme.mineShaftVariationTwo};
`;

const FlowOptionTextDescription = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  opacity: 0.5;
`;

interface RadioButtonWithDescProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  desc?: string;
}

const RadioButtonWithDesc = forwardRef<
  HTMLInputElement,
  RadioButtonWithDescProps
>(({ label, desc, ...rest }, ref) => {
  return (
    <RadioButtonWithDescContainer>
      <RadioButton ref={ref} {...rest}>
        <FlowOptionText>
          <FlowOptionTextHeading>
            <H4>{label}</H4>
          </FlowOptionTextHeading>
          {desc && (
            <>
              <Spacer h="8px" />
              <FlowOptionTextDescription>{desc}</FlowOptionTextDescription>
            </>
          )}
        </FlowOptionText>
      </RadioButton>
    </RadioButtonWithDescContainer>
  );
});

export default RadioButtonWithDesc;
