import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDecision } from '@optimizely/react-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';

import {
  OrderStatusEnums,
  OrderStylizationTypeEnums,
} from 'order/enums/orderEnums';

import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Container } from 'shared/components/Container';
import { Divider, Spacer } from 'shared/components/Layout';
import { ExpandableBox } from 'shared/components/ExpandableBox';
import { FormProvider, useForm } from 'react-hook-form';
import { H2, P, PMedium, SPAN } from 'shared/components/Typography';
import { Info } from 'shared/components/Info';
import { nevada } from 'shared/config/Colors';
import { PrintButton } from 'shared/components/PrintButton';
import { ReactComponent as DividerIcon } from 'assets/icons/divider.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useConnectionOpen } from 'shared/hooks/useConnectionOpen';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

import { ACKTestButtonEnum } from 'tests/enums/ACKTestEnums';
import { testId } from 'tests/utils';

import { getAckChangeLogDiff } from 'order/shared/ackchangelog/store/ackchangelogActions';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import { setOrderPriceReview } from 'order/store/orderActions';
import { ACKStatusEnum } from './enums/ACKStatusEnum';
import { ACKTypeEnum } from './enums/ACKTypeEnum';
import { CollectDrawingsStatusEnum } from './enums/CollectDrawingsStatusEnum';
import { Attachments } from './components/Attachments';
import { BasicInfo } from './components/BasicInfo';

import {
  changeCollectDrawingsStatus,
  clearACKReducer,
  confirmResultCollectDrawings,
  getACKAttachments,
  getCollectDrawingsStatus,
  getLatestACK,
  startCollectDrawings,
} from './store/ACKActions';

import { SpecialOrderApprovalStatusEnum } from './enums/SpecialOrderApprovalStatusEnum';

import { AcknowledgmentPrint } from './AcknowledgmentPrint';
import ACKTypeSheet1 from './components/AckTypeSheets/ACKTypeSheet1';
import ACKTypeSheet2 from './components/AckTypeSheets/ACKTypeSheet2';
import ACKTypeSheet3 from './components/AckTypeSheets/ACKTypeSheet3';
import ACKTypeSheet4 from './components/AckTypeSheets/ACKTypeSheet4';
import CoverSheet from './components/CoverSheet';
import CSRACKActions from './components/CSRACKActions';
import DealerACKActions from './components/DealerACKActions';
import DealerSORActions from './components/DealerSORActions';
import OrderReviewPricing from '../orderReviewPricing/OrderReviewPricing';
import OrderReviewPricingSalesMaterial from '../orderReviewPricing/OrderReviewPricingSalesMaterial';
import SpecialOrderApprovalSheet from './components/AckTypeSheets/SpecialOrderApprovalSheet';
import { CollectingDrawingsSignalRData } from './enums/CollectingDrawingsSignalRData';
import AcknowledgeExpirationDate from './components/AcknowledgeExpirationDate';
import QuoteSheet from './components/AckTypeSheets/QuoteSheet';

export interface AckConsentForm {
  consentGiven: boolean;
  consentGivenSpecialOrderApproval: boolean;
}

export const Acknowledgment = () => {
  const { orderId } = useParams<OrderPageParams>();

  const [collectDrawingsDecision] = useDecision('collect_drawings');

  const [isModalOpened, setIsModalOpened] = useState(false);

  const dispatch = useAppDispatch();

  const canSendACK = useHasPermissions(
    userPermissionsValues.ACKNOWLEDGEMENT_SEND
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const { order, priceReview } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const isCurrentUserViewer = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserViewer
  );

  const latestACK = useSelector(
    (state: RootState) => state.ACKReducer.latestACK
  );

  const ackAttachments = useSelector(
    (state: RootState) => state.ACKReducer.attachments
  );

  const collectDrawings = useSelector(
    (state: RootState) => state.ACKReducer.collectDrawings
  );

  const specialOrderApproval = useSelector(
    (state: RootState) => state.orderReducer.order?.specialOrderApproval
  );

  const collectDrawingsStatus = collectDrawings?.collectDrawingsStatus;

  const collectDrawingsHasStarted =
    collectDrawingsStatus === CollectDrawingsStatusEnum.Started;

  const [latestACKLoading, setLatestACKLoading] = useState(false);

  const methods = useForm<AckConsentForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      consentGiven: false,
      consentGivenSpecialOrderApproval: false,
    },
  });

  const onGetLatestACKFailed = () => {
    setLatestACKLoading(false);
  };

  const onCollectingDrawingsStatusFinished = (data: unknown) => {
    const collectDrawingStatusData = data as CollectingDrawingsSignalRData;

    dispatch(
      changeCollectDrawingsStatus(
        collectDrawingStatusData.collectDrawingsStatus
      )
    );
  };

  const shouldOpenConnectionForCollectDrawings = () => {
    return isUserDealerOrSalesRep !== undefined && collectDrawingsHasStarted;
  };

  const { connection } = useConnectionOpen({
    event: 'CollectDrawingsFinished',
    onReceiveData: onCollectingDrawingsStatusFinished,
    url: `collect-drawings?orderId=${orderId}`,
    shouldOpenConnection: shouldOpenConnectionForCollectDrawings()
      ? !isUserDealerOrSalesRep
      : false,
  });

  const onStartCollectDrawingsSuccessHandler = () => {
    toast.success('Collect drawings operation started successfully.');
    dispatch(changeCollectDrawingsStatus(CollectDrawingsStatusEnum.Started));
  };

  const onCollectDrawingsStartHandler = () => {
    dispatch(
      startCollectDrawings(
        {
          orderId,
        },
        onStartCollectDrawingsSuccessHandler
      )
    );

    setIsModalOpened(false);
  };

  const onConfirmResultCollectDrawingsSuccessHandler = (
    confirmStatus: CollectDrawingsStatusEnum
  ) => {
    toast.success('Collect drawings operation completed successfully.');
    dispatch(changeCollectDrawingsStatus(confirmStatus));
  };

  const onCollectDrawingsFailedCancelHandler = () => {
    dispatch(
      confirmResultCollectDrawings(
        {
          orderId,
        },
        () =>
          onConfirmResultCollectDrawingsSuccessHandler(
            CollectDrawingsStatusEnum.FailedConfirmed
          )
      )
    );
  };

  const onCollectDrawingsFailedTryAgainHandler = () => {
    dispatch(
      startCollectDrawings(
        {
          orderId,
        },
        onStartCollectDrawingsSuccessHandler
      )
    );
  };

  const onCollectDrawingsCompleteHandler = (close: () => void) => {
    dispatch(
      confirmResultCollectDrawings(
        {
          orderId,
        },
        () =>
          onConfirmResultCollectDrawingsSuccessHandler(
            CollectDrawingsStatusEnum.CompletedConfirmed
          )
      )
    );

    close();
  };

  const shouldRenderSORActions = () => {
    // 1. when special order approval exists and it is in the pending status - render sor actions
    return (
      !isCurrentUserViewer &&
      specialOrderApproval?.status === SpecialOrderApprovalStatusEnum.PENDING
    );
  };

  const shouldRenderDealerActions = () => {
    // 1. when latest ack status is pending
    // 2. cannot send ack (dealer) or user is CSR

    return (
      !latestACKLoading &&
      !isCurrentUserViewer &&
      latestACK?.status === ACKStatusEnum.PENDING &&
      (!canSendACK || isUserCSR)
    );
  };

  const shouldRenderCSRActions = () => {
    // 1. can send ack (CSR)
    // 2. latest ack is different then pending
    // 3. special order approval status is different then pending
    return (
      !latestACKLoading &&
      canSendACK &&
      latestACK?.status !== ACKStatusEnum.PENDING &&
      latestACK?.status !== ACKStatusEnum.CHANGES_REQUESTED &&
      (specialOrderApproval?.status as number) !==
        SpecialOrderApprovalStatusEnum.PENDING
    );
  };

  const shouldRenderAckSheet = (type: ACKTypeEnum) => {
    return (
      latestACK?.type === type &&
      latestACK?.status !== ACKStatusEnum.CHANGES_REQUESTED &&
      latestACK?.status !== ACKStatusEnum.FINISHED_REQUESTING_CHANGES
    );
  };

  // close drawing status check connection
  const closeConnection = async () => {
    if (connection) {
      await connection.stop();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLatestACKLoading(true);
    dispatch(getLatestACK(orderId, setLatestACKLoading, onGetLatestACKFailed));
    dispatch(getACKAttachments(orderId));
    dispatch(getAckChangeLogDiff(orderId));
    return () => {
      dispatch(clearACKReducer());
      dispatch(setOrderPriceReview(null));
    };
  }, []);

  useEffect(() => {
    if (isUserDealerOrSalesRep !== undefined && !isUserDealerOrSalesRep) {
      dispatch(getCollectDrawingsStatus(orderId));
    }
  }, [isUserDealerOrSalesRep]);

  // close connection when collect drawing status changes from started to anything else
  useEffect(() => {
    if (isUserDealerOrSalesRep !== undefined && !isUserDealerOrSalesRep) {
      dispatch(getCollectDrawingsStatus(orderId));
    }

    if (!collectDrawingsHasStarted) {
      closeConnection();
    }

    if (connection && collectDrawingsHasStarted) {
      connection.start();
    }
  }, [collectDrawingsStatus]);

  // set default consent checkbox state if ack is approved
  useEffect(() => {
    if (latestACK) {
      methods.reset({
        consentGiven: latestACK.status === ACKStatusEnum.APPROVED,
      });
    }
  }, [latestACK]);

  // set default consent checkbox state if special order approval is approved
  useEffect(() => {
    if (order?.specialOrderApproval) {
      methods.reset({
        consentGivenSpecialOrderApproval:
          order.specialOrderApproval.status ===
          SpecialOrderApprovalStatusEnum.APPROVED,
      });
    }
  }, [order?.specialOrderApproval]);

  useEffect(() => {
    if (isUserCSR) {
      window.addEventListener('unload', closeConnection);
    }

    return () => {
      if (isUserCSR) {
        window.removeEventListener('unload', closeConnection);
        closeConnection();
      }
    };
  }, [connection, isUserCSR]);

  const processedFiles = collectDrawings?.processedFiles ?? 0;
  const numberOfFiles = collectDrawings?.numberOfFiles ?? 0;
  const divisionResult = processedFiles / numberOfFiles;

  const percentageOfProcessedFiles = Number.isNaN(divisionResult)
    ? 100
    : (processedFiles / numberOfFiles) * 100;

  const noFilesFoundMessage = (
    <>
      <P>Collect drawings job completed</P>
      <br />
      <P>
        Success score: {processedFiles}/{numberOfFiles} (100%)
      </P>
      <br />
      <P>No file found that matches work order number {order?.serialNumber}.</P>
    </>
  );

  const filesFoundMessage = (
    <>
      <P>Collect drawings job completed</P>
      <br />
      <P>
        Success score: {processedFiles}/{numberOfFiles} (
        {percentageOfProcessedFiles.toFixed(2)}%)
      </P>
      <br />
      <P>
        Out of {numberOfFiles} files that <SPAN fontWeight={600}>match</SPAN>{' '}
        order number {order?.serialNumber}, {processedFiles} were successfully
        uploaded.
      </P>
    </>
  );

  useWindowScrollPosition(priceReview !== null);

  return (
    <>
      <AcknowledgmentPrint />

      {isUserCSR && (
        <ConfirmationModal
          cancel={() => setIsModalOpened(false)}
          confirm={onCollectDrawingsStartHandler}
          opened={isModalOpened}
          htmlMessage=""
          title="Collecting Drawings"
          buttonText="Collect"
        >
          <P>All the drawings for this order will be gathered</P>
          <br />
          <P>
            <SPAN fontWeight={600}>Reminder:</SPAN> If a drawing PDF file has
            changed since the last drawing collection, it will delete and
            replace the old. Collecting the drawing may take minutes.
          </P>
        </ConfirmationModal>
      )}

      {isUserCSR && (
        <ConfirmationModal
          confirm={onCollectDrawingsCompleteHandler}
          confirmAsSecondary
          buttonText="Dismiss"
          opened={collectDrawingsStatus === CollectDrawingsStatusEnum.Completed}
          title="Collecting Drawings Complete"
          shouldNotRenderCancelButton
        >
          {collectDrawings?.numberOfFiles === 0
            ? noFilesFoundMessage
            : filesFoundMessage}
        </ConfirmationModal>
      )}

      {isUserCSR && (
        <ConfirmationModal
          cancel={onCollectDrawingsFailedCancelHandler}
          confirm={onCollectDrawingsFailedTryAgainHandler}
          opened={collectDrawingsStatus === CollectDrawingsStatusEnum.Failed}
          title="Collecting Drawings Failed"
          buttonText="Try Again"
        >
          <P>Collecting drawings job has failed.</P>
          <br />
          <P>
            Something went wrong with the scanning and the attaching process.
          </P>
          <br />
          <P>Please contact support.</P>
        </ConfirmationModal>
      )}

      <Wrapper>
        <H2>Acknowledgment</H2>
        <Spacer h="6px" />
        <PMedium color={nevada}>See the following categories.</PMedium>
      </Wrapper>

      <>
        <Spacer h="32px" className="ack-print" />

        <Wrapper flex justifyEnd middle className="ack-print">
          {!isQuoteFlow && <AcknowledgeExpirationDate />}

          {collectDrawingsDecision.enabled && isUserCSR ? (
            <ButtonPrimary
              {...testId(ACKTestButtonEnum.COLLECT_DRAWINGS_BTN)}
              onClick={() => setIsModalOpened(true)}
              disabled={collectDrawingsHasStarted}
            >
              <DividerIcon />

              <Spacer w="11px" />

              {collectDrawingsHasStarted
                ? 'Collecting Drawings'
                : 'Collect Drawings'}
              <Loader
                hidden={!collectDrawingsHasStarted}
                insideButton
                noSpacing
                size={16}
              />
            </ButtonPrimary>
          ) : null}

          {/* PLAT-2822 | not implemented yet */}
          {false && (
            <>
              <Spacer w="50px" />
              <PrintButton onClick={() => console.log('clicked')}>
                <PrintIcon />
                <Spacer w="8px" />
                <PMedium>Print</PMedium>
              </PrintButton>
            </>
          )}
        </Wrapper>

        <Spacer h="16px" className="ack-print" />

        <Divider />

        <ExpandableBox
          title="Basic Info"
          description="Find the order details you have placed"
          noBorderBottom={
            ackAttachments === null || ackAttachments?.length === 0
          }
        >
          <BasicInfo />
        </ExpandableBox>

        {(ackAttachments?.length ?? 0) > 0 && (
          <>
            <Spacer h="16px" />
            <ExpandableBox title="Attachments" description="" noBorderBottom>
              <Attachments />
            </ExpandableBox>
          </>
        )}

        {order && !isOrderSalesMaterial && (
          <OrderReviewPricing isReviewPricing={false} />
        )}

        {order && isOrderSalesMaterial && (
          <OrderReviewPricingSalesMaterial isReviewPricing={false} />
        )}

        <Spacer h="32px" />

        <FormProvider {...methods}>
          {specialOrderApproval &&
            specialOrderApproval.status !==
              SpecialOrderApprovalStatusEnum.DISAPPROVED && (
              <>
                <CoverSheet
                  isSpecialOrderApproval
                  consentTitle="I have reviewed the item(s) and approve. Please order the special materials needed to complete my order."
                >
                  <SpecialOrderApprovalSheet
                    approved={
                      specialOrderApproval.status ===
                      SpecialOrderApprovalStatusEnum.APPROVED
                    }
                  />
                </CoverSheet>
                <Spacer h="30px" />
              </>
            )}

          {isUserDealerOrSalesRep &&
            (order?.status?.id ?? 0) >= OrderStatusEnums.Received &&
            order?.acknowledgementId === null &&
            order?.specialOrderApproval === null && (
              <>
                <Spacer h="32px" />
                <PMedium>
                  This order has been submitted and awaits the response from the
                  CSR.
                </PMedium>
              </>
            )}

          {latestACK &&
            latestACK.status === ACKStatusEnum.CHANGES_REQUESTED &&
            !isUserDealerOrSalesRep && (
              <>
                <Spacer h="32px" />
                <Info type="warning">
                  Dealer is currently requesting changes.
                </Info>
              </>
            )}

          {latestACK && shouldRenderAckSheet(ACKTypeEnum.Standard) && (
            <CoverSheet>
              <ACKTypeSheet1
                approved={latestACK.status === ACKStatusEnum.APPROVED}
                version={latestACK.version}
              />
            </CoverSheet>
          )}

          {latestACK &&
            shouldRenderAckSheet(
              ACKTypeEnum['Special Order Approval With Acknowledgement']
            ) && (
              <CoverSheet consentTitle="I have reviewed the item(s) and approve. Please order the special materials needed to complete my order.">
                <ACKTypeSheet2
                  approved={latestACK.status === ACKStatusEnum.APPROVED}
                  version={latestACK.version}
                />
              </CoverSheet>
            )}

          {latestACK &&
            shouldRenderAckSheet(ACKTypeEnum['ASAP Acknowledgement']) && (
              <CoverSheet consentNeeded={false}>
                <ACKTypeSheet3
                  approved={latestACK.status === ACKStatusEnum.APPROVED}
                  version={latestACK.version}
                />
              </CoverSheet>
            )}

          {latestACK &&
            shouldRenderAckSheet(
              ACKTypeEnum['ASAP Acknowledgement approval required']
            ) && (
              <CoverSheet>
                <ACKTypeSheet4
                  approved={latestACK.status === ACKStatusEnum.APPROVED}
                  version={latestACK.version}
                />
              </CoverSheet>
            )}
          {latestACK && latestACK.type === ACKTypeEnum.Quote && (
            <CoverSheet consentNeeded={false}>
              <QuoteSheet
                approved={latestACK.status === ACKStatusEnum.APPROVED}
                version={latestACK.version}
              />
            </CoverSheet>
          )}

          {/* dealer actions when latest ack exists */}
          {shouldRenderDealerActions() && (
            <OrderFooter>
              <Container>
                <DealerACKActions />
              </Container>
            </OrderFooter>
          )}

          {/* SPECIAL ORDER REQUEST FROM CSR */}
          {shouldRenderSORActions() && (
            <OrderFooter>
              <Container>
                <DealerSORActions />
              </Container>
            </OrderFooter>
          )}

          {/* csr actions when latest ack does not exists */}
          {shouldRenderCSRActions() && (
            <OrderFooter>
              <Container>
                <CSRACKActions />
              </Container>
            </OrderFooter>
          )}
        </FormProvider>
      </>

      <OrderLogs
        bottomPosition={
          !shouldRenderSORActions() &&
          !shouldRenderCSRActions() &&
          !shouldRenderDealerActions()
        }
      />
    </>
  );
};
