import { FC, useEffect, useRef, useState } from 'react';
import { Sides } from 'shared/interface/CSSTypes';
import styled from 'styled-components';
import { Tooltip } from './Tooltip';

interface ElipsisTextProps {
  maxWidth?: number | 'none';
  minWidth?: number;
  position?: Sides;
  noTooltip?: boolean;
}

const ElipsisTextOuter = styled.span`
  position: relative;
  display: inline-flex;
  flex: 1;
  width: 100%;
`;

const ElipsisTextContainer = styled.span<ElipsisTextProps>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  position: relative;

  &:hover {
    + ${Tooltip} {
      opacity: 1;
    }
  }
`;

const ElipsisText: FC<ElipsisTextProps> = ({
  maxWidth,
  minWidth,
  position,
  noTooltip,
  children,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const containerTextRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const [maxW, setMaxW] = useState<null | number>(null);

  useEffect(() => {
    if (containerTextRef?.current) {
      setMaxW(containerTextRef?.current?.offsetWidth);
    }
  }, [containerTextRef?.current?.offsetWidth]);

  useEffect(() => {
    if (
      maxW !== null &&
      textRef?.current &&
      textRef.current.offsetWidth >
        (maxWidth !== 'none' ? maxWidth ?? +(maxW ?? 0) : +(maxW ?? 0))
    ) {
      setShowTooltip(true);
    }
  }, [textRef?.current?.offsetWidth, maxW, children]);

  return (
    <ElipsisTextOuter>
      <ElipsisTextContainer
        ref={containerTextRef}
        maxWidth={maxWidth}
        minWidth={minWidth}
      >
        <span ref={textRef}>{children}</span>
      </ElipsisTextContainer>
      {showTooltip && !noTooltip && (
        <Tooltip position={position}>{children}</Tooltip>
      )}
    </ElipsisTextOuter>
  );
};

ElipsisText.defaultProps = {
  maxWidth: 'none',
  minWidth: 0,
  position: 'top',
};

export default ElipsisText;
