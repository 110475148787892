import {
  setActiveChannel,
  setActiveEntityID,
  setActiveEntityName,
  setActiveEntityType,
  setActiveLineItemID,
  setActiveStyleID,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
} from 'curtain/store/curtainActions';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { useAppDispatch } from './useAppDispatch';

export const useResetActiveEntity = () => {
  const dispatch = useAppDispatch();

  const activeStyleID = useSelector(
    (state: RootState) => state.curtainReducer.activeStyleID
  );

  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const resetActiveEntity = (callback?: () => void) => {
    if (!activeStyleID) dispatch(setActiveStyleID(null));

    if (!activeLineItemID) dispatch(setActiveLineItemID(null));

    if (!activeChannel) dispatch(setActiveChannel(null));

    dispatch(setActiveEntityID(null));
    dispatch(setActiveEntityName(null));
    dispatch(setTopicItemDefaultValue(null));
    dispatch(setTopicTypeDefaultValue(null));
    dispatch(setActiveEntityType(null));

    if (callback) {
      callback();
    }
  };

  return resetActiveEntity;
};
