import { Popup } from 'order/popups/interface/Popup';
import { AnyAction } from 'redux';
import { PopupActiontTypes } from './popupActions';

interface PopupReducerProps {
  popups: Popup[] | null;
}

const initialState: PopupReducerProps = {
  popups: null,
};

export const popupReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case PopupActiontTypes.SET_POPUPS:
      return {
        ...state,
        popups: action.payload as Popup[],
      };
    default:
      return state;
  }
};
