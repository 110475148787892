import { CurtainWorkablesTabEnums } from '../enums/CurtainWorkablesTabEnums';
import { IWorkable } from '../interface/Workable';

export enum CurtainWorkablesActions {
  CLEAR_WORKABLES = 'CLEAR_WORKABLES',
  SET_WORKABLES_MODE = 'SET_WORKABLES_MODE',

  GET_WORKABLES = 'GET_WORKABLES',
  SET_WORKABLES = 'SET_WORKABLES',

  GET_WORKABLE = 'GET_WORKABLE',
  SET_WORKABLE = 'SET_WORKABLE',

  CREATE_WORKABLE = 'CREATE_WORKABLE',
  UPDATE_WORKABLE = 'UPDATE_WORKABLE',

  RESOLVE_WORKABLE = 'RESOLVE_WORKABLE',

  UPDATE_WORKABLE_RESOLVE_STATUS = 'UPDATE_WORKABLE_RESOLVE_STATUS',

  GET_UNRESOLVED_WORKABLES_COUNT = 'GET_UNRESOLVED_WORKABLES_COUNT',
  SET_UNRESOLVED_WORKABLES_COUNT = 'SET_UNRESOLVED_WORKABLES_COUNT',
}

export interface GetWorkablesRequest {
  orderId: string;
}

export const clearWorkables = () => ({
  type: CurtainWorkablesActions.CLEAR_WORKABLES,
});

export const getWorkables = (
  payload: GetWorkablesRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: CurtainWorkablesActions.GET_WORKABLES,
  payload,
  loading,
});

export const setWorkables = (workables: IWorkable[] | null) => ({
  type: CurtainWorkablesActions.SET_WORKABLES,
  payload: workables,
});

export interface GetWorkableRequest {
  orderId: string;
  workableId: string;
}

export const getWorkable = (payload: GetWorkableRequest) => ({
  type: CurtainWorkablesActions.GET_WORKABLE,
  payload,
});

export const setWorkable = (workable: IWorkable | null) => ({
  type: CurtainWorkablesActions.SET_WORKABLE,
  payload: workable,
});

export const setWorkablesMode = (mode: CurtainWorkablesTabEnums) => ({
  type: CurtainWorkablesActions.SET_WORKABLES_MODE,
  payload: mode,
});

export interface CreateWorkableRequest
  extends Pick<
    IWorkable,
    'description' | 'dueDate' | 'assignToSelf' | 'styleId'
  > {
  orderId: string;
}

export const createWorkable = (
  payload: CreateWorkableRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: CurtainWorkablesActions.CREATE_WORKABLE,
  payload,
  onSuccess,
  loading,
});

export interface UpdateWorkableRequest
  extends Pick<IWorkable, 'description' | 'dueDate'> {
  orderId: string;
  workableId: string;
  styleId: string;
}

export const updateWorkable = (
  payload: UpdateWorkableRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: CurtainWorkablesActions.UPDATE_WORKABLE,
  payload,
  onSuccess,
  loading,
});

export interface ResolveWorkableRequest {
  orderId: string;
  workableId: string;
  resolved: boolean;
  styleId: string;
}

export const resolveWorkable = (
  payload: ResolveWorkableRequest,
  onSuccess: () => void
) => ({
  type: CurtainWorkablesActions.RESOLVE_WORKABLE,
  payload,
  onSuccess,
});

export const updateWorkableResolveStatus = (workableId: string) => ({
  type: CurtainWorkablesActions.UPDATE_WORKABLE_RESOLVE_STATUS,
  payload: workableId,
});

export const getUnresolvedWorkablesCount = (payload: GetWorkablesRequest) => ({
  type: CurtainWorkablesActions.GET_UNRESOLVED_WORKABLES_COUNT,
  payload,
});

export const setUnresolvedWorkablesCount = (count: number) => ({
  type: CurtainWorkablesActions.SET_UNRESOLVED_WORKABLES_COUNT,
  payload: count,
});
