export enum AttachmentsModalStepsEnums {
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT',
  COMPLETE_ATTACHMENTS = 'COMPLETE_ATTACHMENTS',

  ADD_ATT_TITLE = 'Add a attachment',
  ADD_ATT_DESC = 'Select specs for the selected attachment.',

  UPDATE_ATT_TITLE = 'Update a attachment',
  UPDATE_ATT_DESC = 'Update specs for the selected attachment.',

  COMPLETE_ATT_TITLE = 'Complete',
  COMPLETE_ATT_DESC = 'The following attachments will apear in your attachment list.',
}
