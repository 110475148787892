import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Checkbox } from 'shared/components/Checkbox';
import { Divider, Spacer } from 'shared/components/Layout';
import { drawingOptionEnums } from 'shared/enum/drawingOptionEnums';
import { Wrapper } from 'shared/components/Wrapper';

import { IConfigureLineItemForm } from '../interface/IConfigureLineItem';

const FactoryCheckboxes = () => {
  const methodsContext = useFormContext<IConfigureLineItemForm>();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  return (
    <>
      <Spacer h="24px" />
      <Divider />
      <Spacer h="24px" />

      <Wrapper flex>
        <Checkbox
          id={`drawingOption--${drawingOptionEnums.PER_DRAWING}`}
          {...methodsContext.register('perDrawing')}
          title="Per Drawing"
          disabled={!canEdit}
        />

        <Spacer w="20px" />

        <Checkbox
          id={`drawingOption--${drawingOptionEnums.PER_FACTORY_DRAWING}`}
          {...methodsContext.register('perFactoryDrawing')}
          title="Per Factory Drawing"
          disabled={!canEdit}
        />
      </Wrapper>
    </>
  );
};

export default FactoryCheckboxes;
