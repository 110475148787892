import styled from 'styled-components';
import { FC } from 'react';
import { IDoorCodeField } from 'order/wizard/orderStyles/interface/DoorCodes';
import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Checkbox } from 'shared/components/Checkbox';
import { FormElement } from 'shared/components/FormElement';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';

import InsertPanelInfo from './InsertPanelInfo';
import { IConfigureLineItemForm } from '../interface/IConfigureLineItem';
import { StyledLabel } from './ConfigureLineItem';

const DoorCodeFieldsContainer = styled.div``;

interface DoorCodeFieldsProps {
  codes: IDoorCodeField[];
  prefix: string;
}

const DoorCodeFields: FC<DoorCodeFieldsProps> = ({ codes, prefix }) => {
  const { register } = useFormContext<IConfigureLineItemForm>();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  return (
    <DoorCodeFieldsContainer>
      {codes.map((code) => {
        const codeGroup = `${prefix}Quantities.${code.id}`;

        return (
          <FormElement key={`${prefix}--${code.code}`}>
            <StyledLabel className={!code.populated ? 'disabled' : ''}>
              {code.code}
            </StyledLabel>

            <Spacer h="6px" />

            <Input
              hidden
              {...register(`${codeGroup}.id` as keyof IConfigureLineItemForm)}
            />

            <Checkbox
              hidden
              {...register(
                `${codeGroup}.populated` as keyof IConfigureLineItemForm
              )}
            />

            {code.isAutomatic && (
              <Checkbox
                hidden
                {...register(
                  `${codeGroup}.isAutomatic` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.isFallback && (
              <Checkbox
                hidden
                {...register(
                  `${codeGroup}.isFallback` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.code && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.code` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.upcharge && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.upcharge` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.doorOverlayId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.doorOverlayId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.doorStyleId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.doorStyleId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.doorConfigurationId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.doorConfigurationId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.drawerFrontStyleId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.drawerFrontStyleId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.materialGroupId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.materialGroupId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.grainDirectionId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.grainDirectionId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.materialDrawerId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.materialDrawerId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {code.archStyleId && (
              <Input
                hidden
                {...register(
                  `${codeGroup}.archStyleId` as keyof IConfigureLineItemForm
                )}
              />
            )}

            {/* main input */}
            <Input
              readOnly={!code.populated || !canEdit}
              {...register(
                `${codeGroup}.quantity` as keyof IConfigureLineItemForm
              )}
            />

            <InsertPanelInfo insertPanel={code.insertPanel} />
          </FormElement>
        );
      })}
    </DoorCodeFieldsContainer>
  );
};

export default DoorCodeFields;
