import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Textarea } from 'shared/components/Textarea';
import { noteValidation } from 'shared/validations/validations';

import { OrderStyleSpecificationsTestTextAreaEnum } from 'tests/enums/OrderStyleSpecificationsTestEnums';
import { testId } from 'tests/utils';
import FormError from 'shared/components/FormError';

const FinishNotes = () => {
  const methodsContext = useFormContext<StyleSpecifications>();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  return (
    <FormElement>
      <FormLabel>Finish Notes</FormLabel>
      <Textarea
        placeholder="Finish notes"
        aria-invalid={
          methodsContext.formState.errors.finishNotes ? 'true' : 'false'
        }
        {...methodsContext.register('finishNotes', noteValidation())}
        readOnly={!canEdit}
        {...testId(OrderStyleSpecificationsTestTextAreaEnum.FINISH_NOTES)}
      />

      <FormError
        label="Finish Notes"
        error={methodsContext.formState.errors.finishNotes}
        validationSchema={noteValidation()}
      />
    </FormElement>
  );
};

export default FinishNotes;
