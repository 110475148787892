import styled from 'styled-components';
import { AnyAction } from 'redux';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PlusOutlinedIcon } from 'assets/icons/plus-outlined.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';

import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { Priority } from 'order/enums/priorityEnum';

import CollaborationActions from 'curtain/components/CollaborationActions';
import { ChannelCard } from 'curtain/components/ChannelCard';
import { ChannelEmptyState } from 'curtain/components/ChannelEmptyState';
import { CurtainHeader } from 'curtain/components/CurtainHeader';
import { CurtainTopicList } from 'curtain/components/CurtainTopicList';
import { IChannel } from 'curtain/interfaces/IChannel';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { IGetChannelsRequest } from 'curtain/interfaces/IGetChannelsRequest';
import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  TopicFilterEnums,
  topicFlagOptions,
} from 'curtain/enums/TopicFilterEnums';

import {
  clearCurtain,
  downloadCollaboration,
  getChannels,
  setActiveChannel,
  setActiveEntityType,
  setActiveLineItemID,
  setActiveStyleID,
  setBackToChanel,
  setCreateTopic,
  setLineItemChannels,
  setStyleChannels,
  setTopicTypeDefaultValue,
  setTopicsFilter,
} from 'curtain/store/curtainActions';

import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { H3 } from 'shared/components/Typography';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import { SelectBorderless } from 'shared/components/SelectBorderless';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { black, lynch, mineShaft } from 'shared/config/Colors';
import { orderHeaderHeightWithCollaborationActions } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import CurtainNotifyCollaborators from './CurtainNotifyCollaborators/CurtainNotifyCollaborators';
import { ChannelTypeEnum } from '../enums/ChannelTypeEnum';
import { Tooltip } from '../../shared/components/Tooltip';

const CollaborationChannelList = styled(Wrapper)<{ canEditOrder: boolean }>`
  // Total page height minus header and footer heights plus bottom-border height
  height: calc(
    100vh -
      ${(canEditOrder) =>
        orderHeaderHeightWithCollaborationActions - (canEditOrder ? 0 : 44)}px
  );
  overflow: auto;
`;

interface CollabSections {
  lineItems: boolean;
  styles: boolean;
}

export const CurtainCollaborationTab = () => {
  const dispatch = useAppDispatch();

  const canEditOrder = useCanEditOrder();

  const queryParams = new URLSearchParams(useLocation().search);

  const [areStyleChannelsLoading, setAreStyleChannelsLoading] = useState(false);

  const shouldNavigateToTopic = useSelector(
    (state: RootState) => state.orderReducer.shouldNavigateToTopic
  );

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const [toggleSections, setToggleSections] = useState<CollabSections>({
    styles: true,
    lineItems: true,
  } as CollabSections);

  const [areLineItemChannelsLoading, setAreLineItemChannelsLoading] =
    useState(false);

  const otherTopics: ICurtainTopic[] = useSelector(
    (state: RootState) => state.curtainReducer.otherTopics
  );

  const styleChannels: IChannel[] = useSelector(
    (state: RootState) => state.curtainReducer.styleChannels
  );
  const lineItemChannels: IChannel[] = useSelector(
    (state: RootState) => state.curtainReducer.lineItemChannels
  );

  const topicsFilter = useSelector(
    (state: RootState) => state.curtainReducer.topicsFilter
  );

  const hasCollaborations =
    styleChannels?.length || lineItemChannels?.length || otherTopics?.length;

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const onSuccessGetStyleChannels = (channels: IChannel[]) => {
    setAreStyleChannelsLoading(false);
    dispatch(setStyleChannels(channels));
  };

  const onFailedGetStyleChannels = () => {
    setAreStyleChannelsLoading(false);
    toast.error('Could not retrieve style channels.');
  };

  const onSuccessGetLineItemChannels = (channels: IChannel[]) => {
    setAreLineItemChannelsLoading(false);
    dispatch(setLineItemChannels(channels));
  };

  const onFailedGetLineItemChannels = () => {
    setAreLineItemChannelsLoading(false);
    toast.error('Could not retrieve style channels.');
  };

  const onToggleSectionsHandler = (section: keyof CollabSections) => {
    setToggleSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useEffect(() => {
    if (order && order.id) {
      setAreStyleChannelsLoading(true);
      setAreLineItemChannelsLoading(true);

      const styleChannelsRequest: IGetChannelsRequest = {
        orderId: order.id,
        type: ChannelTypeEnum.STYLE,
      };

      dispatch(
        getChannels(
          styleChannelsRequest,
          onSuccessGetStyleChannels,
          onFailedGetStyleChannels
        )
      );

      const lineItemChannelsRequest: IGetChannelsRequest = {
        orderId: order.id,
        type: ChannelTypeEnum.LINE_ITEM,
      };

      dispatch(
        getChannels(
          lineItemChannelsRequest,
          onSuccessGetLineItemChannels,
          onFailedGetLineItemChannels
        )
      );
    }
  }, [order]);

  const onTopicFilterChange = (val: SelectOptionProps) => {
    dispatch(setTopicsFilter(val.value as TopicFilterEnums));
  };

  const renderPriority = (channels: IChannel[]) => {
    if (channels) {
      if (channels.some((ch) => ch.priority === Priority.Important)) {
        return (
          <>
            <PriorityBadge priority={Priority.Important} />
            <Spacer w="16px" />
          </>
        );
      }
      if (channels.some((ch) => ch.priority === Priority.Attention)) {
        return (
          <>
            <PriorityBadge priority={Priority.Attention} />
            <Spacer w="16px" />
          </>
        );
      }

      return <></>;
    }
    return <></>;
  };

  const handleChannelClick = (
    channel: IChannel,
    setActiveCallback: (id: string) => AnyAction,
    topicType: TopicType
  ) => {
    dispatch(setActiveChannel(channel));
    dispatch(setActiveCallback(channel.id));
    dispatch(setBackToChanel(channel));
    dispatch(setActiveEntityType(topicType));
  };

  const handleLineItemChannelClick = (lineItem: IChannel) => {
    handleChannelClick(lineItem, setActiveLineItemID, TopicType.LINE_ITEM);
  };

  const handleStyleChannelClick = (style: IChannel) => {
    handleChannelClick(style, setActiveStyleID, TopicType.STYLE);
  };

  const channelIdFromUrl = queryParams.get('channelId');
  const topicIdFromUrl = queryParams.get('topicId');
  const isOtherTopicTypeInUrl =
    queryParams.get('topicType') === TopicType.OTHER;

  useEffect(() => {
    const lineItemChannelExists = lineItemChannels?.find(
      (channel) => channel.id === channelIdFromUrl
    );
    const styleItemChannelExists = styleChannels?.find(
      (channel) => channel.id === channelIdFromUrl
    );

    if (
      (lineItemChannelExists || styleItemChannelExists) &&
      channelIdFromUrl &&
      topicIdFromUrl &&
      shouldNavigateToTopic &&
      !isOtherTopicTypeInUrl
    ) {
      if (lineItemChannelExists) {
        handleLineItemChannelClick(lineItemChannelExists);
      } else if (styleItemChannelExists) {
        handleStyleChannelClick(styleItemChannelExists);
      }
    }
  }, [
    lineItemChannels,
    styleChannels,
    channelIdFromUrl,
    topicIdFromUrl,
    isOtherTopicTypeInUrl,
    shouldNavigateToTopic,
  ]);

  const handleDownloadCollaboration = () => {
    if (order) {
      dispatch(downloadCollaboration(order));
    }
  };

  return (
    <Wrapper>
      <CollaborationChannelList canEditOrder={canEditOrder}>
        <ScrollbarsCustom
          autoHide
          autoHideTimeout={500}
          autoHideDuration={300}
          paddingRight={0}
        >
          <CurtainHeader flex middle between>
            <H3>Topics</H3>

            <Wrapper flex middle>
              {isUserCSR && (
                <>
                  <ButtonIcon
                    disabled={!hasCollaborations}
                    onClick={handleDownloadCollaboration}
                  >
                    <Wrapper withTooltip>
                      <SVG icon={<SaveIcon />} hoverColor={lynch} />
                      <Tooltip position="left" maxWidth={200} minWidth={200}>
                        Download all collaboration topics as a .csv file.
                      </Tooltip>
                    </Wrapper>
                  </ButtonIcon>
                  <Spacer w="15px" />
                </>
              )}

              {isUserCSR && (
                <>
                  <CurtainNotifyCollaborators />

                  <Spacer w="15px" />
                </>
              )}

              <ButtonIcon onClick={() => dispatch(clearCurtain())}>
                <SVG icon={<CloseIcon />} hoverColor={lynch} />
              </ButtonIcon>
            </Wrapper>
          </CurtainHeader>

          {!isOrderSalesMaterial && (
            <CurtainHeader
              flex
              middle
              between
              onClick={() => onToggleSectionsHandler('styles')}
              className={toggleSections.styles ? 'expanded' : ''}
            >
              <H3>Style topics</H3>

              <Wrapper flex>
                {!areStyleChannelsLoading &&
                  styleChannels &&
                  !toggleSections.styles &&
                  renderPriority(styleChannels)}
                <SVG
                  icon={<ArrowUpIcon className="arrow-up" />}
                  color={mineShaft}
                />
              </Wrapper>
            </CurtainHeader>
          )}

          {!isOrderSalesMaterial && toggleSections.styles && (
            <>
              {areStyleChannelsLoading && <Loader />}

              {!areStyleChannelsLoading && !styleChannels.length && (
                <ChannelEmptyState>
                  There are no created topics for styles.
                </ChannelEmptyState>
              )}

              {!areStyleChannelsLoading &&
                styleChannels &&
                styleChannels.map((styleChannel) => (
                  <ChannelCard
                    key={`channel-card-${
                      styleChannel.entityId ?? styleChannel.id
                    }`}
                    id={styleChannel.id}
                    name={styleChannel.name}
                    isDeleted={styleChannel.isDeleted}
                    changeRequested={styleChannel.changeRequested}
                    onClick={() => handleStyleChannelClick(styleChannel)}
                    priority={styleChannel.priority}
                    isRead={styleChannel.isRead}
                    isUserCSR={isUserCSR}
                    dealerRead={styleChannel.dealerRead}
                  />
                ))}
            </>
          )}

          <CurtainHeader
            flex
            middle
            between
            onClick={() => onToggleSectionsHandler('lineItems')}
            className={toggleSections.lineItems ? 'expanded' : ''}
          >
            <H3>Line item topics</H3>

            <Wrapper flex>
              {!areLineItemChannelsLoading &&
                lineItemChannels &&
                !toggleSections.lineItems &&
                renderPriority(lineItemChannels)}
              <SVG
                icon={<ArrowUpIcon className="arrow-up" />}
                color={mineShaft}
              />
            </Wrapper>
          </CurtainHeader>

          {toggleSections.lineItems && (
            <>
              {!areLineItemChannelsLoading && !lineItemChannels.length && (
                <ChannelEmptyState>
                  There are no created topics for line items.
                </ChannelEmptyState>
              )}

              {areLineItemChannelsLoading && <Loader />}

              {!areLineItemChannelsLoading &&
                lineItemChannels &&
                lineItemChannels.map((lineItemChannel) => (
                  <ChannelCard
                    key={`channel-card-${lineItemChannel.entityId}`}
                    isDeleted={lineItemChannel.isDeleted}
                    id={lineItemChannel.id}
                    name={lineItemChannel.name}
                    changeRequested={lineItemChannel.changeRequested}
                    onClick={() => handleLineItemChannelClick(lineItemChannel)}
                    priority={lineItemChannel.priority}
                    isRead={lineItemChannel.isRead}
                    isUserCSR={isUserCSR}
                    dealerRead={lineItemChannel.dealerRead}
                  />
                ))}
            </>
          )}

          <CurtainHeader flex middle between>
            <H3>Other Topics</H3>
            <Wrapper flex justifyEnd>
              {otherTopics && (
                <SelectBorderless
                  value={UtilService.mapStringValueToSelectOptionObject(
                    topicsFilter
                  )}
                  isSearchable={false}
                  bgColor="transparent"
                  onChange={onTopicFilterChange}
                  options={topicFlagOptions.filter(
                    (tf) => tf.value !== TopicFilterEnums.BOTH
                  )}
                />
              )}
              <ButtonIcon
                onClick={() => {
                  dispatch(setCreateTopic(true));
                  dispatch(
                    setTopicTypeDefaultValue({
                      value: TopicType.OTHER,
                      label: 'Other',
                    })
                  );
                }}
              >
                <SVG icon={<PlusOutlinedIcon />} color={black} />
              </ButtonIcon>
            </Wrapper>
          </CurtainHeader>

          <CurtainTopicList other />

          <Spacer h="20px" />
        </ScrollbarsCustom>
      </CollaborationChannelList>

      <CollaborationActions />
    </Wrapper>
  );
};
