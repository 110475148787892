import { FC } from 'react';

import { LineItemDetailsImportError } from 'order/wizard/orderStyles/interface/LineItem';

import { lynch, nevada } from 'shared/config/Colors';

import ScrollbarsCustom from './ScrollbarsCustom';
import { P, SPAN } from './Typography';
import { Spacer } from './Layout';

interface InvalidLineItemsProps {
  invalidLineItems?: LineItemDetailsImportError[];
  reason?: string;
}

export const InvalidLineItems: FC<InvalidLineItemsProps> = ({
  invalidLineItems,
  reason,
}) => {
  return (
    <>
      <P color={nevada}>
        <SPAN color={lynch} fontWeight={600}>
          Reminder:
        </SPAN>{' '}
        This list is saved and available on the Attachments page under the
        Exceptions Reports section.
      </P>

      <Spacer h="30px" />

      <P>{reason ?? 'The following Line Item(s) can not be imported:'}</P>

      <Spacer h="15px" />

      <ScrollbarsCustom
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        autoHeight
        autoHeightMin={100}
        autoHeightMax={235}
      >
        {invalidLineItems?.map((lineItem) => (
          <P color="inherit" key={lineItem.id}>
            {lineItem.code} - {lineItem.description}
          </P>
        ))}
      </ScrollbarsCustom>
    </>
  );
};
