import { AnyAction } from 'redux';

import { Priority } from 'order/enums/priorityEnum';

import { TopicFilterEnums } from 'curtain/enums/TopicFilterEnums';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { IChannel } from 'curtain/interfaces/IChannel';

import {
  curtainActions,
  TopicResolvePayload,
} from 'curtain/store/curtainActions';

import { CurtainComment } from 'shared/interface/CurtainComment';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

interface curtainReducerProps {
  curtainTab: string;
  curtainSection: string;
  curtainActiveTopic: ICurtainTopic | null;
  otherTopics: ICurtainTopic[] | null;
  otherTopicsExist: boolean;
  topicsFilter: TopicFilterEnums.UNRESOLVED | TopicFilterEnums.RESOLVED;
  comments: CurtainComment[] | null;
  addingTopic: boolean;
  createTopic: boolean;
  activeTopicID: string | null;
  activeStyleID: string | null;
  activeLineItemID: string | null;
  activeEntityID: string | null;
  activeEntityName: string | null;
  activeChannel: IChannel | null;
  lineItemChannels: IChannel[];
  styleChannels: IChannel[];
  topicTypeDefaultValue: SelectOptionProps | null;
  topicItemDefaultValue: SelectOptionProps | null;
  topics: ICurtainTopic[] | null;
  refreshPriority: boolean;
  backToChannel: IChannel | null;
  activeEntityType: string | null;
  numberOfUnreadTopics: number | null;
  updatedTopic: ICurtainTopic | null;
  updatedComment: CurtainComment | null;
}

const initialState: curtainReducerProps = {
  curtainTab: '',
  curtainSection: '',
  curtainActiveTopic: null,
  otherTopics: null,
  otherTopicsExist: false,
  comments: null,
  topicsFilter: TopicFilterEnums.UNRESOLVED,
  addingTopic: false,
  createTopic: false,
  activeTopicID: null,
  activeStyleID: null,
  activeEntityID: null,
  activeEntityName: null,
  activeLineItemID: null,
  activeChannel: null,
  lineItemChannels: [],
  styleChannels: [],
  topicTypeDefaultValue: null,
  topicItemDefaultValue: null,
  topics: null,
  refreshPriority: false,
  backToChannel: null,
  activeEntityType: null,
  numberOfUnreadTopics: null,
  updatedTopic: null,
  updatedComment: null,
};

export const curtainReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case curtainActions.CLEAR_CURTAIN:
      return {
        ...initialState,
        numberOfUnreadTopics: state.numberOfUnreadTopics,
      };
    case curtainActions.SET_CURTAIN_TAB:
      return {
        ...state,
        curtainTab: action.payload,
      };

    case curtainActions.SET_CURTAIN_ACTIVE_TOPIC:
      return {
        ...state,
        curtainActiveTopic: action.payload,
      };

    case curtainActions.SET_OTHER_TOPICS:
      return {
        ...state,
        otherTopics: action.payload,
      };

    case curtainActions.SET_ACTIVE_TOPIC_TO_RESOLVED:
      return {
        ...state,
        curtainActiveTopic: {
          ...state.curtainActiveTopic,
          isResolved: true,
        },
      };

    case curtainActions.SET_ACTIVE_TOPIC_TO_UNRESOLVED:
      return {
        ...state,
        curtainActiveTopic: {
          ...state.curtainActiveTopic,
          isResolved: false,
        },
      };

    case curtainActions.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload as CurtainComment[],
      };

    case curtainActions.SET_SINGLE_CURTAIN_COMMENT:
      if (state.comments) {
        return {
          ...state,
          comments: [...state.comments, action.payload] as CurtainComment[],
        };
      }
      return state;
    case curtainActions.SET_TOPICS_FILTER:
      return {
        ...state,
        topicsFilter: action.payload,
      };
    case curtainActions.IS_ADDING_TOPIC:
      return {
        ...state,
        addingTopic: action.payload,
        refreshPriority: false,
      };
    case curtainActions.SET_CREATE_TOPIC:
      return {
        ...state,
        createTopic: action.payload,
      };
    case curtainActions.SET_ACTIVE_TOPIC_ID:
      return {
        ...state,
        activeTopicID: action.payload,
      };
    case curtainActions.SET_ACTIVE_STYLE_ID:
      return {
        ...state,
        activeStyleID: action.payload,
      };
    case curtainActions.SET_ACTIVE_LINE_ITEM_ID:
      return {
        ...state,
        activeLineItemID: action.payload,
      };
    case curtainActions.SET_ACTIVE_ENTITY_ID:
      return {
        ...state,
        activeEntityID: action.payload,
      };
    case curtainActions.SET_ACTIVE_ENTITY_NAME:
      return {
        ...state,
        activeEntityName: action.payload,
      };
    case curtainActions.SET_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannel: action.payload,
      };
    case curtainActions.SET_STYLE_CHANNELS:
      return {
        ...state,
        styleChannels: state.refreshPriority
          ? action.payload.map((channel: IChannel) => {
              return {
                ...channel,
                priority:
                  channel.priority === Priority.None
                    ? Priority.Attention
                    : channel.priority,
              };
            })
          : action.payload,
      };
    case curtainActions.SET_LINE_ITEM_CHANNELS:
      return {
        ...state,
        lineItemChannels: state.refreshPriority
          ? action.payload.map((channel: IChannel) => {
              return {
                ...channel,
                priority:
                  channel.priority === Priority.None
                    ? Priority.Attention
                    : channel.priority,
              };
            })
          : action.payload,
      };
    case curtainActions.SET_TOPIC_TYPE_DEFAULT_VALUE:
      return {
        ...state,
        topicTypeDefaultValue: action.payload,
      };
    case curtainActions.SET_TOPIC_ITEM_DEFAULT_VALUE:
      return {
        ...state,
        topicItemDefaultValue: action.payload,
      };
    case curtainActions.SET_TOPICS:
      return {
        ...state,
        topics: state.refreshPriority
          ? action.payload.map((topic: ICurtainTopic) => {
              return {
                ...topic,
                priority:
                  topic.priority === Priority.None
                    ? Priority.Attention
                    : topic.priority,
              };
            })
          : action.payload,
      };
    case curtainActions.SET_TOPIC_TO_RESOLVED: {
      const { topicId, other } = action.payload as TopicResolvePayload;

      return {
        ...state,
        ...(!other &&
          state.topics && {
            topics: state.topics!.map((topic) => {
              if (topic.id === topicId) {
                return {
                  ...topic,
                  isResolved: true,
                  priority: Priority.None,
                };
              }
              return topic;
            }),
          }),
        ...(state.otherTopics && {
          otherTopics: state.otherTopics.map((topic) => {
            if (topic.id === topicId) {
              return {
                ...topic,
                isResolved: true,
                priority: Priority.None,
              };
            }
            return topic;
          }),
        }),
      };
    }

    case curtainActions.SET_TOPIC_TO_UNRESOLVED: {
      const { topicId, isOrderSubmitted, other } =
        action.payload as TopicResolvePayload;

      return {
        ...state,
        ...(!other &&
          state.topics && {
            topics: state.topics!.map((topic) => {
              if (topic.id === topicId) {
                return {
                  ...topic,
                  isResolved: false,
                  priority: isOrderSubmitted
                    ? Priority.Attention
                    : Priority.None,
                };
              }
              return topic;
            }),
          }),
        ...(state.otherTopics && {
          otherTopics: state.otherTopics.map((topic) => {
            if (topic.id === topicId) {
              return {
                ...topic,
                isResolved: false,
                priority: isOrderSubmitted ? Priority.Attention : Priority.None,
              };
            }
            return topic;
          }),
        }),
      };
    }
    case curtainActions.SET_TOPIC_PRIORITY:
      return {
        ...state,
        topics: (state.topics ?? []).map((topic) => {
          if (topic.id === action.payload.topicId) {
            return {
              ...topic,
              priority: action.payload.priority,
            };
          }
          return topic;
        }),
        ...(state.otherTopics && {
          otherTopics: (state.otherTopics ?? []).map((topic) => {
            if (topic.id === action.payload.topicId) {
              return {
                ...topic,
                priority: action.payload.priority,
              };
            }
            return topic;
          }),
        }),
      };
    case curtainActions.SET_STYLE_CHANNEL_PRIORITY:
      return {
        ...state,
        styleChannels: state.styleChannels!.map((channel) => {
          if (channel.id === action.payload.channelId) {
            return {
              ...channel,
              priority: action.payload.priority,
            };
          }
          return channel;
        }),
      };
    case curtainActions.SET_LINEITEM_CHANNEL_PRIORITY:
      return {
        ...state,
        lineItemChannels: state.lineItemChannels!.map((channel) => {
          if (channel.id === action.payload.channelId) {
            return {
              ...channel,
              priority: action.payload.priority,
            };
          }
          return channel;
        }),
      };
    case curtainActions.SET_REFRESH_PRIORITY:
      return {
        ...state,
        refreshPriority: action.payload,
      };
    case curtainActions.SET_BACK_TO_CHANNEL:
      return {
        ...state,
        backToChannel: action.payload as IChannel,
      };
    case curtainActions.SET_ACTIVE_ENTITY_TYPE:
      return {
        ...state,
        activeEntityType: action.payload as string,
      };
    case curtainActions.SET_NUMBER_OF_UNREAD_TOPICS:
      return {
        ...state,
        numberOfUnreadTopics: action.payload as number,
      };
    case curtainActions.SET_UPDATE_TOPIC:
      return {
        ...state,
        updatedTopic: action.payload,
      };
    case curtainActions.SET_UPDATE_COMMENT:
      return {
        ...state,
        updatedComment: action.payload,
      };
    default:
      return state;
  }
};
