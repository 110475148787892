import styled from 'styled-components';
import { FC, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import { ILineItemCategory } from '../interface/IlineItemCategory';

import {
  getSubcategories,
  setCategory,
  setSubcategories,
  setSubcategory,
} from '../store/orderLineItemsActions';

import LineItemSubcategories from '../LineItemSubcategories/LineItemSubcategories';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';

const LineItemCategoryContainer = styled.div`
  margin-bottom: 14px;
`;

interface CategoryNameProps {
  active: boolean;
}

const CategoryName = styled.div<CategoryNameProps>`
  color: ${({ theme, active }) => (active ? theme.lynch : theme.nevada)};
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : '400')};
  transition: color 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.lynch};
  }
`;

const SubcategoryList = styled.div``;

interface LineItemCategoryProps {
  category: ILineItemCategory;
}

const LineItemCategory: FC<LineItemCategoryProps> = ({ category }) => {
  const dispatch = useAppDispatch();

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'styleId',
  ]);

  const [subcategoriesLoading, setSubcategoriesLoading] = useState(false);

  const allProductsCategory = useSelector((state: RootState) =>
    state.orderLineItemsReducer.categories?.find((cat) => cat.id === '0')
  );

  const selectedCategory = useSelector(
    (state: RootState) => state.orderLineItemsReducer.selectedCategory
  );

  const style = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style
  );

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const onSelectCategoryClickHandler = () => {
    if (category.id === '0') {
      dispatch(setSubcategories(null));
    }

    if (selectedCategory?.id === category.id) {
      // set initial cat
      if (allProductsCategory) {
        dispatch(setCategory(allProductsCategory));
        dispatch(setSubcategory(null));
      }
    } else {
      dispatch(setCategory(category));
      dispatch(setSubcategory(null));

      if (category.id !== '0' && queryFields && order && style) {
        setSubcategoriesLoading(true);
        dispatch(
          getSubcategories(
            {
              categoryId: category.id,
              productLineId: queryFields.productLineId,
              doorOverlayId: style.doorOverlay.id,
              orderType: UtilService.getCatalogOrderType(
                order?.type,
                order?.stylizationType
              ),
              styleId: queryFields.styleId,
            },
            setSubcategoriesLoading
          )
        );
      }
    }
  };

  return (
    <LineItemCategoryContainer>
      <Wrapper flex middle>
        <CategoryName
          active={selectedCategory?.id === category.id}
          onClick={onSelectCategoryClickHandler}
        >
          {category.name}
        </CategoryName>

        <Spacer w="10px" />

        {subcategoriesLoading &&
          category.id !== '0' &&
          selectedCategory?.id === category.id && (
            <Loader noSpacing size={14} />
          )}
      </Wrapper>

      <SubcategoryList>
        {!subcategoriesLoading &&
          category.id !== '0' &&
          selectedCategory?.id === category.id && <LineItemSubcategories />}
      </SubcategoryList>
    </LineItemCategoryContainer>
  );
};

export default LineItemCategory;
