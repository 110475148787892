export enum PopupConditionType {
  ProductLine = 'Product Line',
  DoorSeries = 'Door Series',
  WallEdge = 'Wall Edge',
  BaseEdge = 'Base Edge',
  WallInsertPanel = 'Wall Insert Panel',
  BaseInsertPanel = 'Base Insert Panel',
  FromWidth = 'From Width',
  ToWidth = 'To Width',
  FromHeight = 'From Height',
  ToHeight = 'To Height',
  Hinge = 'Hinge',
  FaceFrame = 'Face Frame',
}
