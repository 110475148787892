import { FC } from 'react';

import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { ACKTypeSheetContainer } from '../ACKTypeSheetContainer';
import { ACKTypeSheetProps } from '../../interface/ACKTypeSheetProps';
import ApprovedACKIcon from '../ApprovedACKIcon';
import { ACKTypeEnum } from '../../enums/ACKTypeEnum';

const ACKTypeSheet3: FC<ACKTypeSheetProps> = ({ approved, version }) => {
  return (
    <ACKTypeSheetContainer
      data-testid={`ack-sheet--${ACKTypeEnum['ASAP Acknowledgement']}`}
    >
      <Wrapper inlineFlex>
        <H2 fontSize={24}>
          ASAP Acknowledgment{(version ?? 0) > 1 ? ` v${version}` : ''}
        </H2>

        {approved && (
          <ApprovedACKIcon
            data-testid={`ack-sheet-approved--${ACKTypeEnum['ASAP Acknowledgement']}`}
          />
        )}
      </Wrapper>

      <Spacer h="12px" />

      <P color={nevada}>
        ASAP orders are processed immediately, therefore, they cannot be changed
        or canceled.
      </P>
    </ACKTypeSheetContainer>
  );
};

export default ACKTypeSheet3;
