import { RootState } from 'store';
import styled from 'styled-components';
import { FC, useEffect } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ReactComponent as TrashBinIcon } from 'assets/icons/trash-bin.svg';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { mineShaft } from 'shared/config/Colors';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';

import { SpecificationsOptions } from '../../../store/orderStylesReducer';
import { upchargeValidation } from '../../../../../../../shared/validations/validations';
import FormError from '../../../../../../../shared/components/FormError';

import { getWoodEffectsByWoodEffect } from '../../../store/specifications/orderStylesSpecificationActions';

const WoodEffectFieldContainer = styled(Wrapper)``;

const WoodEffectUpchargeWrapper = styled(Wrapper)`
  position: relative;

  > ${TabbableButton} {
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface WoodEffectFieldProps {
  index: number;
  remove: (index?: number) => void;
  control: Control<StyleSpecifications>;
  readOnly: boolean;
}

const WoodEffectFieldNew: FC<WoodEffectFieldProps> = ({
  index,
  remove,
  control,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const isICSProductLine =
    selectedProductLine?.name === ProductLineEnums.PRODUCT_LINE_ICS;

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const firstInArray = index === 0;

  const woodEffectOptionsKey = `woodEffectOptions${
    index + 1
  }` as keyof SpecificationsOptions;

  const woodEffectOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions[woodEffectOptionsKey]
  );

  const storedWoodEffect = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specifications?.woodEffects?.[index]
  );

  const { watch, setValue, formState, register } =
    useFormContext<StyleSpecifications>();

  const woodOrMaterialWatched = watch('woodOrMaterial');

  const isWoodTypeCharCherry =
    woodOrMaterialWatched?.label ===
    SpecificationsOptionsEnum.WOOD_TYPE_CHAR_CHERRY;

  const woodEffectField = useWatch({
    control,
    name: `woodEffects.${index}`,
  });

  const onRemoveWoodEffectClickHandler = () => {
    remove(index);
  };

  const onWoodEffectOptionChangeHandler = (val: SelectOptionProps) => {
    // if has value set upcharge
    if (val) {
      UtilService.withDecimal(
        `woodEffects.${index}.upcharge`,
        val.upcharge!.toString(),
        setValue
      );
    } else {
      // reset upcharge
      setValue(`woodEffects.${index}.upcharge`, '');

      // remove second wood effect
      remove(1);

      // reset loaded options for second wood effect
    }

    // reset both values when frist wood effect changes
    if (firstInArray) {
      setValue(`woodEffects.${index + 1}.upcharge`, '');
      setValue(`woodEffects.${index + 1}.selectedOption`, null);
    }
  };

  const loadDependents = () => {
    if (
      woodEffectField?.selectedOption &&
      woodOrMaterialWatched &&
      selectedProductLine &&
      firstInArray
    ) {
      dispatch(
        getWoodEffectsByWoodEffect({
          productLineId: selectedProductLine.id,
          woodEffectId: woodEffectField.selectedOption.value,
          woodMaterialId: woodOrMaterialWatched.value,
          ...(styleOverride && { isOverriden: true }),
        })
      );
    }
  };

  useEffect(() => {
    // get dependents only when all of those conditiion below are fulfiled
    loadDependents();
  }, [woodEffectField?.selectedOption]);

  // select char cherry dist based on wood char cherry
  useEffect(() => {
    if (
      firstInArray &&
      woodOrMaterialWatched &&
      woodEffectOptions &&
      formState.dirtyFields.woodOrMaterial
    ) {
      const foundCharCherryDistOption = woodEffectOptions.find(
        (woodEffectOption) =>
          woodEffectOption.label ===
          SpecificationsOptionsEnum.WOOD_EFFECT_CHAR_CHERRY_DIST
      );

      if (foundCharCherryDistOption && isWoodTypeCharCherry) {
        setValue('woodEffects.0.selectedOption', foundCharCherryDistOption);

        setValue(
          'woodEffects.0.upcharge',
          UtilService.withDecimal(
            null,
            (foundCharCherryDistOption.upcharge ?? 0).toString()
          ) as string
        );
      }
    }
  }, [woodEffectOptions]);

  useEffect(() => {
    if (storedWoodEffect && !formState.isDirty) {
      setValue(`woodEffects.${index}.upcharge`, storedWoodEffect.upcharge, {
        shouldDirty: false,
      });

      setValue(
        `woodEffects.${index}.selectedOption`,
        storedWoodEffect.selectedOption,
        { shouldDirty: false }
      );
    }
  }, [storedWoodEffect]);

  return (
    <WoodEffectFieldContainer flex>
      <Input
        hidden
        readOnly={readOnly}
        {...register(`woodEffects.${index}.id`, {
          required: !!woodEffectField.selectedOption,
        })}
      />

      <Input
        hidden
        readOnly={readOnly}
        {...register(`woodEffects.${index}.orderNumber`, {
          required: !!woodEffectField.selectedOption,
        })}
      />

      <FormElement flexGrow>
        {firstInArray && <FormLabel>Wood Effect</FormLabel>}

        <WarningTooltip
          fieldName="Wood Effect"
          withTooltip={UtilService.shouldShowTooltip(
            woodEffectOptions,
            woodEffectField
          )}
        >
          <Controller
            name={`woodEffects.${index}.selectedOption`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                options={
                  firstInArray && !isICSProductLine && isWoodTypeCharCherry
                    ? woodEffectOptions?.map((option) => ({
                        ...option,
                        isDisabled:
                          option.label !==
                          SpecificationsOptionsEnum.WOOD_EFFECT_CHAR_CHERRY_DIST,
                      }))
                    : woodEffectOptions
                }
                isDisabled={!woodEffectOptions?.length || !canEdit || readOnly}
                value={value}
                data-test={`woodEffectOption--${index}`}
                onChange={(val: SelectOptionProps) => {
                  onChange(val);
                  onWoodEffectOptionChangeHandler(val);
                }}
                isClearable={
                  firstInArray && isICSProductLine
                    ? true
                    : !isWoodTypeCharCherry
                }
              />
            )}
          />
        </WarningTooltip>
      </FormElement>

      <Spacer w="24px" />

      <FormElement maxWidth={82}>
        {firstInArray && <FormLabel>Upcharge %</FormLabel>}

        <WoodEffectUpchargeWrapper flex middle>
          <Controller
            name={`woodEffects.${index}.upcharge`}
            control={control}
            rules={upchargeValidation()}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={onChange}
                value={value}
                data-test={`woodEffectUpcharge--${index}`}
                placeholder="0.00"
                readOnly={
                  !woodEffectOptions?.length ||
                  !woodEffectField.selectedOption ||
                  !canEdit ||
                  readOnly
                }
                type="text"
                onBlur={(e) =>
                  setValue(
                    `woodEffects.${index}.upcharge`,
                    UtilService.withDecimal(null, e.target.value) as string
                  )
                }
                aria-invalid={
                  formState.errors.woodEffects?.[index]?.upcharge
                    ? 'true'
                    : 'false'
                }
              />
            )}
          />

          {!readOnly && index > 0 && (
            <TabbableButton
              onClick={onRemoveWoodEffectClickHandler}
              type="button"
              disabled={!canEdit}
            >
              <SVG
                icon={<TrashBinIcon />}
                color={`${mineShaft}80`}
                hoverColor={mineShaft}
              />
            </TabbableButton>
          )}
        </WoodEffectUpchargeWrapper>
        <FormError
          label="Wood Effect Upcharge"
          error={formState.errors.woodEffects?.[index]?.upcharge}
          validationSchema={upchargeValidation()}
        />
      </FormElement>
    </WoodEffectFieldContainer>
  );
};

export default WoodEffectFieldNew;
