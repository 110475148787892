import { createPortal } from 'react-dom';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { H2 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import {
  changeStylesStep,
  clearStyles,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import OverviewWood from './OverviewWood/OverviewWood';
import OverviewImpression from './OverviewImpression/OverviewImpression';

const OrderStylesOverviewContainer = styled.div``;

const ProductLineImage = styled.img`
  margin-left: auto;
`;

const OrderStylesOverview = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [styleLoading, setStyleLoading] = useState(true);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const styleName = useSelector(
    (state: RootState) => state.orderStylesReducer.name
  );

  const storedSpecs = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications
  );

  const storedDoorBuilder = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilder
  );

  const finishImpressionData = useSelector(
    (state: RootState) => state.orderStylesReducer.finishImpressionData
  );

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.DOOR_BUILDER));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.ADJUSTMENTS));
    }
  };

  const onFinishClickHandler = () => {
    dispatch(clearStyles());
    history.replace(`${location.pathname}`, location.state);
  };

  useEffect(() => {
    if ((finishImpressionData || storedSpecs) && storedDoorBuilder) {
      setStyleLoading(false);
    }
  }, [finishImpressionData, storedSpecs, storedDoorBuilder]);

  const styleLoaderContainer = document.getElementById(
    'style-loader-container'
  ) as HTMLElement;

  return (
    <OrderStylesOverviewContainer>
      <Spacer h="49px" />
      <Wrapper flex middle>
        <H2>{styleName}</H2>

        {selectedProductLine && (
          <ProductLineImage src={selectedProductLine.imageUrl} />
        )}
      </Wrapper>

      <Spacer h="30px" />

      {!finishImpressionData ? <OverviewWood /> : <OverviewImpression />}

      <Spacer h="30px" />

      <OrderFooter>
        <Wrapper flex middle between>
          <ButtonSecondary onClick={() => redirect(true)} type="button">
            Back
          </ButtonSecondary>

          {isUserCSR ? (
            <ButtonPrimary
              data-test="button-StylesSpecsSubmit"
              type="button"
              onClick={() => redirect(false)}
            >
              Next
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              onClick={onFinishClickHandler}
              data-test="button-StyleSubmit"
              type="button"
            >
              Finish
            </ButtonPrimary>
          )}
        </Wrapper>
      </OrderFooter>

      {styleLoading &&
        styleLoaderContainer &&
        createPortal(
          <LoaderFullScreen flex middle center bottom={71} top={132}>
            <Loader noSpacing size={40} />
          </LoaderFullScreen>,
          styleLoaderContainer
        )}
    </OrderStylesOverviewContainer>
  );
};

export default OrderStylesOverview;
