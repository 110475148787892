import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

import {
  ButtonDefault,
  ButtonPrimaryCSS,
  ButtonSecondaryCSS,
} from 'shared/components/Button';
import { H4, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
// import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { ILineItemCatalogItem } from 'order/wizard/orderLineItems/interface/ILineItemCatalog';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import Img from './Img';

interface LineItemsProps extends HTMLAttributes<HTMLDivElement> {
  lineItem: ILineItemCatalogItem;
  highlighted: boolean;
  onLineItemConfigureHandler: (lineItem: ILineItemCatalogItem) => void;
  isLineItemAlreadyAdded?: boolean;
}

const LineItemContainer = styled.div<Pick<LineItemsProps, 'highlighted'>>`
  ${BoxShadowCSS}
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 24px;
  width: 100%;
  border: 1px solid transparent;

  ${({ highlighted, theme }) => highlighted && `border-color: ${theme.lynch}`};

  &:focus {
    outline: none;
  }

  ${ButtonDefault} {
    ${({ highlighted }) =>
      highlighted ? ButtonPrimaryCSS : ButtonSecondaryCSS}
  }
`;

const LineItemTopSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: middle;
`;

const Image = styled(Img)`
  height: 176px;
  object-fit: contain;
  object-position: center;
  width: 198px;
`;

const LineItemBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const Description = styled(PSmall)`
  color: ${({ theme }) => theme.nevada};
`;

const Category = styled(PSmall)`
  color: ${({ theme }) => theme.lynch};

  > span {
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  align-items: end;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const LineItem = forwardRef<HTMLDivElement, LineItemsProps>(
  (
    {
      lineItem,
      highlighted,
      onLineItemConfigureHandler,
      isLineItemAlreadyAdded,
      ...rest
    },
    ref
  ) => {
    return (
      <LineItemContainer {...rest} ref={ref} highlighted={highlighted}>
        <LineItemTopSection>
          <Image src={lineItem.imageUrl} alt={`${lineItem.code}`} />
        </LineItemTopSection>

        <Spacer h="42px" />

        <LineItemBottomSection>
          <Wrapper flex>
            <H4>{lineItem.code}</H4>
          </Wrapper>

          <Spacer h="8px" />

          <Description>{lineItem.description}</Description>

          <Spacer h="8px" />

          <Category>
            <span>{lineItem.category.name} -</span> {lineItem.subCategory.name}
          </Category>

          <ButtonContainer>
            <ButtonDefault
              tabIndex={-1}
              type="button"
              onClick={() => onLineItemConfigureHandler(lineItem)}
              disabled={isLineItemAlreadyAdded}
            >
              Add
            </ButtonDefault>
          </ButtonContainer>
        </LineItemBottomSection>
      </LineItemContainer>
    );
  }
);
