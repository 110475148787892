import { P } from 'shared/components/Typography';
import styled from 'styled-components';

interface ChannelEmptyStateProps {
  noBorder?: boolean;
}

export const ChannelEmptyState = styled(P)<ChannelEmptyStateProps>`
  ${({ noBorder, theme }) => !noBorder && `1px solid ${theme.alto};`}
  font-size: 12px;
  padding: 20px 10px;
  text-align: center;
`;
