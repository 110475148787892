import { FC } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { getOrderStatus } from 'order/store/orderActions';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Form } from 'shared/components/Form';
import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import RadioButtonWithDesc from 'shared/components/RadioButtonWithDesc';
import UtilService from 'shared/services/util.service';

import { FormElement } from 'shared/components/FormElement';
import { Textarea } from 'shared/components/Textarea';
import { FormLabel } from 'shared/components/FormLabel';
import { createACK } from '../store/ACKActions';

interface SendACKModalProps {
  close: () => void;
}

interface SendACKForm {
  type: string;
  note: string;
}

const tempAckTypes = [
  {
    id: 1,
    name: 'Acknowledgment',
    desc: 'Send an acknowledgment for this work order number.',
  },
  {
    id: 2,
    name: 'Special Order Approval With Acknowledgment',
    desc: 'Send a special order approval request and acknowledgment for this work order.',
  },
  {
    id: 3,
    name: 'ASAP Acknowledgment',
    desc: 'Send an ASAP acknowledgment for this work order. No dealer approval is required.',
  },
  {
    id: 4,
    name: 'ASAP Acknowledgment - Approval Required.',
    desc: 'Send an ASAP acknowledgment for this work order. This ASAP acknowledgment requires dealer approval.',
  },
];

const SendACKModalContainer = styled.div``;

const SendACKModal: FC<SendACKModalProps> = ({ close }) => {
  const { orderId } = useParams<OrderPageParams>();

  const dispatch = useAppDispatch();

  const { register, handleSubmit, formState } = useForm<SendACKForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      note: '',
    },
  });

  const onCreateACKSuccessHandler = (closeConfirmModal: () => void) => {
    toast.success('Acknowledgment has been successfully created.');

    closeConfirmModal();

    setTimeout(() => {
      window.scrollTo(0, 0);
      UtilService.onPopupClose();
      close();
    }, 100);

    // get latest order status
    dispatch(getOrderStatus(orderId));
  };

  const onSubmit = (data: SendACKForm, closeConfirmModal: () => void) => {
    dispatch(
      createACK(
        {
          orderId,
          type: +data.type,
          note: data.note,
        },
        () => onCreateACKSuccessHandler(closeConfirmModal)
      )
    );
  };

  return (
    <SendACKModalContainer>
      <H2 fontSize={24}>Send Acknowledgment</H2>

      <Spacer h="16px" />

      <P fontSize={16} color={nevada}>
        Select one of the following options.
      </P>

      <Spacer h="32px" />

      <Form>
        {tempAckTypes.map((type) => (
          <RadioButtonWithDesc
            {...register('type', { required: true })}
            data-flow-option
            id={type.id.toString()}
            desc={type.desc}
            key={type.id}
            label={type.name}
            value={type.id}
          />
        ))}
        <Spacer h="32px" />
        <FormElement noMarginBottom>
          <FormLabel>Note</FormLabel>
          <Textarea {...register('note')} />
        </FormElement>
      </Form>

      <Spacer h="32px" />

      <Wrapper flex middle justifyEnd>
        <ButtonSecondary onClick={close}>Cancel</ButtonSecondary>
        <Spacer w="15px" />

        <ConfirmationModal
          nested
          title="Acknowledgment Request"
          buttonText="Send"
          message="Send an Acknowledgment request to the dealer for the confirmation."
          confirm={(closeConfirmModal) =>
            handleSubmit((data) => onSubmit(data, closeConfirmModal))
          }
          trigger={
            <ButtonPrimary disabled={!formState.isValid}>Request</ButtonPrimary>
          }
        />
      </Wrapper>
    </SendACKModalContainer>
  );
};

export default SendACKModal;
