import { FC } from 'react';

import { H6 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { ContainerSmall, ContainerXSmall } from 'shared/components/Container';
import styled from 'styled-components';

interface TotalPriceRowProps {
  title: string;

  isListPriceSelected?: boolean;
  isShowCatalogChecked?: boolean;

  // catalog prices
  catalogListTotal?: number;
  catalogNetTotal?: number;

  // dealer prices
  listTotal?: number;
  netTotal?: number;

  spacingBetweenPrices?: number;
}

const TotalPriceRowContainer = styled(Wrapper)`
  padding-bottom: 0;
`;

const TotalPriceRow: FC<TotalPriceRowProps> = ({
  title,
  isListPriceSelected,
  isShowCatalogChecked,
  catalogListTotal,
  catalogNetTotal,
  listTotal,
  netTotal,
  spacingBetweenPrices,
}) => {
  return (
    <TotalPriceRowContainer flex middle isTableCell border={['top']}>
      <ContainerXSmall mrAuto>
        <H6>{title.toUpperCase()}</H6>
      </ContainerXSmall>

      {isShowCatalogChecked && (
        <>
          <ContainerSmall>
            <H6>
              {isListPriceSelected
                ? catalogListTotal?.toFixed(2)
                : catalogNetTotal?.toFixed(2)}
            </H6>
          </ContainerSmall>
          <Spacer w={`${spacingBetweenPrices}px`} />
        </>
      )}

      {/* when list price is not defined and catalogListTotal and net catalogListTotal is provided */}
      {isListPriceSelected !== undefined &&
      listTotal !== undefined &&
      netTotal !== undefined ? (
        <ContainerSmall>
          <H6>
            {isListPriceSelected ? listTotal?.toFixed(2) : netTotal?.toFixed(2)}
          </H6>
        </ContainerSmall>
      ) : null}

      {listTotal === undefined && netTotal !== undefined ? (
        <>
          <Spacer w="35px" />
          <ContainerSmall>
            <H6>{netTotal?.toFixed(2)}</H6>
          </ContainerSmall>
        </>
      ) : null}

      {isListPriceSelected === undefined &&
        listTotal !== undefined &&
        netTotal !== undefined && (
          <>
            <ContainerSmall>
              <H6>{listTotal?.toFixed(2)}</H6>
            </ContainerSmall>

            <Spacer w="35px" />

            <ContainerSmall>
              <H6>{netTotal?.toFixed(2)}</H6>
            </ContainerSmall>
          </>
        )}
    </TotalPriceRowContainer>
  );
};

TotalPriceRow.defaultProps = {
  spacingBetweenPrices: 146,
};

export default TotalPriceRow;
