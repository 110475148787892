export enum NotificationTypeEnums {
  COLLABORATION = 1,
  ORDER,
  ACK,
  ATTACHMENTS,
  ACTIVATE_DEALERSHIP_SUCCESS = 1021,
  ACTIVATE_DEALERSHIP_FAILED = 1022,
  COMMENT_CREATED = 1000,
  ORDER_SYNCHRONIZATION_FAILED = 1017,
}

export enum NotificationVariationEnums {
  COMMENT_CREATED = 1000,
  COLLABORATOR_ADDED = 1001,
  ASSIGNEE_ADDED = 1003,
  ORDER_CANCELLATION_REQUEST = 1007,
}

export interface INotificationDetals {
  orderId?: string;
  topicId?: string;
  commentId?: string;
  entityId?: string;
  type?: NotificationTypeEnums;
  variation?: NotificationVariationEnums;
  redirectedFromNotif?: boolean;
}

export interface INotification {
  id: string;
  userId: string;
  type: NotificationTypeEnums;
  variation: NotificationVariationEnums;
  title: string;
  content: string;
  isRead: boolean;
  isSeen: boolean;
  createdOnUtc: string;
  details: INotificationDetals;
}
