import { ProductLine } from 'order/interfaces/ProductLine';

import { IFile, IFileData } from 'shared/interface/IFile';

export enum sharedActions {
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  UPLOAD_FILES = 'UPLOAD_FILES',
  DELETE_FILE = 'DELETE_FILE',

  GET_PRODUCT_LINES = 'GET_PRODUCT_LINES',
  SET_PRODUCT_LINES = 'SET_PRODUCT_LINES',
}

export const downloadFile = (
  fileId: string,
  onSuccess: (file: IFileData) => void,
  onFailed: () => void
) => ({
  type: sharedActions.DOWNLOAD_FILE,
  payload: fileId,
  onSuccess,
  onFailed,
});

export const uploadFiles = (
  files: File[],
  onSuccess?: (files: IFile[]) => void,
  onFailed?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: sharedActions.UPLOAD_FILES,
  payload: files,
  onSuccess,
  onFailed,
  loading,
});

export const deleteFile = (
  fileId: string,
  onSuccess?: () => void,
  onFailed?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: sharedActions.DELETE_FILE,
  payload: fileId,
  onSuccess,
  onFailed,
  loading,
});

export const getProductLines = () => ({
  type: sharedActions.GET_PRODUCT_LINES,
});

export const setProductLines = (payload: ProductLine[]) => ({
  type: sharedActions.SET_PRODUCT_LINES,
  payload,
});
