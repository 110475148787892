import {
  AckChangeLogItem,
  AcknowledgmentDifference,
} from 'order/shared/ackchangelog/interface/AckChangeLogItem';

import { PaginatedItems } from 'shared/interface/PaginatedItems';

export enum AckChangeLogActions {
  GET_ACKCHANGELOG = 'GET_ACKCHANGELOG',
  SET_ACKCHANGELOG = 'SET_ACKCHANGELOG',
  GET_ACKCHANGELOG_DIFF = 'GET_ACKCHANGELOG_DIFF',
  SET_ACKCHANGELOG_DIFF = 'SET_ACKCHANGELOG_DIFF',
  RESET_ACKCHANGELOG = 'RESET_ACKCHANGELOG',
}

export interface GetAckChangeLogRequest {
  orderId: string;
  page: number;
  itemsPerPage: number;
  acknowledgementOnly: boolean;
}

export const getAckChangeLog = (
  payload: GetAckChangeLogRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: AckChangeLogActions.GET_ACKCHANGELOG,
  payload,
  loading,
});

export const setAckChangeLog = (
  ackChangeLog: PaginatedItems<AckChangeLogItem> | null,
  append: boolean
) => ({
  type: AckChangeLogActions.SET_ACKCHANGELOG,
  payload: {
    ackChangeLog,
    append,
  },
});

export const getAckChangeLogDiff = (payload: string) => ({
  type: AckChangeLogActions.GET_ACKCHANGELOG_DIFF,
  payload,
});

export const setAckChangeLogDiff = (
  payload: AcknowledgmentDifference[] | null
) => ({
  type: AckChangeLogActions.SET_ACKCHANGELOG_DIFF,
  payload,
});

export const resetAckChangeLog = () => ({
  type: AckChangeLogActions.RESET_ACKCHANGELOG,
});
