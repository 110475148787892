import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LineItem,
  LineItemDetailsImportError,
} from 'order/wizard/orderStyles/interface/LineItem';
import {
  ImportActions,
  setLineItemsForImport,
} from 'order/wizard/import/store/ImportActions';

import {
  GetStyleLineItemsRequest,
  ImportLineItemsRequest,
  MoveLineItemsRequest,
} from 'order/store/orderActions';

import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

function* getStyleLineItems(action: Action<GetStyleLineItemsRequest>) {
  try {
    const { orderId, styleId, searchTerm } = action.payload!;

    const queryParams = new URLSearchParams();

    if (searchTerm) {
      queryParams.append('searchTerm', searchTerm);
    }

    const lineItems: LineItem[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/lineitems`,
      { params: queryParams }
    );

    const notDeletedLineItemsForStyle = lineItems?.filter(
      (lineItem) => !lineItem.isDeleted && lineItem.styleId === styleId
    );

    yield put(setLineItemsForImport(notDeletedLineItemsForStyle));

    if (action.onSuccess) {
      yield call(action.onSuccess, notDeletedLineItemsForStyle);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  } finally {
    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

function* copyLineItems(action: Action<ImportLineItemsRequest>) {
  try {
    const { orderId, newOrderId, lineItemIds, styleId, newStyleId } =
      action.payload!;

    const response: LineItemDetailsImportError[] = yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/lineitems/move/${newOrderId}`,
      { lineItemIds, styleId, newStyleId }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, response);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  } finally {
    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

function* moveLineItems(action: Action<MoveLineItemsRequest>) {
  try {
    const { orderId, lineItemIds, styleId, newStyleId } = action.payload!;

    const response: LineItemDetailsImportError[] = yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/lineitems/move/${orderId}`,
      { lineItemIds, styleId, newStyleId }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, response);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  } finally {
    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

export function* importSagas() {
  yield takeEvery(ImportActions.GET_STYLE_LINE_ITEMS, getStyleLineItems);
  yield takeEvery(ImportActions.COPY_LINE_ITEMS, copyLineItems);
  yield takeEvery(ImportActions.MOVE_LINE_ITEMS, moveLineItems);
}
