import { AnyAction } from 'redux';

import {
  AckChangeLogItem,
  AcknowledgmentDifference,
} from 'order/shared/ackchangelog/interface/AckChangeLogItem';

import { PaginatedItems } from 'shared/interface/PaginatedItems';

import { AckChangeLogActions } from './ackchangelogActions';

interface AckChangeLogInitialState {
  ackChangeLog: PaginatedItems<AckChangeLogItem> | null;
  acknowledgmentDifferences: AcknowledgmentDifference[] | null;
}

const initialState: AckChangeLogInitialState = {
  ackChangeLog: null,
  acknowledgmentDifferences: null,
};

export const ackChangeLogReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AckChangeLogActions.SET_ACKCHANGELOG:
      return {
        ...state,
        ackChangeLog: {
          ...action.payload.ackChangeLog,
          items:
            state.ackChangeLog && action.payload.append
              ? [
                  ...state.ackChangeLog?.items,
                  ...action.payload.ackChangeLog.items,
                ]
              : [...action.payload.ackChangeLog.items],
        } as PaginatedItems<AckChangeLogItem>,
      };
    case AckChangeLogActions.SET_ACKCHANGELOG_DIFF:
      return {
        ...state,
        acknowledgmentDifferences: action.payload as AcknowledgmentDifference[],
      };
    case AckChangeLogActions.RESET_ACKCHANGELOG:
      return {
        ...state,
        ackChangeLog: null,
      };
    default:
      return state;
  }
};
