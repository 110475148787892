import styled, { css } from 'styled-components';
import { FC, MouseEvent } from 'react';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';

import PerFactoryBadges from 'shared/components/PerFactoryBadges';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonIcon } from 'shared/components/Button';
import { H6, PSmall, PLarge } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { silverSandTwo, lynch } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { IModification } from './interface/IModification';
import { ModificationModalStepsEnums } from './enums/ModificationModalStepsEnums';
import ImageBox from '../../../../../shared/components/ImageBox';

import {
  deleteModalModification,
  setSelectedModification,
  updateModificationsModal,
} from './store/lineItemModificationsActions';

interface ModificationBoxProps {
  modification: IModification;
  selected?: boolean;
  disabled?: boolean;
  hoverModeEnabled?: boolean;
  editModeEnabled?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const ModificationBoxActions = styled(Wrapper)`
  background: ${({ theme }) => theme.white};
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 300ms ease;
`;

const additionalHoverCSS = css`
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.lynch};

    ${ModificationBoxActions} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

type ModificationBoxContainerProps = Pick<
  ModificationBoxProps,
  'hoverModeEnabled' | 'editModeEnabled' | 'selected' | 'disabled'
>;

const ModificationBoxContainer = styled.div<ModificationBoxContainerProps>`
  width: 100%;
  padding: 16px 32px 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid
    ${({ selected, theme }) => (selected ? theme.lynch : 'transparent')};
  transition: border-color 300ms ease;
  position: relative;
  ${BoxShadowCSS}
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ hoverModeEnabled, editModeEnabled }) =>
    (hoverModeEnabled || editModeEnabled) && additionalHoverCSS};

  ${({ disabled }) => disabled && `opacity: 0.5; cursor: not-allowed;`}
`;

const CustomWrapper = styled(Wrapper)`
  position: relative;
`;

const QuantityP = styled(PSmall)`
  color: ${({ theme }) => theme.black};
`;

const PriceP = styled(PLarge)`
  color: ${({ theme }) => theme.silverSandTwo};
`;

export const ModificationBox: FC<ModificationBoxProps> = ({
  modification,
  selected,
  disabled,
  hoverModeEnabled,
  editModeEnabled,
  onClick,
}) => {
  const dispatch = useAppDispatch();

  const onEditModClickHandler = () => {
    dispatch(setSelectedModification(modification));

    dispatch(
      updateModificationsModal(
        ModificationModalStepsEnums.UPDATE_MODIFICATION,
        ModificationModalStepsEnums.UPDATE_MOD_TITLE,
        ModificationModalStepsEnums.UPDATE_MOD_DESC
      )
    );
  };

  const onDeleteModClickHandler = () => {
    dispatch(deleteModalModification(modification.id!));
  };

  return (
    <ModificationBoxContainer
      hoverModeEnabled={hoverModeEnabled}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      <Wrapper flex between>
        <Wrapper flex middle>
          <Spacer w="16px" />
          <ImageBox imageUrl={modification?.imageUrl} />
          <Spacer w="16px" />
          <Wrapper flex column>
            <H6 textTransform="uppercase">{modification.name}</H6>
            <PSmall textTransform="capitalize">
              {modification.description.toLowerCase()}
            </PSmall>
          </Wrapper>
          <Spacer w="16px" />
        </Wrapper>

        <CustomWrapper flex middle>
          <QuantityP>{modification.quantity}</QuantityP>
          <Spacer w="27px" />

          {editModeEnabled && (
            <ModificationBoxActions flex justifyEnd middle>
              <ButtonIcon onClick={onEditModClickHandler}>
                <SVG
                  icon={<EditIcon />}
                  color={silverSandTwo}
                  hoverColor={lynch}
                />
              </ButtonIcon>
              <Spacer w="18px" />
              <ButtonIcon onClick={onDeleteModClickHandler}>
                <SVG
                  icon={<TrashIcon />}
                  color={silverSandTwo}
                  hoverColor={lynch}
                />
              </ButtonIcon>
            </ModificationBoxActions>
          )}

          <PriceP>
            ${UtilService.withDecimal(null, modification.price.toString())}
          </PriceP>

          <Spacer w="19px" />

          <PerFactoryBadges
            perDrawing={modification.perDrawing}
            perFactoryDrawing={modification.perFactoryDrawing}
          />
        </CustomWrapper>
      </Wrapper>
    </ModificationBoxContainer>
  );
};

ModificationBox.defaultProps = {
  selected: false,
  hoverModeEnabled: false,
  disabled: false,
  editModeEnabled: false,
  onClick: undefined,
};
