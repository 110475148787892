import styled from 'styled-components';

interface FormProps {
  maxWidth?: number;
  width?: string;
}

export const Form = styled.form<FormProps>`
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth}px;
    margin: 0 auto;
  `}

  ${({ width }) => width && `width: ${width};`}
`;
