import styled from 'styled-components';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { Wrapper } from './Wrapper';

export const Divider = styled.div`
  height: 1px;
  border-radius: 1px;
  background: ${({ theme }) => theme.mischka};
`;

interface SpacerProps {
  h?: string;
  w?: string;
}

export const Spacer = styled.span<SpacerProps>`
  display: block;
  ${({ h }) => h && `min-height: ${h};`};
  ${({ w }) => w && `min-width: ${w};`};
`;

interface WhiteBoxProps {
  padding: string;
}

export const WhiteBox = styled(Wrapper).attrs(() => ({
  tabIndex: '-1',
  className: 'white-box',
}))<WhiteBoxProps>`
  ${BoxShadowCSS};
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
  padding: ${({ padding }) => padding ?? '12px 10px'};
`;

export const ContentWrapper = styled.div`
  padding: 16px;
`;
