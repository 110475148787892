import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { User } from 'shared/interface/User';
import {
  AddCSRUserDataProps,
  AddUserDataProps,
} from '../interfaces/AddUserFormProps';
import { EditCurrentUserDataProps } from '../interfaces/EditCurrentUserFormProps';

export enum manageUsersActions {
  GET_USER_LIST = 'GET_USER_LIST',
  SET_USER_LIST = 'SET_USER_LIST',

  ADD_NEW_USER = 'ADD_NEW_USER',

  GET_USER = 'GET_USER',
  SET_USER = 'SET_USER',

  EDIT_USER = 'EDIT_USER',

  ENABLE_USER = 'ENABLE_USER',

  UPDATE_USER_ENABLED_DISABLED_STATUS = 'UPDATE_USER_ENABLED_DISABLED_STATUS',
  SORT_USER_LIST = 'SORT_USER_LIST',
  GET_CSR_USER_LIST = 'GET_CSR_USER_LIST',

  ADD_NEW_CSR_USER = 'ADD_NEW_CSR_USER',
}

export interface GetUserRequest {
  userId: string;
}

export const getUser = (payload: GetUserRequest) => ({
  type: manageUsersActions.GET_USER,
  payload,
});

export const setUser = (user: User | null) => ({
  type: manageUsersActions.SET_USER,
  payload: user,
});

export interface UpdateUserRequest extends EditCurrentUserDataProps {
  userId: string;
}

export const updateUser = (
  user: UpdateUserRequest,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: manageUsersActions.EDIT_USER,
  payload: user,
  onSuccess,
  onFailed,
  loading,
});

export const getCSRUserList = (loading?: (isLoading: boolean) => void) => ({
  type: manageUsersActions.GET_CSR_USER_LIST,
  loading,
});

export const addNewUser = (
  payload: AddUserDataProps,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: manageUsersActions.ADD_NEW_USER,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const addNewCSRUser = (
  payload: AddCSRUserDataProps,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: manageUsersActions.ADD_NEW_CSR_USER,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const getUserList = (
  dealershipId: string,
  onSuccess: (users: User[]) => void,
  onFailed: (error: ServerErrorResponse) => void
) => ({
  type: manageUsersActions.GET_USER_LIST,
  payload: dealershipId,
  onSuccess,
  onFailed,
});
