export enum CsrTabsEnum {
  'Not Submitted' = 1,
  'Orders to Write',
  'Orders to 2nd Check',
  'Orders to Draw',
  'Orders to Acknowledge',
  'Orders to Approve',
  'Approved Orders',
  'All Orders',
}
