import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Keycloak from 'keycloak-js';
import styled from 'styled-components';

import { getUserSagaAction } from 'auth/store/authActions';

import { H4 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Token } from 'shared/interface/Token';
import { useBrowserStorage } from 'shared/hooks/useBrowserStorage';
import { useLogout } from 'shared/hooks/useLogout';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

import env from '../../env_config';

const Span = styled(Wrapper)`
  height: 100vh;
`;

export const KeyCloakLogin = () => {
  // @ts-ignore
  const [keycloak, setKeycloak] = useState<Keycloak.KeycloakInstance>(null);

  const { setItem } = useBrowserStorage({
    key: 'token',
    storageType: 'localStorage',
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useLogout();

  const onGetUserSuccess = () => {
    history.push('/overview');
  };

  useEffect(() => {
    const kc = Keycloak(`../resources/${env.keycloakSettings}`);
    setKeycloak(kc);
  }, []);

  useEffect(() => {
    if (keycloak) {
      keycloak
        .init({ onLoad: 'login-required' })
        .success(() => {
          const token: Token = {
            access_token: keycloak.token!,
            refresh_token: keycloak.refreshToken!,
          };

          setItem(token);
          dispatch(getUserSagaAction(onGetUserSuccess));
        })
        .error(() => {
          logout();
        });
    }
  }, [keycloak]);

  return (
    <Span flex middle center>
      <div>
        <Loader size={50} platoLogo={1} />
        <Spacer h="5px" />
        <H4>You are being redirected...</H4>
      </div>
    </Span>
  );
};
