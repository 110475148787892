import { FC, useState } from 'react';
import { kashmirBlue, silverChaliceTwo } from 'shared/config/Colors';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import { CabinetBoxMaterialOptionsEnum } from 'order/wizard/orderStyles/enums/CabinetBoxMaterialOptionsEnum';
import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';

import { H5, H6, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import StyleDetailsField from './StyleDetailsField';
import { DoorCodesTableRow, DoorCodesTableRowContent } from './DoorCodesTable';
import { StyleDetailsFieldWrapper } from './StyleDetailsFieldWrapper';
import { ExpandButton } from '../OrderStyleContainers';

interface OrderStyleDetailsProps {
  styleDetails: Style;
  highlight?: boolean;
}

const OrderStyleDetails: FC<OrderStyleDetailsProps> = ({
  styleDetails,
  highlight,
}) => {
  const config = useProductLineConfiguration(styleDetails.productLine);

  const { highlightStyleField, highlightDeletedStyleField } =
    useHighlightHelper(highlight);

  const [doorExpandebleOpen, setDoorExpandebleOpen] = useState(true);
  const [hardwareExpandableOpen, setHardwareExpandableOpen] = useState(true);
  const [finsihExpandableOpen, setFinishExpandableOpen] = useState(true);
  const [woodTypeExpandableOpen, setWoodTypeExpandableOpen] = useState(true);

  return (
    <>
      <Spacer h="16px" />
      {styleDetails.originalWorkOrderNumber && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="W.O.#"
            fieldName={FieldName.OrderWorkOrderNumber}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.originalWorkOrderNumber}
          />
          <Spacer h="16px" />
        </Wrapper>
      )}

      {styleDetails.note && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="STYLE NOTES"
            fieldName={FieldName.StyleNote}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.note}
            asNote
          />
          <Spacer h="32px" />
        </Wrapper>
      )}

      <StyleDetailsFieldWrapper flex between middle>
        <Wrapper flex gap={5}>
          <ExpandButton
            onClick={() => setWoodTypeExpandableOpen(!woodTypeExpandableOpen)}
            iconColor={silverChaliceTwo}
            hoverIconColor={kashmirBlue}
            className={
              woodTypeExpandableOpen
                ? 'expandable-box-button open'
                : 'expandable-box-button'
            }
          >
            <ArrowUpIcon />
          </ExpandButton>
          <H5>{config.styleSpecifications.woodTypeLabel.toUpperCase()}</H5>
        </Wrapper>
      </StyleDetailsFieldWrapper>
      {woodTypeExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            {styleDetails.woodMaterial?.name && (
              <StyleDetailsField
                fieldLabel={config.styleSpecifications.woodLabel.toUpperCase()}
                fieldName={FieldName.StyleWoodMaterial}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.woodMaterial?.name}
              />
            )}

            {!!styleDetails.woodEffects.length && (
              <StyleDetailsField
                fieldLabel="WOOD EFFECT(S)"
                fieldName={FieldName.StyleWoodEffect}
                highlightStyleField={highlightStyleField}
                highlightDeletedStyleField={highlightDeletedStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.woodEffects
                  .reduce((agg, woodEffect) => {
                    agg.push(woodEffect.name ?? '');
                    return agg;
                  }, [] as string[])
                  .filter((x) => x)
                  .join(', ')}
              />
            )}
          </Wrapper>

          {config.styleSpecifications.showEngineeredCheckbox && (
            <>
              <Spacer h="16px" />

              <StyleDetailsField
                fieldLabel="ENGINEERED FURNITURE BOARD CONSTRUCTION"
                fieldName={FieldName.StyleEngineeredFurnitureBoardConstruction}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={
                  styleDetails.engineeredFurnitureBoardConstruction
                    ? 'ON'
                    : 'OFF'
                }
              />
            </>
          )}

          {(config.styleSpecifications.showWoodInterior ||
            config.styleSpecifications.showSelectWoodSpecies) &&
            (styleDetails.woodInterior?.name ||
              styleDetails.selectWoodSpecies) && (
              <>
                <Spacer h="16px" />

                <Wrapper flex>
                  {config.styleSpecifications.showWoodInterior &&
                    styleDetails.woodInterior?.name && (
                      <StyleDetailsField
                        fieldLabel="WOOD INTERIOR"
                        fieldName={FieldName.StyleWoodInterior}
                        highlightStyleField={highlightStyleField}
                        styleId={styleDetails.id!}
                        fieldValue={styleDetails.woodInterior?.name}
                      />
                    )}

                  {config.styleSpecifications.showSelectWoodSpecies &&
                    styleDetails.selectWoodSpecies && (
                      <StyleDetailsField
                        fieldLabel="SELECT WOOD SPECIES"
                        fieldName={FieldName.StyleSelectWoodSpecies}
                        highlightStyleField={highlightStyleField}
                        styleId={styleDetails.id!}
                        fieldValue={styleDetails.selectWoodSpecies}
                      />
                    )}
                </Wrapper>
              </>
            )}

          {config.styleSpecifications.showCabinetBoxMaterial &&
            (styleDetails.cabinetBoxMaterial?.name ||
              styleDetails.specialCabinetBoxMaterial) && (
              <>
                <Spacer h="16px" />

                <Wrapper flex>
                  {styleDetails.cabinetBoxMaterial?.name && (
                    <StyleDetailsField
                      fieldLabel="CABINET BOX MATERIAL"
                      fieldName={FieldName.StyleCabinetBoxMaterial}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.cabinetBoxMaterial?.name}
                    />
                  )}

                  {styleDetails.cabinetBoxMaterial?.name ===
                    CabinetBoxMaterialOptionsEnum.SPECIAL &&
                    styleDetails.specialCabinetBoxMaterial && (
                      <StyleDetailsField
                        fieldLabel="SPECIAL CABINET BOX MATERIAL"
                        fieldName={FieldName.StyleSpecialCabinetBoxMaterial}
                        highlightStyleField={highlightStyleField}
                        styleId={styleDetails.id!}
                        fieldValue={styleDetails.specialCabinetBoxMaterial}
                      />
                    )}
                </Wrapper>
                <Spacer h="16px" />
              </>
            )}

          <Wrapper flex>
            {config.styleSpecifications.showEdgebandColor &&
              styleDetails.edgebandColor && (
                <>
                  <StyleDetailsField
                    fieldLabel="EDGE BAND COLOR"
                    fieldName={FieldName.StyleEdgebandColor}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.edgebandColor}
                  />
                </>
              )}
          </Wrapper>

          {styleDetails.woodNotes && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="WOOD NOTES"
                fieldName={FieldName.StyleWoodNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.woodNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}

      <Spacer h="32px" />
      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setFinishExpandableOpen(!finsihExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            finsihExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>FINISH</H5>
      </Wrapper>
      {finsihExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            <OrderFinishLabel
              style={styleDetails}
              render={(label) => (
                <OrderFinishValue
                  style={styleDetails}
                  defaultValue=""
                  render={(value) => (
                    <StyleDetailsField
                      fieldLabel={label.toUpperCase()}
                      fieldName={FieldName.StyleFinishColor}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={value}
                    />
                  )}
                />
              )}
            />

            {styleDetails.varnish?.name && (
              <>
                <StyleDetailsField
                  fieldLabel="VARNISH SHEEN"
                  fieldName={FieldName.StyleVarnish}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.varnish?.name}
                />
              </>
            )}
          </Wrapper>

          {(!!styleDetails.finishEffects.length ||
            styleDetails.specialFinishSample) && <Spacer h="16px" />}

          <Wrapper flex>
            {!!styleDetails.finishEffects.length && (
              <>
                <StyleDetailsField
                  fieldLabel="FINISH EFFECT(S)"
                  fieldName={FieldName.StyleFinishEffect}
                  highlightStyleField={highlightStyleField}
                  highlightDeletedStyleField={highlightDeletedStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.finishEffects
                    .reduce((agg, finishEffect) => {
                      agg.push(finishEffect.name ?? '');
                      return agg;
                    }, [] as string[])
                    .filter((x) => x)
                    .join(', ')}
                />
              </>
            )}
            {config.styleSpecifications.showSpecialFinishSample &&
              styleDetails.specialFinishSample && (
                <>
                  <StyleDetailsField
                    fieldLabel="SPECIAL FINISH SAMPLE"
                    fieldName={FieldName.StyleSpecialFinishSample}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.specialFinishSample}
                  />
                </>
              )}
          </Wrapper>

          <Wrapper flex>
            {/* {(styleDetails.vendorColor.name ||
              styleDetails.vendorColor.code) && <Spacer h="16px" />} */}
            {styleDetails.vendorColor?.name && (
              <StyleDetailsField
                fieldLabel="VENDOR COLOR NAME"
                fieldName={FieldName.StyleVendorColorName}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.vendorColor?.name}
              />
            )}
            {styleDetails.vendorColor?.code && (
              <StyleDetailsField
                fieldLabel="VENDOR COLOR CODE"
                fieldName={FieldName.StyleVendorColorCode}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.vendorColor?.code}
              />
            )}
          </Wrapper>

          {styleDetails.finishNotes && (
            <>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="FINISH NOTES"
                fieldName={FieldName.StyleFinishNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.finishNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}

      <Spacer h="32px" />

      <Wrapper flex>
        <StyleDetailsFieldWrapper>
          <Wrapper flex gap={5}>
            <ExpandButton
              onClick={() => setDoorExpandebleOpen(!doorExpandebleOpen)}
              iconColor={silverChaliceTwo}
              hoverIconColor={kashmirBlue}
              className={
                doorExpandebleOpen
                  ? 'expandable-box-button open'
                  : 'expandable-box-button'
              }
            >
              <ArrowUpIcon />
            </ExpandButton>
            <H5>DOOR &amp; DRAWER FRONT</H5>
          </Wrapper>
          {doorExpandebleOpen && (
            <Wrapper style={{ marginLeft: '25px' }}>
              <Spacer h="32px" />

              {styleDetails.doorOverlay?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DOOR OVERLAY"
                    fieldName={FieldName.StyleDoorOverlay}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.doorOverlay?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {config.doorBuilder.showFaceFrame &&
                styleDetails.faceFrame?.name && (
                  <>
                    <StyleDetailsField
                      fieldLabel="FACE FRAME"
                      fieldName={FieldName.StyleFaceFrame}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.faceFrame?.name}
                    />

                    <Spacer h="16px" />
                  </>
                )}

              {styleDetails.frameStyle?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="FRAME STYLE"
                    fieldName={FieldName.StyleFrameStyle}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.frameStyle?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {config.doorBuilder.showOneInchDoor && (
                <>
                  <StyleDetailsField
                    fieldLabel='1" DOOR'
                    fieldName={FieldName.StyleOneInchDoor}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.oneInchDoor ? 'YES' : 'NO'}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {config.doorBuilder.showNoMidrails && (
                <>
                  <StyleDetailsField
                    fieldLabel="MIDRAILS"
                    fieldName={FieldName.StyleNoMidrails}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={
                      styleDetails.noMidrails ? 'No Midrails' : 'Midrails'
                    }
                  />

                  <Spacer h="16px" />
                </>
              )}

              {config.doorBuilder.showMetalFinish &&
                styleDetails.metalFinish?.name && (
                  <>
                    <StyleDetailsField
                      fieldLabel="METAL FINISH"
                      fieldName={FieldName.StyleMetalFinish}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.metalFinish?.name}
                    />
                    <Spacer h="16px" />
                  </>
                )}

              {config.doorBuilder.showDrawerTopPanel &&
                styleDetails.topInsertPanel?.name && (
                  <>
                    <StyleDetailsField
                      fieldLabel="TOP DRAWER INSERT PANEL"
                      fieldName={FieldName.StyleTopInsertPanel}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.topInsertPanel?.name}
                    />
                    <Spacer h="16px" />
                  </>
                )}

              {styleDetails.drawerBox?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DRAWER BOX"
                    fieldName={FieldName.StyleDrawerBox}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.drawerBox?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              <StyleDetailsField
                fieldLabel="HDF PANELS"
                fieldName={FieldName.StyleHdfPanels}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hdfPanels === false ? 'NO' : 'YES'}
              />

              {config.doorBuilder.showOneInchFaceFrame && (
                <>
                  <Spacer h="16px" />
                  <StyleDetailsField
                    fieldLabel='1" FACE FRAME'
                    fieldName={FieldName.StyleOneInchFaceFrame}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={
                      styleDetails.oneInchFaceFrame === false ? 'NO' : 'YES'
                    }
                  />
                </>
              )}

              {config.doorBuilder.showToeSpaceType &&
                styleDetails.toeSpaceType?.name && (
                  <>
                    <Spacer h="16px" />
                    <StyleDetailsField
                      fieldLabel="TOE SPACE TYPE"
                      fieldName={FieldName.StyleToeSpaceType}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.toeSpaceType?.name}
                    />
                  </>
                )}
            </Wrapper>
          )}
        </StyleDetailsFieldWrapper>

        <Wrapper flex column>
          <DoorCodesTableRow flex between>
            <div />
            <DoorCodesTableRowContent flex between>
              <H5>WALL</H5>
              <Spacer w="15px" />
              <H5>BASE</H5>
              <Spacer w="15px" />
              <H5>DRAWER</H5>
            </DoorCodesTableRowContent>
          </DoorCodesTableRow>

          {doorExpandebleOpen && (
            <>
              <Spacer h="32px" />

              <DoorCodesTableRow flex between>
                <H6>DOOR STYLE</H6>
                <DoorCodesTableRowContent flex between>
                  {styleDetails.wallDoorStyle?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleWallDoorStyle,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.wallDoorStyle?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.baseDoorStyle?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleBaseDoorStyle,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.baseDoorStyle?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.drawerFrontStyle?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleDrawerStyle,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.drawerFrontStyle?.name}
                    </PSmall>
                  )}
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>CONFIGURATION</H6>
                <DoorCodesTableRowContent flex between>
                  {styleDetails.wallDoorConfiguration?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleWallDoorConfiguration,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.wallDoorConfiguration?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.baseDoorConfiguration?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleBaseDoorConfiguration,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.baseDoorConfiguration?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  <PSmall />
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>INSERT PANEL</H6>
                <DoorCodesTableRowContent flex between>
                  {styleDetails.wallDoorInsertPanel?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleWallDoorInsertPanel,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.wallDoorInsertPanel?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.baseDoorInsertPanel?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleBaseDoorInsertPanel,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.baseDoorInsertPanel?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.drawerInsertPanel?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleDrawerInsertPanel,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.drawerInsertPanel?.name}
                    </PSmall>
                  )}
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              <Spacer h="16px" />
              <DoorCodesTableRow flex between>
                <H6>FRAME SIZE</H6>
                <DoorCodesTableRowContent flex between>
                  {styleDetails.customFrameSize ? (
                    <PSmall wordBreak="break-all">
                      {styleDetails?.productLine.name ===
                      ProductLineEnums.PRODUCT_LINE_PRELUDE
                        ? `${styleDetails?.customFrameSize} + $21`
                        : styleDetails?.customFrameSize.toString()}
                    </PSmall>
                  ) : (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleFrameSize,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails?.frameSize?.upcharge
                        ? `${styleDetails?.frameSize?.size} + ${styleDetails?.frameSize?.upcharge}`
                        : styleDetails?.frameSize?.size?.toString()}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  <PSmall />

                  <Spacer w="15px" />

                  <PSmall />
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>

              {styleDetails.archStyle?.name && (
                <>
                  <Spacer h="16px" />

                  <DoorCodesTableRow flex between>
                    <H6>ARCH STYLE</H6>
                    <DoorCodesTableRowContent flex between>
                      <PSmall
                        className={highlightStyleField(styleDetails.id!, [
                          FieldName.StyleArchStyle,
                        ])}
                        wordBreak="break-all"
                      >
                        {styleDetails.archStyle?.name}
                      </PSmall>

                      <Spacer w="15px" />

                      <PSmall />

                      <Spacer w="15px" />

                      <PSmall />
                    </DoorCodesTableRowContent>
                  </DoorCodesTableRow>
                </>
              )}

              <Spacer h="16px" />

              <DoorCodesTableRow flex between>
                <H6>DOOR EDGE</H6>
                <DoorCodesTableRowContent flex between>
                  {styleDetails.wallDoorEdge?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleWallDoorEdge,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.wallDoorEdge?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.baseDoorEdge?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleBaseDoorEdge,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.baseDoorEdge?.name}
                    </PSmall>
                  )}

                  <Spacer w="15px" />

                  {styleDetails.drawerEdge?.name && (
                    <PSmall
                      className={highlightStyleField(styleDetails.id!, [
                        FieldName.StyleDrawerEdge,
                      ])}
                      wordBreak="break-all"
                    >
                      {styleDetails.drawerEdge?.name}
                    </PSmall>
                  )}
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>
            </>
          )}
        </Wrapper>
      </Wrapper>

      {styleDetails.doorNotes && doorExpandebleOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="16px" />

          <StyleDetailsField
            fieldLabel="DOOR NOTES"
            fieldName={FieldName.StyleDoorNotes}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.doorNotes}
            asNote
          />
        </Wrapper>
      )}

      <Spacer h="32px" />
      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setHardwareExpandableOpen(!hardwareExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            hardwareExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>HARDWARE</H5>
      </Wrapper>
      {hardwareExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            {styleDetails.doorHardware?.name && (
              <StyleDetailsField
                fieldLabel="DOOR HARDWARE"
                fieldName={FieldName.StyleDoorHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.doorHardware?.name}
              />
            )}

            {styleDetails.hingeType?.name && (
              <StyleDetailsField
                fieldLabel="HINGE TYPE"
                fieldName={FieldName.StyleHingeType}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeType?.name}
              />
            )}
          </Wrapper>

          {(styleDetails.doorHardware?.name ||
            styleDetails.hingeType?.name) && <Spacer h="16px" />}

          <Wrapper flex>
            {styleDetails.drawerHardware?.name && (
              <StyleDetailsField
                fieldLabel="DRAWER HARDWARE"
                fieldName={FieldName.StyleDrawerHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.drawerHardware?.name}
              />
            )}

            {styleDetails.hingeColor?.name && (
              <StyleDetailsField
                fieldLabel="HINGE COLOR"
                fieldName={FieldName.StyleHingeColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeColor?.name}
              />
            )}
          </Wrapper>

          {config.doorBuilder.showClosetHardwareColor &&
            styleDetails.closetHardwareColor?.name && (
              <>
                <Spacer h="16px" />

                <StyleDetailsField
                  fieldLabel="CLOSET HARDWARE COLOR"
                  fieldName={FieldName.StyleClosetHardwareColor}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.closetHardwareColor?.name}
                />
              </>
            )}

          {styleDetails.hardwareNotes && (
            <>
              <Spacer h="16px" />

              <StyleDetailsField
                fieldLabel="HARDWARE NOTES"
                fieldName={FieldName.StyleHardwareNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hardwareNotes}
                asNote
              />
            </>
          )}
        </Wrapper>
      )}
      <Spacer h="16px" />
    </>
  );
};

export default OrderStyleDetails;
