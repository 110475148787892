import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { OrderFormFields } from 'order/wizard/orderForm/OrderForm';

import { Checkbox } from 'shared/components/Checkbox';
import { FormDatePicker } from 'shared/components/FormDatePicker';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { globalDateFormat } from 'shared/config/Variables';

import { silverSandTwo } from '../../../shared/config/Colors';

const RedTagWrapper = styled(Wrapper)`
  max-width: 325px;
  padding-left: 25px;
`;

export const OrderScheduling = () => {
  const startDate = useSelector(
    (state: RootState) => state.orderReducer.order?.startDate
  );

  const methods = useFormContext<OrderFormFields>();

  return (
    <div>
      <FormElement>
        {startDate && (
          <>
            <FormLabel>Start Date</FormLabel>

            <Controller
              control={methods.control}
              name="startDate"
              rules={{
                required: {
                  message: 'Start Date is required.',
                  value: true,
                },
              }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <FormDatePicker
                    name={name}
                    onChange={(date) => onChange(date?.toISOString())}
                    value={value}
                    placeholder="Select Start Date..."
                    error={!!methods.formState.errors.startDate}
                    format={globalDateFormat}
                  />
                );
              }}
            />
          </>
        )}
      </FormElement>

      <FormElement>
        <FormLabel>Ship Week</FormLabel>
        <Input
          type="text"
          data-test="input-ship-week"
          {...methods.register('shipWeek')}
          disabled
        />
      </FormElement>

      <FormElement>
        <FormLabel>Target Date for Writing</FormLabel>

        <Input
          type="text"
          data-test="input-target-date-writing"
          {...methods.register('writingDate')}
          disabled
        />
      </FormElement>

      <FormElement>
        <FormLabel>Target Date for 2nd Check</FormLabel>

        <Input
          type="text"
          data-test="input-target-date-second-check"
          {...methods.register('secondCheckDate')}
          disabled
        />
      </FormElement>

      <FormElement>
        <FormLabel>Target Date for Drafting</FormLabel>

        <Input
          type="text"
          data-test="input-target-date-drafting"
          {...methods.register('draftingDate')}
          disabled
        />
      </FormElement>

      <FormElement>
        <FormLabel>Target Date for Acknowledgment</FormLabel>

        <Input
          type="text"
          data-test="input-target-date-acknowledgment"
          {...methods.register('acknowledgementDate')}
          disabled
        />
      </FormElement>

      <Wrapper flex column>
        <Wrapper flex>
          <Checkbox
            {...methods.register('redTag')}
            id="redTag"
            title="Is Red Tag"
            readOnly
          />
        </Wrapper>

        <Spacer h="10px" />

        <RedTagWrapper flex>
          <PSmall color={silverSandTwo}>
            When selected, this mark will indicate there are some ship week and
            target dates deviations.
          </PSmall>
        </RedTagWrapper>
      </Wrapper>
    </div>
  );
};
