import { FC } from 'react';
import styled from 'styled-components';

import {
  SpecialOrderModification,
  SpecialOrderDrawing,
} from 'order/writingHints/interface/WritingHints';

import { nevada } from 'shared/config/Colors';
import { PSmall } from 'shared/components/Typography';

import { ItemRow } from '../../ItemRow';
import UtilService from '../../../../../../shared/services/util.service';

const DrawingsContainer = styled.div``;

interface DrawingProps {
  index?: number;
}

const Drawing: FC<DrawingProps> = ({ index, children }) => {
  return (
    <ItemRow>
      <PSmall color={nevada}>{index}</PSmall>
      <PSmall color={nevada}>{children}</PSmall>
    </ItemRow>
  );
};

interface DrawingsProps {
  drawings: SpecialOrderDrawing[];
}

const Drawings: FC<DrawingsProps> = ({ drawings }) => {
  const getPerFactoryLabel = (
    drawing: SpecialOrderDrawing | SpecialOrderModification
  ) => {
    const drawingFlags = [
      drawing.perFactoryDrawing ? 'pf' : undefined,
      drawing.perDrawing ? 'pd' : undefined,
    ].filter((x) => x);
    return drawingFlags?.length > 0 ? `${drawingFlags.join(', ')}, ` : '';
  };

  return (
    <DrawingsContainer>
      <ItemRow>
        <PSmall bold>#</PSmall>
        <PSmall>Drawings</PSmall>
      </ItemRow>

      {drawings.map((drawing, index) => (
        <>
          <Drawing index={index + 1}>
            {getPerFactoryLabel(drawing)} #{drawing.number}, {drawing.quantity},{' '}
            {UtilService.generateLineItemNameWithDimensions(drawing)}
          </Drawing>

          {drawing.specialOrderModifications.map((specialMod) => {
            if (!specialMod.perFactoryDrawing && !specialMod.perDrawing) {
              return null;
            }
            return (
              <Drawing>
                {getPerFactoryLabel(specialMod)} #{specialMod.number},{' '}
                {specialMod.quantity}
              </Drawing>
            );
          })}
        </>
      ))}
    </DrawingsContainer>
  );
};

export default Drawings;
