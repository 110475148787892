import { call, put, takeEvery } from 'redux-saga/effects';

import { IShipWeek } from 'overview/interface/IShipWeek';

import {
  FormAndCatalog,
  FormCatalogTypeEnum,
} from 'overview/interface/FormAndCatalog';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';

import { Dealership } from 'shared/interface/Dealership';
import { INotification } from 'overview/interface/Notification';
import {
  FormsAndCatalogsPayload,
  OverviewActions,
  setFormsAndCatalogs,
  setShipWeeks,
  setAllDealerships,
  UpdateIsActiveRequest,
  setActivateDealershipResponse,
} from './overviewActions';

function* getFormsAndCatalogs(action: Action<unknown>) {
  try {
    const formsAndCatalogs: FormAndCatalog[] = yield call(
      ApiService.get,
      `/api/catalog/catalogsandforms`
    );

    const splited: FormsAndCatalogsPayload = {
      catalogs: formsAndCatalogs.filter(
        (formOrCatalog) => formOrCatalog.type === FormCatalogTypeEnum.CATALOG
      ),
      forms: formsAndCatalogs.filter(
        (formOrCatalog) => formOrCatalog.type === FormCatalogTypeEnum.FORM
      ),
    };

    yield put(setFormsAndCatalogs(splited));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getShipWeeks(action: Action<unknown>) {
  try {
    const shipWeeks: IShipWeek[] = yield call(
      ApiService.get,
      `/api/order/orders/ship-weeks`
    );

    yield put(
      setShipWeeks(
        shipWeeks
          .sort((a, b) => {
            const aDate = new Date(a.shipWeekStartDate);
            const bDate = new Date(b.shipWeekStartDate);

            return UtilService.sortBy(aDate.getTime(), bDate.getTime());
          })
          .filter(
            (option) =>
              option.shipWeekDescription.toLocaleLowerCase() !== 'asap' &&
              option.shipWeekDescription.toLocaleLowerCase() !== '000'
          )
      )
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* activateDealership(action: Action<number>) {
  try {
    yield call(ApiService.post, `/api/administration/dealerships/activate`, {
      accountNumber: action.payload!,
    });

    yield put(setActivateDealershipResponse(null));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getAllDealerships(action: Action<unknown>) {
  try {
    const dealerships: Dealership[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/filtered`
    );

    yield put(setAllDealerships(dealerships));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, dealerships);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changeDealershipStatus(action: Action<UpdateIsActiveRequest>) {
  try {
    yield call(
      ApiService.put,
      `/api/administration/dealerships/${action.payload!.dealershipId}/status`,
      {
        isActive: action.payload!.isActive,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* finishActivateDealership(action: Action<INotification>) {
  try {
    yield put(setActivateDealershipResponse(action.payload!));
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* overviewSagas() {
  yield takeEvery(OverviewActions.GET_FORMS_AND_CATALOGS, getFormsAndCatalogs);
  yield takeEvery(OverviewActions.GET_SHIP_WEEKS, getShipWeeks);
  yield takeEvery(OverviewActions.ACTIVATE_DEALERSHIP, activateDealership);
  yield takeEvery(OverviewActions.GET_ALL_DEALERSHIPS, getAllDealerships);
  yield takeEvery(
    OverviewActions.CHANGE_DEALERSHIP_STATUS,
    changeDealershipStatus
  );
  yield takeEvery(
    OverviewActions.FINISH_ACTIVATE_DEALERSHIP,
    finishActivateDealership
  );
}
