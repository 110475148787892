import { FC, HtmlHTMLAttributes } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as PlatoLogo } from 'assets/icons/plato-logo.svg';

import { Position } from 'shared/interface/CSSTypes';
import { white } from 'shared/config/Colors';
import UtilService from 'shared/services/util.service';

import { Wrapper } from './Wrapper';

interface LoaderWrapperProps {
  noSpacing?: boolean;
  insideButton?: boolean;
  platoLogo?: 0 | 1;
}

interface LoaderProps
  extends LoaderWrapperProps,
    HtmlHTMLAttributes<HTMLDivElement> {
  size?: number;
}

enum LoaderEnums {
  proportion = 1.142857143,
}

interface LoaderFullScreenProps {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
  borderRadius?: number;
  position?: Position;
}

export const LoaderFullScreen = styled(Wrapper)<LoaderFullScreenProps>`
  position: ${({ position }) => position ?? 'absolute'};
  background-color: ${UtilService.colorWithOpacity(white, 0.5)};
  z-index: 100;

  top: ${({ top }) => (top ? `${top}px` : '0')};
  right: ${({ right }) => (right ? `${right}px` : '0')};
  left: ${({ left }) => (left ? `${left}px` : '0')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '0')};

  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px`};
`;

export const LoaderWrapper = styled(Wrapper)<LoaderWrapperProps>`
  ${({ noSpacing }) => !noSpacing && 'padding: 30px 0;'}
  transition: opacity 200ms ease;

  ${({ insideButton }) =>
    insideButton &&
    css`
      margin: -8px 0 0 10px;
      transform: translateY(4px);
    `};

  &[hidden] {
    display: none;
  }
`;

const LoaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loaderPlatoAnimation = keyframes`
  50% {
		transform: scale(1.1);
		opacity: 1;
	}

	100% {
		transform: scale(1);
		opacity: 0.5;
	}
`;

const LoaderElement = styled.div<LoaderProps>`
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  position: relative;

  &::before,
  &::after {
    bottom: 0;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    border-radius: 50%;
  }

  &::before {
    border: ${({ size }) => (size! * 1.5) / 12}px solid
      ${({ theme }) => theme.periwinkleGray};
  }

  &::after {
    border-color: transparent transparent ${({ theme }) => theme.lynch}
      transparent;
    border-style: solid;
    border-width: ${({ size }) => (size! * 1.5) / 12}px;

    animation: ${LoaderAnimation} 1s infinite linear;
  }
`;

const LoaderPlatoElement = styled(PlatoLogo)<LoaderProps>`
  animation: ${loaderPlatoAnimation} 4s ease infinite;
  transform-origin: center;
  opacity: 0.5;
  width: ${({ size }) => size}px;
  height: ${({ size }) => `${Math.floor(size! * LoaderEnums.proportion)}`}px;
`;

const Loader: FC<LoaderProps> = ({
  size,
  noSpacing,
  platoLogo,
  hidden,
  insideButton,
}) => {
  return (
    <LoaderWrapper
      className="loader"
      hidden={hidden}
      data-test="loader"
      noSpacing={noSpacing}
      insideButton={insideButton}
      flex
      center
    >
      {platoLogo ? (
        <LoaderPlatoElement size={size} />
      ) : (
        <LoaderElement size={size} />
      )}
    </LoaderWrapper>
  );
};

Loader.defaultProps = {
  size: 30,
};

LoaderWrapper.defaultProps = {
  noSpacing: false,
  insideButton: false,
  platoLogo: 0,
};

export default Loader;
