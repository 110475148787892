import { Redirect, Route } from 'react-router';

export const RenderPageNotFound = () => {
  return (
    <Route
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/page-not-found',
            state: { originalUrl: location.pathname },
          }}
        />
      )}
    />
  );
};
