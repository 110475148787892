import { FC, useState, useEffect, useContext } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { Collaborator } from 'order/interfaces/Collaborator';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import {
  changeCollaboratorType,
  deselectCollaborator,
  removeOrderCollaborator,
  setCurrentUserCollaboratorAccess,
} from 'order/store/orderActions';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { User } from 'shared/interface/User';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { UserRole } from 'shared/interface/UserRole';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import UtilService from 'shared/services/util.service';

import { ButtonIcon } from './Button';
import { ConfirmationModal } from './ConfirmationModal';
import { RoleLabel } from './RoleLabel';
import { Select } from './Select';

interface UserInfoProps {
  user: User | Collaborator;
  disabled: boolean;
}

const UserInfoWrapper = styled(Wrapper)`
  width: 100%;
`;

const Name = styled.div`
  font-weight: 600;
  flex: 1;
`;

const CollaboratorAccessLabel = styled.div`
  margin-left: 15px;
`;

const CollaboratorActions = styled(Wrapper)`
  margin-left: 20px;
  width: 145px;

  .collaborator-type-select {
    width: 110px;
  }
`;

// eslint-disable-next-line
function instanceOfCollaborator(data: any): data is Collaborator {
  return 'collaboratorType' in data;
}

const UserInfo: FC<UserInfoProps> = ({ user, disabled }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { onRemovedMyselfAsCollaborator, handleChangeCallbackWrapper } =
    useContext(OrderFormContext);

  const loggedInUser = useSelector(
    (state: RootState) => state.authReducer.user
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isOrderNotSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
  });

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  // const isUserSalesRep = useHasPermissions(
  //   userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  // );

  // only delaer user cannot update another user because he is lower rank then dealer admin
  // thats why i chose this permission to diffirantiate dealer user from dealer admin
  const isUserDealerUser = useHasPermissions(userPermissionsValues.USER_UPDATE);

  // edit mode
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const [defaultTypeOption, setDefaultTypeOption] =
    useState<SelectOptionProps | null>(null);

  const [removeMeModal, setRemoveMeModal] = useState(false);

  const isMe = user?.id === loggedInUser?.id;

  const collaboratorTypeOptions: SelectOptionProps[] = [
    {
      value: collaboratorAccessEnums.OWNER.toString(),
      label: collaboratorAccessEnums.OWNER_NAME as string,
      isDisabled: true,
    },
    {
      value: collaboratorAccessEnums.EDITOR.toString(),
      label: collaboratorAccessEnums.EDITOR_NAME as string,
    },
    {
      value: collaboratorAccessEnums.VIEWER.toString(),
      label: collaboratorAccessEnums.VIEWER_NAME as string,
    },
  ];

  const onDeselectCollaboratorHandler = () => {
    dispatch(deselectCollaborator(user.id));

    if (isMe && editMode && canEdit && (isUserCSR || !isUserDealerUser)) {
      onRemovedMyselfAsCollaborator!(true);
    }
  };

  const onCollaboratorTypeChangeHandler = (
    option: SelectOptionProps,
    collaboratorId: string
  ) => {
    dispatch(
      changeCollaboratorType({
        type: +option.value,
        collaboratorId,
      })
    );
  };

  const onRemoveMeModalOpenHandler = () => {
    setRemoveMeModal(true);
  };

  const handleCollaboratorAccess = () => {
    if (isUserDealerOrSalesRep) {
      history.push('/overview/dashboard', location.state);
    }

    if (isUserCSR && isOrderNotSubmitted) {
      dispatch(
        setCurrentUserCollaboratorAccess({
          isCurrentUserEditor: false,
          isCurrentUserOwner: false,
          isCurrentUserViewer: false,
        })
      );
    }
  };

  const onRemoveMeAsCollaboratorClickHandler = (close: () => void) => {
    UtilService.onPopupClose();
    close();

    dispatch(
      removeOrderCollaborator({ orderId, userId: user.id }, () => {
        setRemoveMeModal(false);
        toast.success('You have successfully removed yourself from the order.');

        handleCollaboratorAccess();
      })
    );
  };

  useEffect(() => {
    if (instanceOfCollaborator(user)) {
      const defaultOption = collaboratorTypeOptions.find(
        (ctype) => +ctype.value === +user.collaboratorType
      );

      setDefaultTypeOption(defaultOption!);
    }
  }, []);

  const userRole = (user.roles[0] as UserRole)?.description ?? user.roles[0];

  return (
    <UserInfoWrapper flex middle>
      <AvatarCircle size={48} user={user} />
      <Spacer w="15px" />
      <Name>{UtilService.getUsersConcatenatedNameOrEmail(user)}</Name>

      {userRole ? (
        <Wrapper mlAuto minWidth={80} flex justifyEnd>
          <RoleLabel userRole={userRole}>{userRole}</RoleLabel>
        </Wrapper>
      ) : null}

      {defaultTypeOption && instanceOfCollaborator(user) && (
        <CollaboratorActions flex middle>
          {user.collaboratorType !== collaboratorAccessEnums.OWNER ? (
            <>
              <Select
                className="collaborator-type-select"
                onChange={(option: SelectOptionProps) =>
                  handleChangeCallbackWrapper?.(() =>
                    onCollaboratorTypeChangeHandler(option, user.id)
                  )
                }
                options={collaboratorTypeOptions}
                defaultValue={defaultTypeOption}
                isDisabled={disabled}
              />

              <Spacer w="15px" />

              {(!disabled || isMe) && (
                <ButtonIcon
                  type="button"
                  onClick={() =>
                    isMe && !canEdit
                      ? onRemoveMeModalOpenHandler()
                      : handleChangeCallbackWrapper?.(() =>
                          onDeselectCollaboratorHandler()
                        )
                  }
                >
                  <CloseIcon />
                </ButtonIcon>
              )}

              <ConfirmationModal
                opened={removeMeModal}
                title="Remove Myself as Collaborator"
                message="Are you sure you want to remove yourself as collaborator?"
                cancel={() => setRemoveMeModal(false)}
                confirm={onRemoveMeAsCollaboratorClickHandler}
                buttonText="Remove Me"
              />
            </>
          ) : (
            <>
              <CollaboratorAccessLabel>
                {collaboratorAccessEnums.OWNER_NAME}
              </CollaboratorAccessLabel>

              <Spacer w="79px" />
            </>
          )}
        </CollaboratorActions>
      )}
    </UserInfoWrapper>
  );
};

export default UserInfo;
