import { FC } from 'react';
import styled from 'styled-components';

interface LinkTextProps {
  text: string;
}

const HiddenTxt = styled.span`
  opacity: 0;
  padding: 0 4px;
`;

const VisibleTxt = styled.span`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  transition: 300ms ease;
`;

export const LinkText: FC<LinkTextProps> = ({ text }) => {
  return (
    <>
      <HiddenTxt className="hidden">{text}</HiddenTxt>
      <VisibleTxt className="visible">{text}</VisibleTxt>
    </>
  );
};
