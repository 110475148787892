export enum OrderTestInputEnum {
  COUNTER_TOP_THICKNESS_INPUT = 'counter-top-thickness-input',
  PO_NUMBER_INPUT = 'po-number-input',
}

export enum OrderTestRadioButtonEnum {
  DOOR_SAMPLE_BASE_BTN = 'door-sample-base-btn',
  SALES_MATERIAL_BTN = 'sales-material-btn',
  NONE_BTN = 'none-btn',
}
