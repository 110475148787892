import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { SynchronizationActionEnum } from 'order/enums/SynchronisationActionEnum';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { cleanSyncToolFailNotification } from 'notifications/store/notificationsActions';

export const SyncToolPopUp = () => {
  const order = useSelector((state: RootState) => state.orderReducer.order);
  const dispatch = useAppDispatch();

  const { syncToolFailNotification } = useSelector(
    (state: RootState) => state.notificationsReducer
  );
  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const text = syncToolFailNotification
    ? `${syncToolFailNotification.content} Please inform system administrators, and await further instructions.`
    : `Please stop editing this work order #${
        order?.serialNumber
      }. A sync error ${
        order?.synchronizationStatus?.failure?.action
          ? SynchronizationActionEnum[
              order!.synchronizationStatus!.failure!.action
            ]
          : ''
      } has occurred. Please inform system administrators, and await further instructions.`;

  return (
    <ConfirmationModal
      confirm={(close: () => void) => {
        dispatch(cleanSyncToolFailNotification());
        close();
      }}
      buttonText="OK"
      shouldNotRenderCancelButton
      title="Warning"
      message={text}
      opened={
        isUserCSR &&
        order !== null &&
        (syncToolFailNotification !== null ||
          order.synchronizationStatus?.failure !== null)
      }
    />
  );
};
