import { RootState } from 'store';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { LineItemHardware } from 'order/wizard/orderLineItems/interface/ILineItemData';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import {
  Inovae2OStyleDoorBuilderRequest,
  StyleDoorBuilderRequest,
} from 'order/wizard/orderStyles/interface/StyleDoorBuilder';

import {
  orderStylesDoorBuilderActions,
  setFrameSizeOptions,
  setDoorOverlayOptions,
  setFaceFrameOptions,
  setFrameStyleOptions,
  setMidrailsOptions,
  setDoorStyleOptions,
  setConfiguration,
  ConfigOptions,
  setArchStyleOptions,
  setInsertPanelWallOptions,
  setInsertPanelBaseOptions,
  setDoorEdgeWallOptions,
  setDoorEdgeBaseOptions,
  setMetalFinishOptions,
  setDrawerBoxOptions,
  setHingeColorOptions,
  setHingeTypeOptions,
  HingeColorOptions,
  setDrawerStyleOptions,
  setDrawerEdgeOptions,
  DoorBuilderPayload,
  setDrawerInsertPanelOptions,
  setTopDrawerInsertPanelOptions,
  setHardwareOptions,
  setClosetHardwareColorOptions,
  setDoorCodes,
  HardwareQuantitiesRequest,
  setHardwareQuantities,
  setToeSpaceTypes,
  GetDoorEdgeBandsRequest,
  setDoorEdgeBands,
  GetMaterialDrawersRequest,
  setMaterialDrawers,
  MaterialDoorCodesRequest,
  ValidateStyleDoorBuilderRequest,
} from './orderStylesDoorBuilderActions';

import { DoorBuilderBreakingChange } from '../../../interface/DoorBuilderBreakingChange';
import { Midrails } from '../../../interface/Midrails';
import { Style } from '../../../interface/Style';
import { StyleFieldOption } from '../../../interface/StyleFieldOption';
import { setStyle } from '../orderStylesActions';

function* getDoorOverlays(action: Action<DoorBuilderPayload>) {
  try {
    const { productLineId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId!);

    const doorOverlays: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/dooroverlays`,
      { params: queryParams }
    );

    yield put(setDoorOverlayOptions(doorOverlays));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFaceFrames(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('productLineId', productLineId!);

    const faceFrames: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/faceframes`,
      { params: queryParams }
    );

    yield put(setFaceFrameOptions(faceFrames));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFrameStyles(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, faceFrameId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('faceFrameId', faceFrameId!);
    queryParams.append('productLineId', productLineId!);

    const frameStyles: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/framestyles`,
      { params: queryParams }
    );

    const frameStylesOptions = UtilService.mapObjectToSelectOptions(
      frameStyles,
      undefined,
      undefined,
      {
        toCapitalize: true,
      }
    ) as SelectOptionProps[];

    yield put(setFrameStyleOptions(frameStylesOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMidrails(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, faceFrameId, frameStyleId, isOverriden } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('faceFrameId', faceFrameId!);
    queryParams.append('frameStyleId', frameStyleId!);

    if (isOverriden) {
      queryParams.append('isOverriden', isOverriden.toString());
    }

    const midrails: Midrails = yield call(
      ApiService.get,
      `/api/catalog/midrails`,
      { params: queryParams }
    );

    yield put(setMidrailsOptions(midrails));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDoorStyles(action: Action<DoorBuilderPayload>) {
  try {
    const {
      woodMaterialId,
      doorOverlayId,
      faceFrameId,
      productLineId,
      oneInchDoor,
      isOverriden,
      isQuoteFlow,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('woodMaterialId', woodMaterialId!);
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('faceFrameId', faceFrameId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('oneInchDoor', oneInchDoor!.toString());

    if (isOverriden) {
      queryParams.append('isOverriden', isOverriden.toString());
    }

    if (isQuoteFlow) {
      queryParams.append('isQuoteFlow', isQuoteFlow.toString());
    }

    const doorStyles: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/doorstyles`,
      { params: queryParams }
    );

    const doorStyleOptions = UtilService.mapObjectToSelectOptions(
      doorStyles,
      undefined,
      undefined,
      {
        toCapitalize: true,
        customParams: ['code', 'imageUrl'],
      }
    ) as SelectOptionProps[];

    const configOptions = doorStyles.reduce((agg, curr) => {
      const config = agg;
      config[curr.id] = UtilService.mapObjectToSelectOptions(
        curr.configurations!,
        undefined,
        undefined,
        {
          customParams: ['imageUrl'],
        }
      ) as SelectOptionProps[];
      return config;
    }, {} as ConfigOptions);

    yield put(setConfiguration(configOptions));
    yield put(setDoorStyleOptions(doorStyleOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getFrameSizes(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, productLineId, doorStyleId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('doorStyleId', doorStyleId!);

    const frameSizes: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/framesizes`,
      { params: queryParams }
    );

    let frameSizeOptions = UtilService.mapObjectToSelectOptions(
      frameSizes,
      undefined,
      'size',
      {
        customParams: ['upcharge', 'isDefault'],
      }
    ) as SelectOptionProps[];

    frameSizeOptions = frameSizeOptions.map((option) => {
      return {
        ...option,
        label: option.upcharge
          ? `${option.label} + $${option.upcharge}`
          : option.label,
      };
    });

    yield put(setFrameSizeOptions(frameSizeOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getArchStyles(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, doorConfigurationId, doorStyleId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorStyleId', doorStyleId!);

    const archStyles: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/archstyle`,
      { params: queryParams }
    );

    const archStyleOptions = UtilService.mapObjectToSelectOptions(
      archStyles,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setArchStyleOptions(archStyleOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getWallInsertPanels(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, doorConfigurationId, doorStyleId, productLineId } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);

    const insertPanels: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/insertpanels`,
      { params: queryParams }
    );

    const insertPanelOptions = UtilService.mapObjectToSelectOptions(
      insertPanels,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setInsertPanelWallOptions(insertPanelOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getBaseInsertPanels(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, doorConfigurationId, doorStyleId, productLineId } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);

    const insertPanels: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/insertpanels`,
      { params: queryParams }
    );

    const insertPanelOptions = UtilService.mapObjectToSelectOptions(
      insertPanels,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setInsertPanelBaseOptions(insertPanelOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getWallDoorEdges(action: Action<DoorBuilderPayload>) {
  try {
    const {
      doorOverlayId,
      doorConfigurationId,
      doorStyleId,
      productLineId,
      faceFrameId,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('faceFrameId', faceFrameId!);

    const doorEdges: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/dooredges`,
      { params: queryParams }
    );

    const doorEdgeOptions = UtilService.mapObjectToSelectOptions(
      doorEdges,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDoorEdgeWallOptions(doorEdgeOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getBaseDoorEdges(action: Action<DoorBuilderPayload>) {
  try {
    const {
      doorOverlayId,
      doorConfigurationId,
      doorStyleId,
      productLineId,
      faceFrameId,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('faceFrameId', faceFrameId!);

    const doorEdges: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/dooredges`,
      { params: queryParams }
    );

    const doorEdgeOptions = UtilService.mapObjectToSelectOptions(
      doorEdges,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDoorEdgeBaseOptions(doorEdgeOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMetalFinishes(action: Action<DoorBuilderPayload>) {
  try {
    const { doorStyleId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorStyleId', doorStyleId!);

    const metalFinishes: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/metalfinishes`,
      { params: queryParams }
    );

    const metalFinishOptions = UtilService.mapObjectToSelectOptions(
      metalFinishes,
      undefined,
      undefined,
      {
        toCapitalize: true,
        customParams: ['upcharge'],
      }
    ) as SelectOptionProps[];

    yield put(setMetalFinishOptions(metalFinishOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDrawerBoxes(action: Action<DoorBuilderPayload>) {
  try {
    const { productLineId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('productLineId', productLineId!);

    const drawerBoxes: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/drawerboxes`,
      { params: queryParams }
    );

    const drawerBoxOptions = UtilService.mapObjectToSelectOptions(
      drawerBoxes,
      undefined,
      undefined,
      {
        toCapitalize: true,
        customParams: ['imageUrl', 'isDefault'],
      }
    ) as SelectOptionProps[];

    yield put(setDrawerBoxOptions(drawerBoxOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getHingeTypes(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, productLineId, doorStyleId, faceFrameId } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('faceFrameId', faceFrameId!);

    const hingeTypes: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/hingetypes`,
      { params: queryParams }
    );

    const hingeTypeOptions = UtilService.mapObjectToSelectOptions(
      hingeTypes,
      undefined,
      undefined,
      {
        customParams: ['isDefault', 'imageUrl'],
      }
    ) as SelectOptionProps[];

    const hingeColorOptions = hingeTypes.length
      ? hingeTypes.reduce((agg, curr) => {
          const config = agg;
          config[curr.id] = UtilService.mapObjectToSelectOptions(
            curr.colors!,
            undefined,
            undefined,
            {
              customParams: ['isDefault', 'imageUrl'],
            }
          ) as SelectOptionProps[];
          return config;
        }, {} as HingeColorOptions)
      : null;

    yield put(setHingeTypeOptions(hingeTypeOptions));
    yield put(setHingeColorOptions(hingeColorOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDrawerStyles(action: Action<DoorBuilderPayload>) {
  try {
    const {
      doorConfigurationId,
      doorEdgeId,
      doorOverlayId,
      doorStyleId,
      productLineId,
      faceFrameId,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorConfigurationId', doorConfigurationId!);
    queryParams.append('doorEdgeId', doorEdgeId!);
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('faceFrameId', faceFrameId!);

    const drawerStyles: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/drawerfrontstyles`,
      { params: queryParams }
    );

    const drawerStyleOptions = UtilService.mapObjectToSelectOptions(
      drawerStyles,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDrawerStyleOptions(drawerStyleOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDrawerEdges(action: Action<DoorBuilderPayload>) {
  try {
    const { drawerFrontStyleId, productLineId, faceFrameId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('drawerFrontStyleId', drawerFrontStyleId!);
    queryParams.append('productLineId', productLineId!);
    queryParams.append('faceFrameId', faceFrameId!);

    const drawerEdges: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/draweredges`,
      { params: queryParams }
    );

    const drawerEdgeOptions = UtilService.mapObjectToSelectOptions(
      drawerEdges,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDrawerEdgeOptions(drawerEdgeOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDrawerInsertPanel(action: Action<DoorBuilderPayload>) {
  try {
    const { drawerFrontStyleId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('drawerFrontStyleId', drawerFrontStyleId!);
    queryParams.append('productLineId', productLineId!);

    const drawerInsertPanels: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/drawerinsertpanels`,
      { params: queryParams }
    );

    const drawerInsertPanelOptions = UtilService.mapObjectToSelectOptions(
      drawerInsertPanels,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDrawerInsertPanelOptions(drawerInsertPanelOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getTopDrawerInsertPanel(action: Action<DoorBuilderPayload>) {
  try {
    const { doorOverlayId, drawerFrontStyleId, insertPanelBaseId } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('drawerFrontStyleId', drawerFrontStyleId!);
    queryParams.append('insertPanelBaseId', insertPanelBaseId!);

    const topDrawerInsertPanels: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/toppanel`,
      { params: queryParams }
    );

    const topDrawerInsertPanelOptions = UtilService.mapObjectToSelectOptions(
      topDrawerInsertPanels,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setTopDrawerInsertPanelOptions(topDrawerInsertPanelOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getHardware(action: Action<DoorBuilderPayload>) {
  try {
    const { productLineId } = action.payload!;

    const hardware: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/hardware?productLineId=${productLineId}`
    );

    const hardwareOptions = UtilService.mapObjectToSelectOptions(
      hardware,
      undefined,
      undefined,
      { customParams: ['upcharge', 'isDefault'] }
    ) as SelectOptionProps[];

    yield put(setHardwareOptions(hardwareOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getClosetHardwareColors(action: Action<unknown>) {
  try {
    const closetHardwareColors: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/closethardwarecolors`
    );

    const closetHardwareColorOptions = UtilService.mapObjectToSelectOptions(
      closetHardwareColors,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setClosetHardwareColorOptions(closetHardwareColorOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getHardwareQuantities(action: Action<HardwareQuantitiesRequest>) {
  try {
    const { orderId, styleId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('styleId', styleId);

    const hardwareQuantities: LineItemHardware = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/hardware-quantities`,
      { params: queryParams }
    );

    yield put(setHardwareQuantities(hardwareQuantities));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDoorCodes(action: Action<DoorBuilderPayload>) {
  try {
    const {
      baseDoorConfigurationId,
      baseDoorStyleId,
      doorConfigurationId,
      doorOverlayId,
      doorStyleId,
      drawerFrontStyleId,
      orderId,
      styleId,
      archStyleId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    if (doorOverlayId) {
      queryParams.append('doorOverlayId', doorOverlayId);
    }

    if (doorStyleId) {
      queryParams.append('wallDoorStyleId', doorStyleId);
    }

    if (baseDoorStyleId) {
      queryParams.append('baseDoorStyleId', baseDoorStyleId);
    }

    if (doorConfigurationId) {
      queryParams.append('wallDoorConfigurationId', doorConfigurationId);
    }

    if (baseDoorConfigurationId) {
      queryParams.append('baseDoorConfigurationId', baseDoorConfigurationId);
    }

    if (drawerFrontStyleId) {
      queryParams.append('drawerFrontStyleId', drawerFrontStyleId);
    }

    if (styleId) {
      queryParams.append('styleId', styleId);
    }

    if (archStyleId) {
      queryParams.append('archStyleId', archStyleId);
    }

    const doorCodes: IDoorCode = yield call(
      ApiService.get,
      `/api/orders/order/${orderId}/doorcodes`,
      { params: queryParams }
    );

    yield put(setDoorCodes(doorCodes));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveDoorBuilderData(action: Action<StyleDoorBuilderRequest>) {
  try {
    const { orderId, styleId, ...rest } = action.payload!;

    const doorBuilderExist: Style = yield select(
      (state: RootState) => state.orderStylesReducer.style?.doorOverlay !== null
    );

    const doorBuilderBreakingChanges: DoorBuilderBreakingChange[] = yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/styles/${styleId}/doorbuilder/${
        doorBuilderExist ? 'update' : 'add'
      }`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, doorBuilderBreakingChanges);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getToeSpaceTypes(action: Action<DoorBuilderPayload>) {
  try {
    const toeSpaceTypes: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/toespacetypes`
    );

    const toeSpaceTypeOptions = UtilService.mapObjectToSelectOptions(
      toeSpaceTypes,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setToeSpaceTypes(toeSpaceTypeOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getProductLineDoorStyles(action: Action<DoorBuilderPayload>) {
  try {
    const { productLineId, doorOverlayId, materialGroupId, materialColorId } =
      action.payload!;
    const queryParams = new URLSearchParams();
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('materialGroupId', materialGroupId!);
    queryParams.append('materialColorId', materialColorId!);

    const doorStyles: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/doorstyles/productline/${productLineId}`,
      {
        params: queryParams,
      }
    );

    const doorStyleOptions = UtilService.mapObjectToSelectOptions(
      doorStyles,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setDoorStyleOptions(doorStyleOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDoorEdgeBands(action: Action<GetDoorEdgeBandsRequest>) {
  try {
    const { materialGroupId, materialColorId } = action.payload!;
    const queryParams = new URLSearchParams();
    queryParams.append('materialGroupId', materialGroupId!);
    queryParams.append('materialColorId', materialColorId!);

    const doorEdgeBands: StyleFieldOption[] = yield call(
      ApiService.get,
      `/api/catalog/dooredgebands`,
      {
        params: queryParams,
      }
    );

    const doorEdgeBandOptions = UtilService.mapObjectToSelectOptions(
      doorEdgeBands
    ) as SelectOptionProps[];

    yield put(setDoorEdgeBands(doorEdgeBandOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMaterialDrawers(action: Action<GetMaterialDrawersRequest>) {
  try {
    const {
      materialGroupId,
      doorOverlayId,
      doorStyleId,
      grainDirectionId,
      productLineId,
    } = action.payload!;
    const queryParams = new URLSearchParams();
    queryParams.append('materialGroupId', materialGroupId!);
    queryParams.append('doorOverlayId', doorOverlayId!);
    queryParams.append('doorStyleId', doorStyleId!);
    queryParams.append('productLineId', productLineId!);

    if (grainDirectionId) {
      queryParams.append('grainDirectionId', grainDirectionId.toString());
    }

    const materialDrawers: StyleFieldOption[] = yield call(
      ApiService.get,
      `api/catalog/materialdrawers`,
      {
        params: queryParams,
      }
    );

    const materialDrawerOptions = UtilService.mapObjectToSelectOptions(
      materialDrawers,
      undefined,
      undefined,
      {
        customParams: ['imageUrl'],
      }
    ) as SelectOptionProps[];

    yield put(setMaterialDrawers(materialDrawerOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveInovae2ODoorBuilder(
  action: Action<Inovae2OStyleDoorBuilderRequest>
) {
  try {
    const { orderId, styleId, styleStep, getStyleAfterUpdate, ...rest } =
      action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/styles/${styleId}/inovae2o/doorbuilder/${
        styleStep === StylesStepsEnum.DOOR_BUILDER ? 'add' : 'update'
      }`,
      rest
    );

    if (getStyleAfterUpdate) {
      const style: Style = yield call(
        ApiService.get,
        `/api/order/orders/${orderId}/styles/${styleId}`
      );

      yield put(setStyle(style));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getMaterialDoorCodes(action: Action<MaterialDoorCodesRequest>) {
  try {
    const {
      orderId,
      styleId,
      materialGroupId,
      grainDirectionId,
      doorOverlayId,
      wallDoorStyleId,
      baseDoorStyleId,
      materialDrawerId,
      materialColorId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('styleId', styleId!);

    if (materialGroupId) {
      queryParams.append('materialGroupId', materialGroupId);
    }

    if (materialColorId) {
      queryParams.append('materialColorId', materialColorId);
    }

    if (grainDirectionId) {
      queryParams.append('grainDirectionId', grainDirectionId.toString());
    }

    queryParams.append('doorOverlayId', doorOverlayId);
    queryParams.append('wallDoorStyleId', wallDoorStyleId);
    queryParams.append('baseDoorStyleId', baseDoorStyleId);
    queryParams.append('materialDrawerId', materialDrawerId);

    const doorCodes: IDoorCode = yield call(
      ApiService.get,
      `/api/orders/order/${orderId}/doorcodes/material`,
      { params: queryParams }
    );

    yield put(setDoorCodes(doorCodes));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* validateStyleDoorBuilder(
  action: Action<ValidateStyleDoorBuilderRequest>
) {
  try {
    const {
      orderId,
      styleId,
      cabinetBoxMaterialId,
      doorOverlayId,
      drawerBoxId,
      faceFrameId,
      frameStyleId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('doorOverlayId', doorOverlayId);
    queryParams.append('faceFrameId', faceFrameId);
    queryParams.append('frameStyleId', frameStyleId);
    queryParams.append('drawerBoxId', drawerBoxId);

    if (cabinetBoxMaterialId) {
      queryParams.append('cabinetBoxMaterialId', cabinetBoxMaterialId);
    }

    const breakingChangeItems: DoorBuilderBreakingChange[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}/doorbuilder/validate`,
      { params: queryParams }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, breakingChangeItems);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderStylesDoorBuilderSagas() {
  yield takeEvery(
    orderStylesDoorBuilderActions.VALIDATE_STYLE_DOOR_BUILDER,
    validateStyleDoorBuilder
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DOOR_OVERLAYS,
    getDoorOverlays
  );
  yield takeEvery(orderStylesDoorBuilderActions.GET_FACE_FRAMES, getFaceFrames);
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_FRAME_STYLES,
    getFrameStyles
  );
  yield takeEvery(orderStylesDoorBuilderActions.GET_MIDRAILS, getMidrails);
  yield takeEvery(orderStylesDoorBuilderActions.GET_DOOR_STYLE, getDoorStyles);
  yield takeEvery(orderStylesDoorBuilderActions.GET_FRAME_SIZE, getFrameSizes);
  yield takeEvery(orderStylesDoorBuilderActions.GET_ARCH_STYLE, getArchStyles);
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_INSERT_PANEL_WALL,
    getWallInsertPanels
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_INSERT_PANEL_BASE,
    getBaseInsertPanels
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DOOR_EDGE_WALL,
    getWallDoorEdges
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DOOR_EDGE_BASE,
    getBaseDoorEdges
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_METAL_FINISH,
    getMetalFinishes
  );
  yield takeEvery(orderStylesDoorBuilderActions.GET_DRAWER_BOX, getDrawerBoxes);
  yield takeEvery(orderStylesDoorBuilderActions.GET_HINGE_TYPE, getHingeTypes);
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DRAWER_STYLES,
    getDrawerStyles
  );

  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DRAWER_EDGES,
    getDrawerEdges
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DRAWER_INSERT_PANEL,
    getDrawerInsertPanel
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_TOP_DRAWER_INSERT_PANEL,
    getTopDrawerInsertPanel
  );
  yield takeEvery(orderStylesDoorBuilderActions.GET_HARDWARE, getHardware);

  yield takeEvery(
    orderStylesDoorBuilderActions.GET_CLOSET_HARDWARE_COLORS,
    getClosetHardwareColors
  );
  yield takeEvery(orderStylesDoorBuilderActions.GET_DOOR_CODES, getDoorCodes);
  yield takeEvery(
    orderStylesDoorBuilderActions.SAVE_DOOR_BUILDER_DATA,
    saveDoorBuilderData
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_HARDWARE_QUANITTIES,
    getHardwareQuantities
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_TOESPACE_TYPES,
    getToeSpaceTypes
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_PRODUCT_LINE_DOOR_STYLES,
    getProductLineDoorStyles
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_DOOR_EDGE_BANDS,
    getDoorEdgeBands
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_MATERIAL_DRAWERS,
    getMaterialDrawers
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.SAVE_INOVAE2O_DOOR_BUILDER,
    saveInovae2ODoorBuilder
  );
  yield takeEvery(
    orderStylesDoorBuilderActions.GET_MATERIAL_DOOR_CODES,
    getMaterialDoorCodes
  );
}
