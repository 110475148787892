import styled from 'styled-components';

import { Wrapper } from 'shared/components/Wrapper';

export const StyleDetailsFieldWrapper = styled(Wrapper)`
  width: 290px;

  &:not(:last-child) {
    margin-right: 120px;
  }
`;
