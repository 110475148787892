import { combineReducers } from 'redux';

import { authReducer } from 'auth/store/authReducer';

import { currentUserReducer } from 'overview/manageUsers/store/currentUserReducer';
import { dealershipReducer } from 'overview/dealership/store/dealershipReducer';
import { manageUsersReducer } from 'overview/manageUsers/store/manageUsersReducers';
import { ordersReducer } from 'overview/orders/store/ordersReducer';
import { quotesReducer } from 'overview/quotes/store/quotesReducer';
import { notificationsReducer } from 'notifications/store/notificationsReducer';
import { overviewReducer } from 'overview/store/overviewReducer';

import { curtainReducer } from 'curtain/store/curtainReducer';
import { curtainWorkablesReducer } from 'curtain/tabs/CurtainWorkablesTab/store/curtainWorkablesReducer';

import { ACKReducer } from 'order/wizard/orderAcknowledgement/store/ACKReducer';
import { importReducer } from 'order/wizard/import/store/ImportReducer';
import { lineItemModificationsReducer } from 'order/wizard/orderLineItems/components/Modifications/store/lineItemModificationsReducer';
import { orderAsapReducer } from 'order/wizard/orderASAP/store/OrderAsapReducer';
import { orderAssigneesReducer } from 'order/components/OrderAssignees/store/orderAssigneesReducer';
import { orderLineItemsReducer } from 'order/wizard/orderLineItems/store/orderLineItemsReducer';
import { orderReducer } from 'order/store/orderReducer';
import { orderStylesReducer } from 'order/wizard/orderStyles/productLines/store/orderStylesReducer';

import { attachmentsModalReducer } from 'shared/components/AttachmentsModal/store/attachmentsModalReducer';
import { popupReducer } from 'order/popups/store/popupReducer';
import { warningsReducer } from 'order/warnings/store/warningsReducer';
import { writingHintsReducer } from 'order/writingHints/store/writingHintsReducer';

import { sharedReducer } from 'shared/store/sharedReducer';
import { ackChangeLogReducer } from 'order/shared/ackchangelog/store/ackchangelogReducer';

import { topicReducer } from 'order/wizard/topic/store/TopicReducer';
import { aditionalFieldReducer } from 'order/additonalFields/store/additionalFieldsReducer';

export default combineReducers({
  attachmentsModalReducer,
  authReducer,
  currentUser: currentUserReducer,
  curtainReducer,
  curtainWorkablesReducer,
  dealershipReducer,
  importStylesReducer: importReducer,
  lineItemModificationsReducer,
  manageUsersReducer,
  ordersReducer,
  notificationsReducer,
  orderAsapReducer,
  orderAssigneesReducer,
  orderLineItemsReducer,
  orderReducer,
  orderStylesReducer,
  overviewReducer,
  popupReducer,
  ACKReducer,
  warningsReducer,
  writingHintsReducer,
  sharedReducer,
  ackChangeLogReducer,
  topicReducer,
  quotesReducer,
  aditionalFieldReducer,
});
