import { RootState } from 'store';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Textarea } from 'shared/components/Textarea';
import FormError from 'shared/components/FormError';

const OrderStyleNote = () => {
  const contextMethods = useFormContext<{ styleNote: string }>();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  return (
    <FormElement>
      <FormLabel>Style Notes</FormLabel>

      <Controller
        name="styleNote"
        control={contextMethods.control}
        rules={{ maxLength: 1000 }}
        render={({ field: { onChange, ref, value } }) => (
          <Textarea
            ref={ref}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            readOnly={!canEdit}
            aria-invalid={
              contextMethods.formState.errors.styleNote ? 'true' : 'false'
            }
          />
        )}
      />

      <FormError
        label="Style Notes"
        error={contextMethods.formState.errors.styleNote}
        validationSchema={{ maxLength: 1000 }}
      />
    </FormElement>
  );
};

export default OrderStyleNote;
