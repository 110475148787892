import { RootState } from 'store';
import { UseFormReturn, Path, PathValue } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ILineItemDefaultData } from 'order/wizard/orderLineItems/interface/ILineItemData';
import { IModification } from 'order/wizard/orderLineItems/components/Modifications/interface/IModification';
import { PopupConditionType } from 'order/popups/enums/PopupConditionTypeEnum';
import { PopupEntityType } from 'order/popups/enums/PopupEntityTypeEnum';
import { PopupEventType } from 'order/popups/enums/PopupEventTypeEnum';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import useModal from './useInfoModal';

const mapFormValueToEntityType = (field: string): PopupEntityType | null => {
  switch (field) {
    case 'woodOrMaterial':
      return PopupEntityType.Wood;
    case 'finishColor':
      return PopupEntityType.FinishColor;
    case 'specialFinishSample':
      return PopupEntityType.SpecialFinishSample;
    case 'doorEdgeWall':
    case 'doorEdgeBase':
      return PopupEntityType.DoorEdge;
    case 'doorStyleBase':
    case 'doorStyleWall':
      return PopupEntityType.Door;
    case 'insertPanelWall':
    case 'insertPanelBase':
      return PopupEntityType.InsertPanel;
    case 'drawerStyle':
      return PopupEntityType.DrawerStyle;
    case 'toeSpaceType':
      return PopupEntityType.ToeSpace;
    case 'hingeType':
      return PopupEntityType.HingeType;
    case 'finishedEndPosition':
      return PopupEntityType.FinishedEnd;
    default:
      return null;
  }
};

const mapConditionTypeToFormValue = (conditionType: string) => {
  switch (conditionType) {
    case PopupConditionType.DoorSeries:
      return 'doorOverlay';
    case PopupConditionType.WallEdge:
      return 'doorEdgeWall';
    case PopupConditionType.BaseEdge:
      return 'doorEdgeBase';
    case PopupConditionType.WallInsertPanel:
      return 'insertPanelWall';
    case PopupConditionType.BaseInsertPanel:
      return 'insertPanelBase';
    case PopupConditionType.FromWidth:
    case PopupConditionType.ToWidth:
      return 'width';
    case PopupConditionType.FromHeight:
    case PopupConditionType.ToHeight:
      return 'height';
    case PopupConditionType.Hinge:
      return 'hingeType';
    default:
      return '';
  }
};

function getValueForEventType<T>(
  eventType: PopupEventType,
  value: PathValue<T, Path<T>>
): string {
  if (!value) return '';
  switch (eventType) {
    case PopupEventType.WoodSelected:
    case PopupEventType.FinishColorSelected:
    case PopupEventType.DrawerStyleSelected:
    case PopupEventType.WallEdgeSelected:
    case PopupEventType.BaseEdgeSelected:
    case PopupEventType.WallDoorSelected:
    case PopupEventType.BaseDoorSelected:
    case PopupEventType.BaseInsertPanelSelected:
    case PopupEventType.HingeTypeSelected:
    case PopupEventType.ToeSpaceSelected:
    case PopupEventType.WallInsertPanelSelected:
      return (value as Record<string, string>).value.toString();
    default:
      return (value as Record<string, string>).id ?? '';
  }
}

interface UsePopupMessageOptions {
  resetDirtyFieldsOnClose?: boolean;
  onCloseCallBack?: () => void;
}

export const usePopupMessage = (options?: UsePopupMessageOptions) => {
  const location = useLocation();

  const productLines = useSelector(
    (state: RootState) => state.sharedReducer.productLines
  );

  const popups = useSelector((state: RootState) => state.popupReducer.popups);

  const queryFields = new URLSearchParams(location.search);
  const productLineId = queryFields.get('productLineId');

  const productLine = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine ??
      productLines?.find((pl) => pl.id === productLineId)
  );

  const lineItemDefaultData = useSelector(
    (state: RootState) =>
      state.orderLineItemsReducer.lineItemDefaultData as ILineItemDefaultData
  );

  // added because few door styles have no configurations and popup appears on style change
  const configurationOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.configurationOptions
  );

  const doorCodes = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemDoorCodes
  );

  const [opened, setOpened] = useState(false);
  const [message, setMessage] = useState('');
  const [dirtyFields, setDirtyFields] = useState([
    {
      key: '',
      value: '',
    },
  ]);

  const [selectedModification, setModification] =
    useState<IModification | null>(null);

  const onClosePopUp = () => {
    if (options?.onCloseCallBack) {
      options.onCloseCallBack();
    }

    setOpened(false);
  };

  const { PopupModal } = useModal({
    opened,
    message,
    close: () => onClosePopUp(),
  });

  function resetDirtyFields() {
    setDirtyFields([
      {
        key: '',
        value: '',
      },
    ]);
  }

  function onModificationSelected(modification: IModification) {
    if (!doorCodes) {
      return;
    }
    const woodGridDoorMessage = 'Wood Grid not allowed. Use Door: ';
    const glassDoorMessage = 'Glass Door not allowed. Use Door: ';
    const inlayDoorMessage = 'Inlay will be: ';
    const specialDoorCountMessage = 'This Modifier Requires User Door Count.';

    let popupMessage = '';

    if (modification.isWoodGrid) {
      const woodGridDoorCode = doorCodes.wall
        .concat(doorCodes.base)
        .find(
          (dc) =>
            dc.doorItemDetails != null &&
            dc.doorItemDetails.noWoodGrid &&
            !dc.isFallback
        );

      if (woodGridDoorCode) {
        popupMessage = `${woodGridDoorMessage}${woodGridDoorCode.doorItemDetails?.woodGridDoor}.`;
      }
    }

    if (modification.isGlassDoor) {
      const glassDoorCode = doorCodes.wall
        .concat(doorCodes.base)
        .find(
          (dc) =>
            dc.doorItemDetails != null &&
            dc.doorItemDetails.noGlassDoor &&
            !dc.isFallback
        );

      if (glassDoorCode) {
        popupMessage = `${glassDoorMessage}${glassDoorCode.doorItemDetails?.glassDoor}.`;
      }
    }

    if (modification.isInlay) {
      const inlayDoorCode = doorCodes.wall
        .concat(doorCodes.base)
        .find(
          (dc) =>
            dc.doorItemDetails != null &&
            dc.doorItemDetails.noInlay &&
            !dc.isFallback
        );

      if (inlayDoorCode) {
        popupMessage = `${inlayDoorMessage}${inlayDoorCode.doorItemDetails?.inlayDoor}.`;
      }
    }

    if (modification.specialDoorCount) {
      popupMessage = specialDoorCountMessage;
    }

    if (popupMessage) {
      setOpened(true);
      setMessage(popupMessage);
      setModification(null);
    }
  }

  useEffect(() => {
    if (!opened && selectedModification) {
      onModificationSelected(selectedModification);
    }
  }, [opened, selectedModification]);

  function onPageLoad(entityId: string) {
    let popUp = popups?.filter(
      (popup) =>
        popup.entityType === PopupEntityType.LineItem &&
        popup.entityId === entityId &&
        popup.productLineId === productLineId &&
        !popup.conditions?.length
    );

    if (!popUp?.length) {
      popUp = popups?.filter(
        (popup) =>
          popup.entityType === PopupEntityType.LineItem &&
          popup.entityId === entityId &&
          popup.productLineId === null &&
          !popup.conditions?.length
      );
    }

    if (popUp?.length) {
      setOpened(true);
      setMessage(popUp.map((p) => p.message).join('<br/>'));
    }
  }

  function onFormLoad(modification: IModification) {
    const entityId =
      modification.catalogModificationId ?? modification.id ?? '';
    let popUp = popups?.find(
      (popup) =>
        popup.entityType === PopupEntityType.Modification &&
        popup.entityId === entityId &&
        popup.productLineId === productLineId
    );

    if (!popUp) {
      popUp = popups?.find(
        (popup) =>
          popup.entityType === PopupEntityType.Modification &&
          popup.entityId === entityId &&
          popup.productLineId === null
      );
    }

    if (
      dirtyFields.some(
        (x) => x.key === 'modificationId' && x.value === entityId
      )
    ) {
      return;
    }

    if (popUp) {
      setOpened(true);
      setMessage(popUp.message);
      setDirtyFields((prevState) => {
        return [
          ...prevState,
          {
            key: 'modificationId',
            value: entityId,
          },
        ];
      });
    }

    setModification(modification);
  }

  function getSizeChangePopupMessage(key: string, fieldValue: string) {
    const value = +fieldValue || 0;

    const isOneEightIncrement = !(value % 0.125);

    const {
      minDepth,
      minHeight,
      minLegDepth,
      minLegWidth,
      minWidth,
      maxDepth,
      maxHeight,
      maxLegDepth,
      maxLegWidth,
      maxWidth,
      validDepths,
      validHeights,
      validWidths,
      fixedDepths,
      fixedHeights,
      fixedWidths,
      quarterInchWidths,
      cornerCabinet,
    } = lineItemDefaultData || {};

    const sortNumbers = (a: number, b: number) => a - b;

    switch (key) {
      case 'width':
        if (value < minWidth || value > maxWidth) {
          return `Width must be ${minWidth} to ${maxWidth}.`;
        }

        if (
          !isOneEightIncrement &&
          productLine?.name === ProductLineEnums.PRODUCT_LINE_PRELUDE &&
          fixedWidths &&
          quarterInchWidths
        ) {
          return 'Width must be on 1/8 Inch.';
        }

        if (fixedWidths && !validWidths?.includes(value)) {
          return `Valid Widths: ${validWidths?.sort().join(', ')}.`;
        }

        break;
      case 'height':
        if (value < minHeight || value > maxHeight) {
          return `Height must be ${minHeight} to ${maxHeight}.`;
        }

        if (
          fixedHeights &&
          !validHeights?.some((validHeight) => validHeight === value)
        ) {
          return `Valid Heights: ${validHeights
            ?.sort(sortNumbers)
            .join(', ')}.`;
        }

        break;

      case 'depth':
        if (value < minDepth || value > maxDepth) {
          return `Depth must be ${minDepth} to ${maxDepth}.`;
        }

        if (fixedDepths && !validDepths?.includes(value)) {
          return `Valid Depths: ${validDepths?.sort().join(', ')}.`;
        }

        break;

      case 'rightLegWidth':
        // If size outside valid Width range AND fldCatItems.fldMinimumLegWidth = 0
        if (cornerCabinet && (value < minLegWidth || value > maxLegWidth)) {
          return `Width must be ${minLegWidth} to ${maxLegWidth}.`;
        }

        // If size outside valid Width Right range AND fldCatItems.fldMinimumLegWidth<> 0
        if (
          cornerCabinet &&
          minLegWidth !== 0 &&
          !validWidths.includes(value) &&
          (value < minLegWidth || value > maxLegWidth)
        ) {
          return `Width must be ${minLegWidth} to ${maxLegWidth}.`;
        }

        // If Prelude AND not on 1/8 inch increment AND tblCatPriceRange.fldFixedWidths=1 AND tblCatItems.fldQuarterInchWidths=1
        if (
          cornerCabinet &&
          !isOneEightIncrement &&
          productLine?.name === ProductLineEnums.PRODUCT_LINE_PRELUDE &&
          fixedWidths &&
          quarterInchWidths
        ) {
          return 'Width must be on 1/8 Inch.';
        }

        // If  tblCatPriceRange.fldMinimumLegWidth = 0 AND width NOT in tblCatPriceRange.fldValidWidths
        if (
          cornerCabinet &&
          minLegWidth === 0 &&
          !validWidths.includes(value)
        ) {
          return `Valid Widths: ${validWidths?.sort(sortNumbers).join(', ')}.`;
        }
        break;

      case 'rightLegDepth':
        // If size outside valid Depth range AND fldCatItems.fldMinimumLegDepth = 0
        if (cornerCabinet && (value < minLegDepth || value > maxLegDepth)) {
          return `Depth must be ${minLegDepth} to ${maxLegDepth}.`;
        }

        // If size outside valid Depth Right  range AND fldCatItems.fldMinimumLegDepth <>  0
        if (
          cornerCabinet &&
          minLegDepth === 0 &&
          !validDepths.includes(value) &&
          (value < minLegDepth || value > maxLegDepth)
        ) {
          return `Depth must be ${minLegDepth} to ${maxLegDepth}.`;
        }

        // If  tblCatPriceRange.fldMinimumLegDepth=0 AND depth NOT in tblCatPriceRange.fldValidDepths
        if (
          cornerCabinet &&
          minLegDepth === 0 &&
          !validDepths.some((validDepth) => validDepth === value)
        ) {
          return `Valid Widths: ${validDepths?.sort(sortNumbers).join(', ')}.`;
        }

        break;
      default:
        break;
    }

    return '';
  }

  function onSizeChange<T>(form: UseFormReturn<T>, fieldKey?: Path<T>) {
    if (
      fieldKey &&
      form.formState.dirtyFields[fieldKey as unknown as keyof T]
    ) {
      const popupMessage = getSizeChangePopupMessage(
        fieldKey as string,
        form.getValues(fieldKey) as string
      );

      if (
        !popupMessage ||
        dirtyFields.some((dirtyField) => dirtyField.key === fieldKey)
      ) {
        return;
      }

      setOpened(true);
      setMessage(popupMessage);

      setDirtyFields((prevState) => {
        return [
          ...prevState,
          {
            key: fieldKey as string,
            value: popupMessage,
          },
        ];
      });
    }
  }

  useEffect(() => {
    if (options?.resetDirtyFieldsOnClose && !opened) {
      resetDirtyFields();
    }
  }, [opened]);

  function onFormChange<T>(form: UseFormReturn<T>) {
    if (form.formState.isDirty) {
      const fieldsEdited = form.formState.dirtyFields;
      Object.keys(fieldsEdited).forEach((key) => {
        const entityType = mapFormValueToEntityType(key);
        if (entityType) {
          let popUp = popups?.find(
            (p) =>
              p.entityType === entityType &&
              p.entityId ===
                getValueForEventType<T>(
                  p.eventType,
                  form.getValues(key as Path<T>)
                ) &&
              p.productLineId === productLine?.id
          );

          if (!popUp) {
            popUp = popups?.find(
              (p) =>
                p.entityType === entityType &&
                p.entityId ===
                  getValueForEventType<T>(
                    p.eventType,
                    form.getValues(key as Path<T>)
                  ) &&
                p.productLineId === null
            );
          }

          if (popUp) {
            if (
              popUp.entityType === PopupEntityType.Door &&
              configurationOptions &&
              configurationOptions[popUp.entityId]?.length &&
              form.getValues('configurationBase' as Path<T>) === '' &&
              form.getValues('configurationWall' as Path<T>) === ''
            ) {
              return;
            }

            const fieldValue = getValueForEventType<T>(
              popUp.eventType,
              form.getValues(key as Path<T>)
            );

            if (
              dirtyFields.some((x) => x.key === key && x.value === fieldValue)
            ) {
              return;
            }

            setDirtyFields((prevState) => {
              return [
                ...prevState,
                {
                  key,
                  value: fieldValue,
                },
              ];
            });

            if (popUp.conditions.length) {
              let result = false;
              popUp.conditions.forEach((condition) => {
                const field = mapConditionTypeToFormValue(condition.name);
                const value = getValueForEventType<T>(
                  0,
                  form.getValues(field as Path<T>)
                );
                if (value === condition.value) {
                  result = true;
                } else {
                  result = false;
                }
              });
              if (result) {
                setOpened(true);
                setMessage(popUp.message);
              }
            } else {
              setOpened(true);
              setMessage(popUp.message);
            }
          }
        }
      });
    }
  }

  return {
    onFormChange,
    onSizeChange,
    onPageLoad,
    onFormLoad,
    onClosePopUp,
    PopupModal,
    opened,
  };
};
