import StaticField from 'order/components/StaticField/StaticField';
import { useSelector } from 'react-redux';
import { Spacer } from 'shared/components/Layout';
import { H3 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { RootState } from 'store';

export const ICSOverviewDoorBuilder = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  return (
    <Wrapper flexGrow>
      <H3>Door style</H3>
      <Spacer h="30px" />

      <StaticField field="Door overlay" value={storedStyle?.doorOverlay.name} />

      <StaticField field="Face frame" value={storedStyle?.faceFrame.name} />

      <StaticField field="Frame style" value={storedStyle?.frameStyle?.name} />

      <StaticField
        field="Door Style"
        value={`Wall: ${storedStyle?.wallDoorStyle?.name || ''}<br/>Base: ${
          storedStyle?.baseDoorStyle?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerFrontStyle?.name || ''}`}
      />

      {storedStyle?.customFrameSize ? (
        <StaticField
          field="Frame size"
          value={storedStyle?.customFrameSize.toString()}
        />
      ) : (
        <StaticField
          field="Frame size"
          value={
            storedStyle?.frameSize?.upcharge
              ? `${storedStyle?.frameSize?.size} + ${storedStyle?.frameSize?.upcharge}`
              : storedStyle?.frameSize?.size?.toString()
          }
        />
      )}

      <StaticField
        field="Configuration"
        value={`Wall: ${
          storedStyle?.wallDoorConfiguration?.name || ''
        }<br/>Base: ${storedStyle?.baseDoorConfiguration?.name || ''}`}
      />

      <StaticField
        field="Inset panel"
        value={`Wall: ${
          storedStyle?.wallDoorInsertPanel?.name || ''
        }<br/>Base: ${
          storedStyle?.baseDoorInsertPanel?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerInsertPanel?.name || ''}`}
      />

      <StaticField
        field="Door edge"
        value={`Wall: ${storedStyle?.wallDoorEdge?.name || ''}<br/>Base: ${
          storedStyle?.baseDoorEdge?.name || ''
        }<br/>Drawer: ${storedStyle?.drawerEdge?.name || ''}`}
      />

      <StaticField field="Door notes" value={storedStyle?.doorNotes || ''} />

      <StaticField
        field="Drawer box"
        value={storedStyle?.drawerBox.name || ''}
      />

      <Spacer h="20px" />
      <H3>Hardware</H3>
      <Spacer h="30px" />

      <StaticField field="Hinge Type" value={storedStyle?.hingeType?.name} />

      <StaticField field="Hinge Color" value={storedStyle?.hingeColor?.name} />

      <StaticField
        field="Door Hardware"
        value={storedStyle?.doorHardware?.name}
      />

      <StaticField
        field="Drawer Hardware"
        value={storedStyle?.drawerHardware?.name}
      />

      <StaticField
        field="Closet Hardware Color"
        value={storedStyle?.closetHardwareColor?.name}
      />

      <StaticField field="Hardware notes" value={storedStyle?.hardwareNotes} />
    </Wrapper>
  );
};
