import { Link } from 'react-router-dom';
import { OutlineCSS } from 'shared/config/GlobalStyles';
import UtilService from 'shared/services/util.service';
import styled, { css } from 'styled-components';

// ButtonDefault

interface DefaultBtnCssProps {
  color?: string;
}

const ButtonDefaultCSS = css<DefaultBtnCssProps>`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  transition: 300ms;

  ${({ color }) => color && `color: ${color};`}

  &:focus-visible {
    ${OutlineCSS}
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-badge-count] {
    position: relative;

    &::before {
      align-items: center;
      background-color: ${({ theme }) => theme.valencia};
      border-radius: 20px;
      border: 2px solid ${({ theme }) => theme.white};
      color: white;
      content: attr(data-badge-count);
      display: flex;
      font-size: 10px;
      height: 15px;
      justify-content: center;
      position: absolute;
      left: calc(100% - 15px);
      top: -5px;
      min-width: 7px;
      padding: 0 4px;
    }
  }
`;

export const ButtonDefault = styled.button`
  ${ButtonDefaultCSS}
`;

export const ButtonClear = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

// ButtonPrimary
export const ButtonPrimaryCSS = css<{ bgColor?: string }>`
  ${ButtonDefaultCSS};
  align-items: center;
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.lynch};
  border-radius: 6px;
  border: 1px solid ${({ theme, bgColor }) => bgColor ?? theme.lynch};
  color: ${({ theme }) => theme.white};
  display: flex;
  padding: 12px 20px;

  &:hover {
    background-color: ${({ theme, bgColor }) =>
      bgColor ? UtilService.colorWithOpacity(bgColor, 0.8) : theme.kashmirBlue};
  }
`;

export const ButtonPrimary = styled.button<{ bgColor?: string }>`
  ${ButtonPrimaryCSS}
`;

// export const ButtonPrimary = ({
//   children,
//   ...props
// }: ComponentPropsWithoutRef<'button'>) => {
//   return <ButtonPrimaryContainer {...props}>{children}</ButtonPrimaryContainer>;
// };

// ButtonSecondary

export const ButtonSecondaryCSS = css`
  ${ButtonDefaultCSS};
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.lynch};
  color: ${({ theme }) => theme.lynch};
  display: flex;
  padding: 12px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.kashmirBlue};
    border-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.white};

    rect {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

export const ButtonSecondary = styled.button`
  ${ButtonSecondaryCSS}
`;

// export const ButtonSecondary = ({
//   children,
//   ...props
// }: ComponentPropsWithoutRef<'button'>) => {
//   return (
//     <ButtonSecondaryContainer {...props}>{children}</ButtonSecondaryContainer>
//   );
// };

// ButtonTertiary

export const ButtonTertiaryCSS = css`
  ${ButtonDefaultCSS};
  font-size: 12px;
  font-weight: 700;
  line-height: 6px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 1;
  }
`;

export const ButtonTertiaryDarkCSS = css`
  ${ButtonTertiaryCSS};
  height: 26px;
  background-color: ${({ theme }) => theme.nevada};
  color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.nevada};

  &:hover {
    background-color: ${({ theme }) => theme.manatee};
    border: 1px solid ${({ theme }) => theme.abbey};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.altoTwo};
    border: none;
  }
`;

export const ButtonTertiaryDark = styled.button`
  ${ButtonTertiaryDarkCSS}
`;

export const ButtonTertiaryLightCSS = css`
  ${ButtonTertiaryCSS}
  height: 26px;
  background-color: ${({ theme }) => theme.athensGray};
  border: 1px solid ${({ theme }) => theme.mischka};
  color: ${({ theme }) => theme.nevada};

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.nevada};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.wildSand};
    border: ${({ theme }) => theme.mischka};
    color: ${({ theme }) => theme.altoThree};
  }
`;

export const ButtonTertiaryLight = styled.button`
  ${ButtonTertiaryLightCSS}
`;

// Button element without any colors or borders
export const TabbableButton = styled.button`
  ${ButtonDefaultCSS}
  background-color: transparent;
  border: none;

  color: ${({ theme, color }) => color ?? theme.mineShaft};

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.kashmirBlue};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
`;

interface ButtonIconProps {
  iconColor?: string;
  hoverIconColor?: string;
}

export const ButtonIcon = styled(TabbableButton)<ButtonIconProps>`
  padding: 3px;

  svg {
    color: ${({ iconColor }) => iconColor};
  }

  &:hover svg {
    color: ${({ hoverIconColor }) => hoverIconColor};
  }

  &.expanded {
    .icon {
      transform: rotate(180deg);
    }
  }
`;

export const ButtonAttachmentIcon = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.athensGray};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.mischka};
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;

  svg > path {
    fill: ${({ theme }) => theme.nevada};
  }

  &:hover {
    background-color: ${({ theme }) => theme.zircon};
    border: 1px solid ${({ theme }) => theme.chambray};

    svg > path {
      fill: ${({ theme }) => theme.chambray};
    }
  }
`;

export const ButtonLink = styled(Link)`
  ${ButtonDefaultCSS}
  ${ButtonPrimaryCSS}
`;
