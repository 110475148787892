import { FC } from 'react';

import { PremiumSelectionPriceReview } from 'order/wizard/orderStyles/interface/OrderPriceReview';

import { H6, PSmall, SPAN } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import {
  ContainerMedium,
  ContainerSmall,
  ContainerXSmall,
} from 'shared/components/Container';
import { nevada } from 'shared/config/Colors';

interface PremiumSelectionPriceRowProps {
  row?: PremiumSelectionPriceReview;
  isShowCatalogChecked: boolean;
  isListPriceSelected: boolean;
  title: string;
}

const PremiumSelectionPriceRow: FC<PremiumSelectionPriceRowProps> = ({
  row,
  isShowCatalogChecked,
  isListPriceSelected,
  title,
}) => {
  return (
    <Wrapper flex middle>
      <ContainerMedium
        minWidth={200}
        maxWidth={200}
        align="left"
        isTableCell
        border={['right']}
      >
        <H6>
          {title.toUpperCase()}

          {row?.catalogOptionLabel && (
            <>
              <br />
              <SPAN color={nevada} fontSize={12}>
                {row.catalogOptionLabel}
              </SPAN>
            </>
          )}
        </H6>
      </ContainerMedium>

      {isShowCatalogChecked && (
        <ContainerXSmall isTableCell border={['right']} flexGrow>
          <PSmall>{row?.catalogQuantity ?? 0}</PSmall>
        </ContainerXSmall>
      )}

      <ContainerXSmall
        isTableCell
        border={['right']}
        flexGrow={!isShowCatalogChecked}
      >
        <PSmall>{row?.quantity ?? 0}</PSmall>
      </ContainerXSmall>

      {isShowCatalogChecked && (
        <>
          {/* unit */}
          <ContainerSmall isTableCell border={['right']}>
            <PSmall>
              {isListPriceSelected
                ? row?.catalogUnitPrice.toFixed(2) ?? '0.00'
                : row?.catalogNetUnitPrice.toFixed(2) ?? '0.00'}
            </PSmall>
          </ContainerSmall>

          {/* list */}
          <ContainerSmall isTableCell border={['right']}>
            <PSmall>
              {isListPriceSelected
                ? row?.catalogListPrice.toFixed(2) ?? '0.00'
                : row?.catalogNetPrice.toFixed(2) ?? '0.00'}
            </PSmall>
          </ContainerSmall>
        </>
      )}

      {/* unit */}
      <ContainerSmall isTableCell border={['right']}>
        <PSmall>
          {isListPriceSelected
            ? row?.unitPrice.toFixed(2) ?? '0.00'
            : row?.netUnitPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>

      {/* list */}
      <ContainerSmall isTableCell>
        <PSmall>
          {isListPriceSelected
            ? row?.listPrice.toFixed(2) ?? '0.00'
            : row?.netPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>
    </Wrapper>
  );
};

export default PremiumSelectionPriceRow;
