import qs from 'qs';
import { takeEvery, put, call } from 'redux-saga/effects';

import {
  dealershipActions,
  setAvailableDealerships,
} from 'overview/dealership/store/dealershipActions';

import { Action } from 'shared/interface/Action';
import { BaseField } from 'shared/interface/BaseField';
import { Dealership } from 'shared/interface/Dealership';
import { LoginFormProps } from 'shared/interface/LoginFormProps';
import { Token } from 'shared/interface/Token';
import { User } from 'shared/interface/User';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { setCurrentUser, setIsAuth, authActions } from './authActions';

import env from '../../env_config';

export function* getUserSaga(action: Action<unknown>) {
  try {
    const user: User = yield ApiService.get('api/administration/users/me');

    const isUserDealerOrSalesRep = user.permissions?.find(
      (perm) =>
        perm.name === userPermissionsValues.DEALER_VIEW_ORDERBOARD ||
        perm.name ===
          userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
    );

    if (isUserDealerOrSalesRep) {
      const availableDealerships: BaseField[] = yield ApiService.get(
        'api/administration/dealerships/available'
      );

      // set dealership only if there is only one in the list of the available dealershps
      if (availableDealerships.length === 1) {
        const dealership: Dealership = yield ApiService.get(
          `/api/administration/dealerships/${availableDealerships[0].id}`
        );

        yield put({
          type: dealershipActions.SET_CURRENT_DEALERSHIP,
          payload: dealership,
        });
      }

      yield put(setAvailableDealerships(availableDealerships));
    }

    if (user) {
      yield put(setCurrentUser(user));
      yield put(setIsAuth(true));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* loginSaga(action: Action<LoginFormProps>) {
  try {
    const data = {
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      client_secret: process.env.REACT_APP_KEYCLOAK_SECRET,
      grant_type: 'password',
      password: action.payload?.password,
      username: action.payload?.email,
    };

    const stringifiedData = qs.stringify(data);

    const result: Token = yield ApiService.post(
      '/auth/realms/Plato/protocol/openid-connect/token',
      stringifiedData,
      {
        baseURL: process.env.REACT_APP_API_KEYCLOAK,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (result) {
      action.onSuccess!(result);

      yield put(setIsAuth(true));
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* logoutSaga(action: Action<Token>) {
  try {
    const token: Token | undefined = action.payload;

    if (token) {
      const data = {
        client_id: env.keycloakClientId!,
        refresh_token: token.refresh_token,
      };

      yield ApiService.post(
        `/auth/realms/${env.keycloakRealm}/protocol/openid-connect/logout`,
        qs.stringify(data),
        {
          baseURL: env.keycloakGatewayUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (action.onSuccess) {
        yield call(action.onSuccess);
      }

      if (action.redirect) {
        yield call(action.redirect);
      }
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export default function* authSagas() {
  yield takeEvery(authActions.GET_LOGGEDIN_USER, getUserSaga);
  yield takeEvery(authActions.USER_LOGIN, loginSaga);
  yield takeEvery(authActions.USER_LOGOUT, logoutSaga);
}
