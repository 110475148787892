import { FC } from 'react';
import { uniqueId } from 'lodash';
import styled, { keyframes } from 'styled-components';

import { TextAlign } from 'shared/interface/CSSTypes';

export interface ISkeletonGridChild {
  w: number;
  h: number;
  spaceBottom: number;
  align?: TextAlign;
  borderRadius?: number;
}

export interface ISkeletonGridItem {
  padding: number;
  borderRadius: number;
  w: number;
  h: number;
  children: ISkeletonGridChild[];
}

interface SkeletonGridProps {
  columns: number;
  gap: number;
  items: ISkeletonGridItem[];
}

const SkeletonGridItems = styled.div<
  Pick<SkeletonGridProps, 'columns' | 'gap'>
>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: ${({ gap }) => gap}px;
`;

const glanceAnimation = (distance: number) => keyframes`
    100% {
        transform: translate(${distance * 1.2}px, -50%) rotate(-45deg);
    }
`;

export const SkeletonItem = styled.div<
  Pick<ISkeletonGridItem, 'padding' | 'borderRadius' | 'w' | 'h'>
>`
  padding: ${({ padding }) => padding}px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.silverSandTwo};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  opacity: 0.5;

  overflow: hidden;

  max-width: ${({ w }) => w}px;
  width: 100%;

  height: ${({ h }) => h}px;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 25%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.2) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    width: ${({ h }) => h * 2.2}px;
    height: ${({ w }) => w * 1.5}px;
    left: 0;
    top: 50%;
    transform-origin: center;
    transform: translate(-${({ h }) => h * 2.2}px, -50%) rotate(-45deg);

    animation: ${({ h }) => glanceAnimation(h)} 2s ease infinite;
  }
`;

export const SkeletonChild = styled.div<ISkeletonGridChild>`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  margin-bottom: ${({ spaceBottom }) => spaceBottom}px;
  margin-bottom: ${({ spaceBottom }) => spaceBottom}px;
  background-color: ${({ theme }) => theme.silverSand};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  position: relative;

  ${({ align }) => {
    switch (align) {
      case 'left':
        return `align-self: flex-start;`;
      case 'right':
        return `align-self: flex-end;`;
      case 'center':
        return `align-self: center;`;
      default:
        return ``;
    }
  }}

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    width: ${({ w }) => w * 2.2}px;
    height: ${({ h }) => h}px;
    left: 0;
    top: 50%;
    transform-origin: center;
    transform: translate(-${({ w }) => w * 2.2}px, -50%) rotate(-45deg);

    animation: ${({ w }) => glanceAnimation(w)} 2s ease infinite;
  }
`;

SkeletonChild.defaultProps = {
  align: 'left',
};

const SkeletonGrid: FC<SkeletonGridProps> = ({ columns, gap, items }) => {
  return (
    <SkeletonGridItems columns={columns} gap={gap}>
      {items.map((item) => (
        <SkeletonItem
          padding={item.padding}
          borderRadius={item.borderRadius}
          w={item.w}
          key={uniqueId()}
          h={item.h}
        >
          {item.children?.map((child) => (
            <SkeletonChild
              key={uniqueId()}
              borderRadius={item.borderRadius}
              {...child}
            />
          ))}
        </SkeletonItem>
      ))}
    </SkeletonGridItems>
  );
};

export default SkeletonGrid;
