import { call, put, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { User } from 'shared/interface/User';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import {
  AddUserFormProps,
  AddUserDataProps,
  AddCSRUserDataProps,
} from '../interfaces/AddUserFormProps';

import {
  GetUserRequest,
  manageUsersActions,
  setUser,
  UpdateUserRequest,
} from './manageUsersActions';

function* getUserList(action: Action<string>) {
  try {
    const response: User[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/${action.payload}/users`
    );

    yield put({
      type: manageUsersActions.SET_USER_LIST,
      payload: response,
    });

    if (action.onSuccess != null) {
      action.onSuccess(response);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCSRUserList(action: Action<unknown>) {
  try {
    const csrs: User[] = yield call(
      ApiService.get,
      `/api/administration/users/csr`
    );

    yield put({
      type: manageUsersActions.SET_USER_LIST,
      payload: csrs,
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* addNewUserSaga(action: Action<AddUserFormProps>) {
  try {
    const { userType, dealershipId, ...userData } = action.payload!;

    yield ApiService.post<AddUserDataProps>(
      `api/administration/dealerships/${dealershipId}/${userType}`,
      userData
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    const error = e as ServerErrorResponse;

    if (error?.status === 409) {
      action.onFailed!({ status: 409 });
    }

    if (error?.status === 500) {
      action.onFailed!({
        status: error.status,
        title: 'Something went wrong.',
      });
    }

    if (!error) {
      action.onFailed!({
        status: undefined,
        title: 'Something went wrong.',
      });
    }
  }
}

function* addNewCSRUser(action: Action<AddCSRUserDataProps>) {
  try {
    yield ApiService.post<AddUserDataProps>(
      `/api/administration/users/csr`,
      action.payload!
    );

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (e) {
    const error = e as ServerErrorResponse;

    if (error?.status === 409) {
      action.onFailed!({ status: 409 });
    }

    if (error?.status === 500) {
      action.onFailed!({
        status: error.status,
        title: 'Something went wrong.',
      });
    }

    if (!error) {
      action.onFailed!({
        status: undefined,
        title: 'Something went wrong.',
      });
    }
  }
}

function* enableDisableUser(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/administration/users/${action.payload}/change-status`
    );

    yield put({
      type: manageUsersActions.UPDATE_USER_ENABLED_DISABLED_STATUS,
      payload: action.payload,
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getUser(action: Action<GetUserRequest>) {
  const { userId } = action.payload!;

  try {
    const user: User = yield ApiService.get(
      `/api/administration/users/${userId}`
    );

    yield put(setUser(user));
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateUser(action: Action<UpdateUserRequest>) {
  try {
    const { userId, ...userData } = action.payload!;

    yield ApiService.put(`/api/administration/users/${userId}`, userData);

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* manageUsersSaga() {
  yield takeEvery(manageUsersActions.GET_USER_LIST, getUserList);
  yield takeEvery(manageUsersActions.GET_CSR_USER_LIST, getCSRUserList);
  yield takeEvery(manageUsersActions.ADD_NEW_USER, addNewUserSaga);
  yield takeEvery(manageUsersActions.ADD_NEW_CSR_USER, addNewCSRUser);
  yield takeEvery(manageUsersActions.ENABLE_USER, enableDisableUser);
  yield takeEvery(manageUsersActions.GET_USER, getUser);
  yield takeEvery(manageUsersActions.EDIT_USER, updateUser);
}
