import { AnyAction } from 'redux';

import { INotification } from 'overview/interface/Notification';

import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  NotificationsActions,
  NotificationCountsPayload,
} from './notificationsActions';

interface NotificationsReducerState {
  notifications: PaginatedItems<INotification> | null;
  unreadCount: number;
  unseenCount: number;
  unreadCollaborationCount: number;
  unseenCollaborationCount: number;
  syncToolFailNotification: INotification | null;
}

const initialState: NotificationsReducerState = {
  notifications: null,
  unseenCount: 0,
  unreadCount: 0,
  unreadCollaborationCount: 0,
  unseenCollaborationCount: 0,
  syncToolFailNotification: null,
};

export const notificationsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case NotificationsActions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload as PaginatedItems<INotification>,
      };
    case NotificationsActions.SET_READ_STATUS:
      return {
        ...state,
        unreadCount: action.payload.showCollaborationOnly
          ? state.unreadCount
          : state.unreadCount +
            action.payload.notificationIds.length *
              (action.payload.read ? -1 : 1),
        unreadCollaborationCount: action.payload.showCollaborationOnly
          ? state.unreadCollaborationCount +
            action.payload.notificationIds.length *
              (action.payload.read ? -1 : 1)
          : state.unreadCollaborationCount,
        notifications: {
          ...state.notifications,
          items: (state.notifications?.items ?? ([] as INotification[])).map(
            (notif) => {
              if (action.payload.notificationIds.includes(notif.id)) {
                return {
                  ...notif,
                  isRead: action.payload.read,
                } as INotification;
              }

              return notif;
            }
          ),
        } as PaginatedItems<INotification>,
      };
    case NotificationsActions.SET_MARK_ALL_AS_READ:
      return {
        ...state,
        unreadCount: action.payload ? state.unreadCount : 0,
        unseenCount: action.payload ? state.unseenCount : 0,
        unreadCollaborationCount: action.payload
          ? 0
          : state.unreadCollaborationCount,
        unseenCollaborationCount: action.payload
          ? 0
          : state.unseenCollaborationCount,
        notifications: {
          ...state.notifications,
          items: (state.notifications?.items ?? ([] as INotification[])).map(
            (notif) =>
              ({
                ...notif,
                isRead: true,
              } as INotification)
          ),
        } as PaginatedItems<INotification>,
      };
    case NotificationsActions.SET_NOTIFS_COUNTS: {
      const {
        unreadCount,
        unseenCount,
        unreadCollaborationCount,
        unseenCollaborationCount,
      } = action.payload as NotificationCountsPayload;
      return {
        ...state,
        unseenCount,
        unreadCount,
        unreadCollaborationCount,
        unseenCollaborationCount,
      };
    }
    case NotificationsActions.SET_AS_SEEN:
      return {
        ...state,
        unseenCount: action.payload.onlyCollaboration
          ? state.unseenCount
          : state.unseenCount - (action.payload.notifs as string[]).length,
        unseenCollaborationCount: action.payload.onlyCollaboration
          ? state.unseenCollaborationCount -
            (action.payload.notifs as string[]).length
          : state.unseenCollaborationCount,
      };
    case NotificationsActions.SET_UNSEEN_COUNT:
      return {
        ...state,
        unseenCount: (state.unseenCount + action.payload) as number,
      };
    case NotificationsActions.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: (state.unreadCount + action.payload) as number,
      };

    case NotificationsActions.SYNC_TOOL_FAIL_NOTIFICATION:
      return {
        ...state,
        syncToolFailNotification: action.payload,
      };
    case NotificationsActions.CLEAN_SYNC_TOOL_FAIL_NOTIFICATION:
      return {
        ...state,
        syncToolFailNotification: null,
      };
    default:
      return state;
  }
};
