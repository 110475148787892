const local = {
  apiGatewayUrl: 'https://gateway.dev.plato-connect.com',
  apiAppUrl: 'https://app.dev.plato-connect.com',
  keycloakRealm: 'Plato',
  keycloakGatewayUrl: 'https://login.dev.plato-connect.com',
  keycloakClientId: 'plato-app',
  keycloakSettings: 'keycloakSettingsDevelopment.json',
  optiSKDKey: '4c1U4yaM32fGr9PybVEQ6',
  dropboxApiKey: 'cj5w9xsblyyre94',
};

const localBE = {
  apiGatewayUrl: 'https://localhost:10143',
  apiAppUrl: 'https://localhost:10143',
  keycloakRealm: 'Plato',
  keycloakGatewayUrl: 'https://localhost:10080/auth',
  keycloakClientId: 'plato-app',
  keycloakSettings: 'keycloakSettingsLocal.json',
  optiSKDKey: '4c1U4yaM32fGr9PybVEQ6',
  dropboxApiKey: 'cj5w9xsblyyre94',
};

const staging = {
  apiGatewayUrl: 'https://gateway.stage.plato-connect.com',
  apiAppUrl: 'https://app.stage.plato-connect.com',
  keycloakGatewayUrl: 'https://login.dev.plato-connect.com',
  keycloakRealm: 'PlatoStage',
  keycloakClientId: 'plato-app',
  keycloakSettings: 'keycloakSettingsStaging.json',
  optiSKDKey: 'ThAs4LSb7M4ZshTvFu9su',
  dropboxApiKey: 'cj5w9xsblyyre94',
};

const production = {
  apiGatewayUrl: 'https://gateway.plato-connect.com',
  apiAppUrl: 'https://app.plato-connect.com',
  keycloakGatewayUrl: 'https://login.plato-connect.com',
  keycloakRealm: 'Plato',
  keycloakClientId: 'plato-app',
  keycloakSettings: 'keycloakSettingsProduction.json',
  optiSKDKey: 'DjjihiN6V6TegmdRdhA2m',
  dropboxApiKey: 'cj5w9xsblyyre94',
};

let config;

switch (process.env.REACT_APP_ENV) {
  case 'production':
    config = production;
    break;
  case 'staging':
    config = staging;
    break;
  case 'localBE':
    config = localBE;
    break;
  default:
    config = local;
    break;
}

export default {
  ...config,
};
