import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import checkIconSrc from 'assets/icons/check-with-circle.svg';

import { ProductLine } from 'order/interfaces/ProductLine';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import { Info } from 'shared/components/Info';
import { RadioButton } from 'shared/components/RadioButton';
import { Tooltip } from 'shared/components/Tooltip';
import Img from 'shared/components/Img';

import { ProductLineForm } from '../OrderStylesProductLine';

const InfoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;

  &.selected {
    right: 60px;

    svg {
      color: ${({ theme }) => theme.lynch};
    }
  }
`;

export const StyledRadioButtonContainer = styled.div`
  position: relative;

  input:checked + label,
  &.selected label {
    background-color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.lynch};

    svg {
      color: ${({ theme }) => theme.lynch};
    }

    &:after {
      display: block;
    }
  }

  input {
    &:not(:disabled) + label:hover {
      border-color: ${({ theme }) => theme.lynch};
    }

    &:disabled + label {
      opacity: 0.3;
      pointer-events: none;
    }
  }
`;

const StyledRadioButton = styled(RadioButton)`
  height: 260px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.silverSand};
  padding-left: 0;
  transition: 300ms ease;

  &:before {
    display: none;
  }

  &:after {
    background: url(${checkIconSrc});
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    right: 18px;
    top: 18px;
    left: auto;
  }
`;

interface ProductLineOptionProps {
  productLine: ProductLine;
  isDisabled: boolean;
}

const ProductLineOption = ({
  productLine,
  isDisabled,
}: ProductLineOptionProps) => {
  const { register, watch } = useFormContext<ProductLineForm>();

  const productLineOptionIdWatched = watch('productLineOptionId');

  const productLineDescriptions = {
    [ProductLineEnums.PRODUCT_LINE_CUSTOM]:
      'Our most exacting line, Personalized Custom features solid wood construction that can be cut, styled and finished precisely as you’ve imagined—down to the smallest detail. Choose from a meticulously curated array of woods, door styles, stains, paints and glazes. Or work with our craftsman to develop something truly one of a kind through one of our speciality programs, including SelectWoods™, which allows you to specify virtually any wood species. This is the line designed for the most discerning of homeowners with tastes ranging from the traditional to trendy.',
    [ProductLineEnums.PRODUCT_LINE_ICS]:
      'The Curate Closet System is our ready-to-assemble line designed to make organization a dream. Whether it’s a walk-in or reach-in closet, mud room, hobby nook, laundry, or pantry, Curate can transform your space with details like soft-closing hinges, solid maple dovetail drawer boxes and durable thermally fused laminate panels. Select your hardware elements and choose from an array of beautifully textured finishes to reflect the style of your space. Or work with us to further tailor your system with speciality closet finishes.',
    [ProductLineEnums.PRODUCT_LINE_INOVAE]:
      'For homeowners who love the timeless and tailored beauty of wood and want to optimize their space, Inovae blends sleek full-access design with a customizable selection of woods, styles and finishes. It’s a fusion that gives you the freedom to create something truly stunning and unique to complement nearly any home. Naturally, premier quality materials, including solid wood dovetail drawers and durable melamine—or optional wood or maple—interiors bring signature Plato craftsmanship and unparalleled flexibility to the spaces you design.',
    [ProductLineEnums.PRODUCT_LINE_INOVAE2O]: '',
    [ProductLineEnums.PRODUCT_LINE_PRELUDE]:
      'Developed to bring beautiful Plato craftsmanship to projects that don’t require the nuances of a fully custom design, Prelude Semi-Custom is constructed with the finest quality woods and materials. With a myriad of door styles, stains, paints and glazes to choose from, there are plenty of options to add thoughtful details without compromising your budget. Each cabinet in the line is also crafted to seamlessly blend with our Personalized Custom series, so you can round out your plans with speciality pieces and create a space that feels truly unique to the home you’re designing.',
    [ProductLineEnums.PRODUCT_LINE_REVOLAE]:
      'The textures, lusters, grains and geometry of the Revolae Euro Custom line push the boundaries of design to create unexpected and sophisticated spaces that speak to the sensibilities of modern homeowners with specific tastes. Cutting-edge materials carefully manufactured to deliver consistency across every piece give you the option to design stylish rooms. Inspired finishes ranging from woodgrain to high gloss come in an array of colors and patterns, so you can tailor-make a bold statement in any room.',
  };

  const productLineDescription =
    productLineDescriptions[
      productLine.name as keyof typeof productLineDescriptions
    ];

  return (
    <StyledRadioButtonContainer
      className={
        productLine.id === productLineOptionIdWatched ? 'selected' : ''
      }
    >
      <StyledRadioButton
        id={`productLineOption--${productLine.id}`}
        value={productLine.id}
        {...register('productLineOptionId', { required: true })}
        disabled={isDisabled}
      >
        <Img src={productLine.imageUrl} alt={productLine.name} />
      </StyledRadioButton>

      {(productLineDescription?.length ?? 0) > 0 && (
        <InfoContainer
          className={
            productLine.id === productLineOptionIdWatched ? 'selected' : ''
          }
        >
          <Info>
            <Tooltip minWidth={300} position="left">
              {productLineDescription}
            </Tooltip>
          </Info>
        </InfoContainer>
      )}
    </StyledRadioButtonContainer>
  );
};

export default ProductLineOption;
