import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';

import { Wrapper } from 'shared/components/Wrapper';
import { ShippingAddress } from 'shared/interface/ShippingAddress';

import OrderShippingAddress from './OrderShippingAddress/OrderShippingAddress';

const DealershipInfo = styled.div`
  ${Wrapper} {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DealershipInfoLabel = styled.div`
  /* min-width: 200px; */
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
`;

const DealershipInfoData = styled.div`
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.nevada};
  font-weight: 600;
`;

const OrderDealershipInfo = () => {
  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const mailingAddress = dealership?.addresses.find(
    (address: ShippingAddress) => address.typeDescription === 'Mailing'
  );

  return (
    <DealershipInfo>
      <Wrapper flex middle>
        <DealershipInfoLabel>Dealership Name</DealershipInfoLabel>
        <DealershipInfoData>
          {dealership ? dealership.name : '-not set-'}
        </DealershipInfoData>
      </Wrapper>
      <Wrapper flex middle>
        <DealershipInfoLabel>Dealership Address</DealershipInfoLabel>
        <DealershipInfoData>
          {mailingAddress && <OrderShippingAddress address={mailingAddress} />}
        </DealershipInfoData>
      </Wrapper>
      <Wrapper flex middle>
        <DealershipInfoLabel>Phone</DealershipInfoLabel>
        <DealershipInfoData>
          {dealership ? dealership.phoneNumber : '-not set-'}
        </DealershipInfoData>
      </Wrapper>
      <Wrapper flex middle>
        <DealershipInfoLabel>Email</DealershipInfoLabel>
        <DealershipInfoData>
          {dealership && dealership.email ? dealership.email : '-not set-'}
        </DealershipInfoData>
      </Wrapper>
      <Wrapper flex middle>
        <DealershipInfoLabel>Plato Account#</DealershipInfoLabel>
        <DealershipInfoData>
          {dealership ? dealership.accountNumber : '-not set-'}
        </DealershipInfoData>
      </Wrapper>
    </DealershipInfo>
  );
};

export default OrderDealershipInfo;
