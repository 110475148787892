import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { useHasPermissions } from './useHasPermissions';
import { useIsOrderInStatus } from './useIsOrderInStatus';

export const useCanEditOrder = () => {
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const [canEdit, setCanEdit] = useState(false);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const isCurrentUserEditor = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserEditor
  );

  const isCurrentUserOwner = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserOwner
  );

  const isCurrentUserViewer = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserViewer
  );

  const orderIsLocked = order?.isLocked ?? false;

  const isOrderCanceled = useIsOrderInStatus({
    status: OrderStatusEnums.Canceled,
  });

  const isOrderInvoiced = useIsOrderInStatus({
    status: OrderStatusEnums.Invoiced,
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const canUpdateOrder = useHasPermissions(userPermissionsValues.ORDER_UPDATE);

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  useEffect(() => {
    if (
      order === null ||
      isUserDealerOrSalesRep === undefined ||
      isUserCSR === undefined ||
      isOrderCanceled === null ||
      isOrderInvoiced === null
    )
      return;

    const canEditLock =
      order &&
      editMode &&
      !isOrderCanceled &&
      !isOrderInvoiced &&
      ((isUserDealerOrSalesRep && orderIsLocked) ||
        (!orderIsLocked &&
          (isUserSalesRep ||
            isCurrentUserEditor ||
            isCurrentUserOwner ||
            (isUserDealerOrSalesRep &&
              canUpdateOrder &&
              !isCurrentUserViewer))) ||
        (isUserCSR && (isOrderSubmitted || orderIsLocked)) ||
        (!isCurrentUserViewer && isUserDealerOrSalesRep && !orderIsLocked));

    setCanEdit(canEditLock);
  }, [
    order,
    isUserDealerOrSalesRep,
    isUserCSR,
    isOrderCanceled,
    isOrderInvoiced,
  ]);

  return canEdit;
};
