import { RootState } from 'store';
import { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import {
  CustomTD,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'shared/components/Table';

import { lynch, manatee } from 'shared/config/Colors';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { P } from 'shared/components/Typography';
import { SortEnums } from 'shared/enum/sortEnums';
import { TabbableButton } from 'shared/components/Button';
import { TableColumnIdEnums } from 'shared/enum/tableEnums';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import SVG from 'shared/components/SVG';

import {
  getDealershipCustomAddresses,
  sortDealershipCustomAddresses,
} from '../store/dealershipActions';

import { CustomAddressForm } from './CustomAddressForm';

export const DealershipCustomAddressTable = () => {
  const dispatch = useAppDispatch();

  const [sortDir, setSortDir] = useState(SortEnums.ASC);
  const [sortColumn, setSortColumn] = useState(TableColumnIdEnums.CITY);

  const customAddresses = useSelector(
    (state: RootState) => state.dealershipReducer.customAddresses
  );

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const onSortHandle = (columnName: SetStateAction<TableColumnIdEnums>) => {
    setSortColumn(columnName);

    if (columnName === sortColumn && sortDir === SortEnums.ASC) {
      setSortDir(SortEnums.DESC);
    } else {
      setSortDir(SortEnums.ASC);
    }
  };

  const onSuccessGetDealershipCustomAddresses = () => {
    dispatch(
      sortDealershipCustomAddresses({
        sortColumn,
        asc: sortDir === SortEnums.ASC,
      })
    );
  };

  const getDealershipAddresses = () => {
    if (dealership) {
      dispatch(
        getDealershipCustomAddresses(
          dealership.id,
          onSuccessGetDealershipCustomAddresses
        )
      );
    }
  };

  // Getting addresses for the first time
  useEffect(() => {
    if (dealership) {
      getDealershipAddresses();
    }
  }, [dealership]);

  // Handle sorting after clicking a sort handle
  useEffect(() => {
    dispatch(
      sortDealershipCustomAddresses({
        sortColumn,
        asc: sortDir === SortEnums.ASC,
      })
    );
  }, [sortColumn, sortDir]);

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>SHIP TO NAME</Th>
          <Th>STREET ADDRESS</Th>
          <Th>ADDRESS LINE 2</Th>
          <Th
            data-direction={sortDir}
            data-active={sortColumn === TableColumnIdEnums.CITY}
            onClick={() => onSortHandle(TableColumnIdEnums.CITY)}
          >
            CITY
          </Th>
          <Th>STATE</Th>
          <Th
            data-direction={sortDir}
            data-active={sortColumn === TableColumnIdEnums.ZIPCODE}
            onClick={() => onSortHandle(TableColumnIdEnums.ZIPCODE)}
          >
            ZIP
          </Th>
          <Th>COUNTRY</Th>
          <Th>PHONE NO</Th>
        </Tr>
      </Thead>
      <Tbody>
        {customAddresses?.map((address) => (
          <Tr key={address?.id}>
            <Td>{address.shipTo}</Td>
            <Td>{address.street}</Td>
            <Td>{address.number}</Td>
            <Td>{address.city}</Td>
            <Td>{address.state}</Td>
            <Td>{address.zipCode}</Td>
            <Td>{address.country}</Td>
            <Td>{address.phoneNumber}</Td>
            <Td>
              <CustomTD flex center>
                <ModalWrapper
                  trigger={
                    <TabbableButton>
                      <SVG
                        icon={<EditIcon />}
                        color={manatee}
                        hoverColor={lynch}
                      />
                    </TabbableButton>
                  }
                  modal
                  closeOnEscape={false}
                >
                  {(close: () => void) => (
                    <Modal title="Edit address" close={close} withoutFooter={1}>
                      <CustomAddressForm
                        onClose={() => {
                          close();
                          getDealershipAddresses();
                        }}
                        editMode
                        data={address}
                      />
                    </Modal>
                  )}
                </ModalWrapper>
              </CustomTD>
            </Td>
          </Tr>
        ))}

        {!customAddresses?.length && (
          <Tr>
            <Td colSpan={9}>
              <P>No Custom shipping addresses yet.</P>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
