import { FC } from 'react';
import styled from 'styled-components';

import { Order } from 'order/interfaces/Order';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import { PMedium } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';

import { OrderHeaderTestContainerEnum } from 'tests/enums/OrderHeaderEnums';
import { testId } from 'tests/utils';
import UtilService from 'shared/services/util.service';

const PriorityShipWeekWrapper = styled(Wrapper)`
  margin-right: 15px;
`;

interface ShipWeekProps {
  order: Order;
}

const ShipWeek: FC<ShipWeekProps> = ({ order }) => {
  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  return isOrderSubmitted && order.shipWeekDate ? (
    <PriorityShipWeekWrapper
      flex
      middle
      {...testId(OrderHeaderTestContainerEnum.ORDER_HEADER_SHIP_WEEK)}
    >
      <PMedium>
        {UtilService.getShipWeekLabel({
          defaultValue: '',
          scheduleType: order.scheduleType,
          shipWeekDate: order.shipWeekDate,
          shipWeekDescription: order.shipWeekDescription,
        })}
      </PMedium>
    </PriorityShipWeekWrapper>
  ) : null;
};

export default ShipWeek;
