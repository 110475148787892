import { css } from 'styled-components';

export const TabStyles = css<{ nounderline?: number }>`
  color: ${({ theme }) => theme.nevada};
  font-size: 14px;
  font-weight: normal;
  margin-right: 43px;
  padding: 15px 0;
  position: relative;
  transition: 300ms ease;

  ${({ nounderline, theme }) =>
    !nounderline &&
    css`
      &::before {
        content: '';
        background-color: ${theme.kashmirBlue};
        border-radius: 3px;
        bottom: 1px;
        height: 2px;
        left: -12px;
        opacity: 0;
        border-radius: 3px;
        position: absolute;
        right: -12px;
        transform: scaleX(0);
        transition: 300ms ease;
      }
    `}

  &:hover {
    color: ${({ theme }) => theme.kashmirBlue};
  }

  &.active {
    color: ${({ theme }) => theme.kashmirBlue};

    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    .visible {
      font-weight: 600;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;
