import { RootState } from 'store';
import { takeEvery, call, put, select } from 'redux-saga/effects';

import { ProductLine } from 'order/interfaces/ProductLine';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';

import { FinishImpressionRequest } from '../../interface/FinishImpression';
import { LineItemDetailsImportError } from '../../interface/LineItem';
import { SelectOptionProps } from '../../../../../shared/interface/SelectOptionProps';
import { Style } from '../../interface/Style';
import { StyleOverride } from '../../interface/StyleOverride';
import { StyleOverrideEnum } from '../../enums/StyleOverrideEnum';
import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { orderStylesSpecificationActions } from './specifications/orderStylesSpecificationActions';

import {
  getFinishImpressionData,
  getFinishImpressionOptions,
} from './specifications/orderStylesSpecificationSagas';

import {
  changeStylesStep,
  ConvertStyleRequest,
  CreateStyleRequest,
  GetReplacementProductLinesRequest,
  GetStyleRequest,
  orderStylesActions,
  setReplacementProductLines,
  setReplacementStyle,
  setStyle,
  setStyleFields,
  setStyleName,
  UpdateStyleNameRequest,
} from './orderStylesActions';

function* createStyle(action: Action<CreateStyleRequest>) {
  try {
    const { orderId, ...rest } = action.payload!;

    const styleId: string = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/styles`,
      rest
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, styleId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getStyle(action: Action<GetStyleRequest>) {
  try {
    const { orderId, styleId, overrideReason } = action.payload!;

    const style: Style = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}`
    );

    yield put(
      setStyle({
        ...style,
        ...((overrideReason ?? '').length >= 1 && {
          override: {
            overridenBy: StyleOverrideEnum.YET_TO_BE_OVERRIDEN,
            overridenReason: overrideReason,
            overridenOnUtc: StyleOverrideEnum.YET_TO_BE_OVERRIDEN,
          } as StyleOverride,
        }),
      })
    );

    if (style.finishImpression) {
      yield getFinishImpressionOptions({
        type: orderStylesSpecificationActions.GET_FINISH_IMPRESSION_OPTIONS,
        payload: style.productLine.id,
      });

      const cabinetBoxMaterialOption = style.cabinetBoxMaterial
        ? (UtilService.mapObjectToSelectOptions(
            style.cabinetBoxMaterial,
            undefined,
            undefined,
            { customParams: ['upcharge'] }
          ) as SelectOptionProps)
        : null;

      if (cabinetBoxMaterialOption) {
        cabinetBoxMaterialOption.upcharge = style.cabinetBoxMaterial.upcharge;
      }

      const singleFinishImpressionRequest = {
        finishImpressionId: style.finishImpression.id,
        productLineId: style.productLine.id,
        cabinetBoxMaterial: cabinetBoxMaterialOption,
        cabinetBoxMaterialUpcharge: UtilService.withDecimal(
          null,
          cabinetBoxMaterialOption?.upcharge?.toString() as string
        ),
        specialCabinetBoxMaterial: style.specialCabinetBoxMaterial,
        naturalInterior: style.naturalInterior ?? false,
        woodTape: style.woodTape ?? true,
      } as FinishImpressionRequest;

      yield getFinishImpressionData({
        type: orderStylesSpecificationActions.GET_FINISH_IMPRESSION_DATA,
        payload: singleFinishImpressionRequest,
      });
    }

    const storedStyle: Style = yield select(
      (state: RootState) => state.orderStylesReducer.style
    );

    const styleStep: number | undefined = yield select(
      (state: RootState) => state.orderStylesReducer.style?.step
    );

    if (styleStep) {
      yield put(
        changeStylesStep(
          storedStyle.isComplete
            ? StylesStepsEnum.SPECIFICATIONS
            : (styleStep.toString() as StylesStepsEnum)
        )
      );

      yield put(
        setStyleFields({
          styleId: storedStyle.id!,
          name: storedStyle.name,
          productLine: storedStyle.productLine as ProductLine,
        })
      );
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateStyleName(action: Action<UpdateStyleNameRequest>) {
  try {
    const { name, orderId, styleId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/styles/${styleId}/name`,
      { name }
    );

    yield put(setStyleName(name));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* duplicateStyle(action: Action<GetStyleRequest>) {
  const { orderId, styleId } = action.payload!;
  try {
    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/styles/${styleId}/duplicate`,
      {}
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getReplacementProductLines(
  action: Action<GetReplacementProductLinesRequest>
) {
  try {
    const { productLineId, styleId, orderId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);

    const replacementProcuctLines: ProductLine[] = yield call(
      ApiService.get,
      `/api/catalog/productlines/conversions`,
      {
        params: queryParams,
      }
    );

    const style: Style = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}`,
      {
        params: queryParams,
      }
    );

    yield put(setReplacementProductLines(replacementProcuctLines));
    yield put(setReplacementStyle(style));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* convertStyle(action: Action<ConvertStyleRequest>) {
  try {
    const { productLineId, styleId, orderId } = action.payload!;

    const response: LineItemDetailsImportError[] = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/styles/${styleId}/convert`,
      {
        productLineId,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, response);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderStylesSagas() {
  yield takeEvery(orderStylesActions.CREATE_STYLE, createStyle);
  yield takeEvery(orderStylesActions.GET_STYLE, getStyle);
  yield takeEvery(orderStylesActions.UPDATE_STYLE_NAME, updateStyleName);
  yield takeEvery(orderStylesActions.DUPLICATE_STYLE, duplicateStyle);
  yield takeEvery(
    orderStylesActions.GET_PRODUCT_LINES_FOR_REPLACEMENT,
    getReplacementProductLines
  );
  yield takeEvery(orderStylesActions.CONVERT_STYLE, convertStyle);
}
