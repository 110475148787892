import styled from 'styled-components';

interface ToggleButtonProps {
  userEnabled: boolean;
  onEnableChange: (userID: string, enabled: boolean) => void;
  userID: string;
  disabled: boolean;
}

interface ToggleButtonContainerProps {
  enabled: boolean;
  disabled: boolean;
}

const ToggleButtonCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: transform 300ms ease;
  background: ${({ theme }) => theme.white};
`;

const ToggleButtonContainer = styled.div<ToggleButtonContainerProps>`
  display: flex;
  align-items: center;
  padding: 0 2px;
  width: 46px;
  height: 22px;
  background-color: ${({ enabled, theme }) =>
    enabled ? '#748194' : theme.mischka};
  border-radius: 16px;
  transition: background-color 300ms ease;

  ${ToggleButtonCircle} {
    ${({ enabled }) => `transform: translateX(${enabled ? '24' : '0'}px)`}
  }

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ToggleButton = ({
  onEnableChange,
  userEnabled,
  disabled,
  userID,
}: ToggleButtonProps) => {
  const handleClickEvent = () => {
    onEnableChange(userID, userEnabled);
  };
  return (
    <ToggleButtonContainer
      onClick={handleClickEvent}
      enabled={userEnabled}
      disabled={disabled}
    >
      <ToggleButtonCircle />
    </ToggleButtonContainer>
  );
};
