import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as DealershipSettingsIcon } from 'assets/icons/dealership-settings.svg';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import { ContentWrapper, Spacer } from 'shared/components/Layout';
import { H1 } from 'shared/components/Typography';
import { LinkText } from 'shared/components/LinkText';
import { Tabs, TabList, Tab, TabPanel } from 'shared/components/Tabs';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import EmptyState from 'shared/components/EmptyState';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import MetaTitle from 'shared/components/MetaTitle';

import { DealershipAboutPage } from './DealershipAboutPage';
import { DealershipPricingPage } from './DealershipPricingPage';

import {
  getAvailableDealerships,
  getDealershipGlobalPriceVariables,
  getDealershipSalesRep,
  getFilteredDealerships,
} from '../store/dealershipActions';

import SelectDealership from '../components/SelectDealership';

export const DealershipPage = () => {
  const dispatch = useAppDispatch();

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const isUserDealer = useHasPermissions(
    userPermissionsValues.DEALER_VIEW_ORDERBOARD
  );

  const [isLoading, setIsLoading] = useState(false);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const availableDealerships = useSelector(
    (state: RootState) => state.dealershipReducer.availableDealerships
  );

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const salesRepresentative = useSelector(
    (state: RootState) => state.dealershipReducer.salesRepresentative
  );

  const isUserSalesRepOrCsr = isUserSalesRep || isUserCSR;

  const priceVariablesGlobal = useSelector(
    (state: RootState) => state.dealershipReducer.priceVariablesGlobal
  );

  const customAddresses = useSelector(
    (state: RootState) => state.dealershipReducer.customAddresses
  );

  const getDealershipRestInfo = () => {
    if (!dealership) return;
    if (dealership.salesRepresentativeId) {
      dispatch(
        getDealershipSalesRep({
          dealershipId: dealership.id,
        })
      );
    }

    dispatch(getDealershipGlobalPriceVariables(dealership.id));
  };

  useEffect(() => {
    if (availableDealerships === null) {
      dispatch(getAvailableDealerships());
    }
  }, [availableDealerships]);

  useEffect(() => {
    if (isUserCSR) {
      dispatch(getFilteredDealerships());
    }
  }, [isUserCSR]);

  useEffect(() => {
    if (dealership) {
      setIsLoading(true);
      getDealershipRestInfo();
    }
  }, [dealership]);

  useEffect(() => {
    if (
      dealership &&
      (dealership.salesRepresentativeId ? salesRepresentative : true) &&
      priceVariablesGlobal &&
      customAddresses
    ) {
      setIsLoading(false);
    }
  }, [dealership, salesRepresentative, priceVariablesGlobal, customAddresses]);

  return (
    <>
      <MetaTitle title="Dealership Settings | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft justifyStart between={false}>
          <H1>Dealership Settings</H1>

          {isUserSalesRepOrCsr && (
            <SelectDealership setIsLoading={setIsLoading} />
          )}
        </OverviewHeaderLeft>
      </OverviewHeader>

      {isLoading && (
        <LoaderFullScreen
          position="fixed"
          top={64}
          left={244}
          flex
          center
          middle
        >
          <Loader size={120} platoLogo={1} />
        </LoaderFullScreen>
      )}

      {!isLoading && !dealership && isUserSalesRepOrCsr && (
        <EmptyState
          iconSize={80}
          icon={<DealershipSettingsIcon />}
          title="Select Dealership"
          message="Please select one of the available dealerships"
        />
      )}

      {((isUserSalesRepOrCsr && dealership) || isUserDealer) && (
        <Tabs>
          <TabList>
            <Tab>
              <LinkText text="About" />
            </Tab>

            <Tab>
              <LinkText text="Pricing variables" />
            </Tab>
          </TabList>

          <Spacer h="16px" />

          <TabPanel>
            <ContentWrapper>
              <DealershipAboutPage />
            </ContentWrapper>
          </TabPanel>

          <TabPanel>
            <ContentWrapper>
              <DealershipPricingPage />
            </ContentWrapper>
          </TabPanel>
        </Tabs>
      )}
    </>
  );
};
