export enum Inovae2OStyleSpecificationsTestTextAreaEnum {
  STYLE_NOTES = 'style-notes-textarea',
  MATERIAL_NOTES = 'material-notes-textarea',
  INTERIOR_NOTES = 'interior-notes-textarea',
}

export enum Inovae2OStyleSpecificationsTestComboBoxEnum {
  MATERIAL_GROUP_CB = 'material-group-cb',
  CABINET_BOX_MATERIAL_CB = 'cabinet-box-material-cb',
  GRAIN_DIRECTION_CB = 'grain-direction-cb',
}
