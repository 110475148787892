import { FC, ReactElement } from 'react';
import { hawkesBlue, lynch, white } from 'shared/config/Colors';
import styled from 'styled-components';
import SVG from './SVG';

interface IconCircleProps {
  size?: number;
  bgColor?: string;
  borderColor?: string;
  icon: ReactElement;
}

type IconCircleContainerProps = Pick<
  IconCircleProps,
  'bgColor' | 'size' | 'borderColor'
>;

const IconCircleContainer = styled.div<IconCircleContainerProps>`
  margin-right: 10px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ bgColor }) => bgColor};
  box-sizing: initial;
  border-radius: 50%;
  border: ${({ size }) => Math.ceil(size! * 0.095238095)}px solid
    ${({ borderColor }) => borderColor};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.white};
`;

IconCircleContainer.defaultProps = {
  size: 25,
  bgColor: lynch,
  borderColor: hawkesBlue,
};

const IconCircle: FC<IconCircleProps> = ({
  icon,
  size,
  bgColor,
  borderColor,
}) => {
  return (
    <IconCircleContainer
      size={size}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      <SVG icon={icon} color={white} />
    </IconCircleContainer>
  );
};

export default IconCircle;
