import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import noImagePlaceholderSrc from 'assets/images/no-image-placeholder.svg';

import { getFinishEffectsByColor } from 'order/wizard/orderStyles/productLines/store/specifications/orderStylesSpecificationActions';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import Img from 'shared/components/Img';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';
import Scrollbars from 'react-custom-scrollbars';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { zeroGuid } from 'shared/config/Variables';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';

const FinishColorContainer = styled.div`
  display: grid;
  gap: 14px 7px;
  grid-template-columns: repeat(5, 1fr);
  padding: 10px;
  text-align: center;
`;

const FinishColorLabel = styled.div`
  color: ${({ theme }) => theme.nevada};
  font-size: 12px;
  margin: 8px auto 0 auto;
  max-width: 96px;
  text-align: center;
  word-wrap: break-word;
`;

const FinishColorEl = styled.div<{ disabled: boolean }>`
  /* border-radius: 50%; */
  position: relative;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}

  &::before {
    border-radius: 50%;
    content: '';
    height: 100px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -2px;
    transform: translateX(-50%) scale(1.1);
    transition: 300ms ease;
    width: 100px;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.kashmirBlue},
      inset 0 0 0 6px white;
  }

  &.selected {
    &::before {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }

    ${FinishColorLabel} {
      color: ${({ theme }) => theme.kashmirBlue};
      font-weight: 600;
    }
  }
`;

const FinishColorImg = styled(Img)`
  border-radius: 50%;
  display: block;
  margin: 0 auto;
`;

interface FinishColorsProps {
  isFinishColorsLoading?: boolean;
}

const FinishColor: FC<FinishColorsProps> = ({ isFinishColorsLoading }) => {
  const dispatch = useAppDispatch();

  const [finishColorScrollbarsRef, setFinishColorScrollbarsRef] =
    useState<Scrollbars | null>(null);

  const { watch, setValue, control, clearErrors, formState, register } =
    useFormContext<StyleSpecifications>();

  const woodOrMaterialWatched = watch('woodOrMaterial');
  const finishColorWatched = watch('finishColor');
  const specialFinishSampleWatched = watch('specialFinishSample');

  const hasSpecialFinishSample = specialFinishSampleWatched?.length > 0;

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const finishColorOptions: SelectOptionProps[] | null | undefined =
    useSelector(
      (state: RootState) =>
        state.orderStylesReducer.specificationsOptions.finishColorOptions
    );

  const onlyOneOption = (finishColorOptions?.length ?? 0) === 1;

  const storedFinishColor = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications?.finishColor
  );

  const storedFirstFinishEffect = useSelector((state: RootState) =>
    state.orderStylesReducer.specifications?.finishEffects?.find(
      (fe) => fe.orderNumber === 1
    )
  );

  const storedWoodOrMaterial = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specifications?.woodOrMaterial ??
      state.orderStylesReducer.specifications?.materialGroup?.woodMaterialId
  );

  const isICSProductLine = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine?.name ===
      ProductLineEnums.PRODUCT_LINE_ICS
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const getFinishEffects = (finishColorOption: SelectOptionProps | null) => {
    dispatch(
      getFinishEffectsByColor({
        productLineId: selectedProductLine!.id,
        woodMaterialId: woodOrMaterialWatched!.value,
        finishColorId: finishColorOption?.value ?? zeroGuid,
        specialFinishSample: specialFinishSampleWatched,
        showOnlyAvailable: formState.dirtyFields.specialFinishSample
          ? hasSpecialFinishSample
          : storedFirstFinishEffect?.showAvailableOnly ?? false,
      })
    );
  };

  const onFinishColorChangeHandler = (
    finishColorOption: SelectOptionProps | null,
    shouldDirty: boolean
  ) => {
    if (selectedProductLine && woodOrMaterialWatched && finishColorOption) {
      setValue('finishColor', finishColorOption, {
        shouldDirty,
      });
      clearErrors('finishColor');

      UtilService.withDecimal<StyleSpecifications>(
        'finishColorUpcharge',
        finishColorOption.upcharge!.toString(),
        setValue
      );

      if (shouldDirty) {
        getFinishEffects(finishColorOption);
      }
    }
  };

  const finishColorReset = (shouldDirty: boolean) => {
    setValue('finishColor', null, { shouldDirty });
    setValue('finishColorUpcharge', '', { shouldDirty });
    setValue('showAvailableFinishesOnly', false, { shouldDirty });
  };

  useEffect(() => {
    if (isFinishColorsLoading) {
      finishColorScrollbarsRef?.scrollToTop();
    }
  }, [isFinishColorsLoading]);

  useEffect(() => {
    // because this component does not exists until wood materail or materail group is not selected
    // set on load withut calling finish effects
    if (storedFinishColor && !formState.isDirty) {
      onFinishColorChangeHandler(storedFinishColor, false);
    }
  }, [storedFinishColor]);

  useEffect(() => {
    const isWoodOrMaterialGroupDirty = UtilService.dirtyOrTouchedFields(
      formState,
      ['woodOrMaterial', 'materialGroup']
    );

    if (
      isUserDealerOrSalesRep !== undefined &&
      isUserDealerOrSalesRep &&
      isWoodOrMaterialGroupDirty &&
      finishColorOptions &&
      woodOrMaterialWatched &&
      onlyOneOption
    ) {
      // preselect finish color for dealer when there is only one option and form is dirty.
      onFinishColorChangeHandler(finishColorOptions[0], true);
    }
  }, [isUserDealerOrSalesRep, onlyOneOption, finishColorOptions]);

  useEffect(() => {
    if (
      isUserCSR &&
      onlyOneOption &&
      finishColorOptions &&
      woodOrMaterialWatched
    ) {
      getFinishEffects(finishColorWatched);
    }
  }, [isUserCSR, onlyOneOption, finishColorOptions]);

  // reset finish color when wood material changes
  useEffect(() => {
    if (!storedWoodOrMaterial) {
      finishColorReset(false);
    }

    if (storedWoodOrMaterial && formState.dirtyFields.woodOrMaterial) {
      finishColorReset(false);
    }
  }, [storedWoodOrMaterial, woodOrMaterialWatched]);

  return (
    <>
      {finishColorOptions === null ? (
        <OrderFinishLabel
          style={{
            productLine: selectedProductLine ?? undefined,
            ...(woodOrMaterialWatched && {
              woodMaterial: {
                id: woodOrMaterialWatched.value,
                name: woodOrMaterialWatched.label,
              },
            }),
          }}
          render={(label) => (
            <P fontSize={14} color={nevada}>
              Please select wood to get {label.toLocaleLowerCase()} options.
            </P>
          )}
        />
      ) : null}

      {finishColorOptions && !finishColorOptions?.length && (
        <OrderFinishLabel
          style={{
            productLine: selectedProductLine ?? undefined,
            ...(woodOrMaterialWatched && {
              woodMaterial: {
                id: woodOrMaterialWatched.value,
                name: woodOrMaterialWatched.label,
              },
            }),
          }}
          render={(label) => (
            <P fontSize={14} color={nevada}>
              There are no {label} options for selected wood.
            </P>
          )}
        />
      )}

      {(finishColorOptions?.length ?? 0) > 0 ? (
        <Wrapper position="relative">
          {isFinishColorsLoading && (
            <LoaderFullScreen flex middle center>
              <Loader size={40} />
            </LoaderFullScreen>
          )}

          <ScrollbarsCustom
            autoHeight
            autoHeightMax={350}
            autoHeightMin={50}
            asInput
            ref={(el) => setFinishColorScrollbarsRef(el)}
            hasError={!!formState.errors.finishColor}
          >
            <Controller
              name="finishColor"
              rules={{ required: !(isUserCSR && isICSProductLine) }}
              control={control}
              render={() => (
                <FinishColorContainer>
                  {finishColorOptions?.map((finishColorOption, index) => (
                    <OrderFinishLabel
                      key={finishColorOption.value}
                      style={{
                        productLine: selectedProductLine ?? undefined,
                        ...(woodOrMaterialWatched && {
                          woodMaterial: {
                            id: woodOrMaterialWatched.value,
                            name: woodOrMaterialWatched.label,
                          },
                        }),
                      }}
                      render={(label) => (
                        <WarningTooltip
                          fieldName={label}
                          position={UtilService.calcTooltipPosition({
                            itemPositionIndex: index,
                            numberOfColumns: 5,
                          })}
                          withTooltip={
                            !finishColorOption.isDisabled &&
                            UtilService.shouldShowTooltip(
                              finishColorOptions,
                              finishColorWatched
                            )
                          }
                        >
                          <FinishColorEl
                            className={
                              finishColorOption.value ===
                              finishColorWatched?.value
                                ? 'selected'
                                : ''
                            }
                            onClick={() =>
                              onFinishColorChangeHandler(
                                finishColorOption,
                                true
                              )
                            }
                            disabled={!canEdit}
                          >
                            <FinishColorImg
                              width="96"
                              height="96"
                              alt={`${finishColorOption.label}`}
                              src={
                                finishColorOption.imageUrl ||
                                noImagePlaceholderSrc
                              }
                            />

                            <FinishColorLabel>
                              {finishColorOption.label}
                            </FinishColorLabel>
                          </FinishColorEl>
                        </WarningTooltip>
                      )}
                    />
                  ))}
                </FinishColorContainer>
              )}
            />
          </ScrollbarsCustom>

          <OrderFinishLabel
            style={{
              productLine: selectedProductLine ?? undefined,
              ...(woodOrMaterialWatched && {
                woodMaterial: {
                  id: woodOrMaterialWatched.value,
                  name: woodOrMaterialWatched.label,
                },
              }),
            }}
            render={(label) => (
              <FormError
                label={label}
                validationSchema={{ required: true }}
                error={formState.errors.finishColor}
              />
            )}
          />
        </Wrapper>
      ) : (
        <input
          hidden
          {...register('finishColor', {
            required: !(isUserCSR && isICSProductLine),
          })}
        />
      )}
    </>
  );
};

FinishColor.defaultProps = {
  isFinishColorsLoading: false,
};

export default FinishColor;
