import { FormAndCatalog } from 'overview/interface/FormAndCatalog';
import { IShipWeek } from 'overview/interface/IShipWeek';
import { INotification } from 'overview/interface/Notification';
import { Dealership } from 'shared/interface/Dealership';

export enum OverviewActions {
  GET_FORMS_AND_CATALOGS = 'GET_FORMS_AND_CATALOGS',
  SET_FORMS_AND_CATALOGS = 'SET_FORMS_AND_CATALOGS',

  GET_SHIP_WEEKS = 'GET_SHIP_WEEKS',
  SET_SHIP_WEEKS = 'SET_SHIP_WEEKS',

  ACTIVATE_DEALERSHIP = 'ACTIVATE_DEALERSHIP',
  FINISH_ACTIVATE_DEALERSHIP = 'FINISH_ACTIVATE_DEALERSHIP',
  SET_ACTIVATE_DEALERSHIP_RESPONSE = 'SET_ACTIVATE_DEALERSHIP_RESPONSE',

  GET_ALL_DEALERSHIPS = 'GET_ALL_DEALERSHIPS',
  SET_ALL_DEALERSHIPS = 'SET_ALL_DEALERSHIPS',

  CHANGE_DEALERSHIP_STATUS = 'CHANGE_DEALERSHIP_STATUS',
}

export const getFormsAndCatalogs = (loading: (isLoading: boolean) => void) => ({
  type: OverviewActions.GET_FORMS_AND_CATALOGS,
  loading,
});

export interface FormsAndCatalogsPayload {
  catalogs: FormAndCatalog[] | null;
  forms: FormAndCatalog[] | null;
}

export const setFormsAndCatalogs = (payload: FormsAndCatalogsPayload) => ({
  type: OverviewActions.SET_FORMS_AND_CATALOGS,
  payload,
});

export const getShipWeeks = (loading: (isLoading: boolean) => void) => ({
  type: OverviewActions.GET_SHIP_WEEKS,
  loading,
});

export const setShipWeeks = (shipWeeks: IShipWeek[]) => ({
  type: OverviewActions.SET_SHIP_WEEKS,
  payload: shipWeeks,
});

export const activateDealership = (
  payload: number,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: OverviewActions.ACTIVATE_DEALERSHIP,
  payload,
  loading,
  onSuccess,
  onFailed,
});

export const getAllDealerships = (
  loading: (isLoading: boolean) => void,
  onFailed: () => void
) => ({
  type: OverviewActions.GET_ALL_DEALERSHIPS,
  loading,
  onFailed,
});

export const setAllDealerships = (payload: Dealership[]) => ({
  type: OverviewActions.SET_ALL_DEALERSHIPS,
  payload,
});

export interface UpdateIsActiveRequest {
  dealershipId: string;
  isActive: boolean;
}

export const changeDealershipStatus = (
  payload: UpdateIsActiveRequest,
  onSuccess: () => void,
  onFailed: () => void
) => ({
  type: OverviewActions.CHANGE_DEALERSHIP_STATUS,
  payload,
  onSuccess,
  onFailed,
});

export const finishActivateDealership = (payload: INotification) => ({
  type: OverviewActions.FINISH_ACTIVATE_DEALERSHIP,
  payload,
});

export const setActivateDealershipResponse = (
  payload: INotification | null
) => ({
  type: OverviewActions.SET_ACTIVATE_DEALERSHIP_RESPONSE,
  payload,
});
