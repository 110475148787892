import { FC } from 'react';
import { uniqueId } from 'lodash';
import styled, { keyframes } from 'styled-components';

import { TextAlign } from 'shared/interface/CSSTypes';

interface ISkeletonListChild {
  w: number;
  h: number;
  spaceBottom: number;
  align?: TextAlign;
  borderRadius?: number;
}

export interface ISkeletonListItem {
  borderRadius: number;
  children: ISkeletonListChild[];
  h: number;
  marginBottom: number;
  padding: number;
  w: number | null;
}

interface SkeletonListProps {
  items: ISkeletonListItem[];
}

const SkeletonListItems = styled.div``;

const glanceAnimation = (distance: number) => keyframes`
    100% {
        transform: translate(${distance * 1.2}px, -50%) rotate(-45deg);
    }
`;

const SkeletonItem = styled.div<
  Pick<
    ISkeletonListItem,
    'padding' | 'borderRadius' | 'w' | 'h' | 'marginBottom'
  >
>`
  padding: ${({ padding }) => padding}px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.silverSandTwo};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  opacity: 0.5;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  &:last-child {
    margin-bottom: 0;
  }

  overflow: hidden;

  ${({ w }) => w && `max-width: ${w}px;`}
  width: 100%;

  height: ${({ h }) => h}px;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 25%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.2) 90%,
      rgba(255, 255, 255, 0) 100%
    );

    width: 100%;
    height: 400%;

    left: 0;
    top: 50%;
    transform-origin: center;

    ${({ w }) =>
      `transform: translate(-${(w ?? 600) * 2.2}px, -50%) rotate(-45deg);`}

    animation: ${({ w }) => glanceAnimation(w ?? 600)} 2s ease infinite;
  }
`;

const SkeletonChild = styled.div<ISkeletonListChild>`
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  margin-bottom: ${({ spaceBottom }) => spaceBottom}px;
  margin-bottom: ${({ spaceBottom }) => spaceBottom}px;
  background-color: ${({ theme }) => theme.silverSand};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  position: relative;

  ${({ align }) => {
    switch (align) {
      case 'left':
        return `align-self: flex-start;`;
      case 'right':
        return `align-self: flex-end;`;
      case 'center':
        return `align-self: center;`;
      default:
        return ``;
    }
  }}

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    width: ${({ w }) => w * 2.2}px;
    height: ${({ h }) => h}px;
    left: 0;
    top: 50%;
    transform-origin: center;
    transform: translate(-${({ w }) => w * 2.2}px, -50%) rotate(-45deg);

    animation: ${({ w }) => glanceAnimation(w)} 2s ease infinite;
  }
`;

SkeletonChild.defaultProps = {
  align: 'left',
};

const SkeletonList: FC<SkeletonListProps> = ({ items }) => {
  return (
    <SkeletonListItems>
      {items.map((item) => (
        <SkeletonItem
          padding={item.padding}
          marginBottom={item.marginBottom}
          borderRadius={item.borderRadius}
          w={item.w}
          h={item.h}
        >
          {item.children?.map((child) => (
            <SkeletonChild
              w={child.w}
              h={child.h}
              key={uniqueId()}
              align={child.align}
              borderRadius={item.borderRadius}
              spaceBottom={child.spaceBottom}
            />
          ))}
        </SkeletonItem>
      ))}
    </SkeletonListItems>
  );
};

export default SkeletonList;
