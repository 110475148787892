import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useOrderProductType = (type: OrderStylizationTypeEnums) => {
  const order = useSelector((state: RootState) => state.orderReducer.order);

  const isType = useSelector(
    (state: RootState) => state.orderReducer.order?.stylizationType === type
  );

  return order ? isType : null;
};
