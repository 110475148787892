import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import { TopicType } from 'curtain/enums/TopicTypeEnum';
import { IChannel } from 'curtain/interfaces/IChannel';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';

import {
  setRefreshPriority,
  setLineItemChannelPriority,
  setStyleChannelPriority,
} from 'curtain/store/curtainActions';

import { OrderStatusEnums } from 'order/enums/orderEnums';
import { Priority } from 'order/enums/priorityEnum';

import {
  setLineItemPriority,
  setOrderPriority,
  setStylePriority,
} from 'order/store/orderActions';

import { useAppDispatch } from './useAppDispatch';

export const usePriorityChange = () => {
  const dispatch = useAppDispatch();
  const { styles, lineItems, order, numberOfUnresolvedTopics } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const {
    topics,
    otherTopics,
    styleChannels,
    lineItemChannels,
    activeChannel,
    curtainTab,
  } = useSelector((state: RootState) => state.curtainReducer);

  const changeEntityPriority = (entityId: string | null, type: TopicType) => {
    const priority = Priority.Attention;

    if (order?.status?.id === OrderStatusEnums['Not Submitted']) return;

    if (entityId) {
      if (type === TopicType.STYLE && styles) {
        const style = styles.find((x) => x.id === entityId);
        if (style && style.priority < priority) {
          dispatch(setStylePriority(entityId, priority));
        }
      }

      if (type === TopicType.LINE_ITEM && lineItems) {
        const lineItem = lineItems.find((x) => x.lineItemId === entityId);
        if (lineItem && lineItem.priority < priority) {
          dispatch(setLineItemPriority(entityId, priority));
        }
      }
    }
  };

  const changeChannelPriority = (priority: Priority) => {
    const styleChannel = styleChannels.find(
      (ch: IChannel) => ch.id === activeChannel?.id
    );
    const lineItemChannel = lineItemChannels.find(
      (ch: IChannel) => ch.id === activeChannel?.id
    );

    if (styleChannel) {
      dispatch(setStyleChannelPriority(styleChannel.id, priority));
    }
    if (lineItemChannel) {
      dispatch(setLineItemChannelPriority(lineItemChannel.id, priority));
    }
  };

  const changePriority = (
    topicId: string,
    entityId: string,
    priority: Priority
  ) => {
    if (order?.status?.id === OrderStatusEnums['Not Submitted']) return;

    const lineItem = lineItems?.find((x) => x.lineItemId === entityId);

    const style = styles?.find((x) => x.id === entityId);

    const otherTopic = otherTopics?.find(
      (t: ICurtainTopic) => t.id === topicId
    );

    const higherPriorityTopic = topics?.find(
      (t: ICurtainTopic) => t.priority > priority && t.id !== topicId
    );

    const higherPriorityOtherTopic = otherTopics?.find(
      (t: ICurtainTopic) => t.priority > priority
    );

    const styleChannel = styleChannels?.find(
      (ch: IChannel) => ch.priority > priority && ch.entityId !== entityId
    );

    const lineItemChannel = lineItemChannels?.find(
      (ch: IChannel) => ch.priority > priority && ch.entityId !== entityId
    );

    let max = priority;

    if (otherTopic) {
      if (higherPriorityTopic) {
        max = higherPriorityTopic.priority;
      }
      if (styleChannel && styleChannel.priority > max) {
        max = styleChannel.priority;
      }
      if (lineItemChannel && lineItemChannel.priority > max) {
        max = lineItemChannel.priority;
      }
    } else if (style) {
      let stylePriority = priority;
      if (higherPriorityTopic) {
        max = higherPriorityTopic.priority;
        stylePriority = higherPriorityTopic.priority;
      }
      if (higherPriorityOtherTopic && higherPriorityOtherTopic.priority > max) {
        max = higherPriorityOtherTopic.priority;
      }
      if (lineItemChannel && lineItemChannel.priority > max) {
        max = lineItemChannel.priority;
      }
      dispatch(setStylePriority(entityId, stylePriority));
    } else if (lineItem) {
      let lineItemPriority = priority;
      if (higherPriorityTopic) {
        max = higherPriorityTopic.priority;
        lineItemPriority = higherPriorityTopic.priority;
      }
      if (higherPriorityOtherTopic && higherPriorityOtherTopic.priority > max) {
        max = higherPriorityOtherTopic.priority;
      }
      if (styleChannel && styleChannel.priority > max) {
        max = styleChannel.priority;
      }
      dispatch(setLineItemPriority(entityId, lineItemPriority));
    } else {
      if (higherPriorityTopic) {
        max = higherPriorityTopic.priority;
      }
      if (higherPriorityOtherTopic && higherPriorityOtherTopic.priority > max) {
        max = higherPriorityOtherTopic.priority;
      }
      if (styleChannel && styleChannel.priority > max) {
        max = styleChannel.priority;
      }
      if (lineItemChannel && lineItemChannel.priority > max) {
        max = lineItemChannel.priority;
      }
    }
    changeChannelPriority(
      higherPriorityTopic ? higherPriorityTopic.priority : priority
    );
    // dispatch(setOrderPriority(max));
  };

  const updateOrderPriority = () => {
    if (numberOfUnresolvedTopics > 0) {
      dispatch(setOrderPriority(Priority.Attention));
      if (curtainTab === CurtainTabsEnum.COLLABORATION_TAB) {
        dispatch(setRefreshPriority(true));
      }
    }
  };

  return { changeEntityPriority, changePriority, updateOrderPriority };
};
