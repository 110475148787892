import { call, takeEvery, put } from 'redux-saga/effects';

import {
  AttachmentDeleteRequest,
  AttachmentNoteUpdateRequest,
  orderAttachmentsActions,
  SaveAttachmentsRequestObject,
  setAttachmentsForCheckedLineItems,
  setCollaborationAttachments,
  setOrderAttachments,
} from 'order/wizard/orderAttachments/store/orderAttachmentsActions';

import { Action } from 'shared/interface/Action';
import { IAttachment } from 'shared/interface/IAttachment';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

function* getOrderAttachmentsSaga(action: Action<string>) {
  try {
    const response: IAttachment[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/attachments`
    );
    yield put(setOrderAttachments(response));

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getAttachmentsForLineItems(
  action: Action<{ orderId: string; lineItemIds: string[] }>
) {
  try {
    const { lineItemIds, orderId } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('onlyIncludedLineItems', 'true');

    lineItemIds
      .filter((_, index) => index + 1)
      .forEach((el) => {
        queryParams.append('lineItemIds', el);
      });

    const response: IAttachment[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/attachments`,
      { params: queryParams }
    );
    yield put(setAttachmentsForCheckedLineItems(response));

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveOrderAttachments(action: Action<SaveAttachmentsRequestObject>) {
  try {
    const { orderId, attachments } = action.payload!;

    yield call(ApiService.post, `/api/order/orders/${orderId}/attachments`, {
      attachments,
    });

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e);

    if (action.onFailed) {
      yield call(action.onFailed, action.payload!.attachments);
    }
  }
}

function* deleteOrderAttachment(action: Action<AttachmentDeleteRequest>) {
  try {
    const { attachmentId, orderId } = action.payload!;

    yield call(
      ApiService.delete,
      `/api/order/orders/${orderId}/attachments/${attachmentId}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, attachmentId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateOrderAttachmentNote(
  action: Action<AttachmentNoteUpdateRequest>
) {
  const { attachmentId, orderId, note } = action.payload!;

  try {
    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/attachments/${attachmentId}`,
      {
        note,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCollaborationAttachmentsSaga(action: Action<string>) {
  try {
    const response: IAttachment[] = yield call(
      ApiService.get,
      `/api/collaboration/attachments?orderId=${action.payload}`
    );
    yield put(setCollaborationAttachments(response));

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderAttachmentsSagas() {
  yield takeEvery(
    orderAttachmentsActions.GET_ORDER_ATTACHMENTS,
    getOrderAttachmentsSaga
  );
  yield takeEvery(
    orderAttachmentsActions.SAVE_ORDER_ATTACHMENTS,
    saveOrderAttachments
  );
  yield takeEvery(
    orderAttachmentsActions.DELETE_ORDER_ATTACHMENT,
    deleteOrderAttachment
  );
  yield takeEvery(
    orderAttachmentsActions.UPDATE_ORDER_ATTACHMENT_NOTE,
    updateOrderAttachmentNote
  );
  yield takeEvery(
    orderAttachmentsActions.GET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS,
    getAttachmentsForLineItems
  );
  yield takeEvery(
    orderAttachmentsActions.GET_COLLABORATION_ATTACHMENTS,
    getCollaborationAttachmentsSaga
  );
}
