import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import {
  getOrderStatus,
  keepAliveOrderEditSession,
  setCanEditOrder,
  setLockInfoOnOrder,
} from 'order/store/orderActions';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { useAppDispatch } from './useAppDispatch';
import { useBrowserStorage } from './useBrowserStorage';

export interface OrderEditSessionTrackReturn {
  sessionTrackSubscribe: () => void;
  sessionTrackUnsubscribe: () => void;
  resetSession: () => void;
  showDialog: boolean;
  kickOut: boolean;
  subscribed: boolean;
}

interface OrderEditSessionTrackOptions {
  interval: number;
  showKickDialogAfter: number;
  kickAfter: number;
}

interface SessionDataInLocalStorage {
  currentKickDialogAfter: number;
  currentKickAfter: number;
  showKickDialogAfter: number;
  kickDialogAfter: number;
  pageHiddenTimestamp: string;
  dialogShown: boolean;
}

/*
 * Order Edit Session Track hook accepts
 * options param which includes:
 * showKickDialogAfter: number in seconds;
 * kickAfter: number in seconds
 */
export const useOrderEditSessionTrack = (
  options: OrderEditSessionTrackOptions
): OrderEditSessionTrackReturn => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const orderLockId = useSelector(
    (state: RootState) => state.orderReducer.lockInfo?.id
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const showKickDialogAfterMS = options.showKickDialogAfter * options.interval;
  const kickAfterMS = options.kickAfter * options.interval;

  const showKickDialogTimestamp = useBrowserStorage<SessionDataInLocalStorage>({
    key: 'showKickDialogTimestamp',
    storageType: 'sessionStorage',
  });

  const [subscribed, setSubscribed] = useState(false);

  const [idleTime, setIdleTime] = useState(0);
  const [passedTime, setPassedTime] = useState(0);

  // set timer interval tick
  const [interval] = useState(options.interval);

  // set local variable for dialog show
  const [kickDialogAfter, setKickDialogAfter] = useState<number>(
    showKickDialogAfterMS
  );

  const [showDialog, setShowDialog] = useState(false);

  // set local variable for kick of the order
  const [kickAfter, setKickAfter] = useState<number>(kickAfterMS);

  const [kickOut, setKickOut] = useState(false);

  // set global counter
  const [intervalId, setIntervalId] = useState<number | null>(() => null);

  // reset counters
  const resetSession = useCallback(() => {
    setIdleTime(0);
    setKickAfter(kickAfterMS);
    setKickOut(false);
    setShowDialog(false);
  }, []);

  // extend session
  const extendSession = useCallback(() => {
    setIdleTime(0);
    setKickAfter(kickAfterMS);
    setKickDialogAfter(showKickDialogAfterMS);
  }, []);

  // time started
  const timeCountStarted = () => {
    setIdleTime((sec) => sec + options.interval);
    setPassedTime((sec) => sec + options.interval);
  };

  // create interval and set it to local variable
  const sessionTrackSubscribe = useCallback(() => {
    // if already subscribed stop subscribing again
    if (subscribed) return;

    const newIntervalId = window.setInterval(timeCountStarted, interval);
    setIntervalId(() => newIntervalId);

    document.addEventListener('keyup', extendSession);
    document.addEventListener('mousemove', extendSession);

    setSubscribed(() => true);
  }, []);

  const unsubscribe = () => {
    setSubscribed(() => false);
  };

  // clear interval on unsubscribe
  const sessionTrackUnsubscribe = () => {
    if (intervalId) window.clearInterval(intervalId);

    // clear interval id
    setIntervalId(null);

    // clear timers
    setIdleTime(0);
    setPassedTime(0);

    // reset dialog and kick counters
    setKickDialogAfter(showKickDialogAfterMS);
    setKickAfter(kickAfterMS);

    // reset
    setKickOut(false);
    setShowDialog(false);
  };

  // keep alive failed handler
  const onKeepAliveFailedHandler = (err: ServerErrorResponse) => {
    if (err.type === ErrorCodesEnum.OrderLockNotLocked) {
      unsubscribe();

      document.removeEventListener('keyup', extendSession);
      document.removeEventListener('mousemove', extendSession);

      dispatch(getOrderStatus(orderId));

      toast.info('Edit mode has been disabled.');

      dispatch(setCanEditOrder(false));
      dispatch(setLockInfoOnOrder(null));
    }
  };

  /** USE EFFECTS */

  useEffect(() => {
    // unsubscribe from track session
    if (!subscribed && intervalId !== null) {
      sessionTrackUnsubscribe();

      document.removeEventListener('keyup', extendSession);
      document.removeEventListener('mousemove', extendSession);
    }
  }, [subscribed, extendSession]);

  // remove attached event listeners when order is closed
  useEffect(() => {
    return () => {
      document.removeEventListener('keyup', extendSession);
      document.removeEventListener('mousemove', extendSession);
    };
  }, [extendSession]);

  // track which timer should be updated
  useEffect(() => {
    if (kickDialogAfter >= 0 && !showDialog) {
      setKickDialogAfter((sec) => sec - options.interval);

      // reset
      setKickAfter(kickAfterMS);
    }

    if (kickAfter >= 0 && showDialog) {
      setKickAfter((sec) => sec - options.interval);

      // reset
      setKickDialogAfter(showKickDialogAfterMS);
    }
  }, [idleTime]);

  useEffect(() => {
    if (subscribed && orderLockId && passedTime % 30000 === 0 && canEdit) {
      dispatch(
        keepAliveOrderEditSession(
          {
            orderId,
            orderLockId,
          },
          onKeepAliveFailedHandler
        )
      );
    }
  }, [subscribed, orderLockId, passedTime, canEdit]);

  useEffect(() => {
    if (
      (!document.hidden || document.visibilityState === 'visible') &&
      showKickDialogTimestamp.value
    ) {
      const sessionData = showKickDialogTimestamp.value;

      // how long page was hidden
      const now = new Date().getTime();

      const lastHiddenPageTime = new Date(
        sessionData.pageHiddenTimestamp
      ).getTime();

      const pageHiddenFor =
        Math.ceil((now - lastHiddenPageTime) / 1000) * options.interval;

      const restTimeToShowDialog =
        sessionData.currentKickDialogAfter - pageHiddenFor;

      setKickDialogAfter(
        restTimeToShowDialog > 0 ? restTimeToShowDialog : showKickDialogAfterMS
      );

      // show kick dialog if needed after page is visible again
      if (
        pageHiddenFor > sessionData.currentKickDialogAfter &&
        pageHiddenFor < showKickDialogAfterMS + kickAfterMS
      ) {
        setShowDialog(true);
      }

      // kick from the order if needed after page is visible again
      if (
        (sessionData.dialogShown &&
          pageHiddenFor > sessionData.currentKickAfter) ||
        pageHiddenFor >
          sessionData.currentKickDialogAfter + sessionData.currentKickAfter
      ) {
        setKickOut(true);
      }

      showKickDialogTimestamp.removeItem();
    }

    if (document.visibilityState === 'hidden' || document.hidden) {
      const sessionData: SessionDataInLocalStorage = {
        currentKickDialogAfter: kickDialogAfter,
        currentKickAfter: kickAfter,
        showKickDialogAfter: showKickDialogAfterMS,
        kickDialogAfter: kickAfterMS,
        pageHiddenTimestamp: new Date().toISOString(),
        dialogShown: showDialog,
      };

      showKickDialogTimestamp.setItem(sessionData);
    }
  }, [document.visibilityState, document.hidden]);

  // kick dialog show up
  useEffect(() => {
    if (kickDialogAfter === 0 && !showDialog) {
      setShowDialog(true);
    }
  }, [kickDialogAfter]);

  // kick out from the order
  useEffect(() => {
    if (kickAfter === 0 && showDialog) {
      showKickDialogTimestamp.removeItem();
      unsubscribe();
      setKickOut(true);
    }
  }, [kickAfter]);

  return {
    sessionTrackSubscribe,
    sessionTrackUnsubscribe: unsubscribe,
    resetSession,
    showDialog,
    kickOut,
    subscribed,
  };
};
