import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface UseGetOrderMetaNamesOptions {
  styleName: string;
}

interface UseGetOrderMetaNamesReturn {
  jobName: string | null;
  orderName: string | null;
  styleName: string | null;
  orderNumber: string | null;
  lineItemName: string | null;
}

export const useGetOrderMetaNames = (
  options?: UseGetOrderMetaNamesOptions
): UseGetOrderMetaNamesReturn => {
  const order = useSelector((state: RootState) => state.orderReducer.order);

  const style = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.style?.name ?? options?.styleName
  );

  const lineItem = useSelector(
    (state: RootState) => state.orderLineItemsReducer.orderLineItemData
  );

  const [orderNumber, setOrderNumber] = useState<null | string>(null);
  const [jobName, setJobName] = useState<null | string>(null);
  const [orderName, setOrderName] = useState<null | string>(null);
  const [styleName, setStyleName] = useState<null | string>(null);
  const [lineItemName, setLineItemName] = useState<null | string>(null);

  useEffect(() => {
    if (order) {
      // set order name
      setOrderName(order.name);

      // set order number
      setOrderNumber(`Order #${order.serialNumber}`);
    }
  }, [order]);

  useEffect(() => {
    if (order?.job) {
      setJobName(order.job?.name);
    }
  }, [order]);

  useEffect(() => {
    if (style) {
      setStyleName(style);
    }
  }, [style]);

  useEffect(() => {
    if (lineItem) {
      setLineItemName(lineItem.code);
    }
  }, [lineItem]);

  return { orderNumber, jobName, orderName, lineItemName, styleName };
};
