import {
  ContainerSmall,
  ContainerMedium,
  ContainerXSmall,
} from 'shared/components/Container';
import { Divider, Spacer, WhiteBox } from 'shared/components/Layout';
import { PSmall, H6 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';
import EmptyState from 'shared/components/EmptyState';
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';
import { FC } from 'react';
import OrderReviewPricingSMRow from './OrderReviewPricingSalesMaterialRow';
import TotalPriceRow from './components/TotalPriceRow';
import NetUpchargeRow from './components/NetUpchargeRow';
import {
  NetUpchargeType,
  OrderStylePriceReview,
} from '../orderStyles/interface/OrderPriceReview';

const TotalPriceContainer = styled(Wrapper)`
  padding: 0 36px;
`;

interface OrderReviewPricingSalesAidsOrderProps {
  orderStylePriceReviewData: OrderStylePriceReview;
  isReviewPricing?: boolean;
}

const OrderReviewPricingSalesAidsOrder: FC<
  OrderReviewPricingSalesAidsOrderProps
> = ({ orderStylePriceReviewData, isReviewPricing }) => {
  const getTotalNetPrice = () => {
    return orderStylePriceReviewData?.lineItemPriceReview
      ?.filter((x) => !x.isDeleted)
      .reduce((prev, curr) => {
        return prev + curr.netPrice;
      }, 0);
  };

  const getNetUpchargePrice = (type: NetUpchargeType) => {
    return orderStylePriceReviewData?.netUpchargesDiscountsPriceReview?.find(
      (fe) => fe.type === type
    );
  };

  const showNetUpchargePrice = (type: NetUpchargeType) => {
    return getNetUpchargePrice(type)?.percentage ?? 0;
  };

  return (
    <>
      {orderStylePriceReviewData &&
        orderStylePriceReviewData.lineItemPriceReview.length > 0 && (
          <>
            <Spacer h="32px" />

            <WhiteBox>
              <Wrapper flex>
                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>PWW#</PSmall>
                </ContainerSmall>
                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>CL#</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>L#</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>Qty</PSmall>
                </ContainerSmall>

                <ContainerMedium
                  align="left"
                  isTableHeadCell
                  flexGrow
                  border={['bottom', 'right']}
                >
                  <PSmall bold>LINE ITEMS</PSmall>
                </ContainerMedium>

                <ContainerSmall isTableHeadCell border={['bottom', 'right']}>
                  <PSmall bold>Unit$</PSmall>
                </ContainerSmall>

                <ContainerSmall isTableHeadCell border={['bottom']}>
                  <PSmall bold>Net$</PSmall>
                </ContainerSmall>
              </Wrapper>

              {orderStylePriceReviewData?.lineItemPriceReview
                .filter((x) => !isReviewPricing || !x.isDeleted)
                .map((lineItem) => (
                  <OrderReviewPricingSMRow
                    lineItem={lineItem}
                    isReviewPricing={isReviewPricing}
                  />
                ))}

              <TotalPriceRow
                title="total"
                netTotal={
                  orderStylePriceReviewData?.totalListPriceForNet ??
                  getTotalNetPrice()
                }
              />
            </WhiteBox>

            {(orderStylePriceReviewData?.netUpchargesDiscountsTotalPriceForNet !==
              0 ||
              orderStylePriceReviewData?.netUpchargesDiscountsPriceReview.some(
                (x) => x.percentage > 0
              )) && (
              <>
                <Spacer h="32px" />
                <WhiteBox>
                  <Wrapper flex middle>
                    <Wrapper
                      flex
                      middle
                      isTableHeadCell
                      minWidth={150}
                      border={['bottom']}
                      flexGrow
                    >
                      <H6>NET UPCHARGES/DISCOUNTS</H6>
                    </Wrapper>
                    <ContainerXSmall
                      isTableHeadCell
                      border={['bottom', 'right']}
                    >
                      <PSmall bold>%</PSmall>
                    </ContainerXSmall>

                    <ContainerSmall isTableHeadCell border={['bottom']}>
                      <PSmall bold>Net$</PSmall>
                    </ContainerSmall>
                  </Wrapper>
                  {(
                    Object.keys(
                      NetUpchargeType
                    ) as (keyof typeof NetUpchargeType)[]
                  )
                    .filter((x) => Number.isNaN(+x))
                    .map((key) =>
                      showNetUpchargePrice(NetUpchargeType[key]) !== 0 ? (
                        <NetUpchargeRow
                          key={key}
                          isListPriceSelected={false}
                          upcharge={getNetUpchargePrice(NetUpchargeType[key])}
                          title={NetUpchargeType[NetUpchargeType[key]]}
                        />
                      ) : undefined
                    )}
                  <Wrapper flex middle>
                    <Wrapper
                      align="left"
                      minWidth={150}
                      isTableCell
                      flexGrow
                      border={['top']}
                    >
                      <H6>SUBTOTAL</H6>
                    </Wrapper>

                    <ContainerSmall isTableCell border={['top']}>
                      <PSmall>
                        {orderStylePriceReviewData?.netUpchargesDiscountsTotalPriceForNet.toFixed(
                          2
                        ) ?? '0.00'}
                      </PSmall>
                    </ContainerSmall>
                  </Wrapper>
                </WhiteBox>
              </>
            )}

            <TotalPriceContainer>
              <Spacer h="60px" />

              <Divider />

              {orderStylePriceReviewData?.totalListPriceNoChargeForNet !== 0 ? (
                <>
                  <Spacer h="16px" />
                  <Wrapper flex middle between>
                    <H6>NO CHARGE</H6>
                    <H6>
                      {orderStylePriceReviewData?.totalListPriceNoChargeForNet.toFixed(
                        2
                      ) ?? '0.00'}
                    </H6>
                  </Wrapper>
                </>
              ) : undefined}
              <Spacer h="16px" />
              <Wrapper flex middle between>
                <H6>TOTAL SALES MATERIALS</H6>
                <H6>
                  {orderStylePriceReviewData?.totalListPriceForNet.toFixed(2) ??
                    '0.00'}
                </H6>
              </Wrapper>
              <Spacer h="24px" />
            </TotalPriceContainer>
          </>
        )}
      {orderStylePriceReviewData.lineItemPriceReview.length === 0 && (
        <EmptyState
          icon={<PaletteIcon />}
          marginTop={20}
          marginBottom={40}
          title="Incomplete Style"
          message="Style is not completed yet. Please return to Styles page and complete this style."
        />
      )}
    </>
  );
};

export default OrderReviewPricingSalesAidsOrder;
