import { FC } from 'react';

import { H6 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';

import styled from 'styled-components';
import { Divider, Spacer } from 'shared/components/Layout';

interface TotalPriceProps {
  title: string;
  total: string;
  spaceTop?: number;
}

const TotalPriceContainer = styled(Wrapper)`
  padding: 0 36px;
`;

const TotalPrice: FC<TotalPriceProps> = ({ title, total, spaceTop }) => {
  return (
    <TotalPriceContainer>
      <Spacer h={`${spaceTop}px`} />
      <Divider />
      <Spacer h="16px" />
      <Wrapper flex middle between>
        <H6>{title.toUpperCase()}</H6>
        <H6>{total}</H6>
      </Wrapper>
      <Spacer h="32px" />
    </TotalPriceContainer>
  );
};

TotalPrice.defaultProps = {
  spaceTop: 64,
};

export default TotalPrice;
