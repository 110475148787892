export enum ErrorCodesEnum {
  Default = 1,
  BadRequest = 2,
  NotFound = 3,
  Conflict = 4,
  UnProcessableEntity = 5,
  ServerError = 9,
  ValidationFailure = 10,
  UserNotFound = 1000,
  UserAlreadyExists = 1001,
  UserIdentityDoesNotExist = 1002,
  UserIdentifierNotFound = 1003,
  DealershipNotFound = 1100,
  DealershipAlreadyExists = 1101,
  SalesRepresentativeNotFound = 1102,
  JobNotFound = 1200,
  JobNameAlreadyExists = 1201,
  OrderNotFound = 1300,
  OrderAlreadyHasOwner = 1301,
  OrderAlreadyHasShippingAddress = 1302,
  OrderOwnerCannotBeChanged = 1303,
  OrderOwnerCannotBeRemoved = 1304,
  OrderAlreadySubmitted = 1305,
  OrderNotSubmitted = 1306,
  OrderAlreadyLocked = 1307,
  OrderLockNotFound = 1308,
  OrderLockNotLocked = 1309,
  OrderNotLocked = 1310,
  OrderStatusNotFound = 1311,
  OrderPriorityCannotBeChanged = 1312,
  OrderCancellationRequestSent = 1313,
  OrderSnapshotForOrderNotFound = 1314,
  OrderStylesLimitReached = 1315,
  OrderLineItemsLimitReached = 1316,
  ShipOptionNotFound = 1317,
  OrderDoesNotHaveAnyStyles = 1318,
  OrderDoesNotHaveAnyLineItems = 1319,
  OrderHasIncompleteStyle = 1320,
  OrderHasStyleWithoutLineItems = 1321,
  OrderIsInvalid = 1322,
  OrderHasInvalidLineItemsOrModifications = 1323,
  AddressNotFound = 1400,
  AddressIsNotCustomShipping = 1401,
  ShippingAddressNotFound = 1402,
  AttachmentNotFound = 1500,
  AttachmentCannotAddToInternalSection = 1501,
  StyleNotFound = 1600,
  StyleNotComplete = 1601,
  StyleCancellationRequestSent = 1602,
  StyleLineItemsLimitReached = 1603,
  RequestCancellationForLastStyleCannotBeSent = 1604,
  AcknowledgementForOrderNotFound = 1700,
  AcknowledgementNotFound = 1701,
  AcknowledgementAlreadyApproved = 1702,
  AcknowledgementAlreadyChangesRequested = 1703,
  AcknowledgementAlreadyPending = 1704,
  AcknowledgementNotInPendingStatus = 1705,
  NewerAcknowledgementFoundForOrder = 1706,
  AcknowledgementAlreadyFinishedRequestingChanges = 1707,
  AcknowledgementNotInRequestedChangesStatus = 1708,
  CollaboratorAlreadyBeenAdded = 1800,
  CollaboratorAccessIdNotFound = 1801,
  UserNotCollaboratorForTheOrder = 1802,
  SpecialOrderApprovalAlreadySent = 1900,
  SpecialOrderApprovalSentAfterAcknowledgement = 1901,
  SpecialOrderApprovalNotFound = 1902,
  SpecialOrderApprovalAlreadyApproved = 1903,
  SpecialOrderApprovalAlreadyDisapproved = 1904,
  ProductLineNotFound = 2000,
  ArchStyleNotValid = 2001,
  NoMidrailNotValid = 2002,
  MetalFinishNotValid = 2003,
  MetalFinishNotFound = 2004,
  WoodMaterialNotFound = 2005,
  WoodDetailNotFound = 2006,
  WoodEffectNotFound = 2007,
  FinishColorNotFound = 2008,
  FinishEffectNotFound = 2009,
  VarnishNotFound = 2010,
  FinishImpressionNotFound = 2011,
  WoodInteriorNotFound = 2012,
  CabinetBoxMaterialNotFound = 2013,
  DoorOverlayNotFound = 2014,
  FaceFrameNotFound = 2015,
  FrameStyleNotFound = 2016,
  FrameSizeNotFound = 2017,
  DoorStyleNotFound = 2018,
  DoorConfigurationNotFound = 2019,
  DoorEdgeNotFound = 2020,
  DrawerFrontStyleNotFound = 2021,
  InsertPanelNotFound = 2022,
  TopInsertPanelNotFound = 2023,
  DrawerBoxNotFound = 2024,
  DrawerInsertPanelNotFound = 2025,
  DrawerEdgeNotFound = 2026,
  HingeTypeNotFound = 2027,
  HingeColorNotFound = 2028,
  DoorHardwareNotFound = 2029,
  DrawerHardwareNotFound = 2030,
  ClosetHardwareColorNotFound = 2031,
  ToeSpaceTypeNotFound = 2032,
  MaterialGroupNotFound = 2033,
  MaterialColorNotFound = 2034,
  GrainDirectionNotFound = 2035,
  DoorEdgeBandNotFound = 2036,
  MaterialDrawerNotFound = 2037,
  LineItemNotFound = 2038,
  LineItemCategoryNotFound = 2039,
  ModificationsNotFound = 2040,
  ModificationNotFound = 2041,
  ChannelNotFound = 2100,
  TopicNotFound = 2101,
  TopicAlreadyResolved = 2102,
  TopicAlreadyUnResolved = 2103,
  CommentNotFound = 2104,
  EntityNameIsEmpty = 2105,
  CannotCreatePrivateCollaboration = 2106,
  WorkableNotFound = 2200,
  WorkableAlreadyResolved = 2201,
  WorkableAlreadyUnResolved = 2202,
  AssigneesOrderLimitExceeded = 2300,
  AlreadyAssignedToTheOrder = 2301,
  AssigneeForRemovalNotFound = 2302,
  UserNotAssignedToTheOrder = 2303,
  CollectDrawingsAlreadyStarted = 2400,
  CollectDrawingsNotFinished = 2401,
  CollectDrawingsRetryNotAvailable = 2402,
  NotificationForUserNotFound = 2500,
  ImageNotFound = 2600,
  FileNotFound = 2601,
  FileAlreadyAttached = 2602,
}

export const ErrorMessagesEnum: Record<number, string> = {
  [ErrorCodesEnum.OrderAlreadyLocked]:
    'Order is already being edit by you or someone else.',
  [ErrorCodesEnum.OrderLockNotLocked]: 'Order is not being edited by anyone.',
  [ErrorCodesEnum.OrderIsInvalid]:
    'Cannot import items from different order types.',
};
