import { FC } from 'react';
import styled from 'styled-components';

import { anzac } from 'shared/config/Colors';
import { Sides } from 'shared/interface/CSSTypes';

import { Tooltip } from './Tooltip';
import { Wrapper } from './Wrapper';

const WarningTooltipContainer = styled(Wrapper)``;

interface WarningTooltipProps {
  fieldName: string;
  withTooltip: boolean;
  warningMessage?: string;
  position?: Sides;
}

const WarningTooltip: FC<WarningTooltipProps> = ({
  fieldName,
  withTooltip,
  children,
  warningMessage,
  position,
}) => {
  return (
    <WarningTooltipContainer withTooltip={withTooltip}>
      {children}

      {withTooltip && (
        <Tooltip bgColor={anzac} position={position}>
          {warningMessage ??
            `${fieldName} set by CSR! If you change this value and save the style - you will not
          be able to set it again.`}
        </Tooltip>
      )}
    </WarningTooltipContainer>
  );
};

WarningTooltip.defaultProps = {
  position: 'top',
};

export default WarningTooltip;
