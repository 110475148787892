import { useEffect, useState } from 'react';

import {
  INotification,
  INotificationDetals,
  NotificationVariationEnums,
} from 'overview/interface/Notification';

export const useGenerateNotifLink = (notif: INotification) => {
  const [url, setUrl] = useState<null | string>(null);
  const [urlState, setUrlState] = useState<INotificationDetals | null>(null);

  const generateNotifLink = () => {
    switch (notif.variation) {
      case NotificationVariationEnums.COMMENT_CREATED:
      case NotificationVariationEnums.COLLABORATOR_ADDED:
      case NotificationVariationEnums.ORDER_CANCELLATION_REQUEST:
      case NotificationVariationEnums.ASSIGNEE_ADDED: {
        setUrl(`/order/${notif.details.orderId}/overview`);
        setUrlState({
          ...notif.details,
          type: notif.type,
          variation: notif.variation,
          redirectedFromNotif: true,
        });
        break;
      }

      default:
        setUrl(null);
        setUrlState(null);
        break;
    }
  };

  useEffect(() => {
    if (notif) {
      generateNotifLink();
    }
  }, [notif]);

  return { url, urlState };
};
