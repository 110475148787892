import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';

interface InputSearchOptions {
  minChars?: number;
}

type InputSearchReturn = [
  string,
  DebouncedFunc<(value: string) => void>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<string>>
];

export const useInputSearch = (
  options?: InputSearchOptions
): InputSearchReturn => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryLoading, setSearchQueryLoading] = useState(false);

  const setDebouncedSearchQuery = (value: string) => {
    setSearchQuery(value);
    setSearchQueryLoading(value.length >= (options?.minChars ?? 0));
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(setDebouncedSearchQuery, 500),
    []
  );

  return [
    searchQuery,
    debouncedChangeHandler,
    searchQueryLoading,
    setSearchQueryLoading,
    setSearchQuery,
  ];
};
