import { InputHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import { Input } from './Input';

const Label = styled.label`
  color: ${({ theme }) => theme.nevada};
  cursor: pointer;
  font-size: 14px;
  display: contents;
  cursor: pointer;
  > ${Input} {
    width: 16px;
    height: 16px;
    accent-color: ${({ theme }) => theme.lynch};
    cursor: pointer;
  }
`;

const LabelTitle = styled.span`
  margin-left: 6px;
`;

export const CurtainCheckbox = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <Label htmlFor={props.id}>
      <Input ref={ref} {...props} type="checkbox" id={props.id} />
      {props.title && <LabelTitle>{props.title}</LabelTitle>}
    </Label>
  );
});
