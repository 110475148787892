import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonPrimary } from 'shared/components/Button';
import { H2, H4, H6 } from 'shared/components/Typography';
import { mediaQuery } from 'shared/config/Mixins';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { WhiteBox, Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import { DealershipCustomAddressTable } from '../components/DealershipCustomAddressTable';
import { DealershipDefaultAddressTable } from '../components/DealershipDefaultAddressTable';
import { CustomAddressForm } from '../components/CustomAddressForm';

const PageTopContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 24px;

  ${mediaQuery('min', 1440)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledWhiteBox = styled(WhiteBox)`
  padding: 40px;
`;

const GridTableRow = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 36px;
  align-items: center;
`;

const H6Styled = styled(H6)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
`;

const H4Styled = styled(H4)`
  color: ${({ theme }) => theme.nevada};
`;

const RequestLink = styled.a`
  text-decoration: underline;
  font-weight: 700;
  color: ${({ theme }) => theme.lynch};
`;

const TableContainer = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 16px;
  ${BoxShadowCSS};
  padding: 31px 0px 16px 0px;
  width: 100%;
`;

const TableContainerHeader = styled(Wrapper)`
  padding: 0 40px;
`;

export const DealershipAboutPage = () => {
  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const salesRepresentative = useSelector(
    (state: RootState) => state.dealershipReducer.salesRepresentative
  );

  return (
    <div>
      <PageTopContainer>
        <StyledWhiteBox>
          <H2>Basic Info</H2>
          <Spacer h="34px" />
          <div>
            <GridTableRow>
              <H6Styled>Plato Dealer Number</H6Styled>
              <H4Styled>{dealership?.accountNumber}</H4Styled>
            </GridTableRow>
            <Spacer h="40px" />
            <GridTableRow>
              <H6Styled>Dealership name</H6Styled>
              <H4Styled>{dealership?.name}</H4Styled>
            </GridTableRow>
            <Spacer h="40px" />
            <GridTableRow>
              <H6Styled>General email</H6Styled>
              <H4Styled>{dealership?.email}</H4Styled>
            </GridTableRow>
            <Spacer h="40px" />
            <GridTableRow>
              <H6Styled>Phone Number</H6Styled>
              <H4Styled>{dealership?.phoneNumber}</H4Styled>
            </GridTableRow>
          </div>
        </StyledWhiteBox>

        <StyledWhiteBox>
          <H2>Sales representative info</H2>

          <Spacer h="34px" />

          <div>
            <GridTableRow>
              <H6Styled>Name</H6Styled>
              <H4Styled>
                {salesRepresentative?.firstName} {salesRepresentative?.lastName}
              </H4Styled>
            </GridTableRow>
            <Spacer h="20px" />
            <GridTableRow>
              <H6Styled>Phone</H6Styled>
              <H4Styled>{salesRepresentative?.phoneNumber}</H4Styled>
            </GridTableRow>
            <Spacer h="20px" />
            <GridTableRow>
              <H6Styled>Email</H6Styled>
              <H4Styled>{salesRepresentative?.email}</H4Styled>
            </GridTableRow>
            <Spacer h="20px" />
            <GridTableRow>
              <H6Styled>Company</H6Styled>
              <H4Styled>{salesRepresentative?.companyName}</H4Styled>
            </GridTableRow>
            <Spacer h="20px" />
            <GridTableRow>
              <H6Styled>Company phone</H6Styled>
              <H4Styled>{salesRepresentative?.companyPhoneNumber}</H4Styled>
            </GridTableRow>
            <Spacer h="20px" />
            <GridTableRow>
              <H6Styled>Company email</H6Styled>
              <H4Styled>{salesRepresentative?.companyEmail}</H4Styled>
            </GridTableRow>
          </div>
        </StyledWhiteBox>
      </PageTopContainer>

      <Spacer h="40px" />

      <H2>Shipping info</H2>

      <Spacer h="32px" />

      <TableContainer>
        <TableContainerHeader flex middle between>
          <H2>Default Shipping addresses</H2>
          <RequestLink
            href="mailto:info@platowoodwork.com?subject=Change of address request&body=Please update my official address as described below.%0D%0A
            %0D%0A
            NEW ADDRESS:%0D%0A
            %0D%0A
            Address type (Mailing address, Showroom location, Truck shipping address, UPS/FEDEx):%0D%0A
            Street address:%0D%0A
            Address line 2:%0D%0A
            City:%0D%0A
            State:%0D%0A
            Zip: %0D%0A
            %0D%0A
            Phone number: %0D%0A
            %0D%0A
            Thank you"
          >
            Request a change of address
          </RequestLink>
        </TableContainerHeader>
        <Spacer h="20px" />

        <DealershipDefaultAddressTable addresses={dealership?.addresses} />
      </TableContainer>

      <Spacer h="40px" />

      <TableContainer>
        <TableContainerHeader flex middle between>
          <H2>Saved Custom Shipping addresses</H2>

          <ModalWrapper
            trigger={<ButtonPrimary type="button">Add address</ButtonPrimary>}
            modal
            closeOnEscape={false}
          >
            {(close: () => void) => (
              <Modal title="Add a new address" close={close} withoutFooter={1}>
                <CustomAddressForm onClose={close} />
              </Modal>
            )}
          </ModalWrapper>
        </TableContainerHeader>

        <Spacer h="20px" />

        <DealershipCustomAddressTable />
      </TableContainer>
    </div>
  );
};
