import { call, put, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import {
  lineItemModificationsActions,
  SearchModificationsRequest,
  setSearchedModifications,
} from './lineItemModificationsActions';

import { IModification } from '../interface/IModification';

function* searchModifications(action: Action<SearchModificationsRequest>) {
  try {
    const {
      catalogLineItemId,
      productLineId,
      searchTerm,
      itemsPerPage,
      lineItemDepth,
      lineItemHeight,
      lineItemPrice,
      lineItemWidth,
      page,
      appendItems,
      cabinetBoxMaterialId,
      styleId,
      doorOverlayId,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);
    queryParams.append('styleId', styleId);
    queryParams.append('lineItemId', catalogLineItemId);
    queryParams.append('itemsPerPage', itemsPerPage);
    queryParams.append('lineItemDepth', lineItemDepth);
    queryParams.append('lineItemHeight', lineItemHeight);
    queryParams.append('lineItemPrice', lineItemPrice);
    queryParams.append('lineItemWidth', lineItemWidth);
    queryParams.append('searchTerm', searchTerm);
    queryParams.append('page', page);

    if (doorOverlayId) {
      queryParams.append('doorOverlayId', doorOverlayId);
    }

    if (cabinetBoxMaterialId) {
      queryParams.append('cabinetBoxMaterialId', cabinetBoxMaterialId);
    }

    const searchedModifications: PaginatedItems<IModification> = yield call(
      ApiService.get,
      `/api/catalog/modifications`,
      {
        params: queryParams,
      }
    );

    yield put(setSearchedModifications({ searchedModifications, appendItems }));

    if (action.onSuccess) {
      yield call(
        action.onSuccess,
        UtilService.mapObjectToSelectOptions(
          searchedModifications.items,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        )
      );
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* lineItemModificationsSagas() {
  yield takeEvery(
    lineItemModificationsActions.SEARCH_MODIFICATIONS,
    searchModifications
  );
}
