import { call, takeEvery, put } from 'redux-saga/effects';

import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import {
  CopySelectedAttachmentsRequest,
  CopySelectedItemsRequest,
  GetAsapItemsRequest,
  OrderAsapActions,
  setAsapItems,
} from './OrderAsapActions';

function* getAsapItems(action: Action<GetAsapItemsRequest>) {
  try {
    const { orderId, isOrderSalesMaterial, productLineId } = action.payload!;

    const styles: Style[] = !isOrderSalesMaterial
      ? yield call(ApiService.get, `/api/order/orders/${orderId}/styles`)
      : [];

    const queryParams = new URLSearchParams();

    if (productLineId) {
      queryParams.append('productLineId', productLineId);
    }

    const params =
      isOrderSalesMaterial && productLineId
        ? { params: queryParams }
        : undefined;

    const lineItems: LineItem[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/${
        isOrderSalesMaterial ? 'salesmaterial-' : ''
      }lineitems`,
      params
    );

    yield put(
      setAsapItems({
        styles,
        lineItems: lineItems.filter((lineItem) => !lineItem.isDeleted),
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* copySelectedItems(action: Action<CopySelectedItemsRequest>) {
  try {
    const { orderId, isOrderSalesMaterial, ...rest } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/copy${
        isOrderSalesMaterial ? '-without-style' : ''
      }`,
      rest
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* copySelectedAttachments(
  action: Action<CopySelectedAttachmentsRequest>
) {
  const { orderId, copyOrderId, attachmentIds } = action.payload!;

  try {
    if ((attachmentIds?.length ?? 0) > 0) {
      yield call(
        ApiService.put,
        `api/order/orders/${orderId}/attachments/copy`,
        {
          orderId: copyOrderId,
          attachmentIds,
        }
      );
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  } finally {
    if (action.loading) {
      yield call(action.loading, false);
    }
  }
}

export default function* orderAsapSagas() {
  yield takeEvery(OrderAsapActions.GET_ASAP_ITEMS, getAsapItems);
  yield takeEvery(OrderAsapActions.COPY_SELECTED_ITEMS, copySelectedItems);
  yield takeEvery(OrderAsapActions.COPY_ATTACHMENTS, copySelectedAttachments);
}
