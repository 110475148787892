import { useState, useEffect } from 'react';

import { positionValues } from 'react-custom-scrollbars';

interface InfiniteScrollOptions {
  hasNextPage: boolean;
  loading: boolean;
  loadList: (load: boolean) => void;
  setLoading: (loading: boolean) => void;
  scrollToTop?: () => void;
}

interface InfiniteScrollReturn {
  handleUpdate: (values: positionValues) => void;
  page: number;
  setPage: (page: number) => void;
  setScrollInProgress: (scrollInProgress: boolean) => void;
  scrolledFromTop: number;
}

export const useInfiniteScroll = ({
  loading,
  hasNextPage,
  setLoading,
  loadList,
  scrollToTop,
}: InfiniteScrollOptions): InfiniteScrollReturn => {
  const [page, setPage] = useState(1);
  const [scrollInProgress, setScrollInProgress] = useState(false);
  const [scrolledFromTop, setScrolledFromTop] = useState(0);

  const handleUpdate = (values: positionValues) => {
    const { scrollHeight, scrollTop, clientHeight } = values;

    const bottom = scrollHeight - clientHeight - 50;

    if (scrollTop !== 0 && scrollTop >= bottom && !loading && hasNextPage) {
      if (scrollInProgress) {
        setPage((prevState) => prevState + 1);
      }
    }

    setScrolledFromTop(scrollTop);
  };

  useEffect(() => {
    if (page === 1 && scrollToTop) {
      scrollToTop();
    }

    if (page > 1) {
      setLoading(true);
      loadList(true);
    }
  }, [page]);

  return { handleUpdate, setScrollInProgress, page, setPage, scrolledFromTop };
};
