import { ReactComponent as HangingNoteIcon } from 'assets/icons/hanging-note.svg';
import { FC } from 'react';

import { lynch } from 'shared/config/Colors';

import { Spacer } from './Layout';
import { Wrapper, WrapperProps } from './Wrapper';
import SVG from './SVG';

interface HangingNoteProps extends Pick<WrapperProps, 'mrAuto' | 'mlAuto'> {
  space?: 'left' | 'right' | 'both';
}

const HangingNote: FC<HangingNoteProps> = ({ space, ...rest }) => {
  return (
    <Wrapper inlineFlex middle {...rest}>
      {(space === 'left' || space === 'both') && <Spacer w="20px" />}
      <SVG icon={<HangingNoteIcon />} color={lynch} />
      {(space === 'right' || space === 'both') && <Spacer w="20px" />}
    </Wrapper>
  );
};

HangingNote.defaultProps = {
  mrAuto: false,
  mlAuto: false,
};

export default HangingNote;
