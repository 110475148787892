import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as DragIndicatorIcon } from 'assets/icons/drag-indicator.svg';

import { altoTwo, lynch } from 'shared/config/Colors';

import { ButtonIcon } from './Button';

interface DragButtonProps extends HTMLAttributes<HTMLButtonElement> {
  hoverIconColor?: string;
  iconColor?: string;
  disabled?: boolean;
}

const DragBtn = styled(ButtonIcon)`
  margin: auto;
  position: absolute;
  right: calc(100% + 16px);
  top: 25px;
`;

const DragButton: FC<DragButtonProps> = ({
  hoverIconColor,
  iconColor,
  ...rest
}) => {
  return (
    <DragBtn
      {...rest}
      hoverIconColor={hoverIconColor ?? lynch}
      iconColor={iconColor ?? altoTwo}
    >
      <DragIndicatorIcon />
    </DragBtn>
  );
};

export default DragButton;
