import { put, call, takeEvery, select } from 'redux-saga/effects';

import {
  OrderCancelRequestRequest,
  OrdersActions,
  setBoardOrders,
  setImmediateActionsForOdrers,
  setOrderCancelRequested,
} from 'overview/orders/store/ordersActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  CsrOrderTableRequest,
  ImediateActionOrder,
  ImmediateActionsForOrdersTableRequest,
  OrderItem,
  OrderTableRequest,
} from 'shared/interface/OrderRow';

import { RootState } from '../../../store';
import { userPermissionsValues } from '../../../shared/enum/userPermissionsEnum';

function* getDealerOrders(action: Action<OrderTableRequest>) {
  try {
    const {
      searchTerm,
      type,
      status,
      productLineId,
      shipWeekStartDate,
      shipWeekEndDate,
      page,
      itemsPerPage,
      sortColumn,
      sortDirection,
      dealerTab,
      collaboratingOn,
      shipWeekFilterType,
      excludeOrdersWithoutStyle,
      dealershipId,
      showCancelledOrders,
      showNewCollaboration,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('searchTerm', searchTerm);
    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('sortColumn', sortColumn);
    queryParams.append('sortDirection', sortDirection);
    queryParams.append('collaboratingOn', collaboratingOn.toString());
    queryParams.append(
      'showNewCollaboration',
      (showNewCollaboration ?? false).toString()
    );

    if (excludeOrdersWithoutStyle) {
      queryParams.append(
        'excludeOrdersWithoutStyle',
        excludeOrdersWithoutStyle.toString()
      );
    }

    if (status) {
      queryParams.append('status', status.toString());
    }

    if (type) {
      queryParams.append('type', type.toString());
    }

    if (productLineId) {
      queryParams.append('productLineId', productLineId.toString());
    }
    if (shipWeekStartDate) {
      queryParams.append('shipWeekStartDate', shipWeekStartDate.toDateString());
    }
    if (shipWeekEndDate) {
      queryParams.append('shipWeekEndDate', shipWeekEndDate.toDateString());
    }

    if (dealerTab) {
      queryParams.append('dealerTab', dealerTab.toString());
    }

    if (shipWeekFilterType) {
      queryParams.append('shipWeekFilterType', shipWeekFilterType.toString());
    }

    if (dealershipId) {
      queryParams.append('dealershipId', dealershipId.toString());
    }

    queryParams.append('showCancelledOrders', showCancelledOrders.toString());

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      '/api/order/orders/dealer',
      {
        params: queryParams,
      }
    );

    yield put(setBoardOrders(orders, page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCsrOrders(action: Action<CsrOrderTableRequest>) {
  try {
    const queryParams = UtilService.getQueryParams(action.payload!);

    const isUserSalesRep: boolean = yield select((state: RootState) =>
      state.authReducer.user?.permissions?.some(
        (permission) =>
          permission.name ===
          userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
      )
    );

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      isUserSalesRep
        ? '/api/order/orders/sales-representative'
        : '/api/order/orders/csr',
      {
        params: queryParams,
      }
    );

    yield put(setBoardOrders(orders, action.payload!.page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrdersForImport(action: Action<CsrOrderTableRequest>) {
  try {
    const queryParams = UtilService.getQueryParams(action.payload!);

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      '/api/order/orders/all-orders',
      {
        params: queryParams,
      }
    );

    yield put(setBoardOrders(orders, action.payload!.page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getImmediateActionsForOrders(
  action: Action<ImmediateActionsForOrdersTableRequest>
) {
  try {
    const { page, itemsPerPage, sortColumn, sortDir, onlyMine } =
      action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('sortColumn', sortColumn);
    queryParams.append('sortDirection', sortDir);
    queryParams.append('onlyMine', onlyMine.toString());

    const orders: PaginatedItems<ImediateActionOrder> = yield call(
      ApiService.get,
      '/api/order/orders/immediate-actions-needed',
      {
        params: queryParams,
      }
    );

    yield put(setImmediateActionsForOdrers(orders));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* orderCancelRequest(action: Action<OrderCancelRequestRequest>) {
  try {
    const { orderId } = action.payload!;
    yield call(
      ApiService.put,
      `api/order/orders/${orderId}/request-cancellation`,
      {}
    );

    yield put(setOrderCancelRequested(orderId));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* ordersSagas() {
  yield takeEvery(OrdersActions.GET_DEALER_ORDERS, getDealerOrders);
  yield takeEvery(OrdersActions.GET_CSR_ORDERS, getCsrOrders);
  yield takeEvery(
    OrdersActions.GET_IMMEDIATE_ACTIONS_FOR_ORDERS,
    getImmediateActionsForOrders
  );
  yield takeEvery(OrdersActions.ORDER_CANCEL_REQUEST, orderCancelRequest);
  yield takeEvery(OrdersActions.GET_ORDERS_FOR_IMPORT, getOrdersForImport);
}
