import { AnyAction } from 'redux';

import { OrderItem } from 'shared/interface/OrderRow';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import { QuotesActions } from './quotesActions';

interface InitialState {
  orderTable: PaginatedItems<OrderItem> | null;
}

const initialState: InitialState = {
  orderTable: null,
};

export const quotesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case QuotesActions.SET_BOARD_QUOTES:
      return {
        ...state,
        orderTable: {
          ...action.payload.quotes,
          items:
            state.orderTable && action.payload.append
              ? [...state.orderTable?.items, ...action.payload.quotes.items]
              : [...action.payload.quotes.items],
        } as PaginatedItems<OrderItem>,
      };

    case QuotesActions.RESET_QUOTES:
      return {
        ...state,
        orderTable: null,
      };

    default:
      return state;
  }
};
