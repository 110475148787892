import { FC } from 'react';
import styled from 'styled-components';

import { Wrapper } from 'shared/components/Wrapper';
import { containerWidth } from 'shared/config/Variables';

const FooterWrapper = styled(Wrapper)`
  min-height: 72px;
  padding: 0 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  background-color: ${({ theme }) => theme.white};
  border-top: 1px solid ${({ theme }) => theme.alto};
`;

const FooterContainer = styled(Wrapper)`
  max-width: ${containerWidth}px;
  padding: 15px 15px;
  margin: 0 auto;
  width: 100%;
`;

export const OrderFooter: FC = ({ children }) => {
  return (
    <FooterWrapper flex middle className="order-page-footer">
      <FooterContainer>{children}</FooterContainer>
    </FooterWrapper>
  );
};
