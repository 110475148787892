import { AnyAction } from 'redux';

import { ProductLine } from 'order/interfaces/ProductLine';

import { sharedActions } from './sharedActions';

export interface SharedInitialStore {
  productLines: ProductLine[] | null;
}

const initialState: SharedInitialStore = {
  productLines: null,
};

export const sharedReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case sharedActions.SET_PRODUCT_LINES:
      return {
        ...state,
        productLines: action.payload as ProductLine[],
      };

    default:
      return state;
  }
};
