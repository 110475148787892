import styled from 'styled-components';

import { Spacer, WhiteBox } from 'shared/components/Layout';

import { DealershipPricingForm } from '../components/DealershipPricingForm';
import { DealershipPricingTable } from '../components/DealershipPricingTable';

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const DealershipPricingPage = () => {
  return (
    <div>
      <Container>
        <WhiteBox>
          <DealershipPricingTable />
        </WhiteBox>
      </Container>

      <Spacer h="40px" />

      <Container>
        <WhiteBox>
          <DealershipPricingForm />
        </WhiteBox>
      </Container>
    </div>
  );
};
