import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { Divider, Spacer } from 'shared/components/Layout';
import { H2, PMedium } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';

import {
  getOrderLineItems,
  getOrderStyles,
  setOrderLineItems,
  setOrderStyles,
} from 'order/store/orderActions';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';
import OrderStyle from 'order/components/OrderStyle/OrderStyle';

import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';
import { ReactComponent as RemoveShoppingCartIcon } from 'assets/icons/removeShoppingCart.svg';

import { OrderOverviewTestLabelEnum } from 'tests/enums/OrderOverviewTestEnums';
import { testId } from 'tests/utils';
import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import OrderStyleExpandable from './OrderStyleExpandable';
import { OrderLineItem } from '../orderLineItems/OrderLineItem';
import SortedBy from './SortedBy/SortedBy';

const OrderOverview = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<{ orderId: string }>();
  const [sortedBySequence, setSortedBySequence] = useState(false);
  const [stylesLoading, setStylesLoading] = useState(false);
  const [lineItemsLoading, setLineItemsLoading] = useState(false);

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );

  const orderLineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const loadOrderStyles = () => {
    dispatch(setOrderStyles(null));

    setStylesLoading(true);
    dispatch(getOrderStyles(orderId, setStylesLoading));
  };

  const loadOrderLineItems = () => {
    dispatch(setOrderLineItems(null));
    setLineItemsLoading(true);

    dispatch(
      getOrderLineItems(
        {
          orderId,
          isSalesMaterial: isOrderSalesMaterial ?? false,
          productLineId: customProductLine?.id ?? '',
        },
        setLineItemsLoading
      )
    );
  };

  useEffect(() => {
    if (!isOrderSalesMaterial) {
      loadOrderStyles();
    }

    if (isOrderSalesMaterial !== null) {
      loadOrderLineItems();
    }
  }, [isOrderSalesMaterial, customProductLine]);

  const isLoading =
    (stylesLoading && lineItemsLoading && !isOrderSalesMaterial) ||
    (!stylesLoading && lineItemsLoading && isOrderSalesMaterial);

  useWindowScrollPosition(orderStyles !== null && orderLineItems !== null);

  return (
    <>
      <Wrapper>
        <H2>Review your order</H2>
        <Spacer h="6px" />
        <PMedium color={nevada}>
          See order items{!isOrderSalesMaterial ? ' and their style.' : '.'}
        </PMedium>
      </Wrapper>

      {isOrderSalesMaterial ? <Spacer h="58px" /> : <Spacer h="16px" />}
      {!isLoading && (
        <SortedBy
          onSortBySequence={setSortedBySequence}
          sortedBySequence={sortedBySequence}
        />
      )}

      {isLoading && <Loader size={50} />}
      {sortedBySequence && (
        <>
          <Divider />
          <Spacer h="32px" />
          <H2 {...testId(OrderOverviewTestLabelEnum.STYLES_LBL)}>Styles</H2>
          <Spacer h="8px" />
          <PMedium color={nevada}>
            List of all styles showed in sequence (chronological)
          </PMedium>
          <Spacer h="32px" />
        </>
      )}

      {isOrderSalesMaterial !== null &&
        !isOrderSalesMaterial &&
        !stylesLoading &&
        !orderStyles?.length && (
          <EmptyState
            title="No styles defined yet!"
            icon={<PaletteIcon />}
            iconSize={80}
            marginTop={0}
            marginBottom={30}
          />
        )}

      {orderStyles?.length && !stylesLoading && (
        <Wrapper>
          {orderStyles?.map((orderStyle) => {
            if (!sortedBySequence) {
              return (
                <OrderStyleExpandable
                  key={orderStyle.id}
                  style={orderStyle}
                  expandedByDefault
                >
                  <OrderStyle
                    key={orderStyle.id}
                    loadOrderStyles={loadOrderStyles}
                    style={orderStyle}
                    withLineItems
                    isOnOrderOverviewPage
                    marginBottom={orderStyle.override ? 0 : 16}
                  />
                </OrderStyleExpandable>
              );
            }

            return (
              <OrderStyle
                key={orderStyle.id}
                loadOrderStyles={loadOrderStyles}
                style={orderStyle}
                marginBottom={orderStyle.override ? 0 : 16}
              />
            );
          })}
        </Wrapper>
      )}

      {sortedBySequence && (
        <>
          <Spacer h="32px" />
          <Divider />
          <Spacer h="32px" />
          <H2 {...testId(OrderOverviewTestLabelEnum.ITEM_LIST_LBL)}>
            Item List
          </H2>
          <Spacer h="8px" />
          <PMedium color={nevada}>
            List of all order items showed in sequence
          </PMedium>
          <Spacer h="32px" />
        </>
      )}

      {sortedBySequence &&
        isOrderSalesMaterial !== null &&
        !lineItemsLoading &&
        !orderLineItems?.length && (
          <EmptyState
            title="No line items added yet!"
            icon={<RemoveShoppingCartIcon />}
            iconSize={80}
            marginTop={30}
          />
        )}

      {!lineItemsLoading &&
        (sortedBySequence || isOrderSalesMaterial) &&
        orderLineItems?.map((orderLineItem) => (
          <OrderLineItem lineItem={orderLineItem} />
        ))}

      <OrderLogs bottomPosition />
    </>
  );
};

export default OrderOverview;
