import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { botticelli } from 'shared/config/Colors';
import { CSR } from 'shared/interface/CSR';
import { H4 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';

import { removeOrderAssignee } from '../../store/orderAssigneesActions';
import Assignee from '../Assignee/Assignee';

const SelectedAssigneesContainer = styled.div``;

const SelectedAssignees = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const selectedAssignees = useSelector(
    (state: RootState) => state.orderAssigneesReducer.selectedCSRs
  );

  const [removingAssignee, setRemovingAssignee] = useState<string[]>([]);

  const onRemoveOrderAssigneeSuccessHandler = (user: CSR) => {
    setRemovingAssignee((prevState) =>
      prevState.filter((userId) => userId !== user.id)
    );

    toast.success(
      `You have successfully removed ${user.firstName} as assignee from the order.`
    );
  };

  const onUnselectAssigneeClickHandler = (user: CSR) => {
    setRemovingAssignee((prevState) => [...prevState, user.id]);

    dispatch(
      removeOrderAssignee({ userId: user.id, orderId }, () =>
        onRemoveOrderAssigneeSuccessHandler(user)
      )
    );
  };

  return (
    <SelectedAssigneesContainer>
      <H4>
        Assignees{' '}
        {!!selectedAssignees?.length && `(${selectedAssignees.length} / 6)`}
      </H4>

      <Spacer h="10px" />

      {!selectedAssignees?.length && (
        <Wrapper flex middle center>
          <SVG
            icon={<PlatoLogoIcon width={70} height={70} />}
            color={botticelli}
          />
        </Wrapper>
      )}

      {selectedAssignees?.map((user) => (
        <Assignee
          key={user.id}
          addingOrRemovingInProgress={removingAssignee.includes(user.id)}
          user={user}
          onUnselect={onUnselectAssigneeClickHandler}
        />
      ))}
    </SelectedAssigneesContainer>
  );
};

export default SelectedAssignees;
