import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';

interface CurtainHeaderProps {
  onClick?: () => void;
}

export const CurtainHeader = styled(Wrapper)<CurtainHeaderProps>`
  padding: 14px;
  min-height: 65px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  ${({ onClick }) => onClick && `cursor: pointer;`}

  &:not(:first-child) {
    background-color: ${({ theme }) => theme.wildSandTwo};
  }

  .icon {
    > .arrow-up {
      margin-right: 5px;
      transform: rotate(180deg);
      transition: 300ms ease;
    }
  }

  &.expanded {
    .icon > .arrow-up {
      transform: rotate(0deg);
    }
  }
`;
