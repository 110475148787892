import { Priority } from 'order/enums/priorityEnum';
import { valencia, anzac } from 'shared/config/Colors';
import { OrderHeaderTestBadgeEnum } from 'tests/enums/OrderHeaderEnums';
import { testId } from 'tests/utils';
import { Badge } from './Badge';

export interface PriorityBadgeProps {
  priority: Priority;
  hoverColor?: string;
}

export const PriorityBadge = ({ priority, hoverColor }: PriorityBadgeProps) => {
  switch (priority) {
    case Priority.None:
      return <></>;
    case Priority.Important:
      return (
        <Badge
          bgColor={valencia}
          bgColorHover={hoverColor}
          {...testId(OrderHeaderTestBadgeEnum.IMPORTANT)}
        >
          IMPORTANT
        </Badge>
      );
    case Priority.Attention:
      return (
        <Badge
          bgColor={anzac}
          bgColorHover={hoverColor}
          {...testId(OrderHeaderTestBadgeEnum.ATTENTION)}
        >
          ATTENTION
        </Badge>
      );
    default:
      return <></>;
  }
};
