import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { onlyDigitValidation } from 'shared/validations/validations';
import { IDoorCodeField } from 'order/wizard/orderStyles/interface/DoorCodes';
import { RootState } from 'store';
import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';
import { ExpandButton } from 'order/components/OrderStyle/OrderStyleContainers';
import { kashmirBlue, silverChaliceTwo } from 'shared/config/Colors';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { Column, ColumnHeader, DoorUpchargeField } from './DoorUpchargesStyled';

interface DoorElementProps {
  upchargeValues: IDoorCodeField[];
  elementName: string;
  inputRegisterName: string;
}

export const DoorElement = ({
  upchargeValues,
  elementName,
  inputRegisterName,
}: DoorElementProps) => {
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);
  const { formState, register } = useFormContext<StyleDoorBuilder>();
  const [elementExpanded, setElementExpanded] = useState(true);

  return (
    <Wrapper flex column>
      <Wrapper middle flex gap={5} marginBottom={16} marginTop={16}>
        <ExpandButton
          type="button"
          onClick={() => setElementExpanded(!elementExpanded)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            elementExpanded
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <ColumnHeader maxWidth={100}>{elementName}</ColumnHeader>
      </Wrapper>
      {elementExpanded &&
        upchargeValues
          ?.filter((f) => f.populated)
          .map((fieldValue) => (
            <DoorUpchargeField key={fieldValue.code} flex middle>
              <Column maxWidth={120}>{fieldValue.code}</Column>
              <Column maxWidth={60}>{fieldValue.quantity || ''}</Column>
              <Column maxWidth={112}>
                <Input
                  {...register(
                    `${inputRegisterName}.${fieldValue.code}.upcharge` as unknown as keyof StyleDoorBuilder,
                    onlyDigitValidation()
                  )}
                  aria-invalid={
                    // @ts-ignore
                    formState.errors?.[inputRegisterName]?.[fieldValue.code]
                      ?.upcharge
                      ? 'true'
                      : 'false'
                  }
                  readOnly={
                    !canEdit ||
                    ((fieldValue.quantity ?? 0) > 0 &&
                      (!fieldValue.populated ||
                        fieldValue.populated === fieldValue.isAutomatic))
                  }
                />
              </Column>
              <Spacer w="16px" />
              <Column>
                <Input
                  {...register(
                    `${inputRegisterName}.${fieldValue.code}.reason` as unknown as keyof StyleDoorBuilder
                  )}
                  maxLength={400}
                  readOnly={
                    !canEdit ||
                    ((fieldValue.quantity ?? 0) > 0 &&
                      (!fieldValue.populated ||
                        fieldValue.populated === fieldValue.isAutomatic))
                  }
                />
              </Column>
            </DoorUpchargeField>
          ))}
    </Wrapper>
  );
};
