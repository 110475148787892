import { Warnings } from 'order/warnings/interface/Wranings';

export enum WarningsActions {
  GET_WARNINGS = 'GET_WARNINGS',
  SET_WARNINGS = 'SET_WARNINGS',
}

export const getWarnings = (
  orderId: string,
  loading: (isLoading: boolean) => void
) => ({
  type: WarningsActions.GET_WARNINGS,
  payload: orderId,
  loading,
});

export const setWarnings = (warnings: Warnings | null) => ({
  type: WarningsActions.SET_WARNINGS,
  payload: warnings,
});
