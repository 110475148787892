import {
  OrderStylizationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

export const allStandardAndDisplayOptions = [
  OrderTypeEnums.Standard,
  OrderTypeEnums.Display,
  OrderTypeEnums['Misc. Std.'],
  OrderTypeEnums['CM Standard'],
  OrderTypeEnums.Personal,
  OrderTypeEnums.Preorder,
];

export const csrOnlyStandardAndDisplayOptions = [
  OrderTypeEnums['Misc. Std.'],
  OrderTypeEnums['CM Standard'],
  OrderTypeEnums.Personal,
];

export const allSalesAidsOptions = [
  OrderTypeEnums['Sales Aids'],
  OrderTypeEnums.Net,
  OrderTypeEnums['Misc. Net'],
  OrderTypeEnums['CM Net'],
];

export const csrOnlySalesAidsOptions = [
  OrderTypeEnums.Net,
  OrderTypeEnums['Misc. Net'],
  OrderTypeEnums['CM Net'],
];

const optionsExcept = (options: OrderTypeEnums[], type: OrderTypeEnums) => {
  return options.filter((x) => x !== type);
};

export const dealerOrderTypeOptionsNonEditMode: SelectOptionProps<
  OrderTypeEnums,
  OrderStylizationTypeEnums
>[] = [
  {
    value: OrderTypeEnums.Standard.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Standard],
    disabledFor: [],
  },
  {
    value: OrderTypeEnums.Display.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Display],
    disabledFor: [],
  },
  {
    value: OrderTypeEnums['Sales Aids'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Sales Aids']],
    disabledFor: [],
  },
];

export const dealerOrderTypeOptions: SelectOptionProps<
  OrderTypeEnums,
  OrderStylizationTypeEnums
>[] = [
  {
    value: OrderTypeEnums.Standard.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Standard],
    disabledFor: [
      ...csrOnlySalesAidsOptions,
      ...optionsExcept(
        optionsExcept(allStandardAndDisplayOptions, OrderTypeEnums.Display),
        OrderTypeEnums.Standard
      ),
    ],
  },
  {
    value: OrderTypeEnums['Misc. Std.'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Misc. Std.']],
    disabledFor: [
      ...csrOnlySalesAidsOptions,
      ...optionsExcept(
        allStandardAndDisplayOptions,
        OrderTypeEnums['Misc. Std.']
      ),
    ],
  },
  {
    value: OrderTypeEnums['CM Standard'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['CM Standard']],
    disabledFor: [
      ...csrOnlySalesAidsOptions,
      ...optionsExcept(
        allStandardAndDisplayOptions,
        OrderTypeEnums['CM Standard']
      ),
    ],
  },
  {
    value: OrderTypeEnums.Personal.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Personal],
    disabledFor: [
      ...csrOnlySalesAidsOptions,
      ...optionsExcept(allStandardAndDisplayOptions, OrderTypeEnums.Personal),
    ],
  },
  {
    value: OrderTypeEnums.Display.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Display],
    disabledFor: [
      ...csrOnlySalesAidsOptions,
      ...optionsExcept(
        optionsExcept(allStandardAndDisplayOptions, OrderTypeEnums.Standard),
        OrderTypeEnums.Display
      ),
    ],
  },
  {
    value: OrderTypeEnums['Sales Aids'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Sales Aids']],
    disabledFor: [
      ...optionsExcept(allSalesAidsOptions, OrderTypeEnums['Sales Aids']),
    ],
  },
  {
    value: OrderTypeEnums.Net.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Net],
    disabledFor: [...optionsExcept(allSalesAidsOptions, OrderTypeEnums.Net)],
    disabledForStylization: [OrderStylizationTypeEnums.SALES_MATERIAL],
  },
  {
    value: OrderTypeEnums['Misc. Net'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Misc. Net']],
    disabledFor: [
      ...optionsExcept(allSalesAidsOptions, OrderTypeEnums['Misc. Net']),
    ],
    disabledForStylization: [OrderStylizationTypeEnums.DOOR_SAMPLE_BASE],
  },
  {
    value: OrderTypeEnums['CM Net'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['CM Net']],
    disabledFor: [
      ...optionsExcept(allSalesAidsOptions, OrderTypeEnums['CM Net']),
    ],
    disabledForStylization: [OrderStylizationTypeEnums.DOOR_SAMPLE_BASE],
  },
  {
    value: OrderTypeEnums.Preorder.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Preorder],
    disabledFor: [
      ...optionsExcept(allStandardAndDisplayOptions, OrderTypeEnums.Preorder),
    ],
  },
];

export const csrOrderTypeOptions: SelectOptionProps<
  OrderTypeEnums,
  OrderStylizationTypeEnums
>[] = [
  {
    value: OrderTypeEnums.Standard.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Standard],
  },
  {
    value: OrderTypeEnums['Misc. Std.'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Misc. Std.']],
  },
  {
    value: OrderTypeEnums['CM Standard'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['CM Standard']],
  },
  {
    value: OrderTypeEnums.Personal.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Personal],
  },
  {
    value: OrderTypeEnums.Display.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Display],
  },
  {
    value: OrderTypeEnums['Sales Aids'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Sales Aids']],
  },
  {
    value: OrderTypeEnums.Net.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Net],
    disabledForStylization: [OrderStylizationTypeEnums.SALES_MATERIAL],
  },
  {
    value: OrderTypeEnums['Misc. Net'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['Misc. Net']],
    disabledForStylization: [OrderStylizationTypeEnums.DOOR_SAMPLE_BASE],
  },
  {
    value: OrderTypeEnums['CM Net'].toString(),
    label: OrderTypeEnums[OrderTypeEnums['CM Net']],
    disabledForStylization: [OrderStylizationTypeEnums.DOOR_SAMPLE_BASE],
  },
  {
    value: OrderTypeEnums.Preorder.toString(),
    label: OrderTypeEnums[OrderTypeEnums.Preorder],
    disabledFor: [],
    disabledForStylization: [
      OrderStylizationTypeEnums.DOOR_SAMPLE_BASE,
      OrderStylizationTypeEnums.SALES_MATERIAL,
    ],
  },
];

export const newQuoteTypeOptions: SelectOptionProps<OrderTypeEnums, unknown>[] =
  [
    {
      value: OrderTypeEnums.Quote.toString(),
      label: OrderTypeEnums[OrderTypeEnums.Quote],
    },
  ];
