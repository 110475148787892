import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';
import { changesRequestFinsih } from 'order/wizard/orderAcknowledgement/store/ACKActions';
import { Order } from 'order/interfaces/Order';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  clearCurtain,
  setActiveChannel,
  setActiveLineItemID,
  setActiveStyleID,
  setBackToChanel,
  setCreateTopic,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
} from 'curtain/store/curtainActions';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import Loader from 'shared/components/Loader';
import { testId } from 'tests/utils';
import { ACKTestButtonEnum } from 'tests/enums/ACKTestEnums';

const ActionsContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
  right: 0;
  width: 100%;
`;

const CollaborationActions = () => {
  const { orderId } = useParams<OrderPageParams>();

  const dispatch = useAppDispatch();

  const { acknowledgementId, acknowledgementStatus } = useSelector(
    (state: RootState) => state.orderReducer.order ?? ({} as Order)
  );

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeEntityType = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityType
  );

  const activeEntityName = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityName
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const [requestChangeFinish, setRequestChangeFinish] = useState(false);

  const onCollaborationCancelClickHandler = () => {
    if (activeChannel) {
      dispatch(setActiveChannel(null));
      dispatch(setActiveStyleID(null));
      dispatch(setActiveLineItemID(null));
      dispatch(setBackToChanel(null));
    }

    if (!activeChannel) {
      dispatch(clearCurtain());
    }
  };

  const onCollaborationCreateNewClickHandler = () => {
    dispatch(setCreateTopic(true));

    if (activeEntityType === TopicType.STYLE) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.STYLE,
          label: 'Style',
        })
      );
    }

    if (activeEntityType === TopicType.LINE_ITEM) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.LINE_ITEM,
          label: 'Line Item',
        })
      );
    }

    if ((activeEntityID && activeEntityName) || activeChannel) {
      dispatch(
        setTopicItemDefaultValue({
          value: activeEntityID ?? activeChannel?.entityId,
          label: activeEntityName ?? activeChannel?.name,
        })
      );
    }
  };

  const onCollaborationFinishRequestClickHandler = (close: () => void) => {
    setRequestChangeFinish(true);

    if (orderId && acknowledgementStatus) {
      dispatch(
        changesRequestFinsih(
          {
            orderId,
            acknowledgementId: acknowledgementId as string,
          },
          () => {
            close();
            dispatch(clearCurtain());
          },
          setRequestChangeFinish
        )
      );
    }
  };

  return (
    <ActionsContainer>
      {(acknowledgementStatus ?? 0) !== ACKStatusEnum.CHANGES_REQUESTED && (
        <>
          <ButtonSecondary onClick={onCollaborationCancelClickHandler}>
            Cancel
          </ButtonSecondary>

          <Spacer w="10px" />

          <ButtonPrimary onClick={onCollaborationCreateNewClickHandler}>
            Create New
          </ButtonPrimary>
        </>
      )}

      {(acknowledgementStatus ?? 0) === ACKStatusEnum.CHANGES_REQUESTED && (
        <>
          <ButtonSecondary onClick={onCollaborationCreateNewClickHandler}>
            Create New
          </ButtonSecondary>

          <Spacer w="10px" />

          <ConfirmationModal
            trigger={
              <ButtonPrimary
                disabled={requestChangeFinish}
                {...testId(ACKTestButtonEnum.FINISH_REQUEST)}
              >
                Finish Request
                <Loader
                  insideButton
                  hidden={!requestChangeFinish}
                  size={16}
                  noSpacing
                />
              </ButtonPrimary>
            }
            title="Finish Requesting Changes"
            message="If done with requesting changes, please proceed so the responsible person for this order can take actions."
            buttonText="Finish"
            confirm={(close) => onCollaborationFinishRequestClickHandler(close)}
          />
        </>
      )}
    </ActionsContainer>
  );
};

export default CollaborationActions;
