export enum OrderStylesProductLineTestInputEnum {
  NAME_INPUT = 'name-input',
}

export enum OrderStylesProductLineTestOptionEnum {
  PRODUCT_LINE_OPTION = 'product-line-option',
}

export enum OrderStylesProductLineTestButtonEnum {
  NEXT_BTN = 'next-btn',
}
