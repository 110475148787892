import { AnyAction } from 'redux';
import { TopicActions } from './TopicActions';
import { IChannel } from '../../../../curtain/interfaces/IChannel';
import { ICurtainTopic } from '../../../../curtain/interfaces/ICurtainTopic';

export interface TopicInitialStore {
  styleChannels: IChannel[] | null;
  lineItemChannels: IChannel[] | null;
  styleTopics: ICurtainTopic[] | null;
  lineItemTopics: ICurtainTopic[] | null;
  otherTopics: ICurtainTopic[] | null;
}

const initialState: TopicInitialStore = {
  styleChannels: null,
  lineItemChannels: null,
  styleTopics: null,
  lineItemTopics: null,
  otherTopics: null,
};

export const topicReducer = (
  state = initialState as TopicInitialStore,
  action: AnyAction
) => {
  switch (action.type) {
    case TopicActions.CLEAR_TOPIC_REDUCER:
      return { ...initialState };
    case TopicActions.TOPIC_SET_STYLE_CHANNELS:
      return {
        ...state,
        styleChannels: action.payload as IChannel[],
      };
    case TopicActions.TOPIC_SET_LINE_ITEM_CHANNELS:
      return {
        ...state,
        lineItemChannels: action.payload as IChannel[],
      };
    case TopicActions.TOPIC_SET_STYLE_TOPICS:
      return {
        ...state,
        styleTopics: action.payload as ICurtainTopic[],
      };
    case TopicActions.TOPIC_SET_LINE_ITEM_TOPICS:
      return {
        ...state,
        lineItemTopics: action.payload as ICurtainTopic[],
      };
    case TopicActions.TOPIC_SET_OTHER_TOPICS:
      return {
        ...state,
        otherTopics: action.payload as ICurtainTopic[],
      };
    default:
      return state;
  }
};
