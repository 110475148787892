import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { downloadFile } from 'shared/store/sharedActions';
import { IFileData } from 'shared/interface/IFile';
import UtilService from 'shared/services/util.service';

import { useAppDispatch } from './useAppDispatch';

interface FileDownloadReturn {
  fileDownloadHandler: (fileId: string) => void;
  fileDownloading: boolean;
}

export const useFileDownload = (): FileDownloadReturn => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<IFileData | null>(null);

  const [fileDownloading, setFileDownloading] = useState(false);

  useEffect(() => {
    if (file) {
      UtilService.convertFileToBlob(file, setFileDownloading);
    }
  }, [file]);

  const onDownloadFailedHandler = () => {
    toast.error('There was a error while downloading the file.');
    setFileDownloading(false);
  };

  const onDownloadSuccessHandler = (downloadedFile: IFileData) => {
    // toast.success('File has been seccessfully downloaded.');
    setFile(downloadedFile);
  };

  const fileDownloadHandler = (fileId: string) => {
    dispatch(
      downloadFile(fileId, onDownloadSuccessHandler, onDownloadFailedHandler)
    );
    setFileDownloading(true);
  };

  return {
    fileDownloadHandler,
    fileDownloading,
  };
};
