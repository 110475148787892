import { CSSProperties, FC, ReactElement } from 'react';
import styled from 'styled-components';
import { mineShaft } from '../config/Colors';

interface SVGComponentProps {
  icon?: ReactElement<SVGSVGElement>;
  color?: string;
  hoverColor?: string;
  animateTransform?: (keyof CSSProperties)[];
}

type SVGContainerProps = Omit<SVGComponentProps, 'icon'>;

const IconContainer = styled.div<SVGContainerProps>`
  display: inline-block;
  color: ${({ color }) => color};

  ${({ animateTransform }) =>
    animateTransform &&
    `transition: ${animateTransform.map(
      (t, tIndex) =>
        `${t} 300ms${tIndex > animateTransform.length - 1 ? ',' : ''}`
    )} ease;`}

  ${({ hoverColor }) =>
    hoverColor &&
    `
    &:hover {
      color: ${hoverColor}
    }
  `}

  svg {
    display: block;
  }
`;

const SVG: FC<SVGComponentProps> = ({
  icon,
  color,
  hoverColor,
  animateTransform,
}) => {
  return (
    <IconContainer
      className="icon"
      color={color}
      hoverColor={hoverColor}
      animateTransform={animateTransform}
    >
      {icon}
    </IconContainer>
  );
};

SVG.defaultProps = {
  color: mineShaft,
};

export default SVG;
