export enum LineItemHingePositionEnum {
  '&nbsp;' = 1,
  L,
  R,
  B,
}

export enum LineItemPositionEnum {
  '&nbsp;' = 1,
  LF,
  RF,
  '2F',
}

export enum LineItemExposedEndsPositionEnum {
  '&nbsp;' = 1,
  LX,
  RX,
  '2X',
}
