import { call, put, takeEvery } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import {
  AdditionalFieldActiontTypes,
  setAdditionalFields,
} from './additionalFieldsActions';
import { AdditionalField } from '../interfaces/AdditionalField';

function* getAdditionalFields(action: Action<string>) {
  try {
    const response: AdditionalField[] = yield call(
      ApiService.get,
      `/api/catalog/additionalfields/`
    );

    yield put(setAdditionalFields(response));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* additionalFieldsSagas() {
  yield takeEvery(
    AdditionalFieldActiontTypes.GET_ADDITIONAL_FIELDS,
    getAdditionalFields
  );
}
