import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { RootState } from 'store';
import { useAppDispatch } from './useAppDispatch';

interface IsStepDirtyOptions {
  isDirty: boolean;
  dirtySetter: (isDirty: boolean) => AnyAction;
  dirtyGetter: (state: RootState) => boolean;
}

export const useIsStepDirty = ({
  isDirty,
  dirtySetter,
  dirtyGetter,
}: IsStepDirtyOptions) => {
  const dispatch = useAppDispatch();

  const isStepDrity = useSelector(dirtyGetter);

  useEffect(() => {
    if (isDirty !== isStepDrity) {
      dispatch(dirtySetter(isDirty));
    }
  }, [isDirty]);

  useEffect(() => {
    return () => {
      if (isDirty && isStepDrity) {
        dispatch(dirtySetter(false));
      }
    };
  }, []);
};
