import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import UtilService from 'shared/services/util.service';

export type QueryField<T> = {
  [key in keyof T]: string;
};

export const useQueryParams = <T>(queries: (keyof T)[]) => {
  const location = useLocation();
  const [fields, setFields] = useState<QueryField<T>>();

  useEffect(() => {
    const query = queries.reduce((agg, curr) => {
      const queryFieldValue = UtilService.parseQueryString(
        location.search,
        curr as string
      );

      const b = {
        ...agg,
        ...(queryFieldValue && {
          [curr]: queryFieldValue,
        }),
      } as QueryField<T>;

      return b;
    }, {} as QueryField<T>);

    setFields(query);
  }, [location.search]);

  return [fields];
};
