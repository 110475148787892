import styled from 'styled-components';
import SearchIcon from 'assets/icons/search.svg';

export interface InputProps {
  password?: 0 | 1;
}

export const Input = styled.input<InputProps>`
  &:not([type='checkbox']) {
    background: ${({ theme }) => theme.white};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.mischka};
    color: ${({ theme }) => theme.nevada};
    font-size: 16px;
    line-height: 20px;
    padding: 12px 16px;
    transition: border-color 300ms, background-color 300ms ease;
    width: 100%;

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    &[aria-invalid='true'] {
      background-color: ${({ theme }) => theme.fairPink};
      border-color: ${({ theme }) => theme.valencia};
      color: ${({ theme }) => theme.valencia};
    }

    &:not([aria-invalid='true']):not(:read-only):focus {
      background-color: ${({ theme }) => theme.catskillWhite};
      border-color: ${({ theme }) => theme.pigeonPost};
    }

    /* if input is type of password */
    ${({ password }) => password && 'padding-right: 45px;'}

    &::placeholder {
      color: ${({ theme }) => theme.silverSand};
    }

    &[disabled],
    &:read-only {
      cursor: not-allowed;
      opacity: 1;
      background-color: ${({ theme }) => theme.wildSand};
      border-color: ${({ theme }) => theme.mischka};
      color: ${({ theme }) => theme.silverSandTwo};
    }
  }

  &[data-role='search'] {
    padding-left: 46px;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: 15px center;
  }
`;
