import { call, put, takeEvery } from 'redux-saga/effects';

import {
  updateCurrentUser,
  updateUserProfilePhoto,
} from 'auth/store/authActions';

import { Action } from 'shared/interface/Action';
import { ImageResponse } from 'shared/interface/serverResponses/ImageResponse';
import { User } from 'shared/interface/User';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { editUserDetailsActions } from './editUserDetailsActions';

function* updateUserDetails(action: Action<User>) {
  try {
    const response: User = yield call(
      ApiService.put,
      '/api/administration/users/profile',
      { ...action.payload }
    );

    yield put(updateCurrentUser(response));

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* uploadProfilePhoto(action: Action<{ imageFile: File }>) {
  try {
    const imageResponse: ImageResponse = yield call(
      ApiService.post,
      '/api/storage/images',
      action.payload
    );
    action.onSuccess!(imageResponse);
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateUserWithProfilePhoto(action: Action<ImageResponse>) {
  try {
    yield call(ApiService.put, '/api/administration/users/profile-image', {
      imageId: action.payload!.id,
    });

    yield put(updateUserProfilePhoto(action.payload!.url));

    if (action.onSuccess) {
      yield call(action.onSuccess!);
    }
  } catch (e) {
    yield call(ApiService.delete, `/api/storage/images/${action.payload!.id}`);
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* editUserDetailsSagas() {
  yield takeEvery(
    editUserDetailsActions.UPDATE_USER_DETAILS,
    updateUserDetails
  );
  yield takeEvery(
    editUserDetailsActions.UPLOAD_PROFILE_PHOTO,
    uploadProfilePhoto
  );
  yield takeEvery(
    editUserDetailsActions.UPDATE_USER_WITH_PROFILE_PHOTO,
    updateUserWithProfilePhoto
  );
}
