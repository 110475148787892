import styled from 'styled-components';
import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';

import CheckIcon from 'assets/icons/check.svg';

export const Stepper = styled(ReactTabs)``;

export const StepperList = styled(ReactTabList)`
  counter-reset: section;
  display: flex;
  margin: 0 auto;
`;

export const Step = styled(ReactTab)`
  align-items: center;
  color: ${({ theme }) => theme.alto};
  counter-increment: section;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-right: 48px;
  position: relative;
  transition: 300ms;

  &::before {
    align-items: center;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.gallery};
    color: ${({ theme }) => theme.silver};
    content: counter(section);
    display: flex;
    height: 26px;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 2px;
    pointer-events: none;
    width: 26px;
    z-index: 1;
  }

  &::after {
    background-color: ${({ theme }) => theme.gallery};
    content: '';
    height: 2px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    width: calc(100% + 48px);
    top: 16px;
  }

  &.step-checked {
    color: ${({ theme }) => theme.lynch};

    &::before {
      background-color: ${({ theme }) => theme.lynch};
      background-image: url(${CheckIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      border-color: transparent;
      content: '';
    }

    &::after {
      background-color: ${({ theme }) => theme.lynch};
    }
  }

  &.react-tabs__tab {
    &--selected {
      color: ${({ theme }) => theme.lynch};

      &::before {
        background-color: ${({ theme }) => theme.lynch};
        border: 4px solid ${({ theme }) => theme.hawkesBlue};
        color: ${({ theme }) => theme.white};
        margin-bottom: 8px;
        margin-top: 0;
      }
    }

    &--disabled {
      pointer-events: none;

      &::before {
        background: ${({ theme }) => theme.gallery};
        border: 2px solid ${({ theme }) => theme.mercury};
      }
    }
  }

  &:first-child {
    &::after {
    }
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }

  ${({ readOnly }) => readOnly && 'pointer-events: none;'}
`;

export const StepPanel = styled(ReactTabPanel)``;
