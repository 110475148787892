import {
  LineItemHingePositionEnum,
  LineItemPositionEnum,
} from '../enums/LineItemPositionEnum';

export const hinges = [
  { id: LineItemHingePositionEnum.L, name: 'Left' },
  { id: LineItemHingePositionEnum.R, name: 'Right' },
  { id: LineItemHingePositionEnum['&nbsp;'], name: 'None' },
];

export const finishedEnds = [
  { id: LineItemPositionEnum.LF, name: 'Left' },
  { id: LineItemPositionEnum.RF, name: 'Right' },
  { id: LineItemPositionEnum['2F'], name: 'Both' },
  { id: LineItemPositionEnum['&nbsp;'], name: 'None' },
];

export const exposedEnds = [
  { id: LineItemPositionEnum.LF, name: 'Left' },
  { id: LineItemPositionEnum.RF, name: 'Right' },
  { id: LineItemPositionEnum['2F'], name: 'Both' },
  { id: LineItemPositionEnum['&nbsp;'], name: 'None' },
];
