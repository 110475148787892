import { AnyAction } from 'redux';

import { AttachmentsModalStepsEnums } from '../enums/AttachmentsModalStepsEnums';
import { IAttachment } from '../../../interface/IAttachment';
import { attachmentsModalActions } from './attachmentsModalActions';

interface AttachmentsModalStore {
  modalTitle: string;
  modalDesc: string;
  selectedAttachments: IAttachment[];
  currentSelectedAttachment: IAttachment | null;
  activeStep: AttachmentsModalStepsEnums;
}

const initialState: AttachmentsModalStore = {
  modalTitle: AttachmentsModalStepsEnums.ADD_ATT_TITLE,
  modalDesc: AttachmentsModalStepsEnums.ADD_ATT_DESC,
  selectedAttachments: [],
  currentSelectedAttachment: null,
  activeStep: AttachmentsModalStepsEnums.ADD_ATTACHMENTS,
};

export const attachmentsModalReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case attachmentsModalActions.CLEAR_ATTACHMENTS_MODAL:
      return { ...initialState };
    case attachmentsModalActions.SET_SELECTED_ATTACHMENTS:
      return {
        ...state,
        selectedAttachments: [
          ...state.selectedAttachments,
          ...(action.payload as IAttachment[]).filter(
            (att) => !state.selectedAttachments.some((a) => a.id === att.id)
          ),
        ] as IAttachment[],
        currentSelectedAttachment: (action.payload as IAttachment[]).find(
          (att) => !att.completed
        ) as IAttachment,
      };
    case attachmentsModalActions.UPDATE_MODAL_ATTACHMENT:
      return {
        ...state,
        selectedAttachments: state.selectedAttachments.map((attachment) => {
          if (attachment.id === action.payload.id) {
            return action.payload as IAttachment;
          }

          return attachment as IAttachment;
        }) as IAttachment[],
      };
    case attachmentsModalActions.DELETE_MODAL_ATTACHMENT:
      return {
        ...state,
        selectedAttachments: state.selectedAttachments.filter(
          (attachment) => attachment.id !== action.payload
        ) as IAttachment[],
      };

    case attachmentsModalActions.SET_SELECTED_ATTACHMENT:
      return {
        ...state,
        currentSelectedAttachment: action.payload as IAttachment,
      };
    case attachmentsModalActions.SET_ATTACHMENTS_MODAL_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload as AttachmentsModalStepsEnums,
      };
    case attachmentsModalActions.UPDATE_ATTACHMENT_MODAL:
      return {
        ...state,
        modalTitle: action.payload.title as AttachmentsModalStepsEnums,
        modalDesc: action.payload.desc as AttachmentsModalStepsEnums,
        activeStep: action.payload.step as AttachmentsModalStepsEnums,
      };
    default:
      return state;
  }
};
