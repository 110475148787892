import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { ImportStylesModalHeaderEnums } from '../../orderStyles/enums/ImportStylesModalEnum';
import { Style } from '../../orderStyles/interface/Style';

import { ImportStylesModalStepsEnums } from '../enums/ImportStylesModalStepsEnums';
import { ImportLineItemsFlow } from '../../orderStyles/enums/ImportLineItemsFlow';
import {
  LineItem,
  LineItemDetailsImportError,
} from '../../orderStyles/interface/LineItem';
import {
  ImportLineItemsRequest,
  MoveLineItemsRequest,
} from '../../../store/orderActions';

export enum ImportActions {
  CLEAR_IMPORT_STYLES_REDUCER = 'CLEAR_IMPORT_STYLES_REDUCER',
  SET_IMPORT_STYLES_MODAL_META = 'SET_IMPORT_STYLES_MODAL_META',
  SET_IMPORT_STYLES_STEP = 'SET_IMPORT_STYLES_STEP',

  // styles
  SELECT_STYLE_FOR_IMPORT = 'SELECT_STYLE_FOR_IMPORT',
  SELECT_SINGLE_STYLE_FOR_IMPORT = 'SELECT_SINGLE_STYLE_FOR_IMPORT',
  SET_STYLES_FOR_IMPORT = 'SET_STYLES_FOR_IMPORT',
  SET_IMPORT_LINE_ITEMS_FLOW = 'SET_IMPORT_LINE_ITEMS_FLOW',

  // line items
  SET_LINE_ITEMS_FOR_IMPORT = 'SET_LINE_ITEMS_FOR_IMPORT',
  GET_STYLE_LINE_ITEMS = 'GET_STYLE_LINE_ITEMS',
  ADD_SELECTED_LINE_ITEM = 'ADD_SELECTED_LINE_ITEM',
  RESET_SELECTED_LINE_ITEMS = 'RESET_SELECTED_LINE_ITEMS',
  COPY_LINE_ITEMS = 'COPY_LINE_ITEMS',
  MOVE_LINE_ITEMS = 'MOVE_LINE_ITEMS',
}

export const clearImportStyles = () => ({
  type: ImportActions.CLEAR_IMPORT_STYLES_REDUCER,
});

export interface SetImportStylesModalMetaPayload {
  title: ImportStylesModalHeaderEnums;
  previousTitle?: string | null;
  desc: ImportStylesModalHeaderEnums;
  step: ImportStylesModalStepsEnums;
  orderId?: string | null;
  style?: Style | null;
}

export const setImportStylesModalMeta = (
  payload: SetImportStylesModalMetaPayload
) => ({
  type: ImportActions.SET_IMPORT_STYLES_MODAL_META,
  payload,
});

export const addSelectedLineItem = (payload: LineItem) => ({
  type: ImportActions.ADD_SELECTED_LINE_ITEM,
  payload,
});

export const resetSelectedLineItems = () => ({
  type: ImportActions.RESET_SELECTED_LINE_ITEMS,
});

export const selectStyleForImport = (style: Style) => ({
  type: ImportActions.SELECT_STYLE_FOR_IMPORT,
  payload: style,
});

export const setImportStylesModalFlow = (payload: ImportLineItemsFlow) => ({
  type: ImportActions.SET_IMPORT_LINE_ITEMS_FLOW,
  payload,
});

export const selectSingleStyleForImport = (style: Style | null) => ({
  type: ImportActions.SELECT_SINGLE_STYLE_FOR_IMPORT,
  payload: style,
});

export const getLineItems = (
  payload: {
    orderId: string;
    styleId?: string;
    searchTerm?: string;
  },
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ImportActions.GET_STYLE_LINE_ITEMS,
  payload,
  onSuccess,
  loading,
});

export const setImportStylesStep = (step: ImportStylesModalStepsEnums) => ({
  type: ImportActions.SET_IMPORT_STYLES_STEP,
  payload: step,
});

export const setStylesForImport = (payload: Style[] | null) => ({
  type: ImportActions.SET_STYLES_FOR_IMPORT,
  payload,
});

export const setLineItemsForImport = (payload: LineItem[] | null) => ({
  type: ImportActions.SET_LINE_ITEMS_FOR_IMPORT,
  payload,
});

export const copyLineItemsForImport = (
  payload: ImportLineItemsRequest,
  onSuccess?: (response: LineItemDetailsImportError[]) => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ImportActions.COPY_LINE_ITEMS,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const moveLineItemsForImport = (
  payload: MoveLineItemsRequest,
  onSuccess?: (response: LineItemDetailsImportError[]) => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ImportActions.MOVE_LINE_ITEMS,
  payload,
  onSuccess,
  onFailed,
  loading,
});
