import { INotification } from 'overview/interface/Notification';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

export enum NotificationsActions {
  // notifications
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',

  CHANGE_READ_STATUS = 'CHANGE_READ_STATUS',
  SET_READ_STATUS = 'SET_READ_STATUS',

  MARK_AS_SEEN = 'MARK_AS_SEEN',
  SET_AS_SEEN = 'SET_AS_SEEN',

  MARK_ALL_AS_READ = 'MARK_ALL_AS_READ',
  SET_MARK_ALL_AS_READ = 'SET_MARK_ALL_AS_READ',

  GET_NOTIFS_COUNTS = 'GET_NOTIFS_COUNTS',
  SET_NOTIFS_COUNTS = 'SET_NOTIFS_COUNTS',

  SET_UNSEEN_COUNT = 'SET_UNSEEN_COUNT',
  SET_UNREAD_COUNT = 'SET_UNREAD_COUNT',

  SYNC_TOOL_FAIL_NOTIFICATION = 'SYNC_TOOL_FAIL_NOTIFICATION',
  CLEAN_SYNC_TOOL_FAIL_NOTIFICATION = 'CLEAN_SYNC_TOOL_FAIL_NOTIFICATION',
}

export interface GetNotificationsRequest {
  page: string;
  itemsPerPage: number;
  appendItems: boolean;
  showUnread: boolean;
  showCollaborationOnly: boolean;
}

export const getNotifications = (
  payload: GetNotificationsRequest,
  onSuccess: (notifs: INotification[]) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: NotificationsActions.GET_NOTIFICATIONS,
  payload,
  onSuccess,
  loading,
});

export const setNotifications = (
  paginatedNotifications: PaginatedItems<INotification> | null
) => ({
  type: NotificationsActions.SET_NOTIFICATIONS,
  payload: paginatedNotifications,
});

export interface ChangeReadStatusRequest {
  notificationIds: string[];
  read: boolean;
  showCollaborationOnly: boolean;
}

export const changeReadStatus = (
  payload: ChangeReadStatusRequest,
  onFailed: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: NotificationsActions.CHANGE_READ_STATUS,
  payload,
  onFailed,
  loading,
});

export interface SetReadStatusPayload {
  notificationIds: string[];
  read: boolean;
  showCollaborationOnly: boolean;
}

export const setReadStatus = (payload: SetReadStatusPayload) => ({
  type: NotificationsActions.SET_READ_STATUS,
  payload,
});

export const markAllNotifsAsRead = (onlyCollaboration: boolean) => ({
  type: NotificationsActions.MARK_ALL_AS_READ,
  payload: onlyCollaboration,
});

export const setMarkAllNotifsAsRead = (onlyCollaboration: boolean) => ({
  type: NotificationsActions.SET_MARK_ALL_AS_READ,
  payload: onlyCollaboration,
});

export const getNotifsCounts = () => ({
  type: NotificationsActions.GET_NOTIFS_COUNTS,
});
export interface NotificationCountsPayload {
  unseenCount: number;
  unreadCount: number;
  unseenCollaborationCount: number;
  unreadCollaborationCount: number;
}

export const setNotifsCounts = (payload: NotificationCountsPayload) => ({
  type: NotificationsActions.SET_NOTIFS_COUNTS,
  payload,
});

export const setNotifUnseenCount = (unseenCount: number) => ({
  type: NotificationsActions.SET_UNSEEN_COUNT,
  payload: unseenCount,
});

export const setNotifUnreadCount = (unreadCount: number) => ({
  type: NotificationsActions.SET_UNREAD_COUNT,
  payload: unreadCount,
});

export interface MarkNotifsAsSeenRequest {
  notificationIds: string[];
  onlyCollaboration: boolean;
}

export const markNotifsAsSeen = (payload: MarkNotifsAsSeenRequest) => ({
  type: NotificationsActions.MARK_AS_SEEN,
  payload,
});

export const setNotifsAsSeen = (
  notifs: string[],
  onlyCollaboration: boolean
) => ({
  type: NotificationsActions.SET_AS_SEEN,
  payload: {
    notifs,
    onlyCollaboration,
  },
});

export const setSyncToolFailNotification = (notification: INotification) => ({
  type: NotificationsActions.SYNC_TOOL_FAIL_NOTIFICATION,
  payload: notification,
});

export const cleanSyncToolFailNotification = () => ({
  type: NotificationsActions.CLEAN_SYNC_TOOL_FAIL_NOTIFICATION,
});
