import { format } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { zonedTimeToUtc } from 'date-fns-tz';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import UtilService from 'shared/services/util.service';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { FormDateAndTimePicker } from 'shared/components/FormDatePicker';
import { globalDateFormat, globalTimeFormat } from 'shared/config/Variables';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';

import { testId } from 'tests/utils';

import {
  AcknowledgeExpirationDateContainerEnum,
  AcknowledgeExpirationDateSelectEnum,
} from 'tests/enums/AcknowledgeExpirationDateEnums';

import { extendACKExpirationDate } from '../store/ACKActions';
import { ACKTypeEnum } from '../enums/ACKTypeEnum';

const AcknowledgeExpirationDateContainer = styled(Wrapper)`
  z-index: 0;
`;

const AcknowledgeExpirationDate = () => {
  const { orderId } = useParams<OrderPageParams>();
  const dispatch = useAppDispatch();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const latestACK = useSelector(
    (state: RootState) => state.ACKReducer.latestACK
  );

  const [date, setDate] = useState<string | null>(null);

  const [extendingExpirationDate, setExtendingExpirationDate] = useState(false);

  const extendACKExpirationDateSuccessHandler = () => {
    toast.success(
      'You have successfully extended expiration date for this acknowledgement.'
    );
  };

  const onExpirationDateChangeHandler = (
    selectedDate: MaterialUiPickersDate
  ) => {
    if (!selectedDate || !latestACK) return;

    setDate(selectedDate.toISOString() ?? null);

    setExtendingExpirationDate(true);

    const zoned = zonedTimeToUtc(selectedDate.toISOString(), 'UTC');
    const extendedDateTime = zoned.toISOString();

    dispatch(
      extendACKExpirationDate(
        {
          orderId,
          acknowledgementId: latestACK.id,
          extendedDateTime,
        },
        extendACKExpirationDateSuccessHandler,
        setExtendingExpirationDate
      )
    );
  };

  useEffect(() => {
    if (latestACK?.expirationDate) {
      setDate(latestACK.expirationDate);
    }
  }, [latestACK]);

  return !isUserCSR ||
    !latestACK ||
    latestACK.type === ACKTypeEnum['ASAP Acknowledgement'] ? null : (
    <AcknowledgeExpirationDateContainer
      flex
      middle
      justifyEnd
      {...testId(
        AcknowledgeExpirationDateContainerEnum.NOTIFY_COLLABORATORS_CONTAINER
      )}
    >
      <Wrapper
        withTooltip={latestACK.anyApproved}
        position="relative"
        minWidth={340}
      >
        {extendingExpirationDate && (
          <LoaderFullScreen flex justifyEnd middle borderRadius={5}>
            <Loader size={18} noSpacing />
            <Spacer w="10px" />
          </LoaderFullScreen>
        )}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormDateAndTimePicker
            value={UtilService.formatDate({
              date,
              asElement: false,
            })}
            onChange={onExpirationDateChangeHandler}
            placeholder="Extend expiration date..."
            inputProps={{
              'data-testid':
                AcknowledgeExpirationDateSelectEnum.NOTIFY_COLLABORATORS_SELECT,
            }}
            disabled={latestACK?.anyApproved ?? false}
            minDate={latestACK?.expirationDate}
            labelFunc={(selectedDate) =>
              selectedDate
                ? `Expiration Date: ${format(
                    selectedDate,
                    `${globalDateFormat} ${globalTimeFormat}`
                  )}`
                : ''
            }
            iconposition="left"
            format={`${globalDateFormat} ${globalTimeFormat}`}
          />
        </MuiPickersUtilsProvider>

        {latestACK.anyApproved && (
          <Tooltip position="bottom">
            The previously specified date has passed or this is no longer the
            first acknowledgment version.
          </Tooltip>
        )}
      </Wrapper>

      <Spacer w="15px" />
    </AcknowledgeExpirationDateContainer>
  );
};

export default AcknowledgeExpirationDate;
