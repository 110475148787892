import { AnyAction } from 'redux';

import { PaginatedItems } from 'shared/interface/PaginatedItems';

import { ModificationModalStepsEnums } from '../enums/ModificationModalStepsEnums';

import { IModification } from '../interface/IModification';

import {
  lineItemModificationsActions,
  SearchedModifications,
} from './lineItemModificationsActions';

interface OrderModificationsInitialStore {
  modalTitle: string;
  modalDesc: string;
  selectedModifications: IModification[];
  searchedModifications: PaginatedItems<IModification> | null;
  currentSelectedModification: IModification | null;
  activeStep: ModificationModalStepsEnums;
  replaceModification: boolean;
  modificationToReplace?: IModification;
}

const initialState: OrderModificationsInitialStore = {
  modalTitle: ModificationModalStepsEnums.SEARCH_MOD_TITLE,
  modalDesc: ModificationModalStepsEnums.SEARCH_MOD_DESC,
  selectedModifications: [],
  searchedModifications: null,
  currentSelectedModification: null,
  activeStep: ModificationModalStepsEnums.SEARCH_MODIFICATIONS,
  replaceModification: false,
};

export const lineItemModificationsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case lineItemModificationsActions.CLEAR_MODIFICATIONS_MODAL:
      return { ...initialState };
    case lineItemModificationsActions.SET_SELECTED_MODIFICATIONS:
      return {
        ...state,
        selectedModifications: [
          ...state.selectedModifications,
          ...(action.payload as IModification[]),
        ] as IModification[],
        currentSelectedModification: (action.payload as IModification[]).find(
          (mod) => !mod.completed
        ) as IModification,
      };

    case lineItemModificationsActions.SET_SEARCHED_MODIFICATIONS: {
      if (action.payload) {
        const { searchedModifications, appendItems } =
          action.payload as SearchedModifications;

        let updatedItems = searchedModifications.items;

        if (state.searchedModifications && appendItems) {
          updatedItems = [
            ...state.searchedModifications.items,
            ...searchedModifications.items,
          ];
        }

        return {
          ...state,
          searchedModifications: {
            ...searchedModifications,
            items: updatedItems,
          } as PaginatedItems<IModification>,
        };
      }

      return {
        ...state,
        searchedModifications: null,
      };
    }
    case lineItemModificationsActions.UPDATE_MODAL_MODIFICATION:
      return {
        ...state,
        selectedModifications: state.selectedModifications.map(
          (modification) => {
            if (modification.id === action.payload.id) {
              return action.payload as IModification;
            }

            return modification as IModification;
          }
        ) as IModification[],
      };
    case lineItemModificationsActions.DELETE_MODAL_MODIFICATION:
      return {
        ...state,
        selectedModifications: state.selectedModifications.filter(
          (modification) => modification.id !== action.payload
        ) as IModification[],
      };

    case lineItemModificationsActions.SET_SELECTED_MODIFICATION:
      return {
        ...state,
        currentSelectedModification: action.payload as IModification,
      };
    case lineItemModificationsActions.SET_MOD_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload as ModificationModalStepsEnums,
      };
    case lineItemModificationsActions.UPDATE_MODIFICATION_MODAL:
      return {
        ...state,
        modalTitle: action.payload.title as ModificationModalStepsEnums,
        modalDesc: action.payload.desc as ModificationModalStepsEnums,
        activeStep: action.payload.step as ModificationModalStepsEnums,
      };
    case lineItemModificationsActions.SET_MODIFICATION_FLOW:
      return {
        ...state,
        replaceModification: action.payload.replaceModification as boolean,
        modificationToReplace: action.payload
          .modificationToReplace as IModification,
      };
    default:
      return state;
  }
};
