import { RootState } from 'store';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';

import { MoreInfoContainer } from 'shared/components/MoreInfo';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { silverChaliceTwo } from 'shared/config/Colors';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import SVG from 'shared/components/SVG';

import {
  MenuWithDropdown,
  MenuWithDropdownIcon,
} from 'shared/components/MenuWithDropdown';

import { clearOrderAssignees } from './store/orderAssigneesActions';
import SearchAssignees from './components/SearchAssignees/SearchAssignees';
import SelectedAssignees from './components/SelectedAssignees/SelectedAssignees';

const OrderAssigneesContainer = styled.div``;

export const AssigneesList = styled(Wrapper)`
  cursor: pointer;
`;

export const AssigneeAvatarWrapper = styled(Wrapper)`
  margin-right: -15px;

  &:last-child {
    margin-right: 0;
  }
`;

export const AssigneeAvatar = styled.div`
  box-shadow: 0 0 0 2px white;
  border-radius: 50%;

  &:hover {
    + ${Tooltip} {
      opacity: 1;
    }
  }
`;

const OrderAssignees: FC = (props) => {
  const dispatch = useAppDispatch();

  const selectedAssignees = useSelector(
    (state: RootState) => state.orderAssigneesReducer.selectedCSRs
  );

  useEffect(() => {
    return () => {
      dispatch(clearOrderAssignees());
    };
  }, []);

  return (
    <OrderAssigneesContainer {...props}>
      <MenuWithDropdown
        position="bottom right"
        trigger={
          selectedAssignees?.length ? (
            <AssigneesList flex middle>
              {selectedAssignees!.map((user) => (
                <AssigneeAvatarWrapper key={user.id} position="relative">
                  <AssigneeAvatar>
                    <AvatarCircle user={user} size={32} />
                  </AssigneeAvatar>

                  <Tooltip position="bottom">
                    {user.firstName} {user.lastName}
                  </Tooltip>
                </AssigneeAvatarWrapper>
              ))}
            </AssigneesList>
          ) : (
            <MenuWithDropdownIcon>
              <SVG icon={<UsersIcon />} color={silverChaliceTwo} />
              <Tooltip position="bottom">Add an Assignee to this order</Tooltip>
            </MenuWithDropdownIcon>
          )
        }
      >
        <MoreInfoContainer padding="30px">
          <SelectedAssignees />

          <Spacer h="20px" />

          <SearchAssignees />
        </MoreInfoContainer>
      </MenuWithDropdown>
    </OrderAssigneesContainer>
  );
};

export default OrderAssignees;
