import { useDecision } from '@optimizely/react-sdk';
import { FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ProductLineOption from 'order/wizard/orderStyles/shared/steps/OrderStylesProductLine/ProductLineOption/ProductLineOption';
import { LineItemDetailsImportError } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLine } from 'order/interfaces/ProductLine';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import {
  changeStylesStep,
  convertStyle,
  createStyle,
  getStyle,
  setIsStylesStepDirty,
  setReplacementProductLines,
  setReplacementStyle,
  setStyleId,
  setStylesProductLine,
  updateStyleName,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import FormError from 'shared/components/FormError';
import Loader, { LoaderWrapper } from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { GridItem } from 'shared/components/GridItem';
import { H2 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { InvalidLineItems } from 'shared/components/InvalidLineItems';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { getProductLines } from 'shared/store/sharedActions';
import { styleNameValidation } from 'shared/validations/validations';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { testId } from 'tests/utils';

import {
  OrderStylesProductLineTestButtonEnum,
  OrderStylesProductLineTestInputEnum,
  OrderStylesProductLineTestOptionEnum,
} from 'tests/enums/OrderStylesProductLineTestEnums';

import { useIsStepDirty } from '../../../../../../shared/hooks/useIsStepDirty';

const ProductLineOptions = styled(Wrapper)`
  margin-bottom: -15px;

  ${LoaderWrapper} {
    margin: 50px auto;
  }
`;

export interface ProductLineForm {
  productLineOptionId: string;
  name: string;
}

export const OrderStylesProductLine = () => {
  const dispatch = useAppDispatch();

  const [revolaeEnabled] = useDecision('revolae_enabled');
  const [customEnabled] = useDecision('custom_enabled');
  const [icsEnabled] = useDecision('ics_enabled');
  const [inovae2oEnabled] = useDecision('inovae2_o_enabled');
  const [inovaeEnabled] = useDecision('inovae_enabled');
  const [preludeEnabled] = useDecision('prelude_enabled');

  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isConvertConfirmModalOpened, setIsConvertConfirmModalOpened] =
    useState(false);

  const { orderId } = useParams<OrderPageParams>();

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const productLines = useSelector(
    (state: RootState) => state.sharedReducer.productLines
  );

  const replacementProductLines = useSelector(
    (state: RootState) => state.orderStylesReducer.replacementProductLines
  );

  const replacementStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.replacementStyle
  );

  const isConvertFlow = !!replacementStyle;

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const methods = useForm<ProductLineForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: replacementStyle?.name ?? storedStyle?.name ?? '',
      productLineOptionId: storedStyle?.productLine?.id ?? '',
    },
  });

  const productLineOptionIdWatched = methods.watch('productLineOptionId');

  const { formState } = methods;

  useIsStepDirty({
    isDirty: formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const [invalidImportLineItemsModal, setInvalidImportLineItemModal] =
    useState<{
      lineItems?: LineItemDetailsImportError[];
      open: boolean;
    }>({
      open: false,
    });

  const onStyleCreatedSuccessHandler = (styleId: string) => {
    const productLine = productLines?.find(
      (product) => product.id === productLineOptionIdWatched
    );

    if (productLine) {
      dispatch(setStylesProductLine(productLine));
    }

    dispatch(setStyleId(styleId));
    dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));

    if (!storedStyle) {
      dispatch(
        getStyle({
          orderId,
          styleId,
        })
      );
    }
  };

  const onStyleCreatedOrUpdatedErrorHandler = (err: ServerErrorResponse) => {
    setLoading(false);
    if (err.status === 403) {
      toast.error("You don't have permission to create style.");
    } else {
      toast.error('Something went wrong.');
    }
  };

  const onStyleUpdatedSuccessHandler = () => {
    dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));
  };

  const getStyleDetails = () => {
    if (replacementStyle) {
      setLoading(true);

      dispatch(
        getStyle(
          {
            orderId,
            styleId: replacementStyle.id!,
          },
          () => {
            toast.success('You have successfully converted the Style.');
            dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));
            dispatch(setReplacementProductLines(null));
            dispatch(setReplacementStyle(null));
          },
          setLoading
        )
      );
    }
  };

  const onCloseInvalidLineItemsModal = () => {
    getStyleDetails();
    setInvalidImportLineItemModal({ open: false });
  };

  const onConvertStyleSuccessHandler = (
    notImportedLineItems: LineItemDetailsImportError[]
  ) => {
    if (notImportedLineItems?.length) {
      setInvalidImportLineItemModal({
        open: true,
        lineItems: notImportedLineItems,
      });
    } else {
      getStyleDetails();
    }
  };

  const onSubmit = (data: ProductLineForm) => {
    setLoading(true);

    if (isConvertFlow && replacementStyle) {
      dispatch(
        convertStyle(
          {
            orderId,
            productLineId: data.productLineOptionId,
            styleId: replacementStyle.id!,
          },
          onConvertStyleSuccessHandler,
          setLoading
        )
      );

      return;
    }

    if (!storedStyle) {
      dispatch(
        createStyle(
          {
            orderId,
            productLineId: data.productLineOptionId,
            name: data.name,
          },
          onStyleCreatedSuccessHandler,
          onStyleCreatedOrUpdatedErrorHandler,
          setLoading
        )
      );

      return;
    }

    if (storedStyle && isDirty) {
      dispatch(
        updateStyleName(
          {
            name: data.name,
            orderId,
            styleId: storedStyle.id!,
          },
          onStyleUpdatedSuccessHandler,
          onStyleCreatedOrUpdatedErrorHandler,
          setLoading
        )
      );

      return;
    }

    dispatch(changeStylesStep(StylesStepsEnum.SPECIFICATIONS));
  };

  // check if product line is already saved
  const isProductLineAlreadySaved = (productLine: ProductLine) => {
    if (selectedProductLine && productLine) {
      return selectedProductLine.id !== productLine.id;
    }

    return false;
  };

  const isProductLineDisabled = (productLineName: string) => {
    switch (productLineName) {
      case ProductLineEnums.PRODUCT_LINE_CUSTOM:
        return customEnabled.enabled;

      case ProductLineEnums.PRODUCT_LINE_PRELUDE:
        return preludeEnabled.enabled;

      case ProductLineEnums.PRODUCT_LINE_INOVAE:
        return inovaeEnabled.enabled;

      case ProductLineEnums.PRODUCT_LINE_REVOLAE:
        return revolaeEnabled.enabled;

      case ProductLineEnums.PRODUCT_LINE_ICS:
        return icsEnabled.enabled;

      case ProductLineEnums.PRODUCT_LINE_INOVAE2O:
        return inovae2oEnabled.enabled;

      default:
        return false;
    }
  };

  const sameAsReplacementProductLine = (productLineName: string) => {
    return productLineName === replacementStyle?.productLine.name;
  };

  // needs to be done because component wont rerender when form becomes dirt.
  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState]);

  useEffect(() => {
    if (!productLines) {
      dispatch(getProductLines());
    }
  }, []);

  return (
    <>
      <Spacer h="48px" />
      <H2>Name a style and select a product line</H2>
      <Spacer h="48px" />

      <FormProvider {...methods}>
        <Form>
          <FormElement maxWidth={410} flexGrow>
            <FormLabel>Name</FormLabel>

            <Wrapper withTooltip={isConvertFlow} tooltipDelay={500}>
              <Input
                {...methods.register(
                  'name',
                  styleNameValidation({ required: true, maxLength: 200 })
                )}
                type="text"
                placeholder="Enter style name"
                data-test="input-name"
                aria-invalid={formState.errors.name ? 'true' : 'false'}
                disabled={!canEdit || isConvertFlow}
                {...testId(OrderStylesProductLineTestInputEnum.NAME_INPUT)}
              />

              {isConvertFlow && (
                <Tooltip>
                  Style name cannot be changed during style conversion
                </Tooltip>
              )}
            </Wrapper>

            <FormError
              label="Style name"
              error={formState.errors.name}
              validationSchema={styleNameValidation({
                required: true,
                maxLength: 200,
              })}
            />
          </FormElement>

          {/* <Info>
            Keep in mind that Style #1 should be the primary style of an order.
          </Info>

          <Spacer h="13px" /> */}

          <ProductLineOptions flex flexWrap>
            {(replacementProductLines ?? productLines)?.map((productLine) => (
              <GridItem key={productLine.id} gridSize={3} gridGap={15}>
                <ProductLineOption
                  productLine={productLine}
                  isDisabled={
                    isProductLineAlreadySaved(productLine) ||
                    !isProductLineDisabled(productLine.name) ||
                    sameAsReplacementProductLine(productLine.name)
                  }
                  {...testId(
                    OrderStylesProductLineTestOptionEnum.PRODUCT_LINE_OPTION
                  )}
                />
              </GridItem>
            ))}
          </ProductLineOptions>

          <OrderFooter>
            <Wrapper flex middle justifyEnd>
              <ButtonPrimary
                disabled={
                  loading ||
                  (storedStyle === null && productLineOptionIdWatched === '')
                }
                type="button"
                onClick={
                  isConvertFlow
                    ? () => setIsConvertConfirmModalOpened(true)
                    : methods.handleSubmit(onSubmit)
                }
                {...testId(OrderStylesProductLineTestButtonEnum.NEXT_BTN)}
              >
                {UtilService.styleNavigationActionsLables(
                  isConvertFlow ? 'Convert' : 'Next',
                  canEdit && formState.isDirty
                )}
                <Loader hidden={!loading} insideButton noSpacing size={16} />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>

          <Spacer h="30px" />
        </Form>

        <ConfirmationModal
          title="Converting a Style"
          buttonText="Convert"
          cancelButtonText="Cancel"
          cancel={() => setIsConvertConfirmModalOpened(false)}
          confirm={() => {
            setIsConvertConfirmModalOpened(false);
            methods.handleSubmit(onSubmit)();
          }}
          message={`Are you sure you want to convert ${
            replacementStyle?.name
          } from ${replacementStyle?.productLine.name} to ${
            replacementProductLines?.find(
              (replacementProductLine) =>
                replacementProductLine.id === productLineOptionIdWatched
            )?.name
          }?`}
          opened={isConvertConfirmModalOpened}
        />
        <ConfirmationModal
          title="List of non available Line Item(s)"
          opened={invalidImportLineItemsModal.open}
          confirm={onCloseInvalidLineItemsModal}
          buttonText="Close"
          shouldNotRenderCancelButton
        >
          <InvalidLineItems
            reason="The following Line Item(s) are not available in the selected product line:"
            invalidLineItems={invalidImportLineItemsModal.lineItems}
          />
        </ConfirmationModal>
      </FormProvider>
    </>
  );
};
