import { FC } from 'react';

import { IDoorCodeInsertPanel } from 'order/wizard/orderStyles/interface/DoorCodes';

import { P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

interface InsertPanelInfoProps {
  insertPanel: IDoorCodeInsertPanel | undefined | null;
  marginLeft?: number;
}

const InsertPanelInfo: FC<InsertPanelInfoProps> = ({
  insertPanel,
  marginLeft,
}) => {
  return insertPanel ? (
    <>
      <Spacer h="6px" />
      <Wrapper flex>
        <Spacer w={`${marginLeft ?? 6}px`} />
        <P fontSize={10} color={nevada}>
          {insertPanel.description || insertPanel.name} ({insertPanel.quantity})
        </P>
      </Wrapper>
    </>
  ) : (
    <Spacer h="20px" />
  );
};

export default InsertPanelInfo;
