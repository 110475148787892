import { FC, useState, useEffect } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CreateNoteIcon } from 'assets/icons/add-note.svg';
import { ReactComponent as EditNoteIcon } from 'assets/icons/edit-note.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';

import { OrderLineItemModalParams } from 'order/wizard/orderLineItems/interface/OrderLineItemModalParams';
import {
  setLineItemAttachments,
  updateLineItemAttachmentNote,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { H6, PSmall, P } from 'shared/components/Typography';
import { IAttachment } from 'shared/interface/IAttachment';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { silverSandTwo, lynch } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useFileDownload } from 'shared/hooks/useFileDownload';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import ElipsisText from 'shared/components/ElipsisText';
import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import {
  ButtonIcon,
  ButtonTertiaryDark,
  ButtonTertiaryLight,
} from 'shared/components/Button';

import { AttachmentBoxTestButtonEnum } from 'tests/enums/AttachmentBoxTestEnums';
import { testId } from 'tests/utils';

import { deleteFile } from 'shared/store/sharedActions';
import {
  deleteOrderAttachment,
  setOrderAttachments,
  updateOrderAttachmentNote,
} from '../../order/wizard/orderAttachments/store/orderAttachmentsActions';

interface AttachmentBoxProps {
  attachment: IAttachment;
  lineItemConfiguration?: boolean;
  disableActions?: boolean;
  canDownload?: boolean;
}

interface FormInputs {
  note: string;
}

const AttachmentBoxContainer = styled.div`
  padding: 16px 32px 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  margin-bottom: 20px;
  ${BoxShadowCSS}

  &:last-child {
    margin-bottom: 0;
  }
`;

const AttachmentNoteContainer = styled(Wrapper)`
  margin-top: 20px;

  textarea {
    font-size: 12px;
    line-height: 16px;
  }
`;

const AttachmentNote = styled(P)`
  padding: 20px 10px;
  font-size: 12px;
`;

const AttachmentNoteButtonContainer = styled(Wrapper)`
  width: 100%;
`;

const AttachmentBox: FC<AttachmentBoxProps> = ({
  attachment,
  lineItemConfiguration,
  disableActions,
  canDownload,
}) => {
  const dispatch = useAppDispatch();

  const { fileDownloadHandler, fileDownloading } = useFileDownload();

  const [noteShown, isNoteShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, getValues, setValue } = useForm<FormInputs>();

  const orderAttachments = useSelector(
    (state: RootState) => state.orderReducer.attachments
  );

  const lineItemAttachments = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemAttachments
  );

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'lineItemId',
  ]);

  const [lineItemEditMode, setLineItemEditMode] = useState(
    queryFields?.lineItemId ?? false
  );

  const orderData = useSelector((state: RootState) => state.orderReducer.order);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const toggleNote = () => {
    isNoteShown((prevState) => !prevState);
  };

  const onSuccessfulAttachmentDeletion = (attachmentId: string) => {
    setIsDeleteModalVisible(false);

    const newListOfAttachments = (
      (!lineItemConfiguration ? orderAttachments : lineItemAttachments) ?? []
    ).filter((deletedAttachment) => deletedAttachment.id !== attachmentId);

    if (!lineItemConfiguration) {
      setTimeout(() => {
        dispatch(setOrderAttachments(newListOfAttachments));
      }, 0);
    }

    if (lineItemConfiguration) {
      dispatch(setLineItemAttachments(newListOfAttachments));
    }

    toast.success('You have successfully deleted an attachment.');
  };

  const onFailedAttachmentDeletion = (err: ServerErrorResponse) => {
    setIsDeleteModalVisible(false);

    if (err.status === 403) {
      toast.error("You don't have permission to delete this attachment.");
    } else {
      toast.error('Could not delete the attachment.');
    }
  };

  const onSuccessfulAttachmentNoteUpdate = (
    attachmentId: string,
    note: string
  ) => {
    setIsLoading(false);
    setEditMode(false);

    toast.success('You have successfully updated an attachment note.');

    const newListOfAttachments = (
      (!lineItemConfiguration ? orderAttachments : lineItemAttachments) ?? []
    ).map((orderAttachment) => {
      if (orderAttachment.id === attachmentId) {
        return {
          ...orderAttachment,
          note,
        };
      }
      return orderAttachment;
    });

    if (!lineItemConfiguration) {
      dispatch(setOrderAttachments(newListOfAttachments));
    }

    if (lineItemConfiguration) {
      dispatch(setLineItemAttachments(newListOfAttachments));
    }
  };

  const onFailedAttachmentNoteUpdate = (err: ServerErrorResponse) => {
    setIsLoading(false);

    if (err.status === 403) {
      toast.error("You don't have permission to update attachment note.");
    } else {
      toast.error('Could not update note.');
    }
  };

  const updateButtonHandler = (attachmentId: string, note: string) => {
    if (!lineItemEditMode && lineItemConfiguration) {
      dispatch(updateLineItemAttachmentNote({ ...attachment, note }));
      setEditMode(false);
      return;
    }

    setIsLoading(true);
    dispatch(
      updateOrderAttachmentNote(
        {
          attachmentId,
          orderId: orderData!.id ? orderData!.id : '',
          note,
        },
        () => onSuccessfulAttachmentNoteUpdate(attachmentId, note),
        onFailedAttachmentNoteUpdate
      )
    );
  };

  const deleteButtonHandler = () => {
    // if attachment box IS NOT on line items
    if (!lineItemEditMode && lineItemConfiguration) {
      dispatch(
        deleteFile(
          attachment.fileId!,
          () => onSuccessfulAttachmentDeletion(attachment.id!),
          () => onFailedAttachmentDeletion
        )
      );

      return;
    }

    dispatch(
      deleteOrderAttachment(
        {
          attachmentId: attachment.id!,
          orderId: orderData!.id ? orderData!.id : '',
        },
        onSuccessfulAttachmentDeletion,
        onFailedAttachmentDeletion
      )
    );
  };

  useEffect(() => {
    setLineItemEditMode(queryFields?.lineItemId ?? false);
  }, [queryFields?.lineItemId]);

  useEffect(() => {
    if (attachment != null) {
      setValue('note', attachment.note);
    }
  }, [attachment]);

  useEffect(() => {
    return () => {
      UtilService.onPopupClose();
    };
  }, []);

  return (
    <AttachmentBoxContainer>
      <Wrapper flex middle>
        <Spacer w="20px" />

        <H6>
          <ElipsisText maxWidth={120} minWidth={120}>
            {attachment.name}
            {attachment.extension}
          </ElipsisText>
        </H6>

        <Spacer w="30px" />

        <Wrapper mlAuto flex middle>
          <PSmall>
            {attachment.lineItem &&
              `#${attachment.lineItem.number} ${attachment.lineItem?.code}`}
          </PSmall>

          <Spacer w="60px" />

          <PSmall>{attachment.userFullName}</PSmall>

          <Spacer w="30px" />

          <PSmall>
            {UtilService.formatDate({
              date: attachment.createdOnUtc!,
              isUtc: true,
            })}
          </PSmall>

          <Spacer w="60px" />

          <PSmall>{UtilService.bytesToMB(attachment.size!)}MB</PSmall>

          <Spacer w="16px" />

          <ButtonIcon
            onClick={toggleNote}
            disabled={(!canEdit && !attachment.note) || disableActions}
            {...testId(AttachmentBoxTestButtonEnum.TOGGLE_NOTE_BTN)}
          >
            <SVG
              icon={!attachment.note ? <CreateNoteIcon /> : <EditNoteIcon />}
              color={noteShown || attachment.note ? lynch : silverSandTwo}
              hoverColor={lynch}
            />
          </ButtonIcon>

          <Spacer w="16px" />

          <ButtonIcon
            onClick={() => fileDownloadHandler(attachment.fileId!)}
            disabled={disableActions && !canDownload}
          >
            {fileDownloading ? (
              <Loader size={18} noSpacing />
            ) : (
              <SVG
                icon={<SaveIcon />}
                color={silverSandTwo}
                hoverColor={lynch}
              />
            )}
          </ButtonIcon>

          {/* <Spacer w="16px" />

          <ButtonIcon onClick={() => console.log('clicked')}>
            <SVG
              icon={<PrintIcon />}
              color={silverSandTwo}
              hoverColor={lynch}
            />
          </ButtonIcon> */}

          <Spacer w="16px" />

          <ButtonIcon
            onClick={() => setIsDeleteModalVisible(true)}
            disabled={!canEdit || disableActions}
            {...testId(AttachmentBoxTestButtonEnum.DELETE_BTN)}
          >
            <SVG
              icon={<TrashIcon />}
              color={silverSandTwo}
              hoverColor={lynch}
            />
          </ButtonIcon>
        </Wrapper>
      </Wrapper>

      {!editMode && noteShown && (
        <>
          <AttachmentNote>{attachment.note}</AttachmentNote>

          <Wrapper flex justifyEnd>
            <ButtonTertiaryDark
              type="button"
              onClick={() => setEditMode((prevState) => !prevState)}
              disabled={isLoading || !canEdit}
              {...testId(AttachmentBoxTestButtonEnum.ADD_OR_EDIT_NOTE_BTN)}
            >
              {!attachment.note ? 'Add' : 'Edit'} note
              <Loader hidden={!isLoading} insideButton noSpacing size={16} />
            </ButtonTertiaryDark>
          </Wrapper>
        </>
      )}

      {editMode && (
        <AttachmentNoteContainer flex column middle>
          <Textarea
            placeholder="Start writing a note"
            cols={30}
            rows={3}
            {...register('note')}
          />

          <Spacer h="16px" />

          <AttachmentNoteButtonContainer flex justifyEnd middle>
            <ButtonTertiaryLight
              onClick={() => {
                setEditMode((prevState) => !prevState);
                setValue('note', attachment.note);
              }}
            >
              Cancel
            </ButtonTertiaryLight>

            <Spacer w="16px" />

            <ButtonTertiaryDark
              type="button"
              onClick={() =>
                updateButtonHandler(attachment.id!, getValues('note'))
              }
              disabled={isLoading}
            >
              Save
              <Loader hidden={!isLoading} insideButton noSpacing size={16} />
            </ButtonTertiaryDark>
          </AttachmentNoteButtonContainer>
        </AttachmentNoteContainer>
      )}

      <ConfirmationModal
        cancel={() => setIsDeleteModalVisible(false)}
        confirm={deleteButtonHandler}
        message={
          lineItemConfiguration
            ? 'This attachment and its data will be deleted from line item. This cannot be undone.'
            : 'If this was the action you wanted to do, please confirm your choice, or cancel and return to the page.'
        }
        opened={isDeleteModalVisible}
        title="Are you sure?"
      />
    </AttachmentBoxContainer>
  );
};

AttachmentBox.defaultProps = {
  lineItemConfiguration: false,
};

export { AttachmentBox };
