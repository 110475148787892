import {
  ForwardedRef,
  FC,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react';

import styled from 'styled-components';
import { ReactComponent as IconEye } from 'assets/icons/eye.svg';
import { Input } from './Input';

interface PasswordToggleProps {
  passwordVisible: boolean;
}

const PasswordInputContainer = styled.div`
  position: relative;
`;

const PasswordToggle = styled.button<PasswordToggleProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  &::before {
    content: '';
    width: 2px;
    height: 17px;
    transform-origin: top;
    position: absolute;
    right: 8px;
    top: 9px;
    background-color: ${({ theme }) => theme.lynch};
    transform: rotate(45deg) scaleY(0);
    transition: 300ms ease;
    border-radius: 1px;
    box-shadow: 0 0 0 1px white;
  }

  ${({ passwordVisible }) =>
    passwordVisible &&
    ` &::before {
        transform: rotate(45deg) scaleX(1);
      }
  `}
`;

const PasswordInput: FC<InputHTMLAttributes<HTMLInputElement>> = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const [passwordVisible, togglePasswordVisibility] = useState(false);

    const handlePasswordVisibilityChange = () => {
      togglePasswordVisibility(!passwordVisible);
    };

    return (
      <>
        <PasswordInputContainer>
          <Input
            ref={ref}
            {...props}
            type={passwordVisible ? 'text' : 'password'}
            password={1}
          />

          <PasswordToggle
            type="button"
            passwordVisible={passwordVisible}
            onClick={handlePasswordVisibilityChange}
          >
            <IconEye />
          </PasswordToggle>
        </PasswordInputContainer>
      </>
    );
  }
);

export { PasswordInput };
