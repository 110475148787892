import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { getOrderStatus, setOrderAckStatus } from 'order/store/orderActions';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import { setCurtainTab } from 'curtain/store/curtainActions';

import { anzac, lynch } from 'shared/config/Colors';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';

import { testId } from 'tests/utils';
import {
  ACKTestButtonEnum,
  ACKTestContainerEnum,
} from 'tests/enums/ACKTestEnums';

import { AckConsentForm } from '../Acknowledgment';
import { ACKStatusEnum } from '../enums/ACKStatusEnum';
import { approveACK, changeRequest } from '../store/ACKActions';

const DealerACKActionsContainer = styled(Wrapper)``;

const DealerACKActions = () => {
  const dispatch = useAppDispatch();
  const methodsContext = useFormContext<AckConsentForm>();

  const { orderId } = useParams<OrderPageParams>();

  const [approveACKModalOpened, setApproveACKModalOpened] = useState(false);

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const latestACK = useSelector(
    (state: RootState) => state.ACKReducer.latestACK
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const onRequestChangesACKSuccessHandler = (close?: () => void) => {
    toast.success(
      `You have successfully requested changes for this order.${
        isUserDealerOrSalesRep ? ' Please collaborate your changes.' : ''
      }`
    );

    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
    dispatch(setOrderAckStatus(ACKStatusEnum.CHANGES_REQUESTED));

    if (close) {
      close();
      UtilService.onPopupClose();
    }
  };

  const onRequestChangesACK = (close?: () => void) => {
    if (latestACK) {
      dispatch(
        changeRequest({ acknowledgementId: latestACK.id, orderId }, () =>
          onRequestChangesACKSuccessHandler(close)
        )
      );
    }
  };

  const onApproveACKSuccessHandler = (close?: () => void) => {
    toast.success('You have successfully approved this order.');

    if (close) {
      UtilService.onPopupClose();
      setApproveACKModalOpened(false);
      close();
    }

    // get latest order status
    dispatch(getOrderStatus(orderId));
  };

  const onApproveACK = (close?: () => void) => {
    if (latestACK) {
      dispatch(
        approveACK(
          {
            acknowledgementId: latestACK.id,
            orderId,
          },
          () => onApproveACKSuccessHandler(close)
        )
      );
    }
  };

  return (
    <DealerACKActionsContainer
      flex
      middle
      justifyEnd
      {...testId(ACKTestContainerEnum.DEALER_ACTIONS)}
    >
      {/* NORMAL ACK */}
      <ConfirmationModal
        confirmBgColor={isUserCSR ? anzac : undefined}
        title="Request Changes"
        buttonText={isUserCSR ? 'Yes' : 'Proceed and Collaborate'}
        htmlMessage={
          isUserCSR
            ? `Are you sure you want to cancel this acknowledgment <strong>on behalf of the dealer</strong>?`
            : `Please request your changes in the collaboration window. <br/><br/> <span style="color: ${lynch}; font-weight: 600;">Reminder:</span> Every topic created will be part of the change process within the acknowledgment that has been sent to you.`
        }
        confirm={onRequestChangesACK}
        trigger={
          <ButtonSecondary {...testId(ACKTestButtonEnum.DISAPPROVE_BTN)}>
            Request Changes
          </ButtonSecondary>
        }
      />

      <Spacer w="15px" />

      <ConfirmationModal
        title="Approve Acknowledgment"
        buttonText="Approve"
        confirmBgColor={isUserCSR ? anzac : undefined}
        htmlMessage={
          isUserCSR
            ? `Are you sure you want to approve this acknowledgment <strong>on behalf of the dealer</strong>?`
            : 'Are you sure you want to approve acknowledgment?'
        }
        confirm={onApproveACK}
        cancel={() => setApproveACKModalOpened(false)}
        opened={approveACKModalOpened}
      />

      <ButtonPrimary
        bgColor={isUserCSR ? anzac : undefined}
        {...testId(ACKTestButtonEnum.APPROVE_BTN)}
        onClick={methodsContext.handleSubmit(
          () => setApproveACKModalOpened(true),
          (e) => console.log(e)
        )}
      >
        Approve Acknowledgment
      </ButtonPrimary>
    </DealerACKActionsContainer>
  );
};

export default DealerACKActions;
