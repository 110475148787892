import { GradingOption } from 'order/enums/orderEnums';
import { changeOrderGrade } from 'order/store/orderActions';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { RootState } from 'store';
import styled, { css } from 'styled-components';
import { Spacer } from './Layout';
import { Wrapper } from './Wrapper';

const GradesContainer = styled(Wrapper)`
  transform: translateX(68px);
  pointer-events: all;
`;

const GradeWrapper = styled.div<{ selected: boolean; disabled: boolean }>`
  border-radius: 50%;
  background-color: ${({ selected, theme }) =>
    selected ? theme.lynch : theme.alto};
  color: ${({ selected, theme }) => (selected ? theme.white : theme.nevada)};
  cursor: pointer;
  width: 48px;
  height: 48px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

interface Grade {
  label: string;
  selected: boolean;
}

export const Grades = () => {
  const dispatch = useAppDispatch();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [grades, setGrades] = useState<Grade[]>(
    Object.keys(GradingOption)
      .filter((k) => Number.isNaN(+k))
      .map((key) => {
        return {
          label: key,
          selected: false,
        };
      })
  );

  const setOrderGrade = () => {
    if (order) {
      const temp = grades.map((grade) => {
        return {
          ...grade,
          selected:
            order.gradingOption ===
              GradingOption[grade.label as keyof typeof GradingOption] ?? false,
        };
      });

      setGrades([...temp]);
    }
  };

  useEffect(() => {
    setOrderGrade();
  }, [order]);

  const onGradeErrorHandler = () => {
    toast.error('Error occured while grading the order.');
    setOrderGrade();
  };

  const onSelectHandler = (grade: Grade) => {
    const list = grades.map((g) => {
      return {
        ...g,
        selected: g.label === grade.label ? !g.selected : false,
      };
    });
    setGrades([...list]);
    dispatch(
      changeOrderGrade(
        {
          orderId: order?.id,
          gradingOption: grade.selected
            ? null
            : GradingOption[grade.label as keyof typeof GradingOption],
        },
        () => toast.success('You have successfully changed order grade.'),
        onGradeErrorHandler
      )
    );
  };

  return (
    <GradesContainer flex column>
      {grades.map((grade) => (
        <Wrapper key={`grade-${grade.label}`}>
          <GradeWrapper
            selected={grade.selected}
            onClick={() => canEdit && onSelectHandler(grade)}
            disabled={!canEdit}
          >
            {grade.label}
          </GradeWrapper>
          <Spacer h="8px" />
        </Wrapper>
      ))}
    </GradesContainer>
  );
};
