import { FC, useEffect, useState } from 'react';

import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDecision } from '@optimizely/react-sdk';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as MoreInfoIcon } from 'assets/icons/vertical-dots.svg';
import { ReactComponent as RemoveShoppingCartIcon } from 'assets/icons/removeShoppingCart.svg';
import { ReactComponent as UnreadCollaborationIcon } from 'assets/icons/unread_collaboration.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/edit-solid.svg';

import { OrderLineItem } from 'order/wizard/orderLineItems/OrderLineItem';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { OrderStylesModal } from 'order/wizard/orderStyles/OrderStylesModal';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { StyleModalEnum } from 'order/wizard/orderStyles/enums/StyleModalEnum';
import { checkAsapItem } from 'order/wizard/orderASAP/store/OrderAsapActions';

import {
  deleteOrderStyle,
  getOrderStyle,
  setOrderStyles,
  styleCancelRequest,
} from 'order/store/orderActions';

import {
  duplicateStyle,
  getReplacementProductLines,
  getStyle,
  setStylesModalOpened,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import { TopicType } from 'curtain/enums/TopicTypeEnum';

import {
  clearCurtain,
  getChannels,
  getTopicsByEntity,
  setActiveChannel,
  setActiveEntityID,
  setActiveEntityName,
  setActiveEntityType,
  setActiveTopic,
  setCreateTopic,
  setCurtainComments,
  setCurtainTab,
  setStyleChannels,
  setTopicItemDefaultValue,
  setTopicTypeDefaultValue,
} from 'curtain/store/curtainActions';

import ElipsisText from 'shared/components/ElipsisText';
import EmptyState from 'shared/components/EmptyState';
import Flash from 'shared/components/Flash';
import FormError from 'shared/components/FormError';
import Img from 'shared/components/Img';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { FloatingCheckbox } from 'shared/components/FloatingCheckbox';
import { H4, H6, P } from 'shared/components/Typography';
import { InfoModal } from 'shared/components/InfoModal';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { MoreInfoButton, MoreInfoContainer } from 'shared/components/MoreInfo';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  kashmirBlue,
  mineShaft,
  silverChaliceTwo,
  taupe,
  valencia,
  anzac,
} from 'shared/config/Colors';

import { OrderStyleTestThreeDotMenuButtonEnum } from 'tests/enums/OrderStyleTestEnums';
import { testId } from 'tests/utils';
import { OrderStyleProps } from './Types';

import ICSOrderStyleDetails from './OrderStyleDetails/ICSOrderStyleDetails';
import Inovae2OOrderStyleDetails from './OrderStyleDetails/Inovae2OOrderStyleDetails';
import OrderStyleDetails from './OrderStyleDetails/OrderStyleDetails';
import { ChannelTypeEnum } from '../../../curtain/enums/ChannelTypeEnum';
import { IChannel } from '../../../curtain/interfaces/IChannel';
import { ImportStylesModalHeaderEnums } from '../../wizard/orderStyles/enums/ImportStylesModalEnum';
import { ImportStylesModalStepsEnums } from '../../wizard/import/enums/ImportStylesModalStepsEnums';
import { OrderPageParams } from '../../interfaces/OrderPageParams';

import {
  selectSingleStyleForImport,
  setImportStylesModalMeta,
} from '../../wizard/import/store/ImportActions';

import OrderFinishLabel from '../OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from '../OrderFinishValue/OrderFinishValue';
import {
  ButtonsContainer,
  ExpandButton,
  OrderStyleBubble,
  OrderStyleContainer,
  RequestCancellationBtnContainer,
  StyleLineItemsContainer,
  TitleContainer,
} from './OrderStyleContainers';

const OrderStyle: FC<OrderStyleProps> = ({
  canCheck,
  expandable,
  highlight,
  isOnOrderOverviewPage,
  loadOrderStyles,
  marginBottom,
  noActions,
  onSelect,
  selected,
  style,
  withLineItems,
  disableOverrideHighlight,
}) => {
  const { orderId } = useParams<OrderPageParams>();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [convertEnabled] = useDecision('style_convert');
  const [importLineItemsEnabled] = useDecision('import_line_items');
  const [globalOverrideEnabled] = useDecision('global_override');

  const [displayStyleDetails, setDisplayStyleDetails] = useState(false);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const [isGlobalOverrideModalOpened, setIsGlobalOverrideModalOpened] =
    useState(false);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      overrideReason: '',
    },
  });

  const overrideReasonWatched = methods.watch('overrideReason');

  const [
    isRequestCancellationModalVisible,
    setIsRequestCancellationModalVisible,
  ] = useState(false);

  const [
    isRequestCancellationInfoModalVisible,
    setIsRequestCancellationInfoModalVisible,
  ] = useState(false);

  const [styleDetails, setStyleDetails] = useState<Style | null>();
  const [isStyleDetailsLoading, setIsStyleDetailsLoading] = useState(false);
  const [gettingStyleLoading, setGettingStyleLoading] = useState(false);
  const [duplicatingStyleLoading, setDuplicatingStyleLoading] = useState(false);

  const [
    gettingReplacementProductLinesLoading,
    setGettingReplacementProductLinesLoading,
  ] = useState(false);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const orderSubmitted =
    useIsOrderInStatus({
      status: OrderStatusEnums['Not Submitted'],
      greaterThen: true,
    }) ?? false;

  const orderBeforeTakenForProcessing =
    useIsOrderInStatus({
      status: OrderStatusEnums['Taken For Processing'],
      greaterThen: false,
    }) ?? false;

  const modalOpenedForStyleId = useSelector(
    (state: RootState) => state.orderStylesReducer.modalOpenedForStyleId
  );

  const checkedStyles = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedStyles
  );

  const styleLineItems = useSelector((state: RootState) => {
    if (withLineItems) {
      return (
        canCheck
          ? state.orderAsapReducer.lineItems
          : state.orderReducer.lineItems
      )?.filter((orderLineItem) => orderLineItem.style.styleId === style.id);
    }

    return null;
  });

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );

  const orderStylesLimitReached = (orderStyles?.length ?? 0) >= 9;

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const topicItemDefaultValue = useSelector(
    (state: RootState) => state.curtainReducer.topicItemDefaultValue
  );

  const styleCanRequestCancellation = useHasPermissions(
    userPermissionsValues.STYLE_CAN_REQUEST_CANCELLATION
  );

  const onFailedCurtainTopicsByEntity = () => {
    dispatch(setActiveEntityID(null));
    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
    dispatch(setCreateTopic(true));

    dispatch(
      setTopicTypeDefaultValue({
        value: TopicType.STYLE,
        label: 'Style',
      })
    );

    dispatch(
      setTopicItemDefaultValue({
        value: style.id ?? '',
        label: style.name,
      })
    );
  };

  const onSuccessGetStyleChannels = (channels: IChannel[]) => {
    dispatch(setStyleChannels(channels));
    dispatch(setActiveChannel(null));

    const styleActiveChannel = channels?.find(
      (styleChannel: IChannel) => styleChannel.entityId === style.id
    );

    if (styleActiveChannel) {
      dispatch(setActiveChannel(styleActiveChannel));
    }

    dispatch(setActiveEntityID(style.id ?? ''));
    dispatch(setActiveEntityType(TopicType.STYLE));
    dispatch(setActiveEntityName(style.name ?? ''));
    dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));

    dispatch(setActiveTopic(null));
    dispatch(setCurtainComments(null));
    dispatch(
      getTopicsByEntity(
        { orderId, entityId: style.id ?? '' },
        undefined,
        onFailedCurtainTopicsByEntity
      )
    );
  };

  const onFailedGetStyleChannels = () => {
    toast.error('Could not retrieve style channels.');
  };

  const openCollaborationTopic = () => {
    if (style) {
      dispatch(
        setTopicItemDefaultValue({
          value: '',
          label: '',
        })
      );

      dispatch(
        getChannels(
          {
            orderId,
            type: ChannelTypeEnum.STYLE,
          },
          onSuccessGetStyleChannels,
          onFailedGetStyleChannels
        )
      );
    }
  };

  const isOrderHighlighted = () => {
    return (
      style.id === activeEntityID ||
      style.id === activeChannel?.entityId ||
      style.id === topicItemDefaultValue?.value
    );
  };

  const redirectUserToStylesPage = () => {
    // if user is not on styles page
    if (!location.pathname.endsWith('/styles')) {
      history.push('styles');
    }
  };

  const onGlobalOverrideClickHandler = () => {
    setIsGlobalOverrideModalOpened(true);
  };

  const closeOverrideStyleModal = (close?: () => void) => {
    setIsGlobalOverrideModalOpened(false);
    methods.reset();
    close?.();
  };

  const getStyleSuccessHandler = () => {
    history.push(`${location.pathname}?styleId=${style.id}`, location.state);
    dispatch(setStylesModalOpened(style.id!));

    // clear curtain on edit style
    dispatch(clearCurtain());
    // close style details
    setDisplayStyleDetails(false);
    setStyleDetails(null);

    if (isGlobalOverrideModalOpened) {
      closeOverrideStyleModal();
    }
  };

  const loadStyleBeforeOpeningModal = () => {
    setGettingStyleLoading(true);

    dispatch(
      getStyle(
        {
          orderId: style.orderId,
          styleId: style.id!,
          ...(overrideReasonWatched.length >= 1 && {
            overrideReason: overrideReasonWatched,
          }),
        },
        getStyleSuccessHandler,
        setGettingStyleLoading
      )
    );
  };

  const onViewOrEditStyleClickHandler = () => {
    loadStyleBeforeOpeningModal();
  };

  const overrideStyleModalConfirmHandler = (
    loading: (isLoading: boolean) => void
  ) => {
    methods.handleSubmit(
      () => loadStyleBeforeOpeningModal(),
      () => loading(false)
    )();
  };

  const getReplacementProductLinesSuccessHandler = () => {
    redirectUserToStylesPage();
    dispatch(setStylesModalOpened(StyleModalEnum.CONVERT_STYLE));
  };

  const onConvertClickHandler = () => {
    setGettingReplacementProductLinesLoading(true);

    dispatch(
      getReplacementProductLines(
        {
          productLineId: style.productLine.id,
          orderId: style.orderId,
          styleId: style.id!,
        },
        getReplacementProductLinesSuccessHandler,
        setGettingReplacementProductLinesLoading
      )
    );
  };

  const onSuccessfulOrderStyleDelete = () => {
    const orderStylesWithoutDeletedStyle = orderStyles!
      .filter((orderStyle) => orderStyle.id !== style?.id)
      .map((orderStyle, index) => ({
        ...orderStyle,
        number: index + 1,
      }));

    UtilService.onPopupClose();

    toast.success(`You have successfully deleted ${style.name} style.`);

    dispatch(setOrderStyles(orderStylesWithoutDeletedStyle));
  };

  const onFailedOrderStyleDelete = () => {
    toast.error('Could not delete style.');
  };

  const deleteStyle = () => {
    dispatch(
      deleteOrderStyle(
        {
          orderID: style.orderId ?? '',
          styleID: style.id!,
        },
        onSuccessfulOrderStyleDelete,
        onFailedOrderStyleDelete
      )
    );
  };

  const onSuccessDuplicateStyle = (close: () => void) => {
    close();

    toast.success(
      `You have successfully duplicated #${style.number} ${style.name} style.`
    );

    if (loadOrderStyles) {
      loadOrderStyles();
    }
  };

  const duplicateStyleHandler = (close: () => void) => {
    setDuplicatingStyleLoading(true);
    dispatch(
      duplicateStyle(
        { orderId: style.orderId, styleId: style.id! },
        () => onSuccessDuplicateStyle(close),
        setDuplicatingStyleLoading
      )
    );
  };

  const onSuccessGetOrderStyle = (orderStyle: Style) => {
    setIsStyleDetailsLoading(false);
    setStyleDetails(orderStyle);
  };

  const onExpandClickHandler = () => {
    if (styleDetails) {
      setStyleDetails(null);
    }

    if (!styleDetails) {
      setIsStyleDetailsLoading(true);
    }

    setDisplayStyleDetails((prevState) => !prevState);
  };

  const onRequestCancellationSuccessHandler = () => {
    setIsRequestCancellationModalVisible(false);
  };

  const onRequestCancellationClickHandler = () => {
    dispatch(
      styleCancelRequest(
        { orderId: style.orderId, styleId: style.id! },
        onRequestCancellationSuccessHandler
      )
    );
  };

  const onStyleCheckHandler = (checked: boolean) => {
    dispatch(
      checkAsapItem({
        checked,
        itemId: style.id!,
        itemType: 'Styles',
      })
    );

    styleLineItems?.forEach((styleLineItem) => {
      dispatch(
        checkAsapItem({
          checked: false,
          itemId: styleLineItem.lineItemId,
          itemType: 'LineItems',
        })
      );

      styleLineItem.modifications.forEach((mod) => {
        dispatch(
          checkAsapItem({
            checked: false,
            itemId: mod.id,
            itemType: 'Modifications',
          })
        );
      });
    });
  };

  const requestCancelation = () => {
    const canRequestStyleCancellation = orderStyles
      ? orderStyles.filter((x) => x.cancellationRequested).length + 1 <
        orderStyles.length
      : true;

    if (canRequestStyleCancellation) {
      setIsRequestCancellationModalVisible(true);
    } else {
      setIsRequestCancellationInfoModalVisible(true);
    }
  };

  const removeRequestCancelation = () => {
    setIsRequestCancellationModalVisible(true);
  };

  const getStyleDetailsBasedOnProductLine = () => {
    if (!styleDetails) return null;

    switch (style.productLine.name) {
      case ProductLineEnums.PRODUCT_LINE_INOVAE2O:
      case ProductLineEnums.PRODUCT_LINE_REVOLAE:
        return (
          <Inovae2OOrderStyleDetails
            styleDetails={styleDetails}
            highlight={highlight}
          />
        );
      case ProductLineEnums.PRODUCT_LINE_ICS:
        return (
          <ICSOrderStyleDetails
            styleDetails={styleDetails}
            highlight={highlight}
          />
        );
      default:
        return (
          <OrderStyleDetails
            styleDetails={styleDetails}
            highlight={highlight}
          />
        );
    }
  };

  const handleImportLineItemsClick = () => {
    redirectUserToStylesPage();
    dispatch(selectSingleStyleForImport(style));

    dispatch(
      setImportStylesModalMeta({
        desc: ImportStylesModalHeaderEnums.IMPORT_LINE_ITEM_DESC,
        orderId: null,
        step: ImportStylesModalStepsEnums.SELECT_IMPORT_LINE_ITEMS_FLOW,
        title: ImportStylesModalHeaderEnums.IMPORT_LINE_ITEM,
      })
    );
  };

  const shouldConvertButtonBeDisabled = () => {
    const isDealerOrSalesRepAndSubmitted =
      isUserDealerOrSalesRep && orderSubmitted;

    return (
      !canEdit ||
      !style.isComplete ||
      !!isDealerOrSalesRepAndSubmitted ||
      !style.canConvert ||
      !!style.override
    );
  };

  const isConvertButtonDisabled = () => {
    const isDealerOrSalesRepAndSubmitted =
      isUserDealerOrSalesRep && orderSubmitted;

    return (
      canEdit &&
      (!style.isComplete ||
        isDealerOrSalesRepAndSubmitted ||
        !style.canConvert ||
        !!style.override)
    );
  };

  const getConvertTooltipText = () => {
    if (canEdit && !style.isComplete)
      return 'Cannot convert not completed Style.';

    if (canEdit && !style.canConvert) return 'This Style cannot be converted.';

    if (canEdit && style.override) return 'Cannot convert an overriden Style.';

    if (canEdit && isUserDealerOrSalesRep && orderSubmitted)
      return 'Style cannot be converted when Order is submitted.';

    return '';
  };

  const shouldImportButtonBeDisabled = () => {
    return !canEdit || !style.isComplete || !!style.override;
  };

  const isImportButtonDisabled = () => {
    return canEdit && (!canEdit || !style.isComplete || !!style.override);
  };

  const getImportTooltipText = () => {
    if (canEdit && !style.isComplete)
      return 'Cannot import Line Item(s) to not completed Style.';

    if (canEdit && style.override)
      return 'Cannot import Line Item(s) to an overriden Style.';

    return '';
  };

  const shouldOverrideButtonBeDisabled = () => {
    return (
      !canEdit ||
      !style.isComplete ||
      !!style.override ||
      orderBeforeTakenForProcessing
    );
  };

  const isOverrideButtonDisabled = () => {
    return (
      canEdit &&
      (!style.isComplete || !!style.override || orderBeforeTakenForProcessing)
    );
  };

  const getOverrideTooltipText = () => {
    if (canEdit && style.override)
      return 'Cannot override already overriden Style.';

    if (canEdit && orderBeforeTakenForProcessing && style.isComplete)
      return 'Style cannot be overriden before the Order is in Taking for Processing status.';

    if (canEdit && !style.isComplete)
      return 'Cannot override not completed Style.';

    return '';
  };

  const shouldDuplicateButtonBeDisabled = () => {
    return (
      !canEdit ||
      !!style.override ||
      orderStylesLimitReached ||
      duplicatingStyleLoading
    );
  };

  const isDuplicateButtonDisabled = () => {
    return (
      canEdit &&
      (!!style.override || orderStylesLimitReached || duplicatingStyleLoading)
    );
  };

  const getDuplicateTooltipText = () => {
    if (canEdit && orderStylesLimitReached && !duplicatingStyleLoading)
      return 'Style limit reached.';

    if (canEdit && style.override)
      return 'Cannot duplicate an overriden Style.';

    return '';
  };

  useEffect(() => {
    if (displayStyleDetails && style) {
      dispatch(
        getOrderStyle(
          {
            orderId: style.orderId,
            styleId: style.id!,
          },
          setIsStyleDetailsLoading,
          onSuccessGetOrderStyle
        )
      );
    }
  }, [displayStyleDetails]);

  const isInovae20OrRevolae =
    style.productLine.name === ProductLineEnums.PRODUCT_LINE_INOVAE2O ||
    style.productLine.name === ProductLineEnums.PRODUCT_LINE_REVOLAE;

  return (
    <>
      <OrderStyleContainer
        className={
          isOrderHighlighted() ? 'order-style highlight' : 'order-style'
        }
        marginBottom={marginBottom}
        selected={selected}
        onClick={() => onSelect && onSelect(style)}
        canSelect={!!onSelect}
        overriden={!!style.override && !disableOverrideHighlight}
      >
        {canCheck && (
          <FloatingCheckbox>
            <Wrapper withTooltip={!!style.override} cursorDefault>
              <Checkbox
                id={style.id}
                checked={checkedStyles.some(
                  (checkedStyle) => checkedStyle === style.id
                )}
                disabled={!!style.override}
                onChange={(e) => onStyleCheckHandler(e.target.checked)}
              />

              {style.override && (
                <Tooltip position="right">
                  Overriden Style cannot be selected when doing order
                  duplication or creation of ASAP order.
                </Tooltip>
              )}
            </Wrapper>
          </FloatingCheckbox>
        )}

        <OrderStyleBubble flex middle>
          {expandable && (
            <>
              <ExpandButton
                onClick={onExpandClickHandler}
                iconColor={silverChaliceTwo}
                hoverIconColor={kashmirBlue}
                className={
                  displayStyleDetails
                    ? 'expandable-box-button open'
                    : 'expandable-box-button'
                }
              >
                {isStyleDetailsLoading ? (
                  <Loader size={16} noSpacing />
                ) : (
                  <ArrowUpIcon />
                )}
              </ExpandButton>

              <Spacer w="48px" className="style-expandable-spacer" />
            </>
          )}

          <Img
            src={style.productLine.imageUrl}
            width={117}
            height={38}
            contain
          />

          <Spacer w="48px" />

          <TitleContainer>
            <H4>
              #{style.number}
              <Spacer w="5px" />
              <ElipsisText maxWidth={180}>{style.name}</ElipsisText>
            </H4>
          </TitleContainer>

          <Wrapper flex middle>
            <Wrapper flex column minWidth={85}>
              <H6 fontSize={13}>
                {isInovae20OrRevolae ? 'Material Group' : 'Wood'}
              </H6>

              <Spacer h="2px" />

              <P minWidth={80} color={mineShaft} fontSize={12}>
                {(isInovae20OrRevolae
                  ? style.materialGroup?.name
                  : style.woodMaterial?.name ?? style.materialGroup?.name) ||
                  'N/A'}
              </P>
            </Wrapper>

            <Spacer w="20px" />

            <Wrapper flex column minWidth={103}>
              <OrderFinishLabel
                style={style}
                render={(label) => <H6 fontSize={13}>{label}</H6>}
              />

              <Spacer h="2px" />

              <Wrapper maxWidth={100}>
                <OrderFinishValue
                  style={style}
                  render={(value) => (
                    <P minWidth={80} color={mineShaft} fontSize={12}>
                      {value}
                    </P>
                  )}
                />
              </Wrapper>
            </Wrapper>

            <Spacer w="20px" />

            <Wrapper flex column>
              <H6 fontSize={13}>Door Style</H6>
              <Spacer h="2px" />
              <P minWidth={80} color={mineShaft} fontSize={12}>
                {style.doorStyle?.name || 'N/A'}
              </P>
            </Wrapper>

            {style.cancellationRequested ? (
              <>
                <Spacer w="20px" />
                <Flash type="Style" />
                <Spacer w="35px" />
              </>
            ) : (
              <Spacer w="70px" />
            )}

            <Wrapper flex column onClick={openCollaborationTopic}>
              <PriorityBadge priority={style.priority} hoverColor={taupe} />
            </Wrapper>
          </Wrapper>

          {!noActions && (
            <ButtonsContainer mlAuto flex middle className="order-style-actons">
              <ButtonIcon
                iconColor={silverChaliceTwo}
                hoverIconColor={kashmirBlue}
                onClick={(e) => {
                  e.stopPropagation();
                  onViewOrEditStyleClickHandler();
                }}
                disabled={!canEdit}
              >
                <Wrapper flex middle>
                  <PencilIcon width={18} height={28} />
                </Wrapper>
              </ButtonIcon>
              <Spacer w="16px" />

              <ButtonIcon
                onClick={openCollaborationTopic}
                iconColor={silverChaliceTwo}
                hoverIconColor={kashmirBlue}
              >
                <Wrapper flex middle>
                  {style.unreadTopicCount > 0 ? (
                    <UnreadCollaborationIcon />
                  ) : (
                    <CommentIcon />
                  )}

                  <Spacer w="5px" />
                  <P color={mineShaft} fontSize={12} fontWeight={700}>
                    {style.topicCount}
                  </P>
                </Wrapper>
              </ButtonIcon>

              <Spacer w="14px" />

              <MenuWithDropdown
                trigger={
                  <ButtonIcon
                    iconColor={silverChaliceTwo}
                    hoverIconColor={kashmirBlue}
                    {...testId(
                      OrderStyleTestThreeDotMenuButtonEnum.THREE_DOT_MENU_BTN
                    )}
                  >
                    <MoreInfoIcon />
                  </ButtonIcon>
                }
              >
                {(close: () => void) => (
                  <MoreInfoContainer flex column alignStart>
                    <MoreInfoButton
                      onClick={onViewOrEditStyleClickHandler}
                      disabled={gettingStyleLoading}
                      {...testId(
                        OrderStyleTestThreeDotMenuButtonEnum.EDIT_OR_VIEW_BTN
                      )}
                    >
                      <Wrapper flex middle>
                        {!canEdit ? 'View' : 'Edit'}

                        <Loader
                          size={16}
                          noSpacing
                          insideButton
                          hidden={!gettingStyleLoading}
                        />
                      </Wrapper>
                    </MoreInfoButton>

                    {globalOverrideEnabled.enabled && isUserCSR && (
                      <Wrapper withTooltip={isOverrideButtonDisabled()}>
                        <MoreInfoButton
                          disabled={shouldOverrideButtonBeDisabled()}
                          {...testId(
                            OrderStyleTestThreeDotMenuButtonEnum.GLOBAL_OVERRIDE_BTN
                          )}
                          onClick={onGlobalOverrideClickHandler}
                        >
                          <Wrapper flex middle>
                            Override
                          </Wrapper>
                        </MoreInfoButton>

                        {isOverrideButtonDisabled() && (
                          <Tooltip position="left">
                            {getOverrideTooltipText()}
                          </Tooltip>
                        )}
                      </Wrapper>
                    )}

                    {convertEnabled.enabled && (
                      <Wrapper withTooltip={isConvertButtonDisabled()}>
                        <MoreInfoButton
                          disabled={
                            gettingReplacementProductLinesLoading ||
                            shouldConvertButtonBeDisabled()
                          }
                          {...testId(
                            OrderStyleTestThreeDotMenuButtonEnum.CONVERT_BTN
                          )}
                          onClick={onConvertClickHandler}
                        >
                          <Wrapper flex middle>
                            Convert
                            <Loader
                              size={16}
                              noSpacing
                              insideButton
                              hidden={!gettingReplacementProductLinesLoading}
                            />
                          </Wrapper>
                        </MoreInfoButton>

                        {isConvertButtonDisabled() && (
                          <Tooltip position="left">
                            {getConvertTooltipText()}
                          </Tooltip>
                        )}
                      </Wrapper>
                    )}

                    {importLineItemsEnabled.enabled && (
                      <Wrapper withTooltip={isImportButtonDisabled()}>
                        <MoreInfoButton
                          onClick={handleImportLineItemsClick}
                          disabled={shouldImportButtonBeDisabled()}
                        >
                          Import Line Item(s)
                        </MoreInfoButton>

                        {isImportButtonDisabled() && (
                          <Tooltip position="left">
                            {getImportTooltipText()}
                          </Tooltip>
                        )}
                      </Wrapper>
                    )}

                    <>
                      <Wrapper withTooltip={isDuplicateButtonDisabled()}>
                        <MoreInfoButton
                          onClick={() => duplicateStyleHandler(close)}
                          disabled={shouldDuplicateButtonBeDisabled()}
                        >
                          <Wrapper flex middle>
                            Duplicate
                            <Loader
                              size={16}
                              noSpacing
                              insideButton
                              hidden={!duplicatingStyleLoading}
                            />
                          </Wrapper>
                        </MoreInfoButton>

                        {isDuplicateButtonDisabled() &&
                          getDuplicateTooltipText() && (
                            <Tooltip position="left">
                              {getDuplicateTooltipText()}
                            </Tooltip>
                          )}
                      </Wrapper>
                      {!orderSubmitted && (
                        <MoreInfoButton
                          color={valencia}
                          onClick={() => setIsDeleteModalOpened(true)}
                          disabled={!canEdit}
                          {...testId(
                            OrderStyleTestThreeDotMenuButtonEnum.DELETE_BTN
                          )}
                        >
                          Delete
                        </MoreInfoButton>
                      )}
                    </>

                    {orderSubmitted && styleCanRequestCancellation && (
                      <RequestCancellationBtnContainer position="relative">
                        <MoreInfoButton
                          onClick={
                            !style.cancellationRequested
                              ? requestCancelation
                              : removeRequestCancelation
                          }
                          disabled={!style.isComplete}
                          {...testId(
                            OrderStyleTestThreeDotMenuButtonEnum.REQUEST_CANCELLATION_BTN
                          )}
                        >
                          {!style.cancellationRequested
                            ? 'Request Cancellation'
                            : 'Remove Cancellation Request'}
                        </MoreInfoButton>

                        {!style.isComplete && (
                          <Tooltip position="left">
                            Incomplete style can&apos;t be requested for
                            cancellation.
                          </Tooltip>
                        )}
                      </RequestCancellationBtnContainer>
                    )}
                  </MoreInfoContainer>
                )}
              </MenuWithDropdown>
            </ButtonsContainer>
          )}
        </OrderStyleBubble>

        {displayStyleDetails && styleDetails
          ? getStyleDetailsBasedOnProductLine()
          : null}
      </OrderStyleContainer>

      {style.override && !disableOverrideHighlight ? (
        <Wrapper marginBottom={8} marginTop={8}>
          <P fontSize={12} color={anzac} textAlign="right">
            This style is overridden
          </P>
        </Wrapper>
      ) : null}

      {styleLineItems && (styleLineItems ?? []).length > 0 && (
        <StyleLineItemsContainer>
          {styleLineItems.map((styleLineItem) => (
            <OrderLineItem
              key={styleLineItem.lineItemId}
              lineItem={styleLineItem}
              canCheck={canCheck}
              expanded={!isOnOrderOverviewPage ? withLineItems : undefined}
              styleOverride={style.override}
            />
          ))}

          {withLineItems && !styleLineItems?.length && (
            <EmptyState
              title="No line items added yet!"
              titleSize={15}
              icon={<RemoveShoppingCartIcon />}
              iconSize={40}
              width={40}
              height={40}
              marginTop={30}
            />
          )}
        </StyleLineItemsContainer>
      )}

      <ModalWrapper
        open={modalOpenedForStyleId === style.id}
        className="modal--full-screen"
        closeOnDocumentClick={false}
        lockScroll
        modal
        closeOnEscape={false}
      >
        <OrderStylesModal />
      </ModalWrapper>

      <ConfirmationModal
        cancel={() => closeOverrideStyleModal()}
        confirm={(_, loading) => overrideStyleModalConfirmHandler(loading)}
        message="Please add a brief reason for this override."
        opened={isGlobalOverrideModalOpened}
        buttonText="Submit"
        bottomSpacing={40}
        title="Add a Reason"
      >
        <Spacer h="30px" />

        <Textarea
          placeholder="Leave a comment"
          aria-invalid={
            methods.formState.errors.overrideReason ? 'true' : 'false'
          }
          {...methods.register('overrideReason', {
            required: true,
            minLength: 1,
            maxLength: 80,
          })}
        />

        <FormError
          label="Override Reason"
          validationSchema={{
            required: true,
            minLength: 1,
            maxLength: 80,
          }}
          error={methods.formState.errors.overrideReason}
        />
      </ConfirmationModal>

      <ConfirmationModal
        cancel={() => setIsDeleteModalOpened(false)}
        confirm={deleteStyle}
        message="This Style may contain Line Items and all of them will be removed."
        opened={isDeleteModalOpened}
        title="Confirmation"
      />

      <ConfirmationModal
        buttonText={
          !style.cancellationRequested
            ? 'Request Cancellation'
            : 'Remove Cancellation Request'
        }
        cancel={() => setIsRequestCancellationModalVisible(false)}
        confirm={onRequestCancellationClickHandler}
        message={
          !style.cancellationRequested
            ? 'A cancellation request will require Plato Woodwork review. Would you like to proceed with the request?'
            : 'Would you like to proceed with the request?'
        }
        opened={isRequestCancellationModalVisible}
        title="Are you sure?"
      />

      <InfoModal
        close={() => setIsRequestCancellationInfoModalVisible(false)}
        message="Only full order cancelation is possible at this point."
        opened={isRequestCancellationInfoModalVisible}
        title="Warning"
      />
    </>
  );
};

OrderStyle.defaultProps = {
  marginBottom: 0,
  expandable: true,
  canCheck: false,
  noActions: false,
  isOnOrderOverviewPage: false,
  disableOverrideHighlight: false,
};

export default OrderStyle;
