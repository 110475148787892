import { FC } from 'react';
import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';
import HTMLReactParser from 'html-react-parser';
import { testId } from 'tests/utils';

const StaticFieldContainer = styled(Wrapper)`
  margin-bottom: 20px;
`;

const StaticFieldLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.08em;
  margin-right: 20px;
  margin-right: auto;
  margin-top: 1px;
  width: 190px;
  text-transform: uppercase;
`;

const StaticFieldValue = styled.span`
  color: ${({ theme }) => theme.nevada};
  font-size: 12px;
  text-align: right;
  width: 266px;
`;

interface StaticFieldProps {
  field: string | null;
  value?: string;
  fieldTestId?: string;
}

const StaticField: FC<StaticFieldProps> = ({ field, value, fieldTestId }) => {
  return (
    <StaticFieldContainer flex>
      <StaticFieldLabel {...(fieldTestId && testId(fieldTestId))}>
        {field}
      </StaticFieldLabel>
      <StaticFieldValue>
        {typeof value === 'string' && value !== ''
          ? HTMLReactParser(value)
          : ''}
      </StaticFieldValue>
    </StaticFieldContainer>
  );
};

export default StaticField;
