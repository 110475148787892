import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AdjustmentTypeEnum } from 'order/wizard/orderStyles/enums/AdjustmentTypeEnum';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylesAdjustmentsRequest } from 'order/wizard/orderStyles/interface/Adjustments';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import {
  getPriceShedules,
  saveAdjustment,
} from 'order/wizard/orderStyles/productLines/store/adjustments/orderStylesAdjustmentsActions';

import {
  changeStylesStep,
  clearStyles,
  setIsStylesStepDirty,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import {
  onlyDigitValidation,
  onlyWholeDigitValidation,
  upchargeValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';

import {
  AdjustmentsTestInputEnum,
  AdjustmentsTestCheckboxEnum,
  AdjustmentsTestButtonEnum,
} from 'tests/enums/AdjustmentsTestEnums';

export interface OrderStyleAdjustmentsFields {
  priceSchedule: string;
  replacement: boolean;
  noCharge: boolean;
  noFreight: boolean;
  displayPercentage: string;
  listDiscountPercentage: string;
  netDiscountPercentage: string;
  designFeePercentage: string;
  numberOfCabinets: string;
  promotionCode: string;
  applyToExistingStyles: boolean;
}

const SectionHeading = styled(H2)`
  &:first-child {
    margin-bottom: 30px;
  }

  &:not(:first-child) {
    margin: 30px 0;
  }
`;

const WhiteBoxSection = styled(WhiteBox)`
  margin-bottom: 50px;
  padding: 48px 96px;
`;

const FormColumns = styled(Wrapper)``;

const LeftColumn = styled.div`
  max-width: 256px;
  flex: 1;
`;

const RightColumn = styled.div`
  max-width: 156px;
  margin-left: 20px;
  flex: 1;
`;

const OrderStylesAdjustments = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { orderId } = useParams<OrderPageParams>();

  const [saveAdjustmentsLoading, setSaveAdjustmentsLoading] = useState(false);
  const [saveAdjustmentsLoadingOnBack, setSaveAdjustmentsLoadingOnBack] =
    useState(false);

  const [priceSchedules, setPriceSchedules] = useState<SelectOptionProps[]>([]);

  const [gettingPriceSchedules, setGettingPriceSchedules] = useState(true);

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const styleName = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.name
  );

  const { canEdit, order } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const withoutStyling =
    order?.stylizationType === OrderStylizationTypeEnums.SALES_MATERIAL;

  const storedAdjustment = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.style?.adjustment ?? order?.adjustment
  );

  const methods = useForm<OrderStyleAdjustmentsFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const applyToExistingStylesWatched = methods.watch('applyToExistingStyles');

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const toNumberOrNull = (value: string | null) => {
    return value ? +value : null;
  };

  const orderStylesAdjustmentsRequestBodyMapper = (
    data: OrderStyleAdjustmentsFields,
    goBack: boolean
  ) => {
    return {
      orderId,
      styleId: styleId!,
      operationType: 'update',
      orderWithoutStyle: withoutStyling,
      priceSchedule: data.priceSchedule!,
      replacement: data.replacement ?? false,
      noCharge: data.noCharge ?? false,
      noFreight: data.noFreight ?? false,
      displayPercentage: toNumberOrNull(data.displayPercentage),
      listDiscountPercentage: toNumberOrNull(data.listDiscountPercentage),
      netDiscountPercentage: toNumberOrNull(data.netDiscountPercentage),
      designFeePercentage: toNumberOrNull(data.designFeePercentage),
      numberOfCabinets: toNumberOrNull(data.numberOfCabinets),
      promotionCode: data.promotionCode,
      applyToExistingStyles: data.applyToExistingStyles,
      goBack,
    };
  };

  const canSubmit = () => {
    return methods.formState.isDirty;
  };

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.STYLE_OVERVIEW));
    } else {
      dispatch(clearStyles());
      history.replace(`${location.pathname}`, location.state);
    }
  };

  const onAdjustmentsSaveSuccessHandler = (goBack: boolean = false) => {
    toast.success(
      withoutStyling
        ? `You have successfully ${
            storedAdjustment ? 'updated' : 'added'
          } adjustment fields for ${order?.name} order.`
        : `You have successfully ${
            storedAdjustment ? 'updated' : 'added'
          } adjustment fields for ${
            applyToExistingStylesWatched ? 'all styles' : `${styleName} style`
          } on this order.`
    );

    if (!withoutStyling && !goBack) {
      dispatch(clearStyles());
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.STYLE_OVERVIEW));
    }
  };

  const onAdjustmentsSaveErrorHandler = () => {
    setSaveAdjustmentsLoading(false);
    setSaveAdjustmentsLoadingOnBack(false);
    toast.error('Something went wrong.');
  };

  const onSubmit = (
    data: OrderStyleAdjustmentsFields,
    goBack: boolean = false
  ) => {
    const orderStylesAdjustmentsRequestBody: OrderStylesAdjustmentsRequest =
      orderStylesAdjustmentsRequestBodyMapper(data, goBack);

    if (goBack) {
      setSaveAdjustmentsLoadingOnBack(true);
    } else {
      setSaveAdjustmentsLoading(true);
    }

    dispatch(
      saveAdjustment(
        orderStylesAdjustmentsRequestBody,
        () => onAdjustmentsSaveSuccessHandler(goBack),
        onAdjustmentsSaveErrorHandler,
        goBack ? setSaveAdjustmentsLoadingOnBack : setSaveAdjustmentsLoading
      )
    );
  };

  const displayNetDiscountPercentageColumn = () => {
    return (
      <FormElement>
        <FormLabel>Net Discount %</FormLabel>
        <Input
          type="text"
          data-test="input-netDiscountPercentage"
          {...methods.register(
            'netDiscountPercentage',
            upchargeValidation({}, 'Net Discount', true)
          )}
          aria-invalid={
            methods.formState.errors.netDiscountPercentage ? 'true' : 'false'
          }
          readOnly={!canEdit}
          {...testId(AdjustmentsTestInputEnum.ADJUSTMENTS_NET_DISCOUNT)}
        />

        <FormError
          label="Net Discount"
          error={methods.formState.errors.netDiscountPercentage as FieldError}
          validationSchema={upchargeValidation({}, 'Net Discount', true)}
        />
      </FormElement>
    );
  };

  const getSubmitBtnLabel = () => {
    if (withoutStyling) {
      return 'Save Changes';
    }

    return UtilService.styleNavigationActionsLables(
      'Finish',
      canEdit && methods.formState.isDirty
    );
  };

  const displaySubmitButton = () => {
    return (
      <ButtonPrimary
        onClick={
          canSubmit()
            ? methods.handleSubmit((data) => onSubmit(data, false))
            : () => redirect(false)
        }
        data-test="button-StyleSubmit"
        type="button"
        disabled={!canEdit || saveAdjustmentsLoading}
        {...testId(AdjustmentsTestButtonEnum.SUBMIT)}
      >
        {getSubmitBtnLabel()}
        <Loader
          hidden={!saveAdjustmentsLoading}
          insideButton
          noSpacing
          size={16}
        />
      </ButtonPrimary>
    );
  };

  useEffect(() => {
    dispatch(getPriceShedules(setPriceSchedules, setGettingPriceSchedules));
  }, []);

  useEffect(() => {
    if (storedAdjustment) {
      methods.reset({
        noCharge: storedAdjustment.noCharge ?? false,
        noFreight: storedAdjustment.noFreight ?? false,
        netDiscountPercentage:
          storedAdjustment.netDiscountPercentage?.toString() ?? '',
        promotionCode: storedAdjustment.promotionCode ?? '',
        ...(!withoutStyling && {
          designFeePercentage:
            storedAdjustment.designFeePercentage?.toString() ?? '',
          displayPercentage:
            storedAdjustment.displayPercentage?.toString() ?? '',
          listDiscountPercentage:
            storedAdjustment.listDiscountPercentage?.toString() ?? '',
          numberOfCabinets: storedAdjustment.numberOfCabinets?.toString() ?? '',
          priceSchedule: storedAdjustment.priceSchedule,
          replacement: storedAdjustment.replacement ?? false,
        }),
      });
    }
  }, [storedAdjustment]);

  return order ? (
    <FormProvider {...methods}>
      <Spacer h="20px" />
      <Form maxWidth={744} {...testId('form')}>
        <WhiteBoxSection>
          <SectionHeading>Adjustments</SectionHeading>

          {!withoutStyling && (
            <FormElement>
              <FormLabel>Price Schedule</FormLabel>
              <Controller
                control={methods.control}
                name="priceSchedule"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    options={priceSchedules.map((priceSchedule) => ({
                      ...priceSchedule,
                      isDisabled: priceSchedule.label !== value,
                    }))}
                    value={priceSchedules.find(
                      (option: SelectOptionProps) => option.label === value
                    )}
                    onChange={(val: SelectOptionProps) => onChange(val.label)}
                    isDisabled={!canEdit || gettingPriceSchedules}
                  />
                )}
              />
            </FormElement>
          )}

          <FormElement>
            <Wrapper flex middle>
              {!withoutStyling && (
                <>
                  <Checkbox
                    title="Replacement"
                    id={`${AdjustmentTypeEnum.REPLACEMENT}`}
                    {...methods.register('replacement')}
                    readOnly={!canEdit}
                    {...testId(
                      AdjustmentsTestCheckboxEnum.ADJUSTMENTS_REPLACEMENT_CHECKBOX
                    )}
                  />
                  <Spacer w="20px" />
                </>
              )}
              <Checkbox
                title="No Charge $"
                id={`${AdjustmentTypeEnum.NO_CHARGE}`}
                {...methods.register('noCharge')}
                readOnly={!canEdit}
                {...testId(
                  AdjustmentsTestCheckboxEnum.ADJUSTMENTS_NO_CHARGE_CHECKBOX
                )}
              />
              <Spacer w="20px" />
              <Checkbox
                title="No Freight $"
                id={`${AdjustmentTypeEnum.NO_FREIGHT}`}
                {...methods.register('noFreight')}
                readOnly={!canEdit}
                {...testId(
                  AdjustmentsTestCheckboxEnum.ADJUSTMENTS_NO_FREIGHT_CHECKBOX
                )}
              />
            </Wrapper>
            <Spacer h="30px" />
          </FormElement>

          <FormColumns flex>
            <LeftColumn>
              {!withoutStyling && (
                <FormElement>
                  <FormLabel>Display/Personal %</FormLabel>
                  <Input
                    type="text"
                    data-test="input-displayPercentage"
                    {...methods.register(
                      'displayPercentage',
                      upchargeValidation({}, 'Display/Personal', true)
                    )}
                    aria-invalid={
                      methods.formState.errors.displayPercentage
                        ? 'true'
                        : 'false'
                    }
                    readOnly={!canEdit}
                  />
                  <FormError
                    label="Display/Personal"
                    error={
                      methods.formState.errors.displayPercentage as FieldError
                    }
                    validationSchema={upchargeValidation(
                      {},
                      'Display/Personal',
                      true
                    )}
                  />
                </FormElement>
              )}

              {!withoutStyling && (
                <FormElement>
                  <FormLabel>List Discount %</FormLabel>
                  <Input
                    type="text"
                    data-test="input-listDiscountPercentage"
                    {...methods.register(
                      'listDiscountPercentage',
                      upchargeValidation({}, 'List Discount', true)
                    )}
                    aria-invalid={
                      methods.formState.errors.listDiscountPercentage
                        ? 'true'
                        : 'false'
                    }
                    readOnly={!canEdit}
                  />
                  <FormError
                    label="List Discount"
                    error={
                      methods.formState.errors
                        .listDiscountPercentage as FieldError
                    }
                    validationSchema={upchargeValidation(
                      {},
                      'List Discount',
                      true
                    )}
                  />
                </FormElement>
              )}

              {!withoutStyling && (
                <FormElement>
                  <FormLabel>Design Fee %</FormLabel>
                  <Input
                    type="text"
                    data-test="input-designFeePercentage"
                    {...methods.register(
                      'designFeePercentage',
                      upchargeValidation({}, 'Design Fee', true)
                    )}
                    aria-invalid={
                      methods.formState.errors.designFeePercentage
                        ? 'true'
                        : 'false'
                    }
                    readOnly={!canEdit}
                  />
                  <FormError
                    label="Design Fee"
                    error={
                      methods.formState.errors.designFeePercentage as FieldError
                    }
                    validationSchema={upchargeValidation(
                      {},
                      'Design Fee',
                      true
                    )}
                  />
                </FormElement>
              )}

              {!withoutStyling && (
                <FormElement>
                  <FormLabel>Number of Cabinets</FormLabel>
                  <Input
                    type="text"
                    data-test="input-numberOfCabinets"
                    {...methods.register(
                      'numberOfCabinets',
                      onlyWholeDigitValidation({}, 'Number of Cabinets')
                    )}
                    aria-invalid={
                      methods.formState.errors.numberOfCabinets
                        ? 'true'
                        : 'false'
                    }
                    readOnly={!canEdit}
                  />
                  <FormError
                    label="Number of Cabinets"
                    error={
                      methods.formState.errors.numberOfCabinets as FieldError
                    }
                    validationSchema={onlyDigitValidation(
                      {},
                      'Number of Cabinets'
                    )}
                  />
                </FormElement>
              )}

              {withoutStyling && displayNetDiscountPercentageColumn()}

              <FormElement>
                <FormLabel>Promo Code</FormLabel>
                <Input
                  type="text"
                  data-test="input-promotionCode"
                  {...methods.register('promotionCode', { maxLength: 10 })}
                  readOnly={!canEdit}
                  aria-invalid={
                    methods.formState.errors.promotionCode ? 'true' : 'false'
                  }
                  {...testId(AdjustmentsTestInputEnum.ADJUSTMENTS_PROMO_CODE)}
                />
                <FormError
                  label="Promotion Code"
                  error={methods.formState.errors.promotionCode as FieldError}
                  validationSchema={{ maxLength: 10 }}
                />
              </FormElement>
            </LeftColumn>

            {!withoutStyling && (
              <RightColumn>
                <Spacer h="86px" />
                {displayNetDiscountPercentageColumn()}
              </RightColumn>
            )}
          </FormColumns>

          {!withoutStyling && (
            <FormElement>
              <Wrapper flex middle>
                <Checkbox
                  title="Apply to all existing styles"
                  id="applyToExistingStyles"
                  {...methods.register('applyToExistingStyles')}
                  readOnly={!canEdit}
                  {...testId(
                    AdjustmentsTestCheckboxEnum.ADJUSTMENTS_APPLY_TO_ALL_EXISTING_STYLES_CHECKBOX
                  )}
                />
              </Wrapper>
            </FormElement>
          )}
        </WhiteBoxSection>

        <OrderFooter>
          {!withoutStyling ? (
            <Wrapper flex middle between>
              {!withoutStyling && (
                <ButtonSecondary
                  onClick={
                    canSubmit()
                      ? methods.handleSubmit((data) => onSubmit(data, true))
                      : () => redirect(true)
                  }
                  type="button"
                  {...testId(AdjustmentsTestButtonEnum.BACK)}
                  disabled={saveAdjustmentsLoadingOnBack}
                >
                  {UtilService.styleNavigationActionsLables(
                    'Back',
                    canEdit && methods.formState.isDirty
                  )}
                  <Loader
                    hidden={!saveAdjustmentsLoadingOnBack}
                    insideButton
                    noSpacing
                    size={16}
                  />
                </ButtonSecondary>
              )}
              {displaySubmitButton()}
            </Wrapper>
          ) : (
            <Wrapper flex justifyEnd>
              {displaySubmitButton()}
            </Wrapper>
          )}
        </OrderFooter>
      </Form>
    </FormProvider>
  ) : (
    <Loader size={50} />
  );
};

export default OrderStylesAdjustments;
