import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDecision } from '@optimizely/react-sdk';
import { useEffect, useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import OrderLogs from 'order/components/OrderLogs/OrderLogs';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderStatusEnums, OrderTypeEnums } from 'order/enums/orderEnums';
import { useUpchargeAvailableOrders } from 'shared/hooks/useUpchargeAvailableOrders';

import {
  exportAsPDF,
  getOrderLineItemCLValues,
  getOrderLineItems,
  getOrderPriceReview,
  getOrderStatus,
  getOrderStyles,
  getUnresolvedTopics,
  setOrderPriceReview,
  setOrderStyles,
  setUpchargeDifferencesModalOpened,
  submitOrder,
} from 'order/store/orderActions';
import { RadioButton } from 'shared/components/RadioButton';

import { CurtainTabsEnum } from 'curtain/enums/CurtainTabsEnum';
import { setCurtainTab } from 'curtain/store/curtainActions';

import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { ContainerMedium, ContainerSmall } from 'shared/components/Container';
import { Divider, Spacer, WhiteBox } from 'shared/components/Layout';
import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { ExpandableBox } from 'shared/components/ExpandableBox';
import { H2, H6, P, PMedium, PSmall } from 'shared/components/Typography';
import { IFileData } from 'shared/interface/IFile';
import { Info } from 'shared/components/Info';
import { Input } from 'shared/components/Input';
import { nevada } from 'shared/config/Colors';
import { onlyDigitValidation } from 'shared/validations/validations';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCalculateOrderTotals } from 'shared/hooks/useCalculateOrderTotals';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { usePriorityChange } from 'shared/hooks/usePriorityChange';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';
import SwitchButton from 'shared/components/SwitchButton';
import UtilService from 'shared/services/util.service';

import { OrderReviewPricingTestButtonEnum } from 'tests/enums/OrderReviewPricingTestEnums';
import { testId } from 'tests/utils';

import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import useAdditionalAttachmentModal from 'order/additonalFields/components/useAdditionalAttachmentModal';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { getAdditionalFields } from 'order/additonalFields/store/additionalFieldsActions';
import OrderReviewPricingStyle from './OrderReviewPricingStyle';
import SubmitErrorModal from './components/SubmitErrorModal';
import TotalPriceRow from './components/TotalPriceRow';
import { AcknowledgmentPrint } from '../orderAcknowledgement/AcknowledgmentPrint';
import { getOrderAttachments } from '../orderAttachments/store/orderAttachmentsActions';

const PercentInputWrapper = styled.div`
  max-width: 112px;
  position: relative;

  &::after {
    align-items: center;
    bottom: 0;
    color: ${({ theme }) => theme.silverSandTwo};
    content: '%';
    display: flex;
    font-size: 14px;
    left: calc(100% + 7px);
    margin: auto;
    position: absolute;
    top: 0;
  }
`;

interface OrderReviewPricingProps {
  isReviewPricing?: boolean;
}

const OrderReviewPricing: FC<OrderReviewPricingProps> = ({
  isReviewPricing,
}) => {
  const dispatch = useAppDispatch();

  const [upcharge_differences] = useDecision('upcharge_differences');

  const { orderId } = useParams<{ orderId: string }>();

  const calculateTotals = useCalculateOrderTotals();

  const isOrderNotSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
  });

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const [isNetPriceSelected, setIsNetPriceSelected] = useState(true);
  const [isNoFreightSelected, setNoIsFreightSelected] = useState(true);

  const [stylesLoading, setStylesLoading] = useState(false);
  const [priceReviewLoading, setPriceReviewLoading] = useState(false);
  const [exportToPDFLoading, setExportToPDFLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [noPricingSelected, setNoPricingSelected] = useState(false);
  const [openAdditional, setOpenAdditonal] = useState(false);

  const [openSubmittedOrderConfirmation, setOpenSubmittedOrderConfirmation] =
    useState(false);

  const [openPDFExportModal, setOpenPDFExportModal] = useState<boolean>(false);
  const [openUnresolvedTopics, setOpenUnresolvedTopics] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      grossMargin: '',
      salesTax: '',
      freight: '',
    },
  });

  const grossMargin = methods.watch('grossMargin');
  const salesTax = methods.watch('salesTax');
  const freight = methods.watch('freight');

  const [submitOrderLoading, setSubmitOrderLoading] = useState<boolean>(false);

  const [submitErrorCodeModal, setSubmitErrorCodeModal] =
    useState<ErrorCodesEnum | null>(null);

  const {
    order,
    styles,
    priceReview,
    canEdit,
    numberOfUnresolvedTopics,
    upchargesOverriden,
    upchargeDifferences,
  } = useSelector((state: RootState) => state.orderReducer);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const { updateOrderPriority } = usePriorityChange();

  const [checkIsOrderInLocalStorage] = useUpchargeAvailableOrders();

  const loadOrderStyles = () => {
    dispatch(setOrderStyles(null));
    setStylesLoading(true);
    dispatch(getOrderStyles(orderId, setStylesLoading));
  };

  const loadOrderPriceReview = () => {
    dispatch(setOrderPriceReview(null));
    setPriceReviewLoading(true);

    dispatch(getOrderPriceReview(orderId, setPriceReviewLoading));
  };

  const showLoader = () => {
    return stylesLoading || priceReviewLoading;
  };

  const isSalesAids = order?.type === OrderTypeEnums['Sales Aids'];

  const getGrossMarginPrice = (totalNetCost: number) => {
    if (grossMargin && !methods.formState.errors.grossMargin) {
      const margin = 1 - parseFloat(grossMargin) / 100;
      return totalNetCost / margin - totalNetCost;
    }

    return 0;
  };

  const getFreightPrice = (totalExcludedNetPrice: number) => {
    if (freight && !methods.formState.errors.freight) {
      return (totalExcludedNetPrice * parseFloat(freight)) / 100;
    }

    return 0;
  };

  const getSalesTaxPrice = (totalNetPrice: number, totalNetCost: number) => {
    // COST IS PRICE WITH FREIGHT AND ALL THOSE THINGS
    // PRICE IS WITHPOUT FREIGHT

    if (salesTax && !methods.formState.errors.salesTax) {
      const noFreightPrice = isNetPriceSelected
        ? totalNetPrice
        : totalNetCost +
          getGrossMarginPrice(totalNetCost) -
          (priceReview?.jobSite ?? 0) -
          (priceReview?.fuelNetSurcharge ?? 0) -
          (priceReview?.totalNetFreight ?? 0);

      const freightPrice = isNetPriceSelected
        ? totalNetCost
        : totalNetCost + getGrossMarginPrice(totalNetCost);

      return isNoFreightSelected
        ? noFreightPrice * (+salesTax / 100)
        : freightPrice * (+salesTax / 100);
    }

    return 0;
  };

  const getSalesPrice = (totalNetPrice: number, totalNetCost: number) => {
    return (
      totalNetCost +
      getSalesTaxPrice(totalNetPrice, totalNetCost) +
      getGrossMarginPrice(totalNetCost)
    );
  };

  const getTotalNetCost = (
    totalNetCost: number,
    totalExcludedNetPrice: number,
    totalNetFreight: number
  ) => {
    return (
      totalNetCost - totalNetFreight + getFreightPrice(totalExcludedNetPrice)
    );
  };

  const onExportToPDFSuccessHandler = (pdfSnapshot: IFileData) => {
    UtilService.convertFileToBlob(pdfSnapshot);
  };

  const onExportToPDFErrorHandler = (error: ServerErrorResponse) => {
    setExportToPDFLoading(false);
    UtilService.handleError({
      err: error,
    });
  };

  const onExportToPDFClickHandler = () => {
    if (!order?.id) return;

    setExportToPDFLoading(true);

    dispatch(
      exportAsPDF(
        { noPricing: noPricingSelected, orderId: order.id! },
        onExportToPDFSuccessHandler,
        onExportToPDFErrorHandler,
        setExportToPDFLoading
      )
    );
    setOpenPDFExportModal(false);
  };

  const continueToSubmit = () => {
    setOpenAdditonal(false);
    setOpenConfirmation(true);
  };

  const { AdditionalAttachmentModal, additionalAttachments } =
    useAdditionalAttachmentModal({
      continueToSubmit,
      opened: openAdditional,
      closeModal: () => {
        setOpenAdditonal(false);
      },
    });

  const onSubmitClickHandler = () => {
    if (
      upcharge_differences.enabled &&
      !upchargesOverriden &&
      (upchargeDifferences ?? []).length > 0 &&
      !checkIsOrderInLocalStorage()
    ) {
      dispatch(
        setUpchargeDifferencesModalOpened({
          opened: true,
          onSubmit: continueToSubmit,
        })
      );
    } else if (numberOfUnresolvedTopics > 0) {
      setOpenUnresolvedTopics(true);
    } else if (additionalAttachments.length > 0) {
      setOpenAdditonal(true);
    } else {
      setOpenConfirmation(true);
      setOpenAdditonal(false);
    }
  };

  const onSubmitOrderSuccessHandler = () => {
    updateOrderPriority();
    setOpenConfirmation(false);

    toast.success('You have successfully submitted the order.');

    // get latest order status
    dispatch(getOrderStatus(orderId));
    setOpenSubmittedOrderConfirmation(true);

    dispatch(
      getOrderLineItemCLValues({
        orderId,
      })
    );
  };

  const onSubmitOrderFailedHandler = (err: ServerErrorResponse) => {
    setOpenConfirmation(false);
    setSubmitErrorCodeModal(err.type);
    setSubmitOrderLoading(false);
  };

  const submitOrderHandler = (isAsap: boolean) => {
    setSubmitOrderLoading(true);
    dispatch(
      submitOrder(
        { orderId, isAsap },
        onSubmitOrderSuccessHandler,
        onSubmitOrderFailedHandler,
        setSubmitOrderLoading
      )
    );
  };

  const bold = (content: string) => {
    return `<b style="color: #677D9E;">${content}</b>`;
  };

  const submittedOrderMessage = `<p>We have received your order and will do our best to process it in a timely manner.</p><br /><p>${bold(
    'Reminder:'
  )} The order is still editable and will remain editable until it reaches the material planning phase which happens when it is taken for processing.</p>`;

  const submittedQuoteMessage = `<p>We have received your quote and will do our best to process it in a timely manner.</p>`;

  const submitQuoteMessage = `<p>Are you sure you’d like to submit this quote to Customer Service for processing?
  </p><br /><p>${bold(
    'Note:'
  )} This is a custom price quote and not an order.</p>`;

  const additionalFields = useSelector(
    (state: RootState) => state.aditionalFieldReducer.aditionalFields
  );

  useEffect(() => {
    if (!styles) {
      loadOrderStyles();
    }

    dispatch(getOrderAttachments(orderId));

    dispatch(
      getOrderLineItems({
        orderId,
      })
    );

    if (!additionalFields) {
      dispatch(getAdditionalFields());
    }

    loadOrderPriceReview();
  }, []);

  useEffect(() => {
    if (priceReview) {
      methods.reset({
        grossMargin: priceReview.grossMargin.toString(),
        salesTax: priceReview.salesTax.toString(),
        freight: priceReview.freight.toString(),
      });
    }
  }, [priceReview]);

  useEffect(() => {
    if (isOrderNotSubmitted) {
      dispatch(getUnresolvedTopics(orderId));
    }
  }, [isOrderNotSubmitted]);

  useEffect(() => {
    if (upchargesOverriden && isOrderNotSubmitted) {
      if (numberOfUnresolvedTopics > 0) {
        setOpenUnresolvedTopics(true);
      } else {
        setOpenConfirmation(true);
      }
    }
  }, [upchargesOverriden, isOrderNotSubmitted]);

  useWindowScrollPosition(priceReview !== null);

  return (
    <>
      <AcknowledgmentPrint />

      {isReviewPricing && (
        <Wrapper>
          <H2>Review Pricing</H2>
          <Spacer h="6px" />
          <PMedium color={nevada}>See the following categories.</PMedium>
        </Wrapper>
      )}

      {showLoader() && <Loader size={40} />}

      {!showLoader() && styles && priceReview && (
        <>
          {isReviewPricing && isUserDealerOrSalesRep && (
            <Wrapper flex justifyEnd middle>
              <ButtonPrimary
                onClick={() => setOpenPDFExportModal(true)}
                disabled={exportToPDFLoading}
              >
                <Wrapper flex middle>
                  Export to PDF
                  <Loader
                    hidden={!exportToPDFLoading}
                    size={16}
                    insideButton
                    noSpacing
                  />
                </Wrapper>
              </ButtonPrimary>
            </Wrapper>
          )}
          {isReviewPricing && isUserDealerOrSalesRep && <Spacer h="16px" />}

          {styles.map((style) => (
            <div key={style.id}>
              <OrderReviewPricingStyle
                style={style}
                key={style.id}
                orderStylePriceReviewData={
                  priceReview.orderStylePriceReview.find(
                    (x) => x.styleId === style.id
                  )!
                }
                showStyleDetails={!isReviewPricing}
                isSalesAids={isSalesAids}
              />
            </div>
          ))}

          <>
            <Divider />

            <ExpandableBox
              title="Total Pricing"
              description="Summary of prices"
            >
              <WhiteBox>
                <Wrapper flex middle>
                  <ContainerMedium
                    isTableHeadCell
                    flexGrow
                    border={['bottom']}
                  />
                  {!isSalesAids && (
                    <>
                      <ContainerSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>%</PSmall>
                      </ContainerSmall>
                      <ContainerSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>List$</PSmall>
                      </ContainerSmall>
                    </>
                  )}

                  <ContainerSmall isTableHeadCell border={['bottom']}>
                    <PSmall bold>Net$</PSmall>
                  </ContainerSmall>
                </Wrapper>

                {styles &&
                  styles.map((style) => (
                    <Wrapper flex middle key={style.id}>
                      <ContainerMedium
                        flexGrow
                        align="left"
                        isTableCell
                        border={['right']}
                      >
                        <H6>
                          TOTAL STYLE {style.number}{' '}
                          {!isSalesAids &&
                          priceReview?.orderStylePriceReview.find(
                            (x) => x.styleId === style.id
                          )?.noFreight
                            ? '(NO FREIGHT)'
                            : ''}
                        </H6>
                      </ContainerMedium>

                      {!isSalesAids && (
                        <ContainerSmall isTableCell border={['right']}>
                          <PSmall>
                            {style.isComplete
                              ? calculateTotals(
                                  style?.id || '',
                                  true
                                ).totalPrice.toFixed(2)
                              : 'Incomplete'}
                          </PSmall>
                        </ContainerSmall>
                      )}

                      <ContainerSmall isTableCell>
                        <PSmall>
                          {style.isComplete
                            ? calculateTotals(
                                style?.id || '',
                                false
                              ).totalPrice.toFixed(2)
                            : 'Incomplete'}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>
                  ))}

                {isSalesAids && (
                  <Wrapper flex middle>
                    <ContainerMedium isTableCell mrAuto>
                      <H6>*FREIGHT CHARGES APPLIED AT INVOICING</H6>
                    </ContainerMedium>
                  </Wrapper>
                )}

                {isReviewPricing && !isSalesAids && (
                  <>
                    <TotalPriceRow
                      title="total"
                      listTotal={priceReview.totalListPrice}
                      netTotal={priceReview.totalNetPrice}
                    />

                    <Spacer h="32px" />
                    <Wrapper flex middle>
                      <ContainerMedium isTableCell flex middle mrAuto>
                        <H6>FREIGHT</H6>

                        <Spacer w="10px" />

                        <Info>
                          <Tooltip position="right">
                            Calculated based on NET value of excluded total.
                          </Tooltip>
                        </Info>
                      </ContainerMedium>

                      <ContainerMedium
                        flex
                        middle
                        isTableCell
                        border={['right']}
                        noVertialSpacing
                      >
                        <PercentInputWrapper>
                          {/* 0 - 99.99 */}
                          <Input
                            {...methods.register(
                              'freight',
                              onlyDigitValidation({ min: 0, max: 99.99 })
                            )}
                            readOnly={!canEdit}
                            aria-invalid={
                              methods.formState.errors.freight
                                ? 'true'
                                : 'false'
                            }
                            className="review-pricing-percentage-input"
                          />
                        </PercentInputWrapper>
                      </ContainerMedium>
                      <ContainerSmall isTableCell>
                        <PSmall>
                          {getFreightPrice(
                            priceReview.totalExcludedNetPrice
                          ).toFixed(2)}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex middle>
                      <ContainerMedium isTableCell mrAuto>
                        <H6>
                          *FUEL SURCHARGE APPLIED AT INVOICING.{' '}
                          <a
                            href="https://plato-dev-files.s3.us-east-1.amazonaws.com/catalogs/Plato%20Custom%20Catalog.pdf#page=34"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SEE CATALOG.
                          </a>
                        </H6>
                        <Spacer w="10px" />
                      </ContainerMedium>

                      {/* (
                        <>
                          <ContainerSmall isTableCell border={['right']}>
                            <PSmall>
                              {isNoChargeOrder
                                ? 0
                                : priceReview?.fuelSurchargePercentage}
                              %
                            </PSmall>
                          </ContainerSmall>

                          <ContainerSmall isTableCell border={['right']}>
                            <PSmall> </PSmall>
                          </ContainerSmall>

                          <ContainerSmall isTableCell>
                            <PSmall>
                              {priceReview?.fuelNetSurcharge.toFixed(2)}
                            </PSmall>
                          </ContainerSmall>
                        </>
                              ) */}
                    </Wrapper>

                    <Wrapper flex middle>
                      <ContainerMedium mrAuto isTableCell>
                        <H6>JOB SITE DELIVERY</H6>
                      </ContainerMedium>

                      <ContainerSmall isTableCell border={['right']}>
                        <PSmall> </PSmall>
                      </ContainerSmall>

                      <ContainerSmall isTableCell border={['right']}>
                        <PSmall> </PSmall>
                      </ContainerSmall>

                      <ContainerSmall isTableCell>
                        <PSmall>{priceReview.jobSite.toFixed(2)}</PSmall>
                      </ContainerSmall>
                    </Wrapper>
                  </>
                )}

                <TotalPriceRow
                  title="total dealer cost"
                  netTotal={
                    isReviewPricing
                      ? getTotalNetCost(
                          priceReview.totalNetCost,
                          priceReview.totalExcludedNetPrice,
                          priceReview.totalNetFreight
                        )
                      : priceReview.totalNetPrice
                  }
                />
              </WhiteBox>

              {isReviewPricing && !isUserCSR && !isSalesAids && (
                <>
                  <Spacer h="24px" />

                  <WhiteBox>
                    <Wrapper flex middle>
                      <ContainerMedium
                        isTableHeadCell
                        align="left"
                        border={['bottom', 'right']}
                        flexGrow
                      >
                        <H6>SELLING TOOLS (OPTIONAL)</H6>
                      </ContainerMedium>

                      <ContainerSmall isTableHeadCell border={['bottom']}>
                        <H6>Net$</H6>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex middle>
                      <ContainerMedium
                        isTableCell
                        align="left"
                        border={['right']}
                        flexGrow
                      >
                        <H6>NET SALE PRICE</H6>
                      </ContainerMedium>

                      <ContainerSmall isTableCell>
                        <PSmall>
                          {getTotalNetCost(
                            priceReview.totalNetCost,
                            priceReview.totalExcludedNetPrice,
                            priceReview.totalNetFreight
                          ).toFixed(2)}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex middle>
                      <ContainerMedium isTableCell align="left" flexGrow>
                        <H6>GROSS MARGIN</H6>
                      </ContainerMedium>

                      <ContainerMedium
                        flex
                        middle
                        isTableCell
                        border={['right']}
                        noVertialSpacing
                      >
                        <PercentInputWrapper>
                          {/* 0 - 99.99 */}
                          <Input
                            {...methods.register(
                              'grossMargin',
                              onlyDigitValidation({ min: 0, max: 99.99 })
                            )}
                            readOnly={!canEdit}
                            aria-invalid={
                              methods.formState.errors.grossMargin
                                ? 'true'
                                : 'false'
                            }
                            className="review-pricing-percentage-input"
                          />
                        </PercentInputWrapper>
                        <Spacer w="15px" />
                      </ContainerMedium>

                      <ContainerSmall isTableCell>
                        <PSmall>
                          {getGrossMarginPrice(
                            getTotalNetCost(
                              priceReview.totalNetCost,
                              priceReview.totalExcludedNetPrice,
                              priceReview.totalNetFreight
                            )
                          ).toFixed(2)}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex>
                      <ContainerMedium
                        flexGrow
                        isTableCell
                        border={['right']}
                      />
                      <ContainerSmall isTableCell />
                    </Wrapper>

                    <Wrapper flex middle between>
                      <ContainerMedium align="left" isTableCell>
                        <H6>SALES TAX</H6>
                      </ContainerMedium>

                      <ContainerMedium
                        flex
                        middle
                        center
                        isTableCell
                        mrAuto
                        mlAuto
                        noVertialSpacing
                      >
                        <div className="review-pricing-switch-buttons">
                          <SwitchButton
                            isActive={isNetPriceSelected}
                            leftButtonText="Net"
                            rightButtonText="List"
                            onChange={setIsNetPriceSelected}
                          />
                        </div>

                        <Spacer
                          w="32px"
                          className="review-pricing-switch-buttons"
                        />

                        <div className="review-pricing-switch-buttons">
                          <SwitchButton
                            isActive={isNoFreightSelected}
                            leftButtonText="No Freight"
                            rightButtonText="Freight"
                            onChange={setNoIsFreightSelected}
                          />
                        </div>
                      </ContainerMedium>

                      <ContainerMedium
                        flex
                        middle
                        isTableCell
                        border={['right']}
                        noVertialSpacing
                      >
                        <PercentInputWrapper>
                          {/* 20 with 3 decimal places */}
                          {/* 0 - 20.000 */}
                          <Input
                            {...methods.register(
                              'salesTax',
                              onlyDigitValidation({ min: 0, max: 20 })
                            )}
                            readOnly={!canEdit}
                            aria-invalid={
                              methods.formState.errors.salesTax
                                ? 'true'
                                : 'false'
                            }
                            className="review-pricing-percentage-input"
                          />
                        </PercentInputWrapper>
                        <Spacer w="15px" />
                      </ContainerMedium>

                      <ContainerSmall isTableCell>
                        <PSmall>
                          {getSalesTaxPrice(
                            priceReview.totalNetPrice,
                            getTotalNetCost(
                              priceReview.totalNetCost,
                              priceReview.totalExcludedNetPrice,
                              priceReview.totalNetFreight
                            )
                          ).toFixed(2)}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex>
                      <ContainerMedium
                        flexGrow
                        isTableCell
                        border={['right']}
                      />
                      <ContainerSmall isTableCell />
                    </Wrapper>

                    <Wrapper flex>
                      <ContainerMedium
                        flexGrow
                        isTableCell
                        border={['right']}
                      />
                      <ContainerSmall isTableCell />
                    </Wrapper>

                    <TotalPriceRow
                      title="selling price"
                      netTotal={getSalesPrice(
                        priceReview.totalNetPrice,
                        getTotalNetCost(
                          priceReview.totalNetCost,
                          priceReview.totalExcludedNetPrice,
                          priceReview.totalNetFreight
                        )
                      )}
                    />
                  </WhiteBox>
                </>
              )}

              {isUserCSR && (
                <>
                  <Spacer h="32px" />
                  <Info type="warning">
                    {isReviewPricing
                      ? 'This is not the final price for the order, it is an estimate of the price.'
                      : 'Acknowledgments are subject to price correction at invoicing.'}
                  </Info>
                </>
              )}
            </ExpandableBox>
          </>
        </>
      )}

      {isReviewPricing &&
        isUserDealerOrSalesRep &&
        (order?.status?.id ?? 0) >= OrderStatusEnums.Received &&
        order?.acknowledgementId === null &&
        order?.specialOrderApproval === null && (
          <>
            <Spacer h="32px" />
            <PMedium>
              This order has been submitted and awaits the response from the
              CSR.
            </PMedium>
          </>
        )}

      <OrderLogs
        bottomPosition={
          (order?.status?.id ?? 0) > OrderStatusEnums['Not Submitted']
        }
      />
      {order?.status?.id === OrderStatusEnums['Not Submitted'] && (
        <>
          <OrderFooter>
            <Wrapper flex middle justifyEnd>
              <ButtonPrimary
                onClick={onSubmitClickHandler}
                disabled={showLoader() || !canEdit}
                {...testId(OrderReviewPricingTestButtonEnum.SUBMIT_BTN)}
              >
                Submit
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </>
      )}

      <SubmitErrorModal
        errorType={submitErrorCodeModal}
        opened={submitErrorCodeModal !== null}
        onClose={(close) => {
          setSubmitErrorCodeModal(null);
          close();
        }}
      />

      <ConfirmationModal
        opened={openPDFExportModal}
        title="Export"
        confirm={onExportToPDFClickHandler}
        cancelButtonText="Cancel"
        buttonText="Export"
        cancel={() => setOpenPDFExportModal(false)}
        bottomSpacing={0}
      >
        <Wrapper flex column gap={32}>
          <RadioButton
            checked={!noPricingSelected}
            id="select-pricing"
            name="pricing"
            onChange={() => setNoPricingSelected(!noPricingSelected)}
            title="Export to PDF with Pricing"
          />

          <RadioButton
            checked={noPricingSelected}
            id="select-without-pricing"
            name="pricing"
            onChange={() => setNoPricingSelected(!noPricingSelected)}
            title="Export to PDF without Pricing"
          />
          <Spacer h="8px" />
        </Wrapper>
      </ConfirmationModal>

      <ConfirmationModal
        opened={openUnresolvedTopics}
        title="Unresolved topics"
        message=""
        htmlMessage="<p>You have unresolved topics in collaboration. Would you like to resolve them first or make the submission anyway?</p>"
        confirm={() => {
          setOpenUnresolvedTopics(false);
          setOpenConfirmation(true);
        }}
        buttonText="Continue to Submit"
        cancelButtonText="Resolve"
        cancel={() => {
          setOpenUnresolvedTopics(false);
          dispatch(setCurtainTab(CurtainTabsEnum.COLLABORATION_TAB));
        }}
        disableLoading
      />

      <ConfirmationModal
        opened={openConfirmation}
        title={isQuoteFlow ? 'Submit Quote' : 'Submit order'}
        message=""
        htmlMessage={isQuoteFlow ? submitQuoteMessage : ''}
        confirm={() => submitOrderHandler(false)}
        buttonText="Submit"
        cancelButtonText="Cancel"
        cancel={() => setOpenConfirmation(false)}
        shouldNotRenderSubmitButton={!isQuoteFlow}
        bottomSpacing={30}
      >
        {!isQuoteFlow && (
          <>
            <Wrapper flex column>
              <Spacer h="20px" />

              <H2>Ship Week</H2>
              <P>
                Cabinet Orders <br />
                Any order containing cabinet(s) should be submitted for review
                by our scheduler. All orders, whether they are to be placed in
                the standard lead time or a custom ship week request, should be
                submitted to receive a ship week. The scheduler will follow up
                soon after order placement with an order confirmation.
              </P>
              <Spacer h="20px" />
              <Wrapper flex justifyEnd>
                <ButtonPrimary
                  onClick={() => submitOrderHandler(false)}
                  disabled={submitOrderLoading}
                >
                  <Wrapper flex middle>
                    Submit for Ship Week
                    <Loader
                      hidden={!submitOrderLoading}
                      size={16}
                      insideButton
                      noSpacing
                    />
                  </Wrapper>
                </ButtonPrimary>
              </Wrapper>
            </Wrapper>
            <Spacer h="30px" />
            <Divider />
            <Spacer h="30px" />
            <Wrapper flex column>
              <H2>ASAPs</H2>
              <P>
                Sales Aids & Punch List Items & Warranty Claims <br />
                This is an expedited order process to handle replacement parts
                and/or products needed to complete an installation. These orders
                also include sales aids such as door samples, color chips, and
                other misc. items. ASAP orders are automatically approved upon
                acknowledgement (unless an approved drawing is required). Be
                certain that information is complete and accurate, or it could
                delay your order.
              </P>
              <Spacer h="20px" />
              <Wrapper flex middle justifyEnd>
                <ButtonPrimary
                  onClick={() => submitOrderHandler(true)}
                  disabled={submitOrderLoading}
                >
                  <Wrapper flex middle>
                    Submit as ASAP
                    <Loader
                      hidden={!submitOrderLoading}
                      size={16}
                      insideButton
                      noSpacing
                    />
                  </Wrapper>
                </ButtonPrimary>
              </Wrapper>
            </Wrapper>
            <Spacer h="20px" />
          </>
        )}
      </ConfirmationModal>

      <ConfirmationModal
        confirm={() => setOpenSubmittedOrderConfirmation(false)}
        opened={openSubmittedOrderConfirmation}
        title={`Thanks For Submitting Your ${isQuoteFlow ? 'Quote' : 'Order'}!`}
        htmlMessage={
          isQuoteFlow ? submittedQuoteMessage : submittedOrderMessage
        }
        bottomSpacing={32}
        shouldNotRenderSubmitButton
        cancelButtonText="Dismiss"
      />

      <AdditionalAttachmentModal />
    </>
  );
};

OrderReviewPricing.defaultProps = {
  isReviewPricing: true,
};

export default OrderReviewPricing;
