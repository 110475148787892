import { AnyAction } from 'redux';

import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { OrderLineItem } from 'order/wizard/orderStyles/interface/LineItem';

import {
  orderLineItemActions,
  LineItemCatalog,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import { IAttachment } from 'shared/interface/IAttachment';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import { IConfigureLineItemMeta } from '../interface/IConfigureLineItem';
import { IModification } from '../components/Modifications/interface/IModification';
import { ILineItemCategory } from '../interface/IlineItemCategory';

import {
  ILineItemCatalogItem,
  ILineItemCatalogItemSM,
} from '../interface/ILineItemCatalog';

import {
  ILineItemDefaultData,
  ILineItemDefaultDataSM,
} from '../interface/ILineItemData';

export interface OrderLineItemsInitialStore {
  catalogLineItem: ILineItemCatalogItem | ILineItemCatalogItemSM | null;
  categories: ILineItemCategory[] | null;
  lineItemDefaultData: ILineItemDefaultData | ILineItemDefaultDataSM | null;
  lineItemMeta: IConfigureLineItemMeta | null;
  lineItemDoorCodes: IDoorCode | null;
  lineItemCatalog: PaginatedItems<ILineItemCatalogItem> | null;
  orderLineItemData: OrderLineItem | null;
  selectedCategory: ILineItemCategory | null;
  selectedSubcategory: ILineItemCategory | null;
  subcategories: ILineItemCategory[] | null;
  modalOpenedForLineItemId: string | null;
  lineItemAttachments: IAttachment[];
  lineItemAttachmentsForDelete: IAttachment[];
  lineItemModifications: IModification[];
  style: Style | null;
  isStepDirty: boolean;
  lineItemNumber: number;
  deletedLineItemModifications: IModification[];
}

const initialState: OrderLineItemsInitialStore = {
  modalOpenedForLineItemId: null,
  catalogLineItem: null,
  categories: null,
  lineItemDefaultData: null,
  orderLineItemData: null,
  lineItemCatalog: null,
  selectedSubcategory: null,
  subcategories: null,
  lineItemMeta: null,
  lineItemDoorCodes: null,
  lineItemAttachments: [] as IAttachment[],
  lineItemModifications: [] as IModification[],
  lineItemAttachmentsForDelete: [],
  selectedCategory: {
    id: '0',
    name: 'All products',
    lineItemCount: 0,
  },
  style: null,
  isStepDirty: false,
  lineItemNumber: 0,
  deletedLineItemModifications: [],
};

export const orderLineItemsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case orderLineItemActions.SET_CURRENT_LINE_ITEM:
      return {
        ...state,
        catalogLineItem: action.payload as ILineItemCatalogItem,
      };
    case orderLineItemActions.SET_IS_LINE_ITEMS_STEP_DIRTY:
      return {
        ...state,
        isStepDirty: action.payload as boolean,
      };
    case orderLineItemActions.CLEAR_LINE_ITEMS:
      return { ...initialState };
    case orderLineItemActions.SET_LINE_ITEMS_MODAL_OPENED:
      return {
        ...state,
        modalOpenedForLineItemId: action.payload as string,
      };
    case orderLineItemActions.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload as ILineItemCategory[],
      };
    case orderLineItemActions.SET_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload as ILineItemCategory,
      };
    case orderLineItemActions.SET_SUBCATEGORIES:
      return { ...state, subcategories: action.payload as ILineItemCategory[] };
    case orderLineItemActions.SET_SUBCATEGORY:
      return {
        ...state,
        selectedSubcategory: action.payload as ILineItemCategory,
      };
    case orderLineItemActions.SET_LINE_ITEM_CATALOG: {
      if (action.payload) {
        const { lineItemCatalog, appendItems } =
          action.payload as LineItemCatalog;

        let updatedItems = lineItemCatalog.items;

        if (state.lineItemCatalog && appendItems) {
          updatedItems = [
            ...state.lineItemCatalog.items,
            ...lineItemCatalog.items,
          ];
        }

        return {
          ...state,
          lineItemCatalog: {
            ...lineItemCatalog,
            items: updatedItems,
          } as PaginatedItems<ILineItemCatalogItem>,
        };
      }
      return {
        ...state,
        lineItemCatalog: null,
      };
    }
    case orderLineItemActions.SET_LINE_ITEM_DEFAULT_DATA:
      return {
        ...state,
        lineItemDefaultData: action.payload
          ? ({
              ...action.payload,
              doorCodes:
                action.payload.materialDoorCodes ?? action.payload.doorCodes,
            } as ILineItemDefaultData)
          : null,
      };
    case orderLineItemActions.SET_ORDER_LINE_ITEM_DATA: {
      const lineItemData = action.payload as OrderLineItem;

      if (lineItemData) {
        const { modifications, attachments, ...rest } = lineItemData;

        return {
          ...state,
          orderLineItemData: {
            ...rest,
            attachments,
            wallDoorCodes: rest.wallDoorCodes?.length
              ? rest.wallDoorCodes
              : rest.materialWallDoorCodes,
            baseDoorCodes: rest.baseDoorCodes?.length
              ? rest.baseDoorCodes
              : rest.materialBaseDoorCodes,
            drawerDoorCodes: rest.drawerDoorCodes?.length
              ? rest.drawerDoorCodes
              : rest.materialDrawerDoorCodes,
            modifications,
          } as OrderLineItem,
          lineItemAttachments: (attachments ?? []).map((att) => ({
            ...att,
            completed: true,
          })),
          lineItemModifications: (modifications ?? []).map((mod) => ({
            ...mod,
            completed: true,
          })),
        };
      }

      return {
        ...state,
        orderLineItemData: lineItemData,
        lineItemAttachments: [],
        lineItemModifications: [],
      };
    }
    case orderLineItemActions.SET_LINE_ITEM_META_BY_WHD:
      return {
        ...state,
        lineItemMeta: action.payload as IConfigureLineItemMeta,
      };
    case orderLineItemActions.SET_LINE_ITEM_DOOR_CODES_BY_WHD:
      return {
        ...state,
        lineItemDoorCodes: action.payload as IDoorCode,
      };
    case orderLineItemActions.SET_LINE_ITEM_ATTACHMENTS:
      return {
        ...state,
        lineItemAttachments: action.payload as IAttachment[],
      };

    case orderLineItemActions.APPEND_SAVED_LINE_ITEM_ATTACHMENT:
      return {
        ...state,
        lineItemAttachments: [
          ...state.lineItemAttachments,
          ...(action.payload as IAttachment[]),
        ],
      };
    case orderLineItemActions.SET_MODIFICATIONS:
      return {
        ...state,
        lineItemModifications: action.payload as IModification[],
      };
    case orderLineItemActions.ADD_MODIFICATIONS:
      return {
        ...state,
        lineItemModifications: [
          ...state.lineItemModifications,
          ...action.payload,
        ] as IModification[],
      };
    case orderLineItemActions.REMOVE_MODIFICATION:
      return {
        ...state,
        lineItemModifications: state.lineItemModifications
          .filter((mod) => mod.id !== action.payload)
          .map((mod, index) => ({ ...mod, number: index + 1 })),
        deletedLineItemModifications: [
          ...state.deletedLineItemModifications,
          state.lineItemModifications.find((mod) => mod.id === action.payload)!,
        ],
      };
    case orderLineItemActions.UPDATE_MODIFICATION: {
      const payloadMod = action.payload as IModification;

      return {
        ...state,
        lineItemModifications: state.lineItemModifications.map((mod) => {
          const modId = mod.id;
          const payloadModId = payloadMod.id;

          if (modId === payloadModId) {
            return payloadMod;
          }

          return mod;
        }),
      };
    }
    case orderLineItemActions.REPLACE_MODIFICATION: {
      const payloadMod = action.payload.modification as IModification;

      return {
        ...state,
        lineItemModifications: state.lineItemModifications.map((mod) => {
          const modToReplace = action.payload.modificationToReplaceId;
          if (modToReplace === mod.id) {
            return { ...payloadMod, number: mod.number };
          }

          return mod;
        }),
      };
    }
    case orderLineItemActions.REORDER_MODIFICATIONS:
      return {
        ...state,
        lineItemModifications: action.payload as IModification[],
      };
    case orderLineItemActions.UPDATE_ATTACHMENT_NOTE:
      return {
        ...state,
        lineItemAttachments: state.lineItemAttachments.map(
          (lineItemAttachment) => {
            if (lineItemAttachment.id === action.payload.id) {
              return {
                ...lineItemAttachment,
                note: action.payload.note,
              };
            }

            return lineItemAttachment;
          }
        ),
      };
    case orderLineItemActions.REMOVE_ATTACHMENT_FROM_LINE_ITEM:
      return {
        ...state,
        lineItemAttachmentsForDelete: [
          ...state.lineItemAttachmentsForDelete,
          ...state.lineItemAttachments.filter(
            (lineItemAttachment) => lineItemAttachment.id === action.payload
          ),
        ],
        lineItemAttachments: state.lineItemAttachments.filter(
          (lineItemAttachment) => lineItemAttachment.id !== action.payload
        ),
      };
    case orderLineItemActions.CLEAR_ATTACHMENTS_FOR_DELETE_LIST:
      return {
        ...state,
        lineItemAttachmentsForDelete: [] as IAttachment[],
      };
    case orderLineItemActions.SET_LINE_ITEM_STYLE:
      return {
        ...state,
        style: action.payload as Style,
      };
    case orderLineItemActions.SET_AUTOMATIC_MODS:
      return {
        ...state,
        lineItemModifications: action.payload as IModification[],
      };
    case orderLineItemActions.UPDATE_MOD_PRICES: {
      const mods = action.payload as Pick<
        IModification,
        'id' | 'price' | 'isFixedPrice'
      >[];

      return {
        ...state,
        lineItemModifications: state.lineItemModifications.map((mod) => ({
          ...mod,
          price: mods.find((m) => m.id === mod.catalogModificationId)
            ?.isFixedPrice
            ? mod.price
            : mods.find((m) => m.id === mod.catalogModificationId)?.price ?? 0,
        })),
      };
    }
    case orderLineItemActions.UPDATE_DIMUP_ON_ORDER_LINE_ITEM: {
      const { dimUpcharge, dimUpchargePrice } = action.payload as Pick<
        OrderLineItem,
        'dimUpcharge' | 'dimUpchargePrice'
      >;

      return {
        ...state,
        orderLineItemData: {
          ...state.orderLineItemData,
          dimUpcharge,
          dimUpchargePrice,
        } as OrderLineItem,
      };
    }
    case orderLineItemActions.INCREMENT_LINE_ITEM_NUMBER: {
      return {
        ...state,
        lineItemNumber: state.lineItemNumber + 1,
      };
    }
    default:
      return state;
  }
};
