import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from 'store';

import {
  INotification,
  NotificationVariationEnums,
} from 'overview/interface/Notification';

import { Action } from 'shared/interface/Action';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import {
  ChangeReadStatusRequest,
  GetNotificationsRequest,
  MarkNotifsAsSeenRequest,
  NotificationsActions,
  setMarkAllNotifsAsRead,
  setNotifications,
  setNotifsAsSeen,
  setNotifsCounts,
  setReadStatus,
} from './notificationsActions';

function* getNotifications(action: Action<GetNotificationsRequest>) {
  try {
    const {
      itemsPerPage,
      page,
      appendItems,
      showUnread,
      showCollaborationOnly,
    } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('unreadOnly', showUnread.toString());
    queryParams.append(
      'showCollaborationOnly',
      showCollaborationOnly.toString()
    );

    const paginatedNotifications: PaginatedItems<INotification> = yield call(
      ApiService.get,
      `/api/notification/notifications`,
      { params: queryParams }
    );

    const notifs: INotification[] = appendItems
      ? yield select(
          (state: RootState) => state.notificationsReducer.notifications?.items
        )
      : [];

    yield put(
      setNotifications({
        ...paginatedNotifications,
        items: [
          ...notifs,
          ...paginatedNotifications.items.map((notif) => ({
            ...notif,
            type: +notif.type.toString().slice(0, 1),
            variation: notif.type as unknown as NotificationVariationEnums,
          })),
        ],
      })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, paginatedNotifications.items);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changeReadStatus(action: Action<ChangeReadStatusRequest>) {
  try {
    const { notificationIds, read, showCollaborationOnly } = action.payload!;

    yield call(
      ApiService.put,
      `/api/notification/notifications/mark-as-${read ? 'un' : ''}read`,
      {
        notificationIds,
      }
    );

    yield put(
      setReadStatus({ notificationIds, read: !read, showCollaborationOnly })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* markAllNotifsAsRead(action: Action<unknown>) {
  try {
    yield call(
      ApiService.put,
      `/api/notification/notifications/mark-all-as-read?onlyCollaboration=${action.payload!}`,
      {}
    );

    yield put(setMarkAllNotifsAsRead(action.payload! as boolean));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getNotifsCounts(action: Action<unknown>) {
  try {
    interface NotificationCountsResponse {
      numberOfNotifications: 12;
      numberOfUnReadNotifications: 0;
      numberOfUnSeenNotifications: 8;
      numberOfCollaborationNotifications: 0;
      numberOfUnreadCollaboration: 0;
      numberOfUnseenCollaboration: 0;
    }

    const notifsCount: NotificationCountsResponse = yield call(
      ApiService.get,
      `/api/notification/notifications/count`
    );

    yield put(
      setNotifsCounts({
        unreadCount: notifsCount.numberOfUnReadNotifications,
        unseenCount: notifsCount.numberOfUnSeenNotifications,
        unreadCollaborationCount: notifsCount.numberOfUnreadCollaboration,
        unseenCollaborationCount: notifsCount.numberOfUnseenCollaboration,
      })
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* markNotifsAsSeen(action: Action<MarkNotifsAsSeenRequest>) {
  try {
    const { notificationIds } = action.payload!;

    yield call(ApiService.put, `api/notification/notifications/mark-as-seen`, {
      notificationIds,
    });

    yield put(
      setNotifsAsSeen(notificationIds, action.payload!.onlyCollaboration)
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* notificationsSagas() {
  yield takeEvery(NotificationsActions.CHANGE_READ_STATUS, changeReadStatus);
  yield takeEvery(NotificationsActions.GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(NotificationsActions.GET_NOTIFS_COUNTS, getNotifsCounts);
  yield takeEvery(NotificationsActions.MARK_ALL_AS_READ, markAllNotifsAsRead);
  yield takeEvery(NotificationsActions.MARK_AS_SEEN, markNotifsAsSeen);
}
