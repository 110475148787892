import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';

import UtilService from 'shared/services/util.service';
import { P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { useQueryParams } from 'shared/hooks/useQueryParams';

import SkeletonList, {
  ISkeletonListItem,
} from 'shared/components/SkeletonList';

import { getCategories } from '../store/orderLineItemsActions';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import LineItemCategory from './LineItemCategory';

const LineItemCategories = () => {
  const dispatch = useAppDispatch();

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'styleId',
  ]);

  const [isMounted, setIsMounted] = useState(false);

  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const categories = useSelector(
    (state: RootState) => state.orderLineItemsReducer.categories
  );

  const style = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style
  );

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const generateSkeletonItems = (sizes: number[]) => {
    const skeletonItems = sizes.map(
      (size) =>
        ({
          padding: 0,
          borderRadius: 8,
          marginBottom: 14,
          w: size,
          h: 19.6,
        } as ISkeletonListItem)
    );

    return skeletonItems;
  };

  useEffect(() => {
    if (isMounted && queryFields?.productLineId && style && order) {
      dispatch(
        getCategories(
          {
            orderType: UtilService.getCatalogOrderType(
              order?.type,
              order?.stylizationType
            ),
            productLineId: queryFields.productLineId,
            styleId: queryFields.styleId,
            doorOverlayId: style.doorOverlay.id,
          },
          setCategoriesLoading
        )
      );
    }

    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, [isMounted, style, order]);

  useEffect(() => {
    if (isOrderSalesMaterial) {
      setCategoriesLoading(false);
    }
  }, [isOrderSalesMaterial]);

  return !isOrderSalesMaterial ? (
    <Wrapper>
      {!categoriesLoading &&
        categories?.map((category) => (
          <LineItemCategory key={category.id} category={category} />
        ))}

      {categoriesLoading && (
        <SkeletonList
          items={generateSkeletonItems([
            80, 90, 100, 120, 100, 110, 110, 90, 100, 100, 120, 100,
          ])}
        />
      )}

      {!categoriesLoading && categories && categories.length === 0 && (
        <P>No categories...</P>
      )}
    </Wrapper>
  ) : (
    <Wrapper>
      <P color={lynch} fontSize={14} bold>
        Sales Aids
      </P>
    </Wrapper>
  );
};

export default LineItemCategories;
