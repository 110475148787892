import { FC, useState } from 'react';
import styled from 'styled-components';

import { Style } from 'order/wizard/orderStyles/interface/Style';
import { H2, P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { ButtonIcon } from 'shared/components/Button';
import SVG from 'shared/components/SVG';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { Spacer } from 'shared/components/Layout';
import { nevada } from 'shared/config/Colors';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const OrderStyleExpandableContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.alto};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.alto};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const OrderStyleExpandableHeader = styled(Wrapper)`
  padding: 16px 0;

  ${ButtonIcon} {
    &.expanded {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
`;

interface OrderStyleExpandableProps {
  style: Style;
  expandedByDefault?: boolean;
  disableExpandCollapse?: boolean;
}

const OrderStyleExpandable: FC<OrderStyleExpandableProps> = ({
  children,
  style,
  expandedByDefault,
  disableExpandCollapse,
}) => {
  const [expanded, setExpanded] = useState(expandedByDefault);

  const lineItemLengthOnStyle = useSelector(
    (state: RootState) =>
      state.orderReducer.lineItems?.filter(
        (orderLineItem) => orderLineItem.style.styleId === style.id
      ).length
  );

  return (
    <OrderStyleExpandableContainer>
      <OrderStyleExpandableHeader flex between middle>
        <Wrapper>
          <H2>
            #{style.number} {style.name}
          </H2>
          <P color={nevada} fontSize={14}>
            {style.productLine.name},{' '}
            {style.woodMaterial?.name ?? style.materialGroup?.name ?? 'N/A'},{' '}
            {style.finishColor?.name ?? style.materialColor?.name ?? 'N/A'},{' '}
            {style.doorStyle?.name ?? 'N/A'}, {lineItemLengthOnStyle} Line item
            {lineItemLengthOnStyle !== 1 ? 's' : ''}
          </P>
        </Wrapper>

        {!disableExpandCollapse && (
          <ButtonIcon
            className={
              expanded
                ? 'expandable-box-button expanded'
                : 'expandable-box-button'
            }
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            <SVG
              animateTransform={['transform', 'borderRadius']}
              icon={<ArrowDownIcon />}
            />
          </ButtonIcon>
        )}
      </OrderStyleExpandableHeader>

      {expanded && children}
      {expanded && <Spacer h="16px" />}
    </OrderStyleExpandableContainer>
  );
};

OrderStyleExpandable.defaultProps = {
  expandedByDefault: false,
  disableExpandCollapse: false,
};

export default OrderStyleExpandable;
