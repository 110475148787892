import { BoxShadowCSS } from 'shared/config/GlobalStyles';

import styled, { css } from 'styled-components';

import { TabbableButton } from './Button';
import { Wrapper } from './Wrapper';

interface MoreInfoContainerProps {
  padding?: string;
}

export const MoreInfoContainer = styled(Wrapper)<MoreInfoContainerProps>`
  ${BoxShadowCSS}
  border-radius: 8px;
  padding: ${({ padding }) => padding ?? '8px 20px'};
`;

interface MoreInfoButtonProps {
  color?: string;
  flex?: boolean;
  middle?: boolean;
  separator?: boolean;
  separatorColor?: string;
}

export const MoreInfoButton = styled(TabbableButton)<MoreInfoButtonProps>`
  padding: 8px 0;
  width: 100%;
  text-align: left;
  border-radius: 0;

  ${({ flex }) => (flex ? 'display: flex' : 'display: block')};
  ${({ middle }) => middle && 'align-items: center'};
  ${({ separator, separatorColor }) =>
    separator &&
    separatorColor &&
    css`
      border-bottom: 1px solid ${separatorColor};
    `}

  &:hover:not(:disabled) {
    opacity: 0.7;
  }

  ${({ color }) => color && `color: ${color}`};
`;
