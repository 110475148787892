import styled from 'styled-components';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { DasboardLocationStateProps } from 'overview/interface/DasboardLocationStateProps';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import {
  getCsrQuotes,
  getDealerQuotes,
  resetQuotes,
} from 'overview/quotes/store/quotesActions';

import { SortDirection, TableColumns } from 'order/enums/orderTableEnums';

import {
  OrderFilterValues,
  OrderFilterValuesRipped,
} from 'order/interfaces/OrderFilterValues';

import MetaTitle from 'shared/components/MetaTitle';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary } from 'shared/components/Button';
import { CsrOrdersTable } from 'overview/components/CsrOrdersTable';
import { CsrQuotesTabsEnum } from 'shared/enum/CsrQuotesTabsEnum';
import { DealerOrdersTable } from 'overview/components/DealerOrdersTable';
import { DealerQuotesTabsEnum } from 'shared/enum/DealerQuoteTabEnum';
import { H1 } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { OrderTableFilters } from 'overview/components/OrderTableFilters';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import { StyledTabs, TabProps } from 'shared/components/StyledTabs';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  CsrQuoteTableRequest,
  QuoteTableRequest,
} from 'shared/interface/QuoteRow';
import { useBrowserStorage } from 'shared/hooks/useBrowserStorage';

export const ContentWrapper = styled.div`
  padding: 16px;
`;

export const QuotePage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [showKickedOutModal, setShowKickedOutModal] = useState(false);

  const location: Location<DasboardLocationStateProps> = useLocation();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const canCreateOrderDraft = useHasPermissions(
    userPermissionsValues.ORDER_CREATE_DRAFT
  );

  const [search, setSearch] = useState<
    OrderFilterValues | OrderFilterValuesRipped
  >({
    order: '',
    orderType: null,
    status: null,
    productLine: null,
    shipWeek: null,
    endShipWeek: null,
    dealership: null,
    assignee: null,
    targetDate: null,
    endTargetDate: null,
    sortColumn: TableColumns.NUMBER,
    sortDirection: SortDirection.DESC,
    page: 1,
    redTag: null,
    collaboratingOn: false,
  });

  const { setItem, value } = useBrowserStorage({
    key: 'quotes',
    storageType: 'localStorage',
  });

  const onTabClickHandler = (tab: string) => {
    const selectedTab = isUserCSR
      ? CsrQuotesTabsEnum[tab as keyof typeof CsrQuotesTabsEnum]
      : DealerQuotesTabsEnum[tab as keyof typeof DealerQuotesTabsEnum];

    setSearch((prevState) => ({
      ...prevState,
      status: undefined,
      page: 1,
      tab: selectedTab,
      collaboratingOn: (prevState as OrderFilterValues).collaboratingOn,
      sortColumn:
        selectedTab > 1
          ? TableColumns.SUBMISSION_DATE
          : TableColumns.CREATED_DATE,
    }));

    dispatch(resetQuotes());
  };

  const tabs: TabProps[] = Object.keys(
    isUserCSR ? CsrQuotesTabsEnum : DealerQuotesTabsEnum
  )
    .filter((k) => Number.isNaN(+k))
    .map((key) => {
      return {
        text: key,
        onClick: () => onTabClickHandler(key),
      };
    });

  const getShipWeeksDates = (data: OrderFilterValues) => {
    const shipWeekStartDate: Date | null = data.shipWeek
      ? new Date(data.shipWeek.value)
      : null;

    const shipWeekEndDate: Date | null = data.endShipWeek
      ? new Date(data.endShipWeek.value)
      : null;

    return {
      shipWeekStartDate,
      shipWeekEndDate,
    };
  };

  const loadQuotes = () => {
    setLoading(true);

    const data = search as OrderFilterValues;

    const shipWeekDates = getShipWeeksDates(data);

    const isNotSubmittedTab = UtilService.isNotSubmittedTab(isUserCSR, search);

    const payload: QuoteTableRequest = {
      page: data.page,
      itemsPerPage: 10,
      searchTerm: data.order,
      status: data.status ? +data.status.value : undefined,
      productLineId: data.productLine?.value,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection,
      dealerTab: data.tab,
      collaboratingOn: data.collaboratingOn,
      dealershipId: data.dealership?.value ?? undefined,
      ...(!isNotSubmittedTab && {
        shipWeekFilterType: data.shipWeekFilterType ?? undefined,
        shipWeekEndDate: shipWeekDates?.shipWeekEndDate ?? undefined,
        shipWeekStartDate: shipWeekDates?.shipWeekStartDate ?? undefined,
      }),
      showCancelledOrders: true,
      showNewCollaboration: data.showNewCollaboration,
    };

    dispatch(getDealerQuotes(payload, setLoading));
  };

  const loadCsrQuotes = () => {
    setLoading(true);

    const data = search as OrderFilterValues;
    const shipWeekDates = getShipWeeksDates(data);

    const isNotSubmittedTab = UtilService.isNotSubmittedTab(isUserCSR, search);

    const canSeeTargetDateFilter = UtilService.canSeeTargetDateFilter(
      isUserCSR,
      search
    );

    const payload: CsrQuoteTableRequest = {
      page: data.page,
      itemsPerPage: 10,
      searchTerm: data.order,
      status: data.status ? +data.status.value : undefined,
      productLineId: data.productLine?.value,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection,
      csrTab: data.tab,
      assigneeId: data.assignee?.value,
      dealershipId: data.dealership?.value,
      redTag: data.redTag ?? undefined,
      collaboratingOn: data.collaboratingOn,
      ...(!isNotSubmittedTab && {
        shipWeekFilterType: data.shipWeekFilterType ?? undefined,
        shipWeekEndDate: shipWeekDates?.shipWeekEndDate ?? undefined,
        shipWeekStartDate: shipWeekDates?.shipWeekStartDate ?? undefined,
      }),
      ...(canSeeTargetDateFilter && {
        targetDateEndDate: data.endTargetDate ?? undefined,
        targetDateFilterType: data.targetDateFilterType ?? undefined,
        targetDateStartDate: data.targetDate ?? undefined,
      }),
      showCancelledOrders: true,
      showNewCollaboration: data.showNewCollaboration,
    };

    dispatch(getCsrQuotes(payload, setLoading));

    history.replace(location.pathname);
  };

  const getQuotes = () => {
    if (isUserCSR) {
      loadCsrQuotes();
    }

    if (isUserDealerOrSalesRep) {
      loadQuotes();
    }
  };

  // trigger api call with saved tab
  useEffect(() => {
    if (location.state?.backToTab) {
      setSearch((prevState) => ({
        ...prevState,
        tab: location.state.backToTab,
      }));

      const backToTabRemoved = UtilService.omit(location.state, [
        'backToTab',
        'backToQuotesBoard',
      ]);

      setTimeout(() => {
        history.replace(
          location.pathname,
          Object.keys(backToTabRemoved).length ? backToTabRemoved : null
        );
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    if (isUserCSR && !location.state?.backToTab && !value) {
      setSearch((prevState) => ({
        ...prevState,
        tab: CsrQuotesTabsEnum['Not Completed'],
        sortColumn: TableColumns.SUBMISSION_DATE,
      }));
    }
  }, [isUserCSR]);

  useEffect(() => {
    if (isUserDealerOrSalesRep && !location.state?.backToTab && !value) {
      setSearch((prevState) => ({
        ...prevState,
        tab: DealerQuotesTabsEnum['Not Submitted'],
        sortColumn: TableColumns.CREATED_DATE,
      }));
    }
  }, [isUserDealerOrSalesRep]);

  useEffect(() => {
    if ((search as OrderFilterValues).tab) {
      getQuotes();
      setItem(search);
    }
  }, [search, isUserCSR, isUserDealerOrSalesRep]);

  useEffect(() => {
    if (location.state?.kickedOut) {
      setShowKickedOutModal(true);
    }

    if (value) {
      const val = value as OrderFilterValues;
      val.page = 1;
      setSearch(val as OrderFilterValues);
    }

    return () => {
      dispatch(resetQuotes());
    };
  }, []);

  return (
    <>
      <MetaTitle title="Quotes | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft>
          <H1>Custom Quotes</H1>

          <Wrapper flex middle>
            {canCreateOrderDraft && (
              <ButtonPrimary
                type="button"
                onClick={() =>
                  history.push('/quote/new', {
                    backToQuotesBoard: true,
                  })
                }
              >
                New Quote Request
              </ButtonPrimary>
            )}
          </Wrapper>
        </OverviewHeaderLeft>
      </OverviewHeader>

      <StyledTabs tabs={tabs} activeIndex={(value as OrderFilterValues)?.tab} />

      <ContentWrapper>
        <OrderTableFilters
          search={search as OrderFilterValues}
          setSearch={setSearch}
          isQuoteBoard
        />

        <Spacer h="16px" />

        <WhiteBox>
          {isUserCSR ? (
            <CsrOrdersTable
              isLoading={loading}
              setSearch={setSearch}
              search={search}
              isQuoteBoard
            />
          ) : (
            <DealerOrdersTable
              isLoading={loading}
              setSearch={setSearch}
              search={search}
              isQuoteBoard
            />
          )}
        </WhiteBox>
      </ContentWrapper>

      <Spacer h="50px" />

      <ModalWrapper
        closeOnEscape={false}
        lockScroll
        modal
        onClose={() => {
          setShowKickedOutModal(false);
          history.replace(
            location.pathname,
            UtilService.omit(location.state, ['kickedOut'])
          );
        }}
        open={showKickedOutModal}
      >
        {(close: () => void) => (
          <Modal close={close} title="Order session expired.">
            You have been kicked out from the order due to inactivity.
          </Modal>
        )}
      </ModalWrapper>
    </>
  );
};
