import { RootState } from 'store';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FC } from 'react';

import { ReactComponent as SyncFailIcon } from 'assets/icons/sync-fail.svg';

import { SynchronizationActionEnum } from 'order/enums/SynchronisationActionEnum';

import { OrderItem } from 'shared/interface/OrderRow';
import { Sides } from 'shared/interface/CSSTypes';
import { Tooltip } from 'shared/components/Tooltip';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { valencia } from 'shared/config/Colors';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

interface SyncToolFailureProps {
  position?: Sides;
  orderRow?: OrderItem;
  margin?: string;
}

const SyncToolFailureContainer = styled(Wrapper)<
  Pick<SyncToolFailureProps, 'margin'>
>`
  margin: ${({ margin }) => margin ?? '0 30px 0 0'};

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }

  .icon {
    display: block;
  }
`;

const SyncToolFailure: FC<SyncToolFailureProps> = ({
  position,
  orderRow,
  margin,
}) => {
  const order = useSelector(
    (state: RootState) => orderRow ?? state.orderReducer.order
  );

  const canViewSyncStatus = useHasPermissions(
    userPermissionsValues.CSR_VIEW_SYNCHRONIZATION_STATUS
  );

  return canViewSyncStatus && order?.synchronizationStatus?.failure ? (
    <SyncToolFailureContainer position="relative" margin={margin}>
      <SVG icon={<SyncFailIcon width={16} height={20.58} />} color={valencia} />
      <Tooltip position={position} minWidth={280}>
        Order Sync failed.
        <br />
        Last Attempt:{' '}
        {UtilService.formatDate({
          date: order.synchronizationStatus.lastAttemptUtc,
        })}
        <br />
        Sync Action:{' '}
        {SynchronizationActionEnum[order.synchronizationStatus.failure.action]}
      </Tooltip>
    </SyncToolFailureContainer>
  ) : null;
};

SyncToolFailure.defaultProps = {
  position: 'right',
};

export default SyncToolFailure;
