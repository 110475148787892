export enum ACKTestContainerEnum {
  DEALER_ACTIONS = 'ack-dealer-actions',
  CSR_ACTIONS = 'ack-csr-actions',
  DEALER_SOR_ACTIONS = 'ack-dealer-sor-actions',
}

export enum ACKTestButtonEnum {
  DISAPPROVE_BTN = 'ack-disapprove-btn',
  DISAPPROVE_SOR_BTN = 'ack-disapprove-sor-btn',
  APPROVE_BTN = 'ack-approve-btn',
  APPROVE_SOR_BTN = 'ack-approve-sor-btn',
  SPECIAL_ORDER_APPROVAL_SEND_BTN = 'special-order-approval-send-btn',
  ACK_SEND_BTN = 'ack-send-btn',
  FINISH_REQUEST = 'finish-request',
  COLLECT_DRAWINGS_BTN = 'collect-drawings-btn',
}

export enum ACKTestCheckboxEnum {
  ACK_CONSENT_CHECKBOX = 'ack-consent-checkbox',
  SOR_CONSENT_CHECKBOX = 'sor-consent-checkbox',
}
