import { FC } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';

import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonIcon } from 'shared/components/Button';
import { silverSandTwo, lynch, nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import { IAttachment } from '../../interface/IAttachment';
import {
  deleteModalAttachment,
  setSelectedAttachment,
  updateAttachmentsModal,
} from './store/attachmentsModalActions';

import { AttachmentsModalStepsEnums } from './enums/AttachmentsModalStepsEnums';
import { H6, P } from '../Typography';
import ElipsisText from '../ElipsisText';

interface AttachmentModalBoxProps {
  attachment: IAttachment;
  editModeEnabled?: boolean;
}

const AttachmentModalBoxActions = styled(Wrapper)`
  background: ${({ theme }) => theme.white};
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 300ms ease;
`;

const additionalHoverCSS = css`
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.lynch};

    ${AttachmentModalBoxActions} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

type AttachmentModalBoxContainerProps = Pick<
  AttachmentModalBoxProps,
  'editModeEnabled'
>;

const AttachmentModalBoxContainer = styled(
  Wrapper
)<AttachmentModalBoxContainerProps>`
  ${BoxShadowCSS}
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 20px;
  padding: 32px;
  position: relative;
  transition: border-color 300ms ease;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ editModeEnabled }) => editModeEnabled && additionalHoverCSS};
`;

export const AttachmentModalBox: FC<AttachmentModalBoxProps> = ({
  attachment,
  editModeEnabled,
}) => {
  const dispatch = useAppDispatch();

  const onEditModClickHandler = () => {
    dispatch(setSelectedAttachment(attachment));
    dispatch(
      updateAttachmentsModal(
        AttachmentsModalStepsEnums.UPDATE_ATTACHMENT,
        AttachmentsModalStepsEnums.UPDATE_ATT_TITLE,
        AttachmentsModalStepsEnums.UPDATE_ATT_DESC
      )
    );
  };

  const onDeleteModClickHandler = () => {
    dispatch(deleteModalAttachment(attachment.id!));
  };

  return (
    <AttachmentModalBoxContainer
      flex
      between
      middle
      editModeEnabled={editModeEnabled}
    >
      <H6>
        <ElipsisText maxWidth={200}>
          {attachment.file?.name ?? 'N/A'}
        </ElipsisText>
      </H6>

      <Spacer w="20px" />

      <Wrapper position="relative" flex middle>
        <P fontSize={12} color={nevada}>
          {UtilService.formatDate({ date: new Date().toISOString() })}
        </P>

        <Spacer w="27px" />

        <P fontSize={12} color={nevada}>
          {UtilService.bytesToMB(attachment.file?.size ?? 0)}MB
        </P>

        {editModeEnabled && (
          <AttachmentModalBoxActions flex justifyEnd middle>
            <ButtonIcon onClick={onEditModClickHandler}>
              <SVG
                icon={<EditIcon />}
                color={silverSandTwo}
                hoverColor={lynch}
              />
            </ButtonIcon>

            <Spacer w="18px" />

            <ButtonIcon onClick={onDeleteModClickHandler}>
              <SVG
                icon={<TrashIcon />}
                color={silverSandTwo}
                hoverColor={lynch}
              />
            </ButtonIcon>
          </AttachmentModalBoxActions>
        )}
      </Wrapper>
    </AttachmentModalBoxContainer>
  );
};

AttachmentModalBox.defaultProps = {
  editModeEnabled: false,
};
