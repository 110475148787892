import { FC, useEffect, useState } from 'react';
import { RootState } from 'store';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/eye.svg';
import { ReactComponent as ReplaceIcon } from 'assets/icons/find_replace.svg';

import { getOrderLineItems } from 'order/store/orderActions';
import { IDoorCodeField } from 'order/wizard/orderStyles/interface/DoorCodes';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import InsertPanelInfo from 'order/wizard/orderLineItems/components/InsertPanelInfo';

import {
  deleteLineItem,
  duplicateLineItem,
  getOrderLineItemData,
  getOrderLineItemSMData,
  setLineItemsModalOpened,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import { clearCurtain } from 'curtain/store/curtainActions';
import { CurtainHeader } from 'curtain/components/CurtainHeader';

import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Divider, Spacer } from 'shared/components/Layout';
import { H3, H5, H6, P } from 'shared/components/Typography';
import { lynch, nevada, silverChaliceTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SVG from 'shared/components/SVG';

const CloseButton = styled(ButtonIcon)`
  path {
    fill: ${({ theme }) => theme.mineShaftVariation};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

const ButtonsContainer = styled(Wrapper)`
  padding: 22px;
`;

const StyledButton = styled(ButtonIcon)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainerWrapper = styled.div`
  height: calc(100% - 156px);
`;

const ContentContainer = styled.div`
  padding: 32px 16px;
`;

const ImageWrapper = styled(Wrapper)`
  padding: 8px 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.mischka};

  > img {
    height: 94px;
    width: 89px;
    object-fit: contain;
  }
`;

interface DoorCodeWrapperProps {
  doorCode: IDoorCodeField;
}

const DoorCodeWrapper: FC<DoorCodeWrapperProps> = ({
  doorCode: { code, insertPanel, quantity },
}) => (
  <>
    <H6>
      {code}
      {(quantity ?? 0) > 0 ? ` (${quantity})` : null}
    </H6>
    <InsertPanelInfo marginLeft={0} insertPanel={insertPanel} />
    <Spacer h="10px" />
  </>
);

export const CurtainDetailsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [lineItemDataLoading, setLineItemDataLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const orderLineItemData = useSelector(
    (state: RootState) => state.orderLineItemsReducer.orderLineItemData
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const editLineItemHandler = () => {
    const params = new URLSearchParams();

    params.append('productLineId', orderLineItemData!.productLineId);
    params.append('styleId', orderLineItemData!.styleId);
    params.append('catalogLineItemId', orderLineItemData!.catalogLineItemId);
    params.append('lineItemId', activeLineItemID!);

    history.replace(`${location.pathname}?${params.toString()}`);
    dispatch(clearCurtain());
    dispatch(setLineItemsModalOpened(activeLineItemID));
  };

  const onSuccessDuplicateLineItem = () => {
    dispatch(clearCurtain());
    dispatch(
      getOrderLineItems({
        orderId,
        ...(isOrderSalesMaterial && {
          isSalesMaterial: true,
          productLineId: customProductLine?.id,
        }),
      })
    );
  };

  const replaceLineItemHandler = () => {
    if (!orderLineItemData) {
      return;
    }
    const params = new URLSearchParams();

    params.append('styleId', orderLineItemData.styleId);
    params.append('productLineId', orderLineItemData.productLineId);
    params.append('lineItemId', activeLineItemID);

    params.append('replacementLineItemId', activeLineItemID);
    params.append(
      'selectedLineItemDetailsId',
      orderLineItemData.catalogLineItemId
    );

    history.replace(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      location.state
    );

    dispatch(setLineItemsModalOpened(activeLineItemID));
  };

  const duplicateLineItemHandler = () => {
    dispatch(
      duplicateLineItem(
        { orderId, lineItemId: activeLineItemID },
        onSuccessDuplicateLineItem
      )
    );
  };

  const onSuccessDeleteLineItem = () => {
    setIsDeleteModalVisible(false);
    dispatch(clearCurtain());
  };

  const onDeleteHandler = () => {
    dispatch(
      deleteLineItem(
        {
          orderId,
          lineItemId: activeLineItemID,
        },
        onSuccessDeleteLineItem
      )
    );
  };

  useEffect(() => {
    if (activeLineItemID) {
      setLineItemDataLoading(true);

      if (isOrderSalesMaterial && customProductLine) {
        dispatch(
          getOrderLineItemSMData(
            {
              orderId,
              orderLineItemId: activeLineItemID,
              productLineId: customProductLine.id,
            },
            setLineItemDataLoading
          )
        );
      } else {
        dispatch(
          getOrderLineItemData(
            {
              orderId,
              orderLineItemId: activeLineItemID,
            },
            setLineItemDataLoading
          )
        );
      }
    }
  }, [activeLineItemID]);

  return (
    <>
      <ConfirmationModal
        cancel={() => setIsDeleteModalVisible(false)}
        confirm={onDeleteHandler}
        opened={isDeleteModalVisible}
        message="Are you sure you want to delete this line item? Keep in mind that collaboration made on this line item will not be deleted."
        title="Confirmation"
      />

      <CurtainHeader flex middle between>
        <H3>Item Details</H3>
        <CloseButton onClick={() => dispatch(clearCurtain())}>
          <CloseIcon />
        </CloseButton>
      </CurtainHeader>

      {orderLineItemData && !lineItemDataLoading && (
        <>
          <ButtonsContainer flex middle center>
            <StyledButton
              onClick={editLineItemHandler}
              iconColor={silverChaliceTwo}
              hoverIconColor={lynch}
            >
              {!canEdit ? <ViewIcon /> : <EditIcon />}
              <Spacer h="8px" />
              <P fontSize={12} color={nevada}>
                {!canEdit ? 'View' : 'Edit'}
              </P>
            </StyledButton>
            <Spacer w="42px" />
            <StyledButton
              onClick={replaceLineItemHandler}
              iconColor={silverChaliceTwo}
              hoverIconColor={lynch}
              disabled={!canEdit}
            >
              <ReplaceIcon />
              <Spacer h="8px" />
              <P fontSize={12} color={nevada}>
                Replace
              </P>
            </StyledButton>
            <Spacer w="42px" />
            <StyledButton
              onClick={duplicateLineItemHandler}
              iconColor={silverChaliceTwo}
              hoverIconColor={lynch}
              disabled={!canEdit}
            >
              <DuplicateIcon />
              <Spacer h="8px" />
              <P fontSize={12} color={nevada}>
                Duplicate
              </P>
            </StyledButton>
            <Spacer w="42px" />
            <StyledButton
              onClick={() => setIsDeleteModalVisible(true)}
              iconColor={silverChaliceTwo}
              hoverIconColor={lynch}
              disabled={!canEdit}
            >
              <DeleteIcon />
              <Spacer h="8px" />
              <P fontSize={12} color={nevada}>
                Delete
              </P>
            </StyledButton>
          </ButtonsContainer>
          <Divider />

          <ContentContainerWrapper>
            <ScrollbarsCustom
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <ContentContainer>
                <Wrapper flex>
                  <ImageWrapper flex>
                    {orderLineItemData.imageUrl !== '' ? (
                      <img src={orderLineItemData.imageUrl} alt="" />
                    ) : (
                      <SVG icon={<PlatoLogoIcon height={94} width={89} />} />
                    )}
                  </ImageWrapper>
                  <Spacer w="16px" />
                  <Wrapper flex column>
                    <H5>{orderLineItemData.code}</H5>
                    <Spacer h="4px" />
                    <P fontSize={14} color={nevada}>
                      {orderLineItemData.description}
                    </P>
                  </Wrapper>
                </Wrapper>
                <Spacer h="42px" />

                {!isOrderSalesMaterial && (
                  <>
                    <H5>Dimensions</H5>
                    <Spacer h="24px" />

                    <Wrapper flex>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Width
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.width}</H6>
                      </Wrapper>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Height
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.height}</H6>
                      </Wrapper>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Depth
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.depth}</H6>
                      </Wrapper>
                    </Wrapper>
                    <Spacer h="16px" />

                    <Wrapper flex>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Right Leg Width
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.rightLegWidth}</H6>
                      </Wrapper>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Right Leg Depth
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.rightLegDepth}</H6>
                      </Wrapper>
                    </Wrapper>
                    <Spacer h="16px" />

                    <Wrapper flex>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          DimUp
                        </P>
                        <Spacer h="4px" />
                        <H6>{orderLineItemData.dimUpcharge ?? ''}</H6>
                      </Wrapper>
                    </Wrapper>
                    <Spacer h="42px" />
                    <H5>Door Counts</H5>
                    <Spacer h="24px" />

                    <Wrapper flex>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Wall
                        </P>
                        <Spacer h="4px" />
                        {orderLineItemData.wallDoorCodes.map(
                          (doorCode, index) => (
                            <DoorCodeWrapper
                              doorCode={doorCode}
                              key={`${doorCode.code}-${index.toString()}`}
                            />
                          )
                        )}
                      </Wrapper>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Base
                        </P>
                        <Spacer h="4px" />
                        {orderLineItemData.baseDoorCodes.map(
                          (doorCode, index) => (
                            <DoorCodeWrapper
                              doorCode={doorCode}
                              key={`${doorCode.code}-${index.toString()}`}
                            />
                          )
                        )}
                      </Wrapper>
                      <Wrapper wThird>
                        <P fontSize={14} color={nevada}>
                          Drawer
                        </P>
                        <Spacer h="4px" />
                        {orderLineItemData.drawerDoorCodes.map(
                          (doorCode, index) => (
                            <DoorCodeWrapper
                              doorCode={doorCode}
                              key={`${doorCode.code}-${index.toString()}`}
                            />
                          )
                        )}
                        {/* TODO check<PXSmall>Solid Lumber (1)</PXSmall> */}
                      </Wrapper>
                    </Wrapper>
                    <Spacer h="42px" />
                  </>
                )}

                <H5>Prices</H5>
                <Spacer h="24px" />

                <Wrapper flex>
                  <Wrapper wThird>
                    <P fontSize={14} color={nevada}>
                      Quantity
                    </P>
                    <Spacer h="4px" />
                    <H6>{orderLineItemData.quantity}</H6>
                  </Wrapper>
                  <Wrapper wThird>
                    <P fontSize={14} color={nevada}>
                      Unit $
                    </P>
                    <Spacer h="4px" />
                    <H6>${orderLineItemData.unitPrice}</H6>
                  </Wrapper>
                  <Wrapper wThird>
                    <P fontSize={14} color={nevada}>
                      Total $
                    </P>
                    <Spacer h="4px" />
                    <H6>${orderLineItemData.totalPrice}</H6>
                  </Wrapper>
                </Wrapper>
              </ContentContainer>
            </ScrollbarsCustom>
          </ContentContainerWrapper>
        </>
      )}

      {!orderLineItemData && lineItemDataLoading && (
        <LoaderFullScreen flex middle center top={72} bottom={71}>
          <Loader size={40} />
        </LoaderFullScreen>
      )}

      {!orderLineItemData && !lineItemDataLoading && (
        <EmptyState>
          Please select line item in order to see it&apos;s details.
        </EmptyState>
      )}
    </>
  );
};
