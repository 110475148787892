import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary } from 'shared/components/Button';
import { Divider, Spacer } from 'shared/components/Layout';
import { Form } from 'shared/components/Form';
import { H6, P } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { onlyDigitValidation } from 'shared/validations/validations';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';

import { updateDealershipCustomPriceVariables } from '../store/dealershipActions';

export interface PriceVariablesForm {
  salesTax: number;
  grossProfit: number;
}

const GridTableRow = styled.div`
  display: grid;
  grid-template-columns: 145px 1fr;
  gap: 40px;
`;

const H6Styled = styled(H6)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: 15px;
  color: ${({ theme }) => theme.mineShaft};
`;

const PStyled = styled(P)`
  font-size: 12px;
  line-height: 16px;
`;

const StyledDivider = styled(Divider)`
  margin: 40px 0;
`;

const InputPercentWrapper = styled.div`
  position: relative;

  > ${Input} {
    padding-right: 50px;
  }

  &::after {
    content: '%';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.lynch};
  }
`;

export const DealershipPricingForm = () => {
  const dispatch = useAppDispatch();

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const { register, handleSubmit, formState, reset } =
    useForm<PriceVariablesForm>({
      mode: 'onChange',
      defaultValues: {
        salesTax: undefined,
        grossProfit: undefined,
      },
    });

  const onSucessSubmit = () => {
    toast.success('You have successfully updated price variables.');
  };

  const onFailedSubmit = () => {
    toast.error(
      "Price variables couldn't be updated at this moment. Try again later."
    );
  };

  const onSubmit = (data: PriceVariablesForm) => {
    if (dealership) {
      dispatch(
        updateDealershipCustomPriceVariables(
          {
            dealershipId: dealership.id,
            grossProfit: data.grossProfit,
            salesTax: data.salesTax,
          },
          onSucessSubmit,
          onFailedSubmit
        )
      );
    }
  };

  useEffect(() => {
    if (dealership) {
      reset({
        grossProfit: dealership.grossProfit,
        salesTax: dealership.salesTax,
      });
    }
  }, [dealership]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <GridTableRow>
          <H6Styled>Sales Tax</H6Styled>

          <div>
            <InputPercentWrapper>
              <Input
                {...register(
                  'salesTax',
                  onlyDigitValidation({ required: true, min: 0, max: 20 })
                )}
                type="number"
                min="0"
                max="20"
                step=".001"
                aria-invalid={formState.errors.salesTax ? 'true' : 'false'}
                placeholder={dealership!.grossProfit.toString()}
              />
            </InputPercentWrapper>

            <FormError
              label="Sales Tax"
              error={formState.errors.salesTax}
              validationSchema={onlyDigitValidation({
                required: true,
                min: 0,
                max: 20,
              })}
            />

            <Spacer h="12px" />

            <PStyled>
              Enter local sales tax rate percentage. If local sales tax is 6.5%,
              enter 6.50. Leave blank if there is no local sales tax.
            </PStyled>
          </div>
        </GridTableRow>

        <StyledDivider />

        <GridTableRow>
          <H6Styled>Gross Margin</H6Styled>
          <div>
            <InputPercentWrapper>
              <Input
                {...register(
                  'grossProfit',
                  onlyDigitValidation({ required: true, min: 0, max: 99.99 })
                )}
                type="number"
                min="0"
                max="100"
                step=".01"
                aria-invalid={formState.errors.grossProfit ? 'true' : 'false'}
                placeholder={dealership!.grossProfit.toString()}
              />
            </InputPercentWrapper>

            <FormError
              label="Gross Profit"
              error={formState.errors.grossProfit}
              validationSchema={onlyDigitValidation({
                required: true,
                min: 0,
                max: 99.99,
              })}
            />

            <Spacer h="12px" />

            <PStyled>
              Enter the gross profit margin desired on jobs. This amount will
              automatically be carried to the appropriate field on the Price
              Summary page.
            </PStyled>
          </div>
        </GridTableRow>

        <Spacer h="35px" />

        <Wrapper flex middle justifyEnd>
          <ButtonPrimary type="submit">Save Changes</ButtonPrimary>
        </Wrapper>
      </div>
    </Form>
  );
};
