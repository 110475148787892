import { Location } from 'history';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import { getOrderStyles } from 'order/store/orderActions';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylesModal } from 'order/wizard/orderStyles/OrderStylesModal';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';
import OrderStyle from 'order/components/OrderStyle/OrderStyle';

import {
  getStyle,
  setStylesModalOpened,
} from 'order/wizard/orderStyles/productLines/store/orderStylesActions';

import { testId } from 'tests/utils';

import {
  OrderStylesTestButtonEnum,
  OrderStylesTestTooltipEnum,
} from 'tests/enums/OrderStylesTestEnums';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { H2 } from 'shared/components/Typography';
import { Info } from 'shared/components/Info';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import { StyleModalEnum } from './enums/StyleModalEnum';
import { OrderStylesModalParams } from './interface/OrderStylesModalParams';
import OrderImportModal from './OrderImportModal';
import { getPopups } from '../../popups/store/popupActions';
import { setImportStylesStep } from '../import/store/ImportActions';

interface StateProps {
  orderCreated: boolean;
}

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 10px;
`;

const OrderStyles = () => {
  const location: Location<StateProps> = useLocation();
  const history = useHistory();
  const { orderId } = useParams<OrderPageParams>();
  const dispatch = useAppDispatch();

  const [queryFields] = useQueryParams<OrderStylesModalParams>(['styleId']);

  const [loading, setLoading] = useState(false);

  const { order, canEdit } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const { selectedStyle: selectedStyleForImport } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const orderStyles = useSelector(
    (state: RootState) => state.orderReducer.styles
  );

  const popups = useSelector((state: RootState) => state.popupReducer.popups);

  const orderStylesLimitReached = (orderStyles?.length ?? 0) >= 9;

  const modalOpenedForStyleId = useSelector(
    (state: RootState) => state.orderStylesReducer.modalOpenedForStyleId
  );

  const onOrderCreatedHandler = () => {
    if (location.state?.orderCreated) {
      toast.success('Order has been successfully created. Add some styles.');

      const stateWithoutOrderCreated = UtilService.omit(location.state, [
        'orderCreated',
      ]);

      history.replace(`${location.pathname}`, stateWithoutOrderCreated);
    }
  };

  const onStyleModalOpen = () => {
    dispatch(setStylesModalOpened(StyleModalEnum.CREATE_NEW_STYLE));
  };

  const onImportStyleModalOpen = () => {
    dispatch(setStylesModalOpened(StyleModalEnum.IMPORT_STYLE));
    dispatch(setImportStylesStep(1));
  };

  const loadOrderStyles = () => {
    setLoading(true);
    dispatch(getOrderStyles(orderId, setLoading));
  };

  useEffect(() => {
    loadOrderStyles();
    if (!popups) {
      dispatch(getPopups());
    }
  }, []);

  useEffect(() => {
    if (!order) {
      onOrderCreatedHandler();
    }

    if (
      order &&
      queryFields?.styleId &&
      modalOpenedForStyleId !== StyleModalEnum.CREATE_NEW_STYLE
    ) {
      dispatch(
        getStyle(
          {
            orderId,
            styleId: queryFields.styleId,
          },
          () => dispatch(setStylesModalOpened(queryFields.styleId))
        )
      );
    }
  }, [order?.id]);

  useWindowScrollPosition(orderStyles !== null);

  return (
    <div>
      <H2>List of styles</H2>

      <Spacer h="40px" />

      {orderStyles?.map((orderStyle) => (
        <OrderStyle
          key={orderStyle.id}
          marginBottom={orderStyle.override ? 0 : 16}
          style={orderStyle}
          loadOrderStyles={loadOrderStyles}
        />
      ))}

      {loading && !orderStyles?.length && <Loader size={50} />}

      {!loading && !orderStyles?.length && orderStyles !== null && (
        <EmptyState
          title="No styles defined yet!"
          icon={<PaletteIcon />}
          iconSize={80}
          marginTop={0}
        />
      )}

      {orderStylesLimitReached && (
        <>
          <Spacer h="50px" />
          <Wrapper flex center>
            <Info
              type="warning"
              fieldTestId={OrderStylesTestTooltipEnum.STYLES_LIMIT_TTP}
            >
              Style limit (9) has been reached. In order to add a new style to
              this order, you would need to remove an existing one.
            </Info>
          </Wrapper>
          <Spacer h="50px" />
        </>
      )}

      <ModalWrapper
        open={
          modalOpenedForStyleId === StyleModalEnum.CREATE_NEW_STYLE ||
          modalOpenedForStyleId === StyleModalEnum.CONVERT_STYLE
        }
        className="modal--full-screen"
        closeOnDocumentClick={false}
        lockScroll
        modal
        closeOnEscape={false}
      >
        <OrderStylesModal />
      </ModalWrapper>

      <ModalWrapper
        open={
          modalOpenedForStyleId === StyleModalEnum.IMPORT_STYLE ||
          !!selectedStyleForImport
        }
        closeOnDocumentClick={false}
        lockScroll
        closeOnEscape={false}
        modal
      >
        <OrderImportModal />
      </ModalWrapper>

      <OrderLogs />

      <OrderFooter>
        <Wrapper flex middle justifyEnd>
          <Wrapper withTooltip>
            <ButtonSecondary
              onClick={onImportStyleModalOpen}
              disabled={!canEdit || orderStylesLimitReached}
              {...testId(
                OrderStylesTestButtonEnum.IMPORT_STYLE_FROM_ANOTHER_ORDER_BTN
              )}
            >
              Import style from another order
            </ButtonSecondary>

            {orderStylesLimitReached && <Tooltip>Style limit reached.</Tooltip>}
          </Wrapper>

          <Spacer w="32px" />

          <Wrapper withTooltip>
            <ButtonPrimary
              onClick={onStyleModalOpen}
              disabled={
                !canEdit || orderStylesLimitReached || !!modalOpenedForStyleId
              }
              {...testId(OrderStylesTestButtonEnum.CREATE_STYLE_BTN)}
            >
              <StyledPlusIcon />
              Create a style
            </ButtonPrimary>

            {orderStylesLimitReached && <Tooltip>Style limit reached.</Tooltip>}
          </Wrapper>
        </Wrapper>
      </OrderFooter>
    </div>
  );
};

export default OrderStyles;
