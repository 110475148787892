import {
  INotification,
  NotificationTypeEnums,
} from 'overview/interface/Notification';
import { activateDealership } from 'overview/store/overviewActions';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import FormError from 'shared/components/FormError';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import Loader from 'shared/components/Loader';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Dealership } from 'shared/interface/Dealership';
import { onlyWholeDigitValidation } from 'shared/validations/validations';
import { RootState } from 'store';

interface ActivateDealershipForm {
  accountNumber: string;
}
interface ActivateDealershipModalProps {
  opened: boolean;
  close: () => void;
  loadDealerships: () => void;
}

export const ActivateDealershipModal: FC<ActivateDealershipModalProps> = ({
  opened,
  close,
  loadDealerships,
}: ActivateDealershipModalProps) => {
  const dispatch = useAppDispatch();

  const dealerships: Dealership[] | null = useSelector(
    (state: RootState) => state.overviewReducer.dealerships
  );

  const activateDealershipResponse: INotification | null = useSelector(
    (state: RootState) => state.overviewReducer.activateDealershipResponse
  );

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, formState, setError } =
    useForm<ActivateDealershipForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        accountNumber: '',
      },
    });

  const onClose = () => {
    setLoading(false);
    reset();
    close();
  };

  const onSubmit = (data: ActivateDealershipForm) => {
    if (dealerships?.some((x) => x.accountNumber === +data.accountNumber)) {
      setError('accountNumber', {
        type: 'validate',
        message: 'This dealership has already been activated.',
      });
      return;
    }

    setLoading(true);
    dispatch(activateDealership(+data.accountNumber));
  };

  useEffect(() => {
    if (activateDealershipResponse) {
      if (
        activateDealershipResponse.type ===
        NotificationTypeEnums.ACTIVATE_DEALERSHIP_SUCCESS
      ) {
        toast.success(activateDealershipResponse.content);
        loadDealerships();
        onClose();
      }

      if (
        activateDealershipResponse.type ===
        NotificationTypeEnums.ACTIVATE_DEALERSHIP_FAILED
      ) {
        toast.error(activateDealershipResponse.content);
        onClose();
      }
    }
  }, [activateDealershipResponse]);

  return (
    <ModalWrapper
      closeOnEscape={false}
      closeOnDocumentClick={false}
      lockScroll
      modal
      onClose={onClose}
      open={opened}
    >
      {() => (
        <Modal close={onClose} title="Activate New Dealership" small>
          <P fontSize={16} color={nevada}>
            By providing the dealership&apos;s account number, the dealership
            will become active in Plato Connect.
          </P>
          <Spacer h="32px" />
          <Form>
            <FormElement noMarginBottom>
              <FormLabel>Dealership Account Number</FormLabel>
              <Input
                {...register(
                  'accountNumber',
                  onlyWholeDigitValidation(
                    { required: true },
                    'Dealership Account Number'
                  )
                )}
                placeholder="Enter dealership number"
                aria-invalid={formState.errors.accountNumber ? 'true' : 'false'}
              />
              <FormError
                error={formState.errors.accountNumber}
                label="Dealership Account Number"
                validationSchema={onlyWholeDigitValidation(
                  { required: true },
                  'Dealership Account Number'
                )}
              />
            </FormElement>
          </Form>

          <Spacer h="32px" />

          <Wrapper flex middle justifyEnd>
            <ButtonSecondary onClick={close}>Cancel</ButtonSecondary>
            <Spacer w="15px" />
            <ButtonPrimary
              disabled={loading || !formState.isValid}
              onClick={handleSubmit(onSubmit)}
            >
              Activate
              <Loader insideButton size={16} noSpacing hidden={!loading} />
            </ButtonPrimary>
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};
