import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';

export const DoorCodesTableRow = styled(Wrapper)`
  > *:first-child {
    width: 144px;
  }
`;

export const DoorCodesTableRowContent = styled(Wrapper)`
  > *:not(span) {
    width: 90px;
    text-align: right;
  }
`;
