import { RootState } from 'store';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  ChangeEvent,
  FC,
  HtmlHTMLAttributes,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactComponent as IconSearch } from 'assets/icons/search.svg';

import { Collaborator } from 'order/interfaces/Collaborator';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';

import {
  removeDealerCollaboratos,
  searchCollaborators,
  selectCollaborator,
  setSearchedCollaborators,
} from 'order/store/orderActions';

import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { User } from 'shared/interface/User';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import EmptyState from 'shared/components/EmptyState';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SearchInput from 'shared/components/SearchInput';
import UserInfo from 'shared/components/UserInfo';

const OrderCollaboratorsContainer = styled.div``;

const CollaboratorList = styled.div`
  > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SearchHint = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: ${({ theme }) => theme.nevada};
`;

const CollaboratorWrapper = styled.div<HtmlHTMLAttributes<HTMLDivElement>>`
  border: none;
  box-shadow: none;
  background: none;
  margin-bottom: 20px;
  cursor: pointer;

  &[aria-disabled='true'] {
    opacity: 0.5;
    transition: opacity 300ms ease;
    pointer-events: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

interface OrderCollaboratorsProps {
  clearSearchedCollaborators: boolean;
}

const OrderCollaborators: FC<OrderCollaboratorsProps> = ({
  clearSearchedCollaborators,
}) => {
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    dealershipId,
    csrSelectedDealershipUserId,
    handleChangeCallbackWrapper,
  } = useContext(OrderFormContext);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const orderJobDealershipId = useSelector(
    (state: RootState) => state.orderReducer.order?.job?.dealershipId
  );

  const searchedCollaborators = useSelector(
    (state: RootState) => state.orderReducer.searchedCollaborators
  );

  const selectedCollaborators = useSelector(
    (state: RootState) => state.orderReducer.selectedCollaborators
  );

  const loggedInUserId = useSelector(
    (state: RootState) => state.authReducer.user?.id
  );

  const createOrderOnBehalfOfDealer = useHasPermissions(
    userPermissionsValues.ORDER_CREATE_DRAFT_ON_BEHALF_OF_DEALER
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onSearchCollaboratorsHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchCollaboratorsHandler, 500),
    []
  );

  const resetSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      // searchInputRef.current.focus();
    }
  };

  const onCollaboratorAddHandler = (user: User) => {
    const collaborator: Collaborator = {
      ...user,
      collaboratorType: collaboratorAccessEnums.EDITOR,
    };

    dispatch(selectCollaborator(collaborator));
    dispatch(setSearchedCollaborators(null));
    setSearchQuery('');

    resetSearchInput();
  };

  useEffect(() => {
    if (searchQuery.length >= 3 && dealershipId) {
      setLoading(true);
      dispatch(
        searchCollaborators(
          {
            dealershipId,
            searchTerm: searchQuery,
          },
          setLoading
        )
      );
    } else {
      dispatch(setSearchedCollaborators(null));
    }
  }, [searchQuery]);

  const onClearSearchedCollaboratorsHandler = () => {
    dispatch(setSearchedCollaborators(null));
    setSearchQuery('');
    resetSearchInput();
  };

  useEffect(() => {
    if (clearSearchedCollaborators) {
      onClearSearchedCollaboratorsHandler();
    }
  }, [clearSearchedCollaborators, searchInputRef]);

  // filter out all collaborators dealers that are not in selected dealership.
  useEffect(() => {
    if (!dealershipId) return;

    if (dealershipId !== orderJobDealershipId) {
      onClearSearchedCollaboratorsHandler();
      dispatch(removeDealerCollaboratos());
    }
  }, [dealershipId]);

  return (
    <OrderCollaboratorsContainer>
      <SearchInput
        data-role="search"
        ref={searchInputRef}
        data-loading={loading}
        data-test="input-collaboratorsSearch"
        placeholder="Search people by name or email"
        onSearchQueryChanged={debouncedChangeHandler}
        disabled={!canEdit || !dealershipId}
        tabIndex={-1}
      />

      {searchQuery.length > 0 && searchQuery.length < 3 && (
        <SearchHint>Type at least 3 characters to get results</SearchHint>
      )}

      {searchedCollaborators && <Spacer h="30px" />}

      <ScrollbarsCustom
        autoHeight
        autoHeightMin={0}
        autoHeightMax={330}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
      >
        {searchedCollaborators && (
          <CollaboratorList>
            {searchedCollaborators
              .filter((collab) =>
                createOrderOnBehalfOfDealer
                  ? collab.id !== csrSelectedDealershipUserId
                  : collab.id !== loggedInUserId
              )
              .map((user: User) => (
                <CollaboratorWrapper
                  aria-disabled={
                    selectedCollaborators
                      ? selectedCollaborators?.some(
                          (collaborator) => collaborator.id === user.id
                        )
                      : false
                  }
                  onClick={() =>
                    handleChangeCallbackWrapper?.(() =>
                      onCollaboratorAddHandler(user as Collaborator)
                    )
                  }
                  key={user.id}
                >
                  <UserInfo user={user} disabled={!canEdit} />
                </CollaboratorWrapper>
              ))}
          </CollaboratorList>
        )}
      </ScrollbarsCustom>

      {searchedCollaborators && !searchedCollaborators.length && (
        <EmptyState
          title="No collaborators found"
          icon={<IconSearch />}
          message="Try searching for someone else."
        />
      )}
    </OrderCollaboratorsContainer>
  );
};

export default OrderCollaborators;
