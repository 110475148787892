import { logoutSagaAction } from 'auth/store/authActions';

import { useHistory } from 'react-router';
import { Token } from 'shared/interface/Token';
import { useAppDispatch } from './useAppDispatch';
import { useBrowserStorage } from './useBrowserStorage';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { value, removeItem } = useBrowserStorage<Token>({
    key: 'token',
    storageType: 'localStorage',
  });

  const redirectToLogin = () => {
    history.push('/authentication/login');
  };

  const logout = () => {
    dispatch(logoutSagaAction(value, removeItem, redirectToLogin));
  };

  return logout;
};
