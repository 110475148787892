import styled from 'styled-components';

export const RadioColor = styled.button<{ isDisabled: boolean }>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 32px;
  margin-bottom: 5px;
  margin-right: 16px;
  position: relative;
  width: 32px;

  .icon {
    align-items: center;
    display: flex;
    justify-content: center;

    > svg {
      height: auto;
      width: 50%;
    }
  }

  img {
    border-radius: 50%;
  }

  &.active {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    border-radius: 50%;
    bottom: 2px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.lynch},
      0 0 0 2px ${({ theme }) => theme.lynch};
    content: '';
    left: 2px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: 300ms;
  }
  ${({ isDisabled }) => isDisabled && `pointer-events:none; opacity:0.4;`}
`;
