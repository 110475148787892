import StaticField from 'order/components/StaticField/StaticField';
import { useSelector } from 'react-redux';
import { Spacer } from 'shared/components/Layout';
import { H3 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { RootState } from 'store';

export const Inovae20OverviewDoorBuilder = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const config = useProductLineConfiguration();

  return (
    <Wrapper flexGrow>
      <H3>Door style</H3>
      <Spacer h="30px" />

      <StaticField
        field="Door overlay"
        value={storedStyle?.doorOverlay?.name}
      />

      <StaticField field="Frame style" value={storedStyle?.frameStyle?.name} />

      {config.doorBuilder.showInteriorNotes && (
        <StaticField
          field="Interior notes"
          value={storedStyle?.interiorNotes}
        />
      )}

      <StaticField
        field="Door Style"
        value={`Wall: ${storedStyle?.wallDoorStyle?.name}<br/>Base: ${storedStyle?.baseDoorStyle?.name}<br/>Drawer: ${storedStyle?.materialDrawer?.name}`}
      />

      <StaticField
        field="Door Edge Band"
        value={storedStyle?.doorEdgeBand?.name}
      />

      <StaticField field="Door notes" value={storedStyle?.doorNotes} />

      <StaticField field="Drawer box" value={storedStyle?.drawerBox?.name} />

      <StaticField
        field="Toe Space Type"
        value={storedStyle?.toeSpaceType?.name}
      />

      <Spacer h="20px" />

      <H3>Hardware</H3>

      <Spacer h="30px" />

      <StaticField field="Hinge Type" value={storedStyle?.hingeType?.name} />

      <StaticField field="Hinge Color" value={storedStyle?.hingeColor?.name} />

      <StaticField
        field="Door Hardware"
        value={storedStyle?.doorHardware?.name}
      />

      <StaticField
        field="Drawer Hardware"
        value={storedStyle?.drawerHardware?.name}
      />

      {config.doorBuilder.showClosetHardwareColor && (
        <StaticField
          field="Closet Hardware Color"
          value={storedStyle?.closetHardwareColor?.name}
        />
      )}

      <StaticField field="Hardware notes" value={storedStyle?.hardwareNotes} />
    </Wrapper>
  );
};
