import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderFormFields } from 'order/wizard/orderForm/OrderForm';

import { H2, P } from 'shared/components/Typography';
import { OrderShipToEnums } from 'order/enums/orderEnums';
import { RadioButton } from 'shared/components/RadioButton';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

import OrderCustomAddress from './OrderCustomAddress/OrderCustomAddress';
import OrderDefaultAddress from './OrderDefaultAddress/OrderDefaultAddress';
import { OrderFormContext } from '../../wizard/orderForm/OrderFormContext/orderFormContext';

const TransportContainer = styled.div`
  margin-top: 30px;
`;

interface OrderTransportationProps {
  addressSelected: (addresId: string | null) => void;
}

const OrderTransportation: FC<OrderTransportationProps> = ({
  addressSelected,
}) => {
  const methodsContext = useFormContext<OrderFormFields>();

  const { handleChangeCallbackWrapper, isCSRSelectedDealershipDirty } =
    useContext(OrderFormContext);

  const selectedShippingAddress = useSelector(
    (state: RootState) => state.orderReducer.selectedShippingAddress
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );

  const [selectedShipToOption, setSelectedShipToOption] = useState<string>();

  const onExistingAddressChangeHandler = (addressId: string | null) => {
    setSelectedAddressId(addressId);
  };

  const onChangeShipToOptionHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedShipToOption(e.target.value);

    // reset job site delivery field when type of the address is changed (default / custom)
    methodsContext.setValue('jobSiteDelivery', false);

    if (e.target.value === (OrderShipToEnums.CUSTOM_ADDRESS as string)) {
      setSelectedAddressId(null);
    }
  };

  useEffect(() => {
    addressSelected(selectedAddressId);
  }, [selectedAddressId]);

  useEffect(() => {
    if (selectedShippingAddress) {
      if (
        !isCSRSelectedDealershipDirty &&
        selectedShippingAddress.originalAddressId
      ) {
        setSelectedAddressId(selectedShippingAddress.originalAddressId);
      }

      setSelectedShipToOption(
        selectedShippingAddress.shippingAddressType!.toString() ===
          (OrderShipToEnums.NEW_CUSTOM as string)
          ? OrderShipToEnums.CUSTOM_ADDRESS
          : selectedShippingAddress.shippingAddressType!.toString()
      );

      return;
    }

    setSelectedShipToOption(OrderShipToEnums.DEFAULT_ADDRESS);
  }, [selectedShippingAddress]);

  return (
    <TransportContainer>
      <H2>Ship to</H2>

      <Spacer h="10px" />
      <P>
        Select one of your provided addresses or enter a custom shipping address
      </P>

      <Spacer h="30px" />

      <Wrapper flex middle>
        <RadioButton
          checked={
            selectedShipToOption ===
            (OrderShipToEnums.DEFAULT_ADDRESS as string)
          }
          className="boxed"
          id={`shipTo--${OrderShipToEnums.DEFAULT_ADDRESS}`}
          name="shipTo"
          onChange={(e) =>
            handleChangeCallbackWrapper?.(() => onChangeShipToOptionHandler(e))
          }
          title="Select from default addresses"
          value={OrderShipToEnums.DEFAULT_ADDRESS}
          disabled={!canEdit}
        />
        <Spacer w="20px" />
        <RadioButton
          checked={
            selectedShipToOption ===
            ((OrderShipToEnums.CUSTOM_ADDRESS as string) ||
              (OrderShipToEnums.NEW_CUSTOM as string))
          }
          className="boxed"
          id={`shipTo--${OrderShipToEnums.CUSTOM_ADDRESS}`}
          name="shipTo"
          onChange={(e) =>
            handleChangeCallbackWrapper?.(() => onChangeShipToOptionHandler(e))
          }
          title="Select or add custom shipping address"
          value={OrderShipToEnums.CUSTOM_ADDRESS}
          disabled={!canEdit}
        />
      </Wrapper>

      <Spacer h="30px" />

      {selectedShipToOption ===
        (OrderShipToEnums.DEFAULT_ADDRESS as string) && (
        <OrderDefaultAddress
          existingAddressChange={onExistingAddressChangeHandler}
        />
      )}

      {selectedShipToOption ===
        ((OrderShipToEnums.CUSTOM_ADDRESS as string) ||
          (OrderShipToEnums.NEW_CUSTOM as string)) && (
        <OrderCustomAddress
          existingAddressChange={onExistingAddressChangeHandler}
        />
      )}
    </TransportContainer>
  );
};

export default OrderTransportation;
