import { ReactNode } from 'react';
import styled from 'styled-components';

interface FormLabelProps {
  children: ReactNode;
}

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.nevada};
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
`;

export const FormLabel = ({ children }: FormLabelProps) => {
  return <StyledLabel>{children}</StyledLabel>;
};
