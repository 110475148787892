export enum OrderTypeEnums {
  'Standard' = 1,
  'Sales Aids' = 2,
  'Display' = 3,
  'Misc. Std.' = 4,
  'CM Standard' = 5,
  'Personal' = 6,
  'Net' = 7,
  'Misc. Net' = 8,
  'CM Net' = 9,
  'Preorder' = 10,
  'Quote' = 11,
}

export enum OrderStylizationTypeEnums {
  NONE = 0,
  DOOR_SAMPLE_BASE = 1,
  SALES_MATERIAL = 2,
}

export enum CatalogOrderType {
  NON_SALES_AIDS = 1,
  SALES_MATERIAL = 2,
  DOOR_SAMPLE_BASE = 3,
}

export enum OrderTransportationTypeEnums {
  NONE = '0',
  WILL_CALL = '1',
  PLATO_CONTRACTED_TRANSPORTATION = '2',
  FEDEX_UPS = '3',
}

export enum OrderDescriptionTypeEnums {
  REMODELED = '1',
  NEW_CONSTRUCTION = '2',
}

export enum OrderTabsEnums {
  OVERVIEW = 'overview',
  ADJUSTMENTS = 'adjustments',
  STYLES = 'styles',
  LINE_ITEMS = 'line-items',
  ATTACHMENTS = 'attachments',
  ORDER_OVERVIEW = 'order-overview',
  REVIEW_PRICING = 'review-pricing',
  ACKNOWLEDGMENT = 'acknowledgment',
}

export enum OrderFlows {
  DRAFT = 'DRAFT',
  DRAFT_EDIT = 'DRAFT_EDIT',
}

export enum OrderFlowOptions {
  NEW_DRAFT = 'createNewDraftOption',
  NEW_ASAP = 'createNewAsapOption',
  CONTINUE_EXISTING = 'continueExistingOrderDraft',
}

export enum OrderShipToEnums {
  DEFAULT_ADDRESS = '1',
  CUSTOM_ADDRESS = '2',
  NEW_CUSTOM = '3',
}

export enum DefaultAddressesLabels {
  Mailing = 'Mailing address',
  ShowRoom = 'Show room',
  TruckShipping = 'Truck shipping',
  UpsOrFedEx = 'Ups FedEx',
  CustomShipping = 'Custom Shipping',
}

export enum AddressType {
  Mailing = 1,
  ShowRoom = 2,
  TruckShipping = 3,
  UpsOrFedEx = 4,
  CustomShipping = 5,
}

export enum OrderStatusEnums {
  'Not Submitted' = 1,
  'Received',
  'Writing',
  'Taken For Processing',
  'Written',
  'Second Checked',
  'Drawing',
  'Taken For Drawing',
  'Drawing Completed',
  'Acknowledged',
  'Approved',
  'Tech Edit Started',
  'Released',
  'Canceled',
  'Invoiced',
}

export enum QuoteStatusEnums {
  'Not Submitted' = 1,
  'Received',
  'Writing',
  'Acknowledged',
}

export enum GradingOption {
  A = 1,
  B,
  C,
  P,
}

export enum AssigneeOptionsEnum {
  UNASSIGNED = '00000000-0000-0000-0000-000000000000',
}
