import { FC } from 'react';

import { NetUpchargesPriceReview } from 'order/wizard/orderStyles/interface/OrderPriceReview';

import { H6, PSmall } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { ContainerSmall, ContainerXSmall } from 'shared/components/Container';

interface NetUpchargeRowProps {
  isListPriceSelected: boolean;
  upcharge?: NetUpchargesPriceReview;
  title?: string;
}

const NetUpchargeRow: FC<NetUpchargeRowProps> = ({
  isListPriceSelected,
  upcharge,
  title = 'upcharge',
}) => {
  return (
    <Wrapper flex middle>
      <Wrapper align="left" minWidth={150} isTableCell flexGrow>
        <H6>{title.toUpperCase()}</H6>
      </Wrapper>

      <ContainerXSmall isTableCell border={['right']}>
        <PSmall>{upcharge?.percentage ?? 0}%</PSmall>
      </ContainerXSmall>

      <ContainerSmall isTableCell>
        <PSmall>
          {isListPriceSelected
            ? upcharge?.listPrice.toFixed(2) ?? '0.00'
            : upcharge?.netPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>
    </Wrapper>
  );
};

export default NetUpchargeRow;
