import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonPrimary } from './Button';
import { Spacer } from './Layout';
import Loader from './Loader';
import { Modal } from './Modal';
import { ModalWrapper } from './ModalWrapper';
import { RadioButton } from './RadioButton';
import { PMedium, H4 } from './Typography';
import { Wrapper } from './Wrapper';

const OptionText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 26px;
`;

const OptionTextHeading = styled.div`
  color: ${({ theme }) => theme.mineShaftVariationTwo};
  margin-bottom: 8px;
`;

const OptionTextDescription = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  opacity: 0.5;
`;

export enum PrintOption {
  PrintWithPrices = 1,
  PrintWithoutPrices = 2,
}

export enum PrintType {
  Printer = 1,
  ExportAsPdf = 2,
}
interface PrintModalProps {
  opened?: boolean;
  onClick?: (printOption: string, printType: string) => void;
  cancel?: () => void;
}
const PrintModal: FC<PrintModalProps> = ({
  opened,
  onClick,
  cancel,
}: PrintModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showContinueBtn, setShowContinueBtn] = useState(true);
  const [selectedOption, setSelectedOption] = useState(
    PrintOption.PrintWithPrices.toString()
  );
  const [selectedType, setSelectedType] = useState(
    PrintType.Printer.toString()
  );

  const btnText =
    selectedType === PrintType.ExportAsPdf.toString() ? 'Export' : 'Print';

  useEffect(() => {
    if (opened) {
      setIsLoading(false);
      setShowContinueBtn(true);
      setSelectedOption(PrintOption.PrintWithPrices.toString());
      setSelectedType(PrintType.Printer.toString());
    }
  }, [opened]);

  const onContinueHandler = () => {
    setShowContinueBtn(false);
  };

  const onPrintOptionChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (showContinueBtn) {
      setSelectedOption(e.target.value);
    } else {
      setSelectedType(e.target.value);
    }
  };

  const onPrintHandler = () => {
    setIsLoading(true);
    if (onClick) {
      onClick(selectedOption, selectedType);
    }
  };
  return (
    <ModalWrapper
      modal
      lockScroll
      closeOnDocumentClick={false}
      open={opened}
      closeOnEscape={false}
    >
      {(close: () => void) => (
        <Modal
          title="Printing Options"
          small
          withoutFooter={1}
          close={cancel ?? close}
        >
          <PMedium>Please select one of the possible printing methods.</PMedium>
          <Spacer h="16px" />
          <Wrapper flex column>
            <RadioButton
              name="printOption"
              data-flow-option
              value={
                showContinueBtn
                  ? PrintOption.PrintWithPrices
                  : PrintType.Printer
              }
              id={`option-${PrintOption.PrintWithPrices}`}
              onChange={onPrintOptionChangeHandler}
              checked={
                showContinueBtn
                  ? selectedOption === PrintOption.PrintWithPrices.toString()
                  : selectedType === PrintType.Printer.toString()
              }
            >
              <OptionText>
                <OptionTextHeading>
                  <H4>{showContinueBtn ? 'Print With Prices' : 'Printer'}</H4>
                </OptionTextHeading>
                <OptionTextDescription>
                  {showContinueBtn
                    ? 'List of order with the prices included'
                    : 'Set up your printer for you to use this option'}
                </OptionTextDescription>
              </OptionText>
            </RadioButton>

            <Spacer h="32px" />

            <RadioButton
              name="printOption"
              data-flow-option
              value={
                showContinueBtn
                  ? PrintOption.PrintWithoutPrices
                  : PrintType.ExportAsPdf
              }
              id={`option-${PrintOption.PrintWithoutPrices}`}
              onChange={onPrintOptionChangeHandler}
              checked={
                showContinueBtn
                  ? selectedOption === PrintOption.PrintWithoutPrices.toString()
                  : selectedType === PrintType.ExportAsPdf.toString()
              }
            >
              <OptionText>
                <OptionTextHeading>
                  <H4>
                    {showContinueBtn ? 'Print Without Prices' : 'Export as PDF'}
                  </H4>
                </OptionTextHeading>
                <OptionTextDescription>
                  {showContinueBtn
                    ? 'This gives you a list of order without the pricing list'
                    : 'Create a pdf file.'}
                </OptionTextDescription>
              </OptionText>
            </RadioButton>
          </Wrapper>
          <Spacer h="32px" />
          <Wrapper flex middle justifyEnd>
            <Spacer w="16px" />
            <ButtonPrimary
              onClick={showContinueBtn ? onContinueHandler : onPrintHandler}
              disabled={isLoading}
            >
              {showContinueBtn ? 'Continue' : btnText}
              <Loader hidden={!isLoading} insideButton noSpacing size={16} />
            </ButtonPrimary>
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};

PrintModal.defaultProps = {
  opened: undefined,
  cancel: undefined,
  onClick: undefined,
};

export { PrintModal };
