import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { LineItemHardware } from 'order/wizard/orderLineItems/interface/ILineItemData';

import {
  Inovae2OStyleDoorBuilderRequest,
  ICSStyleDoorBuilderRequest,
  StyleDoorBuilder,
  StyleDoorBuilderRequest,
  StyleDoorBuilderValidationData,
} from 'order/wizard/orderStyles/interface/StyleDoorBuilder';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { DoorBuilderOptions } from '../orderStylesReducer';
import { Midrails } from '../../../interface/Midrails';
import { StyleFieldOption } from '../../../interface/StyleFieldOption';
import { DoorBuilderBreakingChange } from '../../../interface/DoorBuilderBreakingChange';

export enum orderStylesDoorBuilderActions {
  CLEAR_OPTIONS = 'CLEAR_OPTIONS',

  VALIDATE_STYLE_DOOR_BUILDER = 'VALIDATE_STYLE_DOOR_BUILDER',

  RESET_DOOR_BUILDER = 'RESET_DOOR_BUILDER',

  GET_DOOR_OVERLAYS = 'GET_DOOR_OVERLAYS',
  SET_DOOR_OVERLAYS = 'SET_DOOR_OVERLAYS',

  GET_FACE_FRAMES = 'GET_FACE_FRAMES',
  SET_FACE_FRAMES = 'SET_FACE_FRAMES',

  GET_FRAME_STYLES = 'GET_FRAME_STYLES',
  SET_FRAME_STYLES = 'SET_FRAME_STYLES',

  GET_MIDRAILS = 'GET_MIDRAILS',
  SET_MIDRAILS = 'SET_MIDRAILS',

  GET_DOOR_STYLE = 'GET_DOOR_STYLE',
  SET_DOOR_STYLE = 'SET_DOOR_STYLE',

  GET_FRAME_SIZE = 'GET_FRAME_SIZE',
  SET_FRAME_SIZE = 'SET_FRAME_SIZE',

  SET_CONFIG = 'SET_CONFIG',

  GET_ARCH_STYLE = 'GET_ARCH_STYLE',
  SET_ARCH_STYLE = 'SET_ARCH_STYLE',

  GET_INSERT_PANEL_WALL = 'GET_INSERT_PANEL_WALL',
  SET_INSERT_PANEL_WALL = 'SET_INSERT_PANEL_WALL',

  GET_INSERT_PANEL_BASE = 'GET_INSERT_PANEL_BASE',
  SET_INSERT_PANEL_BASE = 'SET_INSERT_PANEL_BASE',

  GET_DOOR_EDGE_WALL = 'GET_DOOR_EDGE_WALL',
  SET_DOOR_EDGE_WALL = 'SET_DOOR_EDGE_WALL',

  GET_DOOR_EDGE_BASE = 'GET_DOOR_EDGE_BASE',
  SET_DOOR_EDGE_BASE = 'SET_DOOR_EDGE_BASE',

  GET_METAL_FINISH = 'GET_METAL_FINISH',
  SET_METAL_FINISH = 'SET_METAL_FINISH',

  GET_DRAWER_BOX = 'GET_DRAWER_BOX',
  SET_DRAWER_BOX = 'SET_DRAWER_BOX',

  GET_HINGE_TYPE = 'GET_HINGE_TYPE',
  SET_HINGE_TYPE = 'SET_HINGE_TYPE',

  GET_HINGE_COLORS = 'GET_HINGE_COLORS',
  SET_HINGE_COLORS = 'SET_HINGE_COLORS',

  GET_DRAWER_STYLES = 'GET_DRAWER_STYLES',
  SET_DRAWER_STYLES = 'SET_DRAWER_STYLES',

  GET_DRAWER_EDGES = 'GET_DRAWER_EDGES',
  SET_DRAWER_EDGES = 'SET_DRAWER_EDGES',

  GET_DRAWER_INSERT_PANEL = 'GET_DRAWER_INSERT_PANEL',
  SET_DRAWER_INSERT_PANEL = 'SET_DRAWER_INSERT_PANEL',

  GET_TOP_DRAWER_INSERT_PANEL = 'GET_TOP_DRAWER_INSERT_PANEL',
  SET_TOP_DRAWER_INSERT_PANEL = 'SET_TOP_DRAWER_INSERT_PANEL',

  GET_HARDWARE = 'GET_HARDWARE',
  SET_HARDWARE = 'SET_HARDWARE',

  GET_CLOSET_HARDWARE_COLORS = 'GET_CLOSET_HARDWARE_COLORS',
  SET_CLOSET_HARDWARE_COLORS = 'SET_CLOSET_HARDWARE_COLORS',

  GET_DOOR_CODES = 'GET_DOOR_CODES',
  SET_DOOR_CODES = 'SET_DOOR_CODES',

  // save door builder data
  SAVE_DOOR_BUILDER_DATA = 'SAVE_DOOR_BUILDER_DATA',
  SET_DOOR_BUILDER_DATA = 'SET_DOOR_BUILDER_DATA',

  SET_STYLE_DOOR_BUILDER_DATA = 'SET_STYLE_DOOR_BUILDER_DATA',

  GET_HARDWARE_QUANITTIES = 'GET_HARDWARE_QUANITTIES',
  SET_HARDWARE_QUANTITIES = 'SET_HARDWARE_QUANTITIES',

  // inovae
  GET_TOESPACE_TYPES = 'GET_TOESPACE_TYPES',
  SET_TOESPACE_TYPES = 'SET_TOESPACE_TYPES',

  GET_PRODUCT_LINE_DOOR_STYLES = 'GET_PRODUCT_LINE_DOOR_STYLES',

  GET_DOOR_EDGE_BANDS = 'GET_DOOR_EDGE_BANDS',
  SET_DOOR_EDGE_BANDS = 'SET_DOOR_EDGE_BANDS',

  GET_MATERIAL_DRAWERS = 'GET_MATERIAL_DRAWERS',
  SET_MATERIAL_DRAWERS = 'SET_MATERIAL_DRAWERS',

  SAVE_INOVAE2O_DOOR_BUILDER = 'SAVE_INOVAE2O_DOOR_BUILDER',

  GET_MATERIAL_DOOR_CODES = 'GET_MATERIAL_DOOR_CODES',
  SET_MATERIAL_DOOR_CODES = 'SET_MATERIAL_DOOR_CODES',

  // ics
  SAVE_ICS_DOOR_BUILDER = 'SAVE_ICS_DOOR_BUILDER',

  SET_DOOR_BUILDER_OPTIONS_BY_OPTIONS_KEY = 'SET_DOOR_BUILDER_OPTIONS_BY_OPTIONS_KEY',
}

export const clearOptions = (options: (keyof DoorBuilderOptions)[] | null) => ({
  type: orderStylesDoorBuilderActions.CLEAR_OPTIONS,
  payload: options,
});

export interface ValidateStyleDoorBuilderRequest
  extends StyleDoorBuilderValidationData {
  orderId: string;
  styleId: string;
  cabinetBoxMaterialId: string | null;
}

export const validateStyleDoorBuilder = (
  payload: ValidateStyleDoorBuilderRequest,
  onSuccess: (breakingChangeItems: DoorBuilderBreakingChange[]) => void,
  loading?: (isLoaded: false) => void
) => ({
  type: orderStylesDoorBuilderActions.VALIDATE_STYLE_DOOR_BUILDER,
  payload,
  loading,
  onSuccess,
});

export interface DoorBuilderPayload {
  baseDoorConfigurationId?: string;
  baseDoorStyleId?: string;
  doorConfigurationId?: string;
  doorEdgeId?: string;
  doorOverlayId?: string;
  doorStyleId?: string | null;
  drawerFrontStyleId?: string;
  faceFrameId?: string;
  frameStyleId?: string;
  insertPanelBaseId?: string;
  orderId?: string;
  productLineId?: string;
  woodMaterialId?: string;
  styleId?: string;
  archStyleId?: string;
  materialGroupId?: string;
  materialColorId?: string;
  oneInchDoor?: boolean;
  isOverriden?: boolean;
  isQuoteFlow?: boolean;
}

export const getDoorBuilderOptions = (
  payload: DoorBuilderPayload,
  type: orderStylesDoorBuilderActions,
  loading: (isLoading: boolean) => void
) => ({
  type,
  payload,
  loading,
});

export const setDoorOverlayOptions = (
  doorOverlayOptions: StyleFieldOption[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_OVERLAYS,
  payload: doorOverlayOptions,
});

export const setFaceFrameOptions = (faceFrameOptions: StyleFieldOption[]) => ({
  type: orderStylesDoorBuilderActions.SET_FACE_FRAMES,
  payload: faceFrameOptions,
});

export const setFrameStyleOptions = (
  frameStyleOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_FRAME_STYLES,
  payload: frameStyleOptions,
});

export const setMidrailsOptions = (midrailsOptions: Midrails) => ({
  type: orderStylesDoorBuilderActions.SET_MIDRAILS,
  payload: midrailsOptions,
});

export const setDoorStyleOptions = (doorStyleOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_STYLE,
  payload: doorStyleOptions,
});

export const setFrameSizeOptions = (frameSizeOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_FRAME_SIZE,
  payload: frameSizeOptions,
});

export interface ConfigOptions {
  [key: string]: SelectOptionProps[];
}

export const setConfiguration = (configOptions: ConfigOptions) => ({
  type: orderStylesDoorBuilderActions.SET_CONFIG,
  payload: configOptions,
});

export const setArchStyleOptions = (archStyleOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_ARCH_STYLE,
  payload: archStyleOptions,
});

export const setInsertPanelWallOptions = (
  insertPanelWallOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_INSERT_PANEL_WALL,
  payload: insertPanelWallOptions,
});

export const setInsertPanelBaseOptions = (
  insertPanelBaseOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_INSERT_PANEL_BASE,
  payload: insertPanelBaseOptions,
});

export const setDoorEdgeWallOptions = (
  doorEdgeWallOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_EDGE_WALL,
  payload: doorEdgeWallOptions,
});

export const setDoorEdgeBaseOptions = (
  doorEdgeBaseOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_EDGE_BASE,
  payload: doorEdgeBaseOptions,
});

export const setMetalFinishOptions = (
  metalFinishOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_METAL_FINISH,
  payload: metalFinishOptions,
});

export const setDrawerBoxOptions = (drawerBoxOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_DRAWER_BOX,
  payload: drawerBoxOptions,
});

export const setHingeTypeOptions = (drawerBoxOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_HINGE_TYPE,
  payload: drawerBoxOptions,
});

export interface HingeColorOptions {
  [key: string]: SelectOptionProps[];
}

export const setHingeColorOptions = (
  hingeColorOptions: HingeColorOptions | null
) => ({
  type: orderStylesDoorBuilderActions.SET_HINGE_COLORS,
  payload: hingeColorOptions,
});

export const setDrawerStyleOptions = (
  drawerStyleOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DRAWER_STYLES,
  payload: drawerStyleOptions,
});

export const setDrawerEdgeOptions = (
  drawerEdgeOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DRAWER_EDGES,
  payload: drawerEdgeOptions,
});

export const setDrawerInsertPanelOptions = (
  drawerInsertPanelOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_DRAWER_INSERT_PANEL,
  payload: drawerInsertPanelOptions,
});

export const setTopDrawerInsertPanelOptions = (
  drawerTopInsertPanelOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_TOP_DRAWER_INSERT_PANEL,
  payload: drawerTopInsertPanelOptions,
});

export const getClosetHardwareColors = (
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.GET_CLOSET_HARDWARE_COLORS,
  loading,
});

export const setClosetHardwareColorOptions = (
  closetHardwareColorOptions: SelectOptionProps[]
) => ({
  type: orderStylesDoorBuilderActions.SET_CLOSET_HARDWARE_COLORS,
  payload: closetHardwareColorOptions,
});

export const getDoorCodes = (
  payload: DoorBuilderPayload,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.GET_DOOR_CODES,
  payload,
  loading,
});

export const setDoorCodes = (doorCodes: IDoorCode | null) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_CODES,
  payload: doorCodes,
});

export const getHardware = (
  payload: DoorBuilderPayload,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.GET_HARDWARE,
  payload,
  loading,
});

export const setHardwareOptions = (harwareOptions: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_HARDWARE,
  payload: harwareOptions,
});

export const saveDoorBuilderData = (
  payload: StyleDoorBuilderRequest,
  onSuccess: (response: DoorBuilderBreakingChange[]) => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.SAVE_DOOR_BUILDER_DATA,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const setDoorBuilderData = (doorBuilder: StyleDoorBuilder | null) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_BUILDER_DATA,
  payload: doorBuilder,
});

export const setStyleDoorBuilderData = () => ({
  type: orderStylesDoorBuilderActions.SET_STYLE_DOOR_BUILDER_DATA,
});

export interface HardwareQuantitiesRequest {
  orderId: string;
  styleId: string;
}

export const getHardwareQuantities = (payload: HardwareQuantitiesRequest) => ({
  type: orderStylesDoorBuilderActions.GET_HARDWARE_QUANITTIES,
  payload,
});

export const setHardwareQuantities = (
  hardwareQuantities: LineItemHardware
) => ({
  type: orderStylesDoorBuilderActions.SET_HARDWARE_QUANTITIES,
  payload: hardwareQuantities,
});

// INOVAE
export const getToeSpaceTypes = (loading: (isLoading: boolean) => void) => ({
  type: orderStylesDoorBuilderActions.GET_TOESPACE_TYPES,
  loading,
});

export const setToeSpaceTypes = (options: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_TOESPACE_TYPES,
  payload: options,
});

// inovae 2.O
export interface GetDoorEdgeBandsRequest {
  materialGroupId: string;
  materialColorId: string;
}

export const getDoorEdgeBands = (
  payload: GetDoorEdgeBandsRequest,
  loading: (isLoading: boolean) => void
) => ({
  payload,
  type: orderStylesDoorBuilderActions.GET_DOOR_EDGE_BANDS,
  loading,
});

export const setDoorEdgeBands = (options: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_EDGE_BANDS,
  payload: options,
});

export interface GetMaterialDrawersRequest {
  materialGroupId: string;
  grainDirectionId: number | null;
  doorOverlayId: string;
  doorStyleId: string;
  productLineId: string;
}

export const getMaterialDrawers = (
  payload: GetMaterialDrawersRequest,
  loading: (isLoading: boolean) => void
) => ({
  payload,
  type: orderStylesDoorBuilderActions.GET_MATERIAL_DRAWERS,
  loading,
});

export const setMaterialDrawers = (options: SelectOptionProps[]) => ({
  type: orderStylesDoorBuilderActions.SET_MATERIAL_DRAWERS,
  payload: options,
});

export const saveInovae2ODoorBuilder = (
  payload: Inovae2OStyleDoorBuilderRequest,
  onSuccess: () => void,
  onFailed: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.SAVE_INOVAE2O_DOOR_BUILDER,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface MaterialDoorCodesRequest {
  orderId: string;
  styleId?: string;
  materialGroupId?: string;
  materialColorId?: string;
  grainDirectionId?: number;
  doorOverlayId: string;
  wallDoorStyleId: string;
  baseDoorStyleId: string;
  materialDrawerId: string;
}

export const getMaterialDoorCodes = (
  payload: MaterialDoorCodesRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.GET_MATERIAL_DOOR_CODES,
  payload,
  loading,
});

export const setMaterialDoorCodes = (doorCodes: IDoorCode | null) => ({
  type: orderStylesDoorBuilderActions.SET_MATERIAL_DOOR_CODES,
  payload: doorCodes,
});

export const resetDoorBuilder = () => ({
  type: orderStylesDoorBuilderActions.RESET_DOOR_BUILDER,
});

export const saveICSDoorBuilder = (
  payload: ICSStyleDoorBuilderRequest,
  onSuccess: () => void,
  onFailed: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesDoorBuilderActions.SAVE_INOVAE2O_DOOR_BUILDER,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface SetDoorBuilderOptionsByOptionsKeyPayload {
  optionsKey: keyof DoorBuilderOptions;
  options: SelectOptionProps[] | ConfigOptions | HingeColorOptions;
}

export const setDoorBuilderOptionsByOptionsKey = (
  payload: SetDoorBuilderOptionsByOptionsKeyPayload
) => ({
  type: orderStylesDoorBuilderActions.SET_DOOR_BUILDER_OPTIONS_BY_OPTIONS_KEY,
  payload,
});
