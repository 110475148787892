import { FC } from 'react';

import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { Wrapper } from 'shared/components/Wrapper';
import { H6, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';

interface StyleDetailsFieldProps {
  fieldLabel: string;
  fieldValue?: string | number;
  fieldName: FieldName;
  styleId: string;
  asNote?: boolean;

  highlightStyleField: (
    styleId: string,
    fieldNames: FieldName[]
  ) => '' | 'highlight';

  highlightDeletedStyleField?: (
    styleId: string,
    fieldNames: FieldName[]
  ) => string;
}

const StyleDetailsField: FC<StyleDetailsFieldProps> = ({
  highlightStyleField,
  highlightDeletedStyleField,
  styleId,
  fieldName,
  fieldLabel,
  fieldValue,
  asNote,
}) => {
  return (
    <Wrapper
      wFull
      maxWidth={asNote ? undefined : 264}
      flex={!asNote}
      between
      alignStart
      marginRight={120}
    >
      <Wrapper minWidth={140}>
        <H6>{fieldLabel}</H6>
      </Wrapper>

      {asNote ? <Spacer h="16px" /> : <Spacer w="5px" />}

      <PSmall
        className={highlightStyleField(styleId, [fieldName])}
        textAlign={asNote ? undefined : 'right'}
        style={
          asNote
            ? {
                backgroundColor: '#e3aa5320',
                padding: '2px 4px',
                marginRight: '12px',
              }
            : {}
        }
      >
        {fieldValue ?? ''}
      </PSmall>

      {highlightDeletedStyleField &&
        highlightDeletedStyleField(styleId, [fieldName]) && (
          <PSmall className="highlight strikethrough">
            {`[VOID] ${highlightDeletedStyleField(styleId, [fieldName])}`}
          </PSmall>
        )}
    </Wrapper>
  );
};

StyleDetailsField.defaultProps = {
  asNote: false,
};

export default StyleDetailsField;
