import { FC, MouseEvent } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/icons/check-dark.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { mineShaft, silverChalice } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { ResolveButton } from 'shared/components/ResolveButton';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import { IWorkable } from '../../interface/Workable';
import {
  getUnresolvedWorkablesCount,
  resolveWorkable,
  setWorkable,
  setWorkablesMode,
} from '../../store/curtainWorkablesActions';

import { CurtainWorkablesTabEnums } from '../../enums/CurtainWorkablesTabEnums';

const CurtainWorkableContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  cursor: pointer;
  padding: 16px 46px 20px 20px;
  position: relative;
  transition: 300ms ease;

  > ${P} {
    transition: 300ms ease;
  }

  &:hover {
    background-color: ${({ theme }) => theme.wildSand};

    > ${P} {
      color: ${({ theme }) => theme.kashmirBlue};
    }
  }
`;

const CurtainWorkableMeta = styled.div``;

interface CurtainWorkableProps {
  workable: IWorkable;
}

const CurtainWorkable: FC<CurtainWorkableProps> = ({ workable }) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const onWorkableResolveStatusUpdated = () => {
    dispatch(getUnresolvedWorkablesCount({ orderId }));

    toast.success(
      `You have successfully ${
        workable.resolved ? 'unresolved' : 'resolved'
      } a workable.`
    );
  };

  const onResolveClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (workable) {
      dispatch(
        resolveWorkable(
          {
            orderId,
            workableId: workable.id!,
            resolved: workable.resolved,
            styleId: workable.styleId,
          },
          onWorkableResolveStatusUpdated
        )
      );
    }
  };

  const onWorkableClickHandler = () => {
    dispatch(setWorkable(workable));
    dispatch(setWorkablesMode(CurtainWorkablesTabEnums.FORM_MODE));
  };

  return (
    <CurtainWorkableContainer onClick={onWorkableClickHandler}>
      <Wrapper flex alignStart>
        <P fontWeight={600} fontSize={14}>
          {workable.description}
        </P>

        <ResolveButton
          onClick={onResolveClickHandler}
          className={`${workable.resolved && 'resolved-topic'}`}
        >
          <SVG icon={<CheckIcon />} color={mineShaft} />
        </ResolveButton>
      </Wrapper>

      <Spacer h="5px" />

      <CurtainWorkableMeta>
        <P fontSize={12} color={silverChalice} fontWeight={600}>
          Created by: {`${workable.user.firstName} ${workable.user.lastName}`}
        </P>
        <P fontSize={12} color={silverChalice} fontWeight={600}>
          Date Created:{' '}
          {UtilService.formatDate({ date: workable.createdOnUtc })}
        </P>
        <P fontSize={12} color={silverChalice} fontWeight={600}>
          Due Date: {UtilService.formatDate({ date: workable.dueDate })}
        </P>
      </CurtainWorkableMeta>
    </CurtainWorkableContainer>
  );
};

export default CurtainWorkable;
