import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';

import { AttachmentModalBox } from './AttachmentModalBox';
import { AttachmentsModalStepsEnums } from './enums/AttachmentsModalStepsEnums';
import { IAttachment } from '../../interface/IAttachment';
import { Select } from '../Select';
import { Textarea } from '../Textarea';

import {
  setSelectedAttachment,
  updateAttachmentsModal,
  updateModalAttachment,
} from './store/attachmentsModalActions';

import {
  AttachmentModalSectionEnums,
  generateAttachmentSections,
} from './enums/AttachmentModalSectionEnums';

const AttachmentFormContainer = styled.div``;

interface AttachmentFormProps {
  closeModal?: () => void;
  lineItemConfiguration?: boolean;
}

const AttachmentForm: FC<AttachmentFormProps> = ({
  closeModal,
  lineItemConfiguration,
}) => {
  const dispatch = useAppDispatch();

  const canPutInInternalDocsCategory = useHasPermissions(
    userPermissionsValues.INTERNAL_DOCS_CREATE
  );

  const activeStep = useSelector(
    (state: RootState) => state.attachmentsModalReducer.activeStep
  );

  const [updateMod] = useState(
    activeStep === AttachmentsModalStepsEnums.UPDATE_ATTACHMENT
  );

  const selectedAttachment = useSelector(
    (state: RootState) =>
      state.attachmentsModalReducer.currentSelectedAttachment
  );

  const selectedAttachments = useSelector(
    (state: RootState) => state.attachmentsModalReducer.selectedAttachments
  );

  const [attIndex, setAttIndex] = useState(0);
  const [attsLength, setAttsLength] = useState(0);

  type AttachmentFormInputs = Pick<IAttachment, 'section' | 'note'>;

  const getDefaultValues = () => {
    return {
      note: selectedAttachment?.note ?? '',
      section: selectedAttachment?.section ?? null,
    } as AttachmentFormInputs;
  };

  const methods = useForm<AttachmentFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: AttachmentFormInputs) => {
    const nextMod = selectedAttachments[attIndex + 1];

    const updatedMod = {
      ...selectedAttachment,
      ...data,
    } as IAttachment;

    // if closeModal function exists
    if (closeModal) {
      closeModal();
    }

    dispatch(updateModalAttachment(updatedMod));

    if (nextMod && !updateMod) {
      dispatch(setSelectedAttachment(nextMod));
    } else {
      dispatch(
        updateAttachmentsModal(
          AttachmentsModalStepsEnums.COMPLETE_ATTACHMENTS,
          AttachmentsModalStepsEnums.COMPLETE_ATT_TITLE,
          AttachmentsModalStepsEnums.COMPLETE_ATT_DESC
        )
      );
    }
  };

  const getAttachmentSections = () => {
    const sections = lineItemConfiguration
      ? [
          generateAttachmentSections().find(
            (attSection) =>
              attSection.value ===
              AttachmentModalSectionEnums['Line Items'].toString()
          ),
        ]
      : generateAttachmentSections().filter((attSection) =>
          !canPutInInternalDocsCategory
            ? +attSection.value <=
              AttachmentModalSectionEnums['Misc. Documents']
            : +attSection.value <=
              AttachmentModalSectionEnums['Plato internal Docs.']
        );

    return sections;
  };

  useEffect(() => {
    if (selectedAttachment) {
      methods.reset(getDefaultValues());
    }
  }, [selectedAttachment]);

  useEffect(() => {
    if (selectedAttachments && selectedAttachment) {
      setAttIndex(
        selectedAttachments.findIndex(
          (satt) => satt.id === selectedAttachment.id
        )
      );
      setAttsLength(selectedAttachments.length);

      if (lineItemConfiguration) {
        methods.setValue('section', AttachmentModalSectionEnums['Line Items']);
      }
    }
  }, [selectedAttachments, selectedAttachment]);

  return (
    <AttachmentFormContainer>
      {selectedAttachment && (
        <AttachmentModalBox attachment={selectedAttachment} />
      )}
      <Spacer h="30px" />

      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormElement>
          <FormLabel>Add file(s) to relevant section</FormLabel>
          <Select
            {...methods.register('section', { required: true })}
            onChange={(option: SelectOptionProps) =>
              methods.setValue('section', +option.value)
            }
            value={
              generateAttachmentSections().find(
                (attSection) =>
                  attSection.value === methods.watch('section')?.toString()
              ) ?? null
            }
            options={getAttachmentSections()}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Note</FormLabel>
          <Textarea
            placeholder="Add a note"
            {...methods.register('note', { maxLength: 40 })}
            aria-invalid={methods.formState.errors.note ? 'true' : 'false'}
          />
          <FormError
            label="Note"
            validationSchema={{ maxLength: 40 }}
            error={methods.formState.errors.note}
          />
        </FormElement>
        <Wrapper flex justifyEnd>
          <ButtonPrimary type="submit">
            {updateMod ? 'Save' : `Add ${attIndex + 1}/${attsLength}`}
          </ButtonPrimary>
        </Wrapper>
      </Form>
    </AttachmentFormContainer>
  );
};

AttachmentForm.defaultProps = {
  lineItemConfiguration: false,
};

export default AttachmentForm;
