import { call, put, takeEvery } from 'redux-saga/effects';

import { WritingHints } from 'order/writingHints/interface/WritingHints';

import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { setWritingHints, WritingHintsActions } from './writingHintsActions';

function* getWritingHints(action: Action<string>) {
  try {
    const response: WritingHints = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/writing-hints`
    );

    yield put(setWritingHints(response));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* writingHintsSagas() {
  yield takeEvery(WritingHintsActions.GET_WRITING_HINTS, getWritingHints);
}
