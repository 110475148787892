export enum OrderHeaderTestContainerEnum {
  ORDER_HEADER_WRAPPER = 'order-header-wrapper',
  ORDER_HEADER_FLASH = 'order-header-flash',
  ORDER_HEADER_STATUS = 'order-header-status',
  ORDER_HEADER_STATUS_EDIT_LOG = 'order-header-status-edit-log',
  ORDER_HEADER_EDIT_MODE = 'order-header-edit-mode',
  ORDER_HEADER_ASSIGNESS = 'order-header-assigness',
  ORDER_HEADER_SHIP_WEEK = 'order-header-ship-week',
}

export enum OrderHeaderTestElementsEnum {
  STATUS_DYNAMIC = 'status-dynamic',
  STATUS_STATIC = 'status-static',
}

export enum OrderHeaderTestBadgeEnum {
  IMPORTANT = 'badge-important',
  ATTENTION = 'badge-attention',
}
