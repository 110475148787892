import { useSelector } from 'react-redux';
import { Container } from 'shared/components/Container';
import { Info } from 'shared/components/Info';
import { Spacer } from 'shared/components/Layout';
import { P } from 'shared/components/Typography';
import { black } from 'shared/config/Colors';
import { RootState } from 'store';
import styled from 'styled-components';
import { StyleOverrideEnum } from '../../enums/StyleOverrideEnum';

const OverrideDisclamerContainer = styled.div``;

const OverrideDisclamer = () => {
  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  return styleOverride ? (
    <OverrideDisclamerContainer>
      <Spacer h="40px" />

      <Container>
        <Info type="warning">
          <P color={black} fontWeight={600} fontSize={14}>
            {styleOverride.overridenBy === StyleOverrideEnum.YET_TO_BE_OVERRIDEN
              ? 'Keep in mind that you are about to override this Style '
              : 'Keep in mind that this Style is overridden '}
            and that no validation is currently in effect.
          </P>
        </Info>
      </Container>
    </OverrideDisclamerContainer>
  ) : null;
};

export default OverrideDisclamer;
