import { call, takeEvery, put } from 'redux-saga/effects';

import { Action } from 'shared/interface/Action';
import { BaseField } from 'shared/interface/BaseField';
import { CustomAddressRequestObject } from 'shared/interface/CustomAddressRequestObject';
import { Dealership } from 'shared/interface/Dealership';
import { PriceVariablesGlobal } from 'shared/interface/PriceVariables';
import { SalesRepresentative } from 'shared/interface/SalesRepresentative';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import {
  dealershipActions,
  DealershipCustomPricesRequest,
  GetDealershipRequest,
  GetDealershipSalesRepRequest,
  setAvailableDealerships,
  setDealershipCustomPriceVariables,
  setDealershipSalesRep,
} from './dealershipActions';

export function* getAvailableDealerships(action: Action<unknown>) {
  try {
    const availableDealerships: BaseField[] = yield ApiService.get(
      'api/administration/dealerships/available'
    );

    // if there is only one dealership get entity and set it to the store
    if (availableDealerships.length === 1) {
      const dealership: Dealership = yield ApiService.get(
        `/api/administration/dealerships/${availableDealerships[0].id}`
      );

      yield put({
        type: dealershipActions.SET_CURRENT_DEALERSHIP,
        payload: dealership,
      });
    }

    yield put(setAvailableDealerships(availableDealerships));
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealership(action: Action<GetDealershipRequest, Dealership>) {
  try {
    const { dealershipId } = action.payload!;

    const response: Dealership = yield call(
      ApiService.get,
      `/api/administration/dealerships/${dealershipId}`
    );

    yield put({
      type: dealershipActions.SET_CURRENT_DEALERSHIP,
      payload: response,
    });

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, response);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealershipCustomAddresess(action: Action<string>) {
  try {
    const response: ShippingAddress[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/${action.payload}/addresses/custom-shipping`
    );

    yield put({
      type: dealershipActions.SET_DEALERSHIP_CUSTOM_ADDRESSES,
      payload: response,
    });

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, response);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealershipSalesRepresentative(
  action: Action<GetDealershipSalesRepRequest, SalesRepresentative>
) {
  try {
    const { dealershipId } = action.payload!;

    const salesRep: SalesRepresentative = yield call(
      ApiService.get,
      `/api/administration/dealerships/${dealershipId}/sales-representative`
    );

    yield put(setDealershipSalesRep(salesRep));

    if (action.onSuccess) {
      action.onSuccess(salesRep);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealershipPriceVariables(action: Action<string>) {
  try {
    const response: PriceVariablesGlobal = yield call(
      ApiService.get,
      `/api/administration/dealerships/${action.payload}/price-variables`
    );

    yield put({
      type: dealershipActions.SET_DEALERSHIP_GLOBAL_PRICE_VARIABLES,
      payload: response,
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateDealershipCustomPriceVariables(
  action: Action<DealershipCustomPricesRequest>
) {
  try {
    const { dealershipId, ...rest } = action.payload!;

    yield call(
      ApiService.put,
      `/api/administration/dealerships/${dealershipId}/price-variables`,
      rest
    );

    yield put(setDealershipCustomPriceVariables(rest));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* addNewCustomAddress(action: Action<CustomAddressRequestObject>) {
  try {
    yield call(
      ApiService.post,
      `/api/administration/dealerships/${
        action.payload!.dealershipId
      }/addresses`,
      action.payload
    );

    yield call(getDealershipCustomAddresess, {
      type: dealershipActions.GET_DEALERSHIP_CUSTOM_ADDRESSES,
      payload: action.payload!.dealershipId,
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateCustomAddress(action: Action<CustomAddressRequestObject>) {
  try {
    const { dealershipId, addressId, ...rest } =
      action.payload as CustomAddressRequestObject;

    yield call(
      ApiService.put,
      `/api/administration/dealerships/${dealershipId}/addresses/${addressId}`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* getFilteredDealerships(action: Action<unknown>) {
  try {
    const dealerships: Dealership[] = yield ApiService.get(
      'api/administration/dealerships/filtered'
    );

    yield put({
      type: dealershipActions.SET_FILTERED_DEALERSHIPS,
      payload: dealerships,
    });
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* dealershipSagas() {
  yield takeEvery(
    dealershipActions.GET_AVAILABLE_DEALERSHIPS,
    getAvailableDealerships
  );

  yield takeEvery(dealershipActions.GET_DEALERSHIP, getDealership);

  yield takeEvery(
    dealershipActions.GET_DEALERSHIP_CUSTOM_ADDRESSES,
    getDealershipCustomAddresess
  );
  yield takeEvery(
    dealershipActions.GET_DEALERSHIP_SALES_REPRESENTATIVE,
    getDealershipSalesRepresentative
  );
  yield takeEvery(
    dealershipActions.GET_DEALERSHIP_GLOBAL_PRICE_VARIABLES,
    getDealershipPriceVariables
  );
  yield takeEvery(
    dealershipActions.ADD_DEALERSHIP_CUSTOM_ADDRESS,
    addNewCustomAddress
  );
  yield takeEvery(
    dealershipActions.UPDATE_DEALERSHIP_CUSTOM_ADDRESS,
    updateCustomAddress
  );
  yield takeEvery(
    dealershipActions.GET_FILTERED_DEALERSHIPS,
    getFilteredDealerships
  );
  yield takeEvery(
    dealershipActions.UPDATE_DEALERSHIP_CUSTOM_PRICES,
    updateDealershipCustomPriceVariables
  );
}
