import { FC } from 'react';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import styled from 'styled-components';

interface OrderShippingAddressProps {
  address: ShippingAddress;
}

const ShippingAddressContainer = styled.div``;

const OrderShippingAddress: FC<OrderShippingAddressProps> = ({ address }) => {
  return (
    <ShippingAddressContainer>
      <p>{address.street}</p>
      <p>{address.number} </p>
      <p>
        {address.city}, {address.state} {address.zipCode}
      </p>
    </ShippingAddressContainer>
  );
};
export default OrderShippingAddress;
