import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import toastSuccess from 'assets/icons/toast-success.svg';
import toastInfo from 'assets/icons/toast-info.svg';
import toastError from 'assets/icons/toast-error.svg';
import toastWarning from 'assets/icons/toast-warning.svg';

export const Toast = styled(ToastContainer)`
  max-width: 400px;
  width: auto;
  padding: 0;

  .Toastify {
    &__toast {
      display: flex;
      align-items: center;
      padding: 32px 22px;
      border-radius: 8px;

      &:before {
        content: '';
        margin-right: 16px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
      }

      // toast notification
      &.notif {
        padding: 15px;
        min-height: 0;

        &::before {
          display: none;
        }
      }

      &--success {
        background-color: ${({ theme }) => theme.fern};

        &:before {
          background-image: url('${toastSuccess}');
        }
      }

      &--error {
        background-color: ${({ theme }) => theme.valencia};

        &:before {
          background-image: url('${toastError}');
        }
      }

      &--warning {
        background-color: ${({ theme }) => theme.anzac};

        &:before {
          background-image: url('${toastWarning}');
        }
      }

      &--info {
        background-color: ${({ theme }) => theme.lynch};

        &:before {
          background-image: url('${toastInfo}');
        }
      }
    }

    &__toast-body {
      flex: 1;
      font-weight: 600;
      padding: 0;
      word-break: break-word;
    }
  }
`;
