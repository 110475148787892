import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';

import { INotification } from 'overview/interface/Notification';

import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { H3, P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { ToggleCheckbox } from 'shared/components/ToggleCheckbox';
import { Wrapper } from 'shared/components/Wrapper';
import { silverSandTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInfiniteScroll } from 'shared/hooks/useInfiniteScroll';

import {
  getNotifications,
  markAllNotifsAsRead,
  markNotifsAsSeen,
  setNotifications,
} from './store/notificationsActions';

import { Notification } from './Notification';

const NotificationsContainer = styled(Wrapper)<{ shrinkHeader: boolean }>`
  padding: ${({ shrinkHeader }) => (shrinkHeader ? '10px' : '15px')} 0 15px 0;
  transition: padding 300ms ease;
`;

const NotificationsHeader = styled(Wrapper)`
  padding-left: 20px;
`;

const MarkAllAsRead = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: none;
  color: ${({ theme }) => theme.silverSandTwo};
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  transition: 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.nevada};
  }
`;

const NotifScrollHeader = styled(Wrapper)<{ shrinkHeader: boolean }>`
  background-color: ${({ theme }) => theme.white};
  margin-bottom: 20px;
  padding-bottom: ${({ shrinkHeader }) => (shrinkHeader ? '10px' : '0px')};
  padding-left: 20px;
  position: sticky;
  top: 0;
  transition: padding 300ms ease;
  z-index: 5;

  border-bottom: ${({ shrinkHeader, theme }) =>
    shrinkHeader &&
    `1px solid ${UtilService.colorWithOpacity(theme.mischka, 0.3)}`};

  ${MarkAllAsRead} {
    margin-top: ${({ shrinkHeader }) => (shrinkHeader ? '0px' : '24px')};
  }

  > ${P} {
    transition: padding 300ms ease;
    padding-top: ${({ shrinkHeader }) => (shrinkHeader ? '3px' : '0')};
  }
`;

const ThatsAllFalks = styled(Wrapper)`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
`;

interface NotificationsProps {
  showCollaborationOnly: boolean;
}

export const Notifications: FC<NotificationsProps> = ({
  showCollaborationOnly,
}: NotificationsProps) => {
  const dispatch = useAppDispatch();

  const [showUnread, setShowUnred] = useState<boolean | undefined>();

  const [itemsPerPage] = useState(Math.floor(window.innerHeight / 85));

  const [notificationsLoading, setNotificationsLoading] = useState(true);

  const [shrinkHeader, setShrinkHeader] = useState(false);

  const paginatedNotifications = useSelector(
    (state: RootState) => state.notificationsReducer.notifications
  );

  const notifUnreadCount = useSelector((state: RootState) =>
    showCollaborationOnly
      ? state.notificationsReducer.unreadCollaborationCount
      : state.notificationsReducer.unreadCount
  );

  const onGetNotificationsSuccessHandler = (notifs: INotification[]) => {
    const notSeenNotifsIds = notifs
      .filter((notif) => !notif.isSeen)
      .map((notif) => notif.id);

    if (notSeenNotifsIds.length) {
      dispatch(
        markNotifsAsSeen({
          notificationIds: notSeenNotifsIds,
          onlyCollaboration: showCollaborationOnly,
        })
      );
    }
  };

  const loadNotifications = (appendItems: boolean, page: string = '1') => {
    dispatch(
      getNotifications(
        {
          itemsPerPage,
          page,
          showUnread: showUnread ?? false,
          appendItems,
          showCollaborationOnly,
        },
        onGetNotificationsSuccessHandler,
        setNotificationsLoading
      )
    );
  };

  // infnite scroll for notifs
  const { page, handleUpdate, setScrollInProgress, scrolledFromTop, setPage } =
    useInfiniteScroll({
      hasNextPage: paginatedNotifications?.hasNextPage ?? false,
      loadList: () => loadNotifications(true, page.toString()),
      loading: notificationsLoading,
      setLoading: setNotificationsLoading,
    });

  const onMarkAllNotifsAsReadClickHandler = () => {
    dispatch(markAllNotifsAsRead(showCollaborationOnly));
  };

  useEffect(() => {
    loadNotifications(false);

    return () => {
      dispatch(setNotifications(null));
    };
  }, []);

  useEffect(() => {
    if (showUnread === undefined) return;

    setPage(1);
    setNotificationsLoading(true);
    loadNotifications(false, '1');
  }, [showUnread]);

  useEffect(() => {
    setShrinkHeader(scrolledFromTop > 10);
  }, [scrolledFromTop]);

  return (
    <NotificationsContainer
      minWidth={400}
      maxWidth={400}
      shrinkHeader={shrinkHeader}
    >
      <ScrollbarsCustom
        autoHeight
        autoHeightMin={window.innerHeight - 100}
        autoHeightMax={window.innerHeight - 100}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        onScrollStart={() => setScrollInProgress(true)}
        onScrollStop={() => setScrollInProgress(false)}
        onUpdate={handleUpdate}
      >
        <NotificationsHeader>
          <Wrapper flex middle between>
            <H3>Notifications</H3>

            <Wrapper flex middle>
              <P color={silverSandTwo} fontSize={10} bold>
                Only show Unread
              </P>

              <Spacer w="5px" />

              <ToggleCheckbox
                checked={showUnread}
                onChange={() => setShowUnred((prevState) => !prevState)}
                id="showUnread"
              />
            </Wrapper>
          </Wrapper>
        </NotificationsHeader>

        <Spacer h="5px" />

        <NotifScrollHeader flex between shrinkHeader={shrinkHeader}>
          <P color={silverSandTwo} fontSize={10} bold>
            {notifUnreadCount} unread
          </P>

          {notifUnreadCount > 0 && (
            <Wrapper flex mlAuto>
              <MarkAllAsRead
                type="button"
                onClick={onMarkAllNotifsAsReadClickHandler}
              >
                Mark all as Read
              </MarkAllAsRead>
            </Wrapper>
          )}
        </NotifScrollHeader>

        <Wrapper>
          {paginatedNotifications?.items.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              showCollaborationOnly={showCollaborationOnly}
            />
          ))}
        </Wrapper>

        {!paginatedNotifications && notificationsLoading && (
          <Loader size={30} />
        )}

        {!notificationsLoading && !paginatedNotifications?.hasNextPage && (
          <ThatsAllFalks maxWidth={150} mrAuto mlAuto>
            <SVG
              icon={<NotificationsIcon width={16} height={19.5} />}
              color={silverSandTwo}
            />

            <Spacer h="10px" />

            <P color={silverSandTwo} fontSize={10} bold>
              That&apos;s all your notifications.
            </P>
          </ThatsAllFalks>
        )}
      </ScrollbarsCustom>
    </NotificationsContainer>
  );
};
