import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Divider, Spacer, WhiteBox } from 'shared/components/Layout';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H3 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Textarea } from 'shared/components/Textarea';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import {
  firstNameValidation,
  lastNameValidation,
  phoneNumberValidation,
} from 'shared/validations/validations';

import {
  getUser,
  setUser,
  updateUser,
  UpdateUserRequest,
} from '../store/manageUsersActions';

import PWWUsername from './PWWUsername';

const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 570px;
  padding: 16px;
  position: relative;
`;

interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  note?: string;
  pwwUsername?: string;
}

export const ManageUsersEditUserForm = () => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const currentUser = useSelector((state: RootState) => state.currentUser.user);
  const canCreateCSRUser = useHasPermissions(
    userPermissionsValues.CSR_USER_CREATE
  );
  const [resetButtonDisabled, setResetButtonDisabled] = useState(false);

  const [updatingUserInProgress, setUpdatingUserInProgress] = useState(false);

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const defaultValues = {
    email: currentUser?.email ?? '',
    firstName: currentUser?.firstName ?? '',
    lastName: currentUser?.lastName ?? '',
    phoneNumber: currentUser?.phoneNumber ?? '',
    ...(canCreateCSRUser && {
      note: currentUser?.note ?? '',
      pwwUsername: currentUser?.pwwUsername ?? '',
    }),
  };

  const methods = useForm<FormInputs>({
    mode: 'onSubmit',
    defaultValues,
  });
  const {
    reset,
    formState: { isDirty },
  } = methods;

  const watchAllFields = methods.watch();

  const onUpdateUserSuccessHandler = () => {
    history.push('/overview/manage-users', {
      successMessage: 'User has been successfully updated.',
    });
  };

  const onUpdateUserErrorHandler = (err: ServerErrorResponse) => {
    setUpdatingUserInProgress(false);

    UtilService.handleError({
      err,
      fallbackErrorToastMessage:
        'There was a problem while updating data for this user. Please try again later.',
    });
  };

  const onSubmit = (data: FormInputs) => {
    setUpdatingUserInProgress(true);

    const updateUserRequest = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      userId,
      ...(canCreateCSRUser && {
        note: data.note,
        pwwUsername: data.pwwUsername,
      }),
    } as UpdateUserRequest;

    dispatch(
      updateUser(
        updateUserRequest,
        onUpdateUserSuccessHandler,
        onUpdateUserErrorHandler,
        setUpdatingUserInProgress
      )
    );
  };

  useEffect(() => {
    dispatch(
      getUser({
        userId,
      })
    );

    return () => {
      dispatch(setUser(null));
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      reset(defaultValues);
    }
  }, [currentUser, canCreateCSRUser]);

  useEffect(() => {
    const formValues = methods.getValues();

    Object.entries(formValues).every(([key]) => {
      if (
        currentUser &&
        currentUser[key as keyof FormInputs] !==
          formValues[key as keyof FormInputs]
      ) {
        setResetButtonDisabled(false);
        return false;
      }
      setResetButtonDisabled(true);
      return true;
    });
  }, [watchAllFields]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <WhiteBox>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <H3>Profile Settings</H3>
            <Spacer h="28px" />

            <FormElement>
              <FormLabel>First Name</FormLabel>
              <Input
                placeholder="Enter your name here..."
                data-test="input-first-name"
                aria-invalid={
                  methods.formState.errors.firstName ? 'true' : 'false'
                }
                {...methods.register(
                  'firstName',
                  firstNameValidation({ required: true })
                )}
              />
              <FormError
                label="First name"
                error={methods.formState.errors.firstName}
                validationSchema={firstNameValidation({ required: true })}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="Enter your last name here..."
                {...methods.register(
                  'lastName',
                  lastNameValidation({ required: true })
                )}
                data-test="input-last-name"
                aria-invalid={
                  methods.formState.errors.lastName ? 'true' : 'false'
                }
              />
              <FormError
                label="Last name"
                error={methods.formState.errors.lastName}
                validationSchema={lastNameValidation({ required: true })}
              />
            </FormElement>

            <Spacer h="16px" />
            <Divider />
            <Spacer h="35px" />

            <H3>Contact Information</H3>
            <Spacer h="16px" />
            <FormElement>
              <FormLabel>Email for login</FormLabel>
              <Input
                placeholder="Enter your email here..."
                type="email"
                data-test="input-email"
                aria-invalid={methods.formState.errors.email ? 'true' : 'false'}
                {...methods.register('email')}
                readOnly
              />
            </FormElement>

            {!dealership && <PWWUsername />}

            <FormElement>
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="Enter your phone here..."
                data-test="input-phone"
                aria-invalid={
                  methods.formState.errors.phoneNumber ? 'true' : 'false'
                }
                {...methods.register(
                  'phoneNumber',
                  phoneNumberValidation({ required: true })
                )}
              />
              <FormError
                label="Phone number"
                error={methods.formState.errors.phoneNumber}
                validationSchema={phoneNumberValidation({ required: true })}
              />
            </FormElement>

            {!dealership && (
              <FormElement>
                <FormLabel>Note</FormLabel>
                <Textarea
                  data-test="input-note"
                  {...methods.register('note')}
                />
              </FormElement>
            )}

            <Wrapper flex justifyEnd middle>
              <ButtonSecondary
                onClick={() => reset(defaultValues)}
                type="button"
                data-test="button-cancel"
                disabled={resetButtonDisabled}
              >
                Revert
              </ButtonSecondary>
              <Spacer w="20px" />
              <ButtonPrimary
                type="submit"
                disabled={updatingUserInProgress || !isDirty}
                data-test="button-add"
              >
                Update
                <Loader
                  insideButton
                  size={16}
                  hidden={!updatingUserInProgress}
                  noSpacing
                />
              </ButtonPrimary>
            </Wrapper>
          </Form>
        </WhiteBox>

        {!currentUser && (
          <LoaderFullScreen
            top={16}
            right={16}
            bottom={16}
            left={16}
            borderRadius={20}
            flex
            middle
            center
          >
            <Loader size={50} />
          </LoaderFullScreen>
        )}
      </FormContainer>
    </FormProvider>
  );
};
