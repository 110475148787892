import { Sides } from 'shared/interface/CSSTypes';
import styled from 'styled-components';

interface TooltipProps {
  position?: Sides;
  bgColor?: string;
  maxWidth?: number;
  minWidth?: number;
  zIndex?: number;
}

const Tooltip = styled.div.attrs((props: TooltipProps) => ({
  className: props.position,
}))<TooltipProps>`
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.lynch};
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  font-size: 13px;
  font-weight: normal;
  line-height: 1.4;
  max-width: ${({ maxWidth }) => maxWidth ?? '175'}px;
  min-width: ${({ minWidth }) => minWidth ?? '175'}px;
  opacity: 0;
  padding: 4px 10px 5px 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: 300ms ease;
  word-break: break-word;
  z-index: ${({ zIndex }) => zIndex ?? 5};

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-width: 7px 7px 7px 7px;
    border-style: solid;
  }

  &.right {
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    margin-left: 7px;

    &::before {
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 7px 7px 7px 0;
      border-color: transparent
        ${({ theme, bgColor }) => bgColor ?? theme.lynch} transparent
        transparent;
    }
  }

  &.left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 7px;

    &::before {
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent
        ${({ theme, bgColor }) => bgColor ?? theme.lynch};
    }
  }

  &.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 7px;

    &::before {
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent
        ${({ theme, bgColor }) => bgColor ?? theme.lynch} transparent;
    }
  }

  &.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 7px;

    &::before {
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 7px 7px 0 7px;
      border-color: ${({ theme, bgColor }) => bgColor ?? theme.lynch}
        transparent transparent transparent;
    }
  }
`;

Tooltip.defaultProps = {
  position: 'top',
};

export { Tooltip };
