import { createRef, FC, useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as PlatoLogo } from 'assets/icons/plato-logo.svg';

import { LineItemListView } from 'order/wizard/orderLineItems/components/LineItemListView';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';

import { ButtonIcon } from 'shared/components/Button';
import { H2, H3, P, PMedium } from 'shared/components/Typography';
import { LineItem } from 'shared/components/LineItem';
import { lynch, white, silverChaliceTwo } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useEventListener } from 'shared/hooks/useEventListener';
import { useGetOrderMetaNames } from 'shared/hooks/useGetOrderMetaNames';
import { useInfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { useInputSearch } from 'shared/hooks/useInputSearch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import ElipsisText from 'shared/components/ElipsisText';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SearchInput from 'shared/components/SearchInput';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import SkeletonList, {
  ISkeletonListItem,
} from 'shared/components/SkeletonList';

import SkeletonGrid, {
  ISkeletonGridItem,
} from 'shared/components/SkeletonGrid';

import {
  getLineItemCatalog,
  setCategory,
  setCurrentLineItem,
  setSubcategory,
} from '../store/orderLineItemsActions';

import { ILineItemCatalogItem } from '../interface/ILineItemCatalog';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import LineItemCategories from '../LineItemCategories/LineItemCategories';

const Container = styled(Wrapper)`
  padding: 0 12.5px;
  max-width: 1225px;
  margin: 0 auto;
`;

const Header = styled(Wrapper)`
  min-height: 72px;
  background-color: ${({ theme }) => theme.white};

  ${Container} {
    > h3 {
      margin-right: 30px;
      min-width: 330px;
    }
  }
`;

const MainContentContainer = styled(Wrapper)``;

const LeftSideContainer = styled(Wrapper)`
  width: 300px;
  height: calc(100vh - 73px);
`;

const RightSideContainer = styled(Wrapper)`
  width: calc(100% - 360px);
  flex: 0 0 calc(100% - 360px);
  height: calc(100vh - 73px);
`;

const NumberOfResults = styled(PMedium)`
  color: ${({ theme }) => theme.lynch};
  font-weight: 600;
`;

const CatalogFilters = styled(Wrapper)`
  height: 35px;
`;

const ChosenFilterContainer = styled(Wrapper)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 35px;
  color: ${({ theme }) => theme.nevada};
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  padding: 4px 14px;

  svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.lynch};
    padding: 3px;
  }
`;

const ViewModeActions = styled(Wrapper)`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const LineItemsGridContainer = styled(Wrapper)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px 24px;
  margin: 0 4px;
`;

const LineItemsListContainer = styled(Wrapper)`
  margin: 0 4px;
`;

const LineItemsCatalogEmptyState = styled.div`
  font-size: 12px;

  > p {
    font-weight: 600;
    color: ${({ theme }) => theme.silverChalice};
    margin-bottom: 10px;

    .cat {
      font-weight: 700;
    }

    .subCat {
      font-weight: 400;
    }
  }

  > .icon {
    margin-top: 20px;
    svg {
      width: 100px;
      height: auto;
    }
  }
`;

interface OrderLineItemsCatalogProps {
  styleName: string;
}

export const OrderLineItemsCatalog: FC<OrderLineItemsCatalogProps> = ({
  styleName,
}) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation();

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'replacementLineItemId',
    'styleId',
    'selectedLineItemDetailsId',
  ]);

  const replacementMode = !!queryFields?.replacementLineItemId;

  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  const [highlightIndex, setHighlightIndex] = useState(0);

  const skeletonGridItem = {
    padding: 0,
    borderRadius: 16,
    w: 262,
    h: 400,
  } as ISkeletonGridItem;

  const skeletonListItem = {
    padding: 0,
    borderRadius: 16,
    marginBottom: 16,
    w: null,
    h: 70,
  } as ISkeletonListItem;

  const [
    querySearch,
    onQuerySearchChange,
    querySearchLoading,
    setQuerySearchLoading,
  ] = useInputSearch();

  const [loading, setLoading] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(6);

  const lineItemCatalog = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemCatalog
  );

  const orderType = useSelector(
    (state: RootState) => state.orderReducer.order?.type
  );

  const orderStylizationType = useSelector(
    (state: RootState) => state.orderReducer.order?.stylizationType
  );

  const doorOverlayId = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style?.doorOverlay.id
  );

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const selectedCategory = useSelector(
    (state: RootState) => state.orderLineItemsReducer.selectedCategory
  );

  const selectedSubcategory = useSelector(
    (state: RootState) => state.orderLineItemsReducer.selectedSubcategory
  );

  const lineItemNumber = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemNumber
  );

  const numberOfOrderLineItems = useSelector((state: RootState) => {
    if (state.orderReducer.lineItems) {
      return (
        state.orderReducer.lineItems.find(
          (li) =>
            li.lineItemId ===
            state.orderLineItemsReducer.modalOpenedForLineItemId
        )?.number ?? state.orderReducer.lineItems.length + lineItemNumber + 1
      );
    }

    return undefined;
  });

  const {
    orderNumber,
    jobName,
    orderName,
    styleName: orderMetaStyleName,
  } = useGetOrderMetaNames({ styleName });

  const generateRefs = () => {
    const refs = [];

    for (let i = 0; i < itemsPerPage; i += 1) {
      const cratedRef = createRef<HTMLDivElement>();
      refs.push(cratedRef);
    }

    return refs;
  };

  const lineItemRefs = useRef(generateRefs());

  const [lineItemContainerRef, setLineItemContainerRef] =
    useState<Scrollbars | null>(null);

  const lineItemContainerListRef = useRef<HTMLDivElement>(null);

  const onGetLineItemCatalogSuccessHandler = () => {
    setQuerySearchLoading(false);
  };

  const loadLineItemCatalog = (
    appendItems: boolean = false,
    pageNum: string = '1'
  ) => {
    setLoading(true);

    if (queryFields) {
      dispatch(
        getLineItemCatalog(
          {
            productLineId: queryFields.productLineId,
            itemsPerPage: itemsPerPage.toString(),
            appendItems,
            page: pageNum,
            orderType: UtilService.getCatalogOrderType(
              orderType,
              orderStylizationType
            ),
            searchTerm: querySearch,
            ...(queryFields.styleId && { styleId: queryFields.styleId }),
            ...(doorOverlayId !== undefined && { doorOverlayId }),
            ...(selectedCategory &&
              selectedCategory?.id !== '0' && {
                categoryId: selectedCategory.id,
              }),
            ...(selectedSubcategory && {
              subCategoryId: selectedSubcategory.id,
            }),
          },
          onGetLineItemCatalogSuccessHandler,
          setLoading
        )
      );
    }
  };

  const { page, handleUpdate, setScrollInProgress, setPage } =
    useInfiniteScroll({
      hasNextPage: lineItemCatalog?.hasNextPage ?? false,
      loadList: () => loadLineItemCatalog(true, page.toString()),
      loading,
      setLoading,
    });

  const allProductsCategory = useSelector((state: RootState) =>
    state.orderLineItemsReducer.categories?.find((cat) => cat.id === '0')
  );

  const selectLineItemAndRedirectToConfigure = (lineItemId: string) => {
    const params = new URLSearchParams(location.search);

    params.append('catalogLineItemId', lineItemId);

    params.sort();

    history.push(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      location.state
    );
  };

  const onLineItemConfigureHandler = (lineItem: ILineItemCatalogItem) => {
    dispatch(setCurrentLineItem(lineItem));
    selectLineItemAndRedirectToConfigure(lineItem.id);
  };

  const keyPressed = (e: KeyboardEvent) => {
    if (lineItemCatalog && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
      const selectedLineItem = lineItemCatalog?.items.find(
        (el, index) => index === highlightIndex
      );

      if (selectedLineItem && !loading) {
        selectLineItemAndRedirectToConfigure(selectedLineItem.id);
      }
    }
  };

  // new hook added
  useEventListener('keyup', keyPressed);

  const loadNextPageIfNeeded = () => {
    const scrollHeight = lineItemContainerRef?.getClientHeight();

    const containerRect =
      lineItemContainerListRef.current?.getBoundingClientRect();

    if (!containerRect?.top || !lineItemCatalog?.hasNextPage) return;

    const h = (scrollHeight ?? 0) - containerRect.top + 72;

    const hh = containerRect!.height;

    if (h >= hh) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (lineItemCatalog) {
      loadNextPageIfNeeded();
    }
  }, [lineItemCatalog?.items]);

  useEffect(() => {
    if (lineItemContainerRef) {
      lineItemContainerRef.scrollTop(0);
    }
    setHighlightIndex(0);
    setPage(1);
    loadLineItemCatalog(false, '1');
  }, [querySearch, selectedCategory, selectedSubcategory, itemsPerPage]);

  // this is first load of catalog for stylization orders
  // this is applicable only for order with stylization
  useEffect(() => {
    if (queryFields && doorOverlayId && !isOrderSalesMaterial) {
      setLoading(true);
      loadLineItemCatalog();
    }
  }, [queryFields, doorOverlayId, isOrderSalesMaterial]);

  // this is first load of catalog for sales material order
  useEffect(() => {
    if (queryFields && isOrderSalesMaterial) {
      setLoading(true);
      loadLineItemCatalog();
    }
  }, [queryFields, isOrderSalesMaterial]);

  useEffect(() => {
    lineItemRefs.current[0].current?.focus();
    setHighlightIndex(0);
  }, [viewMode]);

  const renderLineItemList = () => {
    if (viewMode === 'list') {
      return (
        <LineItemsListContainer
          flex
          column
          center
          ref={lineItemContainerListRef}
        >
          {lineItemCatalog?.items.map((lineItem, lineItemIndex) => (
            <LineItemListView
              lineItem={lineItem}
              highlighted={lineItemIndex === highlightIndex}
              key={lineItem.id}
              onFocus={() => setHighlightIndex(lineItemIndex)}
              onLineItemConfigureHandler={onLineItemConfigureHandler}
              onMouseDown={(e) => e.preventDefault()}
              ref={lineItemRefs.current[lineItemIndex]}
              tabIndex={0}
              isLineItemAlreadyAdded={
                queryFields?.selectedLineItemDetailsId === lineItem.id
              }
            />
          ))}
        </LineItemsListContainer>
      );
    }

    return (
      <LineItemsGridContainer ref={lineItemContainerListRef}>
        {lineItemCatalog?.items.map((lineItem, lineItemGridIndex) => {
          return (
            <LineItem
              lineItem={lineItem}
              highlighted={lineItemGridIndex === highlightIndex}
              key={lineItem.id}
              onFocus={() => setHighlightIndex(lineItemGridIndex)}
              onLineItemConfigureHandler={onLineItemConfigureHandler}
              onMouseDown={(e) => e.preventDefault()}
              ref={lineItemRefs.current[lineItemGridIndex]}
              tabIndex={0}
              isLineItemAlreadyAdded={
                queryFields?.selectedLineItemDetailsId === lineItem.id
              }
            />
          );
        })}
      </LineItemsGridContainer>
    );
  };

  return (
    <>
      <Header flex middle>
        <Container flex middle flexGrow>
          {!isOrderSalesMaterial ? (
            <H3>
              <ElipsisText position="bottom" maxWidth={330}>
                {orderNumber}
                {jobName && <> - {jobName}</>}
                {orderName && <> - {orderName}</>}
                {orderMetaStyleName && <> - {orderMetaStyleName}</>}
              </ElipsisText>
            </H3>
          ) : (
            <Spacer w="360px" />
          )}

          <SearchInput
            data-role="search"
            width={552}
            data-loading={querySearchLoading}
            data-test="input-lineItemCatalogSearch"
            placeholder="Search catalog by code or description..."
            onSearchQueryChanged={(e) => onQuerySearchChange(e.target.value)}
            autoFocus
          />
        </Container>
      </Header>

      <Container>
        <MainContentContainer flex>
          <LeftSideContainer flexGrow>
            {numberOfOrderLineItems && (
              <Wrapper marginBottom={30} marginTop={30}>
                <H2>
                  {replacementMode ? 'Replace' : 'Select'} a Line Item L#{' '}
                  {numberOfOrderLineItems}
                </H2>
              </Wrapper>
            )}

            <ScrollbarsCustom
              ref={(el) => setLineItemContainerRef(el)}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <LineItemCategories />
            </ScrollbarsCustom>
          </LeftSideContainer>

          <Spacer w="60px" />

          <RightSideContainer>
            <ScrollbarsCustom
              ref={(el) => setLineItemContainerRef(el)}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
              onScrollStart={() => setScrollInProgress(true)}
              onScrollStop={() => setScrollInProgress(false)}
              onUpdate={handleUpdate}
            >
              <Wrapper flex middle between marginTop={30}>
                <CatalogFilters flex middle>
                  <NumberOfResults>
                    {lineItemCatalog?.totalCount ?? '0'} Result
                    {lineItemCatalog?.totalCount !== 1 && 's'}
                  </NumberOfResults>

                  <Spacer w="16px" />

                  {selectedCategory?.id !== '0' && selectedCategory && (
                    <ChosenFilterContainer flex middle center>
                      {selectedCategory.name}
                      <Spacer w="16px" />
                      <ButtonIcon
                        tabIndex={-1}
                        onClick={() => {
                          if (allProductsCategory) {
                            dispatch(setCategory(allProductsCategory));
                          }

                          dispatch(setSubcategory(null));
                        }}
                      >
                        <SVG icon={<CrossIcon />} color={white} />
                      </ButtonIcon>
                    </ChosenFilterContainer>
                  )}

                  {selectedSubcategory && (
                    <ChosenFilterContainer flex middle center>
                      {selectedSubcategory.name}
                      <Spacer w="16px" />
                      <ButtonIcon
                        tabIndex={-1}
                        onClick={() => dispatch(setSubcategory(null))}
                      >
                        <SVG icon={<CrossIcon />} color={white} />
                      </ButtonIcon>
                    </ChosenFilterContainer>
                  )}
                </CatalogFilters>

                <ViewModeActions flex middle justifyEnd>
                  <ButtonIcon
                    tabIndex={-1}
                    onClick={() => {
                      setViewMode('list');
                      setItemsPerPage(10);
                    }}
                  >
                    <SVG
                      icon={<ListIcon />}
                      color={viewMode === 'list' ? lynch : silverChaliceTwo}
                      hoverColor={lynch}
                    />
                  </ButtonIcon>
                  <Spacer w="8px" />
                  <ButtonIcon
                    tabIndex={-1}
                    onClick={() => {
                      setViewMode('grid');
                      setItemsPerPage(6);
                    }}
                  >
                    <SVG
                      icon={<GridIcon />}
                      color={viewMode === 'grid' ? lynch : silverChaliceTwo}
                      hoverColor={lynch}
                    />
                  </ButtonIcon>
                </ViewModeActions>
              </Wrapper>

              <Spacer h="22px" />

              {!!lineItemCatalog?.totalCount && renderLineItemList()}

              {loading && viewMode === 'grid' && !lineItemCatalog && (
                <SkeletonGrid
                  gap={20}
                  columns={3}
                  items={[
                    skeletonGridItem,
                    skeletonGridItem,
                    skeletonGridItem,
                    skeletonGridItem,
                    skeletonGridItem,
                    skeletonGridItem,
                  ]}
                />
              )}

              {loading && viewMode === 'list' && !lineItemCatalog && (
                <SkeletonList
                  items={[
                    skeletonListItem,
                    skeletonListItem,
                    skeletonListItem,
                    skeletonListItem,
                    skeletonListItem,
                    skeletonListItem,
                  ]}
                />
              )}

              {!loading && !lineItemCatalog?.totalCount && (
                <EmptyState>
                  <LineItemsCatalogEmptyState>
                    <P>Sorry, we couldn’t find any results</P>
                    <P>
                      No Line Items were found in{' '}
                      <span className="cat">{selectedCategory?.name}</span>
                      {selectedSubcategory && ' - '}
                      {selectedSubcategory && (
                        <span className="subCat">
                          {selectedSubcategory.name}
                        </span>
                      )}
                    </P>

                    <SVG icon={<PlatoLogo />} color="inherit" />
                  </LineItemsCatalogEmptyState>
                </EmptyState>
              )}

              {!loading &&
                !lineItemCatalog?.hasNextPage &&
                (lineItemCatalog?.items.length ?? 0) > 0 && (
                  <EmptyState>
                    <LineItemsCatalogEmptyState>
                      <P>There are no more line items.</P>
                      <SVG icon={<PlatoLogo />} color="inherit" />
                    </LineItemsCatalogEmptyState>
                  </EmptyState>
                )}

              {loading && (
                <Wrapper flex middle center>
                  <Loader />
                </Wrapper>
              )}

              <Spacer h="30px" />
            </ScrollbarsCustom>
          </RightSideContainer>
        </MainContentContainer>
      </Container>
    </>
  );
};
