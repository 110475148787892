import { FC } from 'react';
import styled, { css } from 'styled-components';

import { User } from 'shared/interface/User';
import UtilService from 'shared/services/util.service';
import { white } from 'shared/config/Colors';
// import { CurtainCommentUser } from 'shared/interface/CurtainComment';

interface AvatarCircleProps {
  user: Partial<User>;
  size?: number;
  border?: number;
  borderColor?: string;
}

type AvatarProps = Pick<AvatarCircleProps, 'size' | 'border' | 'borderColor'>;

const borderCSS = css<Pick<AvatarProps, 'border' | 'borderColor'>>`
  box-shadow: ${({ border, borderColor }) =>
    border && `0 0 0 ${border}px ${borderColor ?? white}`};
`;

const AvatarImg = styled.img<AvatarProps>`
  border-radius: 50%;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  display: block;
  object-fit: cover;
  object-position: center;

  ${({ border }) => border && borderCSS}
`;

export const AvatarCircleContainer = styled.div<AvatarProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.poloBlue};
  border-radius: 50%;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: ${({ size }) => size! / 2}px;
  font-weight: bold;
  height: ${({ size }) => size}px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 1;
  min-width: ${({ size }) => size}px;
  text-align: center;
  width: ${({ size }) => size}px;
  border: 1px solid;

  ${({ border }) => border && borderCSS}
`;

const AvatarCircle: FC<AvatarCircleProps> = ({
  user,
  size,
  border,
  borderColor,
}) => {
  // If the logged user still didn't get all data, display an empty container
  if (!user) {
    return <AvatarCircleContainer border={border} borderColor={borderColor} />;
  }

  return user.imageUrl ? (
    <AvatarImg
      src={user.imageUrl}
      alt="user"
      size={size}
      border={border}
      borderColor={borderColor}
    />
  ) : (
    <AvatarCircleContainer
      size={size}
      border={border}
      borderColor={borderColor}
    >
      {UtilService.getInitials(
        (user.firstName || user.email) ?? '',
        (user.lastName || user.email) ?? ''
      )}
    </AvatarCircleContainer>
  );
};

AvatarCircle.defaultProps = {
  size: 24,
};

export default AvatarCircle;
