import { FC, MouseEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
import { ReactComponent as DetailsIcon } from 'assets/icons/details.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notifications.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';

import {
  INotification,
  NotificationTypeEnums,
} from 'overview/interface/Notification';

import { P } from 'shared/components/Typography';
import { silverSandTwo } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useGenerateNotifLink } from 'shared/hooks/useGenerateNotifLink';
import { Wrapper } from 'shared/components/Wrapper';
import IconCircle from 'shared/components/IconCircle';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';

import { changeReadStatus } from './store/notificationsActions';

interface NotificationProps {
  notification: INotification;
  inToast?: boolean;
  showCollaborationOnly: boolean;
}

const NotificationContainer = styled(Wrapper)<{ inToast: boolean }>`
  transition: background-color 300ms ease;
  padding: ${({ inToast }) =>
    !inToast && `16px 5px 16px 20px; cursor: pointer`};

  border-bottom: 1px solid
    ${({ theme }) => UtilService.colorWithOpacity(theme.mischka, 0.3)};

  &:hover {
    background-color: ${({ theme, inToast }) => !inToast && theme.athensGray};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const NotificationBody = styled.div`
  flex: 1;
`;

interface MarkAsReadBtnProps {
  isRead: boolean;
}

const ReadDotStyle = css`
  &::before {
    background-color: ${({ theme }) => theme.kashmirBlue};
    border-radius: 50%;
    content: '';
    height: 8px;
    opacity: 0;
    transition: 300ms ease;
    width: 8px;
  }
`;

const ReadDotStyleActive = css`
  &::before {
    opacity: 1;
  }
`;

const ReadDotStyleHover = css`
  &::before {
    opacity: 0.5;
  }
`;

const MarkAsReadBtn = styled.button<MarkAsReadBtnProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.mischka};
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 20px;
  position: relative;
  width: 20px;

  ${ReadDotStyle};

  ${({ isRead }) => !isRead && ReadDotStyleActive}

  &:hover {
    ${({ isRead }) => isRead && ReadDotStyleHover}

    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const MarkAsReadLoaderWrapper = styled.div`
  margin-left: 20px;
`;

const Notification: FC<NotificationProps> = ({
  notification,
  inToast,
  showCollaborationOnly,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { url, urlState } = useGenerateNotifLink(notification);

  const getNotificationIconBasedOnType = () => {
    switch (notification.type) {
      case NotificationTypeEnums.COLLABORATION:
        return <PeopleIcon />;
      case NotificationTypeEnums.ORDER:
        return <FolderIcon />;
      case NotificationTypeEnums.ACK:
        return <DetailsIcon />;
      case NotificationTypeEnums.ATTACHMENTS:
        return <AttachmentIcon />;
      default:
        return <NotificationIcon />;
    }
  };

  const onChangeReadStatusClickHandler = (
    e?: MouseEvent<HTMLButtonElement>
  ) => {
    e?.stopPropagation();

    setLoading(true);
    dispatch(
      changeReadStatus(
        {
          notificationIds: [notification.id],
          read: notification.isRead,
          showCollaborationOnly,
        },
        () => {
          setLoading(false);
          toast.error(
            `Cannot mark notification as ${
              notification.isRead ? 'un' : ''
            }read at this moment.`
          );
        },
        setLoading
      )
    );
  };

  const onNotifClickHandler = () => {
    if (!notification.isRead) {
      onChangeReadStatusClickHandler();
    }

    if (url) {
      history.push(url, urlState ?? urlState);
    }
  };

  return (
    <NotificationContainer
      flex
      middle
      onClick={!inToast ? onNotifClickHandler : undefined}
      inToast={inToast ?? false}
    >
      <IconCircle icon={getNotificationIconBasedOnType()} size={42} />

      <NotificationBody>
        <P fontSize={12} bold>
          {notification.content}
        </P>
        <Spacer h="5px" />
        <P color={silverSandTwo} fontSize={10} bold>
          {UtilService.formatDate({ date: notification.createdOnUtc })}
        </P>
      </NotificationBody>

      {loading ? (
        <MarkAsReadLoaderWrapper>
          <Loader size={20} noSpacing />
        </MarkAsReadLoaderWrapper>
      ) : (
        !inToast && (
          <MarkAsReadBtn
            isRead={notification.isRead}
            onClick={onChangeReadStatusClickHandler}
          >
            <Tooltip position="left">
              Mark as {!notification.isRead ? 'Read' : 'Unread'}
            </Tooltip>
          </MarkAsReadBtn>
        )
      )}
    </NotificationContainer>
  );
};

Notification.defaultProps = {
  inToast: false,
};

export { Notification };
