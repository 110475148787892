import { FC, useEffect, useState } from 'react';
import Loader from 'shared/components/Loader';
import Slider, { Slide } from 'shared/components/Slider';
import styled from 'styled-components';
import { ILineItemSize } from '../interface/ILineItemData';

const LineItemSliderContainer = styled.div`
  width: 456px;
`;

interface LineItemSliderProps {
  size: ILineItemSize | null;
}

const LineItemSlider: FC<LineItemSliderProps> = ({ size }) => {
  const [slides, setSlides] = useState<Slide[]>([]);

  const mapSizeToSlide = (data: ILineItemSize) => {
    return {
      id: data.description,
      url: data.imageUrl,
    } as Slide;
  };

  useEffect(() => {
    if (size) {
      setSlides([mapSizeToSlide(size)]);
    }
  }, [size]);

  return (
    <LineItemSliderContainer>
      {slides.length > 0 ? (
        <Slider activeSlideHeight={456} width={456} items={slides} />
      ) : (
        <Loader size={50} />
      )}
    </LineItemSliderContainer>
  );
};

export default LineItemSlider;
