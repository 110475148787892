import styled from 'styled-components';

import * as variables from 'shared/config/Variables';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';

import { Wrapper } from './Wrapper';

interface ContainerProps {
  width?: number;
  padding?: number;
}

export const Container = styled(Wrapper)<ContainerProps>`
  padding: 0 ${({ padding }) => padding ?? variables.containerPadding}px;
  max-width: ${({ width }) => width ?? variables.containerWidth}px;
  width: 100%;
  margin: 0 auto;
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const ContainerXSmall = styled(Wrapper)`
  min-width: ${({ minWidth }) => minWidth ?? 40}px;

  ${({ maxWidth, flexGrow, mrAuto }) =>
    flexGrow === undefined && mrAuto === undefined
      ? `max-width: ${maxWidth ?? 40}px;`
      : undefined}

  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const ContainerSmall = styled(Wrapper)`
  min-width: ${({ minWidth }) => minWidth ?? 80}px;

  ${({ maxWidth, flexGrow, mrAuto }) =>
    flexGrow === undefined && mrAuto === undefined
      ? `max-width: ${maxWidth ?? 40}px;`
      : undefined}

  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const ContainerMedium = styled(Wrapper)`
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    max-width: 25%;
  }
`;

export const ContainerBox = styled(Wrapper).attrs({
  className: 'container-box',
})`
  ${BoxShadowCSS};
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
  padding: 32px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
