import styled from 'styled-components';
import { Location } from 'history';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { CsrQuotesTabsEnum } from 'shared/enum/CsrQuotesTabsEnum';
import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { DealerQuotesTabsEnum } from 'shared/enum/DealerQuoteTabEnum';
import { LinkText } from './LinkText';
import { TabStyles } from './TabStyles';

const StyledTabsContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.mischka};
  left: 0;
  right: 0;
  top: 65px;
  z-index: 5;
  background-color: ${({ theme }) => theme.white};
`;

const Container = styled.div`
  display: flex;
  padding: 0px 30px;
  flex-wrap: wrap;
`;

const TabLink = styled(NavLink)`
  ${TabStyles};
`;

export interface TabProps {
  text: string;
  active?: boolean;
  onClick: () => void;
}

export interface StyledTabsProps {
  tabs: TabProps[];
  nounderline?: number;
  activeIndex?: number;
}

export const StyledTabs = ({
  tabs,
  nounderline,
  activeIndex,
}: StyledTabsProps) => {
  const location: Location<{ backToTab: number }> = useLocation();

  const [tabChanged, setTabChanged] = useState(false);

  const [active, setActive] = useState(0);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const onClickHandler = (tab: TabProps, index: number) => {
    setTabChanged(true);
    setActive(index);
    tab.onClick();
  };

  // set active tab from location state
  useEffect(() => {
    if (!tabChanged && location.state?.backToTab && !activeIndex) {
      if (location.state.backToTab > tabs.length) {
        if (isUserCSR) {
          setActive(
            tabs.findIndex(
              (tab) => tab.text === CsrQuotesTabsEnum[location.state.backToTab]
            )
          );
        } else {
          setActive(
            tabs.findIndex(
              (tab) =>
                tab.text === DealerQuotesTabsEnum[location.state.backToTab]
            )
          );
        }

        return;
      }
      setActive(location.state.backToTab - 1);
    }
  }, [location.state?.backToTab]);

  useEffect(() => {
    if (activeIndex && isUserCSR !== undefined) {
      if (activeIndex > tabs.length) {
        if (isUserCSR) {
          setActive(
            tabs.findIndex((tab) => tab.text === CsrQuotesTabsEnum[activeIndex])
          );
        } else {
          setActive(
            tabs.findIndex(
              (tab) => tab.text === DealerQuotesTabsEnum[activeIndex]
            )
          );
        }

        return;
      }
      setActive(activeIndex - 1);
    }
  }, [activeIndex, isUserCSR]);

  useEffect(() => {
    if (
      !tabChanged &&
      isUserCSR &&
      !location.state?.backToTab &&
      !activeIndex
    ) {
      const foundTabIndex = tabs.findIndex(
        (tab) =>
          tab.text === CsrTabsEnum[CsrTabsEnum['Orders to Write']] ||
          tab.text === CsrQuotesTabsEnum[CsrQuotesTabsEnum['Not Completed']]
      );

      if (foundTabIndex) {
        setActive(foundTabIndex);
      }
    }
  }, [isUserCSR]);

  return (
    <StyledTabsContainer>
      <Container>
        {tabs.map((tab, index) => (
          <TabLink
            to="#"
            onClick={() => onClickHandler(tab, index)}
            key={tab.text}
            activeClassName={active === index || tab.active ? 'active' : ''}
            nounderline={nounderline}
          >
            <LinkText text={tab.text} />
          </TabLink>
        ))}
      </Container>
    </StyledTabsContainer>
  );
};
