import { FC, useEffect, useState } from 'react';
import { RootState } from 'store';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ConfigureLineItem } from 'order/wizard/orderLineItems/components/ConfigureLineItem';
import { getOrderLineItems, setOrderLineItems } from 'order/store/orderActions';
import { OrderLineItemsCatalog } from 'order/wizard/orderLineItems/components/OrderLineItemsCatalog';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import {
  clearLineItemsReducer,
  getLineItemStyle,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import { ButtonIcon } from 'shared/components/Button';
import { lynch, silverChaliceTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import { CurtainSidebar } from 'curtain/CurtainSidebar';
import { ConfigureSalesMaterialLineItem } from './components/ConfigureSalesMaterialLineItem';
import { OrderLineItemModalParams } from './interface/OrderLineItemModalParams';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';

const CloseButton = styled(ButtonIcon)`
  position: absolute;
  right: 37px;
  top: 28px;
`;

interface OrderLineItemsModalProps {
  close: () => void;
}

export const OrderLineItemsModal: FC<OrderLineItemsModalProps> = ({
  close,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [leaveModalOpened, setLeaveModalOpened] = useState(false);

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'catalogLineItemId',
    'styleId',
    'productLineId',
  ]);

  const isStepDirty = useSelector(
    (state: RootState) => state.orderLineItemsReducer.isStepDirty
  );

  const styleName = useSelector(
    (state: RootState) =>
      state.orderReducer.styles?.find(
        (style) => style.id === queryFields?.styleId
      )?.name
  );

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  useEffect(() => {
    return () => {
      UtilService.onPopupClose();
    };
  }, []);

  useEffect(() => {
    if (
      queryFields &&
      queryFields.styleId &&
      isOrderSalesMaterial !== null &&
      !isOrderSalesMaterial
    ) {
      // get style data when loading line item configuration
      dispatch(getLineItemStyle({ orderId, styleId: queryFields.styleId }));
    }
  }, [queryFields, isOrderSalesMaterial]);

  const onCloseModalClickHandler = (shouldCheckIsDirty: boolean = true) => {
    if (isStepDirty && shouldCheckIsDirty) {
      setLeaveModalOpened(true);
    } else {
      history.replace(location.pathname, location.state);
      dispatch(clearLineItemsReducer());
      dispatch(setOrderLineItems(null));
      dispatch(
        getOrderLineItems({
          orderId,
          ...(isOrderSalesMaterial && {
            isSalesMaterial: true,
            productLineId: customProductLine?.id,
          }),
        })
      );

      close();
    }
  };

  const closeModal = () => {
    dispatch(clearLineItemsReducer());
    history.replace(location.pathname);
  };
  return (
    <>
      {queryFields?.productLineId && !queryFields?.catalogLineItemId && (
        <OrderLineItemsCatalog styleName={styleName ?? 'N/A'} />
      )}

      {queryFields?.catalogLineItemId && isOrderSalesMaterial && (
        <ConfigureSalesMaterialLineItem />
      )}

      {queryFields?.catalogLineItemId && !isOrderSalesMaterial && (
        <ConfigureLineItem
          styleName={styleName ?? 'N/A'}
          handleCloseButton={(shouldCheckIfDirty) =>
            onCloseModalClickHandler(shouldCheckIfDirty)
          }
        />
      )}
      <CurtainSidebar top={75} />

      <CloseButton onClick={() => onCloseModalClickHandler()}>
        <SVG icon={<CloseIcon />} color={silverChaliceTwo} hoverColor={lynch} />
      </CloseButton>

      <ConfirmationModal
        title="Are you sure?"
        opened={leaveModalOpened}
        confirm={() => closeModal()}
        cancel={() => setLeaveModalOpened(false)}
        message='Changes in the current page will not be saved until you hit the "Save Changes" button located in the footer. Closing this page will result in loss of current changes to this page.'
        buttonText="Discard"
      />
    </>
  );
};
