import { FieldError, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

export const StyledFormError = styled.div`
  color: ${({ theme, color }) => color ?? theme.valencia};
  font-size: 0.8rem;
  margin-top: 5px;
`;

enum errorTypes {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
  MAX = 'max',
  MIN = 'min',
  VALIDATE = 'validate',
}

interface FormErrorProps {
  error: FieldError | undefined;
  label: string;
  validationSchema: RegisterOptions;
  color?: string;
}

const getErrorMessage = (
  label: string,
  error: FieldError,
  validationSchema: RegisterOptions
) => {
  switch (error.type) {
    case errorTypes.REQUIRED:
      return `${label} is required.`;
    case errorTypes.PATTERN:
      return error.message ? error.message : `${label} is not in valid format.`;
    case errorTypes.VALIDATE:
      return error.message ? error.message : `${label} is not in valid format.`;
    case errorTypes.MIN_LENGTH:
      return `${label} should be at least ${validationSchema.minLength} characters long.`;
    case errorTypes.MAX_LENGTH:
      return `${label} should be at most ${validationSchema.maxLength} characters long.`;
    case errorTypes.MAX:
      return `${label} shouldn't be more than ${validationSchema.max}.`;
    case errorTypes.MIN:
      return `${label} shouldn't be less than ${validationSchema.min}.`;
    default:
      return null;
  }
};

const FormError = ({
  error,
  label,
  validationSchema,
  color,
}: FormErrorProps) => {
  return error ? (
    <StyledFormError color={color}>
      {getErrorMessage(label, error, validationSchema)}
    </StyledFormError>
  ) : null;
};

FormError.defaultProps = {
  color: undefined,
};

export default FormError;
