import styled from 'styled-components';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { setOrderIsValid } from 'order/store/orderActions';

import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, H3, H4, PMedium } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { Select } from 'shared/components/Select';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { Wrapper } from 'shared/components/Wrapper';
import { anzac } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanSubmit } from 'shared/hooks/useCanSubmit';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { useUndefinedOptionAsFirst } from 'shared/hooks/useUndefinedOptionAsFirst';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  digitWithSingleDotValidation,
  noteValidation,
  upchargeValidation,
  workOrderNumberValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';
import { Inovae2OStyleSpecificationsTestTextAreaEnum } from 'tests/enums/Inovae2OStyleSpecificationsTestEnums';

import MaterialColor from '../steps/OrderStylesSpecifications/MaterialColor/MaterialColor';
import OrderStyleNote from '../../shared/OrderStyleNote';
import RevolaeSQFT from './components/RevolaeSQFT';
import VendorColor from '../steps/OrderStylesSpecifications/VendorColor/VendorColor';
import { CabinetBoxMaterialOptionsEnum } from '../../enums/CabinetBoxMaterialOptionsEnum';
import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { resetDoorBuilder } from '../store/doorBuilder/orderStylesDoorBuilderActions';
import { useScrollToElement } from '../../../../../shared/hooks/useScrollToElement';

import {
  changeStylesStep,
  getStyle,
  setIsStylesStepDirty,
} from '../store/orderStylesActions';

import {
  Inovae2OStyleSpecificationsRequest,
  StyleSpecifications,
} from '../../interface/StyleSpecifications';

import {
  getCabinetBoxMaterials,
  getMaterialColors,
  getMaterialGroups,
  saveInovae2OStyleSpecs,
  validateInovae2OStyleSpecifications,
} from '../store/specifications/orderStylesSpecificationActions';

const LeftColumn = styled.div`
  max-width: 456px;
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const StaticValue = styled(Wrapper)`
  height: 44px;
  font-weight: 600;

  &.invalid {
    color: ${({ theme }) => theme.valencia};
  }
`;

const GrainDirectionWrapper = styled(Wrapper)`
  max-width: 320px;
`;

export const Inovae2OStylesSpecifications = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [shouldGoBack, setShouldGoBack] = useState(false);

  const config = useProductLineConfiguration();

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const storedSpecifications = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const defaultValues: Partial<StyleSpecifications> = {};

  const methods = useForm<StyleSpecifications>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const styleStep = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.step
  );

  const editMode = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.materialGroup !== null
  );

  const isStyleComplete = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.isComplete
  );

  const materialGroupOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.materialGroupOptions
  );

  const grainDirectionOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.grainDirectionOptions
  );

  const cabinetBoxOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.cabinetBoxMaterialOptions
  );

  const materialGroupWatched = methods.watch('materialGroup');
  const materialColorWatched = methods.watch('materialColor');
  const cabinetBoxMaterialWatched = methods.watch('cabinetBoxMaterial');
  const grainDirectionWatched = methods.watch('grainDirection');

  const [specSaving, setSpecSaving] = useState(false);
  const [specSavingOnBack, setSpecSavingOnBack] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [materialColorsLoading, setMaterialColorsLoading] = useState(false);

  const { onFormChange, PopupModal } = usePopupMessage();

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.PRODUCT_LINE));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.DOOR_BUILDER));
    }
  };

  const styleSpecSavedHandler = (goBack: boolean = false) => {
    dispatch(
      getStyle(
        { orderId, styleId: styleId! },
        () => redirect(goBack),
        (isLoading) => (goBack ? setSpecSavingOnBack : setSpecSaving)(isLoading)
      )
    );
  };

  const saveStyleSpectErrorHandler = () => {
    console.error('Something went wrong');
    setSpecSaving(false);
    setSpecSavingOnBack(false);
  };

  const mapStyleSpecificationsToRequest = (
    data: StyleSpecifications
  ): Inovae2OStyleSpecificationsRequest => {
    return {
      orderId,
      styleId: styleId!,
      styleStep: (styleStep?.toString() as StylesStepsEnum) ?? null,
      materialGroup: {
        id: data.materialGroup!.value,
        upcharge: +data.materialGroupUpcharge!,
      },
      materialColor: {
        id: data.materialColor!.value,
        upcharge: +data.materialColorUpcharge!,
      },
      cabinetBoxMaterialId: data.cabinetBoxMaterial
        ? +data.cabinetBoxMaterial.value
        : undefined,
      cabinetBoxMaterialUpcharge: +data.cabinetBoxMaterialUpcharge,
      grainDirectionId: data.grainDirection
        ? +data.grainDirection.value
        : undefined,
      materialNotes: data.materialNotes,
      note: data.styleNote,
      specialCabinetBoxMaterial: data.specialCabinetBoxMaterial,
      edgebandColor: data.edgebandColor ?? data.materialColor?.edgebandColor,
      workOrderNumber: data.workOrderNumber,
      ...(styleOverride && {
        isOverriden: true,
        overrideReason: styleOverride.overridenReason,
      }),
      ...(selectedProductLine?.name ===
        ProductLineEnums.PRODUCT_LINE_REVOLAE && {
        doorSquareFootPrice: data.doorSquareFootPrice,
        miDoorSquareFootPrice: data.miDoorSquareFootPrice,
        interiorNotes: data.interiorNotes,
        ...((data.vendorColorCode || data.vendorColorName) && {
          vendorColor: {
            code: data.vendorColorCode ?? '',
            name: data.vendorColorName ?? '',
          },
        }),
      }),
    };
  };

  const onSubmitHandler = ({
    data,
    goBack = false,
    shouldResetDoorBuilder = false,
  }: {
    data: StyleSpecifications;
    goBack?: boolean;
    shouldResetDoorBuilder?: boolean;
  }) => {
    if (shouldResetDoorBuilder) {
      dispatch(resetDoorBuilder());
      dispatch(setOrderIsValid(false));
    }

    if (!canEdit) {
      redirect(goBack);
      return;
    }

    if (goBack) {
      setSpecSavingOnBack(true);
    } else {
      setSpecSaving(true);
    }

    dispatch(
      saveInovae2OStyleSpecs(
        mapStyleSpecificationsToRequest(data),
        () => styleSpecSavedHandler(goBack),
        saveStyleSpectErrorHandler,
        (isLoading) => (goBack ? setSpecSavingOnBack : setSpecSaving)(isLoading)
      )
    );
  };

  const canSubmit = useCanSubmit({
    isFormDity: methods.formState.isDirty,
    editMode,
    isStyleComplete,
    step: StylesStepsEnum.SPECIFICATIONS,
    styleStep,
  });

  const goNextAndSaveForFirstTime = () => {
    return !methods.formState.isDirty && !isStyleComplete && !editMode;
  };

  const validationResponse = (isValid: boolean, goBack: boolean) => {
    setValidationLoading(false);

    if (!!grainDirectionOptions?.length && !grainDirectionWatched) {
      methods.trigger();
      return;
    }

    if (!isValid) {
      setIsResetModalVisible(true);
      setShouldGoBack(goBack);
      return;
    }

    methods.handleSubmit((data) =>
      onSubmitHandler({
        data,
        goBack,
      })
    )();
  };

  const handleValidateOrSubmit = (goBack: boolean) => {
    if (
      editMode &&
      !styleOverride &&
      (methods.formState.dirtyFields.materialGroup ||
        methods.formState.dirtyFields.materialColor) &&
      materialGroupWatched &&
      materialColorWatched
    ) {
      setValidationLoading(true);

      dispatch(
        validateInovae2OStyleSpecifications(
          {
            orderId,
            styleId: styleId!,
            materialGroupId: materialGroupWatched.value,
            materialColorId: materialColorWatched.value,
          },
          (isValid) => validationResponse(isValid, goBack)
        )
      );

      return;
    }

    methods.handleSubmit((data) =>
      onSubmitHandler({
        data,
        goBack,
      })
    )();
  };

  useEffect(() => {
    if (!specSaving && !specSavingOnBack && storedSpecifications) {
      methods.reset(storedSpecifications);
    }
  }, [storedSpecifications]);

  useEffect(() => {
    onFormChange(methods);
  }, [methods.formState]);

  useEffect(() => {
    if (styleId) {
      history.push(`${location.pathname}?styleId=${styleId}`, location.state);
    }
  }, [styleId]);

  useEffect(() => {
    if (selectedProductLine) {
      dispatch(getCabinetBoxMaterials(selectedProductLine.id));
    }
  }, [selectedProductLine]);

  // load initial material group
  useEffect(() => {
    if (selectedProductLine && !materialGroupOptions) {
      setLoading(true);

      dispatch(
        getMaterialGroups(
          {
            productLineId: selectedProductLine.id,
          },
          setLoading
        )
      );
    }
  }, [selectedProductLine, materialGroupOptions]);

  useEffect(() => {
    if (
      !methods.formState.isDirty &&
      storedSpecifications?.materialGroup &&
      selectedProductLine
    ) {
      setMaterialColorsLoading(true);
      dispatch(
        getMaterialColors(
          {
            materialGroupId: storedSpecifications.materialGroup.value,
            productLineId: selectedProductLine.id,
          },
          setMaterialColorsLoading
        )
      );
    }
  }, [storedSpecifications, selectedProductLine]);

  useEffect(() => {
    if (
      methods.formState.isDirty &&
      materialGroupWatched &&
      selectedProductLine
    ) {
      if (config.styleSpecifications.showMaterialUpcharge) {
        UtilService.withDecimal<StyleSpecifications>(
          'materialGroupUpcharge',
          materialGroupWatched.upcharge?.toString() ?? '0',
          methods.setValue
        );
      }

      setMaterialColorsLoading(true);

      dispatch(
        getMaterialColors(
          {
            materialGroupId: materialGroupWatched.value,
            productLineId: selectedProductLine.id,
          },
          setMaterialColorsLoading
        )
      );

      methods.setValue('edgebandColor', '');

      if (config.styleSpecifications.showSQFT) {
        methods.setValue('doorSquareFootPrice', '0.00');
        methods.setValue('miDoorSquareFootPrice', '0.00');
      }
    }
  }, [materialGroupWatched]);

  useEffect(() => {
    if (methods.formState.dirtyFields.materialColor && materialColorWatched) {
      methods.setValue(
        'edgebandColor',
        materialColorWatched.edgebandColor ?? ''
      );
    }
  }, [materialColorWatched]);

  useEffect(() => {
    if (cabinetBoxMaterialWatched) {
      const cabinetBoxMaterialUpcharge = !methods.formState.isDirty
        ? storedSpecifications?.cabinetBoxMaterialUpcharge ?? ''
        : cabinetBoxMaterialWatched.upcharge?.toString() ?? '';

      UtilService.withDecimal<StyleSpecifications>(
        'cabinetBoxMaterialUpcharge',
        cabinetBoxMaterialUpcharge,
        methods.setValue
      );

      if (methods.formState.isDirty) {
        methods.setValue('specialCabinetBoxMaterial', '');
      }
    }
  }, [cabinetBoxMaterialWatched]);

  useEffect(() => {
    if (grainDirectionWatched) {
      methods.trigger('grainDirection');
    }
  }, [grainDirectionWatched]);

  const materialColorRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.materialColor,
    ref: materialColorRef,
    fieldName: 'materialColor',
  });

  const styleLoaderContainer = document.getElementById(
    'style-loader-container'
  ) as HTMLElement;

  useUndefinedOptionAsFirst();

  return (
    <>
      <ConfirmationModal
        cancel={() => setIsResetModalVisible(false)}
        confirm={() =>
          methods.handleSubmit(
            (data) =>
              onSubmitHandler({
                data,
                goBack: shouldGoBack,
                shouldResetDoorBuilder: true,
              }),
            () => setIsResetModalVisible(false)
          )()
        }
        confirmBgColor={anzac}
        message="By changing your material group or material color the selected door style is no longer valid. If you choose to continue the door builder page will reset. Do you wish to continue?"
        opened={isResetModalVisible}
        title="Warning!"
        buttonText="Yes, Reset"
      />

      <Spacer h="49px" />
      <H2>Specifications</H2>

      <FormProvider {...methods}>
        <Form>
          <Wrapper flex>
            <LeftColumn>
              <Spacer h="48px" />

              <FormElement>
                <FormLabel>Original W.O #</FormLabel>
                <Input
                  {...methods.register(
                    'workOrderNumber',
                    workOrderNumberValidation()
                  )}
                  type="text"
                  data-test="input-workOrderNumber"
                  readOnly={!canEdit}
                  aria-invalid={
                    methods.formState.errors.workOrderNumber ? 'true' : 'false'
                  }
                />
                <FormError
                  label="Original W.O #"
                  error={methods.formState.errors.workOrderNumber}
                  validationSchema={workOrderNumberValidation()}
                />
              </FormElement>
              <Spacer h="32px" />

              <H3>Material</H3>

              <Spacer h="20px" />

              <Wrapper flex alignEnd>
                <FormElement flexGrow noMarginBottom>
                  <Wrapper flex middle between>
                    <FormLabel>Material Group</FormLabel>
                  </Wrapper>

                  <Controller
                    control={methods.control}
                    name="materialGroup"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={!materialGroupOptions?.length || !canEdit}
                        aria-invalid={
                          methods.formState.errors.materialGroup
                            ? 'true'
                            : 'false'
                        }
                        options={materialGroupOptions}
                      />
                    )}
                  />

                  {materialGroupWatched?.description && (
                    <Wrapper flex>
                      <PMedium>
                        {materialGroupWatched?.description || ''}
                      </PMedium>
                    </Wrapper>
                  )}
                </FormElement>

                <Spacer w="10px" />

                {config.styleSpecifications.showMaterialUpcharge && (
                  <Wrapper flex middle>
                    <FormElement maxWidth={113} noMarginBottom>
                      <FormLabel>Upcharge %</FormLabel>
                      <Input
                        {...methods.register(
                          'materialGroupUpcharge',
                          upchargeValidation()
                        )}
                        onBlur={(e) =>
                          UtilService.withDecimal<StyleSpecifications>(
                            'materialGroupUpcharge',
                            e.target.value,
                            methods.setValue
                          )
                        }
                        type="text"
                        readOnly={!materialGroupWatched || !canEdit}
                        placeholder="0.00"
                        data-test="input-materialGroupUpcharge"
                        aria-invalid={
                          methods.formState.errors.materialGroupUpcharge
                            ? 'true'
                            : 'false'
                        }
                      />

                      <FormError
                        label="Material Group Upcharge"
                        error={
                          methods.formState.errors
                            .materialGroupUpcharge as FieldError
                        }
                        validationSchema={upchargeValidation()}
                      />
                    </FormElement>
                  </Wrapper>
                )}

                <RevolaeSQFT />
              </Wrapper>

              <Wrapper flex>
                <Wrapper flexGrow>
                  <FormError
                    label="Material Group"
                    error={methods.formState.errors.materialGroup as FieldError}
                    validationSchema={{ required: true }}
                  />
                </Wrapper>

                <Spacer w="10px" />

                <Wrapper minWidth={113} maxWidth={113}>
                  <FormError
                    label="SQFT $"
                    error={
                      methods.formState.errors.doorSquareFootPrice as FieldError
                    }
                    validationSchema={digitWithSingleDotValidation(
                      undefined,
                      'SQFT $'
                    )}
                  />
                </Wrapper>

                <Spacer w="10px" />

                <Wrapper minWidth={113} maxWidth={113}>
                  <FormError
                    label="Matching Interior SQFT $"
                    error={
                      methods.formState.errors
                        .miDoorSquareFootPrice as FieldError
                    }
                    validationSchema={digitWithSingleDotValidation(
                      undefined,
                      'Matching Interior SQFT $'
                    )}
                  />
                </Wrapper>
              </Wrapper>

              <VendorColor spacerTop={<Spacer h="50px" />} />

              <Spacer h="30px" />

              <FormElement>
                <FormLabel>Material Notes</FormLabel>
                <Textarea
                  placeholder="Material notes"
                  aria-invalid={
                    methods.formState.errors.materialNotes ? 'true' : 'false'
                  }
                  {...methods.register('materialNotes', noteValidation())}
                  readOnly={!canEdit}
                  {...testId(
                    Inovae2OStyleSpecificationsTestTextAreaEnum.MATERIAL_NOTES
                  )}
                />

                <FormError
                  label="Material Notes"
                  error={methods.formState.errors.materialNotes}
                  validationSchema={noteValidation()}
                />
              </FormElement>

              <Spacer h="30px" />

              <Wrapper flex alignStart>
                <FormElement flexGrow>
                  <Wrapper flex middle between>
                    <FormLabel>Cabinet Box Material</FormLabel>
                  </Wrapper>
                  <Controller
                    control={methods.control}
                    name="cabinetBoxMaterial"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        aria-invalid={
                          methods.formState.errors.cabinetBoxMaterial
                            ? 'true'
                            : 'false'
                        }
                        isDisabled={!cabinetBoxOptions?.length || !canEdit}
                        options={cabinetBoxOptions}
                      />
                    )}
                  />

                  <FormError
                    label="Cabinet Box Material"
                    error={
                      methods.formState.errors.cabinetBoxMaterial as FieldError
                    }
                    validationSchema={{
                      required: true,
                    }}
                  />
                </FormElement>

                <Spacer w="24px" />

                <Wrapper flex middle>
                  <FormElement maxWidth={82}>
                    <FormLabel>Upcharge %</FormLabel>
                    <Input
                      {...methods.register(
                        'cabinetBoxMaterialUpcharge',
                        upchargeValidation()
                      )}
                      onBlur={(e) =>
                        UtilService.withDecimal<StyleSpecifications>(
                          'cabinetBoxMaterialUpcharge',
                          e.target.value,
                          methods.setValue
                        )
                      }
                      type="text"
                      disabled={!cabinetBoxMaterialWatched || !canEdit}
                      placeholder="0.00"
                      data-test="input-cabinetBoxMaterialUpcharge"
                      aria-invalid={
                        methods.formState.errors.cabinetBoxMaterialUpcharge
                          ? 'true'
                          : 'false'
                      }
                    />
                    <FormError
                      label="Cabinet Box Material Upcharge"
                      error={
                        methods.formState.errors
                          .cabinetBoxMaterialUpcharge as FieldError
                      }
                      validationSchema={upchargeValidation()}
                    />
                  </FormElement>
                </Wrapper>
              </Wrapper>

              {cabinetBoxMaterialWatched?.label ===
                CabinetBoxMaterialOptionsEnum.SPECIAL && (
                <Wrapper flex>
                  <FormElement flexGrow>
                    <FormLabel>Special Cabinet Box Material</FormLabel>

                    <Input
                      type="text"
                      aria-invalid={
                        methods.formState.errors.specialCabinetBoxMaterial
                          ? 'true'
                          : 'false'
                      }
                      {...methods.register('specialCabinetBoxMaterial', {
                        maxLength: 200,
                      })}
                      readOnly={!canEdit}
                    />

                    <FormError
                      label="Special Cabinet Box Material"
                      error={methods.formState.errors.specialCabinetBoxMaterial}
                      validationSchema={{
                        maxLength: 200,
                      }}
                    />
                  </FormElement>
                </Wrapper>
              )}

              {config.styleSpecifications.showInteriorNotes && (
                <FormElement>
                  <FormLabel>Interior Notes</FormLabel>
                  <Textarea
                    placeholder="Interior notes"
                    aria-invalid={
                      methods.formState.errors.interiorNotes ? 'true' : 'false'
                    }
                    {...methods.register('interiorNotes', noteValidation())}
                    readOnly={!canEdit}
                    {...testId(
                      Inovae2OStyleSpecificationsTestTextAreaEnum.INTERIOR_NOTES
                    )}
                  />

                  <FormError
                    label="Interior Notes"
                    error={methods.formState.errors.interiorNotes}
                    validationSchema={noteValidation()}
                  />
                </FormElement>
              )}

              {isUserCSR && (
                <FormElement>
                  <FormLabel>Edge Band Color</FormLabel>
                  <Input
                    aria-invalid={
                      methods.formState.errors.edgebandColor ? 'true' : 'false'
                    }
                    {...methods.register('edgebandColor', {
                      maxLength: 200,
                      required: true,
                    })}
                    readOnly={!canEdit}
                  />

                  <FormError
                    label="Edge Band Color"
                    error={methods.formState.errors.edgebandColor}
                    validationSchema={{ maxLength: 200 }}
                  />
                </FormElement>
              )}
            </LeftColumn>

            <Spacer w="120px" />

            <RightColumn>
              <Spacer h="25px" />

              <OrderStyleNote />

              <H4>Color</H4>
              <Spacer h="32px" />
              <Wrapper flex alignStart ref={materialColorRef}>
                <FormElement flexGrow>
                  <OrderFinishLabel
                    style={{
                      productLine: selectedProductLine ?? undefined,
                    }}
                    render={(label) => <FormLabel>{label}</FormLabel>}
                  />

                  <Spacer h="30px" />

                  <OrderFinishValue
                    defaultValue="Not selected"
                    style={{
                      productLine: selectedProductLine ?? undefined,
                      ...(materialColorWatched && {
                        materialColor: {
                          id: materialColorWatched.value,
                          name: materialColorWatched.label,
                        },
                      }),
                    }}
                    render={(value) => (
                      <StaticValue
                        flex
                        middle
                        className={
                          methods.formState.errors.materialColor
                            ? 'invalid'
                            : ''
                        }
                      >
                        {value}
                      </StaticValue>
                    )}
                  />

                  <FormError
                    label="Color"
                    error={
                      (methods.formState.errors.materialColor as FieldError) ??
                      null
                    }
                    validationSchema={{ required: true }}
                  />
                </FormElement>

                {config.styleSpecifications.showMaterialColorUpcharge && (
                  <>
                    <Spacer w="24px" />

                    <Wrapper flex middle>
                      <FormElement maxWidth={113}>
                        <FormLabel>Upcharge %</FormLabel>
                        <Input
                          {...methods.register(
                            'materialColorUpcharge',
                            upchargeValidation()
                          )}
                          type="text"
                          placeholder="0.00"
                          data-test="input-materialColorUpcharge"
                          readOnly={!materialColorWatched || !canEdit}
                          onBlur={(e) =>
                            UtilService.withDecimal<StyleSpecifications>(
                              'materialColorUpcharge',
                              e.target.value,
                              methods.setValue
                            )
                          }
                          aria-invalid={
                            methods.formState.errors.materialColorUpcharge
                              ? 'true'
                              : 'false'
                          }
                        />
                        <FormError
                          label="Material Color Upcharge"
                          error={
                            methods.formState.errors
                              .materialColorUpcharge as FieldError
                          }
                          validationSchema={upchargeValidation()}
                        />
                      </FormElement>
                    </Wrapper>
                  </>
                )}
              </Wrapper>

              <MaterialColor isMaterialColorsLoading={materialColorsLoading} />

              <Spacer h="30px" />
              <GrainDirectionWrapper>
                <FormElement flexGrow>
                  <Wrapper flex middle between>
                    <FormLabel>Grain direction</FormLabel>
                  </Wrapper>
                  <Controller
                    control={methods.control}
                    name="grainDirection"
                    rules={{
                      required: styleOverride
                        ? false
                        : !!grainDirectionOptions?.length,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={!grainDirectionOptions?.length || !canEdit}
                        aria-invalid={
                          methods.formState.errors.grainDirection
                            ? 'true'
                            : 'false'
                        }
                        options={grainDirectionOptions}
                      />
                    )}
                  />
                  <FormError
                    label="Grain Direction"
                    error={
                      methods.formState.errors.grainDirection as FieldError
                    }
                    validationSchema={{
                      required: styleOverride
                        ? false
                        : !!grainDirectionOptions?.length,
                    }}
                  />
                </FormElement>
              </GrainDirectionWrapper>
            </RightColumn>
          </Wrapper>

          <Spacer h="150px" />

          <OrderFooter>
            <Wrapper flex middle between>
              <ButtonSecondary
                type="button"
                onClick={() =>
                  (canSubmit ? handleValidateOrSubmit : redirect)(true)
                }
                disabled={specSavingOnBack || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Back',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!specSavingOnBack && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>

              <ButtonPrimary
                data-test="button-StylesSpecsSubmit"
                type="button"
                onClick={() =>
                  (canSubmit || goNextAndSaveForFirstTime()
                    ? handleValidateOrSubmit
                    : redirect)(false)
                }
                disabled={specSaving || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Next',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!specSaving && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>

          {loading &&
            styleLoaderContainer &&
            createPortal(
              <LoaderFullScreen flex middle center bottom={71} top={132}>
                <Loader noSpacing size={40} />
              </LoaderFullScreen>,
              styleLoaderContainer
            )}
        </Form>
      </FormProvider>
      {PopupModal}
    </>
  );
};
