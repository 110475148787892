import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import UtilService from 'shared/services/util.service';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch, anzac } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { testId } from 'tests/utils';

import {
  ACKTestButtonEnum,
  ACKTestContainerEnum,
} from 'tests/enums/ACKTestEnums';

import { AckConsentForm } from '../Acknowledgment';

import {
  approveSpecialOrderApproval,
  disapproveSpecialOrderApproval,
} from '../store/ACKActions';

const DealerSORActionsContainer = styled(Wrapper)``;

const DealerSORActions = () => {
  const dispatch = useAppDispatch();
  const methodsContext = useFormContext<AckConsentForm>();

  const { orderId } = useParams<OrderPageParams>();

  const [approveSORModalOpened, setApproveSORModalOpened] = useState(false);

  const specialOrderApproval = useSelector(
    (state: RootState) => state.orderReducer.order?.specialOrderApproval
  );

  const canApproveOrDisapproveOnBehalfOfDealer = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const onDisapproveSORSuccessHandler = (close: () => void) => {
    toast.success(`You have successfully disapproved Special Order Request.`);

    if (close) {
      close();
      UtilService.onPopupClose();
    }
  };

  const onRequstChangesSOR = (close: () => void) => {
    if (specialOrderApproval) {
      dispatch(
        disapproveSpecialOrderApproval(
          {
            orderId,
            specialOrderApprovalId: specialOrderApproval.id,
          },
          () => onDisapproveSORSuccessHandler(close)
        )
      );
    }
  };

  const onApproveSORSuccessHandler = (close: () => void) => {
    toast.success(`You have successfully approved Special Order Request.`);

    if (close) {
      close();
      UtilService.onPopupClose();
    }
  };

  const onApproveSOR = (close: () => void) => {
    if (specialOrderApproval) {
      dispatch(
        approveSpecialOrderApproval(
          {
            orderId,
            specialOrderApprovalId: specialOrderApproval.id,
          },
          () => onApproveSORSuccessHandler(close)
        )
      );
    }
  };

  return (
    <DealerSORActionsContainer
      flex
      middle
      justifyEnd={!canApproveOrDisapproveOnBehalfOfDealer || undefined}
      {...testId(ACKTestContainerEnum.DEALER_SOR_ACTIONS)}
    >
      <ConfirmationModal
        title={
          canApproveOrDisapproveOnBehalfOfDealer
            ? 'Canceling request'
            : 'Special Order Request'
        }
        buttonText={
          canApproveOrDisapproveOnBehalfOfDealer ? 'Yes' : 'Disapprove'
        }
        htmlMessage={
          canApproveOrDisapproveOnBehalfOfDealer
            ? `Are you sure you want to cancel this Special Order <span style="font-weight: 600;">on behalf of the dealer</span>?`
            : `Are you sure you want to disapprove Special Order Request? <br/><br/> <span style="color: ${lynch}; font-weight: 600;">Reminder:</span> State your reasons in the collaboration window.`
        }
        confirmBgColor={
          canApproveOrDisapproveOnBehalfOfDealer ? anzac : undefined
        }
        confirm={onRequstChangesSOR}
        trigger={
          <ButtonSecondary {...testId(ACKTestButtonEnum.DISAPPROVE_SOR_BTN)}>
            Request Changes
          </ButtonSecondary>
        }
      />

      <Spacer w="15px" />

      <ConfirmationModal
        title={
          canApproveOrDisapproveOnBehalfOfDealer
            ? 'Approve Special Order'
            : 'Special Order Request'
        }
        buttonText="Approve"
        message={
          canApproveOrDisapproveOnBehalfOfDealer
            ? 'Are you sure you want to approve this Special Order on behalf of the dealer?'
            : 'Are you sure you want to approve Special Order Request?'
        }
        confirm={onApproveSOR}
        cancel={() => setApproveSORModalOpened(false)}
        confirmBgColor={
          canApproveOrDisapproveOnBehalfOfDealer ? anzac : undefined
        }
        opened={approveSORModalOpened}
      />

      <ButtonPrimary
        bgColor={canApproveOrDisapproveOnBehalfOfDealer ? anzac : undefined}
        {...testId(ACKTestButtonEnum.APPROVE_SOR_BTN)}
        onClick={methodsContext.handleSubmit(() =>
          setApproveSORModalOpened(true)
        )}
      >
        Approve Special Order
      </ButtonPrimary>
    </DealerSORActionsContainer>
  );
};

export default DealerSORActions;
