import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';

export const AuthPage = styled(Wrapper)`
  background-color: ${({ theme }) => theme.wildSand};
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 30px;
  width: 100%;

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;
