import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import StaticField from 'order/components/StaticField/StaticField';
import { CabinetBoxMaterialOptionsEnum } from 'order/wizard/orderStyles/enums/CabinetBoxMaterialOptionsEnum';
import { FinishColorOptionsEnum } from 'order/wizard/orderStyles/enums/FinishColorOptionsEnum';
import { MaterialGroupOptionsEnum } from 'order/wizard/orderStyles/enums/MaterialGroupOptionsEnum';

import UtilService from 'shared/services/util.service';
import { H3 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import { Inovae2OStylesOverviewTestLabelEnum } from 'tests/enums/Inovae2OStylesOverviewTestEnums';

import { Inovae20OverviewDoorBuilder } from './Inovae20OverviewDoorBuilder';

const OverviewMaterialContainer = styled(Wrapper)``;

export const Inovae20OverviewMaterial = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const config = useProductLineConfiguration();

  return (
    <OverviewMaterialContainer flex>
      <Wrapper flexGrow>
        <StaticField
          field="Original W.O #"
          value={storedStyle?.originalWorkOrderNumber || ''}
        />

        <StaticField field="Style Notes" value={storedStyle?.note || ''} />

        <Spacer h="10px" />

        <H3>Material</H3>

        <Spacer h="30px" />

        <StaticField
          field="Material Group"
          value={storedStyle?.materialGroup?.name}
          fieldTestId={Inovae2OStylesOverviewTestLabelEnum.MATERIAL_GROUP_LBL}
        />

        {config.styleSpecifications.showSQFT && (
          <>
            <StaticField
              field="SQFT $"
              value={
                UtilService.withDecimal(
                  null,
                  storedStyle?.doorSquareFootPrice?.toString() ?? ''
                ) as string
              }
            />

            <StaticField
              field="Matching Int. SQFT $"
              value={
                UtilService.withDecimal(
                  null,
                  storedStyle?.miDoorSquareFootPrice?.toString() ?? ''
                ) as string
              }
            />
          </>
        )}

        {config.styleSpecifications.showVendorColor &&
          (storedStyle?.finishColor?.name === FinishColorOptionsEnum.SW ||
            storedStyle?.finishColor?.name === FinishColorOptionsEnum.BM ||
            storedStyle?.materialGroup?.name ===
              MaterialGroupOptionsEnum.SELECT_MATERIAL) && (
            <>
              <StaticField
                field="Vendor Color Name"
                value={storedStyle?.vendorColor?.name}
              />

              <StaticField
                field="Vendor Color Code"
                value={storedStyle?.vendorColor?.code}
              />
            </>
          )}

        <StaticField
          field="Material notes"
          value={storedStyle?.materialNotes}
          fieldTestId={Inovae2OStylesOverviewTestLabelEnum.MATERIAL_NOTES_LBL}
        />

        <StaticField
          field="Cabinet Box Material"
          value={storedStyle?.cabinetBoxMaterial?.name}
        />

        {storedStyle?.cabinetBoxMaterial?.name ===
          CabinetBoxMaterialOptionsEnum.SPECIAL && (
          <StaticField
            field="Special Cabinet Box Material"
            value={storedStyle?.specialCabinetBoxMaterial}
          />
        )}

        {config.styleSpecifications.showInteriorNotes && (
          <StaticField
            field="Interior Notes"
            value={storedStyle?.interiorNotes}
          />
        )}

        <StaticField
          field="Edge Band Color"
          value={storedStyle?.edgebandColor || ''}
        />

        <Spacer h="20px" />

        <H3>Color</H3>

        <Spacer h="30px" />

        <OrderFinishLabel
          style={storedStyle}
          render={(label) => (
            <OrderFinishValue
              style={storedStyle}
              render={(value) => <StaticField field={label} value={value} />}
            />
          )}
        />

        <Spacer h="30px" />

        <StaticField
          field="Grain Direction"
          value={storedStyle?.grainDirection?.name}
        />
      </Wrapper>

      <Spacer w="216px" />

      <Inovae20OverviewDoorBuilder />
    </OverviewMaterialContainer>
  );
};
