import { call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { IWorkable } from '../interface/Workable';
import {
  CreateWorkableRequest,
  CurtainWorkablesActions,
  GetWorkableRequest,
  GetWorkablesRequest,
  ResolveWorkableRequest,
  setUnresolvedWorkablesCount,
  setWorkable,
  setWorkables,
  UpdateWorkableRequest,
  updateWorkableResolveStatus,
} from './curtainWorkablesActions';

function* getWorkables(action: Action<GetWorkablesRequest>) {
  try {
    const workables: IWorkable[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload?.orderId}/workables`
    );

    yield put(setWorkables(workables));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* createWorkable(action: Action<CreateWorkableRequest>) {
  try {
    const { orderId, ...rest } = action.payload!;
    yield call(ApiService.post, `/api/order/orders/${orderId}/workables`, rest);

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateWorkable(action: Action<UpdateWorkableRequest>) {
  try {
    const { orderId, workableId, ...rest } = action.payload!;
    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/workables/${workableId}`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* resolveWorkable(action: Action<ResolveWorkableRequest>) {
  try {
    const { orderId, workableId, resolved, styleId } = action.payload!;
    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/workables/${workableId}/${
        !resolved ? 'resolve' : 'unresolve'
      }?styleId=${styleId}`,
      {}
    );

    yield put(updateWorkableResolveStatus(workableId));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getWorkable(action: Action<GetWorkableRequest>) {
  try {
    const { orderId, workableId } = action.payload!;

    const workable: IWorkable = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/workables/${workableId}`
    );

    yield put(setWorkable(workable));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getUnresolvedWorkablesCount(action: Action<GetWorkablesRequest>) {
  try {
    const count: number = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload?.orderId}/workables/unresolved`
    );

    yield put(setUnresolvedWorkablesCount(count));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* curtainWorkablesSagas() {
  yield takeEvery(CurtainWorkablesActions.GET_WORKABLES, getWorkables);
  yield takeEvery(CurtainWorkablesActions.GET_WORKABLE, getWorkable);
  yield takeEvery(CurtainWorkablesActions.CREATE_WORKABLE, createWorkable);
  yield takeEvery(CurtainWorkablesActions.UPDATE_WORKABLE, updateWorkable);
  yield takeEvery(CurtainWorkablesActions.RESOLVE_WORKABLE, resolveWorkable);
  yield takeEvery(
    CurtainWorkablesActions.GET_UNRESOLVED_WORKABLES_COUNT,
    getUnresolvedWorkablesCount
  );
}
