import styled from 'styled-components';

import { UserRolesEnum } from 'shared/enum/userRolesEnum';
import {
  brownishPink,
  highland,
  taupe,
  windowsBlue,
} from 'shared/config/Colors';

const handleColor = (role: string) => {
  switch (role.toLowerCase()) {
    case UserRolesEnum.DealerAdmin.toLowerCase():
      return highland;
    case UserRolesEnum.DealerUser.toLowerCase():
      return windowsBlue;
    case UserRolesEnum.SalesRepresentative.toLowerCase():
      return brownishPink;
    case UserRolesEnum.CustomerSalesRepresentative.toLowerCase():
      return taupe;
    default:
      return highland;
  }
};

export const RoleLabel = styled.div<{ userRole: string }>`
  align-items: center;
  align-self: center;
  background-color: ${({ userRole }) => handleColor(userRole)};
  border-radius: 2px;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 10px;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  margin-left: 12px;
  padding: 2px 4px;
  text-transform: capitalize;
`;
