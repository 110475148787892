import { DraggableProvided } from 'react-beautiful-dnd';
import { FC, useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Input } from 'shared/components/Input';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';

import { Tooltip } from 'shared/components/Tooltip';
import SVG from 'shared/components/SVG';
import DragButton from 'shared/components/DragButton';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { Checkbox } from 'shared/components/Checkbox';
import { P } from 'shared/components/Typography';
import { Controller, useForm } from 'react-hook-form';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import UtilService from 'shared/services/util.service';
import FormError from 'shared/components/FormError';
import { drawingOptionEnums } from 'shared/enum/drawingOptionEnums';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import {
  modificationDescriptionValidation,
  onlyNumberValidation,
  onlyWholeDigitValidation,
} from 'shared/validations/validations';
import { valencia, silverSandTwo, lynch } from 'shared/config/Colors';
import { FormElement } from 'shared/components/FormElement';
import { Form } from 'shared/components/Form';

import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Info } from 'shared/components/Info';

import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { useAdditionalFields } from 'shared/hooks/useAdditionalFields';
import { Select } from 'shared/components/Select';
import { searchModifications } from './Modifications/store/lineItemModificationsActions';
import {
  addModifications,
  removeLineItemModification,
  setIsLineItemsStepDirty,
  updateLineItemModification,
} from '../store/orderLineItemsActions';
import ImageBox from '../../../../shared/components/ImageBox';
import { IModification } from './Modifications/interface/IModification';

const ModificationContainer = styled.div`
  margin-bottom: 8px;
`;

interface ModificationContainerInnerProps {
  invalid: boolean;
}

const ModificationContainerInner = styled.div<ModificationContainerInnerProps>`
  ${BoxShadowCSS}
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  padding: 16px 32px;
  position: relative;
  width: 100%;
  border: 1px solid transparent;

  ${({ invalid, theme }) =>
    invalid &&
    css`
      background-color: ${theme.fairPink};
      border-color: ${theme.valencia};
    `}
`;

interface CsrModificationFormProps {
  draggableProvided?: DraggableProvided;
  width?: string;
  height?: string;
  depth?: string;
  listPrice: string | number;
  productLineId: string;
  catalogLineItemId: string;
  styleId: string;
  modification: IModification;
}
export const CsrModificationForm: FC<CsrModificationFormProps> = ({
  draggableProvided,
  width,
  height,
  depth,
  listPrice,
  productLineId,
  catalogLineItemId,
  styleId,
  modification,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMod, setSelectedMod] = useState<SelectOptionProps | null>(
    null
  );
  const dispatch = useAppDispatch();
  const [searchOptions, setSearchOptions] = useState<SelectOptionProps[]>([]);

  const { onFormLoad, PopupModal } = usePopupMessage();
  const { onModificationLoad, FormModal, answers } = useAdditionalFields();

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const lineItemStyle = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const onModificationDeleteClickHandler = () => {
    dispatch(setIsLineItemsStepDirty(true));
    setShowDeleteModal(false);
    dispatch(removeLineItemModification(modification.id));
  };
  const perDrawingUpdatePermission = useHasPermissions(
    userPermissionsValues.PER_DRAWING_UPDATE
  );
  const searchedModifications = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.searchedModifications
  );

  const methods = useForm<IModification>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const loadModifications = () => {
    dispatch(
      searchModifications(
        {
          styleId,
          productLineId,
          catalogLineItemId,
          itemsPerPage: '10000',
          appendItems: false,
          lineItemDepth: depth?.toString() ?? '0',
          lineItemHeight: height?.toString() ?? '0',
          lineItemPrice: listPrice?.toString() ?? '0',
          lineItemWidth: width?.toString() ?? '0',
          page: '1',
          doorOverlayId: lineItemStyle?.doorOverlay?.id,
          searchTerm: '',
          ...(lineItemStyle?.cabinetBoxMaterial?.id && {
            cabinetBoxMaterialId: lineItemStyle.cabinetBoxMaterial.id,
          }),
        },
        () => false
      )
    );
  };

  const validate = () => {
    const data = methods.getValues();
    if (!data.catalogModificationId) {
      methods.trigger('catalogModificationId');
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(data.quantity) || isNaN(data.price)) {
      return;
    }

    const updatedMod = {
      ...data,
      price: +data.price,
      quantity: +data.quantity,
      completed: true,
      description: data.description,
      number: modification.number,
      replacedModId: modification.catalogModificationId,
    } as IModification;
    if (!modification.completed) {
      dispatch(addModifications([updatedMod]));
    } else {
      dispatch(updateLineItemModification(updatedMod));
    }
  };

  const handleOptionChange = (option: SelectOptionProps) => {
    setSelectedMod(option);

    const searchedMod = searchedModifications?.items.find(
      (mod) => mod.id === option.value
    );

    const updateMod: IModification = {
      ...modification,
      catalogModificationId: option.value,
      name: searchedMod?.name ?? '',
      description: searchedMod?.description ?? '',
      price: searchedMod?.price ?? 0,
      perDrawing: searchedMod?.perDrawing ?? false,
      perFactoryDrawing: searchedMod?.perFactoryDrawing ?? false,
      note: '',
      additionalNote: '',
    };

    if (searchedMod) {
      onFormLoad(searchedMod);
      onModificationLoad(searchedMod);
      methods.reset(updateMod);
      validate();
    }
  };

  useEffect(() => {
    if (modification && !selectedMod) {
      methods.reset(modification);
      if (modification.catalogModificationId) {
        setSelectedMod(
          UtilService.mapObjectToSelectOptions(
            modification,
            'catalogModificationId',
            'name',
            { customParams: ['imageUrl'] }
          ) as SelectOptionProps
        );
      }
    }
  }, [modification]);

  useEffect(() => {
    if (isOrderSalesMaterial) {
      loadModifications();
    }
  }, [isOrderSalesMaterial]);

  useEffect(() => {
    if (
      !isOrderSalesMaterial &&
      depth !== undefined &&
      width !== undefined &&
      height !== undefined &&
      listPrice !== undefined
    ) {
      loadModifications();
    }
  }, [depth, width, height, listPrice]);

  useEffect(() => {
    if (answers.length === 1) {
      methods.setValue('note', answers[0].answer);
      validate();
    }

    if (answers.length > 1) {
      methods.setValue('note', answers[0].answer);
      methods.setValue('additionalNote', answers[1].answer);
      validate();
    }
  }, [answers]);

  useEffect(() => {
    if (searchedModifications) {
      setSearchOptions(
        UtilService.mapObjectToSelectOptions(
          searchedModifications.items,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        ) as SelectOptionProps[]
      );
    }
  }, [searchedModifications]);

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        'select__option--is-selected'
      )[0];
      if (selectedEl) {
        (selectedEl.parentNode as HTMLElement).scrollTop = (
          selectedEl as HTMLElement
        ).offsetTop;
      }
    }, 10);
  };

  const handleInputChange = (search: string) => {
    if (searchedModifications) {
      setSearchOptions(
        UtilService.mapObjectToSelectOptions(
          searchedModifications.items.filter((mod) =>
            mod.name.startsWith(search.toLowerCase())
          ),
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        ) as SelectOptionProps[]
      );
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (
      inputRef.current &&
      !modification.completed &&
      !methods.formState.isDirty
    ) {
      inputRef.current.focus();
      setTimeout(() => inputRef.current?.select(), 0);
    }
  }, []);

  return (
    <ModificationContainer
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
    >
      <ModificationContainerInner
        invalid={
          modification.isValid === undefined ? false : !modification.isValid
        }
      >
        {draggableProvided && (
          <DragButton
            {...draggableProvided?.dragHandleProps}
            disabled={!canEdit}
          />
        )}
        <Form onChange={validate}>
          <Wrapper flex column>
            <Wrapper inlineFlex flexWrap gap={16}>
              <FormElement maxWidth={50}>
                <Input
                  type="string"
                  inputMode="numeric"
                  {...methods.register(
                    'quantity',
                    onlyWholeDigitValidation({ required: true }, 'Quantity')
                  )}
                  ref={(e) => {
                    methods.register('quantity').ref(e);
                    inputRef.current = e;
                  }}
                  aria-invalid={
                    methods.formState.errors.quantity ? 'true' : 'false'
                  }
                  placeholder="Qty"
                  disabled={!canEdit}
                />
                <FormError
                  label="Quantity"
                  validationSchema={onlyWholeDigitValidation(
                    { required: true },
                    'Quantity'
                  )}
                  error={methods.formState.errors.quantity}
                />
              </FormElement>
              <FormElement>
                <ImageBox imageUrl={selectedMod?.imageUrl ?? ''} />
              </FormElement>
              <FormElement flexGrow maxWidth={200}>
                <Controller
                  control={methods.control}
                  name="catalogModificationId"
                  rules={{ required: true }}
                  render={({ field: { ref } }) => (
                    <Select
                      ref={ref}
                      value={selectedMod}
                      isDisabled={!canEdit}
                      aria-invalid={
                        methods.formState.errors.catalogModificationId
                          ? 'true'
                          : 'false'
                      }
                      options={searchOptions}
                      onChange={handleOptionChange}
                      placeholder="Select"
                      onMenuOpen={onMenuOpen}
                      onInputChange={handleInputChange}
                    />
                  )}
                />
              </FormElement>
              <FormElement flexGrow maxWidth={640}>
                <Input
                  {...methods.register(
                    'description',
                    modificationDescriptionValidation()
                  )}
                  aria-invalid={
                    methods.formState.errors.description ? 'true' : 'false'
                  }
                  placeholder="Description"
                  disabled={!canEdit}
                />

                <FormError
                  label="Custom Modification Description"
                  validationSchema={modificationDescriptionValidation()}
                  error={methods.formState.errors.description}
                />
              </FormElement>
              <FormElement maxWidth={80}>
                <Input
                  {...methods.register(
                    'price',
                    onlyNumberValidation({ required: true }, 'Price')
                  )}
                  aria-invalid={
                    methods.formState.errors.price ? 'true' : 'false'
                  }
                  placeholder="$"
                  readOnly={!canEdit}
                />
                <FormError
                  label="Price"
                  validationSchema={onlyNumberValidation(
                    { required: true },
                    'Price'
                  )}
                  error={methods.formState.errors.price}
                />
              </FormElement>
              {perDrawingUpdatePermission && (
                <>
                  <Wrapper flex marginTop={15}>
                    <Checkbox
                      id={`modDrawingOption--${drawingOptionEnums.PER_DRAWING}_${modification.id}`}
                      {...methods.register('perDrawing')}
                      title="Per Draw."
                      readOnly={!canEdit}
                    />
                  </Wrapper>
                  <Wrapper flex marginTop={15}>
                    <Checkbox
                      id={`modDrawingOption--${drawingOptionEnums.PER_FACTORY_DRAWING}_${modification.id}`}
                      {...methods.register('perFactoryDrawing')}
                      title="Per Fact."
                      readOnly={!canEdit}
                    />
                  </Wrapper>
                </>
              )}
              <Wrapper flex style={{ marginBottom: '15px' }}>
                <Spacer w="14px" />
                <ButtonIcon
                  type="button"
                  onClick={() => setShowDeleteModal(true)}
                  disabled={!canEdit}
                >
                  <Wrapper withTooltip>
                    <SVG
                      icon={<TrashIcon />}
                      color={silverSandTwo}
                      hoverColor={lynch}
                    />
                    <Tooltip position="top" maxWidth={110} minWidth={110}>
                      Delete
                    </Tooltip>
                  </Wrapper>
                </ButtonIcon>
              </Wrapper>
            </Wrapper>
            <Wrapper flex between gap={16}>
              <Input
                placeholder="Note"
                {...methods.register('note', { maxLength: 40 })}
                aria-invalid={methods.formState.errors.note ? 'true' : 'false'}
                disabled={!canEdit}
              />

              <FormError
                label="Note"
                validationSchema={{ maxLength: 40 }}
                error={methods.formState.errors.note}
              />
              <Input
                placeholder="Additional Note"
                {...methods.register('additionalNote', { maxLength: 40 })}
                aria-invalid={
                  methods.formState.errors.additionalNote ? 'true' : 'false'
                }
                disabled={!canEdit}
              />
              <FormError
                label="Note"
                validationSchema={{ maxLength: 40 }}
                error={methods.formState.errors.additionalNote}
              />
            </Wrapper>
          </Wrapper>
        </Form>
      </ModificationContainerInner>

      {modification.isValid === undefined
        ? false
        : !modification.isValid && (
            <Wrapper>
              <Spacer h="5px" />
              <P fontSize={12} color={valencia} textAlign="right">
                {modification.invalidateReason}
              </P>
              <Spacer h="5px" />
            </Wrapper>
          )}

      {PopupModal}
      {FormModal}
      <ConfirmationModal
        opened={showDeleteModal}
        confirm={onModificationDeleteClickHandler}
        title="Are you sure?"
      >
        {isUserDealerOrSalesRep && modification.dealerAvailable === false && (
          <>
            <Info type="warning">
              Modification set by CSR! If you delete it - you wont be able to
              bring it back to this line item.
            </Info>

            <Spacer h="20px" />
          </>
        )}

        <P>
          This modification and its data will be deleted from line item. Change
          will be permanent once you save the line item.
        </P>
      </ConfirmationModal>
    </ModificationContainer>
  );
};
