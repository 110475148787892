import { RootState } from 'store';
import { FC, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import noImagePlaceholderSrc from 'assets/images/no-image-placeholder.svg';

import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import {
  getGrainDirections,
  setGrainDirections,
} from 'order/wizard/orderStyles/productLines/store/specifications/orderStylesSpecificationActions';

import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import FormError from 'shared/components/FormError';
import Img from 'shared/components/Img';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

interface MaterialColorProps {
  numberOfCols?: number;
  isMaterialColorsLoading?: boolean;
}

const MaterialColorContainer = styled.div<
  Required<Pick<MaterialColorProps, 'numberOfCols'>>
>`
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(${({ numberOfCols }) => numberOfCols}, 1fr);
  text-align: center;
  padding: 10px;
`;

const MaterialColorLabel = styled.div`
  color: ${({ theme }) => theme.nevada};
  font-size: 14px;
  margin-top: 5px;
  max-width: 96px;
  margin: 5px auto 0 auto;
  text-align: center;
  word-wrap: break-word;
`;

const MaterialColorEl = styled.div<{ disabled: boolean }>`
  border-radius: 50%;
  position: relative;
  display: inline-block;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}

  &:nth-child(5n) {
    margin-right: 0;
  }

  &::before {
    content: '';
    border-radius: 50%;
    height: 100px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: -2px;
    transform: translateX(-50%) scale(1.1);
    transition: 300ms ease;
    width: 100px;
    pointer-events: none;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.kashmirBlue},
      inset 0 0 0 6px white;
  }

  &.selected {
    &::before {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }

    ${MaterialColorLabel} {
      color: ${({ theme }) => theme.kashmirBlue};
      font-weight: 600;
    }
  }
`;

const MaterialColorImg = styled(Img)`
  display: block;
  margin: 0 auto;
  border-radius: 50%;
`;

const MaterialColor: FC<MaterialColorProps> = ({
  numberOfCols,
  isMaterialColorsLoading,
}) => {
  const dispatch = useAppDispatch();

  const config = useProductLineConfiguration();

  const { watch, setValue, control, clearErrors, formState } =
    useFormContext<StyleSpecifications>();

  const materialGroupWatched = watch('materialGroup');
  const materialColorWatched = watch('materialColor');

  const materialColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.materialColorOptions
  );

  const isICSProductLine = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine?.name ===
      ProductLineEnums.PRODUCT_LINE_ICS
  );

  const onlyOneOption = (materialColorOptions?.length ?? 0) === 1;

  const storedMaterialColor = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications?.materialColor
  );

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onMaterialColorChangeHandler = (
    materialColorOption: SelectOptionProps | null,
    upcharge: string = '',
    shouldDirtyMaterialColor: boolean = false
  ) => {
    if (materialColorOption) {
      setValue('materialColor', materialColorOption, {
        shouldDirty: shouldDirtyMaterialColor,
      });

      clearErrors('materialColor');

      if (
        formState.isDirty &&
        config.styleSpecifications.showMaterialColorUpcharge
      ) {
        UtilService.withDecimal<StyleSpecifications>(
          'materialColorUpcharge',
          upcharge || materialColorOption.upcharge!.toString(),
          setValue
        );
      }

      if (materialColorOption.grainPattern || styleOverride) {
        dispatch(getGrainDirections());
      }
    }
  };

  const materialColorReset = (shouldDirty: boolean) => {
    clearErrors();
    setValue('materialColor', null, { shouldDirty });

    if (config.styleSpecifications.showMaterialColorUpcharge) {
      setValue('materialColorUpcharge', '', { shouldDirty });
    }

    if (!isICSProductLine) {
      setValue('grainDirection', null, { shouldDirty });
      dispatch(setGrainDirections([]));
    }
  };

  useEffect(() => {
    if (
      storedMaterialColor &&
      materialColorOptions?.length &&
      !formState.isDirty
    ) {
      const materialColorOption = materialColorOptions?.find(
        (mc) => mc.value === storedMaterialColor?.value
      );

      onMaterialColorChangeHandler(
        materialColorOption ?? null,
        storedMaterialColor.upcharge?.toString()
      );
    }
  }, [storedMaterialColor, materialColorOptions]);

  useEffect(() => {
    if (
      formState.isDirty &&
      materialColorOptions &&
      materialGroupWatched &&
      onlyOneOption
    ) {
      setValue('materialColor', materialColorOptions[0], { shouldDirty: true });
    }
  }, [onlyOneOption]);

  useEffect(() => {
    if (formState.isDirty) {
      materialColorReset(true);
    }
  }, [materialGroupWatched]);

  useEffect(() => {
    if (formState.isDirty) {
      setValue('grainDirection', null, { shouldDirty: true });
    }
  }, [materialColorWatched]);

  return (
    <>
      {materialColorOptions === null ? (
        <P fontSize={14} color={nevada}>
          Please select material group to get color options.
        </P>
      ) : null}

      {materialColorOptions && !materialColorOptions?.length && (
        <P fontSize={14} color={nevada}>
          There are no{' '}
          {materialGroupWatched?.label ===
          SpecificationsOptionsEnum.WOOD_TYPE_PAINT
            ? 'color'
            : 'stain'}{' '}
          options for selected material group.
        </P>
      )}

      {(materialColorOptions?.length ?? 0) > 0 && (
        <Wrapper position="relative">
          {isMaterialColorsLoading && (
            <LoaderFullScreen flex middle center>
              <Loader size={40} />
            </LoaderFullScreen>
          )}

          <ScrollbarsCustom
            autoHeight
            autoHeightMax={350}
            autoHeightMin={50}
            asInput
            hasError={!!formState.errors.materialColor}
          >
            <MaterialColorContainer numberOfCols={numberOfCols!}>
              <Controller
                name="materialColor"
                rules={{ required: true }}
                control={control}
                render={() => (
                  <>
                    {materialColorOptions?.map((materialColorOption, index) => (
                      <WarningTooltip
                        key={materialColorOption.value}
                        fieldName="Material Color"
                        position={UtilService.calcTooltipPosition({
                          itemPositionIndex: index,
                          numberOfColumns: 5,
                        })}
                        withTooltip={
                          !materialColorOption.isDisabled &&
                          UtilService.shouldShowTooltip(
                            materialColorOptions,
                            materialColorWatched
                          )
                        }
                      >
                        <MaterialColorEl
                          key={materialColorOption.value}
                          className={
                            materialColorOption.value ===
                            materialColorWatched?.value
                              ? 'selected'
                              : ''
                          }
                          onClick={() =>
                            onMaterialColorChangeHandler(
                              materialColorOption,
                              undefined,
                              true
                            )
                          }
                          disabled={!canEdit}
                        >
                          <MaterialColorImg
                            width="96"
                            height="96"
                            alt={`${materialColorOption.label}`}
                            src={
                              materialColorOption.imageUrl ||
                              noImagePlaceholderSrc
                            }
                          />

                          <MaterialColorLabel>
                            {materialColorOption.label}
                          </MaterialColorLabel>
                        </MaterialColorEl>
                      </WarningTooltip>
                    ))}
                  </>
                )}
              />
            </MaterialColorContainer>
          </ScrollbarsCustom>

          <FormError
            label="Material Color"
            validationSchema={{ required: true }}
            error={formState.errors.materialColor}
          />
        </Wrapper>
      )}
    </>
  );
};

MaterialColor.defaultProps = {
  isMaterialColorsLoading: false,
  numberOfCols: 5,
};

export default MaterialColor;
