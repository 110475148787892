import { MutableRefObject, useEffect } from 'react';
import { DeepMap, FieldError, FieldErrors } from 'react-hook-form';
import { StyleFieldOption } from '../../order/wizard/orderStyles/interface/StyleFieldOption';
import { IWoodEffectField } from '../../order/wizard/orderStyles/interface/WoodEffectField';

interface UseScrollToElementParams<T, V> {
  errors: FieldErrors<T>;
  error:
    | FieldError
    | DeepMap<StyleFieldOption, FieldError>
    | (DeepMap<IWoodEffectField, FieldError> | undefined)[]
    | undefined;
  ref: V;
  fieldName: string;
}

export const useScrollToElement = <
  T,
  V extends MutableRefObject<HTMLElement | null>
>({
  errors,
  error,
  ref,
  fieldName,
}: UseScrollToElementParams<T, V>) => {
  useEffect(() => {
    if (error) {
      const isVarnishSheenFirstError = Object.keys(errors)[0] === fieldName;
      if (ref.current && isVarnishSheenFirstError) {
        ref.current.scrollIntoView();
      }
    }
  }, [error]);
};
