import styled from 'styled-components';

import { Wrapper } from 'shared/components/Wrapper';
import { Container } from 'shared/components/Container';
import { black } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';

const OrderLockedContainer = styled(Wrapper)`
  background-color: ${({ theme }) => theme.parchment};
  height: 44px;
  left: 0;
  padding: 7px 0;
  position: fixed;
  right: 0;
  top: 115px;
`;

const OrderLocked = () => {
  return (
    <OrderLockedContainer flex middle>
      <Container flex middle padding={30}>
        <P fontSize={14} color={black}>
          Order is locked for editing.
        </P>
      </Container>
    </OrderLockedContainer>
  );
};

export default OrderLocked;
