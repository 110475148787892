import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';

import { RadioColor } from 'shared/components/RadioColor';
import { silverSandTwo } from 'shared/config/Colors';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import ImgModal from 'shared/components/ImgModal';
import SVG from 'shared/components/SVG';
import WarningTooltip from 'shared/components/WarningTooltip';
import UtilService from 'shared/services/util.service';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

const ClosetHardwareColorContainer = styled.div``;

const ClosetHardwareColor = () => {
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const config = useProductLineConfiguration();

  const closetHardwareColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.closetHardwareColorOptions
  );

  const methodsContext = useFormContext<StyleDoorBuilder>();

  const closetHardwareColorWatched = methodsContext.watch(
    'closetHardwareColor'
  );

  const resetField = (field: keyof StyleDoorBuilder) => {
    methodsContext.setValue(field, '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onClickHandler = (option: SelectOptionProps) => {
    if (option.value !== closetHardwareColorWatched?.value) {
      methodsContext.setValue('closetHardwareColor', option, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      resetField('closetHardwareColor');
    }
  };

  return (
    <ClosetHardwareColorContainer>
      <Wrapper flex middle>
        <Controller
          control={methodsContext.control}
          rules={{
            required: config.doorBuilder.isClosetHardwareColorRequired,
          }}
          name="closetHardwareColor"
          render={({ field: { ref } }) => (
            <>
              {closetHardwareColorOptions?.map((closetHardwareColorOption) => (
                <WarningTooltip
                  key={closetHardwareColorOption.value}
                  fieldName="Closet Hardware Color"
                  position="left"
                  withTooltip={
                    !closetHardwareColorOption.isDisabled &&
                    UtilService.shouldShowTooltip(
                      closetHardwareColorOptions,
                      closetHardwareColorWatched
                    )
                  }
                >
                  <RadioColor
                    type="button"
                    key={closetHardwareColorOption.value}
                    id={closetHardwareColorOption.value}
                    name={closetHardwareColorOption.label}
                    aria-invalid={
                      methodsContext.formState.errors.closetHardwareColor
                        ? 'true'
                        : 'false'
                    }
                    ref={ref}
                    className={
                      closetHardwareColorWatched?.value ===
                      closetHardwareColorOption.value
                        ? 'active'
                        : ''
                    }
                    onClick={() => onClickHandler(closetHardwareColorOption)}
                    isDisabled={!canEdit}
                  >
                    {/* if has image */}
                    {closetHardwareColorOption.imageUrl && (
                      <ImgModal
                        alt={`Closet Hardware Color - ${closetHardwareColorOption.label}`}
                        height={32}
                        hover
                        position="left center"
                        key={closetHardwareColorOption.value}
                        label={closetHardwareColorOption.label}
                        nested
                        src={closetHardwareColorOption.imageUrl}
                        width={32}
                      />
                    )}

                    {/* if has not image */}
                    {!closetHardwareColorOption.imageUrl && (
                      <SVG icon={<PlatoLogoIcon />} color={silverSandTwo} />
                    )}
                  </RadioColor>
                </WarningTooltip>
              ))}
            </>
          )}
        />
      </Wrapper>

      <FormError
        label="Closet Hardware Color"
        error={
          methodsContext.formState.errors.closetHardwareColor as FieldError
        }
        validationSchema={{
          required: config.doorBuilder.isClosetHardwareColorRequired,
        }}
      />
    </ClosetHardwareColorContainer>
  );
};

export default ClosetHardwareColor;
