import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { Wrapper } from 'shared/components/Wrapper';
import { H3 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import StaticField from 'order/components/StaticField/StaticField';
import OverviewDoorBuilder from '../OverviewDoorBuilder/OverviewDoorBuilder';

const OverviewImpressionContainer = styled(Wrapper)``;

const OverviewImpression = () => {
  const finishImpressionData = useSelector(
    (state: RootState) => state.orderStylesReducer.finishImpressionData
  );

  const finishImpressionOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishImpressionOptions
  );

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  return (
    <OverviewImpressionContainer flex>
      <Wrapper flexGrow>
        <StaticField
          field="Original W.O #"
          value={storedStyle?.originalWorkOrderNumber || ''}
        />

        <StaticField field="Style Notes" value={storedStyle?.note || ''} />
        <Spacer h="10px" />

        <H3>Wood type</H3>
        <Spacer h="30px" />

        <StaticField
          field="Impression Name"
          value={
            finishImpressionOptions?.find(
              (fiOption) => fiOption.value === finishImpressionData?.id
            )?.label || ''
          }
        />

        <StaticField
          field="Wood"
          value={finishImpressionData?.woodMaterial.name || ''}
        />

        <StaticField
          field="Wood effect"
          value={
            finishImpressionData?.woodEffects
              ?.map((woodEffect) => woodEffect.name)
              .join('<br/>') || ''
          }
        />

        <StaticField field="Wood notes" value={storedStyle?.woodNotes || ''} />

        <Spacer h="20px" />
        <H3>Finish</H3>
        <Spacer h="30px" />

        <StaticField
          field="Finish color"
          value={finishImpressionData?.finishColor.name || ''}
        />

        <StaticField
          field="Finish effect"
          value={
            finishImpressionData?.finishEffects
              ?.map((finishEffect) => finishEffect.name)
              .join('<br/>') || ''
          }
        />

        <StaticField
          field="Varnish sheen"
          value={finishImpressionData?.varnishSheen.name || ''}
        />

        <StaticField
          field="Finish notes"
          value={storedStyle?.finishNotes || ''}
        />
      </Wrapper>

      <Spacer w="216px" />

      <OverviewDoorBuilder />
    </OverviewImpressionContainer>
  );
};

export default OverviewImpression;
