import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { ProductLine } from 'order/interfaces/ProductLine';

interface ProductLineConfiguration {
  styleSpecifications: {
    showFinishImpression: boolean;
    woodLabel: string;
    woodTypeLabel: string;
    showEngineeredCheckbox: boolean;
    showSelectWoodSpecies: boolean;
    showAvailableFinishesCheckbox: boolean;
    avalableEffectsOnlyLabel: string;
    showWoodInterior: boolean;
    showCabinetBoxMaterial: boolean;
    showSpecialFinishSample: boolean;
    showNaturalInterior: boolean;
    showColorSample: boolean;
    showWoodTape: boolean;
    showEdgebandColor: boolean;
    showVendorColor?: boolean;
    showSQFT?: boolean;
    showInteriorNotes?: boolean;

    // temp until 2.0 is removed from the system
    showMaterialUpcharge?: boolean;
    showMaterialColorUpcharge?: boolean;
  };
  doorBuilder: {
    showNoMidrails: boolean;
    showMetalFinish: boolean;
    showDrawerTopPanel: boolean;
    showClosetHardwareColor: boolean;
    showFaceFrame: boolean;
    showOneInchDoor: boolean;
    showOneInchThickDoorUpcharge: boolean;
    showToeSpaceType: boolean;
    isClosetHardwareColorRequired: boolean;
    showOneInchFaceFrame: boolean;

    // temp until 2.0 is removed from the system
    showInteriorNotes?: boolean;
  };
  lineItems: {
    showExposedEnds: boolean;
    showFinishedEnds: boolean;
  };
}

export const useProductLineConfiguration = (productLine?: ProductLine) => {
  const [config, setConfig] = useState<ProductLineConfiguration>({
    styleSpecifications: {
      showFinishImpression: true,
      woodLabel: 'Wood',
      woodTypeLabel: 'Wood Type',
      showEngineeredCheckbox: false,
      showSelectWoodSpecies: true,
      showAvailableFinishesCheckbox: true,
      avalableEffectsOnlyLabel: 'Available Only',
      showWoodInterior: true,
      showCabinetBoxMaterial: false,
      showSpecialFinishSample: true,
      showNaturalInterior: true,
      showColorSample: true,
      showWoodTape: false,
      showEdgebandColor: false,

      // revolae fields
      showSQFT: false,
      showVendorColor: false,
      showInteriorNotes: false,

      // temp until 2.0 is removed from the system
      showMaterialUpcharge: true,
      showMaterialColorUpcharge: true,
    },
    doorBuilder: {
      showNoMidrails: true,
      showMetalFinish: true,
      showDrawerTopPanel: true,
      showClosetHardwareColor: true,
      showFaceFrame: true,
      showOneInchDoor: true,
      showOneInchThickDoorUpcharge: true,
      showToeSpaceType: false,
      isClosetHardwareColorRequired: false,
      showOneInchFaceFrame: true,
      showInteriorNotes: true,
    },
    lineItems: {
      showExposedEnds: false,
      showFinishedEnds: true,
    },
  });

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine || productLine
  );

  useEffect(() => {
    if (selectedProductLine) {
      switch (selectedProductLine.name) {
        case ProductLineEnums.PRODUCT_LINE_CUSTOM:
          setConfig({
            ...config,
            styleSpecifications: {
              ...config.styleSpecifications,
              showVendorColor: true,
            },
            doorBuilder: { ...config.doorBuilder, showOneInchDoor: false },
          });
          break;
        case ProductLineEnums.PRODUCT_LINE_PRELUDE:
          setConfig({
            ...config,
            styleSpecifications: {
              showFinishImpression: false,
              woodLabel: 'Wood/Material',
              woodTypeLabel: 'Wood Species',
              showEngineeredCheckbox: false,
              showSelectWoodSpecies: false,
              showAvailableFinishesCheckbox: false,
              avalableEffectsOnlyLabel: 'Brushed glaze',
              showWoodInterior: false,
              showCabinetBoxMaterial: false,
              showSpecialFinishSample: false,
              showColorSample: true,
              showNaturalInterior: false,
              showWoodTape: false,
              showEdgebandColor: false,
            },
            doorBuilder: {
              showNoMidrails: false,
              showMetalFinish: false,
              showDrawerTopPanel: false,
              showClosetHardwareColor: false,
              showFaceFrame: true,
              showOneInchDoor: false,
              showOneInchThickDoorUpcharge: false,
              showToeSpaceType: false,
              isClosetHardwareColorRequired: false,
              showOneInchFaceFrame: false,
            },
            lineItems: {
              showExposedEnds: false,
              showFinishedEnds: true,
            },
          });
          break;
        case ProductLineEnums.PRODUCT_LINE_INOVAE:
          setConfig({
            ...config,
            styleSpecifications: {
              ...config.styleSpecifications,
              woodLabel: 'Wood/Material',
              showCabinetBoxMaterial: true,
              showColorSample: true,
              showEdgebandColor: true,
              showNaturalInterior: false,
              showVendorColor: true,
              showWoodInterior: false,
              showWoodTape: true,
            },
            doorBuilder: {
              ...config.doorBuilder,
              showNoMidrails: false,
              showFaceFrame: false,
              showOneInchDoor: true,
              showOneInchThickDoorUpcharge: true,
              showDrawerTopPanel: false,
              showToeSpaceType: true,
              showClosetHardwareColor: false,
              isClosetHardwareColorRequired: false,
              showOneInchFaceFrame: false,
            },
            lineItems: {
              showExposedEnds: true,
              showFinishedEnds: true,
            },
          });
          break;
        case ProductLineEnums.PRODUCT_LINE_INOVAE2O:
          setConfig({
            ...config,
            styleSpecifications: {
              ...config.styleSpecifications,
              showNaturalInterior: false,
              showEdgebandColor: true,
            },
            doorBuilder: {
              ...config.doorBuilder,
              showClosetHardwareColor: false,
            },
            lineItems: {
              showExposedEnds: true,
              showFinishedEnds: false,
            },
          });
          break;
        case ProductLineEnums.PRODUCT_LINE_ICS:
          setConfig({
            ...config,
            styleSpecifications: {
              ...config.styleSpecifications,
              showVendorColor: true,
              showNaturalInterior: false,
              showFinishImpression: false,
            },
            doorBuilder: {
              ...config.doorBuilder,
              showClosetHardwareColor: true,
              isClosetHardwareColorRequired: true,
            },
          });
          break;
        case ProductLineEnums.PRODUCT_LINE_REVOLAE:
          setConfig({
            ...config,
            styleSpecifications: {
              ...config.styleSpecifications,
              showSQFT: true,
              showVendorColor: true,
              showInteriorNotes: true,

              // temp until 2.0 is removed from the system
              showMaterialUpcharge: false,
              showMaterialColorUpcharge: false,
            },
            doorBuilder: {
              ...config.doorBuilder,
              showInteriorNotes: false,
              showClosetHardwareColor: true,
            },
            lineItems: {
              showExposedEnds: true,
              showFinishedEnds: false,
            },
          });
          break;
        default:
          setConfig({ ...config });
          break;
      }
    }
  }, [selectedProductLine]);

  return config;
};
