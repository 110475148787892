export enum PopupEventType {
  LineItemLoaded = 100,
  LineItemBeforeSave = 101,
  LineItemWidthEntered = 102,
  LineItemHeightEntered = 103,
  LineItemDepthEntered = 104,
  LineItemRightLegWidthEntered = 105,
  LineItemRightLegDepthEntered = 107,
  TagLoaded = 200,
  WallInsertPanelSelected = 300,
  WallEdgeSelected = 301,
  BaseInsertPanelSelected = 400,
  BaseEdgeSelected = 401,
  WoodSelected = 500,
  WallDoorSelected = 600,
  BaseDoorSelected = 700,
  DrawerStyleSelected = 800,
  FinishColorSelected = 900,
  SpecialFinishSampleEntered = 1000,
  ToeSpaceSelected = 1100,
  FinishedEndSelected = 1200,
  HingeTypeSelected = 1300,
}
