import { OrderTypeEnums } from 'order/enums/orderEnums';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootState } from 'store';

export enum OrderFlowsEnum {
  ORDER = 'order',
  QUOTE = 'quote',
}

export const useIsOrderFlow = (flow: OrderFlowsEnum) => {
  const location = useLocation();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const [whichFlow, setWhichFlow] = useState(
    location.pathname === `/${flow}/new`
  );

  useEffect(() => {
    setWhichFlow(
      location.pathname === `/${flow}/new` ||
        order?.type === OrderTypeEnums.Quote
    );
  }, [location.pathname, order]);

  return whichFlow;
};
