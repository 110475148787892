import { FC } from 'react';

import { UpchargesPriceReview } from 'order/wizard/orderStyles/interface/OrderPriceReview';

import { H6, PSmall } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { ContainerSmall, ContainerXSmall } from 'shared/components/Container';
import styled from 'styled-components';

const HighlightWrapper = styled(Wrapper)`
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &.strikethrough {
      text-decoration: line-through;
    }
  }
`;

interface UpchargeRowProps {
  isShowCatalogChecked: boolean;
  isListPriceSelected: boolean;
  upcharge?: UpchargesPriceReview;
  title?: string;
  className: string;
}

const UpchargeRow: FC<UpchargeRowProps> = ({
  isShowCatalogChecked,
  isListPriceSelected,
  upcharge,
  title = 'upcharge',
  className,
}) => {
  return (
    <HighlightWrapper
      flex
      middle
      className={upcharge?.isDeleted ? 'highlight strikethrough' : ''}
    >
      <Wrapper align="left" minWidth={150} isTableCell flexGrow>
        <H6>{title.toUpperCase()}</H6>
      </Wrapper>

      {isShowCatalogChecked && (
        <>
          <ContainerXSmall isTableCell border={['right']} flexGrow>
            <PSmall>{upcharge?.catalogPercentage ?? 0}%</PSmall>
          </ContainerXSmall>

          <ContainerSmall isTableCell border={['right']}>
            <PSmall>
              {isListPriceSelected
                ? upcharge?.catalogListPrice.toFixed(2) ?? '0.00'
                : upcharge?.catalogNetPrice.toFixed(2) ?? '0.00'}
            </PSmall>
          </ContainerSmall>
        </>
      )}

      <ContainerXSmall
        isTableCell
        border={['right']}
        className={upcharge?.isDeleted ? '' : className}
      >
        <PSmall>{upcharge?.percentage ?? 0}%</PSmall>
      </ContainerXSmall>

      <ContainerSmall isTableCell>
        <PSmall>
          {isListPriceSelected
            ? upcharge?.listPrice.toFixed(2) ?? '0.00'
            : upcharge?.netPrice.toFixed(2) ?? '0.00'}
        </PSmall>
      </ContainerSmall>
    </HighlightWrapper>
  );
};

export default UpchargeRow;
