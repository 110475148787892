import { FC } from 'react';
import { Modal } from 'shared/components/Modal';
import parse from 'html-react-parser';
import { ButtonPrimary } from './Button';
import { Spacer } from './Layout';
import { ModalWrapper } from './ModalWrapper';
import { Wrapper } from './Wrapper';
import { P } from './Typography';

interface InfoModalProps {
  opened: boolean;
  title: string;
  message: string;
  close: () => void;
}
export const InfoModal: FC<InfoModalProps> = ({
  close,
  message,
  title,
  opened,
}: InfoModalProps) => {
  return (
    <ModalWrapper
      modal
      lockScroll
      closeOnDocumentClick={false}
      open={opened}
      closeOnEscape={false}
    >
      {() => (
        <Modal title={title} confirm withoutFooter={0}>
          <Wrapper flex middle>
            <P>{parse(message)}</P>
          </Wrapper>
          <Spacer h="110px" />
          <Wrapper flex middle justifyEnd>
            <ButtonPrimary onClick={close}>Close</ButtonPrimary>
          </Wrapper>
        </Modal>
      )}
    </ModalWrapper>
  );
};
