import { FC } from 'react';

import { SpecialOrderDrawing } from 'order/writingHints/interface/WritingHints';

import { PSmall } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';

import { ItemRow } from '../../ItemRow';
import UtilService from '../../../../../../shared/services/util.service';

interface SpecialOrderItemsProps {
  specialOrderItems: SpecialOrderDrawing[];
}

const SpecialOrderItems: FC<SpecialOrderItemsProps> = ({
  specialOrderItems,
}) => {
  return (
    <>
      <ItemRow>
        <PSmall bold>#</PSmall>
        <PSmall bold>Special Order Items</PSmall>
      </ItemRow>

      {specialOrderItems.map((specialOrderItem, index) => (
        <>
          <ItemRow>
            <PSmall color={nevada}>{index + 1}</PSmall>
            <PSmall color={nevada}>
              #{specialOrderItem.number}, {specialOrderItem.quantity},{' '}
              {UtilService.generateLineItemNameWithDimensions(specialOrderItem)}
            </PSmall>
          </ItemRow>

          {specialOrderItem.specialOrderModifications.map((specialMod) => {
            if (!specialMod.specialOrder) {
              return null;
            }
            return (
              <ItemRow>
                <PSmall color={nevada}> </PSmall>
                <PSmall color={nevada}>
                  {specialMod.quantity ?? 1}, {specialMod.description}
                </PSmall>
              </ItemRow>
            );
          })}
        </>
      ))}
    </>
  );
};

export default SpecialOrderItems;
