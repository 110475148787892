import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AuthHeader } from 'auth/components/AuthHeader';
import { AuthPage } from 'auth/components/AuthPage';
import { ButtonPrimaryCSS } from 'shared/components/Button';
import Logo from 'shared/components/Logo';
import MetaTitle from 'shared/components/MetaTitle';
import { H1, H2, P } from 'shared/components/Typography';
import { WhiteBox, Spacer } from 'shared/components/Layout';

const ContentContainer = styled.div`
  width: 552px;
  text-align: center;

  h1 {
    margin-bottom: 0;
  }
`;

const Title = styled(H2)`
  font-size: 80px;
  line-height: 1;
  color: ${({ theme }) => theme.heather};
`;

const HomeButton = styled(Link)`
  ${ButtonPrimaryCSS};
  display: inline-flex;
`;

export const ForbiddenPage = () => {
  return (
    <AuthPage flex middle center>
      <MetaTitle title="Forbidden | Plato Connect" />

      <AuthHeader>
        <Logo />
      </AuthHeader>

      <ContentContainer>
        <WhiteBox>
          <Title>403</Title>
          <H1>Unauthorized access</H1>
          <Spacer h="8px" />

          <P>You don&apos;t have permission to view this page</P>
          <Spacer h="32px" />

          <HomeButton to="/">Go home</HomeButton>
        </WhiteBox>
      </ContentContainer>
    </AuthPage>
  );
};
