import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import SelectDealership from 'overview/dealership/components/SelectDealership';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import { ButtonLink } from 'shared/components/Button';
import { H1 } from 'shared/components/Typography';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import MetaTitle from 'shared/components/MetaTitle';

import { ManageUsersAddUserForm } from './components/ManageUsersAddUserForm';

const ContentContainer = styled.div``;

export const ManageUsersAddUserPage = () => {
  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  return (
    <>
      <MetaTitle title="Add User | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft>
          <H1>Add User</H1>

          {(isUserSalesRep || isUserCSR) && <SelectDealership />}

          {canCreateDealershipUsers && (
            <Wrapper mlAuto>
              <ButtonLink to="/overview/manage-users/add-user">
                <PlusIcon />
                <span>Add User</span>
              </ButtonLink>
            </Wrapper>
          )}
        </OverviewHeaderLeft>
      </OverviewHeader>

      <ContentContainer>
        <ManageUsersAddUserForm />
      </ContentContainer>
    </>
  );
};
