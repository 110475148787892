import { forwardRef, ImgHTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

import fallbackSrc from 'assets/images/plato-fallback.svg';

interface ImageProps {
  contain?: boolean;
}

const Image = styled.img<ImageProps>`
  display: block;
  object-position: center;
  object-fit: ${({ contain }) => (contain ? 'contain' : 'cover')};
`;

interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  contain?: boolean;
}

const Img = forwardRef<HTMLImageElement, ImgProps>((props, ref) => {
  const [src, setSrc] = useState<string>(props.src ?? '');

  const onImgLoadErrorHandler = () => {
    setSrc(fallbackSrc);
  };

  useEffect(() => {
    if (props.src) {
      setSrc(props.src);
    } else {
      setSrc(fallbackSrc);
    }
  }, [props.src]);

  return (
    <Image
      ref={ref}
      {...props}
      src={src}
      onError={onImgLoadErrorHandler}
      contain={props.contain}
    />
  );
});

export default Img;
