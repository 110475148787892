import { Control, Controller } from 'react-hook-form';
import { FC, useState } from 'react';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  OrderStylizationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import UtilService from 'shared/services/util.service';
import { OrderFormFields } from '../OrderForm';

import {
  allSalesAidsOptions,
  allStandardAndDisplayOptions,
  csrOrderTypeOptions,
  dealerOrderTypeOptions,
  dealerOrderTypeOptionsNonEditMode,
  newQuoteTypeOptions,
} from './OrderTypeOptionSets';

interface OrderTypeSelectProps {
  orderType: string;
  orderStylizationType: string;
  methodContextControl: Control<OrderFormFields>;
}

const OrderTypeSelect: FC<OrderTypeSelectProps> = ({
  orderType,
  orderStylizationType,
  methodContextControl,
}) => {
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [isAsap] = useOrderFlow('asap');

  const [isDuplicate] = useOrderFlow('duplicate');

  const isAsapOrDuplicate = isAsap || isDuplicate;

  const [typeOptionsCSR] = useState(csrOrderTypeOptions);
  const [typeOptionsDealer] = useState(dealerOrderTypeOptions);

  const [typeOptionsDealerNonEditMode] = useState(
    dealerOrderTypeOptionsNonEditMode
  );

  const [typeOptionsNewQuote] = useState(newQuoteTypeOptions);

  const isSalesAids = UtilService.isSalesAids(+orderType);

  const isOptionDisabled = (
    option: SelectOptionProps<
      OrderTypeEnums,
      OrderStylizationTypeEnums | unknown
    >
  ) => {
    if (isSalesAids) {
      return (
        (option.disabledForStylization?.includes(+orderStylizationType) ??
          false) ||
        allStandardAndDisplayOptions.includes(+option.value)
      );
    }

    return allSalesAidsOptions.includes(+option.value);
  };

  const mapOrderTypeOptions = (
    option: SelectOptionProps<
      OrderTypeEnums,
      OrderStylizationTypeEnums | unknown
    >
  ) =>
    ({
      ...option,
      isDisabled: isAsapOrDuplicate ? isOptionDisabled(option) : false,
    } as SelectOptionProps<OrderTypeEnums, OrderStylizationTypeEnums>);

  const getOrderTypeOptions = () => {
    if (isQuoteFlow) {
      return typeOptionsNewQuote;
    }

    if (isUserDealerOrSalesRep) {
      return editMode ? typeOptionsDealer : typeOptionsDealerNonEditMode;
    }

    return typeOptionsCSR;
  };

  const getOrderTypeByValue = () => {
    if (isQuoteFlow) {
      return typeOptionsNewQuote;
    }

    if (isUserDealerOrSalesRep) {
      return typeOptionsDealer;
    }

    return typeOptionsCSR;
  };

  return (
    <Controller
      control={methodContextControl}
      name="type"
      render={({ field: { onChange, ref, value } }) => (
        <Select
          ref={ref}
          options={getOrderTypeOptions().map(mapOrderTypeOptions)}
          value={getOrderTypeByValue().find((x) => x.value === value)}
          onChange={(val: SelectOptionProps) => onChange(val.value)}
          isDisabled={!canEdit}
        />
      )}
    />
  );
};

export default OrderTypeSelect;
