import styled from 'styled-components';

export const Textarea = styled.textarea`
  background: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.mischka};
  color: ${({ theme }) => theme.nevada};
  display: block;
  font-size: 16px;
  line-height: 20px;
  min-height: 100px;
  padding: 12px 16px;
  resize: vertical;
  transition: border-color 300ms, background-color 300ms ease;
  width: 100%;

  &:focus {
    box-shadow: none;
  }

  &[aria-invalid='true'] {
    background-color: ${({ theme }) => theme.fairPink};
    border-color: ${({ theme }) => theme.valencia};
    color: ${({ theme }) => theme.valencia};
  }

  &:not([aria-invalid='true']):focus {
    background-color: ${({ theme }) => theme.catskillWhite};
    border-color: ${({ theme }) => theme.pigeonPost};
  }

  &::placeholder {
    color: ${({ theme }) => theme.silverSand};
  }

  &[disabled],
  &:read-only {
    cursor: not-allowed;
    opacity: 1;
    background-color: ${({ theme }) => theme.wildSand};
    border-color: ${({ theme }) => theme.mischka};
    color: ${({ theme }) => theme.silverSandTwo};
  }
`;
