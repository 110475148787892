import { AnyAction } from 'redux';

import { Style } from '../../orderStyles/interface/Style';

import {
  ImportActions,
  SetImportStylesModalMetaPayload,
} from './ImportActions';
import { LineItem } from '../../orderStyles/interface/LineItem';
import { ImportStylesModalStepsEnums } from '../enums/ImportStylesModalStepsEnums';
import { ImportStylesModalHeaderEnums } from '../../orderStyles/enums/ImportStylesModalEnum';
import { ImportLineItemsFlow } from '../../orderStyles/enums/ImportLineItemsFlow';

export interface ImportStylesInitialStore {
  step: ImportStylesModalStepsEnums;
  title: ImportStylesModalHeaderEnums;
  previousTitle: string | null;
  desc: ImportStylesModalHeaderEnums;
  orderId: string | null;
  style: Style | null;
  selectedStyles: Style[];
  selectedStyle: Style | null;
  styles: Style[] | null;
  lineItems: LineItem[] | null;
  importLineItemsFlow: ImportLineItemsFlow;
  selectedLineItems: LineItem[] | null;
}

const initialState: ImportStylesInitialStore = {
  step: 0,
  title: ImportStylesModalHeaderEnums.ORDER_SEARCH_TITLE,
  previousTitle: null,
  desc: ImportStylesModalHeaderEnums.ORDER_SEARCH_DESC,
  orderId: null,
  style: null,
  selectedStyles: [] as Style[],
  selectedStyle: null,
  styles: null,
  lineItems: null,
  importLineItemsFlow: ImportLineItemsFlow.COPY_LINE_ITEMS,
  selectedLineItems: null,
};

export const importReducer = (
  state = initialState as ImportStylesInitialStore,
  action: AnyAction
) => {
  switch (action.type) {
    case ImportActions.CLEAR_IMPORT_STYLES_REDUCER:
      return { ...initialState };
    case ImportActions.SELECT_STYLE_FOR_IMPORT: {
      const styleExistsInSelected = state.selectedStyles.some(
        (selectedStyle) => selectedStyle.id === action.payload.id
      );

      return {
        ...state,
        selectedStyles: styleExistsInSelected
          ? state.selectedStyles.filter(
              (selectedStyle) => selectedStyle.id !== action.payload.id
            )
          : [...state.selectedStyles, action.payload as Style],
      };
    }
    case ImportActions.SELECT_SINGLE_STYLE_FOR_IMPORT:
      return {
        ...state,
        selectedStyle: action.payload as Style,
      };
    case ImportActions.SET_IMPORT_LINE_ITEMS_FLOW:
      return {
        ...state,
        importLineItemsFlow: action.payload as ImportLineItemsFlow,
      };
    case ImportActions.SET_STYLES_FOR_IMPORT:
      return {
        ...state,
        styles: action.payload as Style[],
        ...(!action.payload && { selectedStyles: [] }),
      };
    case ImportActions.SET_LINE_ITEMS_FOR_IMPORT:
      return {
        ...state,
        lineItems: action.payload as LineItem[],
      };
    case ImportActions.ADD_SELECTED_LINE_ITEM: {
      const currentSelectedLineItems = state.selectedLineItems;
      const isAlreadyAdded = currentSelectedLineItems?.find(
        (selectedLineItem) =>
          selectedLineItem.lineItemId === action.payload.lineItemId
      );

      let updatedSelectedLineItems: LineItem[] | undefined;
      if (currentSelectedLineItems) {
        if (isAlreadyAdded) {
          updatedSelectedLineItems = currentSelectedLineItems.filter(
            (lineItem) => lineItem.lineItemId !== action.payload.lineItemId
          );
        } else {
          updatedSelectedLineItems = [
            ...currentSelectedLineItems,
            action.payload as LineItem,
          ];
        }
      }
      return {
        ...state,
        selectedLineItems: currentSelectedLineItems
          ? (updatedSelectedLineItems as LineItem[])
          : ([action.payload] as LineItem[]),
      };
    }
    case ImportActions.RESET_SELECTED_LINE_ITEMS:
      return {
        ...state,
        selectedLineItems: null,
      };
    case ImportActions.SET_IMPORT_STYLES_MODAL_META: {
      const { desc, step, title, previousTitle, orderId, style } =
        action.payload as SetImportStylesModalMetaPayload;

      return {
        ...state,
        step,
        desc,
        title,
        previousTitle: previousTitle ?? state.previousTitle,
        orderId: orderId ?? state.orderId,
        style: style ?? state.style,
      };
    }
    case ImportActions.SET_IMPORT_STYLES_STEP:
      return {
        ...state,
        step: action.payload as ImportStylesModalStepsEnums,
      };
    default:
      return state;
  }
};
