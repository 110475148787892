import { FC, useState } from 'react';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import { CabinetBoxMaterialOptionsEnum } from 'order/wizard/orderStyles/enums/CabinetBoxMaterialOptionsEnum';
import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { kashmirBlue, silverChaliceTwo } from 'shared/config/Colors';

import { H5, H6, PSmall } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import StyleDetailsField from './StyleDetailsField';
import { DoorCodesTableRow, DoorCodesTableRowContent } from './DoorCodesTable';
import { StyleDetailsFieldWrapper } from './StyleDetailsFieldWrapper';
import { ExpandButton } from '../OrderStyleContainers';

interface Inovae2OOrderStyleDetailsProps {
  styleDetails: Style;
  highlight?: boolean;
}

const Inovae2OOrderStyleDetails: FC<Inovae2OOrderStyleDetailsProps> = ({
  styleDetails,
  highlight,
}) => {
  const { highlightStyleField } = useHighlightHelper(highlight);

  const config = useProductLineConfiguration(styleDetails.productLine);

  const [doorExpandebleOpen, setDoorExpandebleOpen] = useState(true);
  const [hardwareExpandableOpen, setHardwareExpandableOpen] = useState(true);
  const [colorExpandableOpen, setColorExpandableOpen] = useState(true);
  const [materialExpandableOpen, setMaterialExpandableOpen] = useState(true);

  return (
    <>
      <Spacer h="16px" />
      {styleDetails.originalWorkOrderNumber && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="W.O.#"
            fieldName={FieldName.OrderWorkOrderNumber}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.originalWorkOrderNumber}
          />
          <Spacer h="16px" />
        </Wrapper>
      )}
      {styleDetails.note && (
        <Wrapper marginLeft={25}>
          <StyleDetailsField
            fieldLabel="STYLE NOTES"
            fieldName={FieldName.StyleNote}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.note}
            asNote
          />
          <Spacer h="32px" />
        </Wrapper>
      )}

      <Wrapper flex>
        <StyleDetailsFieldWrapper flex between middle>
          <Wrapper flex gap={5}>
            <ExpandButton
              onClick={() => setMaterialExpandableOpen(!materialExpandableOpen)}
              iconColor={silverChaliceTwo}
              hoverIconColor={kashmirBlue}
              className={
                materialExpandableOpen
                  ? 'expandable-box-button open'
                  : 'expandable-box-button'
              }
            >
              <ArrowUpIcon />
            </ExpandButton>
            <H5>MATERIAL</H5>
          </Wrapper>
        </StyleDetailsFieldWrapper>
      </Wrapper>

      {materialExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex alignStart>
            {styleDetails.materialGroup?.name && (
              <StyleDetailsField
                fieldLabel="MATERIAL GROUP"
                fieldName={FieldName.StyleMaterialGroup}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.materialGroup?.name}
              />
            )}

            {styleDetails.edgebandColor && (
              <StyleDetailsField
                fieldLabel="EDGE BAND COLOR"
                fieldName={FieldName.StyleEdgebandColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.edgebandColor}
              />
            )}
          </Wrapper>

          {(styleDetails.materialGroup?.name || styleDetails.edgebandColor) && (
            <Spacer h="16px" />
          )}

          {config.styleSpecifications.showSQFT &&
            (styleDetails.doorSquareFootPrice ||
              styleDetails.miDoorSquareFootPrice) && (
              <>
                <Wrapper flex alignStart>
                  {styleDetails.doorSquareFootPrice !== undefined && (
                    <StyleDetailsField
                      fieldLabel="SQFT $"
                      fieldName={FieldName.StyleSQFT}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.doorSquareFootPrice}
                    />
                  )}

                  {styleDetails.miDoorSquareFootPrice !== undefined && (
                    <StyleDetailsField
                      fieldLabel="MATCHING INT. SQFT $"
                      fieldName={FieldName.StyleMatchingInteriorSQFT}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.miDoorSquareFootPrice}
                    />
                  )}
                </Wrapper>

                <Spacer h="16px" />
              </>
            )}
          {config.styleSpecifications.showVendorColor &&
            (styleDetails.vendorColor?.name ||
              styleDetails.vendorColor?.code) && (
              <>
                <Wrapper flex alignStart>
                  {styleDetails.vendorColor?.name && (
                    <StyleDetailsField
                      fieldLabel="VENDOR COLOR NAME"
                      fieldName={FieldName.StyleVendorColorName}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.vendorColor?.name}
                    />
                  )}

                  {styleDetails.vendorColor?.code && (
                    <StyleDetailsField
                      fieldLabel="VENDOR COLOR CODE"
                      fieldName={FieldName.StyleVendorColorCode}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.vendorColor?.code}
                    />
                  )}
                </Wrapper>

                <Spacer h="16px" />
              </>
            )}

          <Wrapper flex alignStart>
            {styleDetails.cabinetBoxMaterial?.name && (
              <StyleDetailsField
                fieldLabel="CABINET BOX MATERIAL"
                fieldName={FieldName.StyleCabinetBoxMaterial}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.cabinetBoxMaterial?.name}
              />
            )}

            {styleDetails.cabinetBoxMaterial &&
              styleDetails.cabinetBoxMaterial.name ===
                CabinetBoxMaterialOptionsEnum.SPECIAL &&
              styleDetails.specialCabinetBoxMaterial && (
                <StyleDetailsField
                  fieldLabel="SPECIAL CABINET BOX MATERIAL"
                  fieldName={FieldName.StyleSpecialCabinetBoxMaterial}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.specialCabinetBoxMaterial}
                />
              )}
          </Wrapper>

          {config.styleSpecifications.showInteriorNotes &&
            styleDetails.interiorNotes && (
              <Wrapper marginLeft={25}>
                <Spacer h="16px" />
                <StyleDetailsField
                  fieldLabel="INTERIOR NOTES"
                  fieldName={FieldName.StyleInteriorNotes}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.interiorNotes}
                  asNote
                />
              </Wrapper>
            )}
        </Wrapper>
      )}
      {styleDetails.materialNotes && materialExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="16px" />
          <StyleDetailsField
            fieldLabel="MATERIAL NOTES"
            fieldName={FieldName.StyleMaterialNotes}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.materialNotes}
            asNote
          />
        </Wrapper>
      )}

      <Spacer h="32px" />

      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setColorExpandableOpen(!colorExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            colorExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>COLOR</H5>
      </Wrapper>
      {colorExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />

          <Wrapper flex>
            <OrderFinishLabel
              style={styleDetails}
              render={(label) => (
                <OrderFinishValue
                  style={styleDetails}
                  defaultValue=""
                  render={(value) => (
                    <StyleDetailsField
                      fieldLabel={label.toUpperCase()}
                      fieldName={FieldName.StyleMaterialColor}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={value}
                    />
                  )}
                />
              )}
            />

            {styleDetails.grainDirection?.name && (
              <StyleDetailsField
                fieldLabel="GRAIN DIRECTION"
                fieldName={FieldName.StyleGrainDirection}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.grainDirection?.name}
              />
            )}
          </Wrapper>
        </Wrapper>
      )}
      <Spacer h="32px" />

      <Wrapper flex>
        <StyleDetailsFieldWrapper>
          <Wrapper flex gap={5}>
            <ExpandButton
              onClick={() => setDoorExpandebleOpen(!doorExpandebleOpen)}
              iconColor={silverChaliceTwo}
              hoverIconColor={kashmirBlue}
              className={
                doorExpandebleOpen
                  ? 'expandable-box-button open'
                  : 'expandable-box-button'
              }
            >
              <ArrowUpIcon />
            </ExpandButton>
            <H5>DOOR &amp; DRAWER FRONT</H5>
          </Wrapper>

          {doorExpandebleOpen && (
            <Wrapper style={{ marginLeft: '25px' }}>
              <Spacer h="32px" />

              {styleDetails.doorOverlay?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DOOR OVERLAY"
                    fieldName={FieldName.StyleDoorOverlay}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.doorOverlay?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}
              {styleDetails.frameStyle?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="FRAME STYLE"
                    fieldName={FieldName.StyleFrameStyle}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.frameStyle?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {styleDetails.doorEdgeBand?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DOOR EDGE BAND"
                    fieldName={FieldName.StyleDoorEdgeBand}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.doorEdgeBand?.name}
                  />
                  <Spacer h="16px" />
                </>
              )}

              {config.doorBuilder.showInteriorNotes &&
                styleDetails.interiorNotes && (
                  <Wrapper marginLeft={25}>
                    <Spacer h="16px" />
                    <StyleDetailsField
                      fieldLabel="INTERIOR NOTES"
                      fieldName={FieldName.StyleInteriorNotes}
                      highlightStyleField={highlightStyleField}
                      styleId={styleDetails.id!}
                      fieldValue={styleDetails.interiorNotes}
                      asNote
                    />
                    <Spacer h="16px" />
                  </Wrapper>
                )}

              {styleDetails.drawerBox?.name && (
                <>
                  <StyleDetailsField
                    fieldLabel="DRAWER BOX"
                    fieldName={FieldName.StyleDrawerBox}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.drawerBox?.name}
                  />

                  <Spacer h="16px" />
                </>
              )}
              {styleDetails.toeSpaceType?.name && (
                <StyleDetailsField
                  fieldLabel="TOE SPACE TYPE"
                  fieldName={FieldName.StyleToeSpaceType}
                  highlightStyleField={highlightStyleField}
                  styleId={styleDetails.id!}
                  fieldValue={styleDetails.toeSpaceType?.name}
                />
              )}
            </Wrapper>
          )}
        </StyleDetailsFieldWrapper>

        <Wrapper flex column>
          <DoorCodesTableRow flex between>
            <div />
            <DoorCodesTableRowContent flex between>
              <H5>WALL</H5>
              <Spacer w="15px" />
              <H5>BASE</H5>
              <Spacer w="15px" />
              <H5>DRAWER</H5>
            </DoorCodesTableRowContent>
          </DoorCodesTableRow>

          {doorExpandebleOpen && (
            <>
              <Spacer h="32px" />

              <DoorCodesTableRow flex between>
                <H6>DOOR STYLE</H6>

                <DoorCodesTableRowContent flex between>
                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleWallDoorStyle,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.wallDoorStyle?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleBaseDoorStyle,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.baseDoorStyle?.name || ''}
                  </PSmall>

                  <Spacer w="15px" />

                  <PSmall
                    className={highlightStyleField(styleDetails.id!, [
                      FieldName.StyleMaterialDrawer,
                    ])}
                    wordBreak="break-all"
                  >
                    {styleDetails.materialDrawer?.name || ''}
                  </PSmall>
                </DoorCodesTableRowContent>
              </DoorCodesTableRow>
            </>
          )}
        </Wrapper>
      </Wrapper>

      {styleDetails.doorNotes && doorExpandebleOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="16px" />
          <StyleDetailsField
            fieldLabel="DOOR NOTES"
            fieldName={FieldName.StyleDoorNotes}
            highlightStyleField={highlightStyleField}
            styleId={styleDetails.id!}
            fieldValue={styleDetails.doorNotes}
            asNote
          />
        </Wrapper>
      )}
      <Spacer h="32px" />

      <Wrapper flex gap={5}>
        <ExpandButton
          onClick={() => setHardwareExpandableOpen(!hardwareExpandableOpen)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            hardwareExpandableOpen
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
        <H5>HARDWARE</H5>
      </Wrapper>
      {hardwareExpandableOpen && (
        <Wrapper marginLeft={25}>
          <Spacer h="32px" />
          <Wrapper flex>
            {styleDetails.doorHardware?.name && (
              <StyleDetailsField
                fieldLabel="DOOR HARDWARE"
                fieldName={FieldName.StyleDoorHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.doorHardware?.name}
              />
            )}

            {styleDetails.hingeType?.name && (
              <StyleDetailsField
                fieldLabel="HINGE TYPE"
                fieldName={FieldName.StyleHingeType}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeType?.name}
              />
            )}
          </Wrapper>

          {(styleDetails.doorHardware?.name ||
            styleDetails.hingeType?.name) && <Spacer h="16px" />}

          <Wrapper flex>
            {styleDetails.drawerHardware?.name && (
              <StyleDetailsField
                fieldLabel="DRAWER HARDWARE"
                fieldName={FieldName.StyleDrawerHardware}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.drawerHardware?.name}
              />
            )}

            {styleDetails.hingeColor?.name && (
              <StyleDetailsField
                fieldLabel="HINGE COLOR"
                fieldName={FieldName.StyleHingeColor}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hingeColor?.name}
              />
            )}
          </Wrapper>

          {config.doorBuilder.showClosetHardwareColor &&
            styleDetails.closetHardwareColor?.name && (
              <>
                <Spacer h="16px" />
                <Wrapper flex>
                  <StyleDetailsField
                    fieldLabel="CLOSET HARDWARE COLOR"
                    fieldName={FieldName.StyleClosetHardwareColor}
                    highlightStyleField={highlightStyleField}
                    styleId={styleDetails.id!}
                    fieldValue={styleDetails.closetHardwareColor?.name}
                  />
                </Wrapper>
              </>
            )}

          {styleDetails.hardwareNotes && (
            <Wrapper marginLeft={25}>
              <Spacer h="16px" />
              <StyleDetailsField
                fieldLabel="HARDWARE NOTES"
                fieldName={FieldName.StyleHardwareNotes}
                highlightStyleField={highlightStyleField}
                styleId={styleDetails.id!}
                fieldValue={styleDetails.hardwareNotes}
                asNote
              />
            </Wrapper>
          )}
        </Wrapper>
      )}
      <Spacer h="16px" />
    </>
  );
};

export default Inovae2OOrderStyleDetails;
