import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as DealershipSettingsIcon } from 'assets/icons/dealership-settings.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as ManageUsersIcon } from 'assets/icons/manage-users.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import { ReactComponent as QuoteIcon } from 'assets/icons/quote.svg';
import { ReactComponent as BrowseCatalogsIcon } from 'assets/icons/browse-catalogs.svg';
import { ReactComponent as NoteSolidIcon } from 'assets/icons/note-solid.svg';

import SVG from 'shared/components/SVG';
import { Spacer } from 'shared/components/Layout';
import Logo from 'shared/components/Logo';
import UtilService from 'shared/services/util.service';
import { TabbableButton } from 'shared/components/Button';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useLogout } from 'shared/hooks/useLogout';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import BrowseCatalogs from './BrowseCatalogs';
import BrowseForms from './BrowseForms';

const OverviewSidebarContainer = styled.div`
  background: ${({ theme }) => theme.white};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  min-width: 244px;
  padding: 32px 12px;
  position: fixed;
  top: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  z-index: 10;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 68px;
  margin-left: 21px;
`;

const StyledLinkCSS = css`
  display: flex;
  align-items: center;
  padding: 12px 22px;
  border-radius: 8px;
  margin: 2px 0;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.osloGray};

  &:hover {
    background-color: ${({ theme }) => theme.wildSand};
  }

  svg path {
    fill: ${({ theme }) => theme.osloGray};
  }

  &.active-link {
    color: ${({ theme }) => theme.lynch};
    background-color: ${({ theme }) => theme.wildSand};

    svg path {
      fill: ${({ theme }) => theme.lynch};
    }
  }
`;

const StyledLink = styled(Link)`
  ${StyledLinkCSS}
  position: relative;

  &[aria-disabled='true'] {
    color: ${({ theme }) => UtilService.colorWithOpacity(theme.osloGray, 0.5)};

    svg path {
      fill: ${({ theme }) => UtilService.colorWithOpacity(theme.osloGray, 0.5)};
    }
  }

  &:hover {
    > ${Tooltip} {
      opacity: 1;
    }
  }
`;

export const StyledButton = styled(TabbableButton)`
  ${StyledLinkCSS};
  width: 100%;
`;

const StyledA = styled.a`
  ${StyledLinkCSS}
`;

const StyledLinkText = styled.span`
  margin-left: 6px;
`;

const OverviewSidebarFooter = styled(Wrapper)``;

export const OverviewSidebar = () => {
  const { pathname } = useLocation();
  const logout = useLogout();

  const canReadDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USERS_READ
  );

  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canReadDealership = useHasPermissions(
    userPermissionsValues.DEALERSHIP_READ
  );

  const dealershipUsersPageUrl = `/overview/${
    canCreateDealershipUsers ? 'manage-' : ''
  }users`;

  return (
    <OverviewSidebarContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <StyledLink
        to="/overview/dashboard"
        className={`${pathname.includes('dashboard') && 'active-link'}`}
        data-test="link-dashboard"
      >
        <DashboardIcon />
        <StyledLinkText>Order Board</StyledLinkText>
      </StyledLink>

      <StyledLink
        to="/overview/quotes"
        className={`${pathname.includes('quotes') && 'active-link'}`}
        data-test="link-quotes"
      >
        <QuoteIcon />
        <StyledLinkText>Custom Quotes</StyledLinkText>
      </StyledLink>

      <StyledA
        href="https://www.platowoodwork.com/"
        target="_blank"
        rel="noopener noreferrer"
        data-test="link-website"
      >
        <WebsiteIcon />
        <StyledLinkText>Visit website</StyledLinkText>
      </StyledA>

      {canReadDealershipUsers && (
        <StyledLink
          to={dealershipUsersPageUrl}
          className={`${pathname.includes('manage-users') && 'active-link'}`}
          data-test="link-manageUsers"
        >
          <ManageUsersIcon />
          <StyledLinkText>
            {canCreateDealershipUsers ? 'Manage ' : ''}Users
          </StyledLinkText>
        </StyledLink>
      )}

      {/* <StyledLink
        // This is a temporary link until the user-management page is created
        to="/overview/edit-user-details"
        className={`${pathname.includes('user-management') && 'active-link'}`}
        data-test="link-userManagement"
      >
        <UserManagementIcon />
        <StyledLinkText>User Management</StyledLinkText>
      </StyledLink> */}

      {canReadDealership && (
        <StyledLink
          to="/overview/dealership-settings"
          className={`${
            pathname.includes('dealership-settings') && 'active-link'
          }`}
          data-test="link-manageDealership"
        >
          <DealershipSettingsIcon />
          <StyledLinkText>Dealership Settings</StyledLinkText>
        </StyledLink>
      )}

      {isUserCSR && (
        <StyledLink
          to="/overview/dealerships"
          className={`${pathname.includes('dealerships') && 'active-link'}`}
          data-test="link-dealerships"
        >
          <DealershipSettingsIcon />
          <StyledLinkText>Dealerships</StyledLinkText>
        </StyledLink>
      )}

      <OverviewSidebarFooter mtAuto>
        <BrowseCatalogs>
          <StyledButton type="button">
            <SVG icon={<BrowseCatalogsIcon width="16" height="16" />} />
            <Spacer w="10px" />
            Browse Catalogs
          </StyledButton>
        </BrowseCatalogs>

        <BrowseForms>
          <StyledButton type="button">
            <SVG icon={<NoteSolidIcon width="16" height="16" />} />
            <Spacer w="10px" />
            Browse Forms
          </StyledButton>
        </BrowseForms>

        <StyledButton type="button" onClick={logout} data-test="button-logout">
          <LogoutIcon />
          <StyledLinkText>Logout</StyledLinkText>
        </StyledButton>
      </OverviewSidebarFooter>
    </OverviewSidebarContainer>
  );
};
