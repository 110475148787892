import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import LineItemSubcategory from './LineItemSubcategory';

const LineItemSubcategoriesContainer = styled.div`
  margin: 20px 0 20px 20px;
`;

const LineItemSubcategories = () => {
  const subcategories = useSelector(
    (state: RootState) => state.orderLineItemsReducer.subcategories
  );

  return (
    <LineItemSubcategoriesContainer>
      {subcategories?.map((subcat) => (
        <LineItemSubcategory key={subcat.id} subcategory={subcat} />
      ))}
    </LineItemSubcategoriesContainer>
  );
};

export default LineItemSubcategories;
