import { Wrapper } from 'shared/components/Wrapper';
import styled from 'styled-components';

export const DoorUpchargesContainer = styled.div``;

export const DoorUpchagesColumnsWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DoorUpchagesColumns = styled(Wrapper)`
  margin-bottom: 8px;
`;

export const LabelWrapper = styled(Wrapper)`
  line-height: 20px;
`;

export const Column = styled.div<{ maxWidth?: number }>`
  flex: 1;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};

  font-size: 14px;
  color: ${({ theme }) => theme.nevada};
`;

export const ColumnHeader = styled.div<{ maxWidth?: number }>`
  flex: 1;
  padding: 4px 0px;
`;
export const DoorUpchargeField = styled(Wrapper)<{ disabled?: boolean }>`
  margin-bottom: 16px;

  ${({ disabled }) => disabled && `opacity: 0.4; pointer-events: none;`}

  &:last-child {
    margin-bottom: 0;
  }
`;
