import { ProductLine } from 'order/interfaces/ProductLine';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { LineItemDetailsImportError } from '../../interface/LineItem';
import { OrderStylesInitialStore } from './orderStylesReducer';

export enum orderStylesActions {
  CHANGE_STYLES_STEP = 'CHANGE_STYLES_STEP',
  CLEAR_STYLE_SPECS_OPTIONS = 'CLEAR_STYLE_SPECS_OPTIONS',
  CLEAR_STYLES = 'CLEAR_STYLES',
  CREATE_STYLE = 'CREATE_STYLE',
  DUPLICATE_STYLE = 'DUPLICATE_STYLE',
  GET_STYLE = 'GET_STYLE',
  SET_IS_STYLES_STEP_DIRTY = 'SET_IS_STYLES_STEP_DIRTY',
  SET_STYLE = 'SET_STYLE',
  SET_STYLE_FIELDS = 'SET_STYLE_FIELDS',
  SET_STYLE_ID = 'SET_STYLE_ID',
  SET_STYLE_NAME = 'SET_STYLE_NAME',
  SET_STYLES_MODAL_OPENED = 'SET_STYLES_MODAL_OPENED',
  SET_STYLES_PRODUCT_LINE = 'SET_STYLES_PRODUCT_LINE',
  UPDATE_STYLE_NAME = 'UPDATE_STYLE_NAME',

  SET_REPLACEMENT_STYLE = 'SET_REPLACEMENT_STYLE',

  GET_PRODUCT_LINES_FOR_REPLACEMENT = 'GET_PRODUCT_LINES_FOR_REPLACEMENT',
  SET_PRODUCT_LINES_FOR_REPLACEMENT = 'SET_PRODUCT_LINES_FOR_REPLACEMENT',

  CONVERT_STYLE = 'CONVERT_STYLE',
}

export const changeStylesStep = (step: StylesStepsEnum) => ({
  type: orderStylesActions.CHANGE_STYLES_STEP,
  payload: step,
});

export const setIsStylesStepDirty = (isDirty: boolean) => ({
  type: orderStylesActions.SET_IS_STYLES_STEP_DIRTY,
  payload: isDirty,
});

export const clearStyles = () => ({
  type: orderStylesActions.CLEAR_STYLES,
});

export const setStylesProductLine = (productLine: ProductLine) => ({
  type: orderStylesActions.SET_STYLES_PRODUCT_LINE,
  payload: productLine,
});

export const setStylesModalOpened = (modalOpenedForStyleId: string | null) => ({
  type: orderStylesActions.SET_STYLES_MODAL_OPENED,
  payload: modalOpenedForStyleId,
});

export interface CreateStyleRequest {
  orderId: string;
  name: string;
  productLineId: string;
}

export const createStyle = (
  payload: CreateStyleRequest,
  onSuccess: (styleId: string) => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesActions.CREATE_STYLE,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface GetStyleRequest {
  orderId: string;
  styleId: string;
  overrideReason?: string;
}

export const getStyle = (
  payload: GetStyleRequest,
  onSuccess?: () => void,
  loading?: (isLoaded: false) => void
) => ({
  type: orderStylesActions.GET_STYLE,
  payload,
  onSuccess,
  loading,
});

export const clearStyleSpecificationsOptions = () => ({
  type: orderStylesActions.CLEAR_STYLE_SPECS_OPTIONS,
});

export const setStyle = (payload: Style) => ({
  type: orderStylesActions.SET_STYLE,
  payload,
});

export const setStyleId = (styleId: string) => ({
  type: orderStylesActions.SET_STYLE_ID,
  payload: styleId,
});

export type StyleFields = Pick<
  OrderStylesInitialStore,
  'productLine' | 'name' | 'styleId'
>;

export const setStyleFields = (payload: StyleFields) => ({
  type: orderStylesActions.SET_STYLE_FIELDS,
  payload,
});

export interface UpdateStyleNameRequest {
  orderId: string;
  styleId: string;
  name: string;
}

export const updateStyleName = (
  payload: UpdateStyleNameRequest,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesActions.UPDATE_STYLE_NAME,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const setStyleName = (name: string) => ({
  type: orderStylesActions.SET_STYLE_NAME,
  payload: name,
});

export const duplicateStyle = (
  payload: GetStyleRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesActions.DUPLICATE_STYLE,
  payload,
  onSuccess,
  loading,
});

export interface GetReplacementProductLinesRequest {
  productLineId: string;
  styleId: string;
  orderId: string;
}

export const getReplacementProductLines = (
  payload: GetReplacementProductLinesRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesActions.GET_PRODUCT_LINES_FOR_REPLACEMENT,
  payload,
  onSuccess,
  loading,
});

export const setReplacementProductLines = (
  productLines: ProductLine[] | null
) => ({
  type: orderStylesActions.SET_PRODUCT_LINES_FOR_REPLACEMENT,
  payload: productLines,
});

export const setReplacementStyle = (style: Style | null) => ({
  type: orderStylesActions.SET_REPLACEMENT_STYLE,
  payload: style,
});

export interface ConvertStyleRequest {
  orderId: string;
  styleId: string;
  productLineId: string;
}

export const convertStyle = (
  payload: ConvertStyleRequest,
  onSuccess: (response: LineItemDetailsImportError[]) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesActions.CONVERT_STYLE,
  payload,
  onSuccess,
  loading,
});
