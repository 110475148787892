import { FC, ReactElement } from 'react';

import { StyleWarning } from 'order/warnings/interface/Wranings';

import { nevada } from 'shared/config/Colors';
import { PSmall } from 'shared/components/Typography';
import { StyleHeader } from 'shared/components/StyleHeader';

import { ItemRow } from '../ItemRow';

interface StyleWarningsProps {
  styleWraning: StyleWarning;
  noStyleHeader?: boolean;
  sectionTitle?: ReactElement | null;
  withNumberOrder?: boolean;
  fontWeight?: number;
}

const StyleWarnings: FC<StyleWarningsProps> = ({
  styleWraning,
  noStyleHeader,
  sectionTitle,
  withNumberOrder,
  fontWeight,
}) => {
  return (
    <>
      {sectionTitle}

      {!noStyleHeader && (
        <StyleHeader fontWeight={fontWeight} fontSize={12}>
          #{styleWraning.number} {styleWraning.name}
        </StyleHeader>
      )}

      {styleWraning.warnings.map((w, swwIndex) => (
        <ItemRow key={`${styleWraning.id}--${w.entityId}`}>
          {withNumberOrder && <PSmall color={nevada}>{swwIndex + 1}</PSmall>}

          <PSmall
            fontWeight={fontWeight}
            color={withNumberOrder ? nevada : undefined}
          >
            {w.message.replace('\\n', ' ')}
          </PSmall>
        </ItemRow>
      ))}

      {styleWraning.lineItemWarnings.map((lineItemWarning) => (
        <div key={lineItemWarning.id}>
          {lineItemWarning.reason.length > 0 && (
            <ItemRow>
              {withNumberOrder && <PSmall />}

              <PSmall
                fontWeight={fontWeight}
                color={withNumberOrder ? nevada : undefined}
              >
                #{lineItemWarning.number} {lineItemWarning.code} -{' '}
                {lineItemWarning.reason}
              </PSmall>
            </ItemRow>
          )}

          {lineItemWarning.warnings.map((w) => {
            return (
              <ItemRow key={`${lineItemWarning.id}--${w.entityId}`}>
                {withNumberOrder && <PSmall />}

                <PSmall
                  fontWeight={fontWeight}
                  color={withNumberOrder ? nevada : undefined}
                >
                  #{lineItemWarning.number} {lineItemWarning.code} - {w.message}
                </PSmall>
              </ItemRow>
            );
          })}

          {lineItemWarning.modificationWarnings.map((modWarning) => (
            <div key={modWarning.id}>
              {modWarning.reason.length > 0 && (
                <ItemRow key={`${modWarning.id}--reason`}>
                  {withNumberOrder && <PSmall />}

                  <PSmall
                    fontWeight={fontWeight}
                    color={withNumberOrder ? nevada : undefined}
                  >
                    #{lineItemWarning.number} {lineItemWarning.code} -{' '}
                    {modWarning.name.toUpperCase()} - {lineItemWarning.reason}
                  </PSmall>
                </ItemRow>
              )}

              {modWarning.warnings.map((wr) => (
                <ItemRow key={`${modWarning.id}--${wr.entityId}`}>
                  {withNumberOrder && <PSmall />}

                  <PSmall
                    fontWeight={fontWeight}
                    color={withNumberOrder ? nevada : undefined}
                  >
                    #{lineItemWarning.number} {lineItemWarning.code} -{' '}
                    {modWarning.name.toUpperCase()} - {wr.message}
                  </PSmall>
                </ItemRow>
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

StyleWarnings.defaultProps = {
  noStyleHeader: false,
  sectionTitle: null,
  withNumberOrder: false,
  fontWeight: 600,
};

export default StyleWarnings;
