import { FC } from 'react';
import styled from 'styled-components';

import { DoorBuilderBreakingChange } from 'order/wizard/orderStyles/interface/DoorBuilderBreakingChange';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { H2, P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { anzac, nevada } from 'shared/config/Colors';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

const DoorBuilderBreakingChangesContainer = styled.div`
  padding: 19px 24px;
  min-width: 433px;
  max-width: 433px;
`;

const BreakingChange = styled(P)`
  margin-bottom: 10px;
`;

interface DoorBuilderBreakingChangesProps {
  breakingChanges: DoorBuilderBreakingChange[] | null;
  dismiss: () => void;
  confirm: () => void;
  loading: boolean;
}

const DoorBuilderBreakingChanges: FC<DoorBuilderBreakingChangesProps> = ({
  breakingChanges,
  dismiss,
  confirm,
  loading,
}) => {
  return (
    <DoorBuilderBreakingChangesContainer>
      <H2>Invalid items and modifiers</H2>

      <Spacer h="25px" />

      <P color={nevada}>
        Some line items or modifiers were impacted by the change you made,
        rendering them invalid. Additional modification will be required for
        certain modifiers or line items.
      </P>

      <Spacer h="10px" />

      <P color={nevada}>Would you like to save this change?</P>

      <Spacer h="25px" />

      {breakingChanges?.map((breakingChange) => (
        <div key={breakingChange.lineItemId}>
          <BreakingChange color={nevada}>
            Item Invalid {breakingChange.lineItemNumber}:{' '}
            {breakingChange.lineItemCode}
          </BreakingChange>

          {breakingChange.breakingChangeModifications.map(
            (breakingChangeMod) => (
              <BreakingChange>
                Item {breakingChange.lineItemNumber} Invalid Modifier:{' '}
                {breakingChangeMod.name}
              </BreakingChange>
            )
          )}
        </div>
      ))}

      <Spacer h="20px" />

      <Wrapper flex justifyEnd>
        <ButtonSecondary onClick={dismiss}>Cancel</ButtonSecondary>

        <Spacer w="16px" />

        <ButtonPrimary onClick={confirm} disabled={loading} bgColor={anzac}>
          Yes, Save
          <Loader size={16} insideButton noSpacing hidden={!loading} />
        </ButtonPrimary>
      </Wrapper>
    </DoorBuilderBreakingChangesContainer>
  );
};

export default DoorBuilderBreakingChanges;
