import { useHistory, useLocation } from 'react-router';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { IModification } from 'order/wizard/orderLineItems/components/Modifications/interface/IModification';
import { OrderLineItem } from 'order/wizard/orderLineItems/OrderLineItem';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import OrderStyle from 'order/components/OrderStyle/OrderStyle';
import OrderStyleExpandable from 'order/wizard/orderOverview/OrderStyleExpandable';

import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import { Checkbox } from 'shared/components/Checkbox';
import { FloatingCheckbox } from 'shared/components/FloatingCheckbox';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useQueryParams } from 'shared/hooks/useQueryParams';

import { ButtonPrimary, TabbableButton } from 'shared/components/Button';

import { OrderAsapParams } from '../../interface/OrderAsapParams';

import {
  checkAsapItem,
  copySelectedItems,
  getAsapItems,
  setAsapStep,
} from '../../store/OrderAsapActions';

import {
  CheckedLineItem,
  CheckedModification,
  CheckedStyle,
} from '../../interface/CheckedItems';
import { lockOrderForEdit } from '../../../../store/orderActions';

const OrderSelectItemsContainer = styled.div``;

const OrderSelectItems = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const [itemsLoading, setItemsLoading] = useState(true);

  const styles = useSelector(
    (state: RootState) => state.orderAsapReducer.styles
  );

  const lineItems = useSelector(
    (state: RootState) => state.orderAsapReducer.lineItems
  );

  const checkedStyles = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedStyles
  );

  const checkedLineItems = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedLineItems
  );

  const checkedModifications = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedModifications
  );

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const isOrderSalesMaterial = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.stylizationType ===
      OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const [copingItemsLoading, setCopingItemsLoading] = useState(false);

  const [queryFields] = useQueryParams<OrderAsapParams>([
    'orderId',
    'newOrderId',
  ]);

  const onCheckAllInStyleHandler = (checked: boolean, styleId: string) => {
    dispatch(
      checkAsapItem({
        checked,
        itemId: styleId,
        itemType: 'Styles',
      })
    );

    if (lineItems?.length) {
      // select all line items
      lineItems
        .filter((lineItem) => lineItem.styleId === styleId)
        .forEach((lineItem) => {
          dispatch(
            checkAsapItem({
              checked,
              itemId: lineItem.lineItemId!,
              itemType: 'LineItems',
            })
          );

          lineItem.modifications.forEach((modification) => {
            dispatch(
              checkAsapItem({
                checked,
                itemId: modification.id,
                itemType: 'Modifications',
              })
            );
          });
        });
    }
  };

  const checkAllInStyleIsChecked = (styleId: string) => {
    const lineItemsInStyle = lineItems?.filter(
      (lineItem) => lineItem.styleId === styleId
    );

    const styleIsSelected = checkedStyles.includes(styleId);

    const lineItemsAreSelected = lineItemsInStyle?.every(
      (cLineItem) =>
        checkedLineItems.includes(cLineItem.lineItemId) &&
        cLineItem.modifications.every((mod) =>
          checkedModifications.includes(mod.id)
        )
    );

    return styleIsSelected && lineItemsAreSelected;
  };

  const onSelectClearAllItemsClickHandler = (checked: boolean) => {
    if (!isOrderSalesMaterial) {
      styles
        ?.filter((style) => !style.override)
        ?.forEach((style) => {
          dispatch(
            checkAsapItem({
              checked,
              itemId: style.id!,
              itemType: 'Styles',
            })
          );
        });
    }

    lineItems
      ?.filter(
        (lineItem) =>
          !styles?.find((style) => style.id === lineItem.styleId)?.override
      )
      .forEach((lineItem) => {
        dispatch(
          checkAsapItem({
            checked,
            itemId: lineItem.lineItemId,
            itemType: 'LineItems',
          })
        );

        lineItem.modifications.forEach((mod) => {
          dispatch(
            checkAsapItem({
              checked,
              itemId: mod.id,
              itemType: 'Modifications',
            })
          );
        });
      });
  };

  const lockOrderForEditSuccessHandler = () => {
    history.push(`/order/${queryFields?.newOrderId}/overview`, location.state);
  };

  const redirectToOrderOverview = () => {
    setCopingItemsLoading(true);

    if (queryFields) {
      dispatch(
        lockOrderForEdit(
          { orderId: queryFields.newOrderId },
          lockOrderForEditSuccessHandler,
          undefined,
          setCopingItemsLoading
        )
      );
    }
  };

  const onCopyItemsSuccessHandler = () => {
    if (!isOrderSalesMaterial) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('step');
      queryParams.append('step', '2');
      dispatch(setAsapStep(2));

      history.replace(
        `${location.pathname}?${queryParams.toString()}`,
        location.state
      );
    } else {
      redirectToOrderOverview();
    }

    toast.success(
      `You have successfully copy selected items to the newly created order.`
    );
  };

  const reduceMods = (mods: IModification[]) => {
    return mods.reduce((agg, curr) => {
      if (checkedModifications.includes(curr.id)) {
        agg.push({ modificationId: curr.id } as CheckedModification);
      }

      return agg;
    }, [] as CheckedModification[]);
  };

  const reduceLineItems = (styleId: string) => {
    return lineItems!.reduce((agg, curr) => {
      if (
        styleId === curr.styleId &&
        checkedLineItems.includes(curr.lineItemId!)
      ) {
        agg.push({
          lineItemId: curr.lineItemId!,
          modifications: reduceMods(curr.modifications),
        } as CheckedLineItem);
      }

      return agg;
    }, [] as CheckedLineItem[]);
  };

  const reduceLineItemsWithoutStyles = () => {
    return lineItems!.reduce((agg, curr) => {
      if (checkedLineItems.includes(curr.lineItemId!)) {
        agg.push({
          lineItemId: curr.lineItemId!,
          modifications: reduceMods(curr.modifications ?? []),
        } as CheckedLineItem);
      }

      return agg;
    }, [] as CheckedLineItem[]);
  };

  const reduceStyles = () => {
    return styles!.reduce((agg, curr) => {
      if (checkedStyles.includes(curr.id!)) {
        agg.push({
          styleId: curr.id!,
          lineItems: reduceLineItems(curr.id!),
        } as CheckedStyle);
      }

      return agg;
    }, [] as CheckedStyle[]);
  };

  const onContinueClickHandler = () => {
    if (styles) {
      const data = !isOrderSalesMaterial
        ? reduceStyles()
        : reduceLineItemsWithoutStyles();

      if (queryFields) {
        setCopingItemsLoading(true);
        dispatch(
          copySelectedItems(
            {
              newOrderId: queryFields.newOrderId,
              orderId: queryFields.orderId,
              ...(!isOrderSalesMaterial && { styles: data as CheckedStyle[] }),
              ...(isOrderSalesMaterial && {
                lineItems: data as CheckedLineItem[],
              }),
              isOrderSalesMaterial,
            },
            onCopyItemsSuccessHandler,
            setCopingItemsLoading
          )
        );
      }
    }
  };

  useEffect(() => {
    if (queryFields && order && customProductLine) {
      // call styles line items and modifications
      dispatch(
        getAsapItems(
          {
            orderId: queryFields.orderId,
            isOrderSalesMaterial,
            productLineId: customProductLine?.id ?? '',
          },
          setItemsLoading
        )
      );
    }
  }, [queryFields, order, customProductLine]);

  const canSelectOrClear =
    (styles?.length ?? 0) > 0 || (lineItems?.length ?? 0) > 0;

  return (
    <OrderSelectItemsContainer>
      {itemsLoading && <Loader size={50} />}

      {!isOrderSalesMaterial && !itemsLoading && !styles?.length && (
        <EmptyState
          title="No Styles, Line Items or Modifications"
          icon={<PlatoLogoIcon />}
          iconSize={80}
          message="There are no styles, line items or modification in this order."
        />
      )}

      {isOrderSalesMaterial && !itemsLoading && !lineItems?.length && (
        <EmptyState
          title="No Line Items"
          icon={<PlatoLogoIcon />}
          iconSize={80}
          message="There are no line items in this order."
        />
      )}

      {!itemsLoading && (
        <>
          {canSelectOrClear && (
            <Wrapper flex middle>
              <TabbableButton
                color={lynch}
                onClick={() => onSelectClearAllItemsClickHandler(true)}
              >
                Select all
              </TabbableButton>
              <Spacer w="5px" />
              <P color={lynch}>-</P>
              <Spacer w="5px" />
              <TabbableButton
                color={lynch}
                onClick={() => onSelectClearAllItemsClickHandler(false)}
              >
                Clear
              </TabbableButton>
            </Wrapper>
          )}

          {isOrderSalesMaterial && (
            <>
              <Spacer h="30px" />
              {lineItems?.map((lineItem) => (
                <OrderLineItem
                  key={lineItem.lineItemId}
                  lineItem={lineItem}
                  canCheck
                />
              ))}
            </>
          )}

          {!isOrderSalesMaterial &&
            styles?.map((style) => (
              <Wrapper position="relative" key={style.id}>
                <FloatingCheckbox top={45}>
                  <Wrapper withTooltip={!!style.override} cursorDefault>
                    <Checkbox
                      id={`check-all-in-style--${style.id!}`}
                      checked={checkAllInStyleIsChecked(style.id!)}
                      onChange={(e) =>
                        onCheckAllInStyleHandler(e.target.checked, style.id!)
                      }
                      disabled={!!style.override}
                    />

                    {style.override && (
                      <Tooltip position="right">
                        Overriden Style cannot be selected when doing order
                        duplication or creation of ASAP order.
                      </Tooltip>
                    )}
                  </Wrapper>
                </FloatingCheckbox>

                <OrderStyleExpandable
                  style={style}
                  expandedByDefault
                  disableExpandCollapse
                >
                  <OrderStyle
                    canCheck
                    loadOrderStyles={() => null}
                    style={style}
                    withLineItems
                  />
                </OrderStyleExpandable>
              </Wrapper>
            ))}
        </>
      )}

      <OrderFooter>
        <Wrapper flex middle justifyEnd>
          <ButtonPrimary
            disabled={
              copingItemsLoading ||
              (!checkedStyles?.length && !isOrderSalesMaterial)
            }
            onClick={onContinueClickHandler}
          >
            {order && !isOrderSalesMaterial ? 'Continue' : 'Finish'}
            <Loader
              hidden={!copingItemsLoading}
              insideButton
              size={16}
              noSpacing
            />
          </ButtonPrimary>
        </Wrapper>
      </OrderFooter>
    </OrderSelectItemsContainer>
  );
};

export default OrderSelectItems;
