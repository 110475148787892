import { createGlobalStyle, css } from 'styled-components';

import * as variables from './Variables';
import * as colors from './Colors';

export const BoxShadowCSS = css`
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(24, 32, 51, 0.05);
`;

export const OutlineCSS = css`
  &:not(:hover):not(:active) {
    border-radius: 6px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.white},
      0 0 0 2px ${({ theme }) => theme.pigeonPost};
  }
`;

export const GlobalStyles = createGlobalStyle`
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;

      &:focus {
          outline: none;
      }
    }

    [style*=overflow] { 
      scroll-behavior: smooth; 
    }

    body {
      color: ${colors.mineShaft};
      font-family: "Open Sans", sans-serif;
      font-size: ${variables.fontSize}px;
      line-height: ${variables.lineHeight};


      &.noScroll {
          overflow: hidden !important;
      }
    }
    
    button,
    input:not([type="checkbox"]):not([type="radio"]),
    optgroup,
    select,
    textarea {
      font-family: inherit;
      appearance: none;
    }
    
    button {
      border-radius: 4px;

      path {
          transition: 300ms;
      }

      rect {
          transition: 300ms;
      }
      
      &:focus-visible {
          ${OutlineCSS}
      }
    }

    a {
      cursor: pointer;
      text-decoration: none;
      transition: 300ms;

      &:focus-visible {
          ${OutlineCSS}
      }
    }

    li {
        list-style-type: none;
    }
`;
