import {
  OrderStylesAdjustments,
  OrderStylesAdjustmentsRequest,
} from 'order/wizard/orderStyles/interface/Adjustments';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

export enum orderStylesAdjustmentsActions {
  CREATE_ADJUSTMENT = 'CREATE_ADJUSTMENT',
  SET_STYLE_ADJUSTMENT_DATA = 'SET_STYLE_ADJUSTMENT_DATA',

  GET_PRICE_SCHEDULES = 'GET_PRICE_SCHEDULES',
}

export const saveAdjustment = (
  payload: OrderStylesAdjustmentsRequest,
  onSuccess: () => void,
  onFailed: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesAdjustmentsActions.CREATE_ADJUSTMENT,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const setStyleAdjustmentsData = (payload: OrderStylesAdjustments) => ({
  type: orderStylesAdjustmentsActions.SET_STYLE_ADJUSTMENT_DATA,
  payload,
});

export const getPriceShedules = (
  onSuccess: (priceSchedules: SelectOptionProps[]) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderStylesAdjustmentsActions.GET_PRICE_SCHEDULES,
  onSuccess,
  loading,
});
