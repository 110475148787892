import { CSR } from 'shared/interface/CSR';

export enum orderAssigneesActions {
  CLEAR_ORDER_ASSIGNEES = 'CLEAR_ORDER_ASSIGNEES',
  SEARCH_CSRS = 'SEARCH_CSRS',
  SET_SEARCHED_CSRS = 'SET_SEARCHED_CSRS',
  SELECT_CSR = 'SELECT_CSR',
  DESELECT_CSR = 'DESELECT_CSR',
  ADD_ASSIGNEE = 'ADD_ASSIGNEE',
  REMOVE_ASSIGNEE = 'REMOVE_ASSIGNEE',
  UPDATE_CSR_PRIMARY = 'UPDATE_CSR_PRIMARY',
  SET_CSR_PRIMARY = 'SET_CSR_PRIMARY',
  CLEAR_SELECTED_CSRS = 'CLEAR_SELECTED_CSRS',
  GET_ORDER_ASSIGNEES = 'GET_ORDER_ASSIGNEES',
  SET_ORDER_ASSIGNEES = 'SET_ORDER_ASSIGNEES',
}

export const clearOrderAssignees = () => ({
  type: orderAssigneesActions.CLEAR_ORDER_ASSIGNEES,
});

export interface SearchCSRSRequest {
  searchTerm: string;
}

export const searchCSRS = (
  payload: SearchCSRSRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: (csrs: CSR[]) => void
) => ({
  type: orderAssigneesActions.SEARCH_CSRS,
  payload,
  loading,
  onSuccess,
});

export interface AddOrderAssigneeRequest {
  orderId: string;
  user: CSR;
}

export const addOrderAssignee = (
  payload: AddOrderAssigneeRequest,
  onSuccess?: () => void,
  loading?: (userId: string) => void
) => ({
  type: orderAssigneesActions.ADD_ASSIGNEE,
  payload,
  onSuccess,
  loading,
});

export interface RemoveOrderAssigneeRequest {
  orderId: string;
  userId: string;
}

export const removeOrderAssignee = (
  payload: RemoveOrderAssigneeRequest,
  onSuccess?: () => void,
  loading?: (userId: string) => void
) => ({
  type: orderAssigneesActions.REMOVE_ASSIGNEE,
  payload,
  onSuccess,
  loading,
});

export const setSearchedCSRS = (users: CSR[] | null) => ({
  type: orderAssigneesActions.SET_SEARCHED_CSRS,
  payload: users,
});

export const selectCSR = (payload: CSR) => ({
  type: orderAssigneesActions.SELECT_CSR,
  payload,
});

export const deselectCSR = (userId: string) => ({
  type: orderAssigneesActions.DESELECT_CSR,
  payload: userId,
});

export interface UpdateCSRPrimaryPayload {
  userId: string;
  primary: boolean;
}

export interface SetCSRasPrimaryRequest {
  userId: string;
  orderId: string;
  primary: boolean;
}

export const setCSRasPrimary = (
  payload: SetCSRasPrimaryRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderAssigneesActions.SET_CSR_PRIMARY,
  payload,
  onSuccess,
  loading,
});

export const updateCSRPrimary = (payload: UpdateCSRPrimaryPayload) => ({
  type: orderAssigneesActions.UPDATE_CSR_PRIMARY,
  payload,
});

export const clearSelectedCSRS = () => ({
  type: orderAssigneesActions.CLEAR_SELECTED_CSRS,
});

export interface GetOrderAssigneesRequest {
  orderId: string;
}

export const getOrderAssignees = (payload: GetOrderAssigneesRequest) => ({
  type: orderAssigneesActions.GET_ORDER_ASSIGNEES,
  payload,
});

export const setOrderAssignees = (users: CSR[]) => ({
  type: orderAssigneesActions.SET_ORDER_ASSIGNEES,
  payload: users,
});
