import styled from 'styled-components';
import { RootState } from 'store';
import { FC, ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FinishColorOptionsEnum } from 'order/wizard/orderStyles/enums/FinishColorOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import FormError from 'shared/components/FormError';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { MaterialGroupOptionsEnum } from 'order/wizard/orderStyles/enums/MaterialGroupOptionsEnum';

const VendorColorContainer = styled.div``;

interface VendorColorProps {
  spacerTop?: ReactElement;
  spacerBottom?: ReactElement;
}

const VendorColor: FC<VendorColorProps> = ({ spacerTop, spacerBottom }) => {
  const config = useProductLineConfiguration();

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const methodsContext = useFormContext<StyleSpecifications>();
  const finishColorWatched = methodsContext.watch('finishColor');
  const materialGroupWatched = methodsContext.watch('materialGroup');

  const shouldShowVendorColor =
    config.styleSpecifications.showVendorColor &&
    (finishColorWatched?.label === FinishColorOptionsEnum.SW ||
      finishColorWatched?.label === FinishColorOptionsEnum.BM ||
      materialGroupWatched?.label === MaterialGroupOptionsEnum.SELECT_MATERIAL);

  useEffect(() => {
    if (!shouldShowVendorColor) {
      methodsContext.unregister(['vendorColorCode', 'vendorColorName']);
    }
  }, [shouldShowVendorColor]);

  return !shouldShowVendorColor ? null : (
    <VendorColorContainer>
      {spacerTop ?? null}

      <FormElement>
        <FormLabel>Vendor Color Name</FormLabel>

        <Input
          {...methodsContext.register('vendorColorName', { maxLength: 80 })}
          readOnly={!canEdit}
          aria-invalid={
            methodsContext.formState.errors.vendorColorName ? 'true' : 'false'
          }
        />

        <FormError
          error={methodsContext.formState.errors.vendorColorName}
          label="Vendor Color Name"
          validationSchema={{ maxLength: 80 }}
        />
      </FormElement>

      <FormElement>
        <FormLabel>Vendor Color Code</FormLabel>

        <Input
          {...methodsContext.register('vendorColorCode', { maxLength: 50 })}
          readOnly={!canEdit}
          aria-invalid={
            methodsContext.formState.errors.vendorColorCode ? 'true' : 'false'
          }
        />

        <FormError
          error={methodsContext.formState.errors.vendorColorCode}
          label="Vendor Color Code"
          validationSchema={{ maxLength: 50 }}
        />
      </FormElement>

      {spacerBottom ?? null}
    </VendorColorContainer>
  );
};

export default VendorColor;
