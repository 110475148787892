import parse from 'html-react-parser';
import styled from 'styled-components';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as RemoveShoppingCartIcon } from 'assets/icons/removeShoppingCart.svg';
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';

import OrderStyle from 'order/components/OrderStyle/OrderStyle';
import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';

import EmptyState from 'shared/components/EmptyState';
import SwitchButton from 'shared/components/SwitchButton';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { Divider, Spacer, WhiteBox } from 'shared/components/Layout';
import { H2, H6, P, PMedium, PSmall, SPAN } from 'shared/components/Typography';
import { Info } from 'shared/components/Info';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';

import {
  kashmirBlue,
  lynch,
  nevada,
  silverChaliceTwo,
} from 'shared/config/Colors';

import {
  ContainerMedium,
  ContainerSmall,
  ContainerXSmall,
} from 'shared/components/Container';

import {
  FinishedEndType,
  LineItemPriceReview,
  NetUpchargeType,
  OrderStylePriceReview,
  PremiumSelectionType,
  UpchargeType,
} from '../orderStyles/interface/OrderPriceReview';

import {
  LineItemHingePositionEnum,
  LineItemPositionEnum,
  LineItemExposedEndsPositionEnum,
} from '../orderLineItems/enums/LineItemPositionEnum';

import DoorCodesRow from './components/DoorCodesRow';
import FinishedEndRow from './components/FinishedEndRow';
import NetUpchargeRow from './components/NetUpchargeRow';
import OrderReviewPricingSalesAidsOrder from './OrderReviewPricingSalesAidsOrder';
import PremiumSelectionPriceRow from './components/PremiumSelectionPriceRow';
import TotalPrice from './components/TotalPrice';
import TotalPriceRow from './components/TotalPriceRow';
import UpchargeRow from './components/UpchargeRow';
import { Style } from '../orderStyles/interface/Style';

const ExpandButton = styled(ButtonIcon)`
  svg {
    transition: transform 500ms;
    transform: rotateZ(180deg);
  }

  &.open {
    svg {
      transform: rotateZ(0);
    }
  }
`;

const TotalPriceContainer = styled(Wrapper)`
  padding: 0 36px;
`;

const HighlightedWrapper = styled(Wrapper)`
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &.strikethrough {
      text-decoration: line-through;
    }
  }
`;

interface OrderReviewPricingStyleProps {
  style: Style;
  orderStylePriceReviewData: OrderStylePriceReview;
  showStyleDetails: boolean;
  isSalesAids: boolean;
}

const OrderReviewPricingStyle = ({
  style,
  orderStylePriceReviewData,
  showStyleDetails,
  isSalesAids,
}: OrderReviewPricingStyleProps) => {
  const lineItemNameColumnWidth = 188;

  const [displayDetails, setDisplayDetails] = useState(true);
  const [isListPriceSelected, setIsListPriceSelected] = useState(true);
  const [isShowCatalogChecked, setIsShowCatalogChecked] = useState(false);

  const isRevolae =
    style.productLine.name === ProductLineEnums.PRODUCT_LINE_REVOLAE;

  const {
    highlightLineItemRow,
    highlightModificationRow,
    highlightLineItemField,
    highlightStyleField,
    highlightModificationField,
    highlightDeletedModifications,
    highlightDeletedUpcharges,
  } = useHighlightHelper(showStyleDetails);

  const deletedUpcharges = highlightDeletedUpcharges(
    orderStylePriceReviewData,
    style.id
  );

  const { enableExposedEnds } = UtilService.shouldShowEnds({
    cabinetBoxMaterialName: style.cabinetBoxMaterial?.name,
    productLineName: style.productLine.name,
  });

  const getFinishedEndPrice = (type: FinishedEndType) => {
    return orderStylePriceReviewData?.finishedEndsPriceReview?.find(
      (fe) => fe.type === type
    );
  };

  const getUpchargePrice = (type: UpchargeType) => {
    let upcharge = orderStylePriceReviewData?.upchargesPriceReview?.find(
      (fe) => fe.upchargeType === type
    );

    if (
      orderStylePriceReviewData?.upchargesPriceReview.length === 0 &&
      deletedUpcharges.length > 0
    ) {
      upcharge = deletedUpcharges.find((x) => x.upchargeType === type);
    }

    return upcharge;
  };

  const getNetUpchargePrice = (type: NetUpchargeType) => {
    return orderStylePriceReviewData?.netUpchargesDiscountsPriceReview?.find(
      (fe) => fe.type === type
    );
  };

  const getPremiumSelectionPrice = (type: PremiumSelectionType) => {
    return orderStylePriceReviewData?.premiumSelectionPriceReview?.find(
      (fe) => fe.premiumSelectionType === type
    );
  };

  const showUpchargePrice = (type: UpchargeType) => {
    return isShowCatalogChecked
      ? getUpchargePrice(type)?.catalogPercentage ?? undefined
      : getUpchargePrice(type)?.percentage ?? undefined;
  };

  const showNetUpchargePrice = (type: NetUpchargeType) => {
    return getNetUpchargePrice(type)?.percentage ?? 0;
  };

  const showPremiumSelectionPrice = (type: PremiumSelectionType) => {
    return (getPremiumSelectionPrice(type)?.quantity ?? 0) > 0;
  };

  const showFinishedEndPrice = (type: FinishedEndType) => {
    return (getFinishedEndPrice(type)?.quantity ?? 0) > 0;
  };

  const getUpchargeRowTitle = (
    field: UpchargeType,
    productLineName: string
  ) => {
    if (
      field === UpchargeType['Wood Upcharge'] &&
      productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE2O
    ) {
      return UpchargeType[UpchargeType['Material Group Upcharge']];
    }

    if (
      field === UpchargeType['Finish Upcharge'] &&
      productLineName === ProductLineEnums.PRODUCT_LINE_INOVAE2O
    ) {
      return UpchargeType[UpchargeType['Color Upcharge']];
    }

    return UpchargeType[field];
  };

  const getUpchargeRowClass = (field: UpchargeType) => {
    const upchargeFields = [];
    switch (field) {
      case UpchargeType['Wood Upcharge']:
        upchargeFields.push(FieldName.StyleWoodMaterialUpcharge);
        upchargeFields.push(FieldName.StyleMaterialGroupUpcharge);
        break;
      case UpchargeType['Finish Upcharge']:
        upchargeFields.push(FieldName.StyleFinishColorUpcharge);
        upchargeFields.push(FieldName.StyleMaterialColorUpcharge);
        break;
      case UpchargeType['Wood Special Effect']:
        upchargeFields.push(FieldName.StyleWoodEffectUpcharge);
        break;
      case UpchargeType['Finish Special Effect']:
        upchargeFields.push(FieldName.StyleFinishEffectUpcharge);
        break;
      case UpchargeType['Cabinet Box Material']:
        upchargeFields.push(FieldName.StyleCabinetBoxMaterialUpcharge);
        break;
      case UpchargeType['Icon Face Frame']:
        upchargeFields.push(FieldName.StyleFaceFrame);
        break;
      case UpchargeType['One Inch Thick Door']:
        upchargeFields.push(FieldName.StyleOneInchThickDoorUpcharge);
        break;
      case UpchargeType['Finish Impression']:
        upchargeFields.push(FieldName.StyleFinishImpressionUpcharge);
        break;
      default:
        break;
    }

    return highlightStyleField(style.id!, upchargeFields);
  };

  const showSpecialDimensions = (lineItem: LineItemPriceReview) => {
    return (
      (lineItem.dimUp.length > 0 && !isShowCatalogChecked) ||
      (lineItem.catalogDimUp.length > 0 && isShowCatalogChecked)
    );
  };

  const getPositionEnds = (lineItem: LineItemPriceReview) => {
    return enableExposedEnds
      ? parse(LineItemExposedEndsPositionEnum[lineItem.exposedEndPosition])
      : parse(LineItemPositionEnum[lineItem.finishEndPosition]);
  };

  return (
    <>
      <Divider className="review-pricing-divider" />

      <Spacer h="16px" className="review-pricing-spacer" />

      <Wrapper flex middle between>
        <div>
          <H2>
            #{style.number} {style.name}
          </H2>
          <Spacer h="8px" />
          <PMedium>
            {style.productLine?.name},{' '}
            {style.woodMaterial?.name || style.materialGroup?.name || 'N/A'},{' '}
            {style.finishColor?.name || style.materialColor?.name || 'N/A'},{' '}
            {style.doorStyle?.name || 'N/A'},{' '}
            {orderStylePriceReviewData?.lineItemPriceReview?.filter(
              (x) => !x.isDeleted
            ).length || 0}{' '}
            Line Items
          </PMedium>

          {orderStylePriceReviewData?.styleNote || style.note ? (
            <>
              <Spacer h="10px" />
              <P color={nevada} fontSize={14}>
                Note:{' '}
                <SPAN color={lynch} fontSize="inherit" fontWeight={600}>
                  {orderStylePriceReviewData?.styleNote || style.note}
                </SPAN>
              </P>
            </>
          ) : null}

          {orderStylePriceReviewData?.priceSchedule && (
            <>
              <Spacer h="10px" />

              <P color={nevada} fontSize={14}>
                Price Schedule:{' '}
                <SPAN color={lynch} fontSize="inherit" fontWeight={600}>
                  {orderStylePriceReviewData?.priceSchedule}
                </SPAN>
              </P>
            </>
          )}

          {orderStylePriceReviewData?.workOrderNumber && (
            <>
              <Spacer h="10px" />

              <P color={nevada} fontSize={14}>
                Original W.O #:{' '}
                <SPAN color={lynch} fontSize="inherit" fontWeight={600}>
                  {orderStylePriceReviewData?.workOrderNumber}
                </SPAN>
              </P>
            </>
          )}
        </div>

        <ExpandButton
          onClick={() => setDisplayDetails(!displayDetails)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
          className={
            displayDetails
              ? 'expandable-box-button open'
              : 'expandable-box-button'
          }
        >
          <ArrowUpIcon />
        </ExpandButton>
      </Wrapper>

      {!displayDetails && <Spacer h="16px" />}

      {displayDetails && (
        <>
          {showStyleDetails && (
            <>
              <OrderStyle
                loadOrderStyles={() => console.log('test')}
                style={style}
                highlight={showStyleDetails}
                disableOverrideHighlight={showStyleDetails}
                marginBottom={16}
              />

              {style.override && (
                <Info type="warning">
                  There could be overridden values on this style.
                </Info>
              )}
            </>
          )}

          {!isSalesAids && (
            <>
              <Spacer h="15px" />
              <Wrapper
                flex
                justifyEnd
                middle
                className="review-pricing-style-switch-buttons"
              >
                <SwitchButton
                  isActive={isListPriceSelected}
                  leftButtonText="List"
                  rightButtonText="Net"
                  onChange={setIsListPriceSelected}
                />

                <Spacer w="42px" />

                <Checkbox
                  id={`show-dealer-price-${style.id}`}
                  title="Show Catalog$"
                  checked={isShowCatalogChecked}
                  onChange={() =>
                    setIsShowCatalogChecked((prevState) => !prevState)
                  }
                />

                <Spacer w="36px" />
              </Wrapper>
            </>
          )}

          {!isSalesAids && (
            <>
              <Spacer h="16px" />

              {orderStylePriceReviewData?.lineItemPriceReview.length > 0 && (
                <>
                  <WhiteBox>
                    <Wrapper flex middle>
                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>PWW#</H6>
                      </ContainerXSmall>
                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>CL#</H6>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>L#</H6>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>Qty</H6>
                      </ContainerXSmall>

                      <ContainerMedium
                        isTableHeadCell
                        border={['right', 'bottom']}
                        maxWidth={lineItemNameColumnWidth}
                        minWidth={lineItemNameColumnWidth}
                        flexGrow
                        align="left"
                      >
                        <H6>LINE ITEMS</H6>
                      </ContainerMedium>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>Hinge</H6>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                      >
                        <H6>{enableExposedEnds ? 'EE' : 'FE'}</H6>
                      </ContainerXSmall>

                      {isShowCatalogChecked && (
                        <>
                          <ContainerSmall
                            isTableHeadCell
                            border={['right', 'bottom']}
                            flexGrow
                          >
                            <H6>CatUnit$</H6>
                          </ContainerSmall>

                          <ContainerSmall
                            isTableHeadCell
                            border={['right', 'bottom']}
                          >
                            <H6>
                              {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                            </H6>
                          </ContainerSmall>
                        </>
                      )}

                      <ContainerSmall
                        isTableHeadCell
                        border={['right', 'bottom']}
                        flexGrow={!isShowCatalogChecked}
                      >
                        <H6>Unit$</H6>
                      </ContainerSmall>

                      <ContainerSmall
                        isTableHeadCell
                        border={isShowCatalogChecked ? ['bottom'] : ['bottom']}
                      >
                        <H6>{isListPriceSelected ? 'List$' : 'Net$'}</H6>
                      </ContainerSmall>
                    </Wrapper>

                    {orderStylePriceReviewData?.lineItemPriceReview
                      .filter((x) => showStyleDetails || !x.isDeleted)
                      .map((lineItem) => (
                        <HighlightedWrapper
                          key={`${lineItem.code}--${uuidv4()}`}
                          className={highlightLineItemRow(
                            orderStylePriceReviewData?.styleId,
                            lineItem.lineItemId,
                            lineItem.isDeleted
                          )}
                        >
                          <Wrapper flex middle>
                            <ContainerXSmall isTableCell border={['right']}>
                              <PSmall>{lineItem.pwwNumber}</PSmall>
                            </ContainerXSmall>

                            <ContainerXSmall
                              isTableCell
                              border={['right']}
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemClientLineItemNumber]
                              )}
                            >
                              <PSmall>{lineItem.clientLineItemNumber}</PSmall>
                            </ContainerXSmall>

                            <ContainerXSmall
                              isTableCell
                              border={['right']}
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemNumber]
                              )}
                            >
                              <PSmall>{lineItem.number}</PSmall>
                            </ContainerXSmall>

                            <ContainerXSmall
                              isTableCell
                              border={['right']}
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemQuantity]
                              )}
                            >
                              <PSmall>{lineItem.quantity}</PSmall>
                            </ContainerXSmall>

                            <ContainerMedium
                              isTableCell
                              border={['right']}
                              maxWidth={lineItemNameColumnWidth}
                              minWidth={lineItemNameColumnWidth}
                              flexGrow
                              align="left"
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [
                                  FieldName.LineItemWidth,
                                  FieldName.LineItemHeight,
                                  FieldName.LineItemDepth,
                                ]
                              )}
                            >
                              <PSmall>
                                <SPAN fontSize="inherit" bold>
                                  {lineItem.isDeleted ? '[VOID]' : ''}{' '}
                                  {UtilService.generateLineItemNameWithDimensions(
                                    lineItem
                                  )}
                                </SPAN>
                                {lineItem.squareFootTotal
                                  ? ` (Includes ${lineItem.squareFootTotal} SQFT)`
                                  : null}
                              </PSmall>
                              {(lineItem.rightLegWidth > 0 ||
                                lineItem.rightLegDepth > 0) && (
                                <>
                                  <Spacer h="5px" />
                                  <PSmall>
                                    <SPAN fontSize="inherit">
                                      LEFT WIDTH : {lineItem.width.toFixed(2)}{' '}
                                      LEFT DEPTH : {lineItem.depth.toFixed(2)}{' '}
                                    </SPAN>

                                    <Spacer h="5px" />
                                    <SPAN fontSize="inherit">
                                      RIGHT WIDTH :{' '}
                                      {lineItem.rightLegWidth.toFixed(2)} RIGHT
                                      DEPTH :{' '}
                                      {lineItem.rightLegDepth.toFixed(2)}
                                    </SPAN>
                                  </PSmall>
                                </>
                              )}
                            </ContainerMedium>
                            <ContainerXSmall
                              isTableCell
                              border={['right']}
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemHingePosition]
                              )}
                            >
                              <PSmall>
                                {parse(
                                  LineItemHingePositionEnum[
                                    lineItem.hingePosition
                                  ]
                                )}
                              </PSmall>
                            </ContainerXSmall>

                            <ContainerXSmall
                              isTableCell
                              border={['right']}
                              mrAuto
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemFinishedEndPosition]
                              )}
                            >
                              <PSmall>{getPositionEnds(lineItem)}</PSmall>
                            </ContainerXSmall>

                            {/* catalog prices */}
                            {isShowCatalogChecked && (
                              <>
                                <ContainerSmall isTableCell border={['right']}>
                                  <PSmall>
                                    {isListPriceSelected
                                      ? lineItem.catalogUnitPrice.toFixed(2)
                                      : lineItem.catalogNetUnitPrice.toFixed(2)}
                                  </PSmall>
                                </ContainerSmall>

                                <ContainerSmall isTableCell border={['right']}>
                                  <PSmall>
                                    {isListPriceSelected
                                      ? lineItem.catalogListPrice.toFixed(2)
                                      : lineItem.catalogNetPrice.toFixed(2)}
                                  </PSmall>
                                </ContainerSmall>
                              </>
                            )}
                            {/* catalog prices */}

                            {/* dealer prices */}

                            <ContainerSmall
                              isTableCell
                              border={['right']}
                              className={highlightLineItemField(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId,
                                [FieldName.LineItemListPrice]
                              )}
                            >
                              <PSmall>
                                {isListPriceSelected
                                  ? lineItem.unitPrice.toFixed(2)
                                  : lineItem.netUnitPrice.toFixed(2)}
                              </PSmall>
                            </ContainerSmall>

                            <ContainerSmall isTableCell>
                              <PSmall>
                                {isListPriceSelected
                                  ? lineItem.listPrice.toFixed(2)
                                  : lineItem.netPrice.toFixed(2)}
                              </PSmall>
                            </ContainerSmall>
                            {/* dealer prices */}
                          </Wrapper>

                          {showSpecialDimensions(lineItem) && (
                            <Wrapper flex middle>
                              <Spacer h="16px" />
                              <ContainerXSmall isTableCell border={['right']} />
                              <ContainerXSmall isTableCell border={['right']} />
                              <ContainerXSmall isTableCell border={['right']} />
                              <ContainerXSmall isTableCell border={['right']} />
                              <ContainerMedium
                                flex
                                middle
                                isTableCell
                                maxWidth={lineItemNameColumnWidth}
                                minWidth={lineItemNameColumnWidth}
                                border={['right']}
                              >
                                <Spacer w="15px" />

                                <PSmall>
                                  Special{' '}
                                  {UtilService.getSpecialDimensions(
                                    isShowCatalogChecked
                                      ? lineItem.catalogDimUp
                                      : lineItem.dimUp
                                  )}
                                </PSmall>
                              </ContainerMedium>

                              <ContainerXSmall isTableCell border={['right']} />
                              <ContainerXSmall
                                isTableCell
                                border={['right']}
                                mrAuto
                              />

                              {/* catalog dimup */}
                              {isShowCatalogChecked && (
                                <>
                                  <ContainerSmall
                                    isTableCell
                                    border={['right']}
                                  >
                                    <PSmall>
                                      {isListPriceSelected
                                        ? lineItem.catalogDimUpUnitPrice.toFixed(
                                            2
                                          )
                                        : lineItem.catalogDimUpNetUnitPrice.toFixed(
                                            2
                                          )}
                                    </PSmall>
                                  </ContainerSmall>

                                  <ContainerSmall
                                    isTableCell
                                    border={['right']}
                                  >
                                    <PSmall>
                                      {isListPriceSelected
                                        ? lineItem.catalogDimUpListPrice.toFixed(
                                            2
                                          )
                                        : lineItem.catalogDimUpNetPrice.toFixed(
                                            2
                                          )}
                                    </PSmall>
                                  </ContainerSmall>
                                </>
                              )}
                              {/* catalog dimup */}

                              {/* dealer dimup */}
                              <ContainerSmall isTableCell border={['right']}>
                                <PSmall>
                                  {isListPriceSelected
                                    ? lineItem.dimUpUnitPrice.toFixed(2)
                                    : lineItem.dimUpNetUnitPrice.toFixed(2)}
                                </PSmall>
                              </ContainerSmall>

                              <ContainerSmall isTableCell>
                                <PSmall>
                                  {isListPriceSelected
                                    ? lineItem.dimUpListPrice.toFixed(2)
                                    : lineItem.dimUpNetPrice.toFixed(2)}
                                </PSmall>
                              </ContainerSmall>
                              {/* dealer dimup */}
                            </Wrapper>
                          )}

                          {lineItem.modificationsPrice
                            .concat(
                              highlightDeletedModifications(
                                orderStylePriceReviewData?.styleId,
                                lineItem.lineItemId
                              )
                            )
                            .map((modification, index) => (
                              <HighlightedWrapper
                                key={modification.id}
                                flex
                                middle
                                className={highlightModificationRow(
                                  orderStylePriceReviewData?.styleId,
                                  lineItem.lineItemId,
                                  modification.description,
                                  modification.isDeleted
                                )}
                              >
                                <ContainerXSmall
                                  isTableCell
                                  border={['right']}
                                />

                                <ContainerXSmall
                                  isTableCell
                                  border={['right']}
                                />
                                <ContainerXSmall
                                  isTableCell
                                  border={['right']}
                                />
                                <ContainerXSmall isTableCell border={['right']}>
                                  <PSmall
                                    className={highlightModificationField(
                                      orderStylePriceReviewData?.styleId,
                                      lineItem.lineItemId,
                                      index + 1,
                                      [FieldName.ModificationQuantity]
                                    )}
                                  >
                                    {modification.quantity}
                                  </PSmall>
                                </ContainerXSmall>

                                <ContainerMedium
                                  flex
                                  isTableCell
                                  border={['right']}
                                  align="left"
                                  flexGrow
                                  maxWidth={lineItemNameColumnWidth}
                                  minWidth={lineItemNameColumnWidth}
                                >
                                  <Spacer w="15px" />

                                  <Wrapper>
                                    <PSmall color={nevada}>
                                      {modification.isDeleted ? '[VOID] ' : ''}
                                      {modification.description ||
                                        modification.name.toUpperCase()}
                                      {modification.squareFootParts
                                        ? ' (Adds to SQFT)'
                                        : null}
                                    </PSmall>

                                    {(
                                      modification.note +
                                      modification.additionalNote
                                    ).length > 0 && (
                                      <>
                                        <Spacer h="5px" />

                                        <PSmall
                                          fontSize={12}
                                          color={nevada}
                                          className={highlightModificationField(
                                            orderStylePriceReviewData?.styleId,
                                            lineItem.lineItemId,
                                            index + 1,
                                            [
                                              FieldName.ModificationNote,
                                              FieldName.ModificationAdditionalNote,
                                            ]
                                          )}
                                        >
                                          {[
                                            modification.note,
                                            modification.additionalNote,
                                          ]
                                            .filter((note) => note !== '')
                                            .join(', ')}
                                        </PSmall>
                                      </>
                                    )}
                                  </Wrapper>
                                </ContainerMedium>
                                <ContainerXSmall isTableCell border={['right']}>
                                  <PSmall />
                                </ContainerXSmall>

                                <ContainerXSmall
                                  isTableCell
                                  border={['right']}
                                  mrAuto
                                />

                                {/* catalog mod price */}
                                {isShowCatalogChecked && (
                                  <>
                                    <ContainerSmall
                                      isTableCell
                                      border={['right']}
                                    >
                                      <PSmall>
                                        {isListPriceSelected
                                          ? modification.catalogUnitPrice.toFixed(
                                              2
                                            )
                                          : modification.catalogNetUnitPrice.toFixed(
                                              2
                                            )}
                                      </PSmall>
                                    </ContainerSmall>

                                    <ContainerSmall
                                      isTableCell
                                      border={['right']}
                                    >
                                      <PSmall>
                                        {isListPriceSelected
                                          ? modification.catalogListPrice.toFixed(
                                              2
                                            )
                                          : modification.catalogNetPrice.toFixed(
                                              2
                                            )}
                                      </PSmall>
                                    </ContainerSmall>
                                  </>
                                )}
                                {/* catalog mod price */}

                                {/* dealer mod price */}
                                <ContainerSmall isTableCell border={['right']}>
                                  <PSmall
                                    className={highlightModificationField(
                                      orderStylePriceReviewData?.styleId,
                                      lineItem.lineItemId,
                                      index + 1,
                                      [FieldName.ModificationPrice]
                                    )}
                                  >
                                    {isListPriceSelected
                                      ? modification.unitPrice.toFixed(2)
                                      : modification.netUnitPrice.toFixed(2)}
                                  </PSmall>
                                </ContainerSmall>

                                <ContainerSmall isTableCell>
                                  <PSmall>
                                    {isListPriceSelected
                                      ? modification.listPrice.toFixed(2)
                                      : modification.netPrice.toFixed(2)}
                                  </PSmall>
                                </ContainerSmall>
                                {/* dealer mod price */}
                              </HighlightedWrapper>
                            ))}
                        </HighlightedWrapper>
                      ))}

                    <TotalPriceRow
                      title="line item total"
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      listTotal={orderStylePriceReviewData?.lineItemsListTotal}
                      netTotal={orderStylePriceReviewData?.lineItemsNetTotal}
                      catalogNetTotal={
                        orderStylePriceReviewData?.catalogLineItemsNetTotal
                      }
                      catalogListTotal={
                        orderStylePriceReviewData?.catalogLineItemsListTotal
                      }
                    />
                  </WhiteBox>

                  <Spacer h="24px" />
                </>
              )}

              {orderStylePriceReviewData?.finishedEndsListTotal > 0 && (
                <>
                  <WhiteBox>
                    <Wrapper flex middle between>
                      <ContainerMedium
                        align="left"
                        minWidth={135}
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <H6>FINISHED ENDS</H6>
                      </ContainerMedium>

                      <ContainerSmall
                        flexGrow
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <H6>Qty</H6>
                      </ContainerSmall>

                      {/* catalog finished ends */}
                      {isShowCatalogChecked && (
                        <>
                          <ContainerSmall
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <H6>CatUnit$</H6>
                          </ContainerSmall>

                          <ContainerSmall
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <H6>
                              {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                            </H6>
                          </ContainerSmall>
                        </>
                      )}
                      {/* catalog finished ends */}

                      {/* dealer finished ends */}
                      <ContainerSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <H6>Unit$</H6>
                      </ContainerSmall>

                      <ContainerSmall isTableHeadCell border={['bottom']}>
                        <H6>{isListPriceSelected ? 'List$' : 'Net$'}</H6>
                      </ContainerSmall>
                      {/* dealer finished ends */}
                    </Wrapper>

                    {showFinishedEndPrice(FinishedEndType.Wall) && (
                      <FinishedEndRow
                        isListPriceSelected={isListPriceSelected}
                        isShowCatalogChecked={isShowCatalogChecked}
                        rowTitle={FinishedEndType[FinishedEndType.Wall]}
                        row={getFinishedEndPrice(FinishedEndType.Wall)}
                      />
                    )}

                    {showFinishedEndPrice(FinishedEndType.Base) && (
                      <FinishedEndRow
                        isListPriceSelected={isListPriceSelected}
                        isShowCatalogChecked={isShowCatalogChecked}
                        rowTitle={FinishedEndType[FinishedEndType.Base]}
                        row={getFinishedEndPrice(FinishedEndType.Base)}
                      />
                    )}

                    {showFinishedEndPrice(FinishedEndType.Tall) && (
                      <FinishedEndRow
                        isListPriceSelected={isListPriceSelected}
                        isShowCatalogChecked={isShowCatalogChecked}
                        rowTitle={FinishedEndType[FinishedEndType.Tall]}
                        row={getFinishedEndPrice(FinishedEndType.Tall)}
                      />
                    )}

                    {showFinishedEndPrice(
                      FinishedEndType['No Midrail Cabinets']
                    ) && (
                      <FinishedEndRow
                        isListPriceSelected={isListPriceSelected}
                        isShowCatalogChecked={isShowCatalogChecked}
                        rowTitle={
                          FinishedEndType[
                            FinishedEndType['No Midrail Cabinets']
                          ]
                        }
                        row={getFinishedEndPrice(
                          FinishedEndType['No Midrail Cabinets']
                        )}
                      />
                    )}

                    <TotalPriceRow
                      title="finished end total"
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      catalogListTotal={
                        orderStylePriceReviewData?.finishedEndsListTotal
                      }
                      catalogNetTotal={
                        orderStylePriceReviewData?.finishedEndsNetTotal
                      }
                      listTotal={
                        orderStylePriceReviewData?.finishedEndsListTotal
                      }
                      netTotal={orderStylePriceReviewData?.finishedEndsNetTotal}
                    />
                  </WhiteBox>
                </>
              )}
              {(orderStylePriceReviewData?.totalListSum > 0 ||
                orderStylePriceReviewData?.totalNetSum > 0) && (
                <TotalPrice
                  spaceTop={16}
                  title="subtotal"
                  total={
                    isListPriceSelected
                      ? orderStylePriceReviewData?.totalListSum.toFixed(2)
                      : orderStylePriceReviewData?.totalNetSum.toFixed(2)
                  }
                />
              )}

              {orderStylePriceReviewData?.excludedListSum > 0 && (
                <>
                  <WhiteBox>
                    <Wrapper flex middle>
                      <ContainerMedium
                        isTableHeadCell
                        align="left"
                        border={['bottom']}
                        flexGrow
                      >
                        <H6>EXCLUDED FROM UPCHARGES (NO WOOD/FIN UPCHARGE)</H6>
                      </ContainerMedium>

                      {isShowCatalogChecked && (
                        <>
                          <ContainerSmall
                            minWidth={120}
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <PSmall bold>CatUnit$</PSmall>
                          </ContainerSmall>

                          <ContainerSmall
                            minWidth={120}
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <PSmall bold>
                              {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                            </PSmall>
                          </ContainerSmall>
                        </>
                      )}

                      <ContainerSmall
                        minWidth={120}
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>Unit$</PSmall>
                      </ContainerSmall>

                      <ContainerSmall
                        minWidth={120}
                        isTableHeadCell
                        border={['bottom']}
                      >
                        <PSmall bold>
                          {isListPriceSelected ? 'List$' : 'Net$'}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex middle>
                      <ContainerMedium isTableCell align="left" flexGrow>
                        <H6>EXCLUDED SUM</H6>
                      </ContainerMedium>

                      {isShowCatalogChecked && (
                        <>
                          <ContainerSmall
                            minWidth={120}
                            isTableCell
                            border={['right']}
                          >
                            <PSmall>
                              {isListPriceSelected
                                ? orderStylePriceReviewData?.catalogExcludedListSum.toFixed(
                                    2
                                  )
                                : orderStylePriceReviewData?.catalogExcludedNetSum.toFixed(
                                    2
                                  )}
                            </PSmall>
                          </ContainerSmall>

                          <ContainerSmall
                            minWidth={120}
                            isTableCell
                            border={['right']}
                          >
                            <PSmall>
                              {isListPriceSelected
                                ? orderStylePriceReviewData?.catalogExcludedListSum.toFixed(
                                    2
                                  )
                                : orderStylePriceReviewData?.catalogExcludedNetSum.toFixed(
                                    2
                                  )}
                            </PSmall>
                          </ContainerSmall>
                        </>
                      )}

                      <ContainerSmall
                        minWidth={120}
                        isTableCell
                        border={['right']}
                      >
                        <PSmall>
                          {isListPriceSelected
                            ? orderStylePriceReviewData?.excludedListSum.toFixed(
                                2
                              )
                            : orderStylePriceReviewData?.excludedNetSum.toFixed(
                                2
                              )}
                        </PSmall>
                      </ContainerSmall>

                      <ContainerSmall minWidth={120} isTableCell>
                        <PSmall>
                          {isListPriceSelected
                            ? orderStylePriceReviewData?.excludedListSum.toFixed(
                                2
                              )
                            : orderStylePriceReviewData?.excludedNetSum.toFixed(
                                2
                              )}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    <Wrapper flex middle isTableCell border={['top']}>
                      <ContainerMedium mrAuto>
                        <H6>EXCLUDED TOTAL</H6>
                      </ContainerMedium>

                      {isShowCatalogChecked && (
                        <Wrapper flex justifyEnd middle minWidth={240}>
                          <PSmall>
                            (
                            {(isListPriceSelected
                              ? orderStylePriceReviewData?.catalogTotalListSum
                              : orderStylePriceReviewData?.catalogTotalNetSum
                            ).toFixed(2)}{' '}
                            -{' '}
                            {isListPriceSelected
                              ? orderStylePriceReviewData?.catalogExcludedListSum.toFixed(
                                  2
                                ) ?? 0
                              : orderStylePriceReviewData?.catalogExcludedNetSum.toFixed(
                                  2
                                ) ?? 0}
                            )
                          </PSmall>
                          <Spacer w="33px" />
                          <H6>
                            {isListPriceSelected
                              ? orderStylePriceReviewData?.catalogExcludedListTotal.toFixed(
                                  2
                                ) ?? '0.00'
                              : orderStylePriceReviewData?.catalogExcludedNetTotal.toFixed(
                                  2
                                ) ?? '0.00'}
                          </H6>
                        </Wrapper>
                      )}

                      <Spacer w="65px" />

                      <Wrapper flex middle justifyEnd minWidth={240}>
                        <PSmall>
                          (
                          {(isListPriceSelected
                            ? orderStylePriceReviewData?.totalListSum
                            : orderStylePriceReviewData?.totalNetSum
                          ).toFixed(2)}{' '}
                          -{' '}
                          {isListPriceSelected
                            ? orderStylePriceReviewData?.excludedListSum.toFixed(
                                2
                              ) ?? 0
                            : orderStylePriceReviewData?.excludedNetSum.toFixed(
                                2
                              ) ?? 0}
                          )
                        </PSmall>
                        <Spacer w="33px" />
                        <H6>
                          {isListPriceSelected
                            ? orderStylePriceReviewData?.excludedListTotal.toFixed(
                                2
                              ) ?? '0.00'
                            : orderStylePriceReviewData?.excludedNetTotal.toFixed(
                                2
                              ) ?? '0.00'}
                        </H6>
                      </Wrapper>
                    </Wrapper>
                  </WhiteBox>
                  <Spacer h="24px" />
                </>
              )}

              {orderStylePriceReviewData &&
                (orderStylePriceReviewData?.catalogUpchargesListTotal !== 0 ||
                  orderStylePriceReviewData?.upchargesListTotal !== 0 ||
                  deletedUpcharges.length > 0) && (
                  <>
                    <WhiteBox>
                      <Wrapper flex middle>
                        <Wrapper
                          flex
                          middle
                          isTableHeadCell
                          minWidth={150}
                          border={['bottom']}
                          flexGrow
                        >
                          <H6>UPCHARGES/DISCOUNTS</H6>
                          <Spacer w="10px" />
                          <Info>
                            <Tooltip position="right">
                              Upcharges are calculated based on excluded total.
                            </Tooltip>
                          </Info>
                        </Wrapper>

                        {/* catalog upcharge */}
                        {isShowCatalogChecked && (
                          <>
                            <ContainerXSmall
                              isTableHeadCell
                              border={['bottom', 'right']}
                            >
                              <PSmall bold>Catalog%</PSmall>
                            </ContainerXSmall>

                            <ContainerSmall
                              isTableHeadCell
                              border={['bottom', 'right']}
                            >
                              <H6>
                                {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                              </H6>
                            </ContainerSmall>
                          </>
                        )}
                        {/* catalog upcharge */}

                        {/* dealer upcharge */}
                        <ContainerXSmall
                          isTableHeadCell
                          border={['bottom', 'right']}
                        >
                          <PSmall bold>%</PSmall>
                        </ContainerXSmall>

                        <ContainerSmall isTableHeadCell border={['bottom']}>
                          <PSmall bold>
                            {isListPriceSelected ? 'List$' : 'Net$'}
                          </PSmall>
                        </ContainerSmall>
                        {/* dealer upcharge */}
                      </Wrapper>

                      {/* 
                    Branko - 01.09.2022
                    I have came to conclusion that this should be done in this way
                    because i want to show rows based on enum. Centralized. Smaller chance for bugs.
                  */}

                      {(
                        Object.keys(
                          UpchargeType
                        ) as (keyof typeof UpchargeType)[]
                      )
                        .filter((x) => Number.isNaN(+x))
                        .map((key) =>
                          showUpchargePrice(UpchargeType[key]) !== undefined ? (
                            <UpchargeRow
                              key={key}
                              isListPriceSelected={isListPriceSelected}
                              isShowCatalogChecked={isShowCatalogChecked}
                              upcharge={getUpchargePrice(UpchargeType[key])}
                              className={getUpchargeRowClass(UpchargeType[key])}
                              title={getUpchargeRowTitle(
                                UpchargeType[key],
                                style.productLine.name
                              )}
                            />
                          ) : undefined
                        )}

                      <TotalPriceRow
                        isListPriceSelected={isListPriceSelected}
                        isShowCatalogChecked={isShowCatalogChecked}
                        title="upcharges/discounts total"
                        spacingBetweenPrices={106}
                        catalogListTotal={
                          orderStylePriceReviewData?.catalogUpchargesListTotal
                        }
                        catalogNetTotal={
                          orderStylePriceReviewData?.catalogUpchargesNetTotal
                        }
                        listTotal={
                          orderStylePriceReviewData?.upchargesListTotal
                        }
                        netTotal={orderStylePriceReviewData?.upchargesNetTotal}
                      />
                    </WhiteBox>

                    <Spacer h="24px" />
                  </>
                )}

              {(orderStylePriceReviewData?.catalogDoorCodesListTotal > 0 ||
                orderStylePriceReviewData?.doorCodesListTotal > 0) && (
                <>
                  <WhiteBox>
                    <Wrapper flex middle>
                      <ContainerXSmall
                        minWidth={50}
                        align="left"
                        isTableHeadCell
                        border={['bottom']}
                      >
                        <H6>DOORS</H6>
                      </ContainerXSmall>

                      <ContainerXSmall
                        minWidth={59}
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>Item</PSmall>
                      </ContainerXSmall>

                      {isShowCatalogChecked && (
                        <ContainerXSmall
                          isTableHeadCell
                          border={['bottom', 'right']}
                        >
                          <H6>CatQty</H6>
                        </ContainerXSmall>
                      )}

                      <ContainerXSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>Qty</PSmall>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <Wrapper withTooltip tooltipDelay={500}>
                          <PSmall bold>Upch$</PSmall>
                          <Tooltip>Upcharge$</Tooltip>
                        </Wrapper>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>Total</PSmall>
                      </ContainerXSmall>

                      <ContainerXSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                        maxWidth={isShowCatalogChecked && isRevolae ? 43 : 120}
                        minWidth={isShowCatalogChecked && isRevolae ? 43 : 120}
                        align="left"
                      >
                        <PSmall bold>Reason</PSmall>
                      </ContainerXSmall>

                      {/* Show SQFT only for revolae */}
                      {isRevolae && (
                        <ContainerSmall
                          flexGrow
                          isTableHeadCell
                          border={['bottom', 'right']}
                        >
                          <PSmall bold>TotSQFT</PSmall>
                        </ContainerSmall>
                      )}

                      {/* catalog upcharges */}
                      {isShowCatalogChecked && (
                        <>
                          {isRevolae ? (
                            <>
                              <ContainerSmall
                                isTableHeadCell
                                border={['bottom', 'right']}
                              >
                                <H6>CatSQFT$</H6>
                              </ContainerSmall>

                              <ContainerSmall
                                isTableHeadCell
                                border={['bottom', 'right']}
                              >
                                <H6>TotCatSQFT$</H6>
                              </ContainerSmall>
                            </>
                          ) : (
                            <>
                              <ContainerSmall
                                isTableHeadCell
                                flexGrow
                                border={['bottom', 'right']}
                              >
                                <H6>CatUnit$</H6>
                              </ContainerSmall>

                              <ContainerSmall
                                isTableHeadCell
                                border={['bottom', 'right']}
                              >
                                <H6>
                                  {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                                </H6>
                              </ContainerSmall>
                            </>
                          )}
                        </>
                      )}
                      {/* catalog upcharges */}

                      {/* dealer upcharges */}
                      {isRevolae ? (
                        <>
                          <ContainerSmall
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <H6>SQFT$</H6>
                          </ContainerSmall>

                          <ContainerSmall isTableHeadCell border={['bottom']}>
                            <PSmall bold>TotSQFT$</PSmall>
                          </ContainerSmall>
                        </>
                      ) : (
                        <>
                          <ContainerSmall
                            flexGrow={!isShowCatalogChecked}
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <PSmall bold>Unit$</PSmall>
                          </ContainerSmall>
                          <ContainerSmall isTableHeadCell border={['bottom']}>
                            <PSmall bold>
                              {isListPriceSelected ? 'List$' : 'Net$'}
                            </PSmall>
                          </ContainerSmall>
                        </>
                      )}
                      {/* dealer upcharges */}
                    </Wrapper>

                    <DoorCodesRow
                      codes={
                        orderStylePriceReviewData?.doorCodesPriceReview.wall
                      }
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      title="Wall"
                      isRevolae={isRevolae}
                    />

                    <DoorCodesRow
                      codes={
                        orderStylePriceReviewData?.doorCodesPriceReview.base
                      }
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      title="Base"
                      isRevolae={isRevolae}
                    />

                    <DoorCodesRow
                      codes={
                        orderStylePriceReviewData?.doorCodesPriceReview.drawer
                      }
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      title="Drawer"
                      isRevolae={isRevolae}
                    />

                    <TotalPriceRow
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      title="door total"
                      listTotal={orderStylePriceReviewData?.doorCodesListTotal}
                      netTotal={orderStylePriceReviewData?.doorCodesNetTotal}
                      catalogListTotal={
                        orderStylePriceReviewData?.catalogDoorCodesListTotal
                      }
                      catalogNetTotal={
                        orderStylePriceReviewData?.catalogDoorCodesNetTotal
                      }
                      spacingBetweenPrices={146}
                    />
                  </WhiteBox>

                  <Spacer h="24px" />
                </>
              )}

              {orderStylePriceReviewData?.premiumSelectionPriceReview.some(
                (premiumSelectionRow) =>
                  premiumSelectionRow.catalogQuantity +
                    premiumSelectionRow.quantity >
                  0
              ) && (
                <>
                  <WhiteBox>
                    <Wrapper flex middle>
                      <ContainerMedium
                        minWidth={200}
                        maxWidth={200}
                        align="left"
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <H6>PREMIUM SELECTIONS</H6>
                      </ContainerMedium>

                      {isShowCatalogChecked && (
                        <ContainerXSmall
                          flexGrow
                          isTableHeadCell
                          border={['bottom', 'right']}
                        >
                          <PSmall bold>CatQty</PSmall>
                        </ContainerXSmall>
                      )}

                      <ContainerXSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                        flexGrow={!isShowCatalogChecked}
                      >
                        <PSmall bold>Qty</PSmall>
                      </ContainerXSmall>

                      {/* catalog premium selection */}
                      {isShowCatalogChecked && (
                        <>
                          <ContainerSmall
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <H6>CatUnit$</H6>
                          </ContainerSmall>

                          <ContainerSmall
                            isTableHeadCell
                            border={['bottom', 'right']}
                          >
                            <H6>
                              {isListPriceSelected ? 'CatList$' : 'CatNet$'}
                            </H6>
                          </ContainerSmall>
                        </>
                      )}

                      <ContainerSmall
                        isTableHeadCell
                        border={['bottom', 'right']}
                      >
                        <PSmall bold>Unit$</PSmall>
                      </ContainerSmall>

                      <ContainerSmall isTableHeadCell border={['bottom']}>
                        <PSmall bold>
                          {isListPriceSelected ? 'List$' : 'Net$'}
                        </PSmall>
                      </ContainerSmall>
                    </Wrapper>

                    {(
                      Object.keys(
                        PremiumSelectionType
                      ) as (keyof typeof PremiumSelectionType)[]
                    )
                      .filter((x) => Number.isNaN(+x))
                      .map((key) =>
                        showPremiumSelectionPrice(PremiumSelectionType[key]) ? (
                          <PremiumSelectionPriceRow
                            key={key}
                            isListPriceSelected={isListPriceSelected}
                            isShowCatalogChecked={isShowCatalogChecked}
                            title={
                              PremiumSelectionType[PremiumSelectionType[key]]
                            }
                            row={getPremiumSelectionPrice(
                              PremiumSelectionType[key]
                            )}
                          />
                        ) : undefined
                      )}

                    <TotalPriceRow
                      isListPriceSelected={isListPriceSelected}
                      isShowCatalogChecked={isShowCatalogChecked}
                      title="premium total"
                      catalogNetTotal={
                        orderStylePriceReviewData?.catalogPremiumSelectionNetTotal
                      }
                      catalogListTotal={
                        orderStylePriceReviewData?.catalogPremiumSelectionListTotal
                      }
                      listTotal={
                        orderStylePriceReviewData?.premiumSelectionListTotal
                      }
                      netTotal={
                        orderStylePriceReviewData?.premiumSelectionNetTotal
                      }
                    />
                  </WhiteBox>
                </>
              )}

              {orderStylePriceReviewData &&
                orderStylePriceReviewData?.lineItemPriceReview.length > 0 &&
                (orderStylePriceReviewData?.netUpchargesDiscountsTotalPriceForList !==
                  0 ||
                  orderStylePriceReviewData?.netUpchargesDiscountsPriceReview.some(
                    (x) => x.percentage > 0
                  )) && (
                  <>
                    <TotalPrice
                      title={`subtotal style ${style.number}`}
                      spaceTop={40}
                      total={
                        isListPriceSelected
                          ? orderStylePriceReviewData?.totalListPrice.toFixed(2)
                          : orderStylePriceReviewData?.totalNetPrice.toFixed(2)
                      }
                    />

                    <WhiteBox>
                      <Wrapper flex middle>
                        <Wrapper
                          flex
                          middle
                          isTableHeadCell
                          minWidth={150}
                          border={['bottom']}
                          flexGrow
                        >
                          <H6>NET UPCHARGES/DISCOUNTS</H6>
                        </Wrapper>

                        <ContainerXSmall
                          isTableHeadCell
                          border={['bottom', 'right']}
                        >
                          <PSmall bold>%</PSmall>
                        </ContainerXSmall>

                        <ContainerSmall isTableHeadCell border={['bottom']}>
                          <PSmall bold>
                            {isListPriceSelected ? 'List$' : 'Net$'}
                          </PSmall>
                        </ContainerSmall>
                      </Wrapper>

                      {(
                        Object.keys(
                          NetUpchargeType
                        ) as (keyof typeof NetUpchargeType)[]
                      )
                        .filter((x) => Number.isNaN(+x))
                        .map((key) =>
                          showNetUpchargePrice(NetUpchargeType[key]) !== 0 ? (
                            <NetUpchargeRow
                              key={key}
                              isListPriceSelected={isListPriceSelected}
                              upcharge={getNetUpchargePrice(
                                NetUpchargeType[key]
                              )}
                              title={NetUpchargeType[NetUpchargeType[key]]}
                            />
                          ) : undefined
                        )}

                      <TotalPriceRow
                        isListPriceSelected={isListPriceSelected}
                        title="net upcharges/discounts total"
                        spacingBetweenPrices={106}
                        listTotal={
                          orderStylePriceReviewData?.netUpchargesDiscountsTotalPriceForList
                        }
                        netTotal={
                          orderStylePriceReviewData?.netUpchargesDiscountsTotalPriceForNet
                        }
                      />
                    </WhiteBox>
                    <Spacer h="24px" />
                  </>
                )}

              <TotalPriceContainer>
                <Divider />

                {orderStylePriceReviewData?.noCharge ? (
                  <>
                    <Spacer h="16px" />
                    <Wrapper flex middle between>
                      <H6>NO CHARGE</H6>
                      <H6>
                        {isListPriceSelected
                          ? orderStylePriceReviewData?.totalListPriceNoChargeForList.toFixed(
                              2
                            ) ?? '0.00'
                          : orderStylePriceReviewData?.totalListPriceNoChargeForNet.toFixed(
                              2
                            ) ?? '0.00'}
                      </H6>
                    </Wrapper>
                  </>
                ) : undefined}

                <Spacer h="32px" />
                <Wrapper flex middle between>
                  <H6>{`TOTAL STYLE ${style.number}`}</H6>
                  <H6>
                    {isListPriceSelected
                      ? orderStylePriceReviewData?.totalListPriceForList.toFixed(
                          2
                        ) ?? '0.00'
                      : orderStylePriceReviewData?.totalListPriceForNet.toFixed(
                          2
                        ) ?? '0.00'}
                  </H6>
                </Wrapper>
                <Spacer h="16px" />
              </TotalPriceContainer>

              {orderStylePriceReviewData?.lineItemPriceReview.length === 0 && (
                <EmptyState
                  icon={<RemoveShoppingCartIcon />}
                  marginTop={24}
                  marginBottom={40}
                  title="No line items added"
                  message="You haven't added line items to this style yet."
                />
              )}
            </>
          )}
          {!style.isComplete && (
            <EmptyState
              icon={<PaletteIcon />}
              marginTop={24}
              marginBottom={40}
              title="Incomplete Style"
              message="Style is not completed yet. Please return to Styles page and complete this style."
            />
          )}
          {isSalesAids && orderStylePriceReviewData && (
            <>
              <OrderReviewPricingSalesAidsOrder
                orderStylePriceReviewData={orderStylePriceReviewData}
                isReviewPricing={!showStyleDetails}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderReviewPricingStyle;
