import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  OrderItem,
  ImediateActionOrder,
  OrderTableRequest,
  CsrOrderTableRequest,
  ImmediateActionsForOrdersTableRequest,
} from 'shared/interface/OrderRow';

export enum OrdersActions {
  GET_DEALER_ORDERS = 'GET_DEALER_ORDERS',
  GET_CSR_ORDERS = 'GET_CSR_ORDERS',
  RESET_ORDERS = 'RESET_ORDERS',

  ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST',
  SET_ORDER_CANCEL_REQUEST = 'SET_ORDER_CANCEL_REQUEST',
  GET_IMMEDIATE_ACTIONS_FOR_ORDERS = 'GET_IMMEDIATE_ACTIONS_FOR_ORDERS',
  SET_IMMEDIATE_ACTIONS_FOR_ORDERS = 'SET_IMMEDIATE_ACTIONS_FOR_ORDERS',
  GET_ORDERS_FOR_IMPORT = 'GET_ORDERS_FOR_IMPORT',
  SET_BOARD_ORDERS = 'SET_BOARD_ORDERS',
}

export const resetOrders = () => ({
  type: OrdersActions.RESET_ORDERS,
});

export const getDealerOrders = (
  payload: OrderTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: OrdersActions.GET_DEALER_ORDERS,
  payload,
  loading,
  onSuccess,
});

export const setBoardOrders = (
  orders: PaginatedItems<OrderItem>,
  append: boolean
) => ({
  type: OrdersActions.SET_BOARD_ORDERS,
  payload: {
    orders,
    append,
  },
});

export const getCsrOrders = (
  payload: CsrOrderTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: OrdersActions.GET_CSR_ORDERS,
  payload,
  loading,
  onSuccess,
});

export const getOrdersForImport = (
  payload: CsrOrderTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: OrdersActions.GET_ORDERS_FOR_IMPORT,
  payload,
  loading,
  onSuccess,
});

export const getImmediateActionsForOdrers = (
  payload: ImmediateActionsForOrdersTableRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: OrdersActions.GET_IMMEDIATE_ACTIONS_FOR_ORDERS,
  payload,
  loading,
  onSuccess,
});

export const setImmediateActionsForOdrers = (
  orders: PaginatedItems<ImediateActionOrder>
) => ({
  type: OrdersActions.SET_IMMEDIATE_ACTIONS_FOR_ORDERS,
  payload: orders,
});

export interface OrderCancelRequestRequest {
  orderId: string;
}

export const orderCancelRequest = (
  payload: OrderCancelRequestRequest,
  onSuccess: () => void
) => ({
  type: OrdersActions.ORDER_CANCEL_REQUEST,
  payload,
  onSuccess,
});

export const setOrderCancelRequested = (orderId: string) => ({
  type: OrdersActions.SET_ORDER_CANCEL_REQUEST,
  payload: orderId,
});
