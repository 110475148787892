import { PaginatedItems } from 'shared/interface/PaginatedItems';

import { IModification } from 'order/wizard/orderLineItems/components/Modifications/interface/IModification';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ModificationModalStepsEnums } from '../enums/ModificationModalStepsEnums';

export enum lineItemModificationsActions {
  CLEAR_MODIFICATIONS_MODAL = 'CLEAR_MODIFICATIONS_MODAL',
  SET_MOD_ACTIVE_STEP = 'SET_MOD_ACTIVE_STEP',

  SET_SELECTED_MODIFICATIONS = 'SET_SELECTED_MODIFICATIONS',

  UPDATE_MODAL_MODIFICATION = 'UPDATE_MODAL_MODIFICATION',
  DELETE_MODAL_MODIFICATION = 'DELETE_MODAL_MODIFICATION',

  SET_SELECTED_MODIFICATION = 'SET_SELECTED_MODIFICATION',

  UPDATE_MODIFICATION_MODAL = 'UPDATE_MODIFICATION_MODAL',

  SEARCH_MODIFICATIONS = 'SEARCH_MODIFICATIONS',
  SET_SEARCHED_MODIFICATIONS = 'SET_SEARCHED_MODIFICATIONS',
  SET_MODIFICATION_FLOW = 'SET_MODIFICATION_FLOW',
}

export const clearModsModal = () => ({
  type: lineItemModificationsActions.CLEAR_MODIFICATIONS_MODAL,
});

export const setSelectedModifications = (modifications: IModification[]) => ({
  type: lineItemModificationsActions.SET_SELECTED_MODIFICATIONS,
  payload: modifications,
});

export const updateModalModification = (modification: IModification) => ({
  type: lineItemModificationsActions.UPDATE_MODAL_MODIFICATION,
  payload: modification,
});

export const deleteModalModification = (modificationId: string) => ({
  type: lineItemModificationsActions.DELETE_MODAL_MODIFICATION,
  payload: modificationId,
});

export const setSelectedModification = (
  modification: IModification | null
) => ({
  type: lineItemModificationsActions.SET_SELECTED_MODIFICATION,
  payload: modification,
});

export const setModificationsModalActiveStep = (
  value: ModificationModalStepsEnums
) => ({
  type: lineItemModificationsActions.SET_MOD_ACTIVE_STEP,
  payload: value,
});

export const updateModificationsModal = (
  step: ModificationModalStepsEnums,
  title: ModificationModalStepsEnums,
  desc: ModificationModalStepsEnums
) => ({
  type: lineItemModificationsActions.UPDATE_MODIFICATION_MODAL,
  payload: { step, title, desc },
});

export const setModificationFlow = ({
  replaceModification,
  modificationToReplace,
}: {
  replaceModification: boolean;
  modificationToReplace: IModification;
}) => ({
  type: lineItemModificationsActions.SET_MODIFICATION_FLOW,
  payload: { replaceModification, modificationToReplace },
});

export interface SearchModificationsRequest {
  productLineId: string;
  styleId: string;
  lineItemPrice: string;
  lineItemWidth: string;
  lineItemHeight: string;
  lineItemDepth: string;
  catalogLineItemId: string;
  appendItems: boolean;
  page: string;
  itemsPerPage: string;
  searchTerm: string;
  cabinetBoxMaterialId?: string;
  doorOverlayId?: string;
}

export const searchModifications = (
  payload: SearchModificationsRequest,
  loading: (isLoading: boolean) => void,
  onSuccess?: (items: SelectOptionProps[]) => void
) => ({
  type: lineItemModificationsActions.SEARCH_MODIFICATIONS,
  payload,
  loading,
  onSuccess,
});

export interface SearchedModifications {
  searchedModifications: PaginatedItems<IModification>;
  appendItems: boolean;
}

export const setSearchedModifications = (
  payload: SearchedModifications | null
) => ({
  type: lineItemModificationsActions.SET_SEARCHED_MODIFICATIONS,
  payload,
});
