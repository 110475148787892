import { RootState } from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Container } from 'shared/components/Container';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useQueryParams } from 'shared/hooks/useQueryParams';

import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';

import {
  Stepper,
  StepperList,
  Step,
  StepPanel,
} from 'shared/components/Stepper';

import { clearAsapReducer, setAsapStep } from './store/OrderAsapActions';
import { OrderAsapParams } from './interface/OrderAsapParams';
import OrderAsapHeader from './components/OrderAsapHeader/OrderAsapHeader';
import OrderForm from '../orderForm/OrderForm';
import OrderSelectItems from './components/OrderSelectItems/OrderSelectItems';
import OrderAttachmentsStep from './components/OrderAttachmentsStep/OrderAttachmentsStep';

const OrderAsapOrDuplicateContainer = styled.div``;

const StepperListStyled = styled(StepperList)`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  padding: 38px 0 26px 0;
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  z-index: 1;
`;

const OrderAsapOrDuplicate = () => {
  const dispatch = useAppDispatch();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const isOrderSalesMaterial = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.stylizationType ===
      OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const [queryFields] = useQueryParams<OrderAsapParams>([
    'orderId',
    'newOrderId',
    'step',
  ]);

  const asapStep = useSelector(
    (state: RootState) => state.orderAsapReducer.asapStep
  );

  const styles = useSelector(
    (state: RootState) => state.orderAsapReducer.styles
  );

  const lineItems = useSelector(
    (state: RootState) => state.orderAsapReducer.lineItems
  );

  // const changeAsapStepHandler = (index: number) => {
  //   dispatch(setAsapStep(index));
  // };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (queryFields) {
      dispatch(setAsapStep(queryFields?.newOrderId ? 1 : 0));
    }
  }, [queryFields]);

  useEffect(() => {
    return () => {
      dispatch(clearAsapReducer());
    };
  }, []);

  const stepFromUrl = queryFields?.step;

  useEffect(() => {
    const stepNumber = Number(stepFromUrl);
    if (stepNumber) {
      dispatch(setAsapStep(stepNumber));
    }
  }, [stepFromUrl]);

  return (
    <OrderAsapOrDuplicateContainer>
      <Spacer h="34px" />

      <Stepper selectedIndex={asapStep}>
        <StepperListStyled>
          <Step readOnly>Basic Info</Step>
          <Step readOnly>
            {order && !isOrderSalesMaterial ? 'Styles and Items' : 'Items'}
          </Step>
          {order && !isOrderSalesMaterial && <Step readOnly>Attachments</Step>}
        </StepperListStyled>

        <StepPanel>
          <>
            <OrderAsapHeader title="Adjust the basic info tab according to your needs" />
            <Container width={744}>
              <OrderForm />
            </Container>
          </>
        </StepPanel>
        <StepPanel>
          <>
            {(!isOrderSalesMaterial ? styles ?? [] : lineItems ?? []).length >
              0 && (
              <OrderAsapHeader
                title={`Select ${
                  !isOrderSalesMaterial ? 'styles and ' : ''
                }line items from the existing order`}
                desc="Check items and add them quickly..."
              />
            )}
            <OrderSelectItems />
          </>
        </StepPanel>
        {order && !isOrderSalesMaterial && (
          <StepPanel>
            <>
              <OrderAsapHeader
                title="Attachments"
                desc="Please whether you want to copy the attachments"
              />
              <OrderAttachmentsStep />
            </>
          </StepPanel>
        )}
      </Stepper>
    </OrderAsapOrDuplicateContainer>
  );
};

export default OrderAsapOrDuplicate;
