import { SetStateAction, useState, FC, useEffect, Dispatch } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ACKTypeEnum } from 'order/wizard/orderAcknowledgement/enums/ACKTypeEnum';

import {
  OrderFilterValues,
  OrderFilterValuesRipped,
} from 'order/interfaces/OrderFilterValues';

import { getImmediateActionsForOdrers } from 'overview/orders/store/ordersActions';

import { Checkbox } from 'shared/components/Checkbox';
import { H4, P } from 'shared/components/Typography';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { Table, Thead, Tr, Th, Tbody, Td } from 'shared/components/Table';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';

import {
  ImediateActionOrder,
  ImmediateActionsForOrdersTableRequest,
} from 'shared/interface/OrderRow';
import { anzac, saltpan, yellow } from 'shared/config/Colors';
import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';

interface StyledTrProps {
  onClick?: (orderId: string) => void;
  backgroundColor: string;
}

const ScrollTable = styled(Table)`
  max-height: 400px;
  height: auto !important;
  scroll-behavior: auto;

  > thead {
    background-color: ${({ theme }) => theme.white};
    position: sticky;
    top: 52px;
    z-index: 5;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -12px;
      height: 12px;
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

const StyledTr = styled(Tr)<StyledTrProps>`
  ${({ onClick }) => onClick && 'cursor: pointer'};
  ${({ backgroundColor }) => `background-color:${backgroundColor} !important`};

  &.highlight {
    background: #e3aa5333;
    border-radius: 16px 16px 0px 0px;
  }

  > ${Td} {
    height: 48px;
  }
`;

const CheckboxWrapper = styled(Wrapper)`
  border-bottom: 1px solid ${({ theme }) => theme.mischka};
  position: sticky;
  top: -15px;
  z-index: 5;
  background-color: ${({ theme }) => theme.white};
  padding: 16px;
`;

enum TableColumns {
  DEALERSHIP = 'dealership',
  JOB = 'Job',
  ROOM = 'Room',
  REQUEST_TYPE = 'RequestType',
  SHIP_WEEK = 'ShipWeekStartDate',
  ACK_DATE = 'AcknowledgementDate',
  EXPIRATION_DATE = 'ExpirationDate',
  ORDERED_BY = 'OrderedBy',
  SERIAL_NUMBER = 'SerialNumber',
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
export interface ImediateActionOrdersProps {
  isUserCSR?: boolean;
  onlyMine: boolean;
  setOnlyMine: Dispatch<SetStateAction<boolean>>;
  orderList: PaginatedItems<ImediateActionOrder> | null;
  search: OrderFilterValues | OrderFilterValuesRipped;
}

export const ImediateActionOrders: FC<ImediateActionOrdersProps> = ({
  isUserCSR,
  onlyMine,
  setOnlyMine,
  orderList,
  search,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [sortColumn, setSortColumn] = useState(TableColumns.JOB);
  const [sortDir, setSortDir] = useState(SortDirection.DESC);
  const [isDirty, setIsDirty] = useState(false);

  const fontSize = 12;
  const tdFontSize = 14;

  const handleRowClick = (orderID: string | undefined) => {
    if (orderID != null) {
      history.push(`/order/${orderID}/overview`, {
        backToTab: (search as OrderFilterValues).tab,
      });

      window.scrollTo(0, 0);
    }
  };

  const onSortHandle = (columnName: SetStateAction<TableColumns>) => {
    setIsDirty(true);
    setSortColumn(columnName);

    if (columnName === sortColumn && sortDir === SortDirection.ASC) {
      setSortDir(SortDirection.DESC);
    } else {
      setSortDir(SortDirection.ASC);
    }
  };

  const onOnlyMineChangeHandler = () => {
    setIsDirty(true);
    setOnlyMine((prevState) => !prevState);
    window.localStorage.setItem('onlyMine', (!onlyMine).toString());
  };

  useEffect(() => {
    if (isDirty) {
      const payload: ImmediateActionsForOrdersTableRequest = {
        page: 1,
        itemsPerPage: 1000,
        sortColumn,
        sortDir,
        onlyMine,
      };
      dispatch(getImmediateActionsForOdrers(payload));
    }
  }, [sortColumn, sortDir, onlyMine, isDirty]);

  const getHighlightColor = (status: ACKStatusEnum) => {
    switch (status) {
      case ACKStatusEnum.CHANGES_REQUESTED:
        return yellow;
      case ACKStatusEnum.FINISHED_REQUESTING_CHANGES:
        return saltpan;
      default:
        return '';
    }
  };

  return (
    <Wrapper flex column>
      <CheckboxWrapper flex between>
        <H4>Immediate Action Needed</H4>

        {isUserCSR && (
          <Checkbox
            title="Only mine"
            id="onlyMine"
            checked={onlyMine}
            onChange={onOnlyMineChangeHandler}
          />
        )}
      </CheckboxWrapper>
      <ScrollTable>
        <Thead>
          <Tr>
            <Th fontSize={fontSize}>Dealership</Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.JOB}
              onClick={() => onSortHandle(TableColumns.JOB)}
            >
              Job
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.ROOM}
              onClick={() => onSortHandle(TableColumns.ROOM)}
            >
              Room
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.SERIAL_NUMBER}
              onClick={() => onSortHandle(TableColumns.SERIAL_NUMBER)}
            >
              W.O.#
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.REQUEST_TYPE}
              onClick={() => onSortHandle(TableColumns.REQUEST_TYPE)}
            >
              Request Type
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.SHIP_WEEK}
              onClick={() => onSortHandle(TableColumns.SHIP_WEEK)}
            >
              Ship week
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.ACK_DATE}
              onClick={() => onSortHandle(TableColumns.ACK_DATE)}
            >
              Ack. Date
            </Th>
            <Th
              fontSize={fontSize}
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.EXPIRATION_DATE}
              onClick={() => onSortHandle(TableColumns.EXPIRATION_DATE)}
            >
              Expiration Date
            </Th>
            <Th
              fontSize={fontSize}
              data-name="13"
              data-direction={sortDir}
              data-active={sortColumn === TableColumns.ORDERED_BY}
              onClick={() => onSortHandle(TableColumns.ORDERED_BY)}
            >
              Ordered by
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderList && orderList.items.length > 0 ? (
            orderList.items.map((order) => {
              return (
                <StyledTr
                  key={order.id}
                  onClick={() => handleRowClick(order.id)}
                  backgroundColor={
                    isUserCSR
                      ? getHighlightColor(order.acknowledgementStatus)
                      : ''
                  }
                >
                  <Td fontSize={tdFontSize}>{order.dealership?.name}</Td>
                  <Td fontSize={tdFontSize}>{order.job}</Td>
                  <Td fontSize={tdFontSize}>{order.room}</Td>
                  <Td fontSize={tdFontSize}>{order.serialNumber}</Td>
                  <Td data-name="12" fontSize={tdFontSize}>
                    {ACKTypeEnum[+order.requestType]}
                  </Td>
                  <Td fontSize={tdFontSize}>
                    {UtilService.getShipWeekLabel({
                      defaultValue: '--',
                      shipWeekDescription: order.shipWeekDescription,
                      shipWeekDate: order.shipWeekDate,
                      scheduleType: order.scheduleType,
                    })}
                  </Td>
                  <Td data-name="18" fontSize={tdFontSize}>
                    {order.acknowledgementDate
                      ? UtilService.formatDate({
                          date: order.acknowledgementDate,
                        })
                      : '--'}
                  </Td>
                  <Td fontSize={tdFontSize}>
                    <P
                      fontSize="inherit"
                      fontWeight={
                        order.expirationDateExtended ? 600 : undefined
                      }
                      color={order.expirationDateExtended ? anzac : undefined}
                    >
                      {order.expirationDate
                        ? UtilService.formatDate({
                            date: order.expirationDate,
                            isUtc: order.expirationDateExtended,
                          })
                        : '--'}
                    </P>
                  </Td>
                  <Td data-name="13" fontSize={tdFontSize}>
                    {order.orderedBy}
                  </Td>
                </StyledTr>
              );
            })
          ) : (
            <Tr>
              {/* hack to span td across all cols regardless the number of cols */}
              <Td colSpan={42}>No orders.</Td>
            </Tr>
          )}
        </Tbody>
      </ScrollTable>
    </Wrapper>
  );
};
