import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';

const AttachmentsModalHeaderContainer = styled.div`
  margin-top: -12px;
  margin-bottom: 24px;
`;

const AttachmentsModalHeader = () => {
  const attachmentsModalTitle = useSelector(
    (state: RootState) => state.attachmentsModalReducer.modalTitle
  );

  const attachmentsModalDesc = useSelector(
    (state: RootState) => state.attachmentsModalReducer.modalDesc
  );

  return (
    <AttachmentsModalHeaderContainer>
      <H2>{attachmentsModalTitle}</H2>
      <Spacer h="2px" />
      <P color={nevada}>{attachmentsModalDesc}</P>
    </AttachmentsModalHeaderContainer>
  );
};

export default AttachmentsModalHeader;
