import { AnyAction } from 'redux';

import { WritingHints } from 'order/writingHints/interface/WritingHints';

import { WritingHintsActions } from './writingHintsActions';

interface WritingHintsInitialState {
  writingHints: WritingHints | null;
}

const initialState: WritingHintsInitialState = {
  writingHints: null,
};

export const writingHintsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case WritingHintsActions.SET_WRITING_HINTS:
      return { ...state, writingHints: action.payload as WritingHints };
    default:
      return state;
  }
};
