import { InputHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as CheckLine } from 'assets/icons/check-line.svg';

import { white } from 'shared/config/Colors';

import { Input } from './Input';
import { Wrapper } from './Wrapper';

export enum TriStateCheckboxEnum {
  CHECKED = 'CHECKED',
  UNCHECKED = 'UNCHECKED',
  INDETERMINATE = 'INDETERMINATE',
}

const CheckSign = styled(Check)`
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transition: 300ms ease;
`;

const CheckLineSign = styled(CheckLine)`
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 7.5px;
  transition: 300ms ease;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.nevada};
  cursor: pointer;
  display: block;
  font-size: 14px;
  min-height: 18px;
  padding-left: 18px;
  position: relative;
  user-select: none;

  &::before {
    background-color: ${({ theme }) => theme.white};
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.mischka};
    content: '';
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transition: 300ms ease;
    width: 16px;
  }

  &:hover:not(:disabled) {
    &::before {
      background-color: ${({ theme }) => theme.blackSqueeze};
      border-color: ${({ theme }) => theme.pigeonPost};
    }
  }
`;

const LabelTitle = styled.span`
  margin-left: 6px;
`;

const CheckboxContainer = styled.div`
  > ${Input} {
    display: none;

    &:disabled,
    &[readonly] {
      + ${Label} {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &:indeterminate {
      + ${Label} {
        &::before {
          background-color: ${({ theme }) => theme.lynch};
          border-color: ${({ theme }) => theme.lynch};
        }

        ${CheckLineSign} {
          opacity: 1;
        }
      }
    }

    &:checked {
      + ${Label} {
        &::before {
          background-color: ${({ theme }) => theme.lynch};
          border-color: ${({ theme }) => theme.lynch};
        }

        ${CheckSign} {
          opacity: 1;
        }
      }
    }

    &[aria-invalid='true'] + label {
      color: ${({ theme }) => theme.valencia};

      &:before {
        border-color: ${({ theme }) => theme.valencia};
      }
    }

    &:not([aria-invalid='true']):not(:read-only):focus + label {
      background-color: ${({ theme }) => theme.catskillWhite};
      border-color: ${({ theme }) => theme.pigeonPost};
    }
  }
`;

export const Checkbox = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <CheckboxContainer hidden={props.hidden}>
      <Input ref={ref} {...props} type="checkbox" id={props.id} />

      <Label htmlFor={props.id}>
        <Wrapper color={white}>
          <CheckSign />
          <CheckLineSign />
        </Wrapper>
        {props.title && <LabelTitle>{props.title}</LabelTitle>}
      </Label>
    </CheckboxContainer>
  );
});
