import { P, SPAN } from 'shared/components/Typography';
import styled from 'styled-components';

export const ACKSheetHint = styled(P)`
  color: ${({ theme }) => theme.mineShaft};
  font-size: 14px;
  margin-bottom: 8px;
`;

export const ACKTypeSheetContainer = styled.div`
  > ${P}:not(${ACKSheetHint}) {
    font-size: 14px;
    color: ${({ theme }) => theme.nevada};
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    > ${SPAN} {
      width: 340px;
      color: ${({ theme }) => theme.nevada};
    }
  }
`;
