import { useHighlightHelper } from 'order/shared/ackchangelog/helpers/useHighlightHelper';
import { FieldName } from 'order/shared/ackchangelog/interface/AckChangeLogItem';
import { FC } from 'react';
import { ContainerMedium, ContainerSmall } from 'shared/components/Container';
import { PSmall } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { nevada } from 'shared/config/Colors';
import styled from 'styled-components';
import { Spacer } from 'shared/components/Layout';
import { LineItemPriceReview } from '../orderStyles/interface/OrderPriceReview';
import UtilService from '../../../shared/services/util.service';

const OrderReviewPricingSMRowContainer = styled(Wrapper)`
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &.strikethrough {
      text-decoration: line-through;
    }
  }
`;

interface OrderReviewPricingSMRowProps {
  lineItem: LineItemPriceReview;
  isReviewPricing?: boolean;
}

const OrderReviewPricingSalesMaterialRow: FC<OrderReviewPricingSMRowProps> = ({
  lineItem,
  isReviewPricing,
}) => {
  const {
    highlightLineItemRow,
    highlightLineItemField,
    highlightModificationField,
    highlightDeletedModifications,
    highlightModificationRow,
  } = useHighlightHelper(!isReviewPricing);
  return (
    <>
      <OrderReviewPricingSMRowContainer
        flex
        className={highlightLineItemRow(
          null,
          lineItem.lineItemId,
          lineItem.isDeleted
        )}
      >
        <ContainerSmall isTableCell border={['right']}>
          <PSmall color={nevada}>{lineItem.pwwNumber}</PSmall>
        </ContainerSmall>
        <ContainerSmall
          isTableCell
          border={['right']}
          className={highlightLineItemField(null, lineItem.lineItemId, [
            FieldName.LineItemClientLineItemNumber,
          ])}
        >
          <PSmall color={nevada}>{lineItem.clientLineItemNumber}</PSmall>
        </ContainerSmall>

        <ContainerSmall
          isTableCell
          border={['right']}
          className={highlightLineItemField(null, lineItem.lineItemId, [
            FieldName.LineItemNumber,
          ])}
        >
          <PSmall color={nevada}>{lineItem.number}</PSmall>
        </ContainerSmall>

        <ContainerSmall
          isTableCell
          border={['right']}
          className={highlightLineItemField(null, lineItem.lineItemId, [
            FieldName.LineItemQuantity,
          ])}
        >
          <PSmall color={nevada}>{lineItem.quantity}</PSmall>
        </ContainerSmall>

        <ContainerMedium align="left" isTableCell border={['right']} flexGrow>
          <PSmall bold>
            {lineItem.isDeleted ? '[VOID]' : ''}
            {UtilService.generateLineItemNameWithDimensions(lineItem)}
          </PSmall>
        </ContainerMedium>

        <ContainerSmall isTableCell border={['right']}>
          <PSmall color={nevada}>{lineItem.unitPrice.toFixed(2)}</PSmall>
        </ContainerSmall>

        <ContainerSmall isTableCell>
          <PSmall color={nevada}>{lineItem.netPrice.toFixed(2)}</PSmall>
        </ContainerSmall>
      </OrderReviewPricingSMRowContainer>

      {lineItem.modificationsPrice &&
        lineItem.modificationsPrice
          .concat(highlightDeletedModifications(null, lineItem.lineItemId))
          .map((modification, index) => (
            <OrderReviewPricingSMRowContainer
              key={modification.id}
              flex
              middle
              className={highlightModificationRow(
                null,
                lineItem.lineItemId,
                modification.description,
                modification.isDeleted
              )}
            >
              <ContainerSmall isTableCell border={['right']} />
              <ContainerSmall isTableCell border={['right']} />

              <ContainerSmall isTableCell border={['right']} />
              <ContainerSmall isTableCell border={['right']}>
                <PSmall
                  className={highlightModificationField(
                    null,
                    lineItem.lineItemId,
                    index + 1,
                    [FieldName.ModificationQuantity]
                  )}
                >
                  {modification.quantity}
                </PSmall>
              </ContainerSmall>
              <ContainerMedium
                flex
                isTableCell
                border={['right']}
                align="left"
                flexGrow
              >
                <Spacer w="15px" />

                <Wrapper>
                  <PSmall color={nevada}>
                    {modification.isDeleted ? '[VOID] ' : ''}
                    {modification.description ||
                      modification.name.toUpperCase()}
                  </PSmall>

                  {(modification.note + modification.additionalNote).length >
                    0 && (
                    <>
                      <Spacer h="5px" />

                      <PSmall
                        fontSize={12}
                        color={nevada}
                        className={highlightModificationField(
                          null,
                          lineItem.lineItemId,
                          index + 1,
                          [
                            FieldName.ModificationNote,
                            FieldName.ModificationAdditionalNote,
                          ]
                        )}
                      >
                        {[modification.note, modification.additionalNote]
                          .filter((note) => note !== '')
                          .join(', ')}
                      </PSmall>
                    </>
                  )}
                </Wrapper>
              </ContainerMedium>
              <ContainerSmall isTableCell border={['right']}>
                <PSmall />
              </ContainerSmall>

              <ContainerSmall isTableCell border={['right']} mrAuto />
              <ContainerSmall isTableCell border={['right']}>
                <PSmall color={nevada}>
                  {modification.unitPrice.toFixed(2)}{' '}
                </PSmall>
              </ContainerSmall>
              <ContainerSmall isTableCell border={['right']}>
                <PSmall color={nevada}>
                  {modification.netPrice.toFixed(2)}{' '}
                </PSmall>
              </ContainerSmall>
            </OrderReviewPricingSMRowContainer>
          ))}
    </>
  );
};

export default OrderReviewPricingSalesMaterialRow;
