import { createContext, FC } from 'react';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

export interface OrderFormContextData {
  csrSelectedDealershipId?: string | null;
  csrSelectedDealershipUserId?: string | null;
  dealershipId?: string | null;
  editMode?: boolean;
  isCSRSelectedDealershipDirty?: boolean;
  isCSRSelectedDealershipUserDirty?: boolean;
  onDealershipChange?: (option: SelectOptionProps | null) => void;
  onDealershipUserChange?: (option: SelectOptionProps | null) => void;
  setIsCSRSelectedDealershipDirty?: (isDirty: boolean) => void;
  setIsCSRSelectedDealershipUserDirty?: (isDirty: boolean) => void;
  removedMyselfAsCollaborator?: boolean;
  onRemovedMyselfAsCollaborator?: (removed: boolean) => void;
  handleChangeCallbackWrapper?: (callback: () => void) => void;
}

export const OrderFormContext = createContext<OrderFormContextData>({});

export interface ProviderProps {
  contextData: Partial<OrderFormContextData>;
}

export const Provider: FC<ProviderProps> = ({ children, contextData }) => {
  return (
    <OrderFormContext.Provider value={contextData}>
      {children}
    </OrderFormContext.Provider>
  );
};
