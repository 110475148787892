import { AnyAction } from 'redux';

import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { ImediateActionOrder, OrderItem } from 'shared/interface/OrderRow';

import { OrdersActions } from './ordersActions';

interface InitialState {
  imediateActionOrders: PaginatedItems<ImediateActionOrder> | null;
  orderTable: PaginatedItems<OrderItem> | null;
}

const initialState: InitialState = {
  imediateActionOrders: null,
  orderTable: null,
};

export const ordersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OrdersActions.SET_BOARD_ORDERS:
      return {
        ...state,
        orderTable: {
          ...action.payload.orders,
          items:
            state.orderTable && action.payload.append
              ? [...state.orderTable?.items, ...action.payload.orders.items]
              : [...action.payload.orders.items],
        } as PaginatedItems<OrderItem>,
      };
    case OrdersActions.SET_IMMEDIATE_ACTIONS_FOR_ORDERS:
      return {
        ...state,
        imediateActionOrders:
          action.payload as PaginatedItems<ImediateActionOrder>,
      };

    case OrdersActions.RESET_ORDERS:
      return {
        ...state,
        orderTable: null,
      };

    case OrdersActions.SET_ORDER_CANCEL_REQUEST:
      return {
        ...state,
        orderTable: {
          ...state.orderTable,
          items: (state.orderTable?.items ?? []).map((item) =>
            item.id === action.payload
              ? { ...item, cancellationRequested: !item.cancellationRequested }
              : item
          ) as OrderItem[],
        } as PaginatedItems<OrderItem>,
      } as InitialState;

    default:
      return state;
  }
};
