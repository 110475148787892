import { AnyAction } from 'redux';
import { User } from 'shared/interface/User';
import { authActions } from './authActions';

interface InitialState {
  user: User | null;
  isAuth: boolean;
}

const initialState: InitialState = {
  user: null,
  isAuth: false,
};

export const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case authActions.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload as boolean,
      };
    case authActions.SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload as User,
      };
    case authActions.UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        } as User,
      };
    case authActions.USER_LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: null,
      };
    case authActions.UPDATE_USER_PROFILE_PHOTO:
      return {
        ...state,
        user: {
          ...(state.user as User),
          imageUrl: action.payload as string,
        },
      };
    default:
      return state;
  }
};
