import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import { H1 } from 'shared/components/Typography';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import MetaTitle from 'shared/components/MetaTitle';

import { ManageUsersEditUserForm } from './components/ManageUsersEditUserForm';

const ContentContainer = styled.div`
  // max-height: calc(100vh - 136px);
  // overflow: auto;
`;

export const ManageUsersEditUserPage = () => {
  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  return (
    <>
      <MetaTitle title="Edit User | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft>
          <H1>Edit User</H1>

          {canCreateDealershipUsers && (
            <Link to="/overview/manage-users/add-user">
              <PlusIcon />
              <span>Add User</span>
            </Link>
          )}
        </OverviewHeaderLeft>
      </OverviewHeader>

      <ContentContainer>
        <ManageUsersEditUserForm />
      </ContentContainer>
    </>
  );
};
