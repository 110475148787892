import { useLocation } from 'react-router';
import { IModification } from 'order/wizard/orderLineItems/components/Modifications/interface/IModification';
import { useState } from 'react';
import { AdditionalField } from 'order/additonalFields/interfaces/AdditionalField';
import {
  AdditionalFieldEntityType,
  AdditionalFieldType,
} from 'order/additonalFields/enums/AdditionalFieldsEnums';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useFormModal, { FormAnswer } from './useFormModal';

interface UseAdditionalFieldsOptions {
  onCloseCallBack?: () => void;
}

export const useAdditionalFields = (options?: UseAdditionalFieldsOptions) => {
  const location = useLocation();

  const queryFields = new URLSearchParams(location.search);
  const productLineId = queryFields.get('productLineId');

  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState<AdditionalField[]>([]);
  const [answers, setAnswers] = useState<FormAnswer[]>([]);

  const onClosePopUp = () => {
    if (options?.onCloseCallBack) {
      options.onCloseCallBack();
    }

    setOpened(false);
  };

  const onFormSubmit = (data: FormAnswer[]) => {
    if (data.length) {
      setAnswers(data);
    }
    onClosePopUp();
  };

  const { FormModal } = useFormModal({
    opened: opened && selected.length > 0,
    onClose: () => onClosePopUp(),
    additionalFields: selected,
    submitForm: onFormSubmit,
  });

  const additionalFields = useSelector(
    (state: RootState) => state.aditionalFieldReducer.aditionalFields
  );

  function onLineItemLoad(entityId: string) {
    const addField = additionalFields?.filter(
      (af) =>
        af.entityType === AdditionalFieldEntityType.LineItem &&
        af.entityId === entityId &&
        af.productLineId === productLineId &&
        af.type === AdditionalFieldType.Variable
    );

    if (addField?.length) {
      setOpened(true);
      setSelected([...addField]);
    }
  }

  function onModificationLoad(modification: IModification) {
    const entityId =
      modification.catalogModificationId ?? modification.id ?? '';
    const addField = additionalFields?.filter(
      (af) =>
        af.entityType === AdditionalFieldEntityType.Modification &&
        af.entityId === entityId &&
        af.productLineId === productLineId &&
        af.type === AdditionalFieldType.Variable
    );

    if (addField?.length) {
      setOpened(true);
      setSelected([...addField]);
    }
  }

  return {
    onLineItemLoad,
    onModificationLoad,
    onClosePopUp,
    FormModal,
    formModalOpened: opened,
    answers,
  };
};
