import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Location } from 'history';

export default function useWindowScrollPosition(setCondition?: boolean): void {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (setCondition) {
      const positionState = window.sessionStorage.getItem(location.pathname);
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          window.scrollTo(0, +(positionState ?? '0'));
        }, 500);
      });
    }
  }, [setCondition]);

  const beforeUnloadListener = (update: Location) => {
    if (
      update.pathname !== location.pathname ||
      update.search !== location.search
    ) {
      window.sessionStorage.setItem(
        location.pathname,
        window.scrollY.toString()
      );
    }
  };

  useEffect(() => {
    const historyUnsubscribe = history.listen((update) => {
      const loc = update as unknown as Location;
      beforeUnloadListener(loc);
    });
    return () => historyUnsubscribe();
  }, []);
}
