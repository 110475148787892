import styled, { css } from 'styled-components';

import {
  TextAlign,
  TextDecoration,
  TextTransform,
  WordBreak,
} from 'shared/interface/CSSTypes';

const headingFont = `
  font-weight: 600;
`;

interface HBaseProps {
  textTransform?: TextTransform;
  fontSize?: number;
  wordBreak?: WordBreak;
}

const HBase = css<HBaseProps>`
  ${({ textTransform, wordBreak }) => css`
    ${textTransform && `text-transform: ${textTransform}`};
    ${wordBreak && `word-break: ${wordBreak}`};
  `}
`;

export const H1 = styled.h1`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '28'}px;
`;

export const H2 = styled.h2`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '22'}px;
`;

export const H3 = styled.h3`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '18'}px;
`;

export const H4 = styled.h4`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '16'}px;
`;

export const H5 = styled.h5`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '14'}px;
  line-height: 20px;
`;

export const H6 = styled.h6`
  ${HBase}
  ${headingFont}

  font-size: ${({ fontSize }) => fontSize ?? '12'}px;
  line-height: 16px;
`;

interface PProps {
  color?: string | 'inherit';
  bold?: boolean;
  textTransform?: TextTransform;
  fontSize?: number | 'inherit';
  textAlign?: TextAlign;
  fontWeight?: number;
  textDecoration?: TextDecoration;
  pointer?: boolean;
  minWidth?: number;
  wordBreak?: WordBreak;
}

export const P = styled.p<PProps>`
  color: ${({ color, theme }) => color ?? theme.mineShaft};
  font-size: ${({ fontSize }) =>
    typeof fontSize === 'number' ? `${fontSize}px;` : fontSize ?? `16px`};
  font-weight: ${({ bold, fontWeight }) => !fontWeight && (bold ? 600 : 400)};
  font-weight: ${({ fontWeight, bold }) => !bold && fontWeight && fontWeight};

  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};

  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration}`};

  ${({ pointer }) => pointer && `cursor: pointer`};

  &.invalid {
    color: ${({ theme }) => theme.valencia};
  }
`;

export const PDark = styled(P)`
  color: ${({ theme }) => theme.mineShaft};
`;

export const PLarge = styled(P)`
  font-size: 16px;
  line-height: 22px;
`;

export const PMedium = styled(P)`
  font-size: 14px;
  line-height: 20px;

  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const PSmall = styled(P)`
  font-size: 12px;
  line-height: 16px;
  &.highlight {
    background: ${({ theme }) => theme.anzac}20;
    border: 2px solid ${({ theme }) => theme.anzac}20;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &.strikethrough {
      text-decoration: line-through;
    }
  }
`;

export const PXSmall = styled(P)`
  font-size: 10px;
  line-height: 14px;
`;

export const PSmallWidthMargin = styled(P)`
  font-size: 12px;
  line-height: 16px;
  margin-left: 25px;
`;

interface SpanProps {
  color?: string | 'inherit';
  bold?: boolean;
  textTransform?: TextTransform;
  fontSize?: number | string;
  textAlign?: TextAlign;
  fontWeight?: number;
  textDecoration?: TextDecoration;
  pointer?: boolean;
  minWidth?: number;
  wordBreak?: WordBreak;
}

export const SPAN = styled.span<SpanProps>`
  color: ${({ color, theme }) => color ?? theme.mineShaft};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px;` : '16px')};
  font-weight: ${({ bold, fontWeight }) => !fontWeight && (bold ? 600 : 400)};
  font-weight: ${({ fontWeight, bold }) => !bold && fontWeight && fontWeight};

  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};

  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration}`};

  ${({ pointer }) => pointer && `cursor: pointer`};

  &.invalid {
    color: ${({ theme }) => theme.valencia};
  }
`;
