import styled from 'styled-components';
import { FC, useState } from 'react';

import { OrderFilterValues } from 'order/interfaces/OrderFilterValues';
import { TableTabsEnums } from 'order/enums/orderTableEnums';

import { Checkbox } from 'shared/components/Checkbox';
import { Spacer } from 'shared/components/Layout';
import { TabProps, StyledTabs } from 'shared/components/StyledTabs';
import { Wrapper } from 'shared/components/Wrapper';

import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { OrdersTable, OrdersTableProps } from './OrdersTable';

enum Tabs {
  General = 1,
  Timeline,
  Metrics,
}

const CheckboxWrapper = styled(Wrapper)`
  padding: 12px 23px;
  position: absolute;
  top: 0;
  right: 0;
`;

type CsrOrdersTableProps = Pick<
  OrdersTableProps,
  'isLoading' | 'setSearch' | 'search' | 'isQuoteBoard'
>;

export const CsrOrdersTable: FC<CsrOrdersTableProps> = ({
  isLoading,
  setSearch,
  search,
  isQuoteBoard,
}: CsrOrdersTableProps) => {
  const [selectedTab, setSelectedTab] = useState(TableTabsEnums.GENERAL);

  const tabs: TabProps[] = Object.keys(Tabs)
    .filter((k) => Number.isNaN(+k))
    .map((key) => {
      return {
        text: key,
        active: selectedTab === key,
        onClick: () => setSelectedTab(key as TableTabsEnums),
      };
    });

  const onRedTagChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      redTag: (search as OrderFilterValues).redTag ? null : true,
      page: 1,
    });
  };

  const onCollaboratingOnChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      collaboratingOn: !(search as OrderFilterValues).collaboratingOn,
      page: 1,
    });
  };

  const onShowCancelledOrdersChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      showCancelledOrders: !(search as OrderFilterValues).showCancelledOrders,
      page: 1,
    });
  };

  const onShowNewCollaborationChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      showNewCollaboration: !(search as OrderFilterValues).showNewCollaboration,
      page: 1,
    });
  };

  return (
    <Wrapper position="relative">
      <CheckboxWrapper flex middle>
        {(search as OrderFilterValues).tab === CsrTabsEnum['Not Submitted'] &&
          !isQuoteBoard && (
            <Checkbox
              title="Hide Cancelled orders"
              id="showCancelledOrders"
              checked={
                (search as OrderFilterValues).showCancelledOrders ?? false
              }
              onChange={onShowCancelledOrdersChangeHandler}
            />
          )}

        <>
          <Spacer w="32px" />

          <Checkbox
            title="New collaboration"
            id="showNewCollaboration"
            checked={
              (search as OrderFilterValues).showNewCollaboration ?? false
            }
            onChange={onShowNewCollaborationChangeHandler}
          />
        </>

        <Spacer w="32px" />

        <Checkbox
          title="Collaborating on"
          id="collaboratingOn"
          checked={(search as OrderFilterValues).collaboratingOn ?? false}
          onChange={onCollaboratingOnChangeHandler}
        />

        <Spacer w="32px" />

        <Checkbox
          title="Red Tags only"
          id="red-tags"
          checked={(search as OrderFilterValues).redTag ?? false}
          onChange={onRedTagChangeHandler}
        />
      </CheckboxWrapper>

      <StyledTabs tabs={tabs} nounderline={1} />

      <OrdersTable
        tab={selectedTab}
        isLoading={isLoading}
        setSearch={setSearch}
        search={search}
        isQuoteBoard={isQuoteBoard}
      />
    </Wrapper>
  );
};
