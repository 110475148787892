import { useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormElement } from 'shared/components/FormElement';
import { H2, H4 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';

import {
  IDoorCode,
  IDoorCodeField,
  IDoorCodeWithoutHardware,
} from 'order/wizard/orderStyles/interface/DoorCodes';

import { IConfigureLineItemForm } from '../interface/IConfigureLineItem';
import { LineItemHardware } from '../interface/ILineItemData';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import { StyledLabel } from './ConfigureLineItem';
import DoorCodeFields from './DoorCodeFields';

const DoorCodesContainer = styled.div`
  position: relative;
`;

interface DoorCodesProps {
  loading: boolean;
  replaceModalOpened: boolean;
}

const DoorCodes: FC<DoorCodesProps> = ({ loading }) => {
  const doorCodesColumnMinWidth = 135;

  const initialDoorCodeSetup = {
    wall: [
      { id: '0', code: 'Wall 1', quantity: 0, populated: false },
      { id: '1', code: 'Wall 2', quantity: 0, populated: false },
      { id: '2', code: 'Wall 3', quantity: 0, populated: false },
      { id: '3', code: 'Wall 4', quantity: 0, populated: false },
      { id: '4', code: 'Wall 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
    base: [
      { id: '0', code: 'Base 1', quantity: 0, populated: false },
      { id: '1', code: 'Base 2', quantity: 0, populated: false },
      { id: '2', code: 'Base 3', quantity: 0, populated: false },
      { id: '3', code: 'Base 4', quantity: 0, populated: false },
      { id: '4', code: 'Base 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
    drawer: [
      { id: '0', code: 'Drawer 1', quantity: 0, populated: false },
      { id: '1', code: 'Drawer 2', quantity: 0, populated: false },
      { id: '2', code: 'Drawer 3', quantity: 0, populated: false },
      { id: '3', code: 'Drawer 4', quantity: 0, populated: false },
      { id: '4', code: 'Drawer 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
  } as IDoorCodeWithoutHardware;

  const defaultDoorCodes = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemDoorCodes
  );

  const orderLineItemData = useSelector(
    (state: RootState) => state.orderLineItemsReducer.orderLineItemData
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [doorCodeValues, setDoorCodeValues] =
    useState<IDoorCodeWithoutHardware>(initialDoorCodeSetup);

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'lineItemId',
    'replacementLineItemId',
  ]);

  const [editMode, setEditMode] = useState(queryFields?.lineItemId ?? false);

  const { register, setValue, formState } =
    useFormContext<IConfigureLineItemForm>();

  // const doorCodesWatched = watch('doorCodes');

  const populateDoorCodes = (doorCodes: IDoorCode) => {
    const updatedDoorCodes = {
      ...initialDoorCodeSetup,
    } as IDoorCodeWithoutHardware;

    Object.keys(doorCodes).forEach((key) => {
      if (key !== 'hardware' && key !== 'hingeRequired') {
        const k = key as keyof IDoorCodeWithoutHardware;
        const value = doorCodes[k];

        value.forEach((el, index) => {
          updatedDoorCodes[k][index] = {
            ...updatedDoorCodes[k][index],
            ...el,
            quantity: el.quantity ?? 0,
            populated: true,
          };

          const quantityField = `${k}${
            k !== 'drawer' ? 'Door' : ''
          }Quantities.${index}.quantity` as unknown as keyof IConfigureLineItemForm;

          const populatedField = `${k}${
            k !== 'drawer' ? 'Door' : ''
          }Quantities.${index}.populated` as unknown as keyof IConfigureLineItemForm;

          setValue(quantityField, el.quantity ?? '0');
          setValue(populatedField, true);
        });

        setDoorCodeValues(updatedDoorCodes);
      }

      if (key === 'hardware') {
        Object.keys(doorCodes.hardware).forEach((hKey) => {
          setValue(
            hKey as keyof IConfigureLineItemForm,
            doorCodes.hardware[hKey as keyof LineItemHardware]
          );
        });
      }
    });
  };

  useEffect(() => {
    if (queryFields) {
      setEditMode(!!queryFields.lineItemId);
    }
  }, [queryFields]);

  useEffect(() => {
    if (
      !formState.isDirty &&
      orderLineItemData &&
      !queryFields?.replacementLineItemId
    ) {
      const savedDoorCodes: IDoorCode = {
        base: orderLineItemData.baseDoorCodes.map((x) => ({
          ...x,
          populated: true,
        })),
        wall: orderLineItemData.wallDoorCodes.map((x) => ({
          ...x,
          populated: true,
        })),
        drawer: orderLineItemData.drawerDoorCodes.map((x) => ({
          ...x,
          populated: true,
        })),
        hardware: {
          doorHardwareCount: orderLineItemData.doorHardwareNumber,
          drawerHardwareCount: orderLineItemData.drawerHardwareNumber,
          hingeCount: orderLineItemData.hingeNumber,
        },
      };
      populateDoorCodes(savedDoorCodes);
    }
  }, [orderLineItemData]);

  useEffect(() => {
    if (
      (formState.isDirty || !editMode || queryFields?.replacementLineItemId) &&
      defaultDoorCodes
    ) {
      populateDoorCodes(defaultDoorCodes);
    }
  }, [defaultDoorCodes]);

  return (
    <DoorCodesContainer>
      <H2>Doors / Drawers / Hardware</H2>

      <Spacer h="45px" />

      <Wrapper flex>
        <Wrapper flex column minWidth={doorCodesColumnMinWidth}>
          <H4>Wall Doors</H4>
          <DoorCodeFields prefix="wallDoor" codes={doorCodeValues.wall} />
        </Wrapper>

        <Spacer w="16px" />

        <Wrapper flex column minWidth={doorCodesColumnMinWidth}>
          <H4>Base Doors</H4>
          <DoorCodeFields prefix="baseDoor" codes={doorCodeValues.base} />
        </Wrapper>

        <Spacer w="16px" />

        <Wrapper flex column minWidth={doorCodesColumnMinWidth}>
          <H4>Drawers</H4>
          <DoorCodeFields prefix="drawer" codes={doorCodeValues.drawer} />
        </Wrapper>

        <Spacer w="16px" />

        <Wrapper flex column>
          <H4>Hardware</H4>
          <FormElement>
            <StyledLabel># Door HW</StyledLabel>
            <Spacer h="6px" />
            <Input {...register('doorHardwareCount')} disabled={!canEdit} />
            <Spacer h="20px" />
          </FormElement>
          <FormElement>
            <StyledLabel># Drawer HW</StyledLabel>
            <Spacer h="6px" />
            <Input {...register('drawerHardwareCount')} disabled={!canEdit} />
            <Spacer h="20px" />
          </FormElement>
          <FormElement>
            <StyledLabel># Hinges</StyledLabel>
            <Spacer h="6px" />
            <Input {...register('hingeCount')} disabled={!canEdit} />
            <Spacer h="20px" />
          </FormElement>
        </Wrapper>
      </Wrapper>

      {loading && (
        <LoaderFullScreen
          flex
          middle
          center
          top={-5}
          bottom={15}
          left={-5}
          borderRadius={5}
        >
          <Loader />
        </LoaderFullScreen>
      )}
    </DoorCodesContainer>
  );
};

export default DoorCodes;
