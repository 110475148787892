import { RootState } from 'store';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IWoodEffectField } from 'order/wizard/orderStyles/interface/WoodEffectField';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';

import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { setWoodEffectOptions2 } from '../../../store/specifications/orderStylesSpecificationActions';
import WoodEffectFieldNew from './WoodEffectFieldNew';
import { useScrollToElement } from '../../../../../../../shared/hooks/useScrollToElement';

const WoodEffectNewContainer = styled.div``;

const TabbableButtonStyled = styled(TabbableButton)`
  color: ${({ theme }) => theme.lynch};

  &:hover {
    color: ${({ theme }) => theme.chambray};
  }
`;

export const generateNewWoodEffectField = (id: string) => {
  return {
    id,
    orderNumber: +id,
    selectedOption: null,
    upcharge: '',
  } as IWoodEffectField;
};

const WoodEffectNew = () => {
  const dispatch = useAppDispatch();

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isICSProductLine = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine?.name ===
      ProductLineEnums.PRODUCT_LINE_ICS
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const storedWoodEffects = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications?.woodEffects
  );

  const { control, watch, setValue, formState, reset, getValues } =
    useFormContext<StyleSpecifications>();

  const woodOrMaterialWatched = watch('woodOrMaterial');
  const specificationOptionWatched = watch('specificationOption');

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'woodEffects', // unique name for your Field Array
  });

  const resetAllWoodEffects = () => {
    setValue('woodEffects', [generateNewWoodEffectField('1')]);
    dispatch(setWoodEffectOptions2(null));
  };

  // dealer has no ability to add new wood effect (only csr)
  const isAddingWoodEffectDisabled = () => {
    return (isICSProductLine && isUserDealerOrSalesRep) ?? false;
  };

  useEffect(() => {
    if (formState.dirtyFields.woodOrMaterial) {
      resetAllWoodEffects();
    }
  }, [woodOrMaterialWatched]);

  useEffect(() => {
    if (
      storedWoodEffects &&
      storedWoodEffects.length > 0 &&
      !formState.isDirty &&
      !fields.some((field) =>
        storedWoodEffects.some(
          (storedWoodEffect) => storedWoodEffect.id === field.id
        )
      )
    ) {
      append(storedWoodEffects);
    }
  }, [storedWoodEffects]);

  useEffect(() => {
    if (
      (storedStyle?.step ?? 0) === +StylesStepsEnum.SPECIFICATIONS &&
      (fields?.length ?? 0) === 0 &&
      !formState.isDirty
    ) {
      reset({
        ...getValues(),
        woodEffects: [generateNewWoodEffectField('1')],
      });
    }
  }, [storedStyle?.step, fields]);

  useEffect(() => {
    if (
      formState.isDirty &&
      fields?.length === 0 &&
      specificationOptionWatched === SpecificationsOptionsEnum.CONFIGURE_WOOD
    ) {
      append(generateNewWoodEffectField('1'));
    }
  }, [specificationOptionWatched]);

  const woodEffectsRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: formState.errors,
    error: formState.errors.woodEffects,
    ref: woodEffectsRef,
    fieldName: 'woodEffects',
  });

  return (
    <>
      <WoodEffectNewContainer ref={woodEffectsRef}>
        {fields?.map((woodEffect) => (
          <WoodEffectFieldNew
            key={woodEffect.id}
            index={+woodEffect.id - 1}
            remove={remove}
            readOnly={isAddingWoodEffectDisabled()}
            control={control}
          />
        ))}

        {!isAddingWoodEffectDisabled() && (
          <TabbableButtonStyled
            disabled={fields.length >= 2 || !canEdit}
            onClick={() => append(generateNewWoodEffectField('2'))}
            type="button"
          >
            + Add another wood effect
          </TabbableButtonStyled>
        )}
      </WoodEffectNewContainer>

      <Spacer h="30px" />
    </>
  );
};

export default WoodEffectNew;
