import { RootState } from 'store';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import UtilService from 'shared/services/util.service';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import { digitWithSingleDotValidation } from 'shared/validations/validations';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

const RevolaeSQFT = () => {
  const config = useProductLineConfiguration();
  const methodsContext = useFormContext<StyleSpecifications>();
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const materialColorWatched = methodsContext.watch('materialColor');

  useEffect(() => {
    if (
      materialColorWatched &&
      methodsContext.formState.dirtyFields.materialColor &&
      config.styleSpecifications.showSQFT
    ) {
      UtilService.withDecimal<StyleSpecifications>(
        'doorSquareFootPrice',
        (materialColorWatched.doorSquareFootPrice || '0').toString(),
        methodsContext.setValue
      );

      UtilService.withDecimal<StyleSpecifications>(
        'miDoorSquareFootPrice',
        (materialColorWatched.miDoorSquareFootPrice || '0').toString(),
        methodsContext.setValue
      );
    }
  }, [materialColorWatched]);

  return config.styleSpecifications.showSQFT ? (
    <>
      <FormElement maxWidth={113} noMarginBottom>
        <FormLabel>SQFT $</FormLabel>
        <Input
          {...methodsContext.register(
            'doorSquareFootPrice',
            digitWithSingleDotValidation(undefined, 'SQFT $')
          )}
          onBlur={(e) =>
            UtilService.withDecimal<StyleSpecifications>(
              'doorSquareFootPrice',
              e.target.value,
              methodsContext.setValue
            )
          }
          type="text"
          readOnly={!materialColorWatched || !canEdit}
          placeholder="0.00"
          data-test="input-doorSquareFootPrice"
          aria-invalid={
            methodsContext.formState.errors.doorSquareFootPrice
              ? 'true'
              : 'false'
          }
        />
      </FormElement>

      <Spacer w="10px" />

      <FormElement maxWidth={113} noMarginBottom>
        <FormLabel>
          Matching Interior
          <br />
          SQFT $
        </FormLabel>

        <Input
          {...methodsContext.register(
            'miDoorSquareFootPrice',
            digitWithSingleDotValidation(undefined, 'Matching Interior SQFT $')
          )}
          onBlur={(e) =>
            UtilService.withDecimal<StyleSpecifications>(
              'miDoorSquareFootPrice',
              e.target.value,
              methodsContext.setValue
            )
          }
          type="text"
          readOnly={!materialColorWatched || !canEdit}
          placeholder="0.00"
          data-test="input-miDoorSquareFootPrice"
          aria-invalid={
            methodsContext.formState.errors.miDoorSquareFootPrice
              ? 'true'
              : 'false'
          }
        />
      </FormElement>
    </>
  ) : null;
};

export default RevolaeSQFT;
