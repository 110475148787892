import styled from 'styled-components';

import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';

export const Tabs = styled(ReactTabs)``;

export const TabList = styled(ReactTabList)`
  border-bottom: 1px solid ${({ theme }) => theme.mischka};
  padding: 13px 30px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
`;

export const Tab = styled(ReactTab)`
  color: ${({ theme }) => theme.nevada};
  font-size: 14px;
  font-weight: normal;
  margin-right: 43px;
  padding: 5px 0;
  position: relative;
  transition: 300ms ease;
  cursor: pointer;

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.kashmirBlue};
    border-radius: 3px;
    bottom: -12px;
    height: 2px;
    left: -12px;
    opacity: 0;
    border-radius: 3px;
    position: absolute;
    right: -12px;
    transform: scaleX(0);
    transition: 300ms ease;
  }

  &.react-tabs__tab--selected {
    color: ${({ theme }) => theme.kashmirBlue};

    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    .visible {
      font-weight: 600;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const TabPanel = styled(ReactTabPanel)``;
