import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';
import { useEffect, useState } from 'react';
import UtilService from 'shared/services/util.service';

interface CanSubmitOptions {
  isFormDity: boolean;
  editMode: boolean;
  isStyleComplete?: boolean;
  styleStep?: number;
  step: StylesStepsEnum;
}

export const useCanSubmit = ({
  isFormDity,
  isStyleComplete,
  editMode,
  styleStep,
  step,
}: CanSubmitOptions) => {
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (isFormDity || isStyleComplete || editMode || styleStep || step) {
      setCanSubmit(
        isFormDity ||
          (!isStyleComplete &&
            editMode &&
            UtilService.styleOnStep({
              styleStep,
              step,
            }))
      );
    }
  }, [isFormDity, editMode, styleStep, step]);

  return canSubmit;
};
