import { TdHTMLAttributes, ThHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import CarretDownIcon from 'assets/icons/carret-down.svg';
import CarretUpIcon from 'assets/icons/carret-up.svg';
import { SortEnums } from 'shared/enum/sortEnums';

import { Wrapper } from './Wrapper';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
`;

export const ScrollTable = styled(Table)`
  scroll-behavior: auto;
  bottom: 20px !important;

  > thead {
    background-color: ${({ theme }) => theme.white};
    position: sticky;
    top: 12px;
    z-index: 5;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -12px;
      height: 12px;
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

interface TRProps {
  firstRow?: boolean;
  disabled?: boolean;
  noHover?: boolean;
}

export const Tr = styled.tr<TRProps>`
  &[disabled] {
    opacity: 0.5;
  }

  ${Tbody} & {
    border-top: 1px solid ${({ theme }) => theme.mischka};

    &:not([disabled]):hover {
      ${({ theme, noHover }) =>
        !noHover && `background-color: ${theme.wildSand}`};
    }
  }
`;

const sortIcons = css`
  position: relative;
  cursor: pointer;
  transition: background-color 300ms ease;

  &:hover {
    &::before,
    &::after {
      opacity: 0.75;
    }
  }

  &:first-child {
    padding-left: 65px;

    &::before,
    &::after {
      left: 45px;
    }
  }

  &:not(:first-child) {
    padding-left: 25px;
  }

  &::before,
  &::after {
    content: '';
    width: 10px;
    pointer-events: none;
    height: 10px;
    position: absolute;
    left: 5px;
    background-size: contain;
    transition: opacity 300ms ease;
    opacity: 0.5;
  }

  // desc
  &::before {
    background-image: url(${CarretDownIcon});
    bottom: calc(50% - 9px);
  }

  // asc
  &::after {
    background-image: url(${CarretUpIcon});
    top: calc(50% - 9px);
  }

  &[data-direction=${SortEnums.ASC}][data-active='true'] {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }

  &[data-direction=${SortEnums.DESC}][data-active='true'] {
    &::after {
      opacity: 0;
    }

    &::before {
      opacity: 1;
    }
  }
`;

interface ThProps extends ThHTMLAttributes<HTMLTableCellElement> {
  width?: number | string;
  fontSize?: number;
}

export const Th = styled.th<ThProps>`
  color: ${({ theme }) => theme.mineShaftVariation};
  font-size: ${(p) => p.fontSize ?? 14}px;
  letter-spacing: 0.08em;
  line-height: 17px;
  padding: 10px;
  text-transform: uppercase;

  ${({ align }) => (align ? `text-align: ${align}` : 'text-align: left')};
  ${({ width }) =>
    width &&
    (typeof width === 'string' ? `width: ${width}` : `width: ${width}px`)};

  &[data-direction] {
    ${sortIcons}
  }

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    /* padding-right: 40px; */
  }
`;

interface TdProps
  extends Omit<TdHTMLAttributes<HTMLTableRowElement>, 'align' | 'valign'> {
  bold?: boolean;
  width?: number;
  fontSize?: number;
  align?: Pick<TdHTMLAttributes<HTMLTableRowElement>, 'align'>;
  valign?: Pick<TdHTMLAttributes<HTMLTableRowElement>, 'valign'>;
}

export const Td = styled.td<TdProps>`
  color: ${({ theme }) => theme.mineShaft};
  font-size: ${(p) => p.fontSize ?? 16}px;
  letter-spacing: 0.01em;
  line-height: 20px;
  padding: 12px 8px;
  font-weight: ${(props) => (props.bold ? '600' : '400')};

  ${({ width }) => width && `width: ${width}px`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ valign }) => valign && `vertical-align: ${valign}`};

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    /* padding-right: 40px; */
  }
`;

export const CustomTD = styled(Wrapper)`
  > svg:hover {
    cursor: pointer;
  }
`;
