import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import UserInfo from 'shared/components/UserInfo';
import { Collaborator } from 'order/interfaces/Collaborator';
import { useContext, useEffect } from 'react';
import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import {
  removeDealerCollaboratos,
  selectCollaborator,
  updateSelectedCollaborator,
} from 'order/store/orderActions';

import { useParams } from 'react-router';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';

const SelectedCollaboratorsContainer = styled.div`
  > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const OrderSelectedCollaborators = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const {
    isCSRSelectedDealershipDirty,
    csrSelectedDealershipUserId,
    isCSRSelectedDealershipUserDirty,
    // removedMyselfAsCollaborator,
  } = useContext(OrderFormContext);

  const selectedCollaborators = useSelector(
    (state: RootState) => state.orderReducer.selectedCollaborators
  );

  const dealershipUsers = useSelector(
    (state: RootState) => state.orderReducer.dealershipUsers
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const orderOwnerId = useSelector(
    (state: RootState) => state.orderReducer.order?.ownerId
  );

  useEffect(() => {
    const isOrderOwnerInSelectedCollaborators = selectedCollaborators?.some(
      (selectedCollaborator) =>
        selectedCollaborator.collaboratorType === collaboratorAccessEnums.OWNER
    );

    // CSR Selected User Does Not Exists In Collaborators As Owners
    const csrSelectedUserInCollaborators = selectedCollaborators?.some(
      (selectedCollaborator) =>
        selectedCollaborator.id === csrSelectedDealershipUserId &&
        selectedCollaborator.collaboratorType === collaboratorAccessEnums.OWNER
    );

    const dealershipUser = dealershipUsers?.find(
      (u) => u.value === csrSelectedDealershipUserId
    );

    if (
      editMode &&
      orderOwnerId &&
      isCSRSelectedDealershipUserDirty &&
      !csrSelectedUserInCollaborators &&
      dealershipUser
    ) {
      dispatch(
        (isOrderOwnerInSelectedCollaborators
          ? updateSelectedCollaborator
          : selectCollaborator)({
          ...dealershipUser.user!,
          collaboratorType: collaboratorAccessEnums.OWNER,
        })
      );
    }
  }, [csrSelectedDealershipUserId]);

  useEffect(() => {
    if (isCSRSelectedDealershipDirty && csrSelectedDealershipUserId == null) {
      dispatch(removeDealerCollaboratos());
    }
  }, [csrSelectedDealershipUserId]);

  return (
    <SelectedCollaboratorsContainer>
      {selectedCollaborators?.map((selectedCollaborator) => (
        <UserInfo
          key={selectedCollaborator.id}
          user={selectedCollaborator as Collaborator}
          disabled={!canEdit}
        />
      ))}

      {/* {removedMyselfAsCollaborator ? 'removed' : 'not'} */}
    </SelectedCollaboratorsContainer>
  );
};

export default OrderSelectedCollaborators;
