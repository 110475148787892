import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { ButtonIcon } from 'shared/components/Button';
import { CSR } from 'shared/interface/CSR';
import { mineShaft, nevada, silverChaliceTwo } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { ToggleCheckbox } from 'shared/components/ToggleCheckbox';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';

import { setCSRasPrimary } from '../../store/orderAssigneesActions';

interface AssigneeProps {
  user: CSR;
  disabled?: boolean;
  onSelect?: (user: CSR) => void;
  onUnselect?: (user: CSR) => void;
  addingOrRemovingInProgress: boolean;
}

interface AssigneeContainerProps extends Pick<AssigneeProps, 'disabled'> {
  pointer: boolean;
}

const AssigneeContainer = styled(Wrapper)<AssigneeContainerProps>`
  ${({ pointer }) => pointer && 'cursor: pointer'};
  ${({ disabled }) => disabled && `pointer-events: none; opacity: 0.5;`}

  margin-bottom: 10px;
  transition: opacity 300ms ease;
`;

const Assignee: FC<AssigneeProps> = ({
  user,
  onSelect,
  onUnselect,
  disabled,
  addingOrRemovingInProgress,
}) => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);

  const [primary, setPrimary] = useState(user.primary ?? false);

  const onSetCSRasPrimarySuccessHandler = () => {
    toast.success(
      `You have successfully ${!primary ? 'set' : 'unset'} ${user.firstName} ${
        user.lastName
      } as primary CSR on this order.`
    );
  };

  useEffect(() => {
    setPrimary(user.primary ?? false);
  }, [user.primary]);

  return (
    <AssigneeContainer
      flex
      middle
      pointer={!!onSelect}
      disabled={disabled}
      onClick={() => onSelect && onSelect(user)}
    >
      <AvatarCircle user={user} size={32} />

      <Spacer w="10px" />

      <Wrapper maxWidth={235}>
        <P fontSize={14} color={nevada}>
          {user.firstName} {user.lastName}
        </P>
      </Wrapper>

      {!onUnselect && addingOrRemovingInProgress && (
        <>
          <Spacer w="10px" />
          <Loader noSpacing size={16} />
        </>
      )}

      {onUnselect && (
        <Wrapper mlAuto flex middle>
          {loading ? (
            <Wrapper minWidth={40}>
              <Loader noSpacing size={18} />
            </Wrapper>
          ) : (
            <Wrapper withTooltip>
              <ToggleCheckbox
                onChange={(e) => {
                  setLoading(true);
                  dispatch(
                    setCSRasPrimary(
                      {
                        userId: user.id,
                        orderId,
                        primary: e.target.checked,
                      },
                      onSetCSRasPrimarySuccessHandler,
                      setLoading
                    )
                  );
                }}
                checked={primary}
                id={user.id}
              />

              <Tooltip position="bottom">
                {!primary && 'Set as'} CSR/Primary
              </Tooltip>
            </Wrapper>
          )}

          <Spacer w="35px" />

          {addingOrRemovingInProgress ? (
            <Loader noSpacing size={20} />
          ) : (
            <ButtonIcon
              onClick={() => onUnselect(user)}
              iconColor={silverChaliceTwo}
              hoverIconColor={mineShaft}
            >
              <SVG icon={<CloseIcon />} />
            </ButtonIcon>
          )}
        </Wrapper>
      )}
    </AssigneeContainer>
  );
};

export default Assignee;
