import { RootState } from 'store';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  OrderStylizationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import { FormElement } from 'shared/components/FormElement';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { RadioButton } from 'shared/components/RadioButton';
import { Spacer } from 'shared/components/Layout';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';
import { Wrapper } from 'shared/components/Wrapper';

import { OrderTestRadioButtonEnum } from 'tests/enums/OrderTestEnums';
import { testId } from 'tests/utils';

import { OrderFormFields } from '../OrderForm';
import { csrOnlySalesAidsOptions } from './OrderTypeOptionSets';

const OrderStylizationTypeField = () => {
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  // asap or duplicate
  const [isAsap] = useOrderFlow('asap');
  const [isDuplicate] = useOrderFlow('duplicate');

  const asapOrDuplicate = isAsap || isDuplicate;

  // edit mode
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const methodsContext = useFormContext<OrderFormFields>();

  const orderTypeWatched = methodsContext.watch('type');

  const shouldShowStylizationType = () => {
    return (
      +orderTypeWatched === OrderTypeEnums['Sales Aids'] ||
      +orderTypeWatched === OrderTypeEnums.Net ||
      +orderTypeWatched === OrderTypeEnums['CM Net'] ||
      +orderTypeWatched === OrderTypeEnums['Misc. Net']
    );
  };

  return shouldShowStylizationType() ? (
    <FormElement>
      {!editMode && +orderTypeWatched === OrderTypeEnums.Preorder && (
        <>
          <P color={nevada} fontSize={14}>
            Choose some option other than &quot;None&quot; if you wanna create
            order as DS/DSB or Sales Material, otherwise it will be created as
            Standard type of order.
          </P>
          <Spacer h="25px" />
        </>
      )}

      <Wrapper flex middle>
        <RadioButton
          {...methodsContext.register('stylizationType', { required: true })}
          id={`stylizationType--${OrderStylizationTypeEnums.DOOR_SAMPLE_BASE}`}
          data-test={`stylizationType--${OrderStylizationTypeEnums.DOOR_SAMPLE_BASE}`}
          title="Door Sample/Door Sample Base"
          value={OrderStylizationTypeEnums.DOOR_SAMPLE_BASE}
          readOnly={
            !canEdit ||
            asapOrDuplicate ||
            csrOnlySalesAidsOptions
              .filter((x) => x !== OrderTypeEnums.Net)
              .includes(+orderTypeWatched)
          }
          {...testId(OrderTestRadioButtonEnum.DOOR_SAMPLE_BASE_BTN)}
        />

        <Spacer w="20px" />

        <RadioButton
          {...methodsContext.register('stylizationType', { required: true })}
          id={`stylizationType--${OrderStylizationTypeEnums.SALES_MATERIAL}`}
          data-test={`stylizationType--${OrderStylizationTypeEnums.SALES_MATERIAL}`}
          title="Sales Material (includes Aluminum Door Samples)"
          value={OrderStylizationTypeEnums.SALES_MATERIAL}
          readOnly={
            !canEdit ||
            asapOrDuplicate ||
            +orderTypeWatched === OrderTypeEnums.Net
          }
          {...testId(OrderTestRadioButtonEnum.SALES_MATERIAL_BTN)}
        />
      </Wrapper>
      <Spacer h="10px" />
    </FormElement>
  ) : null;
};

export default OrderStylizationTypeField;
