import {
  ChangeEvent,
  forwardRef,
  HtmlHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import { lynch, silverChaliceTwo } from 'shared/config/Colors';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ButtonIcon } from './Button';
import { Input } from './Input';
import Loader from './Loader';
import SVG from './SVG';

interface SearchInputContainerProps {
  width?: number;
}

const SearchInputContainer = styled.div<SearchInputContainerProps>`
  position: relative;

  ${({ width }) => width && `width: ${width}px;`}

  .loader {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .clearBtn {
    opacity: 0;
    pointer-events: none;
    transition: 0ms ease;
  }
`;

const StyledInput = styled(Input)`
  position: relative;

  &[data-loading='false'] {
    ~ .loader {
      opacity: 0;
    }
  }

  &[data-loading='true'] {
    ~ .loader {
      opacity: 1;
    }
  }

  &[data-empty='false'] {
    &[data-loading='false'] {
      ~ .clearBtn {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
`;

const StyledInputClearButton = styled(ButtonIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

interface SearchInputProps extends HtmlHTMLAttributes<HTMLInputElement> {
  onSearchQueryChanged: (e: ChangeEvent<HTMLInputElement>) => void;
  width?: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onSearchQueryChanged, width, disabled, autoFocus, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onClearSearchInputHandler = () => {
      if (inputRef?.current) {
        const input = inputRef.current;

        const object = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        );

        if (object?.set) {
          object.set.call(input, '');
          input.dispatchEvent(new Event('change', { bubbles: true }));
        }
      }
    };

    useEffect(() => {
      if (inputRef?.current && autoFocus) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }, [autoFocus]);

    return (
      <SearchInputContainer width={width}>
        <StyledInput
          ref={ref ?? inputRef}
          data-empty={inputRef.current?.value.length ? 'false' : 'true'}
          {...rest}
          onChange={onSearchQueryChanged}
          disabled={disabled}
        />

        <Loader size={20} noSpacing />

        <StyledInputClearButton
          className="clearBtn"
          tabIndex={-1}
          onClick={onClearSearchInputHandler}
        >
          <SVG
            icon={<CloseIcon />}
            color={silverChaliceTwo}
            hoverColor={lynch}
          />
        </StyledInputClearButton>
      </SearchInputContainer>
    );
  }
);

export default SearchInput;
