import styled from 'styled-components';
import { FC } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { ILineItemCategory } from '../interface/IlineItemCategory';
import { setSubcategory } from '../store/orderLineItemsActions';

const LineItemSubcategoryContainer = styled.div`
  margin-bottom: 10px;
`;

interface LineItemSubcategoryProps {
  subcategory: ILineItemCategory;
}

interface CategoryNameProps {
  active: boolean;
}

const CategoryName = styled.div<CategoryNameProps>`
  font-size: 14px;
  color: ${({ theme, active }) => (active ? theme.lynch : theme.nevada)};
  cursor: pointer;
  transition: color 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.lynch};
  }
`;

const LineItemSubcategory: FC<LineItemSubcategoryProps> = ({ subcategory }) => {
  const dispatch = useAppDispatch();

  const selectedSubcategory = useSelector(
    (state: RootState) => state.orderLineItemsReducer.selectedSubcategory
  );

  const onSelectSubcategoryClickHandler = () => {
    if (selectedSubcategory?.id === subcategory.id) {
      dispatch(setSubcategory(null));
    } else {
      dispatch(setSubcategory(subcategory));
    }
  };

  return (
    <LineItemSubcategoryContainer>
      <CategoryName
        active={selectedSubcategory?.id === subcategory.id}
        onClick={onSelectSubcategoryClickHandler}
      >
        {subcategory.name}
      </CategoryName>
    </LineItemSubcategoryContainer>
  );
};

export default LineItemSubcategory;
