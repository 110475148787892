import styled from 'styled-components';
import { ButtonIcon } from './Button';

export const ResolveButton = styled(ButtonIcon)`
  position: absolute;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 23px;
  border: 2px solid ${({ theme }) => theme.silverSand};
  border-radius: 50%;

  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &.resolved-topic {
    background-color: ${({ theme }) => theme.fern};

    path {
      fill: ${({ theme }) => theme.white};
    }
  }
`;
