import { AdditionalFieldType } from 'order/additonalFields/enums/AdditionalFieldsEnums';
import { useEffect, useMemo, useState } from 'react';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Spacer } from 'shared/components/Layout';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { P, SPAN } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Select } from 'shared/components/Select';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { Input } from 'shared/components/Input';
import { AdditionalField } from 'order/additonalFields/interfaces/AdditionalField';
import UtilService from 'shared/services/util.service';
import styled from 'styled-components';

export const StyledFormError = styled.div`
  color: ${({ theme, color }) => color ?? theme.valencia};
  font-size: 0.8rem;
  margin-top: 5px;
`;
export interface FormAnswer {
  id: string;
  answer: string;
  file: File | null;
}
export interface UseFormModalProps {
  opened: boolean;
  onClose: () => void;
  additionalFields: AdditionalField[];
  submitForm: (answers: FormAnswer[]) => void;
}

const useFormModal = ({
  opened,
  onClose,
  additionalFields,
  submitForm,
}: UseFormModalProps) => {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  const maxSize = 40;

  const [answers, setAnswers] = useState<FormAnswer[]>([]);

  const onSubmit = () => {
    const mappedAnswers = additionalFields.map((af) => {
      const formAnswer = answers.find((a) => a.id === af.id);
      let text = formAnswer?.answer ?? af.defaultAnswer ?? '';
      if (af.type === AdditionalFieldType.Variable) {
        text = af.includePrompt ? `${af.name.trim()} ${text.trim()}` : text;
      }
      return {
        id: af.id,
        answer: text,
        file: formAnswer?.file,
      } as FormAnswer;
    });

    submitForm(mappedAnswers);
  };

  const getError = (field: AdditionalField) => {
    const formAnswer = answers.find((a) => a.id === field.id);
    let text = formAnswer?.answer ?? '';

    if (!text && field.isRequired) {
      return 'Required field.';
    }

    if (field.type === AdditionalFieldType.Variable) {
      text = field.includePrompt ? `${field.name.trim()} ${text.trim()}` : text;
    }

    return text.length > maxSize
      ? 'Answer should be at most 40 characters long.'
      : '';
  };

  const renderFormElements = (field: AdditionalField, index: number) => {
    const handleChange = (text: string) => {
      const fieldAnswer = answers.find((x) => x.id === field.id);

      if (!fieldAnswer) {
        setAnswers([
          ...answers,
          {
            id: field.id,
            answer: text,
            file: null,
          },
        ]);
      } else {
        const changedAnswers = answers.map((an) => {
          if (an.id === field.id) {
            return {
              ...an,
              answer: text,
            };
          }
          return an;
        });

        setAnswers(changedAnswers);
      }
    };

    const selectOptions = field.options
      ? field.options.split(';').map((op) => {
          return {
            value: uuidv4(),
            label: op.trim(),
          } as SelectOptionProps;
        })
      : [];

    return (
      <>
        <Wrapper flex middle>
          <P>
            {parse(field.name.replace(regex, '<br>'))}
            {field.isRequired && '*'}
          </P>
        </Wrapper>
        {field.hintAnswer && (
          <>
            <Spacer h="5px" />
            <SPAN bold>{`Hint: ${field.hintAnswer}`}</SPAN>
          </>
        )}
        <Spacer h="20px" />
        {field.type === AdditionalFieldType.Variable && (
          <Form>
            {selectOptions.length > 0 ? (
              <FormElement flexGrow>
                <Select
                  options={selectOptions}
                  placeholder="Select answer"
                  onChange={(val: SelectOptionProps) => handleChange(val.label)}
                  aria-invalid={getError(field) ? 'true' : 'false'}
                  name={`answer_${index + 1}`}
                  defaultValue={
                    field.defaultAnswer
                      ? selectOptions.find(
                          (op) => op.label === field.defaultAnswer
                        )
                      : null
                  }
                />
                {getError(field) && (
                  <StyledFormError>{getError(field)}</StyledFormError>
                )}
              </FormElement>
            ) : (
              <FormElement flexGrow>
                <Input
                  onChange={(e) => handleChange(e.target.value)}
                  placeholder="Answer"
                  defaultValue={field.defaultAnswer ?? undefined}
                  aria-invalid={getError(field) ? 'true' : 'false'}
                  name={`answer_${index + 1}`}
                />
                {getError(field) && (
                  <StyledFormError>{getError(field)}</StyledFormError>
                )}
              </FormElement>
            )}
          </Form>
        )}
      </>
    );
  };

  const FormModal = useMemo(() => {
    const hasRequiredFields = additionalFields.some((af) => af.isRequired);
    const hasInvalidFields = additionalFields.some((af) => getError(af));

    return (
      <ModalWrapper
        modal
        lockScroll
        closeOnDocumentClick={false}
        open={opened}
        closeOnEscape={false}
      >
        {() => (
          <Modal title="Additional Info" confirm withoutFooter={0}>
            {additionalFields
              .sort((a, b) => UtilService.sortBy(a.orderNumber, b.orderNumber))
              .map((af, index) => renderFormElements(af, index))}
            <Wrapper flex middle justifyEnd>
              {!hasRequiredFields && (
                <ButtonSecondary onClick={onClose} type="button">
                  Close
                </ButtonSecondary>
              )}
              <Spacer w="16px" />
              <ButtonPrimary onClick={onSubmit} disabled={hasInvalidFields}>
                Continue
              </ButtonPrimary>
            </Wrapper>
          </Modal>
        )}
      </ModalWrapper>
    );
  }, [opened, answers]);

  useEffect(() => {
    if (additionalFields) {
      const defaultAnswerFields = additionalFields
        .filter((af) => af.defaultAnswer)
        .map((af) => {
          return {
            id: af.id,
            answer: af.defaultAnswer,
            file: null,
          };
        });

      setAnswers(defaultAnswerFields);
    }
  }, [additionalFields]);

  return {
    FormModal,
  };
};

export default useFormModal;
