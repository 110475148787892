import { FC } from 'react';
import styled from 'styled-components';

import { OrderFilterValuesRipped } from 'order/interfaces/OrderFilterValues';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

import { ButtonPrimary } from './Button';
import { Wrapper } from './Wrapper';

interface SwitchButtonDynamicProps {
  setSearch: (value: OrderFilterValuesRipped) => void;
  buttons: SelectOptionProps[];
  search: OrderFilterValuesRipped;
}

const SwitchButtonContainer = styled(Wrapper)``;

export const SwitchButtonStyled = styled(ButtonPrimary)`
  height: 46px;
  font-size: 14px;
  font-weight: 400;
  border: none;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &.not-active {
    background: ${({ theme }) => theme.athensGray};
    color: ${({ theme }) => theme.nevada};

    &:hover {
      background: ${({ theme }) => theme.mischka};
    }
  }
`;

const SwitchButtonDynamic: FC<SwitchButtonDynamicProps> = ({
  setSearch,
  buttons,
  search,
}) => {
  return (
    <SwitchButtonContainer flex middle>
      {buttons.map((button) => (
        <SwitchButtonStyled
          key={button.value}
          onClick={() => setSearch({ ...search, orderType: button })}
          className={
            search.orderType?.value !== button.value ? 'not-active' : ''
          }
        >
          {button.label}
        </SwitchButtonStyled>
      ))}
    </SwitchButtonContainer>
  );
};

export default SwitchButtonDynamic;
