import { useSelector } from 'react-redux';
import { RootState } from 'store';

import {
  ChangeAction,
  EntityType,
  FieldName,
} from 'order/shared/ackchangelog/interface/AckChangeLogItem';

import {
  ModificationsPriceReview,
  UpchargesPriceReview,
  UpchargeType,
  OrderStylePriceReview,
} from 'order/wizard/orderStyles/interface/OrderPriceReview';

export const useHighlightHelper = (isAllowed?: boolean) => {
  const { acknowledgmentDifferences } = useSelector(
    (state: RootState) => state.ackChangeLogReducer
  );

  const strikeThroughLineItem = (
    styleId: string | null,
    lineItemId: string,
    isDeleted: boolean
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return false;

    const lineItemDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.lineItemId === lineItemId &&
        isDeleted &&
        (styleId == null || ad.styleId === styleId) &&
        ad.entityType === EntityType.LineItem &&
        ad.changeAction === ChangeAction.LineItemRemoved
    );

    return lineItemDifferences;
  };

  const highlightLineItemRow = (
    styleId: string | null,
    lineItemId: string,
    isDeleted: boolean
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const lineItemDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.lineItemId === lineItemId &&
        (styleId == null || ad.styleId === styleId) &&
        ad.entityType === EntityType.LineItem &&
        ad.changeAction === ChangeAction.LineItemAdded
    );

    const strikeThrough = strikeThroughLineItem(styleId, lineItemId, isDeleted);

    if (strikeThrough) {
      return 'highlight strikethrough';
    }

    if (lineItemDifferences) {
      return 'highlight';
    }

    return '';
  };

  const highlightModificationRow = (
    styleId: string | null,
    lineItemId: string,
    description: string,
    isDeleted?: boolean
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const modificationDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.lineItemId === lineItemId &&
        (styleId === null || ad.styleId === styleId) &&
        ad.entityType === EntityType.Modification &&
        ad.changeAction === ChangeAction.ModificationAdded &&
        ad.newValue === description
    );

    if (isDeleted) {
      return 'highlight strikethrough';
    }

    return modificationDifferences ? 'highlight' : '';
  };

  const highlightLineItemField = (
    styleId: string | null,
    lineItemId: string,
    fieldNames: FieldName[]
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const lineItemDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.lineItemId === lineItemId &&
        (styleId === null || ad.styleId === styleId) &&
        ad.entityType === EntityType.LineItem &&
        ad.changeAction === ChangeAction.LineItemUpdated &&
        fieldNames.indexOf(ad.fieldName) > -1
    );

    return lineItemDifferences ? 'highlight' : '';
  };

  const highlightOrderField = (fieldName: FieldName) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const orderFieldDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.entityType === EntityType.Order &&
        ad.changeAction === ChangeAction.OrderUpdated &&
        ad.fieldName === fieldName
    );

    return orderFieldDifferences ? 'highlight' : '';
  };

  const highlightStyleField = (styleId: string, fieldNames: FieldName[]) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const styleFieldDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.entityType === EntityType.Style &&
        fieldNames.indexOf(ad.fieldName) > -1 &&
        ad.styleId === styleId
    );

    return styleFieldDifferences ? 'highlight' : '';
  };

  const highlightDeletedModifications = (
    styleId: string | null,
    lineItemId: string
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return [];

    const modificationDifferences = acknowledgmentDifferences?.filter(
      (ad) =>
        ad.lineItemId === lineItemId &&
        (styleId === null || ad.styleId === styleId) &&
        ad.entityType === EntityType.Modification &&
        ad.changeAction === ChangeAction.ModificationRemoved
    );

    const modificationPrices: ModificationsPriceReview[] =
      modificationDifferences.map((md) => {
        const mod = JSON.parse(md.description);
        return {
          id: '',
          quantity: mod.Quantity,
          name: md.oldValue ?? '',
          description: md.oldValue ?? '',
          note: mod.Note,
          additionalNote: mod.AdditionalNote,

          // prices catalog
          catalogListPrice: 0,
          catalogNetPrice: 0,
          catalogNetUnitPrice: 0,
          catalogUnitPrice: 0,

          // prices dealer
          listPrice: mod.Quantity * mod.Price,
          netPrice: 0,
          netUnitPrice: 0,
          unitPrice: mod.Price,

          isDeleted: true,
          squareFootParts: mod.squareFootParts,
        };
      });

    return modificationPrices;
  };

  const highlightModificationField = (
    styleId: string | null,
    lineItemId: string,
    index: number,
    fieldNames: FieldName[]
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const modDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.lineItemId === lineItemId &&
        (styleId === null || ad.styleId === styleId) &&
        ad.entityType === EntityType.Modification &&
        ad.changeAction === ChangeAction.ModificationUpdated &&
        ad.entityId === index.toString() &&
        fieldNames.indexOf(ad.fieldName) > -1
    );

    return modDifferences ? 'highlight' : '';
  };

  const highlightDeletedUpcharges = (
    orderStylePriceReviewData: OrderStylePriceReview,
    styleId?: string
  ) => {
    const fieldNames = [
      FieldName.StyleWoodMaterialUpcharge,
      FieldName.StyleMaterialGroupUpcharge,
      FieldName.StyleFinishColorUpcharge,
      FieldName.StyleMaterialColorUpcharge,
      FieldName.StyleWoodEffectUpcharge,
      FieldName.StyleFinishEffectUpcharge,
      FieldName.StyleCabinetBoxMaterialUpcharge,
      FieldName.StyleOneInchThickDoorUpcharge,
      FieldName.StyleFinishImpressionUpcharge,
    ];

    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return [];

    const styleFieldDifferences = acknowledgmentDifferences?.filter(
      (ad) =>
        ad.entityType === EntityType.Style &&
        ad.styleId === styleId &&
        fieldNames.indexOf(ad.fieldName) > -1 &&
        ad.newValue === '0'
    );

    if (styleFieldDifferences.length === 0) return [];

    const fieldUpchargeMap = new Map<FieldName, UpchargeType>([
      [FieldName.StyleWoodMaterialUpcharge, UpchargeType['Wood Upcharge']],
      [FieldName.StyleMaterialGroupUpcharge, UpchargeType['Wood Upcharge']],

      [FieldName.StyleFinishColorUpcharge, UpchargeType['Finish Upcharge']],
      [FieldName.StyleMaterialColorUpcharge, UpchargeType['Finish Upcharge']],

      [FieldName.StyleWoodEffectUpcharge, UpchargeType['Wood Special Effect']],
      [
        FieldName.StyleFinishEffectUpcharge,
        UpchargeType['Finish Special Effect'],
      ],

      [
        FieldName.StyleCabinetBoxMaterialUpcharge,
        UpchargeType['Cabinet Box Material'],
      ],
      [
        FieldName.StyleOneInchThickDoorUpcharge,
        UpchargeType['One Inch Thick Door'],
      ],
      [
        FieldName.StyleFinishImpressionUpcharge,
        UpchargeType['Finish Impression'],
      ],
    ]);

    const totalCatalogUpchargesForList =
      orderStylePriceReviewData?.lineItemsListTotal +
      orderStylePriceReviewData?.finishedEndsListTotal -
      orderStylePriceReviewData?.excludedListSum;

    const percentage = parseFloat(styleFieldDifferences[0].oldValue ?? '0');
    const upchargeRow: UpchargesPriceReview = {
      upchargeType: fieldUpchargeMap.get(styleFieldDifferences[0].fieldName)!,
      catalogListPrice: 0,
      catalogNetPrice: 0,
      catalogPercentage: 0,

      netPrice: 0,
      percentage,
      listPrice: totalCatalogUpchargesForList * (percentage / 100),
      isDeleted: true,
    };

    return [upchargeRow];
  };

  const highlightDeletedStyleField = (
    styleId: string,
    fieldNames: FieldName[]
  ) => {
    if (
      acknowledgmentDifferences === null ||
      acknowledgmentDifferences.length === 0 ||
      !isAllowed
    )
      return '';

    const styleFieldDifferences = acknowledgmentDifferences?.find(
      (ad) =>
        ad.entityType === EntityType.Style &&
        fieldNames.indexOf(ad.fieldName) > -1 &&
        ad.styleId === styleId
    );

    return styleFieldDifferences?.oldValue ?? '';
  };

  return {
    highlightLineItemRow,
    highlightModificationRow,
    highlightLineItemField,
    highlightOrderField,
    highlightStyleField,
    highlightDeletedModifications,
    highlightModificationField,
    highlightDeletedUpcharges,
    highlightDeletedStyleField,
  };
};
