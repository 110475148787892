export interface UserRole {
  description: string;
}

export enum UserRoleDescription {
  DEALER_USER = 'Dealer user',
  DEALER_ADMIN = 'Dealer admin',
  SUPER_ADMIN = 'Super admin',
  CSR = 'CSR',
  SALES_REP = 'Sales Rep',
}
