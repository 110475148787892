import { BaseField } from 'shared/interface/BaseField';
import { IAttachment } from 'shared/interface/IAttachment';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { ACKStatusEnum } from '../enums/ACKStatusEnum';
import { ACKTypeEnum } from '../enums/ACKTypeEnum';
import { CollectDrawingsStatusEnum } from '../enums/CollectDrawingsStatusEnum';

import {
  Acknowledgement,
  ACKSnapshot,
  CollectDrawings,
} from '../interface/Acknowledgement';

export enum ACKActions {
  CLEAR_ACK_REDUCER = 'CLEAR_ACK_REDUCER',
  CREATE_ACK = 'CREATE_ACK',

  GET_LATEST_ACK = 'GET_LATEST_ACK',
  SET_LATEST_ACK = 'SET_LATEST_ACK',

  GET_ACK_VERSIONS = 'GET_ACK_VERSIONS',
  SET_ACK_VERSIONS = 'SET_ACK_VERSIONS',

  GET_LATEST_ACK_SNAPSHOT = 'GET_LATEST_ACK_SNAPSHOT',
  SET_LATEST_ACK_SNAPSHOT = 'SET_LATEST_ACK_SNAPSHOT',

  APPROVE_ACK = 'APPROVE_ACK',

  UPDATE_ACK = 'UPDATE_ACK',

  CHANGES_REQUEST = 'CHANGES_REQUEST',
  CHANGES_REQUEST_FINISH = 'CHANGES_REQUEST_FINISH',

  SEND_SPECIAL_ORDER_APPROVAL = 'SEND_SPECIAL_ORDER_APPROVAL',
  APPROVE_SPECIAL_ORDER_APPROVAL = 'APPROVE_SPECIAL_ORDER_APPROVAL',
  DISAPPROVE_SPECIAL_ORDER_APPROVAL = 'DISAPPROVE_SPECIAL_ORDER_APPROVAL',
  UPDATE_ACK_CHANGES_REQUESTED = 'UPDATE_ACK_CHANGES_REQUESTED',
  START_COLLECT_DRAWINGS = 'START_COLLECT_DRAWINGS',
  CHANGE_COLLECT_DRAWINGS_STATUS = 'CHANGE_COLLECT_DRAWINGS_STATUS',
  CONFIRM_RESULT_COLLECT_DRAWINGS = 'CONFIRM_RESULT_COLLECT_DRAWINGS',

  GENERATE_ORDER_SNAPSHOT = 'GENERATE_ORDER_SNAPSHOT',

  GET_COLLECT_DRAWINGS_STATUS = 'GET_COLLECT_DRAWINGS_STATUS',
  SET_COLLECT_DRAWINGS_STATUS = 'SET_COLLECT_DRAWINGS_STATUS',

  GET_ACK_ATTACHMENTS = 'GET_ACK_ATTACHMENTS',
  SET_ACK_ATTACHMENTS = 'SET_ACK_ATTACHMENTS',

  EXTEND_ACK_EXPIRATION_DATE = 'EXTEND_ACK_EXPIRATION_DATE',
}

export const clearACKReducer = () => ({
  type: ACKActions.CLEAR_ACK_REDUCER,
});

export interface CreateACKRequest {
  orderId: string;
  type: ACKTypeEnum;
  note: string;
}

export const createACK = (
  payload: CreateACKRequest,
  onSuccess: () => void
) => ({
  type: ACKActions.CREATE_ACK,
  payload,
  onSuccess,
});

export const getLatestACK = (
  orderId: string,
  loading: (isLoading: boolean) => void,
  onFailed: () => void
) => ({
  type: ACKActions.GET_LATEST_ACK,
  payload: orderId,
  loading,
  onFailed,
});

export const setLatestACK = (ACK: Acknowledgement) => ({
  type: ACKActions.SET_LATEST_ACK,
  payload: ACK,
});

export const getACKVersions = (orderId: string) => ({
  type: ACKActions.GET_ACK_VERSIONS,
  payload: orderId,
});

export const setACKVersions = (ACKVersions: BaseField[]) => ({
  type: ACKActions.SET_ACK_VERSIONS,
  payload: ACKVersions,
});

export const getLatestACKSnapshot = (
  orderId: string,
  loading: (isLoading: boolean) => void,
  onFailed: () => void
) => ({
  type: ACKActions.GET_LATEST_ACK_SNAPSHOT,
  payload: orderId,
  loading,
  onFailed,
});

export const setLatestACKSnapshot = (ACKS: ACKSnapshot) => ({
  type: ACKActions.SET_LATEST_ACK_SNAPSHOT,
  payload: ACKS,
});

export interface ApproveACKRequest {
  orderId: string;
  acknowledgementId: string;
}

export const approveACK = (
  payload: ApproveACKRequest,
  onSuccess: () => void
) => ({
  type: ACKActions.APPROVE_ACK,
  payload,
  onSuccess,
});

export const updateACKStatus = (ackStatus: ACKStatusEnum) => ({
  type: ACKActions.UPDATE_ACK,
  payload: ackStatus,
});

export interface ChangeRequestACKRequest {
  orderId: string;
  acknowledgementId: string;
}

export const changeRequest = (
  payload: ChangeRequestACKRequest,
  onSuccess: () => void
) => ({
  type: ACKActions.CHANGES_REQUEST,
  payload,
  onSuccess,
});

export interface ChangeRequestFinishACKRequest {
  orderId: string;
  acknowledgementId: string;
}

export const changesRequestFinsih = (
  payload: ChangeRequestFinishACKRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ACKActions.CHANGES_REQUEST_FINISH,
  payload,
  onSuccess,
  loading,
});

export interface SpecialOrderApprovalRequest {
  note: string;
  orderId: string;
}

export const sendSpecialOrderApproval = (
  payload: SpecialOrderApprovalRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ACKActions.SEND_SPECIAL_ORDER_APPROVAL,
  payload,
  onSuccess,
  loading,
});

export interface ApproveSpecialOrderApprovalRequest {
  orderId: string;
  specialOrderApprovalId: string;
}

export const approveSpecialOrderApproval = (
  payload: ApproveSpecialOrderApprovalRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ACKActions.APPROVE_SPECIAL_ORDER_APPROVAL,
  payload,
  onSuccess,
  loading,
});

export type DisapproveSpecialOrderApprovalRequest =
  ApproveSpecialOrderApprovalRequest;

export const disapproveSpecialOrderApproval = (
  payload: DisapproveSpecialOrderApprovalRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: ACKActions.DISAPPROVE_SPECIAL_ORDER_APPROVAL,
  payload,
  onSuccess,
  loading,
});
export interface CollectDrawingsRequest {
  orderId: string;
}

export const startCollectDrawings = (
  payload: CollectDrawingsRequest,
  onSuccess: () => void
) => ({
  type: ACKActions.START_COLLECT_DRAWINGS,
  payload,
  onSuccess,
});

export interface CollectDrawingsStatusRequest {
  collectDrawingsStatus: CollectDrawingsStatusEnum;
}
export const changeCollectDrawingsStatus = (
  payload: CollectDrawingsStatusEnum
) => ({
  type: ACKActions.CHANGE_COLLECT_DRAWINGS_STATUS,
  payload,
});

export const confirmResultCollectDrawings = (
  payload: CollectDrawingsRequest,
  onSuccess: () => void
) => ({
  type: ACKActions.CONFIRM_RESULT_COLLECT_DRAWINGS,
  payload,
  onSuccess,
});

export interface GenerateOrderSnapshotRequest {
  orderId: string;
}

export const generateOrderSnapshot = (
  payload: GenerateOrderSnapshotRequest,
  onSuccess: () => void,
  onFailed: (error: ServerErrorResponse) => void
) => ({
  type: ACKActions.GENERATE_ORDER_SNAPSHOT,
  payload,
  onSuccess,
  onFailed,
});

export const getCollectDrawingsStatus = (orderId: string) => ({
  type: ACKActions.GET_COLLECT_DRAWINGS_STATUS,
  payload: orderId,
});

export const setCollectDrawingsStatus = (CDS: CollectDrawings) => ({
  type: ACKActions.SET_COLLECT_DRAWINGS_STATUS,
  payload: CDS,
});

export const getACKAttachments = (orderId: string) => ({
  type: ACKActions.GET_ACK_ATTACHMENTS,
  payload: orderId,
});

export const setACKAttachments = (attachments: IAttachment[]) => ({
  type: ACKActions.SET_ACK_ATTACHMENTS,
  payload: attachments,
});

export interface ExtendACKExpirationDateRequest {
  orderId: string;
  acknowledgementId: string;
  extendedDateTime: string;
}

export const extendACKExpirationDate = (
  payload: ExtendACKExpirationDateRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: ACKActions.EXTEND_ACK_EXPIRATION_DATE,
  payload,
  onSuccess,
  loading,
});
