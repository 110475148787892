import { FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, ButtonSecondaryCSS } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Divider, Spacer, WhiteBox } from 'shared/components/Layout';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H3, P, SPAN } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { lynch } from 'shared/config/Colors';
import { PasswordInput } from 'shared/components/PasswordInput';
import { RadioButton } from 'shared/components/RadioButton';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Textarea } from 'shared/components/Textarea';
import { Tooltip } from 'shared/components/Tooltip';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import FormError, { StyledFormError } from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import PasswordValidation from 'shared/components/PasswordValidation';
import UtilService from 'shared/services/util.service';

import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  passwordValidation,
  phoneNumberValidation,
  userTypeValidation,
} from 'shared/validations/validations';

import { addNewCSRUser, addNewUser } from '../store/manageUsersActions';
import PWWUsername from './PWWUsername';

import {
  AddCSRUserDataProps,
  AddUserFormProps,
} from '../interfaces/AddUserFormProps';

const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 570px;
  padding: 16px;
`;

const CancelButton = styled(Link)`
  ${ButtonSecondaryCSS}
`;

export const ManageUsersAddUserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const canCreateCSRUser = useHasPermissions(
    userPermissionsValues.CSR_USER_CREATE
  );

  const canCreateDealershipUsers = useHasPermissions(
    userPermissionsValues.DEALERSHIP_USER_CREATE
  );

  const dealershipUsersPageUrl = `/overview/${
    canCreateDealershipUsers ? 'manage-' : ''
  }users`;

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const methods = useForm<
    AddUserFormProps & { note?: string } & { pwwUsername?: string }
  >({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      dealershipId: '',
      email: '',
      firstName: '',
      lastName: '',
      note: '',
      password: '',
      phoneNumber: '',
      pwwUsername: '',
      userType: '',
    },
  });

  const watchEmail = methods.watch('email');
  const firstNameWatched = methods.watch('firstName');
  const lastNameWatched = methods.watch('lastName');

  const [emailExists, setEmailExists] = useState(false);

  const [validPassword, setValidPassword] = useState(false);

  const [creatingUserInProgress, setCreatingUserInProgress] = useState(false);

  const [dealershipConfirmModalOpened, setDealershipConfirmModalOpened] =
    useState(false);

  const watchedPassword = methods.watch('password');

  const onCreateUserSuccessHandler = () => {
    history.push(dealershipUsersPageUrl, {
      successMessage: 'User has been successfully created.',
    });
  };

  const onCreateUserErrorHandler = (err: ServerErrorResponse) => {
    setCreatingUserInProgress(false);

    if (err.status === 409) {
      setEmailExists(true);
    }

    if (err.title && err.status === 500) {
      toast.error(err.title);
    }
  };

  const onSubmit = (data: AddUserFormProps | AddCSRUserDataProps) => {
    setCreatingUserInProgress(true);

    if (dealership) {
      const userDataTrimmed = UtilService.trimStartEndSpaces<AddUserFormProps>(
        data as AddUserFormProps
      );
      dispatch(
        addNewUser(
          userDataTrimmed,
          onCreateUserSuccessHandler,
          onCreateUserErrorHandler,
          setCreatingUserInProgress
        )
      );
    } else {
      const csrUserData = UtilService.omit(data as AddUserFormProps, [
        'userType',
        'dealershipId',
      ]);

      const csrUserDataTrimmed =
        UtilService.trimStartEndSpaces<AddCSRUserDataProps>(
          csrUserData as unknown as AddCSRUserDataProps
        );

      dispatch(
        addNewCSRUser(
          { ...csrUserDataTrimmed, note: csrUserDataTrimmed.note || null },
          onCreateUserSuccessHandler,
          onCreateUserErrorHandler,
          setCreatingUserInProgress
        )
      );
    }
  };

  const onPasswordCheckHandler = (isPasswordValid: boolean) => {
    setValidPassword(isPasswordValid);
  };

  // Remove error for existing email when the user start to change it
  useEffect(() => {
    setEmailExists(false);
  }, [watchEmail]);

  useEffect(() => {
    if (dealership) {
      methods.setValue('dealershipId', dealership.id);
    }
  }, [dealership]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <WhiteBox>
          <Form>
            <H3>Profile Settings</H3>
            <Spacer h="28px" />

            {(!canCreateCSRUser || dealership) && (
              <FormElement>
                <FormLabel>User Type</FormLabel>

                <Spacer h="5px" />

                <Wrapper flex middle>
                  <RadioButton
                    {...methods.register(
                      'userType',
                      userTypeValidation({ required: true })
                    )}
                    aria-invalid={
                      methods.formState.errors.userType ? 'true' : 'false'
                    }
                    id="userType--dealer"
                    data-test="dealer-admin"
                    title="Dealer Admin"
                    value="owner"
                  />

                  <Spacer w="20px" />

                  <RadioButton
                    {...methods.register(
                      'userType',
                      userTypeValidation({ required: true })
                    )}
                    aria-invalid={
                      methods.formState.errors.userType ? 'true' : 'false'
                    }
                    id="userType--user"
                    data-test="dealer-user"
                    title="Dealer User"
                    value="dealer"
                  />
                </Wrapper>

                <FormError
                  label="User Type"
                  error={methods.formState.errors.userType}
                  validationSchema={userTypeValidation({ required: true })}
                />
              </FormElement>
            )}

            <FormElement>
              <FormLabel>First Name</FormLabel>

              <Input
                placeholder="Enter your name here..."
                data-test="input-first-name"
                aria-invalid={
                  methods.formState.errors.firstName ? 'true' : 'false'
                }
                {...methods.register(
                  'firstName',
                  firstNameValidation({ required: true })
                )}
              />

              <FormError
                label="First name"
                error={methods.formState.errors.firstName}
                validationSchema={firstNameValidation({ required: true })}
              />
            </FormElement>

            <FormElement>
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="Enter your last name here..."
                {...methods.register(
                  'lastName',
                  lastNameValidation({ required: true })
                )}
                data-test="input-last-name"
                aria-invalid={
                  methods.formState.errors.lastName ? 'true' : 'false'
                }
              />

              <FormError
                label="Last name"
                error={methods.formState.errors.lastName}
                validationSchema={lastNameValidation({ required: true })}
              />
            </FormElement>

            <FormElement>
              <FormLabel>Email for login</FormLabel>

              <Input
                placeholder="Enter your email here..."
                type="email"
                data-test="input-email"
                aria-invalid={
                  methods.formState.errors.email || emailExists
                    ? 'true'
                    : 'false'
                }
                {...methods.register(
                  'email',
                  emailValidation({ required: true })
                )}
              />

              <FormError
                label="Email"
                error={methods.formState.errors.email}
                validationSchema={emailValidation({ required: true })}
              />

              {emailExists && (
                <StyledFormError>
                  The user with the specified email already exists.
                </StyledFormError>
              )}
            </FormElement>

            <FormElement>
              <FormLabel>Password</FormLabel>

              <PasswordInput
                placeholder="Enter your password here..."
                type="password"
                autoComplete="new-password"
                aria-invalid={
                  (!validPassword && !!watchedPassword?.length) ||
                  methods.formState.errors.password
                    ? 'true'
                    : 'false'
                }
                data-test="input-password"
                {...methods.register(
                  'password',
                  passwordValidation({ required: true })
                )}
              />

              <FormError
                label="Password"
                error={methods.formState.errors.password}
                validationSchema={passwordValidation({ required: true })}
              />

              {!!watchedPassword?.length && (
                <PasswordValidation
                  onPasswordCheck={onPasswordCheckHandler}
                  password={watchedPassword}
                />
              )}
            </FormElement>

            {canCreateCSRUser && !dealership && <PWWUsername />}

            <Spacer h="16px" />
            <Divider />
            <Spacer h="35px" />

            <H3>Contact Information</H3>

            <Spacer h="16px" />

            <FormElement>
              <FormLabel>Phone Number</FormLabel>

              <Input
                placeholder="Enter your phone here..."
                data-test="input-phone"
                aria-invalid={
                  methods.formState.errors.phoneNumber ? 'true' : 'false'
                }
                {...methods.register(
                  'phoneNumber',
                  phoneNumberValidation({ required: true })
                )}
              />

              <FormError
                label="Phone number"
                error={methods.formState.errors.phoneNumber}
                validationSchema={phoneNumberValidation({ required: true })}
              />
            </FormElement>

            {canCreateCSRUser && !dealership && (
              <FormElement>
                <FormLabel>Note</FormLabel>
                <Textarea
                  data-test="input-note"
                  {...methods.register('note')}
                />
              </FormElement>
            )}

            <Wrapper flex justifyEnd middle>
              <CancelButton
                to={dealershipUsersPageUrl}
                type="button"
                data-test="button-cancel"
              >
                Cancel
              </CancelButton>

              <Spacer w="20px" />

              <Wrapper withTooltip>
                <ButtonPrimary
                  type="button"
                  disabled={
                    (!validPassword && !!watchedPassword?.length) ||
                    creatingUserInProgress ||
                    (isUserSalesRep && !dealership)
                  }
                  data-test="button-add"
                  onClick={
                    isUserSalesRep && methods.formState.isValid
                      ? () => setDealershipConfirmModalOpened(true)
                      : methods.handleSubmit(onSubmit)
                  }
                >
                  Add
                  <Loader
                    hidden={!creatingUserInProgress}
                    insideButton
                    noSpacing
                    size={16}
                  />
                </ButtonPrimary>

                {isUserSalesRep && !dealership && (
                  <Tooltip>
                    Adding user is disable until the dealership is selected.
                  </Tooltip>
                )}
              </Wrapper>
            </Wrapper>
          </Form>
        </WhiteBox>
      </FormContainer>

      <ConfirmationModal
        title="Add User"
        opened={dealershipConfirmModalOpened}
        confirm={(close) => {
          close();
          setDealershipConfirmModalOpened(false);
          methods.handleSubmit(onSubmit)();
        }}
        buttonText="Add User"
        cancelButtonText="Cancel"
        cancel={() => {
          setDealershipConfirmModalOpened(false);
        }}
      >
        <P>
          Are you sure you want to add{' '}
          <SPAN color={lynch} fontWeight={600}>
            {firstNameWatched} {lastNameWatched}
          </SPAN>{' '}
          to the{' '}
          <SPAN color={lynch} fontWeight={600}>
            {dealership?.name}
          </SPAN>{' '}
          dealership?
        </P>
      </ConfirmationModal>
    </FormProvider>
  );
};
