import { FC, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import { kashmirBlue, nevada, silverChaliceTwo } from 'shared/config/Colors';

import { ButtonIcon } from './Button';
import { H2, P } from './Typography';
import { Spacer } from './Layout';
import { Wrapper } from './Wrapper';
import SVG from './SVG';

const ExpandableContainer = styled.div<{ noBorderBottom?: boolean }>`
  ${({ noBorderBottom = false, theme }) =>
    !noBorderBottom && `border-bottom: 1px solid ${theme.alto};`}

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.alto};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ExpandableHeader = styled(Wrapper)`
  padding: 16px 0;
`;

interface ExpandableBoxProps {
  title: string;
  description: string;
  noBorderBottom?: boolean;
}

export const ExpandableBox: FC<ExpandableBoxProps> = ({
  title,
  description,
  noBorderBottom,
  children,
}) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableContainer noBorderBottom={noBorderBottom}>
      <ExpandableHeader flex between middle>
        <Wrapper>
          <H2>{title}</H2>
          <P color={nevada} fontSize={14}>
            {description}
          </P>
        </Wrapper>
        <ButtonIcon
          className={
            expanded
              ? 'expandable-box-button expanded'
              : 'expandable-box-button'
          }
          onClick={() => setExpanded((prevState) => !prevState)}
          iconColor={silverChaliceTwo}
          hoverIconColor={kashmirBlue}
        >
          <SVG
            animateTransform={['transform', 'borderRadius']}
            icon={<ArrowDownIcon />}
          />
        </ButtonIcon>
      </ExpandableHeader>

      {expanded && children}
      {expanded && <Spacer h="32px" />}
    </ExpandableContainer>
  );
};
