import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';

interface PasswordValidationProps {
  password: string;
  onPasswordCheck: (value: boolean) => void;
}

const PasswordValidationContainer = styled.div`
  margin-top: 20px;
`;

const validPasswordCSS = css`
  color: ${({ theme }) => theme.fern};
`;

const invalidPasswordCSS = css`
  color: ${({ theme }) => theme.nevada};
`;

const PasswordValidationList = styled.ul`
  list-style: none;
  list-style-type: none;
`;

interface PasswordValidationListItemProps {
  valid: boolean;
}

const getPasswordCss = (valid: boolean) => {
  return valid ? validPasswordCSS : invalidPasswordCSS;
};

const CheckStyled = styled(Check)``;

const CrossStyled = styled(Cross)``;

const PasswordValidationListItem = styled.li<PasswordValidationListItemProps>`
  position: relative;
  padding-left: 30px;
  margin-bottom: 14px;
  font-size: 14px;
  transition: color 300ms ease;

  &:last-child {
    margin-bottom: 0;
  }

  ${CrossStyled},
  ${CheckStyled} {
    width: 14px;
    height: 14px;
    left: 0;
    position: absolute;
    top: 9px;
    transform: translateY(-50%);
    transition: 300ms ease;
  }

  ${CheckStyled} {
    ${({ valid }) => (valid ? 'opacity: 1;' : 'opacity: 0;')}

    path {
      fill: ${({ theme }) => theme.fern};
    }
  }

  ${CrossStyled} {
    ${({ valid }) => (!valid ? 'opacity: 1;' : 'opacity: 0;')}

    path {
      fill: ${({ theme }) => theme.nevada};
    }
  }

  ${({ valid }) => getPasswordCss(valid)};
`;

const PasswordValidation = ({
  password,
  onPasswordCheck,
}: PasswordValidationProps) => {
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordLowerLetters, setpasswordLowerLetters] = useState(false);
  const [passwordUpperLetters, setpasswordUpperLetters] = useState(false);
  const [passwordNumbers, setpasswordNumbers] = useState(false);
  const [passwordSymbols, setpasswordSymbols] = useState(false);

  const isPasswordValid = () => {
    return (
      passwordLength &&
      passwordLowerLetters &&
      passwordUpperLetters &&
      passwordNumbers &&
      passwordSymbols
    );
  };

  useEffect(() => {
    setPasswordLength(/.{6,}/.test(password));
    setpasswordLowerLetters(/[a-z]/.test(password));
    setpasswordUpperLetters(/[A-Z]/.test(password));
    setpasswordNumbers(/[0-9]{1,}/.test(password));
    setpasswordSymbols(/[^\w\d\s]/.test(password));

    onPasswordCheck(isPasswordValid());
  }, [
    password,
    passwordLength,
    passwordLowerLetters,
    passwordUpperLetters,
    passwordNumbers,
    passwordSymbols,
  ]);

  return (
    <PasswordValidationContainer>
      <PasswordValidationList>
        <PasswordValidationListItem valid={passwordLength}>
          <CrossStyled />
          <CheckStyled />
          <span>Minimum 6 characters</span>
        </PasswordValidationListItem>

        <PasswordValidationListItem valid={passwordLowerLetters}>
          <CrossStyled />
          <CheckStyled />
          <span>At least one lowercase letter</span>
        </PasswordValidationListItem>

        <PasswordValidationListItem valid={passwordUpperLetters}>
          <CrossStyled />
          <CheckStyled />
          <span>At least one uppercase letter</span>
        </PasswordValidationListItem>

        <PasswordValidationListItem valid={passwordNumbers}>
          <CrossStyled />
          <CheckStyled />
          <span>At least one number</span>
        </PasswordValidationListItem>

        <PasswordValidationListItem valid={passwordSymbols}>
          <CrossStyled />
          <CheckStyled />
          <span>At least one special character/symbol</span>
        </PasswordValidationListItem>
      </PasswordValidationList>
    </PasswordValidationContainer>
  );
};

export default PasswordValidation;
