import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useEffect } from 'react';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormDatePicker } from 'shared/components/FormDatePicker';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { RadioButton } from 'shared/components/RadioButton';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';

const SpanText = styled.span`
  padding: 10px;
`;
const Container = styled(Wrapper)`
  width: 100%;
  max-width: 190px;
`;

export interface TargetDateFormValues {
  targetDateOption: string;
  targetDate: Date | null;
  endTargetDate: Date | null;
}

interface TargetDateModalProps {
  opened: boolean;
  cancel: () => void;
  onSubmit: (form: TargetDateFormValues) => void;
}

enum RadioButtonNames {
  PRECISE_DATE = 'preciseDate',
  BETWEEN_DATES = 'betweenDates',
  AFTER_DATE = 'afterDate',
  BEFORE_DATE = 'beforeDate',
}

export enum TargetDateFilterType {
  'preciseDate' = 1,
  'betweenDates',
  'afterDate',
  'beforeDate',
}

export const TargetDateModal = ({
  opened,
  cancel,
  onSubmit,
}: TargetDateModalProps) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState,
    control,
    clearErrors,
  } = useForm<TargetDateFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const selectedTargetDateOption = watch('targetDateOption');

  useEffect(() => {
    if (!selectedTargetDateOption) {
      setValue('targetDateOption', RadioButtonNames.PRECISE_DATE);
    } else {
      setValue('targetDate', null);
      setValue('endTargetDate', null);
      clearErrors();
    }
  }, [selectedTargetDateOption]);

  return (
    <ModalWrapper
      modal
      closeOnDocumentClick={false}
      open={opened}
      closeOnEscape={false}
    >
      {(close: () => void) => (
        <Modal title="" withoutFooter={1} small>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper flexGrow>
              <Wrapper flex>
                <RadioButton
                  {...register('targetDateOption', { required: true })}
                  name="targetDateOption"
                  title="precise date"
                  value={RadioButtonNames.PRECISE_DATE}
                  id={`targetDate-${RadioButtonNames.PRECISE_DATE}`}
                />
              </Wrapper>
              <Spacer h="16px" />
              {selectedTargetDateOption === RadioButtonNames.PRECISE_DATE && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="targetDate"
                        rules={{
                          required:
                            selectedTargetDateOption ===
                            RadioButtonNames.PRECISE_DATE,
                        }}
                        render={({ field }) => (
                          <FormDatePicker
                            {...UtilService.omit(field, ['ref'])}
                            placeholder="Target date"
                            clearable
                            error={!!formState.errors.targetDate}
                            format="MM/dd/yyyy"
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('targetDateOption', { required: true })}
                  name="targetDateOption"
                  title="between dates"
                  value={RadioButtonNames.BETWEEN_DATES}
                  id={`targetDate-${RadioButtonNames.BETWEEN_DATES}`}
                />
              </Wrapper>
              <Spacer h="16px" />

              {selectedTargetDateOption === RadioButtonNames.BETWEEN_DATES && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="targetDate"
                        rules={{
                          required:
                            selectedTargetDateOption ===
                            RadioButtonNames.BETWEEN_DATES,
                        }}
                        render={({ field }) => (
                          <FormDatePicker
                            {...UtilService.omit(field, ['ref'])}
                            placeholder="From"
                            clearable
                            error={!!formState.errors.targetDate}
                            format="MM/dd/yyyy"
                          />
                        )}
                      />
                    </Container>
                    <Spacer w="10px" />
                    <SpanText>and</SpanText>
                    <Spacer w="10px" />
                    <Container>
                      <Controller
                        control={control}
                        name="endTargetDate"
                        rules={{
                          required:
                            selectedTargetDateOption ===
                            RadioButtonNames.BETWEEN_DATES,
                        }}
                        render={({ field }) => (
                          <FormDatePicker
                            {...UtilService.omit(field, ['ref'])}
                            placeholder="To"
                            clearable
                            error={!!formState.errors.endTargetDate}
                            format="MM/dd/yyyy"
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('targetDateOption', { required: true })}
                  title="after date"
                  value={RadioButtonNames.AFTER_DATE}
                  id={`targetDate-${RadioButtonNames.AFTER_DATE}`}
                />
              </Wrapper>
              <Spacer h="16px" />

              {selectedTargetDateOption === RadioButtonNames.AFTER_DATE && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="targetDate"
                        rules={{
                          required:
                            selectedTargetDateOption ===
                            RadioButtonNames.AFTER_DATE,
                        }}
                        render={({ field }) => (
                          <FormDatePicker
                            {...UtilService.omit(field, ['ref'])}
                            placeholder="Target date"
                            clearable
                            error={!!formState.errors.targetDate}
                            format="MM/dd/yyyy"
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('targetDateOption', { required: true })}
                  name="targetDateOption"
                  title="before date"
                  value={RadioButtonNames.BEFORE_DATE}
                  id={`targetDate-${RadioButtonNames.BEFORE_DATE}`}
                />
              </Wrapper>
              <Spacer h="16px" />
              {selectedTargetDateOption === RadioButtonNames.BEFORE_DATE && (
                <Wrapper flex>
                  <Container>
                    <Controller
                      control={control}
                      name="targetDate"
                      rules={{
                        required:
                          selectedTargetDateOption ===
                          RadioButtonNames.BEFORE_DATE,
                      }}
                      render={({ field }) => (
                        <FormDatePicker
                          {...UtilService.omit(field, ['ref'])}
                          placeholder="Target date"
                          clearable
                          error={!!formState.errors.targetDate}
                          format="MM/dd/yyyy"
                        />
                      )}
                    />
                  </Container>
                </Wrapper>
              )}
              <Spacer h="32px" />
              <Wrapper flex justifyEnd>
                <ButtonSecondary onClick={cancel ?? close} type="button">
                  Cancel
                </ButtonSecondary>
                <Spacer w="16px" />
                <ButtonPrimary type="submit">Apply Filter</ButtonPrimary>
              </Wrapper>
            </Wrapper>
          </Form>
        </Modal>
      )}
    </ModalWrapper>
  );
};
