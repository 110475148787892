import { FC, ReactElement, useState } from 'react';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PencilIcon } from 'assets/icons/edit-solid.svg';
import { ReactComponent as PencilCrossedIcon } from 'assets/icons/edit-disabled.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  getLockInfoOnOrder,
  getOrderStatus,
  lockOrderForEdit,
  setCanEditOrder,
  setLockInfoOnOrder,
  unlockOrderForEdit,
} from 'order/store/orderActions';

import AvatarCircle from 'shared/components/AvatarCircle';
import Loader from 'shared/components/Loader';
import UtilService from 'shared/services/util.service';
import { Container } from 'shared/components/Container';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { P } from 'shared/components/Typography';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { User } from 'shared/interface/User';
import { Wrapper } from 'shared/components/Wrapper';
import { black } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrderBeforeStatus } from 'shared/hooks/useCanEditOrderBeforeStatus';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  ButtonDefault,
  ButtonTertiaryDarkCSS,
  ButtonTertiaryLightCSS,
} from 'shared/components/Button';

const OrderEditLockContainer = styled(Wrapper)`
  background-color: ${({ theme }) => theme.parchment};
  height: 44px;
  left: 0;
  padding: 7px 0;
  position: fixed;
  right: 0;
  top: 115px;
`;

enum LockStatusEnum {
  UNLOCKED = 1,
  AS_EDITOR = 2,
  AS_VIEWER = 3,
}

interface EditLockMeta {
  message: string;
  label: string;
  id: LockStatusEnum;
  icon: ReactElement<SVGSVGElement>;
}

const UserName = styled(P)``;

const EditOrderLockBtn = styled(ButtonDefault)<{ asEditor: boolean }>`
  ${({ asEditor }) =>
    asEditor ? ButtonTertiaryLightCSS : ButtonTertiaryDarkCSS}
`;

const OrderEditLock: FC = (props) => {
  const editIconSize = '14';

  const dispatch = useAppDispatch();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);

  const orderLockInfo = useSelector(
    (state: RootState) => state.orderReducer.lockInfo
  );

  const loggedInUserId = useSelector(
    (state: RootState) => state.authReducer.user?.id
  );

  const canEditBeforeStatus = useCanEditOrderBeforeStatus(
    OrderStatusEnums.Writing
  );

  const currentlyLoggedInUserIsEditor =
    orderLockInfo?.lockedByUserId === loggedInUserId;

  const lockOrderForEditSuccessHandler = (asEditor: boolean) => {
    if (canEditBeforeStatus || isUserCSR) {
      dispatch(setCanEditOrder(asEditor));
    }
  };

  const lockOrderForEditErrorHandler = (err: ServerErrorResponse) => {
    setLoading(false);

    UtilService.onPopupClose();

    dispatch(
      getLockInfoOnOrder({
        orderId,
      })
    );

    UtilService.handleError({
      err,
      fallbackErrorToastMessage: 'There was an error while entering edit mode.',
      callback: () => {
        if (err.status === 403) {
          dispatch(getOrderStatus(orderId));
        }
      },
    });
  };

  const unlockOrderForEditErrorHandler = (err: ServerErrorResponse) => {
    setLoading(false);

    dispatch(setLockInfoOnOrder(null));

    UtilService.handleError({
      err,
      fallbackErrorToastMessage: 'There was an error while leaving edit mode.',
    });
  };

  const someoneElseIsEditing = () => {
    return loggedInUserId !== orderLockInfo?.lockedByUserId;
  };

  /* const getRestOfTheTimeToLimit = () => {
    if (shipWeekEndDate && shipWeekLimit) {
      const currentTime = new Date();

      const shipWeekEndDateTime = new Date(shipWeekEndDate);
      shipWeekEndDateTime.setHours(0, 0, 0);

      const shipWeekMinusLimit = subWeeks(shipWeekEndDateTime, shipWeekLimit);

      return currentTime.getTime() < shipWeekMinusLimit.getTime()
        ? formatDistanceStrict(currentTime, shipWeekMinusLimit)
        : undefined;
    }

    return undefined;
  }; */

  const editLockMeta = (): EditLockMeta | null => {
    // if currently logged in user is editor of the order
    if (currentlyLoggedInUserIsEditor) {
      return {
        message: 'You are currently the editor on this order.',
        label: 'Disable Editing',
        id: LockStatusEnum.AS_EDITOR,
        icon: <PencilIcon width={editIconSize} height={editIconSize} />,
      };
    }

    // if currently logged in user is not editor of the order
    if (
      !currentlyLoggedInUserIsEditor &&
      orderLockInfo &&
      someoneElseIsEditing()
    ) {
      return {
        message:
          "This order is currently being edited by someone else; once they're done, you can edit it.",
        label: 'Dismiss',
        id: LockStatusEnum.AS_VIEWER,
        icon: <PencilCrossedIcon width={editIconSize} height={editIconSize} />,
      };
    }

    return {
      message:
        'To make some changes on this order, please enable editing mode.',
      label: 'Edit',
      id: LockStatusEnum.UNLOCKED,
      icon: <PencilIcon width={editIconSize} height={editIconSize} />,
    };
  };

  const onOrderEditLock = () => {
    if (!orderLockInfo) {
      setLoading(true);
      dispatch(
        lockOrderForEdit(
          { orderId },
          () => lockOrderForEditSuccessHandler(true),
          (err) => lockOrderForEditErrorHandler(err),
          setLoading
        )
      );
    } else {
      setLoading(true);
      dispatch(
        unlockOrderForEdit(
          { orderId, orderLockId: orderLockInfo.id },
          () => lockOrderForEditSuccessHandler(false),
          (err) => unlockOrderForEditErrorHandler(err),
          setLoading
        )
      );
    }
  };

  return (
    <OrderEditLockContainer
      {...props}
      flex
      middle
      className="order-edit-lock-container"
    >
      <Container flex middle padding={30}>
        <P fontSize={14} color={black}>
          {editLockMeta()?.message}
        </P>

        {/* shipWeekEndDate && isOrderSubmitted && isUserDealerOrSalesRep && (
          <>
            <Spacer w="25px" />

            <Info type="warning">
              <P color="inherit" fontSize={14}>
                {getRestOfTheTimeToLimit() ? (
                  <>
                    Order will be locked for editing in{' '}
                    <strong>{getRestOfTheTimeToLimit()}</strong> due to
                    scheduled ship week date.
                  </>
                ) : (
                  'Order is now locked for editing.'
                )}
              </P>
            </Info>
          </>
                ) */}

        {orderLockInfo && (
          <>
            <Spacer w="32px" />

            <Wrapper flex middle>
              <AvatarCircle
                user={orderLockInfo.user as User}
                size={28}
                border={2}
              />

              <Spacer w="16px" />

              <UserName color={black} fontSize={14}>
                {orderLockInfo.user.firstName} {orderLockInfo.user.lastName}
              </UserName>
            </Wrapper>
          </>
        )}

        {editLockMeta()?.id !== LockStatusEnum.AS_VIEWER && (
          <Wrapper mlAuto flex middle>
            <EditOrderLockBtn
              asEditor={editLockMeta()?.id === LockStatusEnum.AS_EDITOR}
              onClick={onOrderEditLock}
            >
              <Wrapper flex middle>
                {editLockMeta()?.icon}

                <Spacer w="5px" />

                {editLockMeta()?.label}

                <Loader insideButton noSpacing hidden={!loading} size={16} />
              </Wrapper>
            </EditOrderLockBtn>

            <Spacer w="10px" />
          </Wrapper>
        )}
      </Container>
    </OrderEditLockContainer>
  );
};

export default OrderEditLock;
