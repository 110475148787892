import { AnyAction } from 'redux';
import { TableColumnIdEnums } from 'shared/enum/tableEnums';

import { User } from 'shared/interface/User';
import UtilService from 'shared/services/util.service';

import { manageUsersActions } from './manageUsersActions';

interface ManageUsersReducer {
  users: User[] | null;
}

const initialState: ManageUsersReducer = {
  users: null,
};

export const manageUsersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case manageUsersActions.SET_USER_LIST:
      return {
        ...state,
        users: action.payload as User[],
      };
    case manageUsersActions.UPDATE_USER_ENABLED_DISABLED_STATUS:
      return {
        ...state,
        users: (state.users ?? []).map((user: User) => {
          if (user.id === action.payload) {
            return {
              ...user,
              enabled: !user.enabled,
            };
          }
          return user;
        }),
      };
    case manageUsersActions.SORT_USER_LIST:
      switch (action.payload.sortColumn) {
        case TableColumnIdEnums.NAME: {
          const sortedUsers = (state.users ?? []).sort((a, b) =>
            UtilService.sortBy(
              `${a.firstName} ${a.lastName}`,
              `${b.firstName} ${b.lastName}`,
              action.payload.asc
            )
          );

          return {
            ...state,
            users: [...sortedUsers],
          };
        }
        case TableColumnIdEnums.EMAIL: {
          const sortedUsers = (state.users ?? []).sort((a, b) =>
            UtilService.sortBy(a.email, b.email, action.payload.asc)
          );

          return {
            ...state,
            users: [...sortedUsers],
          };
        }
        default:
          return state;
      }
    default:
      return state;
  }
};
