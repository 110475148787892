import { AnyAction } from 'redux';
import { AdditionalFieldActiontTypes } from './additionalFieldsActions';
import { AdditionalField } from '../interfaces/AdditionalField';

interface AdditionalFieldReducerProps {
  aditionalFields: AdditionalField[] | null;
}

const initialState: AdditionalFieldReducerProps = {
  aditionalFields: null,
};

export const aditionalFieldReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AdditionalFieldActiontTypes.SET_ADDITIONAL_FIELDS:
      return {
        ...state,
        aditionalFields: action.payload as AdditionalField[],
      };
    default:
      return state;
  }
};
