import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from 'store';

import { Order } from 'order/interfaces/Order';
import { setAssigneesOnOrder } from 'order/store/orderActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { CSR } from 'shared/interface/CSR';

import {
  AddOrderAssigneeRequest,
  deselectCSR,
  GetOrderAssigneesRequest,
  orderAssigneesActions,
  RemoveOrderAssigneeRequest,
  SearchCSRSRequest,
  selectCSR,
  SetCSRasPrimaryRequest,
  setOrderAssignees,
  setSearchedCSRS,
  updateCSRPrimary,
} from './orderAssigneesActions';

function* searchCSRS(action: Action<SearchCSRSRequest>) {
  try {
    const { searchTerm } = action.payload!;
    const params = new URLSearchParams();
    params.append('searchTerm', searchTerm);

    const csrs: CSR[] = yield call(
      ApiService.get,
      `/api/administration/users/csr/filtered`,
      {
        params,
      }
    );

    yield put(setSearchedCSRS(csrs));

    if (action.onSuccess) {
      yield call(action.onSuccess, csrs);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* addOrderAssignee(action: Action<AddOrderAssigneeRequest>) {
  try {
    const { user, orderId } = action.payload!;

    yield call(ApiService.post, `/api/order/orders/${orderId}/assignee`, {
      userId: user.id,
    });

    yield put(selectCSR(user));

    const order: Order = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}`
    );

    yield put(setAssigneesOnOrder(order.assigneeResponses ?? []));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield action.loading(null);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* removeOrderAssignee(action: Action<RemoveOrderAssigneeRequest>) {
  try {
    const { userId, orderId } = action.payload!;

    yield call(
      ApiService.delete,
      `/api/order/orders/${orderId}/assignee/${userId}`
    );

    yield put(deselectCSR(userId));

    const order: Order = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}`
    );

    yield put(setAssigneesOnOrder(order.assigneeResponses ?? []));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderAssignees(action: Action<GetOrderAssigneesRequest>) {
  try {
    const { orderId } = action.payload!;

    const csrs: CSR[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/assignees`
    );

    yield put(setOrderAssignees(csrs));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* setCSRasPrimary(action: Action<SetCSRasPrimaryRequest>) {
  try {
    const { orderId, userId, primary } = action.payload!;

    yield call(
      primary ? ApiService.put : ApiService.delete,
      `/api/order/orders/${orderId}/assignees/${userId}/primary`
    );

    const otherAssignee: CSR | undefined = yield select((state: RootState) =>
      state.orderAssigneesReducer.selectedCSRs?.find((csr) => csr.id !== userId)
    );

    if (otherAssignee?.primary) {
      yield call(
        ApiService.delete,
        `/api/order/orders/${orderId}/assignees/${otherAssignee.id}/primary`
      );
    }

    yield put(updateCSRPrimary({ userId, primary }));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderAssigneesSagas() {
  yield takeEvery(orderAssigneesActions.SEARCH_CSRS, searchCSRS);
  yield takeEvery(orderAssigneesActions.GET_ORDER_ASSIGNEES, getOrderAssignees);
  yield takeEvery(orderAssigneesActions.SET_CSR_PRIMARY, setCSRasPrimary);
  yield takeEvery(orderAssigneesActions.ADD_ASSIGNEE, addOrderAssignee);
  yield takeEvery(orderAssigneesActions.REMOVE_ASSIGNEE, removeOrderAssignee);
}
