import styled, { css } from 'styled-components';
import { ReactNode, useEffect } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { Spacer } from 'shared/components/Layout';

import { ModalTestEnum } from 'tests/enums/ModalTestEnums';
import { testId } from 'tests/utils';

import { ButtonIcon } from './Button';
import { H2, H3 } from './Typography';

interface ModalProps {
  children: ReactNode;
  close?: (callBackFunc?: () => void) => void;
  onSetLoading?: (isLoading: boolean) => void;
  withoutFooter?: 1 | 0;
  title?: string;
  noHeader?: boolean;
  confirm?: boolean;
  small?: boolean;
  medium?: boolean;
}

const ModalConfirmCSS = css`
  width: 615px;
  padding: 32px;
`;

const ModalCSS = css`
  width: 700px;
  padding: 40px 38px;
`;

const SmallModalCSS = css`
  width: 500px;
  padding: 40px 38px;
`;

const MediumModalCSS = css`
  width: 850px;
  padding: 40px 38px;
`;

const ModalContainer = styled.div<
  Pick<ModalProps, 'confirm' | 'small' | 'medium'>
>`
  ${({ confirm, small, medium }) =>
    // eslint-disable-next-line no-nested-ternary
    confirm
      ? ModalConfirmCSS
      : // eslint-disable-next-line no-nested-ternary
      small
      ? SmallModalCSS
      : medium
      ? MediumModalCSS
      : ModalCSS}
`;

const ModalHeader = styled.div``;

const CloseButton = styled(ButtonIcon)`
  position: absolute;
  top: 40px;
  right: 38px;

  > svg > path {
    color: ${({ theme }) => theme.nevada};
  }
`;
const HeaderLeftSide = styled.div`
  max-width: calc(100% - 30px);
`;

const ModalContent = styled.div`
  padding-top: 2px;
`;

const ModalFooter = styled.div``;

export const Modal = ({
  children,
  close,
  confirm,
  noHeader,
  withoutFooter,
  title,
  small,
  onSetLoading,
  ...rest
}: ModalProps) => {
  useEffect(() => {
    return () => {
      if (onSetLoading) {
        onSetLoading(false);
      }
    };
  }, []);

  return (
    <ModalContainer confirm={confirm} small={small} {...rest}>
      <ModalHeader>
        {title && !noHeader && (
          <HeaderLeftSide>
            {confirm ? (
              <H2 {...testId(ModalTestEnum.TITLE)}>{title}</H2>
            ) : (
              <H3 {...testId(ModalTestEnum.TITLE)}>{title}</H3>
            )}
          </HeaderLeftSide>
        )}

        {close != null && (
          <CloseButton
            onClick={() => close()}
            {...testId(ModalTestEnum.CLOSE_BTN)}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </ModalHeader>

      <ModalContent {...testId(ModalTestEnum.BODY)}>
        {(title !== '' || close != null) && !noHeader ? (
          <Spacer h={confirm ? '25px' : '45px'} />
        ) : null}
        {children}
      </ModalContent>
      {!withoutFooter && <ModalFooter {...testId(ModalTestEnum.FOOTER)} />}
    </ModalContainer>
  );
};

Modal.defaultProps = {
  title: '',
  close: null,
  onSetLoading: null,
  noHeader: false,
  confirm: false,
  small: false,
  withoutFooter: 0,
  medium: false,
};
