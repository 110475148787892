import { FC } from 'react';
import { RootState } from 'store';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as NotificationIcon } from 'assets/icons/notifications.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/edit.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/mail.svg';

import { ButtonPrimaryCSS, ButtonIcon } from 'shared/components/Button';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { mineShaft, mischka, osloGray } from 'shared/config/Colors';
import { MoreInfoButton, MoreInfoContainer } from 'shared/components/MoreInfo';
import { Spacer } from 'shared/components/Layout';
import { useLogout } from 'shared/hooks/useLogout';
import { useNotifsCountCheck } from 'shared/hooks/useNotifsCountCheck';
import { Wrapper } from 'shared/components/Wrapper';
import AvatarCircle from 'shared/components/AvatarCircle';
import IconCircle from 'shared/components/IconCircle';
import SVG from 'shared/components/SVG';

import { Notifications } from '../../notifications/Notifications';

const HeaderContainer = styled(Wrapper)`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  left: 0;
  min-height: 64px;
  right: 0;
  top: 0;
  z-index: 6;
  padding: 0 25px;
`;

export const OverviewHeaderLeft = styled(Wrapper)`
  width: 100%;
  margin-right: 25px;

  > a {
    ${ButtonPrimaryCSS};
    line-height: 12px;
  }

  span {
    margin-left: 8px;
  }
`;

OverviewHeaderLeft.defaultProps = {
  flex: true,
  middle: true,
  between: true,
};

const HeaderRight = styled(Wrapper)`
  ${MoreInfoContainer} {
    min-width: 460px;
  }
`;

const AvatarButton = styled(ButtonIcon)`
  padding: 4px;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.linkWater};
  }
`;

export const OverviewHeader: FC = ({ children }) => {
  const history = useHistory();
  const logout = useLogout();

  const userData = useSelector((state: RootState) => state.authReducer.user);

  const { unseenCount, unseenCollaborationCount } = useSelector(
    (state: RootState) => state.notificationsReducer
  );

  useNotifsCountCheck({
    interval: 15000,
  });

  return (
    <HeaderContainer flex middle between>
      {children}

      <HeaderRight flex middle>
        <MenuWithDropdown
          position="bottom right"
          trigger={
            <ButtonIcon
              data-badge-count={
                unseenCollaborationCount > 0
                  ? unseenCollaborationCount
                  : undefined
              }
            >
              <SVG icon={<MessageIcon />} color={osloGray} />
            </ButtonIcon>
          }
        >
          <MoreInfoContainer minWidth={300} padding="0px">
            <Notifications showCollaborationOnly />
          </MoreInfoContainer>
        </MenuWithDropdown>

        <Spacer w="20px" />

        <MenuWithDropdown
          position="bottom right"
          trigger={
            <ButtonIcon
              data-badge-count={unseenCount > 0 ? unseenCount : undefined}
            >
              <SVG icon={<NotificationIcon />} color={osloGray} />
            </ButtonIcon>
          }
        >
          <MoreInfoContainer minWidth={300} padding="0px">
            <Notifications showCollaborationOnly={false} />
          </MoreInfoContainer>
        </MenuWithDropdown>

        <Spacer w="20px" />

        <MenuWithDropdown
          trigger={
            <AvatarButton data-test="img-profile">
              {userData && <AvatarCircle user={userData} />}
            </AvatarButton>
          }
        >
          <MoreInfoContainer flex column alignStart minWidth={300}>
            <MoreInfoButton
              onClick={() => history.push('/overview/edit-user-details')}
              flex
              middle
              separator
              separatorColor={mischka}
            >
              <IconCircle icon={<PencilIcon />} size={42} />
              Edit Profile
            </MoreInfoButton>

            {/* PLAT-2822 | not implemented yet */}
            {false && (
              <MoreInfoButton flex middle separator separatorColor={mineShaft}>
                <IconCircle icon={<NotificationIcon />} size={42} />
                My Notifications
              </MoreInfoButton>
            )}

            <MoreInfoButton onClick={logout} flex middle>
              <IconCircle icon={<LogoutIcon />} size={42} />
              Log out
            </MoreInfoButton>
          </MoreInfoContainer>
        </MenuWithDropdown>
      </HeaderRight>
    </HeaderContainer>
  );
};
