export enum SynchronizationActionEnum {
  'Order Submit' = 1,
  'Order Update' = 2,
  'Change Status' = 3,
  'Cancellation Request' = 4,
  'Add Style' = 5,
  'Update Style' = 6,
  'Style Cancellation Request' = 7,
  'Add Line Item' = 8,
  'Update Line Item' = 9,
  'Delete Line Item' = 10,
  'Reorder Line Items' = 11,
}
