import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

import { clearCurtain } from 'curtain/store/curtainActions';
import { CurtainHeader } from 'curtain/components/CurtainHeader';

import {
  TopicFilterEnums,
  topicFlagOptions,
} from 'curtain/enums/TopicFilterEnums';

import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { H3 } from 'shared/components/Typography';
import { SelectBorderless } from 'shared/components/SelectBorderless';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { black } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import {
  ButtonIcon,
  ButtonPrimary,
  ButtonSecondary,
} from 'shared/components/Button';

import CurtainWorkableForm from './components/CurtainWorkableForm/CurtainWorkableForm';
import CurtainWorkableList from './components/CurtainWorkablesList/CurtainWorkableList';
import { CurtainWorkablesTabEnums } from './enums/CurtainWorkablesTabEnums';

import {
  clearWorkables,
  setWorkablesMode,
} from './store/curtainWorkablesActions';

const CurtainWorkablesTabContainer = styled.div`
  height: 100%;
  position: relative;
`;

const CurtainWorkablesTabActions = styled(Wrapper)`
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const CurtainWorkablesTab = () => {
  const dispatch = useAppDispatch();

  const mode = useSelector(
    (state: RootState) => state.curtainWorkablesReducer.mode
  );

  const [workableFilter, setWorkableFilter] = useState(
    UtilService.mapStringValueToSelectOptionObject(TopicFilterEnums.UNRESOLVED)
  );

  useEffect(() => {
    return () => {
      dispatch(clearWorkables());
    };
  }, []);

  return (
    <CurtainWorkablesTabContainer>
      <CurtainHeader flex middle between>
        <Wrapper flex middle>
          {mode === CurtainWorkablesTabEnums.FORM_MODE && (
            <>
              <ButtonIcon
                onClick={() =>
                  dispatch(setWorkablesMode(CurtainWorkablesTabEnums.LIST_MODE))
                }
              >
                <SVG icon={<ArrowLeftIcon />} />
              </ButtonIcon>
              <Spacer w="10px" />
            </>
          )}
          <H3>Workables</H3>
        </Wrapper>

        <Wrapper flex justifyEnd>
          {mode === CurtainWorkablesTabEnums.LIST_MODE && (
            <SelectBorderless
              bgColor="transparent"
              isSearchable={false}
              onChange={setWorkableFilter}
              options={topicFlagOptions}
              value={workableFilter}
            />
          )}

          <Spacer w="20px" />

          <ButtonIcon onClick={() => dispatch(clearCurtain())}>
            <SVG icon={<CloseIcon />} color={black} />
          </ButtonIcon>
        </Wrapper>
      </CurtainHeader>

      {mode === CurtainWorkablesTabEnums.LIST_MODE && (
        <CurtainWorkableList filterOption={workableFilter} />
      )}

      {mode === CurtainWorkablesTabEnums.FORM_MODE && <CurtainWorkableForm />}

      {mode !== CurtainWorkablesTabEnums.FORM_MODE && (
        <CurtainWorkablesTabActions flex justifyEnd>
          <ButtonSecondary onClick={() => dispatch(clearCurtain())}>
            Cancel
          </ButtonSecondary>
          <Spacer w="10px" />
          <ButtonPrimary
            onClick={() =>
              dispatch(setWorkablesMode(CurtainWorkablesTabEnums.FORM_MODE))
            }
          >
            Create New
          </ButtonPrimary>
        </CurtainWorkablesTabActions>
      )}
    </CurtainWorkablesTabContainer>
  );
};

export default CurtainWorkablesTab;
