import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import { RenderPageNotFound } from 'routes/RenderPageNotFound';

import { DashboardPage } from 'overview/pages/DashboardPage';
import { DealershipPage } from 'overview/dealership/pages/DealershipPage';
import { EditUserDetailsPage } from 'overview/editUserDetails/EditUserDetailsPage';
import { ManageUsersAddUserPage } from 'overview/manageUsers/ManageUsersAddUserPage';
import { ManageUsersEditUserPage } from 'overview/manageUsers/ManageUsersEditUserPage';
import { ManageUsersPage } from 'overview/manageUsers/ManageUsersPage';
import { OverviewSidebar } from 'overview/components/OverviewSidebar';
import { QuotePage } from 'overview/pages/QuotePage';

import { Page } from 'shared/components/Page';
import { Wrapper } from 'shared/components/Wrapper';
import { DealershipAccountPage } from './DealershipAccountPage';

const OverviewWrapper = styled(Wrapper)`
  padding-left: 244px;
`;

const OverviewContent = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export const OverviewPage = () => {
  return (
    <Page>
      <OverviewWrapper flex>
        <OverviewSidebar />

        <OverviewContent>
          <Switch>
            <Route exact path="/overview">
              <Redirect to="/overview/dashboard" />
            </Route>

            <Route path="/overview/dashboard" component={DashboardPage} />

            <Route path="/overview/quotes" component={QuotePage} />

            <Route
              exact
              path={['/overview/manage-users', '/overview/users']}
              component={ManageUsersPage}
            />
            <Route
              path="/overview/edit-user-details"
              component={EditUserDetailsPage}
            />
            <Route
              path="/overview/manage-users/add-user"
              component={ManageUsersAddUserPage}
            />
            <Route
              path="/overview/manage-users/:userId/edit-user"
              component={ManageUsersEditUserPage}
            />

            <Route
              path="/overview/dealership-settings"
              exact
              component={DealershipPage}
            />

            <Route
              path="/overview/dealerships"
              component={DealershipAccountPage}
            />

            <RenderPageNotFound />
          </Switch>
        </OverviewContent>
      </OverviewWrapper>
    </Page>
  );
};
