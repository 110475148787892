import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { getShipWeeks } from 'overview/store/overviewActions';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { RadioButton } from 'shared/components/RadioButton';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import UtilService from 'shared/services/util.service';

const SpanText = styled.span`
  padding: 10px;
`;

const Container = styled(Wrapper)`
  width: 100%;
  max-width: 190px;
`;

export interface ShipWeekFormValues {
  shipWeekOption: string;
  shipWeek: SelectOptionProps | null;
  endShipWeek: SelectOptionProps | null;
}

interface ShipWeekModalProps {
  opened: boolean;
  cancel: () => void;
  onSubmit: (form: ShipWeekFormValues) => void;
}

export enum RadioButtonNames {
  PRECISE_WEEK = 'preciseWeek',
  BETWEEN_WEEKS = 'betweenWeeks',
  AFTER_WEEK = 'afterWeek',
  BEFORE_WEEK = 'beforeWeek',
  ASAP = 'asap',
}

export enum ShipWeekFilterType {
  'preciseWeek' = 1,
  'betweenWeeks',
  'afterWeek',
  'beforeWeek',
  'asap',
}

export const ShipWeekModal = ({
  opened,
  cancel,
  onSubmit,
}: ShipWeekModalProps) => {
  const dispatch = useAppDispatch();

  const shipWeeks = useSelector((state: RootState) =>
    state.overviewReducer.shipWeeks
      ? (UtilService.mapObjectToSelectOptions(
          state.overviewReducer.shipWeeks,
          'shipWeekStartDate',
          'shipWeekDescription'
        ) as SelectOptionProps[])
      : null
  );

  const [shipWeeksLoading, setShipWeeksLoading] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState,
    control,
    clearErrors,
  } = useForm<ShipWeekFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const selectedShipWeekOption = watch('shipWeekOption');

  useEffect(() => {
    if (!selectedShipWeekOption) {
      setValue('shipWeekOption', RadioButtonNames.PRECISE_WEEK);
    } else {
      setValue('shipWeek', null);
      setValue('endShipWeek', null);
      clearErrors();
    }
  }, [selectedShipWeekOption]);

  useEffect(() => {
    if (opened && !shipWeeks) {
      setShipWeeksLoading(true);
      dispatch(getShipWeeks(setShipWeeksLoading));
    }
  }, [opened, shipWeeks]);

  const getShipWeeksOptions = () => {
    return shipWeeks?.map((item) => {
      return {
        ...item,
        label: `${item.label} (${new Date(item.value).getFullYear()})`,
      };
    });
  };

  return (
    <ModalWrapper
      modal
      closeOnDocumentClick={false}
      open={opened}
      closeOnEscape={false}
    >
      {(close: () => void) => (
        <Modal title="" withoutFooter={1} small>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper flexGrow>
              <Wrapper flex>
                <RadioButton
                  {...register('shipWeekOption', { required: true })}
                  name="shipWeekOption"
                  title="precise week"
                  value={RadioButtonNames.PRECISE_WEEK}
                  id={`shipWeek-${RadioButtonNames.PRECISE_WEEK}`}
                />
              </Wrapper>
              <Spacer h="16px" />
              {selectedShipWeekOption === RadioButtonNames.PRECISE_WEEK && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="shipWeek"
                        rules={{
                          required:
                            selectedShipWeekOption ===
                            RadioButtonNames.PRECISE_WEEK,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Ship week"
                            isDisabled={shipWeeksLoading}
                            options={getShipWeeksOptions()}
                            aria-invalid={
                              formState.errors.shipWeek ? 'true' : 'false'
                            }
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('shipWeekOption', { required: true })}
                  name="shipWeekOption"
                  title="between weeks"
                  value={RadioButtonNames.BETWEEN_WEEKS}
                  id={`shipWeek-${RadioButtonNames.BETWEEN_WEEKS}`}
                />
              </Wrapper>
              <Spacer h="16px" />

              {selectedShipWeekOption === RadioButtonNames.BETWEEN_WEEKS && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="shipWeek"
                        rules={{
                          required:
                            selectedShipWeekOption ===
                            RadioButtonNames.BETWEEN_WEEKS,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="From"
                            options={getShipWeeksOptions()}
                            aria-invalid={
                              formState.errors.shipWeek ? 'true' : 'false'
                            }
                          />
                        )}
                      />
                    </Container>

                    <Spacer w="10px" />
                    <SpanText>and</SpanText>
                    <Spacer w="10px" />

                    <Container>
                      <Controller
                        control={control}
                        name="endShipWeek"
                        rules={{
                          required:
                            selectedShipWeekOption ===
                            RadioButtonNames.BETWEEN_WEEKS,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="To"
                            options={getShipWeeksOptions()}
                            aria-invalid={
                              formState.errors.endShipWeek ? 'true' : 'false'
                            }
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('shipWeekOption', { required: true })}
                  title="after week"
                  value={RadioButtonNames.AFTER_WEEK}
                  id={`shipWeek-${RadioButtonNames.AFTER_WEEK}`}
                />
              </Wrapper>

              <Spacer h="16px" />

              {selectedShipWeekOption === RadioButtonNames.AFTER_WEEK && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="shipWeek"
                        rules={{
                          required:
                            selectedShipWeekOption ===
                            RadioButtonNames.AFTER_WEEK,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Ship week"
                            isDisabled={shipWeeksLoading}
                            options={getShipWeeksOptions()}
                            aria-invalid={
                              formState.errors.shipWeek ? 'true' : 'false'
                            }
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <Wrapper flex>
                <RadioButton
                  {...register('shipWeekOption', { required: true })}
                  name="shipWeekOption"
                  title="before week"
                  value={RadioButtonNames.BEFORE_WEEK}
                  id={`shipWeek-${RadioButtonNames.BEFORE_WEEK}`}
                />
              </Wrapper>

              <Spacer h="16px" />

              {selectedShipWeekOption === RadioButtonNames.BEFORE_WEEK && (
                <>
                  <Wrapper flex>
                    <Container>
                      <Controller
                        control={control}
                        name="shipWeek"
                        rules={{
                          required:
                            selectedShipWeekOption ===
                            RadioButtonNames.BEFORE_WEEK,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Ship week"
                            isDisabled={shipWeeksLoading}
                            options={getShipWeeksOptions()}
                            aria-invalid={
                              formState.errors.shipWeek ? 'true' : 'false'
                            }
                          />
                        )}
                      />
                    </Container>
                  </Wrapper>
                  <Spacer h="16px" />
                </>
              )}

              <RadioButton
                {...register('shipWeekOption', { required: true })}
                name="shipWeekOption"
                title="asap"
                value={RadioButtonNames.ASAP}
                id={`shipWeek-${RadioButtonNames.ASAP}`}
              />
              <Spacer h="32px" />

              <Wrapper flex justifyEnd>
                <ButtonSecondary onClick={cancel ?? close} type="button">
                  Cancel
                </ButtonSecondary>
                <Spacer w="16px" />
                <ButtonPrimary type="submit">Apply Filter</ButtonPrimary>
              </Wrapper>
            </Wrapper>
          </Form>
        </Modal>
      )}
    </ModalWrapper>
  );
};
