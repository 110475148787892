export enum OrderLineItemTestThreeDotMenuButtonEnum {
  DELETE_BTN = 'delete-btn',
  EDIT_OR_VIEW_BTN = 'edit-or-view-btn',
  THREE_DOT_MENU_BTN = 'three-dot-menu-btn',
  REPLACE_BTN = 'replace-btn',
  VIEW_BTN = 'view-btn',
}

export enum OrderLineItemTestButtonEnum {
  EXPAND_MODIFICATIONS_BTN = 'expand-modifications-btn',
}
