import styled from 'styled-components';

interface PageProps {
  overflowHidden?: boolean;
}

const Page = styled.div<PageProps>`
  min-height: 100vh;
  background: ${({ theme }) => theme.wildSand};

  ${({ overflowHidden }) => overflowHidden && 'overflow: hidden'};
`;

Page.defaultProps = {
  overflowHidden: false,
};

export { Page };
