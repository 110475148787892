import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { Style } from 'order/wizard/orderStyles/interface/Style';

import {
  OrderLineItem,
  OrderLineItemSM,
} from 'order/wizard/orderStyles/interface/LineItem';

import { IAttachment } from 'shared/interface/IAttachment';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { IModification } from '../components/Modifications/interface/IModification';
import { ILineItemCatalogItem } from '../interface/ILineItemCatalog';
import { ILineItemCategory } from '../interface/IlineItemCategory';

import {
  ConfigureLineItemRequest,
  ConfigureLineItemSMRequest,
  IConfigureLineItemMeta,
} from '../interface/IConfigureLineItem';

import {
  ILineItemDefaultData,
  ILineItemDefaultDataSM,
} from '../interface/ILineItemData';

export enum orderLineItemActions {
  CLEAR_LINE_ITEMS = 'CLEAR_LINE_ITEMS',

  SET_CURRENT_LINE_ITEM = 'SET_CURRENT_LINE_ITEM',
  SET_LINE_ITEMS_MODAL_OPENED = 'SET_LINE_ITEMS_MODAL_OPENED',

  // catalog categories
  GET_CATEGORIES = 'GET_CATEGORIES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_CATEGORY = 'SET_CATEGORY',

  GET_SUBCATEGORIES = 'GET_SUBCATEGORIES',
  SET_SUBCATEGORIES = 'SET_SUBCATEGORIES',
  SET_SUBCATEGORY = 'SET_SUBCATEGORY',

  GET_LINE_ITEM_CATALOG = 'GET_LINE_ITEM_CATALOG',
  SET_LINE_ITEM_CATALOG = 'SET_LINE_ITEM_CATALOG',

  // get line item for normal and sm order product type
  GET_LINE_ITEM_DEFAULT_DATA = 'GET_LINE_ITEM_DEFAULT_DATA',
  GET_LINE_ITEM_SM_DEFAULT_DATA = 'GET_LINE_ITEM_SM_DEFAULT_DATA',

  // set line item default data normal or sm
  SET_LINE_ITEM_DEFAULT_DATA = 'SET_LINE_ITEM_DEFAULT_DATA',

  GET_ORDER_LINE_ITEM_DATA = 'GET_ORDER_LINE_ITEM_DATA',
  GET_ORDER_LINE_ITEM_SM_DATA = 'GET_ORDER_LINE_ITEM_SM_DATA',
  SET_ORDER_LINE_ITEM_DATA = 'SET_ORDER_LINE_ITEM_DATA',

  SAVE_LINE_ITEM = 'SAVE_LINE_ITEM',
  UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM',

  // sm line items
  SAVE_LINE_ITEM_SM = 'SAVE_LINE_ITEM_SM',
  UPDATE_LINE_ITEM_SM = 'UPDATE_LINE_ITEM_SM',

  DELETE_LINE_ITEM = 'DELETE_LINE_ITEM',
  DUPLICATE_LINE_ITEM = 'DUPLICATE_LINE_ITEM',

  CHANGE_LINE_ITEM_CLIENT_NUMBER = 'CHANGE_LINE_ITEM_CLIENT_NUMBER',
  CHANGE_LINE_ITEM_NUMBERS = 'CHANGE_LINE_ITEM_NUMBERS',

  GET_LINE_ITEM_META_BY_WHD = 'GET_LINE_ITEM_META_BY_WHD',
  SET_LINE_ITEM_META_BY_WHD = 'SET_LINE_ITEM_META_BY_WHD',

  SET_LINE_ITEM_DOOR_CODES_BY_WHD = 'SET_LINE_ITEM_DOOR_CODES_BY_WHD',

  SET_LINE_ITEM_ATTACHMENTS = 'SET_LINE_ITEM_ATTACHMENTS',
  APPEND_SAVED_LINE_ITEM_ATTACHMENT = 'APPEND_SAVED_LINE_ITEM_ATTACHMENT',

  SET_MODIFICATIONS = 'SET_MODIFICATIONS',
  ADD_MODIFICATIONS = 'ADD_MODIFICATIONS',
  REMOVE_MODIFICATION = 'REMOVE_MODIFICATION',
  UPDATE_MODIFICATION = 'UPDATE_MODIFICATION',
  REORDER_MODIFICATIONS = 'REORDER_MODIFICATIONS',
  REPLACE_MODIFICATION = 'REPLACE_MODIFICATION',

  UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS',
  UPDATE_ATTACHMENT_NOTE = 'UPDATE_ATTACHMENT_NOTE',
  REMOVE_ATTACHMENT_FROM_LINE_ITEM = 'REMOVE_ATTACHMENT_FROM_LINE_ITEM',

  CLEAR_ATTACHMENTS_FOR_DELETE_LIST = 'CLEAR_ATTACHMENTS_FOR_DELETE_LIST',

  SAVE_LINE_ITEM_ATTACHMENTS = 'SAVE_LINE_ITEM_ATTACHMENTS',

  GET_LINE_ITEM_STYLE = 'GET_LINE_ITEM_STYLE',
  SET_LINE_ITEM_STYLE = 'SET_LINE_ITEM_STYLE',

  GET_AUTOMATIC_MODS = 'GET_AUTOMATIC_MODS',
  SET_AUTOMATIC_MODS = 'SET_AUTOMATIC_MODS',

  GET_VALID_MODS = 'GET_VALID_MODS',

  GET_MOD_PRICES = 'GET_MOD_PRICES',
  UPDATE_MOD_PRICES = 'UPDATE_MOD_PRICES',

  UPDATE_DIMUP_ON_ORDER_LINE_ITEM = 'UPDATE_DIMUP_ON_ORDER_LINE_ITEM',

  SET_IS_LINE_ITEMS_STEP_DIRTY = 'SET_IS_LINE_ITEMS_STEP_DIRTY',

  INCREMENT_LINE_ITEM_NUMBER = 'INCREMENT_LINE_ITEM_NUMBER',
}

export const clearLineItemsReducer = () => ({
  type: orderLineItemActions.CLEAR_LINE_ITEMS,
});

export const setCurrentLineItem = (lineItem: ILineItemCatalogItem | null) => ({
  type: orderLineItemActions.SET_CURRENT_LINE_ITEM,
  payload: lineItem,
});

export const setIsLineItemsStepDirty = (isDirty: boolean) => ({
  type: orderLineItemActions.SET_IS_LINE_ITEMS_STEP_DIRTY,
  payload: isDirty,
});

export const setLineItemsModalOpened = (modalOpened: string | null) => ({
  type: orderLineItemActions.SET_LINE_ITEMS_MODAL_OPENED,
  payload: modalOpened,
});

export interface CategoriesRequest {
  orderType: string;
  productLineId: string;
  styleId: string;
  doorOverlayId: string;
}

export const getCategories = (
  payload: CategoriesRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.GET_CATEGORIES,
  payload,
  loading,
});

export interface SubcategoriesRequest extends CategoriesRequest {
  categoryId: string;
}

export const getSubcategories = (
  payload: SubcategoriesRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.GET_SUBCATEGORIES,
  payload,
  loading,
});

export const setCategories = (categories: ILineItemCategory[]) => ({
  type: orderLineItemActions.SET_CATEGORIES,
  payload: categories,
});

export const setCategory = (selectedCategory: ILineItemCategory | null) => ({
  type: orderLineItemActions.SET_CATEGORY,
  payload: selectedCategory,
});

export const setSubcategories = (
  subcategories: ILineItemCategory[] | null
) => ({
  type: orderLineItemActions.SET_SUBCATEGORIES,
  payload: subcategories,
});

export const setSubcategory = (
  selectedSubcategory: ILineItemCategory | null
) => ({
  type: orderLineItemActions.SET_SUBCATEGORY,
  payload: selectedSubcategory,
});

export interface LineItemCatalogRequest {
  productLineId: string;
  page: string;
  itemsPerPage: string;
  searchTerm: string;
  categoryId?: string;
  subCategoryId?: string;
  orderType: string;
  appendItems: boolean;
  styleId?: string;
  doorOverlayId?: string;
}

export const getLineItemCatalog = (
  payload: LineItemCatalogRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.GET_LINE_ITEM_CATALOG,
  payload,
  onSuccess,
  loading,
});

export interface LineItemCatalog {
  lineItemCatalog: PaginatedItems<ILineItemCatalogItem>;
  appendItems: boolean;
}

export const setLineItemCatalog = (payload: LineItemCatalog | null) => ({
  type: orderLineItemActions.SET_LINE_ITEM_CATALOG,
  payload,
});

// export const setLineItemCatalog = (
//   lineItemCatalog: PaginatedItems<ILineItemCatalogItem>
// ) => ({
//   type: orderLineItemActions.SET_LINE_ITEM_CATALOG,
//   payload: lineItemCatalog,
// });

export interface LineItemDefaultDataRequest {
  lineItemId: string;
  styleId: string;
  productLineId: string;
}

interface GetLineItemDefaultDataOptions {
  onFailed?: () => void;
  onSuccess?: () => void;
}

export const getLineItemDefaultData = (
  payload: LineItemDefaultDataRequest,
  loading: (isLoading: boolean) => void,
  options?: GetLineItemDefaultDataOptions
) => ({
  type: orderLineItemActions.GET_LINE_ITEM_DEFAULT_DATA,
  payload,
  loading,
  onFailed: options?.onFailed,
  onSuccess: options?.onSuccess,
});

export interface LineItemSMDefaultDataRequest {
  lineItemId: string;
  productLineId: string;
}

export const getLineItemSMDefaultData = (
  payload: LineItemSMDefaultDataRequest,
  loading: (isLoading: boolean) => void,
  onFailed?: () => void
) => ({
  type: orderLineItemActions.GET_LINE_ITEM_SM_DEFAULT_DATA,
  payload,
  loading,
  onFailed,
});

// set line item default data for normal or sm line item
export const setLineItemDefaultData = (
  lineItemDefaultData: ILineItemDefaultData | ILineItemDefaultDataSM | null
) => ({
  type: orderLineItemActions.SET_LINE_ITEM_DEFAULT_DATA,
  payload: lineItemDefaultData,
});

export interface OrderLineItemDataRequest {
  orderId: string;
  orderLineItemId: string;
  productLineId?: string;
}

export const getOrderLineItemData = (
  payload: OrderLineItemDataRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.GET_ORDER_LINE_ITEM_DATA,
  payload,
  loading,
});

export const getOrderLineItemSMData = (
  payload: OrderLineItemDataRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.GET_ORDER_LINE_ITEM_SM_DATA,
  payload,
  loading,
});

export const setOrderLineItemData = (
  orderLineItemData: OrderLineItem | OrderLineItemSM | null
) => ({
  type: orderLineItemActions.SET_ORDER_LINE_ITEM_DATA,
  payload: orderLineItemData,
});

export const saveLineItem = (
  payload: ConfigureLineItemRequest,
  onSuccess?: (savedLineItem: string) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderLineItemActions.SAVE_LINE_ITEM,
  payload,
  onSuccess,
  onFailed,
});

export const updateLineItem = (
  payload: ConfigureLineItemRequest,
  onSuccess: (updatedOrderLineItemId: string) => void,
  onFailed: (err: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.UPDATE_LINE_ITEM,
  payload,
  onSuccess,
  onFailed,
  loading,
});

// save sm line item
export const saveLineItemSM = (
  payload: ConfigureLineItemSMRequest,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.SAVE_LINE_ITEM_SM,
  payload,
  onSuccess,
  loading,
});

// update sm line item
export const updateLineItemSM = (
  payload: ConfigureLineItemSMRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.UPDATE_LINE_ITEM_SM,
  payload,
  onSuccess,
  loading,
});

export interface LineItemDeleteRequest {
  orderId: string;
  lineItemId: string;
}

export const deleteLineItem = (
  payload: LineItemDeleteRequest,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: orderLineItemActions.DELETE_LINE_ITEM,
  payload,
  onSuccess,
  onFailed,
});

export interface LineItemDuplicateRequest {
  orderId: string;
  lineItemId: string;
}

export const duplicateLineItem = (
  payload: LineItemDuplicateRequest,
  onSuccess?: () => void,
  onFailed?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.DUPLICATE_LINE_ITEM,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface LineItemClientNumberRequest {
  lineItemId: string;
  orderId: string;
  clientNumber: string | null;
}

export const changeLineItemClientNumber = (
  payload: LineItemClientNumberRequest,
  onFailed?: () => void,
  onSuccess?: () => void
) => ({
  type: orderLineItemActions.CHANGE_LINE_ITEM_CLIENT_NUMBER,
  payload,
  onFailed,
  onSuccess,
});

export interface LineItemChangeNumbersProps {
  lineItemId: string;
  number: number;
}
export interface LineItemChangeNumbersRequest {
  orderId: string;
  lineItemChangeNumbers: LineItemChangeNumbersProps[];
}

export const changeLineItemNumbers = (
  payload: LineItemChangeNumbersRequest,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: orderLineItemActions.CHANGE_LINE_ITEM_NUMBERS,
  payload,
  onSuccess,
  onFailed,
});

export interface LineItemMetaByWHDRequest {
  lineItemId: string;
  productLineId: string;
  styleId?: string;
  width: number;
  height: number;
  depth: number;
  rightWidth?: number;
  rightDepth?: number;
}

export const getLineItemMetaByWHD = (
  payload: LineItemMetaByWHDRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: orderLineItemActions.GET_LINE_ITEM_META_BY_WHD,
  payload,
  loading,
  onSuccess,
});

export const setLineItemMetaByWHD = (
  payload: IConfigureLineItemMeta | null
) => ({
  type: orderLineItemActions.SET_LINE_ITEM_META_BY_WHD,
  payload,
});

export const setLineItemDoorCodesByWHD = (payload: IDoorCode | null) => ({
  type: orderLineItemActions.SET_LINE_ITEM_DOOR_CODES_BY_WHD,
  payload,
});

export const setLineItemAttachments = (payload: IAttachment[]) => ({
  type: orderLineItemActions.SET_LINE_ITEM_ATTACHMENTS,
  payload,
});

export const appendSavedLineItemAttachment = (payload: IAttachment[]) => ({
  type: orderLineItemActions.APPEND_SAVED_LINE_ITEM_ATTACHMENT,
  payload,
});

export const setModifications = (payload: IModification[] | null) => ({
  type: orderLineItemActions.SET_MODIFICATIONS,
  payload,
});

export const addModifications = (payload: IModification[]) => ({
  type: orderLineItemActions.ADD_MODIFICATIONS,
  payload,
});

export const removeLineItemModification = (modId: string) => ({
  type: orderLineItemActions.REMOVE_MODIFICATION,
  payload: modId,
});

export const updateLineItemModification = (modification: IModification) => ({
  type: orderLineItemActions.UPDATE_MODIFICATION,
  payload: modification,
});

export const replaceLineItemModification = (
  modification: IModification,
  modificationToReplaceId: string
) => ({
  type: orderLineItemActions.REPLACE_MODIFICATION,
  payload: { modification, modificationToReplaceId },
});

export const reorderLineItemModifications = (
  modifications: IModification[]
) => ({
  type: orderLineItemActions.REORDER_MODIFICATIONS,
  payload: modifications,
});

export const updateLineItemAttachmentNote = (attachment: IAttachment) => ({
  type: orderLineItemActions.UPDATE_ATTACHMENT_NOTE,
  payload: attachment,
});

export const removeLineItemAttachment = (attachmentId: string) => ({
  type: orderLineItemActions.REMOVE_ATTACHMENT_FROM_LINE_ITEM,
  payload: attachmentId,
});

export const clearAttachmentsForDeleteList = () => ({
  type: orderLineItemActions.CLEAR_ATTACHMENTS_FOR_DELETE_LIST,
});

export interface SaveLineItemAttachmentsRequest {
  orderId: string;
  orderLineItemId: string;
  attachments: IAttachment[];
}

export const saveLineItemAttachments = (
  payload: SaveLineItemAttachmentsRequest,
  onSuccess?: (savedAttachmentsCount?: number) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderLineItemActions.SAVE_LINE_ITEM_ATTACHMENTS,
  payload,
  onSuccess,
  loading,
});

export interface GetLineItemStyleRequest {
  orderId: string;
  styleId: string;
}

export const getLineItemStyle = (payload: GetLineItemStyleRequest) => ({
  type: orderLineItemActions.GET_LINE_ITEM_STYLE,
  payload,
});

export const setLineItemStyle = (style: Style) => ({
  type: orderLineItemActions.SET_LINE_ITEM_STYLE,
  payload: style,
});

export interface AutomaticModificationsRequest {
  depth: string;
  height: string;
  lineitemId: string;
  lineItemPrice: string;
  productLineId: string;
  styleId: string;
  width: string;
}

export const getAutomaticModifications = (
  payload: AutomaticModificationsRequest
) => ({
  type: orderLineItemActions.GET_AUTOMATIC_MODS,
  payload,
});

export const setAutomaticModifications = (modifications: IModification[]) => ({
  type: orderLineItemActions.SET_AUTOMATIC_MODS,
  payload: modifications,
});

export interface ModificationPricesRequest {
  productLineId: string;
  modificationIds: string[];
  lineitemId: string;
  lineItemPrice: string;
  width: string;
  height: string;
  depth: string;
  styleId?: string;
}

export const getModificationPrices = (payload: ModificationPricesRequest) => ({
  type: orderLineItemActions.GET_MOD_PRICES,
  payload,
});

export const updateModificationPrices = (
  modPrices: Pick<IModification, 'id' | 'price'>[]
) => ({
  type: orderLineItemActions.UPDATE_MOD_PRICES,
  payload: modPrices,
});

export const updateOrderLineItemDimUp = (
  dimup: Pick<OrderLineItem, 'dimUpcharge' | 'dimUpchargePrice'>
) => ({
  payload: dimup,
  type: orderLineItemActions.UPDATE_DIMUP_ON_ORDER_LINE_ITEM,
});

export interface ValidModsRequest {
  catalogLineItemId: string;
  existingLineItemId: string;
  orderId: string;
}

export const getValidMods = (payload: ValidModsRequest) => ({
  type: orderLineItemActions.GET_VALID_MODS,
  payload,
});

export const incrementLineItemNumber = (payload: boolean) => ({
  type: orderLineItemActions.INCREMENT_LINE_ITEM_NUMBER,
  payload,
});
