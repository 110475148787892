import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  getWritingHints,
  setWritingHints,
} from 'order/writingHints/store/writingHintsActions';

import { nevada } from 'shared/config/Colors';
import { PSmall } from 'shared/components/Typography';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';

import { ItemRow } from '../ItemRow';
import { StyleNotesContainer } from './components/StyleNotesContainer';

const WritingHintsContainer = styled.div`
  height: calc(100% - 95px);
`;

const Header = styled(PSmall)`
  padding: 16px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.athensGray};
`;

const WritingHints = () => {
  const { orderId } = useParams<OrderPageParams>();

  const [writingHintsLoading, setWritingHintsLoading] = useState(true);

  const writingHints = useSelector(
    (state: RootState) => state.writingHintsReducer.writingHints
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWritingHints(orderId, setWritingHintsLoading));

    return () => {
      dispatch(setWritingHints(null));
    };
  }, []);

  return writingHintsLoading ? (
    <Loader />
  ) : (
    <WritingHintsContainer>
      <ScrollbarsCustom autoHide autoHideDuration={300} autoHideTimeout={500}>
        {(writingHints?.dealershipPreferencesResponse.length ?? 0) > 0 ? (
          <>
            <Header bold>Dealer Notices for this Order</Header>

            {writingHints?.dealershipPreferencesResponse.map(
              (preference, index) => (
                <ItemRow>
                  <PSmall color={nevada}>{index + 1}</PSmall>
                  <PSmall color={nevada}>{preference}</PSmall>
                </ItemRow>
              )
            )}
          </>
        ) : null}

        {writingHints?.orderNotesResponse.styleNoteResponses.map(
          (styleNotes) => {
            return (
              <StyleNotesContainer
                styleNotes={styleNotes}
                writingHints={writingHints}
              />
            );
          }
        )}
      </ScrollbarsCustom>
    </WritingHintsContainer>
  );
};

export default WritingHints;
