import { OrderFormFields } from 'order/wizard/orderForm/OrderForm';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Checkbox } from 'shared/components/Checkbox';
import { Spacer } from 'shared/components/Layout';
import { H4, P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { RootState } from 'store';
import styled from 'styled-components';

const AddressDetailsContainer = styled.div`
  margin-top: 15px;
  ${H4} {
    margin-bottom: 10px;
  }

  ${P} {
    font-size: 14px;
  }
`;

const Upcharge = styled(P)`
  color: ${({ theme }) => theme.fern};
`;

interface AddressDetailsProps {
  address: ShippingAddress;
}

const AddressDetails: FC<AddressDetailsProps> = ({ address }) => {
  const methodsContext = useFormContext<OrderFormFields>();

  const jobSiteDeliveryWatched = methodsContext.watch('jobSiteDelivery');
  const transportationType = methodsContext.watch('transportationType');

  const jobSiteUpcharge = useSelector(
    (state: RootState) => state.dealershipReducer.priceVariablesGlobal?.jobSite
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  // reset job site delivery when transportation type is changed
  useEffect(() => {
    if (methodsContext.formState.isDirty) {
      methodsContext.setValue('jobSiteDelivery', false);
    }
  }, [transportationType]);

  return (
    <Wrapper flexGrow>
      <Spacer h="20px" />

      <Checkbox
        {...methodsContext.register('jobSiteDelivery')}
        id="job-site-delivery"
        title="Job Site Delivery $"
        readOnly={!canEdit}
      />

      <AddressDetailsContainer>
        <H4>{address.shipTo}</H4>
        <P>{address.street}</P>
        <P>{address.number} </P>
        <P>
          {address.city}, {address.state} {address.zipCode}
        </P>
        <P>{address.phoneNumber}</P>
        {jobSiteUpcharge && jobSiteDeliveryWatched && (
          <Upcharge>Upcharge: + ${jobSiteUpcharge}</Upcharge>
        )}
      </AddressDetailsContainer>
    </Wrapper>
  );
};

export default AddressDetails;
