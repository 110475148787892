import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useOrderFlow = (
  page: 'asap' | 'duplicate'
): [boolean | null, string] => {
  const location = useLocation();
  const [isPage, setIsPage] = useState<boolean | null>(null);

  useEffect(() => {
    const { 2: currentPage } = location.pathname.split('/');
    setIsPage(currentPage === page);
  }, [location]);

  return [isPage, page];
};
