import {
  CurtainCommentActionPayload,
  DeleteCommentRequest,
  UpdateCommentRequest,
} from 'curtain/interfaces/CurtainCommentActionPayload';
import { CurtainGetOtherTopicsActionPayload } from 'curtain/interfaces/CurtainGetOtherTopicsActionPayload';
import { IChannel } from 'curtain/interfaces/IChannel';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { IGetChannelsRequest } from 'curtain/interfaces/IGetChannelsRequest';
import { IGetTopicsRequest } from 'curtain/interfaces/IGetTopicsRequest';
import { TopicFilterEnums } from 'curtain/enums/TopicFilterEnums';

import {
  CreateTopicRequest,
  IAttachment,
  UpdateTopicRequest,
} from 'curtain/interfaces/CreateTopicRequest';

import { CurtainComment } from 'shared/interface/CurtainComment';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { NotifyEntity } from '../interfaces/NotifyEntity';
import { Order } from '../../order/interfaces/Order';

export enum curtainActions {
  // tabs
  SET_CURTAIN_TAB = 'SET_CURTAIN_TAB',

  GET_COMMENTS = 'GET_COMMENTS',
  SET_COMMENTS = 'SET_COMMENTS',
  SET_SINGLE_CURTAIN_COMMENT = 'SET_SINGLE_CURTAIN_COMMENT',
  ADD_COMMENT = 'ADD_COMMENT',
  SET_CURTAIN_ACTIVE_TOPIC = 'SET_CURTAIN_ACTIVE_TOPIC',
  GET_OTHER_TOPICS = 'GET_OTHER_TOPICS',
  SET_OTHER_TOPICS = 'SET_OTHER_TOPICS',
  SET_ACTIVE_TOPIC_TO_RESOLVED = 'SET_ACTIVE_TOPIC_TO_RESOLVED',
  SET_ACTIVE_TOPIC_TO_UNRESOLVED = 'SET_ACTIVE_TOPIC_TO_UNRESOLVED',
  UPLOAD_FILE = 'UPLOAD_FILE',
  CLEAR_CURTAIN = 'CLEAR_CURTAIN',
  IS_ADDING_TOPIC = 'IS_ADDING_TOPIC',
  SET_CREATE_TOPIC = 'SET_CREATE_TOPIC',
  SET_ACTIVE_TOPIC_ID = 'SET_ACTIVE_TOPIC_ID',
  SET_ACTIVE_STYLE_ID = 'SET_ACTIVE_STYLE_ID',
  SET_ACTIVE_LINE_ITEM_ID = 'SET_ACTIVE_LINE_ITEM_ID',
  SET_ACTIVE_ENTITY_ID = 'SET_ACTIVE_ENTITY_ID',
  SET_ACTIVE_ENTITY_NAME = 'SET_ACTIVE_ENTITY_NAME',
  SET_ACTIVE_CHANNEL = 'SET_ACTIVE_CHANNEL',
  GET_TOPICS_BY_CHANNEL = 'GET_TOPICS_BY_CHANNEL',
  GET_TOPICS_BY_ENTITY = 'GET_TOPICS_BY_ENTITY',
  SET_TOPICS = 'SET_TOPICS',
  RESOLVE_TOPIC = 'RESOLVE_TOPIC',
  UNRESOLVE_TOPIC = 'UNRESOLVE_TOPIC',
  SET_TOPIC_TO_RESOLVED = 'SET_TOPIC_TO_RESOLVED',
  SET_TOPIC_TO_UNRESOLVED = 'SET_TOPIC_TO_UNRESOLVED',
  CREATE_TOPIC = 'CREATE_TOPIC',
  GET_CHANNELS = 'GET_CHANNELS',
  SET_STYLE_CHANNELS = 'SET_STYLE_CHANNELS',
  SET_LINE_ITEM_CHANNELS = 'SET_LINE_ITEM_CHANNELS',
  SET_TOPIC_TYPE_DEFAULT_VALUE = 'SET_TOPIC_TYPE_DEFAULT_VALUE',
  SET_TOPIC_ITEM_DEFAULT_VALUE = 'SET_TOPIC_ITEM_DEFAULT_VALUE',
  SET_TOPICS_FILTER = 'SET_TOPICS_FILTER',
  CHANGE_TOPIC_PRIORITY = 'CHANGE_TOPIC_PRIORITY',
  SET_TOPIC_PRIORITY = 'SET_TOPIC_PRIORITY',
  SET_STYLE_CHANNEL_PRIORITY = 'SET_STYLE_CHANNEL_PRIORITY',
  SET_LINEITEM_CHANNEL_PRIORITY = 'SET_LINEITEM_CHANNEL_PRIORITY',
  SET_REFRESH_PRIORITY = 'SET_REFRESH_PRIORITY',

  SET_BACK_TO_CHANNEL = 'SET_BACK_TO_CHANNEL',

  SET_ACTIVE_ENTITY_TYPE = 'SET_ACTIVE_ENTITY_TYPE',

  GET_NOT_SENT_TOPICS = 'GET_NOT_SENT_TOPICS',
  NOTIFY_COLLABORTORS = 'NOTIFY_COLLABORTORS',

  DOWNLOAD_COLLABORATION = 'DOWNLOAD_COLLABORATION',

  MARK_TOPIC_READ = 'MARK_TOPIC_READ',
  GET_NUMBER_OF_UNREAD_TOPICS = 'GET_NUMBER_OF_UNREAD_TOPICS',
  SET_NUMBER_OF_UNREAD_TOPICS = 'SET_NUMBER_OF_UNREAD_TOPICS',

  SET_UPDATE_TOPIC = 'SET_UPDATE_TOPIC',
  SET_UPDATE_COMMENT = 'SET_UPDATE_COMMENT',

  UPDATE_TOPIC = 'UPDATE_TOPIC',
  DELETE_TOPIC = 'DELETE_TOPIC',

  UPDATE_COMMENT = 'UPDATE_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
}

export const clearCurtain = () => ({
  type: curtainActions.CLEAR_CURTAIN,
});

export const setCurtainTab = (tab: string) => ({
  type: curtainActions.SET_CURTAIN_TAB,
  payload: tab,
});

export const setActiveTopic = (activeTopic: ICurtainTopic | null) => ({
  type: curtainActions.SET_CURTAIN_ACTIVE_TOPIC,
  payload: activeTopic,
});

export const getOtherTopics = (
  payload: CurtainGetOtherTopicsActionPayload,
  onSuccess: () => void
) => ({
  type: curtainActions.GET_OTHER_TOPICS,
  payload,
  onSuccess,
});

export const setOtherTopics = (data: ICurtainTopic[] | null) => ({
  type: curtainActions.SET_OTHER_TOPICS,
  payload: data,
});

export const resolveTopic = (
  topicId: string,
  onSuccess: (topicId: string) => void,
  onFailed: () => void
) => ({
  type: curtainActions.RESOLVE_TOPIC,
  payload: topicId,
  onSuccess,
  onFailed,
});

export const unresolveTopic = (
  topicId: string,
  onSuccess: (topicId: string) => void,
  onFailed: () => void
) => ({
  type: curtainActions.UNRESOLVE_TOPIC,
  payload: topicId,
  onSuccess,
  onFailed,
});

export const setActiveTopicToResolved = () => ({
  type: curtainActions.SET_ACTIVE_TOPIC_TO_RESOLVED,
});

export const setActiveTopicToUnresolved = () => ({
  type: curtainActions.SET_ACTIVE_TOPIC_TO_UNRESOLVED,
});

export const getCurtainComments = (
  topicID: string,
  loading: (isLoading: boolean) => void,
  onFailed: () => void
) => ({
  type: curtainActions.GET_COMMENTS,
  payload: topicID,
  loading,
  onFailed,
});

export const setCurtainComments = (payload: CurtainComment[] | null) => ({
  type: curtainActions.SET_COMMENTS,
  payload,
});

export const setSingleCurtainComment = (payload: CurtainComment) => ({
  type: curtainActions.SET_SINGLE_CURTAIN_COMMENT,
  payload,
});

export const addCurtainComment = (
  payload: CurtainCommentActionPayload,
  onSuccess: (topicId?: string) => void,
  onFailed: () => void
) => ({
  type: curtainActions.ADD_COMMENT,
  payload,
  onSuccess,
  onFailed,
});

export const uploadFiles = (
  data: File[],
  onSuccess: (uploadedFiles: FileUploadResponse[]) => void,
  onFailed: () => void,
  loading?: (loading: boolean) => void
) => ({
  type: curtainActions.UPLOAD_FILE,
  payload: data,
  onSuccess,
  onFailed,
  loading,
});

export const isAddingTopic = (payload: boolean) => ({
  type: curtainActions.IS_ADDING_TOPIC,
  payload,
});

export const setCreateTopic = (payload: boolean) => ({
  type: curtainActions.SET_CREATE_TOPIC,
  payload,
});

export const setActiveEntityType = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_ENTITY_TYPE,
  payload,
});

export const setActiveTopicID = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_TOPIC_ID,
  payload,
});

export const setActiveStyleID = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_STYLE_ID,
  payload,
});

export const setActiveLineItemID = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_LINE_ITEM_ID,
  payload,
});

export const setActiveEntityID = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_ENTITY_ID,
  payload,
});
export const setActiveEntityName = (payload: string | null) => ({
  type: curtainActions.SET_ACTIVE_ENTITY_NAME,
  payload,
});
export const setActiveChannel = (payload: IChannel | null) => ({
  type: curtainActions.SET_ACTIVE_CHANNEL,
  payload,
});

export const getTopicsByChannel = (
  payload: IGetTopicsRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: curtainActions.GET_TOPICS_BY_CHANNEL,
  payload,
  loading,
});

export const getTopicsByEntity = (
  payload: { orderId: string; entityId: string },
  onSuccess?: (channels: IChannel[]) => void,
  onFailed?: () => void
) => ({
  type: curtainActions.GET_TOPICS_BY_ENTITY,
  payload,
  onSuccess,
  onFailed,
});

export const setTopics = (payload: ICurtainTopic[]) => ({
  type: curtainActions.SET_TOPICS,
  payload,
});

export interface TopicResolvePayload {
  topicId: string;
  isOrderSubmitted: boolean;
  other?: boolean;
}

export const setTopicToResolved = (payload: TopicResolvePayload) => ({
  type: curtainActions.SET_TOPIC_TO_RESOLVED,
  payload,
});

export const setTopicToUnresolved = (payload: TopicResolvePayload) => ({
  type: curtainActions.SET_TOPIC_TO_UNRESOLVED,
  payload,
});

export const createTopic = (
  data: CreateTopicRequest,
  onSuccess: (topicId: string) => void,
  onFailed: (attachments: IAttachment[]) => void
) => ({
  type: curtainActions.CREATE_TOPIC,
  payload: data,
  onSuccess,
  onFailed,
});

export const getChannels = (
  data: IGetChannelsRequest,
  onSuccess: (channels: IChannel[]) => void,
  onFailed: () => void
) => ({
  type: curtainActions.GET_CHANNELS,
  payload: data,
  onSuccess,
  onFailed,
});

export const setStyleChannels = (channels: IChannel[]) => ({
  type: curtainActions.SET_STYLE_CHANNELS,
  payload: channels,
});

export const setLineItemChannels = (channels: IChannel[]) => ({
  type: curtainActions.SET_LINE_ITEM_CHANNELS,
  payload: channels,
});

export const setTopicTypeDefaultValue = (
  topicTypeValue: SelectOptionProps | null
) => ({
  type: curtainActions.SET_TOPIC_TYPE_DEFAULT_VALUE,
  payload: topicTypeValue,
});

export const setTopicItemDefaultValue = (
  topicItemValue: SelectOptionProps | null
) => ({
  type: curtainActions.SET_TOPIC_ITEM_DEFAULT_VALUE,
  payload: topicItemValue,
});

export const setTopicsFilter = (filter: TopicFilterEnums) => ({
  type: curtainActions.SET_TOPICS_FILTER,
  payload: filter,
});

export const changeTopicPriority = (
  topicId: string,
  priority: number,
  onSuccess: () => void,
  onFailed: () => void
) => ({
  type: curtainActions.CHANGE_TOPIC_PRIORITY,
  payload: {
    topicId,
    priority,
  },
  onSuccess,
  onFailed,
});

export const setTopicPriority = (topicId: string, priority: number) => ({
  type: curtainActions.SET_TOPIC_PRIORITY,
  payload: {
    topicId,
    priority,
  },
});

export const setStyleChannelPriority = (
  channelId: string,
  priority: number
) => ({
  type: curtainActions.SET_STYLE_CHANNEL_PRIORITY,
  payload: {
    channelId,
    priority,
  },
});

export const setLineItemChannelPriority = (
  channelId: string,
  priority: number
) => ({
  type: curtainActions.SET_LINEITEM_CHANNEL_PRIORITY,
  payload: {
    channelId,
    priority,
  },
});

export const setRefreshPriority = (payload: boolean) => ({
  type: curtainActions.SET_REFRESH_PRIORITY,
  payload,
});

export const setBackToChanel = (payload: IChannel | null) => ({
  type: curtainActions.SET_BACK_TO_CHANNEL,
  payload,
});

export const getNotSentTopics = (
  orderId: string,
  onSuccess: (topics: NotifyEntity[]) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: curtainActions.GET_NOT_SENT_TOPICS,
  payload: orderId,
  onSuccess,
  loading,
});

export const notifyCollaborators = (
  orderId: string,
  onSuccess: () => void,
  onFailed: (error: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: curtainActions.NOTIFY_COLLABORTORS,
  payload: orderId,
  onSuccess,
  onFailed,
  loading,
});

export const downloadCollaboration = (order: Order) => ({
  type: curtainActions.DOWNLOAD_COLLABORATION,
  payload: order,
});

export const markTopicAsRead = (
  topicId: string,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: curtainActions.MARK_TOPIC_READ,
  payload: topicId,
  onSuccess,
  onFailed,
});

export const getNumberOfUnreadTopics = (
  orderId: string,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: curtainActions.GET_NUMBER_OF_UNREAD_TOPICS,
  payload: orderId,
  onSuccess,
  onFailed,
});

export const setNumberOfUnreadTopics = (payload: number) => ({
  type: curtainActions.SET_NUMBER_OF_UNREAD_TOPICS,
  payload,
});

export const setUpdatedTopic = (payload: ICurtainTopic | null) => ({
  type: curtainActions.SET_UPDATE_TOPIC,
  payload,
});

export const setUpdatedComment = (payload: CurtainComment | null) => ({
  type: curtainActions.SET_UPDATE_COMMENT,
  payload,
});

export const updateTopic = (
  data: UpdateTopicRequest,
  onSuccess: () => void,
  onFailed: (attachments: IAttachment[]) => void
) => ({
  type: curtainActions.UPDATE_TOPIC,
  payload: data,
  onSuccess,
  onFailed,
});

export const deleteTopic = (
  topicId: string,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: curtainActions.DELETE_TOPIC,
  payload: topicId,
  onSuccess,
  onFailed,
});

export const updateComment = (
  payload: UpdateCommentRequest,
  onSuccess: () => void,
  onFailed: () => void
) => ({
  type: curtainActions.UPDATE_COMMENT,
  payload,
  onSuccess,
  onFailed,
});

export const deleteComment = (
  payload: DeleteCommentRequest,
  onSuccess?: () => void,
  onFailed?: () => void
) => ({
  type: curtainActions.DELETE_COMMENT,
  payload,
  onSuccess,
  onFailed,
});
