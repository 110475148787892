import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { getAvailableDealerships } from 'overview/dealership/store/dealershipActions';

import { useAppDispatch } from './useAppDispatch';

export const useGetAvailableDealershipId = () => {
  const dispatch = useAppDispatch();

  const [dealershipId, setDealershipId] = useState<string | null>(null);

  const availableDealerships = useSelector(
    (state: RootState) => state.dealershipReducer.availableDealerships
  );

  const availableDealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const order = useSelector((state: RootState) => state.orderReducer.order);

  useEffect(() => {
    if (availableDealerships === null) {
      dispatch(getAvailableDealerships());
    }
  }, [availableDealerships]);

  useEffect(() => {
    if (availableDealerships?.length > 1) {
      setDealershipId(order?.job?.dealershipId ?? null);
    } else {
      setDealershipId(
        availableDealership?.id ?? order?.job?.dealershipId ?? null
      );
    }
  }, [availableDealership, order]);

  return dealershipId;
};
