import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { useParams } from 'react-router';

export const useUpchargeAvailableOrders = () => {
  const { orderId } = useParams<OrderPageParams>();

  const checkIsOrderInLocalStorage = () => {
    const orders: string[] =
      JSON.parse(localStorage.getItem('orders_upcharges_available') || '[]') ||
      [];

    return orders.some((item: string) => item === orderId);
  };

  const removeOrderInLocalStorage = () => {
    let orders: string[] = [];
    orders =
      JSON.parse(localStorage.getItem('orders_upcharges_available') || '[]') ||
      [];
    const newOrders = orders.filter((item) => item !== orderId);
    localStorage.setItem(
      'orders_upcharges_available',
      JSON.stringify(newOrders)
    );
  };

  const saveOrderInLocalStorage = () => {
    let orders: string[] = [];
    orders =
      JSON.parse(localStorage.getItem('orders_upcharges_available') || '[]') ||
      [];
    orders.push(orderId);
    localStorage.setItem('orders_upcharges_available', JSON.stringify(orders));
  };

  return [
    checkIsOrderInLocalStorage,
    removeOrderInLocalStorage,
    saveOrderInLocalStorage,
  ] as const;
};
