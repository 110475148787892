import { Switch, Route, Redirect } from 'react-router';

import { NotFoundPage } from 'auth/pages/NotFoundPage';

import { KeyCloakLogin } from './KeyCloakLogin';

const Auth = () => {
  return (
    <Switch>
      <Route path="/authentication/login" component={KeyCloakLogin} />

      <Redirect exact from="/authentication" to="/authentication/login" />

      <Route path="**">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Auth;
