import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FC } from 'react';
import { white } from 'shared/config/Colors';
import SVG from './SVG';

interface CloseButtonProps {
  color?: string;
}

const CloseButtonContainer = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 15px;
  padding: 0;
  width: 20px;

  &:hover {
    .icon {
      transform: rotate(180deg);
    }
  }
`;

const CloseButton: FC<CloseButtonProps> = ({ color }) => {
  return (
    <CloseButtonContainer>
      <SVG icon={<CloseIcon />} color={color} />
    </CloseButtonContainer>
  );
};

CloseButton.defaultProps = {
  color: white,
};

export default CloseButton;
