import styled from 'styled-components';
import { FC } from 'react';
import { useHistory } from 'react-router';

import platoLogo from 'assets/images/plato-logo.svg';
import { OutlineCSS } from 'shared/config/GlobalStyles';
import { useIsUserLoggedIn } from 'shared/hooks/useIsUserLoggedIn';

import { Wrapper } from './Wrapper';

enum LogoEnums {
  size = 20,
  proportion = 0.8666666667,
}

interface LogoElementsProps {
  size: number;
}

interface LogoProps {
  size?: number;
}

const LogoWrapper = styled(Wrapper)`
  cursor: pointer;

  &:focus-visible {
    ${OutlineCSS}
  }
`;

const LogoImg = styled.img<LogoElementsProps>`
  height: ${({ size }) => size + 10}px;
  width: ${({ size }) => size * LogoEnums.proportion + 10}px;
`;

const LogoTitle = styled.div<LogoElementsProps>`
  font-size: ${({ size }) => size}px;
  margin-left: 4px;
  position: relative;
  top: -3px;
  font-weight: 600;
  line-height: 1;
`;

const Logo: FC<LogoProps> = ({ size }) => {
  const history = useHistory();
  const isAuth = useIsUserLoggedIn();

  const goToHome = () => {
    if (isAuth) {
      history.push('/overview/dashboard');
    } else {
      history.push('/authentication/login');
    }
  };

  return (
    <LogoWrapper tabIndex={0} flex alignEnd onClick={goToHome}>
      <LogoImg size={size!} src={platoLogo} alt="Plato logo" />
      <LogoTitle size={size!}>Plato Connect</LogoTitle>
    </LogoWrapper>
  );
};

Logo.defaultProps = {
  size: LogoEnums.size,
};

export default Logo;
