import styled, { css } from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { H2 } from 'shared/components/Typography';
import { lynch } from 'shared/config/Colors';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Divider, Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import UtilService from 'shared/services/util.service';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { useFormContext } from 'react-hook-form';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modification } from './Modification';
import { ModificationsModal } from './Modifications/ModificationsModal';
import { CsrModificationForm } from './CsrModificationForm';

import {
  reorderLineItemModifications,
  setIsLineItemsStepDirty,
} from '../store/orderLineItemsActions';

import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import { IConfigureLineItemForm } from '../interface/IConfigureLineItem';
import { IModification } from './Modifications/interface/IModification';

const LineItemModificationsContainer = styled(Wrapper)``;

interface LineItemModificationsListProps {
  hasMods?: boolean;
}

const LineItemModificationsList = styled.div<LineItemModificationsListProps>`
  /* max-height: 420px;
  overflow-y: auto;

  ${({ hasMods, theme }) =>
    hasMods &&
    css`
      padding: 10px 15px 0 50px;
      border: 1px solid ${theme.mischka};
      border-radius: 8px;
      background-color: ${UtilService.colorWithOpacity(theme.white, 0.5)};
    `}; */
`;

const LineItemModificationsHeader = styled(Wrapper)`
  > h2 {
    font-size: 24px;
  }
`;

const LineItemModifications = () => {
  const dispatch = useAppDispatch();

  const modifications = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemModifications
  );
  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );
  const { watch } = useFormContext<IConfigureLineItemForm>();
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);
  const width = watch('width');
  const height = watch('height');
  const depth = watch('depth');
  const listPrice = watch('listPrice');

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'catalogLineItemId',
    'styleId',
  ]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (modifications) {
      const mods = UtilService.reorder(
        [...modifications],
        result.source.index,
        result.destination.index
      );

      dispatch(reorderLineItemModifications(mods));
      dispatch(setIsLineItemsStepDirty(true));
    }
  };

  const [modificationList, setModificationList] = useState<IModification[]>([]);

  const handleAddModification = () => {
    if (!modificationList.some((item) => item.completed === false)) {
      dispatch(setIsLineItemsStepDirty(true));
      let emptyMod = {} as IModification;
      emptyMod = {
        ...emptyMod,
        id: uuidv4(),
        quantity: 1,
        price: 0,
        description: '',
        note: '',
        perDrawing: false,
        perFactoryDrawing: false,
        imageUrl: '',
        completed: false,
        number: modificationList.length + 1,
      };
      setModificationList([...modificationList, emptyMod]);
    }
  };

  useEffect(() => {
    setModificationList([...modifications]);
  }, [modifications]);

  const addModification = isUserCSR ? (
    <TabbableButton
      color={lynch}
      type="button"
      disabled={!canEdit}
      onClick={handleAddModification}
    >
      + Add a modification
    </TabbableButton>
  ) : (
    <ModalWrapper
      modal
      nested
      closeOnDocumentClick={false}
      closeOnEscape={false}
      trigger={
        <TabbableButton color={lynch} type="button" disabled={!canEdit}>
          + Add a modification
        </TabbableButton>
      }
    >
      {(close: () => void) => (
        <Modal close={close} noHeader withoutFooter={1}>
          <ModificationsModal closeModal={close} />
        </Modal>
      )}
    </ModalWrapper>
  );

  return (
    <>
      <Spacer h="72px" />
      <Divider />
      <Spacer h="48px" />

      <LineItemModificationsContainer>
        <LineItemModificationsHeader flex between>
          <H2>Modifications</H2>

          {addModification}
        </LineItemModificationsHeader>

        {modificationList.length > 0 && <Spacer h="45px" />}

        <LineItemModificationsList hasMods={!!modificationList.length}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="draggable-modifications">
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {modificationList.map((modification, index) => (
                    <Draggable
                      key={modification.id}
                      draggableId={modification.id || ''}
                      index={index}
                      isDragDisabled={!canEdit}
                    >
                      {(draggableProvided) =>
                        isUserCSR ? (
                          <CsrModificationForm
                            draggableProvided={draggableProvided}
                            catalogLineItemId={
                              queryFields?.catalogLineItemId ?? ''
                            }
                            productLineId={queryFields?.productLineId ?? ''}
                            styleId={queryFields?.styleId ?? ''}
                            depth={depth}
                            height={height}
                            listPrice={listPrice}
                            width={width}
                            modification={modification}
                          />
                        ) : (
                          <Modification
                            draggableProvided={draggableProvided}
                            modification={modification}
                            noActions={!canEdit}
                          />
                        )
                      }
                    </Draggable>
                  ))}

                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </LineItemModificationsList>
        <Spacer h="8px" />
        <Wrapper flex justifyEnd>
          {modificationList.length > 0 && addModification}
        </Wrapper>
      </LineItemModificationsContainer>
      <Spacer h="48px" />

      <Divider />
      <Spacer h="72px" />
    </>
  );
};

export default LineItemModifications;
