export enum FieldName {
  OrderType = 1,
  OrderDescriptionType = 2,
  OrderTransportationType = 3,
  OrderName = 4,
  OrderCeilingHeight = 5,
  OrderCountertopThickness = 6,
  OrderPostOfficeNumber = 7,
  OrderWorkOrderNumber = 8,
  OrderDateRequested = 9,
  OrderNote = 10,
  OrderJobSiteDelivery = 11,
  OrderJob = 12,
  OrderShippingAddress = 13,
  OrderDepositPaid = 14,
  OrderDepositPaidDate = 15,
  OrderShipVia = 16,
  Style = 100,
  StyleName = 101,
  StyleNumber = 102,
  StyleFinishColor = 103,
  StyleFinishColorUpcharge = 104,
  StyleVarnish = 105,
  StyleGrainDirection = 106,
  StyleWoodDetail = 107,
  StyleWoodMaterial = 108,
  StyleWoodMaterialUpcharge = 109,
  StyleWoodInterior = 110,
  StyleWoodInteriorUpcharge = 111,
  StyleWoodEffect = 112,
  StyleWoodEffectUpcharge = 113,
  StyleCabinetBoxMaterial = 114,
  StyleSpecialFinishSample = 115,
  StyleSelectWoodSpecies = 116,
  StyleShowAvailableFinishesOnly = 117,
  StyleEngineeredFurnitureBoardConstruction = 118,
  StyleFinishImpression = 119,
  StyleFinishImpressionUpcharge = 120,
  StyleNoMidrails = 121,
  StyleOneInchDoor = 122,
  StyleHdfPanels = 123,
  StyleMaterialGroup = 124,
  StyleMaterialGroupUpcharge = 125,
  StyleMaterialColor = 126,
  StyleMaterialColorUpcharge = 127,
  StyleMaterialNotes = 128,
  StyleWoodNotes = 129,
  StyleFinishNotes = 130,
  StyleInteriorNotes = 131,
  StyleDoorNotes = 132,
  StyleHardwareNotes = 133,
  StyleNote = 134,
  StyleFaceFrame = 135,
  StyleFrameStyle = 136,
  StyleFrameSize = 137,
  StyleMetalFinish = 138,
  StyleMetalFinishUpcharge = 139,
  StyleArchStyle = 140,
  StyleDoorOverlay = 141,
  StyleBaseDoorStyle = 142,
  StyleWallDoorStyle = 143,
  StyleWallDoorConfiguration = 144,
  StyleBaseDoorConfiguration = 145,
  StyleWallDoorInsertPanel = 146,
  StyleBaseDoorInsertPanel = 147,
  StyleWallDoorEdge = 148,
  StyleBaseDoorEdge = 149,
  StyleDrawerStyle = 150,
  StyleDrawerInsertPanel = 151,
  StyleDrawerEdge = 152,
  StyleDrawerBox = 153,
  StyleTopInsertPanel = 154,
  StyleHingeType = 155,
  StyleHingeTypeQuantity = 156,
  StyleHingeTypeAdder = 157,
  StyleHingeColor = 158,
  StyleDoorHardware = 159,
  StyleDoorHardwareQuantity = 160,
  StyleDoorHardwareAdder = 161,
  StyleDrawerHardware = 162,
  StyleDrawerHardwareQuantity = 163,
  StyleDrawerHardwareAdder = 164,
  StyleToeSpaceType = 165,
  StyleMaterialDrawer = 166,
  StyleDoorEdgeBand = 167,
  StyleFinishEffect = 168,
  StyleFinishEffectUpcharge = 169,
  StyleFinishEffectShowAvailableEffectsOnly = 170,
  StyleClosetHardwareColor = 171,
  StyleOneInchFaceFrame = 172,
  StyleOneInchThickDoorUpcharge = 173,
  StyleNaturalInterior = 174,
  StyleColorSample = 175,
  StyleWoodTape = 176,
  StyleEdgebandColor = 177,
  StyleSpecialCabinetBoxMaterial = 178,
  StyleCabinetBoxMaterialUpcharge = 179,
  StyleSQFT = 180,
  StyleMatchingInteriorSQFT = 181,
  StyleVendorColorName = 182,
  StyleVendorColorCode = 183,
  LineItemNumber = 200,
  LineItemClientLineItemNumber = 201,
  LineItemQuantity = 202,
  LineItemWidth = 203,
  LineItemHeight = 204,
  LineItemDepth = 205,
  LineItemHingePosition = 206,
  LineItemFinishedEndPosition = 207,
  LineItemExposedEndPosition = 208,
  LineItemListPrice = 209,
  LineItemRightLegWidth = 210,
  LineItemRightLegDepth = 211,
  LineItemPerDrawing = 212,
  LineItemPerFactoryDrawing = 213,
  LineItemDimUpcharge = 214,
  LineItem = 215,
  ModificationNumber = 300,
  ModificationQuantity = 301,
  ModificationPrice = 302,
  ModificationNote = 303,
  ModificationAdditionalNote = 304,
  ModificationPerDrawing = 305,
  ModificationPerFactoryDrawing = 306,
  Modification = 307,
  StyleCancellationRequested = 190,
}

export enum EntityType {
  Order = 1,
  Style = 2,
  LineItem = 3,
  Modification = 4,
}

export enum ChangeAction {
  OrderUpdated = 1,
  StyleAdded = 10,
  StyleUpdated = 11,
  StyleRemoved = 12,
  StyleFinishEffectAdded = 13,
  StyleFinishEffectUpdated = 14,
  StyleFinishEffectRemoved = 15,
  StyleWoodEffectAdded = 16,
  StyleWoodEffectUpdated = 17,
  StyleWoodEffectRemoved = 18,
  LineItemAdded = 20,
  LineItemUpdated = 21,
  LineItemRemoved = 22,
  ModificationAdded = 30,
  ModificationUpdated = 31,
  ModificationRemoved = 32,
}

export interface AckChangeLogItem {
  id: number;
  orderId: string;
  acknowledgementId?: string;
  entityType: EntityType;
  changeAction: ChangeAction;
  fieldName: FieldName;
  version: string;
  number?: number;
  description: string;
  oldEntityId: string;
  oldValue: string;
  newEntityId: string;
  newValue: string;
  createdOnUtc: string;
  changedBy: string;
}

export interface AcknowledgmentDifference {
  styleId?: string;
  lineItemId: string | null;
  entityType: EntityType;
  fieldName: FieldName;
  changeAction: ChangeAction;
  oldValue: string | null;
  newValue: string | null;
  description: string;
  number: number | null;
  entityId: string | null;
}
