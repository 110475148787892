import { RootState } from 'store';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getFormsAndCatalogs,
  setFormsAndCatalogs,
} from 'overview/store/overviewActions';

import { ButtonPrimary } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import RadioButtonWithDesc from 'shared/components/RadioButtonWithDesc';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';

const BrowseCatalogsContainer = styled.div``;

const HiddenLink = styled.a``;

interface CatalogsForm {
  catalogUrl: string;
}

const BrowseCatalogs = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const [modalOpened, setModalOpened] = useState(false);

  const [catalogsLoading, setCatalogsLoading] = useState(true);

  const hiddenLinkRef = useRef<HTMLAnchorElement>(null);

  const catalogs = useSelector(
    (state: RootState) => state.overviewReducer.catalogs
  );

  const { register, handleSubmit, formState, reset, watch } =
    useForm<CatalogsForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        catalogUrl: '',
      },
    });

  const catalogUrlWatched = watch('catalogUrl');

  const onCatalogSelect = (data: CatalogsForm, close: () => void) => {
    if (hiddenLinkRef.current) {
      hiddenLinkRef.current.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }

    reset();
    close();
  };

  useEffect(() => {
    if (modalOpened) {
      setCatalogsLoading(true);
      dispatch(getFormsAndCatalogs(setCatalogsLoading));
    } else {
      dispatch(setFormsAndCatalogs({ catalogs: null, forms: null }));
    }
  }, [modalOpened]);

  return (
    <BrowseCatalogsContainer>
      <HiddenLink
        ref={hiddenLinkRef}
        hidden
        href={catalogUrlWatched}
        target="_blank"
        rel="noopener"
      />
      <ModalWrapper
        modal
        lockScroll
        onOpen={() => setModalOpened((prevState) => !prevState)}
        onClose={() => setModalOpened((prevState) => !prevState)}
        trigger={<div>{children}</div>}
      >
        {(close: () => void) => (
          <Modal title="Catalogs" confirm close={close}>
            <P color={nevada}>Please select a catalog to view</P>

            <Spacer h="30px" />

            <ScrollbarsCustom
              autoHide
              autoHideDuration={300}
              autoHideTimeout={500}
              autoHeight
              autoHeightMin={495}
              autoHeightMax={495}
            >
              {!catalogsLoading &&
                catalogs?.map((catalog) => (
                  <RadioButtonWithDesc
                    {...register('catalogUrl', { required: true })}
                    data-flow-option
                    id={catalog.id}
                    key={catalog.id}
                    label={catalog.name}
                    value={catalog.fileUrl}
                  />
                ))}

              {!catalogsLoading && !catalogs && (
                <EmptyState
                  marginTop={10}
                  marginBottom={30}
                  title="There are no catalogs"
                />
              )}

              {catalogsLoading && <Loader size={42} />}
            </ScrollbarsCustom>

            <Spacer h="30px" />

            <Wrapper flex justifyEnd>
              <ButtonPrimary
                type="button"
                onClick={handleSubmit((data: CatalogsForm) =>
                  onCatalogSelect(data, close)
                )}
                disabled={!formState.isValid || !catalogs?.length}
              >
                Open Catalog
              </ButtonPrimary>
            </Wrapper>
          </Modal>
        )}
      </ModalWrapper>
    </BrowseCatalogsContainer>
  );
};

export default BrowseCatalogs;
