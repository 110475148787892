import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import StaticField from 'order/components/StaticField/StaticField';

import { H3 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';

import { OrderStylesOverviewTestLabelEnum } from 'tests/enums/OrderStylesOverviewTestEnums';

import OverviewDoorBuilder from '../OverviewDoorBuilder/OverviewDoorBuilder';

const OverviewWoodContainer = styled(Wrapper)``;

const OverviewWood = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const config = useProductLineConfiguration();

  return (
    <OverviewWoodContainer flex>
      <Wrapper flexGrow>
        <StaticField
          field="Original W.O #"
          value={storedStyle?.originalWorkOrderNumber || ''}
        />

        <StaticField field="Style Notes" value={storedStyle?.note || ''} />
        <Spacer h="10px" />

        <H3>{config.styleSpecifications.woodTypeLabel}</H3>

        <Spacer h="30px" />

        <StaticField
          field={config.styleSpecifications.woodLabel}
          value={storedStyle?.woodMaterial?.name || ''}
          fieldTestId={OrderStylesOverviewTestLabelEnum.WOOD_LBL}
        />

        {config.styleSpecifications.showEngineeredCheckbox && (
          <StaticField
            field="Engineered Furniture Board Construction"
            value={
              storedStyle?.engineeredFurnitureBoardConstruction ? 'ON' : 'OFF'
            }
          />
        )}

        {config.styleSpecifications.showSelectWoodSpecies && (
          <StaticField
            field="Select Wood Species"
            value={storedStyle?.selectWoodSpecies || ''}
          />
        )}

        <StaticField
          field="Wood effect"
          value={
            storedStyle?.woodEffects
              ?.map((woodEffect) => woodEffect.name)
              .join('<br/>') || ''
          }
        />

        {config.styleSpecifications.showWoodInterior && (
          <StaticField
            field="Wood interior"
            value={storedStyle?.woodInterior?.name}
            fieldTestId={OrderStylesOverviewTestLabelEnum.WOOD_INTERIOR_LBL}
          />
        )}

        <StaticField field="Wood notes" value={storedStyle?.woodNotes || ''} />

        {config.styleSpecifications.showCabinetBoxMaterial && (
          <>
            <StaticField
              field="Cabinet Box Material"
              value={storedStyle?.cabinetBoxMaterial?.name || ''}
              fieldTestId={
                OrderStylesOverviewTestLabelEnum.CABINET_BOX_MATERIAL_LBL
              }
            />

            <StaticField
              field="Special Cabinet Box Material"
              value={storedStyle?.specialCabinetBoxMaterial || ''}
            />
          </>
        )}

        {config.styleSpecifications.showEdgebandColor && (
          <StaticField
            field="Edge Band Color"
            value={storedStyle?.edgebandColor || ''}
          />
        )}

        <Spacer h="20px" />
        <H3>Finish</H3>
        <Spacer h="30px" />

        <OrderFinishLabel
          style={storedStyle}
          render={(label) => (
            <OrderFinishValue
              style={storedStyle}
              render={(value) => <StaticField field={label} value={value} />}
            />
          )}
        />

        {config.styleSpecifications.showSpecialFinishSample && (
          <StaticField
            field="Special finish sample"
            value={storedStyle?.specialFinishSample || ''}
          />
        )}

        {storedStyle?.vendorColor && (
          <>
            <StaticField
              field="Vendor Color Code"
              value={storedStyle?.vendorColor?.code || ''}
            />
            <StaticField
              field="Vendor Color Name"
              value={storedStyle?.vendorColor?.name || ''}
            />
          </>
        )}

        <StaticField
          field="Finish effect"
          value={
            storedStyle?.finishEffects
              ?.map((finishEffect) => finishEffect.name)
              .join('<br/>') || ''
          }
        />

        <StaticField
          field="Varnish sheen"
          value={storedStyle?.varnish?.name || ''}
        />

        <StaticField
          field="Finish notes"
          value={storedStyle?.finishNotes || ''}
        />
      </Wrapper>

      <Spacer w="216px" />

      <OverviewDoorBuilder />
    </OverviewWoodContainer>
  );
};

export default OverviewWood;
