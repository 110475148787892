export const containerWidth: number = 1190;
export const containerPadding: number = 15;
export const fontSize: number = 16;
export const lineHeight: number = 1.4;
export const svgSizeMultiplyer = 1.524;

export const pigeonPost: string = '#B2C1D8';

export const globalMaxFileSize = 25;
export const globalMaxDWGFileSize = 300;

export const orderHeaderHeightWithCollaborationActions = 240;
export const orderHeaderHeightWithoutCollaborationActions = 159;

export const globalDateFormat = 'MM/dd/yy';
export const globalTimeFormat = 'hh:mm a';
export const globalTimeWithSecondsFormat = 'hh:mm:ss a';

export const zeroGuid = '00000000-0000-0000-0000-000000000000';

export const fileTypesAllowed = {
  csv: '.csv',
  dfx: '.dfx',
  doc: '.doc',
  docx: '.docx',
  dwg: '.dwg',
  dxf: '.dxf',
  heic: '.heic',
  jpeg: '.jpeg',
  jpg: '.jpg',
  kit: '.kit',
  msg: '.msg',
  pdf: '.pdf',
  pdq: '.pdq',
  png: '.png',
  txt: '.txt',
  xls: '.xls',
  xlsx: '.xlsx',
};
