export enum ModificationModalStepsEnums {
  SEARCH_MODIFICATIONS = 'SEARCH_MODIFICATIONS',
  ADD_MODIFICATIONS = 'ADD_MODIFICATIONS',
  UPDATE_MODIFICATION = 'UPDATE_MODIFICATION',
  COMPLETE_MODIFICATIONS = 'COMPLETE_MODIFICATIONS',

  SEARCH_MOD_TITLE = 'Add a modification',
  SEARCH_MOD_DESC = 'Search for a specific modification.',

  ADD_MOD_TITLE = 'Add a modification',
  ADD_MOD_DESC = 'Select specs for the selected modification.',

  UPDATE_MOD_TITLE = 'Update a modification',
  UPDATE_MOD_DESC = 'Update specs for the selected modification.',

  COMPLETE_MOD_TITLE = 'Complete',
  COMPLETE_MOD_DESC = 'The following mods will apear in your mod list.',

  REPLACE_MOD_TITLE = 'Replace a modification',
}
