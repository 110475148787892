import styled from 'styled-components';

interface StyledFromElementProps {
  last?: 0 | 1;
  maxWidth?: number;
  minWidth?: number;
  flexGrow?: boolean;
  flex?: boolean;
  flexDirectionColumn?: boolean;
  between?: boolean;
  noMarginBottom?: boolean;
}

const FormElement = styled.div<StyledFromElementProps>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  ${({ flexGrow }) => flexGrow && `flex-grow: 1`};
  ${({ flex }) => flex && `display: flex`};
  ${({ between }) => between && 'justify-content: space-between;'}
  ${({ flexDirectionColumn }) =>
    flexDirectionColumn && `flex-direction: column`};
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? '0' : '20px')};
`;

FormElement.defaultProps = {
  flexGrow: false,
};

export { FormElement };
