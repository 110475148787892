import { ErrorCode, FileError, useDropzone } from 'react-dropzone';
import { extension } from 'mime-types';
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as CloudUpload } from 'assets/icons/upload-cloud.svg';

import { lynch, silverSandTwo } from 'shared/config/Colors';
import UtilService from 'shared/services/util.service';

import {
  fileTypesAllowed,
  globalMaxDWGFileSize,
  globalMaxFileSize,
  svgSizeMultiplyer,
} from 'shared/config/Variables';

import { ButtonPrimary } from './Button';
import { P } from './Typography';
import { Spacer } from './Layout';
import { Wrapper } from './Wrapper';
import SVG from './SVG';

interface DragAndDropFilesProps {
  maxSize?: number;
  dwgMaxSize?: number;
  allowedFileTypes?: string[];
  selectedFiles: (files: File[] | null) => void;
  inline?: boolean;
  disabled?: boolean;
}

export const UploadContainer = styled(Wrapper)<
  Pick<DragAndDropFilesProps, 'inline'>
>`
  padding: ${({ inline }) => (inline ? 30 : 42)}px 32px;
  background-color: ${({ theme }) => theme.athensGray};
  border: 1px dashed ${({ theme }) => theme.lynch};
  border-radius: 8px;
  transition: 300ms;

  * {
    transition: 300ms;
  }

  &.drag-active {
    background: ${({ theme }) => theme.botticelli};

    * {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const DragAndDropFiles: FC<DragAndDropFilesProps> = ({
  allowedFileTypes,
  disabled,
  inline,
  maxSize,
  dwgMaxSize,
  selectedFiles,
}) => {
  const fileValidation = (file: File): FileError | null => {
    const { size, type } = file;
    const ext = extension(type);

    if (ext === 'dwg' && size > UtilService.mbToBytes(dwgMaxSize!)) {
      return {
        message: `${ext.toUpperCase()} file cannot be lager then ${dwgMaxSize} MB.`,
        code: ErrorCode.FileTooLarge,
      };
    }

    if (
      typeof ext === 'string' &&
      ext !== 'dwg' &&
      size > UtilService.mbToBytes(maxSize!)
    ) {
      return {
        message: `${ext.toUpperCase()} file cannot be lager then ${maxSize} MB.`,
        code: ErrorCode.FileTooLarge,
      };
    }

    return null;
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: allowedFileTypes!,
    multiple: true,
    validator: (file: File) => fileValidation(file),
  });

  useEffect(() => {
    selectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    fileRejections.map(({ errors, file }) => {
      const isFileInvalidType = errors?.[0]?.code === ErrorCode.FileInvalidType;

      const errorMessage = isFileInvalidType
        ? `${file.name} could not be uploaded because this file format is unsupported`
        : `${file.name} could not be uploaded because it's larger then ${maxSize}MB`;

      return toast.error(errorMessage);
    });
  }, [fileRejections]);

  return (
    <UploadContainer
      {...getRootProps()}
      className={isDragActive ? 'drag-active' : ''}
      inline={inline}
      column={!inline}
      flex
      isDragActive={isDragActive}
      middle
      center
      disabled={disabled}
    >
      <SVG
        icon={
          <CloudUpload
            width={inline ? 24 * svgSizeMultiplyer : 64 * svgSizeMultiplyer}
            height={inline ? 16 * svgSizeMultiplyer : 42 * svgSizeMultiplyer}
          />
        }
        color={silverSandTwo}
      />

      {inline ? <Spacer w="5px" /> : <Spacer h="2px" />}

      <P fontSize={14} color={silverSandTwo}>
        Drag &amp; drop files here or
      </P>

      {inline ? <Spacer w="5px" /> : <Spacer h="7px" />}

      {inline ? (
        <P pointer textDecoration="underline" fontSize={14} color={lynch}>
          Choose file
        </P>
      ) : (
        <ButtonPrimary type="button">Choose File</ButtonPrimary>
      )}

      <input multiple {...getInputProps()} />
    </UploadContainer>
  );
};

DragAndDropFiles.defaultProps = {
  inline: false,
  allowedFileTypes: Object.values(fileTypesAllowed),
  maxSize: globalMaxFileSize,
  dwgMaxSize: globalMaxDWGFileSize,
};

export default DragAndDropFiles;
