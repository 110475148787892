import { useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  OrderStylizationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';

import {
  allSalesAidsOptions,
  csrOnlySalesAidsOptions,
} from './OrderTypeOptionSets';

import { OrderFormFields } from '../OrderForm';
import OrderTypeSelect from './OrderTypeSelect';

const OrderTypeField = () => {
  const storedStylizationType = useSelector(
    (state: RootState) => state.orderReducer.order?.stylizationType
  );
  const methodContext = useFormContext<OrderFormFields>();

  const orderTypeWatched = methodContext.watch('type');
  const orderStylizationTypeWatched = methodContext.watch('stylizationType');

  useEffect(() => {
    const isCSRSalesAidsOption = allSalesAidsOptions.includes(
      +orderTypeWatched
    );

    if (
      +orderTypeWatched !== OrderTypeEnums['Sales Aids'] &&
      !isCSRSalesAidsOption
    ) {
      methodContext.setValue(
        'stylizationType',
        OrderStylizationTypeEnums.NONE.toString()
      );
      methodContext.setValue('dateRequested', '');
      return;
    }

    if (
      // when order type is sales aids or mapped option
      +orderTypeWatched === OrderTypeEnums['Sales Aids'] ||
      isCSRSalesAidsOption
    ) {
      const ds_dsb = !csrOnlySalesAidsOptions
        .filter((x) => x !== OrderTypeEnums.Net)
        .includes(+orderTypeWatched)
        ? OrderStylizationTypeEnums.DOOR_SAMPLE_BASE
        : OrderStylizationTypeEnums.SALES_MATERIAL;

      if (methodContext.formState.isDirty) {
        methodContext.setValue('stylizationType', ds_dsb.toString());
      } else {
        methodContext.setValue(
          'stylizationType',
          storedStylizationType?.toString() ?? ds_dsb.toString()
        );
      }

      methodContext.setValue('dateRequested', 'ASAP');
    }
  }, [orderTypeWatched]);

  return (
    <FormElement>
      <FormLabel>Order type</FormLabel>

      <OrderTypeSelect
        methodContextControl={methodContext.control}
        orderType={orderTypeWatched}
        orderStylizationType={orderStylizationTypeWatched}
      />
    </FormElement>
  );
};

export default OrderTypeField;
