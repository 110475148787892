import { createGlobalStyle } from 'styled-components';

export const AcknowledgmentPrint = createGlobalStyle`
  @media print {
    .order-header,
    .order-tabs,
    .curtain,
    .order-page-footer,
    .ack-print,
    .expandable-box-button,
    .order-list-of-changes,
    .review-pricing-style-switch-buttons,
    .review-pricing-switch-buttons,
    .order-edit-lock-container,
    .review-pricing-divider,
    .review-pricing-spacer {
      display: none;
    }

    .order-page {
      padding-top: 0 !important;
      background-color: white;
    }

    .order-page-container {
      padding: 0 !important;
    }

    .container-box,
    .white-box {
      padding: 0;
      page-break-inside: avoid;
      box-shadow: none;
    }


    .order-style {
      padding: 5px;
      box-shadow: none;
      
      .style-expandable-spacer,
      .order-style-actons {
        display: none;
      }
    }


    .ack-attachments {
      display: block;
      

      > .container-box {
        margin-bottom: 30px;
      }
    }
  }
`;
