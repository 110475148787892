import { AnyAction } from 'redux';

import { IDoorCode } from 'order/wizard/orderStyles/interface/DoorCodes';
import { ImpressionData } from 'order/wizard/orderStyles/interface/FinishImpression';
import { LineItemHardware } from 'order/wizard/orderLineItems/interface/ILineItemData';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import UtilService from 'shared/services/util.service';

import { IFinishEffectField } from '../../interface/FinishEffectField';
import { IWoodEffectField } from '../../interface/WoodEffectField';
import { Midrails } from '../../interface/Midrails';
import { ProductLine } from '../../../../interfaces/ProductLine';
import { SpecificationsOptionsEnum } from '../../enums/SpecificationsOptionsEnum';
import { Style } from '../../interface/Style';
import { StyleDoorBuilder } from '../../interface/StyleDoorBuilder';
import { StyleFieldOption } from '../../interface/StyleFieldOption';
import { StyleSpecifications } from '../../interface/StyleSpecifications';
import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { generateNewFinishEffectField } from '../steps/OrderStylesSpecifications/FinishEffectNew/FinishEffectNew';
import { generateNewWoodEffectField } from '../steps/OrderStylesSpecifications/WoodEffectNew/WoodEffectNew';
import { orderStylesActions, StyleFields } from './orderStylesActions';

import {
  ConfigOptions,
  HingeColorOptions,
  orderStylesDoorBuilderActions,
  SetDoorBuilderOptionsByOptionsKeyPayload,
} from './doorBuilder/orderStylesDoorBuilderActions';

import {
  orderStylesSpecificationActions,
  SetSpecsOptionsByOptionsKeyPayload,
} from './specifications/orderStylesSpecificationActions';

export interface SpecificationsOptions {
  finishEffectOptions1?: SelectOptionProps[] | null;
  finishEffectOptions2?: SelectOptionProps[] | null;
  finishEffectOptions3?: SelectOptionProps[] | null;
  finishColorOptions?: SelectOptionProps[] | null;
  finishImpressionOptions?: SelectOptionProps[] | null;
  varnishSheenOptions?: SelectOptionProps[] | null;
  woodEffectOptions1?: SelectOptionProps[] | null;
  woodEffectOptions2?: SelectOptionProps[] | null;
  woodInteriorOptions?: SelectOptionProps[] | null;
  woodOrMaterialOptions?: SelectOptionProps[] | null;
  cabinetBoxMaterialOptions?: SelectOptionProps[] | null;
  materialGroupOptions?: SelectOptionProps[] | null;
  materialColorOptions?: SelectOptionProps[] | null;
  grainDirectionOptions?: SelectOptionProps[] | null;
}

export interface DoorBuilderOptions {
  archStyleOptions: SelectOptionProps[] | null;
  closetHardwareColorOptions: SelectOptionProps[] | null;
  configurationOptions: ConfigOptions | null;
  doorCodes: IDoorCode | null;
  doorEdgeBandOptions: SelectOptionProps[] | null;
  doorEdgeBaseOptions: SelectOptionProps[] | null;
  doorEdgeWallOptions: SelectOptionProps[] | null;
  doorOverlayOptions: StyleFieldOption[] | null;
  doorStyleBaseOptions: SelectOptionProps[] | null;
  doorStyleWallOptions: SelectOptionProps[] | null;
  drawerBoxOptions: SelectOptionProps[] | null;
  drawerEdgeOptions: SelectOptionProps[] | null;
  drawerInsertPanelOptions: SelectOptionProps[] | null;
  drawerStyleOptions: SelectOptionProps[] | null;
  faceFrameOptions: StyleFieldOption[] | null;
  frameSizeOptions: SelectOptionProps[] | null;
  frameStyleOptions: SelectOptionProps[] | null;
  hardwareOptions: SelectOptionProps[] | null;
  hardwareQuantities: LineItemHardware | null;
  hingeColorOptions: HingeColorOptions | null;
  hingeTypeOptions: SelectOptionProps[] | null;
  insertPanelBaseOptions: SelectOptionProps[] | null;
  insertPanelWallOptions: SelectOptionProps[] | null;
  materialDrawerOptions: SelectOptionProps[] | null;
  metalFinishOptions: SelectOptionProps[] | null;
  midrailsOptions: Midrails | null;
  toeSpaceTypeOptions: SelectOptionProps[] | null;
  topDrawerInsertPanelOptions: SelectOptionProps[] | null;
}

export interface OrderStylesInitialStore {
  replacementProductLines: ProductLine[] | null;
  replacementStyle: Style | null;
  currentStep: StylesStepsEnum;
  doorBuilder: StyleDoorBuilder | null;
  doorBuilderOptions: DoorBuilderOptions;
  finishImpressionData: ImpressionData | null;
  isStepDirty: boolean;
  modalOpenedForStyleId: string | null;
  name: string | null;
  productLine: ProductLine | null;
  specifications: StyleSpecifications | null;
  specificationsOptions: SpecificationsOptions;
  style: Style | null;
  styleId: string | null;
}

const initialState: OrderStylesInitialStore = {
  currentStep: StylesStepsEnum.PRODUCT_LINE,
  doorBuilder: null,
  name: null,
  productLine: null,
  finishImpressionData: null,
  specifications: null,
  style: null,
  styleId: null,
  modalOpenedForStyleId: null,
  isStepDirty: false,
  replacementProductLines: null,
  replacementStyle: null,
  specificationsOptions: {
    finishEffectOptions1: null,
    finishEffectOptions2: null,
    finishEffectOptions3: null,
    varnishSheenOptions: null,
    finishColorOptions: null,
    finishImpressionOptions: null,
    woodEffectOptions1: null,
    woodEffectOptions2: null,
    woodInteriorOptions: null,
    woodOrMaterialOptions: null,
    cabinetBoxMaterialOptions: null,
    materialColorOptions: null,
  } as SpecificationsOptions,
  doorBuilderOptions: {
    doorOverlayOptions: null,
    faceFrameOptions: null,
    frameStyleOptions: null,
    midrailsOptions: null,
    doorStyleWallOptions: null,
    doorStyleBaseOptions: null,
    frameSizeOptions: null,
    configurationOptions: null,
    archStyleOptions: null,
    insertPanelWallOptions: null,
    insertPanelBaseOptions: null,
    doorEdgeWallOptions: null,
    doorEdgeBaseOptions: null,
    metalFinishOptions: null,
    drawerBoxOptions: null,
    hingeTypeOptions: null,
    hingeColorOptions: null,
    drawerStyleOptions: null,
    drawerEdgeOptions: null,
    drawerInsertPanelOptions: null,
    topDrawerInsertPanelOptions: null,
    hardwareOptions: null,
    closetHardwareColorOptions: null,
    doorCodes: null,
    hardwareQuantities: null,
    toeSpaceTypeOptions: null,
    doorEdgeBandOptions: null,
    materialDrawerOptions: null,
  } as DoorBuilderOptions,
};

export const orderStylesReducer = (
  state = initialState as OrderStylesInitialStore,
  action: AnyAction
) => {
  switch (action.type) {
    case orderStylesActions.CHANGE_STYLES_STEP:
      return { ...state, currentStep: action.payload as StylesStepsEnum };
    case orderStylesActions.CLEAR_STYLES:
      return { ...initialState };
    case orderStylesActions.SET_STYLES_PRODUCT_LINE:
      return { ...state, productLine: action.payload as ProductLine };
    case orderStylesActions.SET_STYLE_NAME:
      return {
        ...state,
        name: action.payload as string,
        style: { ...state.style, name: action.payload } as Style,
      };
    case orderStylesActions.CLEAR_STYLE_SPECS_OPTIONS:
      return {
        ...state,
        specificationsOptions: {
          ...initialState.specificationsOptions,
          finishImpressionOptions:
            state.specificationsOptions.finishImpressionOptions,
        },
      };
    case orderStylesActions.SET_IS_STYLES_STEP_DIRTY: {
      return {
        ...state,
        isStepDirty: action.payload as boolean,
      };
    }
    case orderStylesActions.SET_PRODUCT_LINES_FOR_REPLACEMENT:
      return {
        ...state,
        replacementProductLines: action.payload as ProductLine[],
      };
    case orderStylesActions.SET_REPLACEMENT_STYLE:
      return {
        ...state,
        replacementStyle: action.payload as Style,
      };
    case orderStylesSpecificationActions.SET_STYLES_SPECIFICATIONS: {
      return {
        ...state,
        specifications: action.payload as StyleSpecifications,
      };
    }
    case orderStylesDoorBuilderActions.SET_DOOR_BUILDER_DATA: {
      const styleDoorBuilderData =
        UtilService.mapDoorBuilderDataToStyleDoorBuilder(action.payload);

      return {
        ...state,
        doorBuilder: {
          ...action.payload,
          hingeTypeAdd: UtilService.withDecimal(
            null,
            action.payload.hingeTypeAdd
          ),
          doorHardwareAdd: UtilService.withDecimal(
            null,
            action.payload.doorHardwareAdd
          ),
          drawerHardwareAdd: UtilService.withDecimal(
            null,
            action.payload.drawerHardwareAdd
          ),
        } as StyleDoorBuilder,
        style: {
          ...state.style,
          ...(state.style && { step: 4 }),
          ...styleDoorBuilderData,
        } as Style,
      };
    }

    case orderStylesActions.SET_STYLES_MODAL_OPENED:
      return {
        ...state,
        modalOpenedForStyleId: action.payload as string,
      };
    case orderStylesActions.SET_STYLE_ID:
      return {
        ...state,
        styleId: action.payload as string,
      };
    case orderStylesSpecificationActions.SET_WOOD_OR_MATERIAL_OPTIONS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          woodOrMaterialOptions: action.payload as SelectOptionProps[],
        },
      };
    case orderStylesSpecificationActions.SET_SPECS_BY_WOOD_OR_MATERIAL:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          ...(action.payload as SpecificationsOptions),
        },
      };
    case orderStylesSpecificationActions.SET_SPECS_BY_WOOD_OR_MATERIAL_FOR_ICS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          ...(action.payload as SpecificationsOptions),
        },
      };
    case orderStylesSpecificationActions.SET_FINISH_EFFECTS: {
      if (action.payload) {
        const finishEffectOptionKey =
          `finishEffectOptions${action.payload.optionNumber}` as keyof SpecificationsOptions;

        return {
          ...state,
          specificationsOptions: {
            ...state.specificationsOptions,
            [finishEffectOptionKey]: action.payload
              .finishEffectOptions as SelectOptionProps[],
          },
        };
      }

      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          finishEffectOptions1: null,
          finishEffectOptions2: null,
          finishEffectOptions3: null,
        },
      };
    }
    case orderStylesActions.SET_STYLE: {
      const storedStyle = action.payload as Style;
      return {
        ...state,
        style: storedStyle,
      };
    }
    case orderStylesActions.SET_STYLE_FIELDS: {
      const { productLine, styleId, name } = action.payload as StyleFields;

      return {
        ...state,
        productLine,
        styleId,
        name,
      };
    }

    case orderStylesSpecificationActions.SET_STYLE_SPECS_DATA: {
      const { finishImpressionOptions } = state.specificationsOptions;

      const {
        finishColor,
        finishEffects,
        finishImpression,
        finishNotes,
        selectWoodSpecies,
        showAvailableFinishesOnly,
        specialFinishSample,
        varnish,
        woodEffects,
        woodMaterial,
        woodNotes,
        woodInterior,
        engineeredFurnitureBoardConstruction,
        cabinetBoxMaterial,
        materialGroup,
        materialColor,
        materialNotes,
        grainDirection,
        // csr fields
        woodTape,
        edgebandColor,
        colorSample,
        naturalInterior,
        specialCabinetBoxMaterial,

        note,
        workOrderNumber,
        originalWorkOrderNumber,
        vendorColor,
        doorSquareFootPrice,
        miDoorSquareFootPrice,
        interiorNotes,
      } = state.style!;

      const finishColorOption = finishColor
        ? (UtilService.mapObjectToSelectOptions(
            finishColor,
            undefined,
            undefined,
            { customParams: ['upcharge'] }
          ) as SelectOptionProps)
        : null;

      if (finishColorOption) {
        finishColorOption.upcharge = finishColor.upcharge;
      }

      const finishImpressionOption = finishImpressionOptions?.find(
        (option) => option.value === finishImpression?.id
      );

      const varnishSheenOption = varnish
        ? (UtilService.mapObjectToSelectOptions(varnish, undefined, undefined, {
            customParams: ['upcharge'],
          }) as SelectOptionProps)
        : null;

      let woodOrMaterialOption = woodMaterial
        ? (UtilService.mapObjectToSelectOptions(
            woodMaterial,
            undefined,
            undefined,
            { customParams: ['upcharge'] }
          ) as SelectOptionProps)
        : null;

      if (woodOrMaterialOption) {
        woodOrMaterialOption.upcharge = woodMaterial.upcharge;
      }

      const cabinetBoxMaterialOption = cabinetBoxMaterial
        ? (UtilService.mapObjectToSelectOptions(
            cabinetBoxMaterial,
            undefined,
            undefined,
            { customParams: ['upcharge'] }
          ) as SelectOptionProps)
        : null;

      if (cabinetBoxMaterialOption) {
        cabinetBoxMaterialOption.upcharge = cabinetBoxMaterial.upcharge;
      }

      const woodEffectOptions =
        woodEffects.length > 0
          ? woodEffects.map(
              (we) =>
                ({
                  id: we.orderNumber!.toString(),
                  selectedOption: UtilService.mapObjectToSelectOptions(
                    we,
                    undefined,
                    undefined,
                    { customParams: ['upcharge'] }
                  ) as SelectOptionProps,
                  upcharge: UtilService.withDecimal(
                    null,
                    we.upcharge!.toString()
                  ) as string,
                  orderNumber: we.orderNumber,
                } as IWoodEffectField)
            )
          : [generateNewWoodEffectField('1')];

      const finishEffectsMapped =
        finishEffects.length > 0
          ? finishEffects.map(
              (fe) =>
                ({
                  id: fe.orderNumber!.toString(),
                  selectedOption: UtilService.mapObjectToSelectOptions(
                    fe,
                    undefined,
                    undefined,
                    { customParams: ['upcharge'] }
                  ) as SelectOptionProps,
                  showAvailableOnly: fe.showAvailableEffectsOnly ?? false,
                  upcharge: UtilService.withDecimal(
                    null,
                    fe.upcharge!.toString() as string
                  ),
                  orderNumber: fe.orderNumber,
                } as IFinishEffectField)
            )
          : [
              generateNewFinishEffectField(
                '1',
                finishColor?.showAvailableEffectsOnly ?? false
              ),
            ];

      const woodInteriorOption = woodInterior
        ? UtilService.mapObjectToSelectOptions(
            woodInterior,
            undefined,
            undefined,
            { customParams: ['upcharge'] }
          )
        : null;

      const materialColorOption = materialColor
        ? (UtilService.mapObjectToSelectOptions(
            materialColor,
            undefined,
            undefined,
            { customParams: ['upcharge', 'grainPattern'] }
          ) as SelectOptionProps)
        : null;

      const materialGroupOption = materialGroup
        ? (UtilService.mapObjectToSelectOptions(
            materialGroup,
            undefined,
            undefined,
            { customParams: ['upcharge', 'description', 'woodMaterialId'] }
          ) as SelectOptionProps)
        : null;

      if (materialGroupOption?.woodMaterialId) {
        woodOrMaterialOption = UtilService.generateWoodMaterialOption(
          materialGroupOption.woodMaterialId
        );
      }

      const specs = {
        finishColor: finishColorOption!,
        finishImpression: finishImpressionOption!,
        finishColorUpcharge: UtilService.withDecimal(
          null,
          finishColorOption?.upcharge?.toString() as string
        ),
        finishEffects: finishEffectsMapped,
        finishNotes,
        specialFinishSample: specialFinishSample ?? '',
        specificationOption: finishImpressionOption
          ? SpecificationsOptionsEnum.SELECT_IMPRESSION
          : SpecificationsOptionsEnum.CONFIGURE_WOOD,
        varnishSheen: varnishSheenOption,
        varnishUpcharge: UtilService.withDecimal(
          null,
          varnishSheenOption?.upcharge?.toString() as string
        ),
        selectWoodSpecies,
        showAvailableFinishesOnly,
        woodEffects: woodEffectOptions,
        ...(woodInteriorOption && { woodInterior: woodInteriorOption }),
        woodNotes,
        woodOrMaterial: woodOrMaterialOption,
        woodOrMaterialUpcharge: UtilService.withDecimal(
          null,
          woodOrMaterialOption?.upcharge?.toString() as string
        ),
        engineeredFurnitureBoardConstruction,
        cabinetBoxMaterial: cabinetBoxMaterialOption,

        cabinetBoxMaterialUpcharge: UtilService.withDecimal(
          null,
          cabinetBoxMaterialOption?.upcharge?.toString() as string
        ),
        materialGroup: materialGroupOption,
        materialColor: materialColorOption,
        materialNotes,
        grainDirection: UtilService.mapObjectToSelectOptions(grainDirection),
        materialGroupUpcharge: UtilService.withDecimal(
          null,
          materialGroupOption?.upcharge?.toString() as string
        ),
        materialColorUpcharge: UtilService.withDecimal(
          null,
          materialColorOption?.upcharge?.toString() as string
        ),
        woodTape,
        edgebandColor,
        colorSample,
        naturalInterior,
        specialCabinetBoxMaterial,
        styleNote: note ?? '',
        workOrderNumber: originalWorkOrderNumber ?? workOrderNumber ?? '',
        vendorColorCode: vendorColor?.code ?? '',
        vendorColorName: vendorColor?.name ?? '',
        doorSquareFootPrice,
        miDoorSquareFootPrice,
        interiorNotes,
      } as StyleSpecifications;

      return {
        ...state,
        specifications: specs,
      };
    }
    case orderStylesDoorBuilderActions.SET_STYLE_DOOR_BUILDER_DATA: {
      let updatedDoorBuilder = state.doorBuilder;

      if (state.style) {
        const {
          doorOverlay,
          faceFrame,
          frameStyle,
          noMidrails,

          wallDoorStyle,
          baseDoorStyle,
          frameSize,
          wallDoorConfiguration,
          baseDoorConfiguration,
          archStyle,
          wallDoorInsertPanel,
          baseDoorInsertPanel,
          wallDoorEdge,
          baseDoorEdge,

          metalFinish,

          drawerFrontStyle,
          drawerInsertPanel,
          drawerEdge,
          topInsertPanel,

          doorNotes,
          drawerBox,

          hingeType,
          hingeColor,

          doorHardware,
          drawerHardware,
          closetHardwareColor,
          hardwareNotes,
          metalFinishUpcharge,

          // wallDoorUpcharges,
          // baseDoorUpcharges,
          // drawerUpcharges,
          toeSpaceType,
          interiorNotes,
          doorEdgeBand,
          oneInchDoor,
          oneInchFaceFrame,
          oneInchThickDoorUpcharge,

          materialDrawer,
          hdfPanels,
          note,
          workOrderNumber,
          customFrameSize,
        } = state.style;

        const frameStyleOption =
          UtilService.mapObjectToSelectOptions(frameStyle);

        const doorStyleBase = UtilService.mapObjectToSelectOptions(
          baseDoorStyle,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const doorStyleWall = UtilService.mapObjectToSelectOptions(
          wallDoorStyle,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        let frameSizeOption = UtilService.mapObjectToSelectOptions(
          frameSize,
          'id',
          'size',
          {
            customParams: ['upcharge'],
          }
        ) as SelectOptionProps;

        if (frameSizeOption) {
          frameSizeOption = {
            ...frameSizeOption,
            label: frameSizeOption.upcharge
              ? `${frameSizeOption.label} + $${frameSizeOption.upcharge}`
              : frameSizeOption.label,
          };
        }

        const configurationBase = UtilService.mapObjectToSelectOptions(
          baseDoorConfiguration,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const configurationWall = UtilService.mapObjectToSelectOptions(
          wallDoorConfiguration,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const archStyleOption = archStyle
          ? UtilService.mapObjectToSelectOptions(
              archStyle,
              undefined,
              undefined,
              { customParams: ['imageUrl'] }
            )
          : null;

        const insertPanelBase = UtilService.mapObjectToSelectOptions(
          baseDoorInsertPanel,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const insertPanelWall = UtilService.mapObjectToSelectOptions(
          wallDoorInsertPanel,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const doorEdgeBase = UtilService.mapObjectToSelectOptions(
          baseDoorEdge,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const doorEdgeWall = UtilService.mapObjectToSelectOptions(
          wallDoorEdge,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const metalFinishOption = metalFinish
          ? UtilService.mapObjectToSelectOptions(
              metalFinish,
              undefined,
              undefined,
              { customParams: ['imageUrl', 'upcharge'] }
            )
          : null;

        const drawerStyleOption = UtilService.mapObjectToSelectOptions(
          drawerFrontStyle,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const drawerInsertPanelOption = UtilService.mapObjectToSelectOptions(
          drawerInsertPanel,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const drawerEdgeOption = UtilService.mapObjectToSelectOptions(
          drawerEdge,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const topInsertPanelOption = topInsertPanel
          ? UtilService.mapObjectToSelectOptions(
              topInsertPanel,
              undefined,
              undefined,
              { customParams: ['imageUrl'] }
            )
          : null;

        const hingeTypeOption = UtilService.mapObjectToSelectOptions(
          hingeType,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const hingeColorOption = UtilService.mapObjectToSelectOptions(
          hingeColor,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const drawerHardwareOption =
          UtilService.mapObjectToSelectOptions(drawerHardware);

        const drawerBoxOption = UtilService.mapObjectToSelectOptions(drawerBox);

        const doorHardwareOption =
          UtilService.mapObjectToSelectOptions(doorHardware);

        const closetHardwareColorOption = closetHardwareColor
          ? UtilService.mapObjectToSelectOptions(
              closetHardwareColor,
              undefined,
              undefined,
              { customParams: ['imageUrl'] }
            )
          : null;

        const toeSpaceTypeOption = UtilService.mapObjectToSelectOptions(
          toeSpaceType,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        const doorEdgeBandOption =
          UtilService.mapObjectToSelectOptions(doorEdgeBand);

        const materialDrawerOption = UtilService.mapObjectToSelectOptions(
          materialDrawer,
          undefined,
          undefined,
          { customParams: ['imageUrl'] }
        );

        updatedDoorBuilder = {
          ...state.doorBuilder,
          doorOverlay,
          faceFrame,
          frameStyle: frameStyleOption,
          midrails: noMidrails,

          doorStyleWall,
          doorStyleBase,
          frameSize: frameSizeOption,
          configurationWall,
          configurationBase,
          archStyle: archStyleOption,
          insertPanelWall,
          insertPanelBase,
          doorEdgeWall,
          doorEdgeBase,

          metalFinish: metalFinishOption,
          metalFinishUpcharge:
            metalFinishUpcharge ??
            UtilService.withDecimal(
              null,
              (metalFinish?.upcharge ?? 0).toString()
            ),

          drawerStyle: drawerStyleOption,
          drawerInsertPanel: drawerInsertPanelOption,
          topDrawerInsertPanel: topInsertPanelOption,
          drawerEdge: drawerEdgeOption,

          drawerBox: drawerBoxOption,
          doorNotes,

          hingeType: hingeTypeOption,
          hingeTypeAdd: UtilService.withDecimal(
            null,
            hingeType.adder?.toString() ?? '0'
          ),
          hingeTypeQty: hingeType.quantity?.toString() ?? '',
          hingeColor: hingeColorOption,
          drawerHardware: drawerHardwareOption,
          doorHardware: doorHardwareOption,
          drawerHardwareAdd: UtilService.withDecimal(
            null,
            drawerHardware?.adder?.toString() ?? '0'
          ),
          drawerHardwareQty: drawerHardware?.quantity?.toString() ?? '0',
          doorHardwareAdd: UtilService.withDecimal(
            null,
            doorHardware?.adder?.toString() ?? '0'
          ),
          doorHardwareQty: doorHardware?.quantity?.toString() ?? '0',
          closetHardwareColor: closetHardwareColorOption,
          hardwareNotes,
          toeSpaceType: toeSpaceTypeOption,
          interiorNotes,
          doorEdgeBand: doorEdgeBandOption,
          oneInchDoor,
          oneInchThickDoorUpcharge: UtilService.withDecimal(
            null,
            oneInchThickDoorUpcharge?.toString() ?? '0'
          ),
          materialDrawer: materialDrawerOption,
          oneInchFaceFrame,
          hdfPanels,
          styleNote: note ?? '',
          workOrderNumber: workOrderNumber ?? '',
          customFrameSize,
        } as StyleDoorBuilder;
      }

      return {
        ...state,
        doorBuilder: updatedDoorBuilder,
      };
    }

    case orderStylesSpecificationActions.UPDATE_SPEC_OPTION: {
      return {
        ...state,
        specifications: {
          ...state.specifications,
          specificationOption: action.payload as SpecificationsOptionsEnum,
        } as StyleSpecifications,
      };
    }

    case orderStylesSpecificationActions.SET_SPECS_NOTES: {
      return {
        ...state,
        specifications: {
          ...state.specifications,
          woodNotes: action.payload.woodNotes as string,
          finishNotes: action.payload.finishNotes as string,
        } as StyleSpecifications,
      };
    }

    case orderStylesSpecificationActions.SET_FINISH_COLORS: {
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          finishColorOptions: action.payload as SelectOptionProps[],
        },
      };
    }
    case orderStylesSpecificationActions.CLEAR_FINISH_COLORS: {
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          finishColorOptions: null,
        },
      };
    }
    case orderStylesSpecificationActions.SET_SPECS_FINISH_IMPRESSION_OPTION: {
      const { finishImpressionOptions } = state.specificationsOptions;

      const finishImpression = finishImpressionOptions?.find(
        (fiOption) => fiOption.value === action.payload
      ) as SelectOptionProps;

      return {
        ...state,
        specifications: {
          ...state.specifications,
          finishImpression,
        } as StyleSpecifications,
      };
    }
    case orderStylesSpecificationActions.SET_WOOD_EFFECT_OPTIONS_2:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          woodEffectOptions2: action.payload as SelectOptionProps[],
        },
      };

    case orderStylesSpecificationActions.SET_FINISH_IMPRESSION_OPTIONS: {
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          finishImpressionOptions: action.payload! as SelectOptionProps[],
        } as SpecificationsOptions,
      };
    }

    case orderStylesSpecificationActions.SET_FINISH_IMPRESSION_DATA:
      return {
        ...state,
        finishImpressionData: action.payload as ImpressionData,
      };

    // DOOR BUILDER CASES
    case orderStylesDoorBuilderActions.SET_DOOR_OVERLAYS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorOverlayOptions: action.payload as StyleFieldOption[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_FACE_FRAMES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          faceFrameOptions: action.payload as StyleFieldOption[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_FRAME_STYLES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          frameStyleOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_MIDRAILS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          midrailsOptions: action.payload as Midrails,
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DOOR_STYLE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorStyleWallOptions: action.payload as SelectOptionProps[],
          doorStyleBaseOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_FRAME_SIZE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          frameSizeOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_CONFIG:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          configurationOptions: action.payload as ConfigOptions,
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_ARCH_STYLE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          archStyleOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_INSERT_PANEL_WALL:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          insertPanelWallOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_INSERT_PANEL_BASE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          insertPanelBaseOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DOOR_EDGE_WALL:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorEdgeWallOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DOOR_EDGE_BASE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorEdgeBaseOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_METAL_FINISH:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          metalFinishOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DRAWER_BOX:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          drawerBoxOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_HINGE_TYPE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          hingeTypeOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_HINGE_COLORS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          hingeColorOptions: action.payload as HingeColorOptions,
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DRAWER_STYLES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          drawerStyleOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DRAWER_EDGES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          drawerEdgeOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DRAWER_INSERT_PANEL:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          drawerInsertPanelOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_TOP_DRAWER_INSERT_PANEL:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          topDrawerInsertPanelOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_HARDWARE:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          hardwareOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_CLOSET_HARDWARE_COLORS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          closetHardwareColorOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_DOOR_CODES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorCodes: action.payload as IDoorCode,
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.CLEAR_OPTIONS: {
      const resetAllFields = action.payload;
      let options = { ...state.doorBuilderOptions };

      if (resetAllFields) {
        const doorBuilderOptions =
          action.payload as (keyof DoorBuilderOptions)[];
        doorBuilderOptions.forEach((option) => {
          options[option] = null;
        });
      } else {
        options = { ...initialState.doorBuilderOptions };
      }

      return {
        ...state,
        doorBuilderOptions: options as DoorBuilderOptions,
      };
    }

    case orderStylesDoorBuilderActions.SET_HARDWARE_QUANTITIES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          hardwareQuantities: action.payload as LineItemHardware,
        } as DoorBuilderOptions,
      };
    case orderStylesSpecificationActions.SET_CABINETBOX_MATERIALS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          cabinetBoxMaterialOptions: action.payload as SelectOptionProps[],
        } as SpecificationsOptions,
      };
    case orderStylesDoorBuilderActions.SET_TOESPACE_TYPES:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          toeSpaceTypeOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesSpecificationActions.SET_MATERIAL_GROUPS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          materialGroupOptions: action.payload as SelectOptionProps[],
        } as SpecificationsOptions,
      };
    case orderStylesSpecificationActions.SET_MATERIAL_COLORS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          materialColorOptions: action.payload as SelectOptionProps[],
        } as SpecificationsOptions,
      };
    case orderStylesSpecificationActions.SET_GRAIN_DIRECTIONS:
      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          grainDirectionOptions: action.payload as SelectOptionProps[],
        } as SpecificationsOptions,
      };
    case orderStylesDoorBuilderActions.SET_DOOR_EDGE_BANDS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          doorEdgeBandOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.SET_MATERIAL_DRAWERS:
      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          materialDrawerOptions: action.payload as SelectOptionProps[],
        } as DoorBuilderOptions,
      };
    case orderStylesDoorBuilderActions.RESET_DOOR_BUILDER: {
      return {
        ...state,
        doorBuilder: null,
        doorBuilderOptions: {
          ...initialState.doorBuilderOptions,
          doorCodes: state.doorBuilderOptions.doorCodes,
        } as DoorBuilderOptions,
      };
    }
    case orderStylesSpecificationActions.SET_SPECS_OPTIONS_BY_OPTIONS_KEY: {
      const { optionsKey, options } =
        action.payload as SetSpecsOptionsByOptionsKeyPayload;

      return {
        ...state,
        specificationsOptions: {
          ...state.specificationsOptions,
          [optionsKey]: options,
        } as SpecificationsOptions,
      };
    }
    case orderStylesDoorBuilderActions.SET_DOOR_BUILDER_OPTIONS_BY_OPTIONS_KEY: {
      const { optionsKey, options } =
        action.payload as SetDoorBuilderOptionsByOptionsKeyPayload;

      return {
        ...state,
        doorBuilderOptions: {
          ...state.doorBuilderOptions,
          [optionsKey]: options,
        } as DoorBuilderOptions,
      };
    }
    case orderStylesSpecificationActions.SET_STYLE_ADJUSTMENT_DATA:
      return {
        ...state,
        style: {
          ...state.style,
          adjustment: action.payload,
        } as Style,
      };
    default:
      return state;
  }
};
