import { OrderStatusEnums } from 'order/enums/orderEnums';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useCanEditOrderBeforeStatus = (status: OrderStatusEnums) => {
  const [beforeStatus, setBeforeStatus] = useState<boolean | null>(null);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  useEffect(() => {
    if (order) {
      setBeforeStatus((order.status?.id ?? 0) < status);
    }
  }, [order]);

  return beforeStatus;
};
