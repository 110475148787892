import { useMemo } from 'react';
import { InfoModal } from 'shared/components/InfoModal';

export interface UseModalProps {
  opened: boolean;
  close: () => void;
  message: string;
}

const useModal = ({ opened, close, message }: UseModalProps) => {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  const PopupModal = useMemo(() => {
    return (
      <InfoModal
        close={close}
        message={message.replace(regex, '<br>')}
        opened={opened}
        title="Important"
      />
    );
  }, [opened]);

  return {
    PopupModal,
  };
};

export default useModal;
