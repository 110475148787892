import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { lockOrderForEdit } from 'order/store/orderActions';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

import { ButtonPrimary, TabbableButton } from 'shared/components/Button';

import { OrderAsapParams } from '../../interface/OrderAsapParams';

import OrderAttachments from '../../../orderAttachments/OrderAttachments';
import { lynch } from '../../../../../shared/config/Colors';
import { P } from '../../../../../shared/components/Typography';
import {
  copySelectedAttachments,
  setCheckedAttachments,
} from '../../store/OrderAsapActions';
import { RootState } from '../../../../../store';
import { getAttachmentsForCheckedLineItems } from '../../../orderAttachments/store/orderAttachmentsActions';

const OrderSelectItemsContainer = styled.div``;

const OrderAttachmentsStep = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const checkedLineItems = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedLineItems
  );

  const attachmentsForCheckedLineItems = useSelector(
    (state: RootState) => state.orderReducer.attachmentsForCheckedLineItems
  );

  const checkedAttachments = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedAttachments
  );

  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  const [copingAttachmentsLoading, setCopingAttachmentsLoading] =
    useState(false);

  const [queryFields] = useQueryParams<OrderAsapParams>([
    'orderId',
    'newOrderId',
  ]);

  const orderId = queryFields?.orderId;

  const lockOrderForEditSuccessHandler = () => {
    history.push(`/order/${queryFields?.newOrderId}/overview`, location.state);
  };

  const redirectToOrderOverview = () => {
    setCopingAttachmentsLoading(true);
    dispatch(setCheckedAttachments(null));
    if (queryFields) {
      dispatch(
        lockOrderForEdit(
          { orderId: queryFields.newOrderId },
          lockOrderForEditSuccessHandler,
          undefined,
          setCopingAttachmentsLoading
        )
      );
    }
  };

  const onCopyAttachmentsSuccessHandler = () => {
    redirectToOrderOverview();
    if ((checkedAttachments?.length ?? 0) > 0) {
      toast.success(
        `You have successfully copy attachments to the newly created order.`
      );
    }
  };

  const onFinishClickHandler = () => {
    if (queryFields) {
      setCopingAttachmentsLoading(true);
      dispatch(
        copySelectedAttachments(
          {
            copyOrderId: queryFields.newOrderId,
            orderId: queryFields.orderId,
            attachmentIds: checkedAttachments
              ? checkedAttachments.map((attachment) => attachment.id!)
              : [],
          },
          onCopyAttachmentsSuccessHandler,
          setCopingAttachmentsLoading
        )
      );
    }
  };

  const handleSelectAllAttachments = () => {
    if (attachmentsForCheckedLineItems) {
      dispatch(setCheckedAttachments(attachmentsForCheckedLineItems));
    }
  };

  const handleUnSelectAllAttachments = () => {
    dispatch(setCheckedAttachments([]));
  };

  const failedToReceiveOrderAttachments = () => {
    toast.error('Could not retrieve order attachments.');
    setAttachmentsLoading(false);
  };

  useEffect(() => {
    if (orderId) {
      dispatch(
        getAttachmentsForCheckedLineItems(
          {
            orderId,
            lineItemIds: checkedLineItems ?? [],
          },
          setAttachmentsLoading,
          failedToReceiveOrderAttachments
        )
      );
    }
  }, [checkedLineItems, orderId]);

  return (
    <OrderSelectItemsContainer>
      {attachmentsLoading ? (
        <Loader noSpacing size={50} />
      ) : (
        <>
          {(attachmentsForCheckedLineItems?.length ?? 0) > 0 && (
            <Wrapper flex middle>
              <TabbableButton
                color={lynch}
                onClick={handleSelectAllAttachments}
              >
                Select all
              </TabbableButton>
              <Spacer w="5px" />
              <P color={lynch}>-</P>
              <Spacer w="5px" />
              <TabbableButton
                color={lynch}
                onClick={handleUnSelectAllAttachments}
              >
                Clear
              </TabbableButton>
            </Wrapper>
          )}

          <Spacer h="32px" />

          <OrderAttachments fromStepper />

          <OrderFooter>
            <Wrapper flex middle justifyEnd>
              <ButtonPrimary
                disabled={copingAttachmentsLoading}
                onClick={onFinishClickHandler}
              >
                Finish
                <Loader
                  hidden={!copingAttachmentsLoading}
                  insideButton
                  size={16}
                  noSpacing
                />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>
        </>
      )}
    </OrderSelectItemsContainer>
  );
};

export default OrderAttachmentsStep;
