import { call, put, takeEvery } from 'redux-saga/effects';

import { Popup } from 'order/popups/interface/Popup';

import { Action } from 'shared/interface/Action';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

import { GetPopupsRequest, PopupActiontTypes, setPopups } from './popupActions';

function* getPopups(action: Action<GetPopupsRequest>) {
  try {
    const queryParams = new URLSearchParams();
    if (action.payload && action.payload?.entityTypes.length) {
      queryParams.append(
        'entityTypes',
        JSON.stringify(action.payload?.entityTypes)
      );
    }

    const response: Popup[] = yield call(
      ApiService.get,
      `/api/catalog/popups/`,
      { params: queryParams }
    );

    yield put(setPopups(response));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* popupSagas() {
  yield takeEvery(PopupActiontTypes.GET_POPUPS, getPopups);
}
