import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';

import { sendSpecialOrderApproval } from '../store/ACKActions';

interface SpecialOrderApprovalModalProps {
  close: () => void;
}

interface SpecialOrderApprovalForm {
  note: string;
}

const SpecialOrderApprovalModalContainer = styled.div``;

const SpecialOrderApprovalModal: FC<SpecialOrderApprovalModalProps> = ({
  close,
}) => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<SpecialOrderApprovalForm>({
    defaultValues: {
      note: '',
    },
  });

  const onSubmit = (data: SpecialOrderApprovalForm) => {
    setLoading(true);

    dispatch(
      sendSpecialOrderApproval(
        { orderId, note: data.note },
        () => close(),
        setLoading
      )
    );
  };

  return (
    <SpecialOrderApprovalModalContainer>
      <H2 fontSize={24}>Special Order Approval</H2>

      <Spacer h="16px" />

      <P fontSize={16} color={nevada}>
        Send Special Order Approval request to the dealer for the confirmation.
      </P>

      <Spacer h="32px" />

      <Form>
        <FormElement noMarginBottom>
          <FormLabel>Note</FormLabel>
          <Textarea {...register('note')} />
        </FormElement>
      </Form>

      <Spacer h="32px" />

      <Wrapper flex middle justifyEnd>
        <ButtonSecondary onClick={close}>Cancel</ButtonSecondary>
        <Spacer w="15px" />
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          Send
          <Loader insideButton size={16} noSpacing hidden={!loading} />
        </ButtonPrimary>
      </Wrapper>
    </SpecialOrderApprovalModalContainer>
  );
};

export default SpecialOrderApprovalModal;
