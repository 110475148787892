import { FC, useState } from 'react';

import {
  StyleNotes,
  WritingHints,
} from 'order/writingHints/interface/WritingHints';

import { PSmall } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';

import Drawings from './Drawings';
import SVG from '../../../../../../shared/components/SVG';
import SpecialOrderItems from './SpecialOrderItems';
import StyleWarnings from '../../Warnings/StyleWarnings';
import { ButtonIcon } from '../../../../../../shared/components/Button';
import { ItemRow } from '../../ItemRow';
import { ReactComponent as ArrowDownIcon } from '../../../../../../assets/icons/arrow-down.svg';
import { StyleHeader } from '../../../../../../shared/components/StyleHeader';
import { Wrapper } from '../../../../../../shared/components/Wrapper';

interface StyleNotesProps {
  styleNotes: StyleNotes;
  writingHints: WritingHints;
}

const ignoredKeys = ['id', 'name', 'number'];

export const StyleNotesContainer: FC<StyleNotesProps> = ({
  styleNotes,
  writingHints,
}) => {
  const [expanded, setExpanded] = useState(true);

  const notesAreEmpty = !Object.entries(styleNotes).some(([key, value]) => {
    return !ignoredKeys.includes(key) && value;
  });

  const styleWarning = writingHints.warningsResponse.styleWarnings.find(
    (warnings) => warnings.id === styleNotes.id
  );

  const specialOrderItems = writingHints.specialOrderDrawingsResponse.filter(
    (specialOrderDrawing) => {
      const hasAtLeastOneSpecialOrderModifier =
        specialOrderDrawing.specialOrderModifications.some(
          (specialOrderMod) => specialOrderMod.specialOrder
        );
      return (
        specialOrderDrawing.styleId === styleNotes.id &&
        (specialOrderDrawing.specialOrder || hasAtLeastOneSpecialOrderModifier)
      );
    }
  );

  const drawings = writingHints.specialOrderDrawingsResponse.filter(
    (specialOrderDrawing) => {
      const hasSpecialOrderModificationDrawing =
        specialOrderDrawing.specialOrderModifications.some(
          (specialOrderMod) =>
            specialOrderMod.perFactoryDrawing || specialOrderMod.perDrawing
        );
      return (
        specialOrderDrawing.styleId === styleNotes.id &&
        (specialOrderDrawing.perFactoryDrawing ||
          specialOrderDrawing.perDrawing ||
          hasSpecialOrderModificationDrawing)
      );
    }
  );

  return (
    <>
      <Wrapper onClick={() => setExpanded((prevState) => !prevState)}>
        <StyleHeader fontWeight={600} fontSize={12} collapsable>
          #{styleNotes.number} {styleNotes.name}
          <ButtonIcon
            className={
              expanded
                ? 'expandable-box-button expanded'
                : 'expandable-box-button'
            }
          >
            <SVG
              animateTransform={['transform', 'borderRadius']}
              icon={<ArrowDownIcon />}
            />
          </ButtonIcon>
        </StyleHeader>
      </Wrapper>

      {expanded && (
        <>
          {!notesAreEmpty && (
            <>
              <ItemRow>
                <PSmall bold>#</PSmall>
                <PSmall bold>Notes</PSmall>
              </ItemRow>

              {Object.entries(styleNotes)
                .filter(
                  ([key, value]) => !ignoredKeys.includes(key) && value !== null
                )
                .map(([key, value]) => (
                  <ItemRow noteRow>
                    <PSmall bold color={nevada}>
                      {(key === 'notes'
                        ? 'style'
                        : key.replace('Notes', '')
                      ).toUpperCase()}
                    </PSmall>
                    <PSmall color={nevada}>{value}</PSmall>
                  </ItemRow>
                ))}
            </>
          )}

          {/* Special Order Items section - Filter order drawings respose by styleId and specialOrder true */}
          {specialOrderItems.length > 0 && (
            <SpecialOrderItems specialOrderItems={specialOrderItems} />
          )}

          {styleWarning && (
            <StyleWarnings
              styleWraning={styleWarning}
              noStyleHeader
              withNumberOrder
              fontWeight={400}
              sectionTitle={
                <ItemRow>
                  <PSmall bold>#</PSmall>
                  <PSmall bold>Warnings</PSmall>
                </ItemRow>
              }
            />
          )}

          {/* filter special order drawings based on perFactoryDrawing and perDrawing */}
          {drawings.length > 0 && <Drawings drawings={drawings} />}
        </>
      )}
    </>
  );
};
