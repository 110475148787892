import { FC } from 'react';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';

import { PSmall } from 'shared/components/Typography';
import UtilService from 'shared/services/util.service';

interface TopicMetaProps {
  topic: ICurtainTopic;
}

export const TopicMeta: FC<TopicMetaProps> = ({ topic }) => {
  return (
    <PSmall>
      {[
        topic.participants.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}, `
        ),
        UtilService.formatDate({
          date:
            topic.lastCommentUtc?.toString() ?? topic.createdOnUtc.toString(),
          asElement: false,
        }),
      ]}
      {topic.private && ' (Private)'}
    </PSmall>
  );
};
