import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import FormError from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import { ButtonSecondary, ButtonPrimary } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
  zipCodeValidation,
  phoneNumberValidation,
  specialCharactersNotAllowedValidation,
} from 'shared/validations/validations';

import { orderActions } from 'order/store/orderActions';
import { dealershipActions } from '../store/dealershipActions';

interface CustomAddressFormProps {
  onClose: () => void;
  afterCancel?: () => void;
  afterSubmit?: (address: ShippingAddress) => void;
  editMode?: boolean;
  data?: ShippingAddress;
  dontCallApi?: boolean;
  orderId?: string;
}

interface FormInputs {
  shipTo: string;
  street: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  saveToCustom?: boolean;
}

const CustomFormRow = styled(Wrapper)`
  > div {
    margin-left: 14px;
  }

  div:nth-child(1) {
    margin-left: 0;
  }
`;

export const CustomAddressForm = ({
  onClose,
  afterCancel,
  afterSubmit,
  dontCallApi,
  editMode,
  data,
  orderId,
}: CustomAddressFormProps) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const dispatch = useAppDispatch();

  const dealershipId = useSelector(
    (state: RootState) => state.dealershipReducer.dealership?.id
  );

  useEffect(() => {
    if (!editMode) {
      setValue('country', 'USA', { shouldValidate: true });
      setValue('saveToCustom', true, { shouldValidate: true });
    }

    if (editMode && data != null) {
      reset({
        ...data,
        addressLineTwo: data.number,
      });
    }

    setValue('saveToCustom', true, { shouldValidate: true });
  }, []);

  const afterSuccessfulSubmit = (message: string) => {
    onClose();
    toast.success(message);
    setLoading(false);
  };

  const afterFailedSubmit = (message: string) => {
    onClose();
    toast.error(message);
    setLoading(false);
  };

  const onSubmit = (formData: FormInputs) => {
    // when address needs to be saved directly from modal
    setLoading(true);

    if (!dontCallApi) {
      if (editMode) {
        dispatch({
          type: orderId
            ? orderActions.CREATE_NON_EXISTING_SHIPPING_ADDRESS
            : dealershipActions.UPDATE_DEALERSHIP_CUSTOM_ADDRESS,
          payload: {
            ...formData,
            dealershipId,
            addressId: orderId ? data?.originalAddressId : data!.id,
            number: formData.addressLineTwo ? formData.addressLineTwo : null,
            orderId,
            editMode,
          },
          onSuccess: () =>
            afterSuccessfulSubmit(
              'You have successfully updated the selected shipping address.'
            ),
          onFailed: () => afterFailedSubmit(`Couldn't edit address.`),
        });
      } else {
        dispatch({
          type: dealershipActions.ADD_DEALERSHIP_CUSTOM_ADDRESS,
          payload: {
            ...formData,
            dealershipId,
            number: formData.addressLineTwo ? formData.addressLineTwo : null,
          },
          onSuccess: () =>
            afterSuccessfulSubmit(
              'You have successfully added a new shipping address.'
            ),
          onFailed: () => afterFailedSubmit(`Couldn't add address.`),
        });
      }
    } else {
      // when address does not need to be saved directly in modal
      afterSubmit!(
        { ...formData, number: formData.addressLineTwo }! as ShippingAddress
      );
      onClose();
    }
  };

  const onCancel = () => {
    onClose();

    if (afterCancel) {
      afterCancel();
    }
  };

  return (
    <>
      <Form>
        <FormElement>
          <FormLabel>Ship to Name</FormLabel>
          <Input
            type="text"
            placeholder="Enter..."
            {...register(
              'shipTo',
              specialCharactersNotAllowedValidation({
                required: true,
                maxLength: 50,
              })
            )}
            aria-invalid={errors.shipTo ? 'true' : 'false'}
          />

          <FormError
            label="Ship to Name"
            error={errors.shipTo}
            validationSchema={{ required: true, maxLength: 50 }}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Street Address</FormLabel>
          <Input
            type="text"
            placeholder="Enter street address"
            {...register(
              'street',
              specialCharactersNotAllowedValidation({
                required: true,
                maxLength: 50,
              })
            )}
            aria-invalid={errors.street ? 'true' : 'false'}
          />

          <FormError
            label="Street Address"
            error={errors.street}
            validationSchema={specialCharactersNotAllowedValidation({
              required: true,
              maxLength: 50,
            })}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            type="text"
            placeholder="Enter address line 2"
            {...register(
              'addressLineTwo',
              specialCharactersNotAllowedValidation({
                maxLength: 50,
              })
            )}
            aria-invalid={errors.addressLineTwo ? 'true' : 'false'}
          />

          <FormError
            label="Address Line 2"
            error={errors.addressLineTwo}
            validationSchema={specialCharactersNotAllowedValidation({
              required: true,
              maxLength: 50,
            })}
          />
        </FormElement>

        <CustomFormRow flex>
          <FormElement>
            <FormLabel>City</FormLabel>
            <Input
              type="text"
              placeholder="Enter city"
              {...register(
                'city',
                specialCharactersNotAllowedValidation({
                  required: true,
                  maxLength: 50,
                })
              )}
              aria-invalid={errors.city ? 'true' : 'false'}
            />

            <FormError
              label="City"
              error={errors.city}
              validationSchema={{ required: true, maxLength: 50 }}
            />
          </FormElement>

          <FormElement>
            <FormLabel>State</FormLabel>

            <Input
              type="text"
              placeholder="Enter state"
              {...register(
                'state',
                specialCharactersNotAllowedValidation({
                  required: true,
                  maxLength: 2,
                })
              )}
              aria-invalid={errors.state ? 'true' : 'false'}
            />

            <FormError
              label="State"
              error={errors.state}
              validationSchema={specialCharactersNotAllowedValidation({
                required: true,
                maxLength: 2,
              })}
            />
          </FormElement>

          <FormElement>
            <FormLabel>Zip Code</FormLabel>

            <Input
              type="text"
              placeholder="Enter zip code"
              {...register('zipCode', zipCodeValidation({ required: true }))}
              aria-invalid={errors.zipCode ? 'true' : 'false'}
            />

            <FormError
              label="Zip Code"
              error={errors.zipCode}
              validationSchema={zipCodeValidation()}
            />
          </FormElement>
        </CustomFormRow>

        <FormElement>
          <FormLabel>Country</FormLabel>
          <Input
            type="text"
            placeholder="Enter country"
            {...register(
              'country',
              specialCharactersNotAllowedValidation({
                required: true,
                maxLength: 50,
              })
            )}
            aria-invalid={errors.country ? 'true' : 'false'}
          />
          <FormError
            label="Country"
            error={errors.country}
            validationSchema={specialCharactersNotAllowedValidation({
              required: true,
              maxLength: 50,
            })}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Phone Number</FormLabel>

          <Input
            type="text"
            placeholder="Enter phone number"
            {...register(
              'phoneNumber',
              phoneNumberValidation({ required: true })
            )}
            aria-invalid={errors.phoneNumber ? 'true' : 'false'}
          />

          <FormError
            label="Phone Number"
            error={errors.phoneNumber}
            validationSchema={phoneNumberValidation({ required: true })}
          />
        </FormElement>

        <Checkbox
          {...register('saveToCustom')}
          id="saveToCustom"
          title="Save for later"
        />

        <Wrapper flex justifyEnd>
          <ButtonSecondary onClick={onCancel} type="button">
            Cancel
          </ButtonSecondary>
          <Spacer w="20px" />
          <ButtonPrimary
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={loading || !isDirty}
          >
            {editMode ? 'Update Address' : 'Add Address'}

            <Loader hidden={!loading} insideButton noSpacing size={16} />
          </ButtonPrimary>
        </Wrapper>
      </Form>
    </>
  );
};

CustomAddressForm.defaultProps = {
  editMode: false,
  afterCancel: () => {
    // handle after cancel
  },
  afterSubmit: () => {
    // handle after submit
  },
  data: {},
  dontCallApi: false,
  orderId: '',
};
