import Popup from 'reactjs-popup';
import styled from 'styled-components';

import 'reactjs-popup/dist/index.css';

export const ModalWrapper = styled(Popup)`
  &-overlay {
    &:not(.modal--full-screen-overlay) {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 20px;
    }

    &.background-scrollable-overlay {
      pointer-events: none !important;
    }
  }

  &-content {
    width: auto;
    border-radius: 16px;

    &.modal--full-screen-content {
      width: 100%;
      height: 100vh;
      margin-bottom: 0 !important;
      background: ${({ theme }) => theme.wildSand};
      border-radius: 0;
      padding: 0;
    }

    &.radio-button-modal-content {
      border-radius: 16px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border: none;
      width: 277px;
    }

    &.background-scrollable-content {
      /* opacity: 0.5; */
      /* transition: opacity 300ms ease;

      &:hover {
        opacity: 1;
      } */
    }
  }
`;
