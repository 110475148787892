import styled from 'styled-components';
import { FC } from 'react';

import UtilService from 'shared/services/util.service';
import { H4 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { OrderLogsSubTabEnum } from '../../enums/OrderLogsSubTabEnum';

const OrderLogsSubHeaderContainer = styled(Wrapper)`
  margin-top: 15px;
`;

const OrderLogsSubTab = styled(H4)<{ active: boolean }>`
  padding: 5px 0 5px 32px;
  transition: 300ms ease;
  cursor: pointer;

  color: ${({ theme, active }) =>
    active ? theme.mineShaft : theme.silverSandTwo};

  &:hover {
    color: ${({ theme, active }) =>
      !active && UtilService.colorWithOpacity(theme.mineShaft, 0.5)};
  }
`;

interface OrderLogsSubHeaderProps {
  subTab: OrderLogsSubTabEnum | null;
  setSubTab: (subTab: OrderLogsSubTabEnum | null) => void;
}

const OrderLogsSubHeader: FC<OrderLogsSubHeaderProps> = ({
  subTab,
  setSubTab,
}) => {
  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  return (
    <OrderLogsSubHeaderContainer flex>
      <OrderLogsSubTab
        active={subTab === OrderLogsSubTabEnum.WARNINGS}
        onClick={() => setSubTab(OrderLogsSubTabEnum.WARNINGS)}
      >
        {OrderLogsSubTabEnum.WARNINGS}
      </OrderLogsSubTab>

      {isUserCSR && (
        <OrderLogsSubTab
          active={subTab === OrderLogsSubTabEnum.WRITING_HINTS}
          onClick={() => setSubTab(OrderLogsSubTabEnum.WRITING_HINTS)}
        >
          {OrderLogsSubTabEnum.WRITING_HINTS}
        </OrderLogsSubTab>
      )}
    </OrderLogsSubHeaderContainer>
  );
};

export default OrderLogsSubHeader;
