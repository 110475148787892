import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import StaticField from 'order/components/StaticField/StaticField';

import { Spacer } from 'shared/components/Layout';
import { H3 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';

import { ICSStylesOverviewTestLabelEnum } from 'tests/enums/ICSStylesOverviewTestEnums';

import { ICSOverviewDoorBuilder } from './ICSOverviewDoorBuilder';

const OverviewMaterialContainer = styled(Wrapper)``;

export const ICSOverviewWood = () => {
  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  return (
    <OverviewMaterialContainer flex>
      <Wrapper flexGrow>
        <StaticField
          field="Original W.O #"
          value={storedStyle?.originalWorkOrderNumber || ''}
        />

        <StaticField field="Style Notes" value={storedStyle?.note || ''} />

        <Spacer h="10px" />

        <H3>Material</H3>
        <Spacer h="30px" />

        <StaticField
          field="Wood / Material"
          value={storedStyle?.materialGroup?.name}
        />

        <StaticField
          field="Wood effect"
          value={
            storedStyle?.woodEffects
              ?.map((woodEffect) => woodEffect.name)
              .join('<br/>') || ''
          }
        />

        <StaticField
          field="Wood / Material notes"
          value={storedStyle?.woodNotes}
        />

        <StaticField field="Style Notes" value={storedStyle?.note || ''} />

        <StaticField
          field="Special finish sample"
          value={storedStyle?.specialFinishSample || ''}
        />

        <StaticField
          field="Finish effect"
          value={
            storedStyle?.finishEffects
              ?.map((finishEffect) => finishEffect.name)
              .join('<br/>') || ''
          }
        />

        <StaticField
          field="Varnish Sheen"
          value={storedStyle?.varnish.name || ''}
        />

        <StaticField
          field="Finish notes"
          value={storedStyle?.finishNotes || ''}
        />

        <Spacer h="20px" />

        <H3>Color</H3>
        <Spacer h="30px" />

        <StaticField
          field="Material Color"
          value={storedStyle?.materialColor.name || ''}
          fieldTestId={ICSStylesOverviewTestLabelEnum.MATERIAL_COLOR_LBL}
        />

        <StaticField
          field="Finish Color"
          value={storedStyle?.finishColor?.name || ''}
          fieldTestId={ICSStylesOverviewTestLabelEnum.FINISH_COLOR_LBL}
        />
      </Wrapper>

      <Spacer w="216px" />

      <ICSOverviewDoorBuilder />
    </OverviewMaterialContainer>
  );
};
