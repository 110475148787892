import { FC, ReactElement } from 'react';

import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { Style } from 'order/wizard/orderStyles/interface/Style';

interface OrderFinishValueProps {
  style?: Partial<Style> | null;
  defaultValue?: string;
  render: (value: string) => ReactElement;
}

const OrderFinishValue: FC<OrderFinishValueProps> = ({
  style,
  defaultValue,
  render,
}) => {
  const { finishColor, materialColor, productLine, finishImpression } =
    style ?? {};

  const getFinishValue = () => {
    if (finishImpression) return finishImpression.name ?? defaultValue!;

    if (
      materialColor &&
      (productLine?.name === ProductLineEnums.PRODUCT_LINE_INOVAE2O ||
        productLine?.name === ProductLineEnums.PRODUCT_LINE_ICS)
    )
      return materialColor?.name || defaultValue!;

    return (finishColor?.name ?? materialColor?.name) || defaultValue!;
  };

  return render(getFinishValue());
};

OrderFinishValue.defaultProps = {
  defaultValue: 'N/A',
};

export default OrderFinishValue;
