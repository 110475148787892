import { Link, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import {
  OrderStatusEnums,
  OrderStylizationTypeEnums,
  OrderTabsEnums,
} from 'order/enums/orderEnums';

import { LinkText } from 'shared/components/LinkText';
import { TabStyles } from 'shared/components/TabStyles';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { containerWidth } from '../../../shared/config/Variables';

const OrderTabsContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.mischka};
  left: 0;
  position: fixed;
  right: 0;
  top: 65px;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: ${containerWidth}px;
  padding: 0 30px;
`;

const OrderTabLink = styled(Link)`
  ${TabStyles};
`;

const OrderTabs = () => {
  const location = useLocation();

  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  const editModeBaseUrl = `/order/${orderId}`;

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const withoutStyling =
    order?.stylizationType === OrderStylizationTypeEnums.SALES_MATERIAL;

  const isOrderSubmitted = useIsOrderInStatus({
    greaterThen: true,
    status: OrderStatusEnums['Not Submitted'],
  });

  const isOrderAcknowledged = useIsOrderInStatus({
    status: OrderStatusEnums['Drawing Completed'],
    greaterThen: true,
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const [
    basicInfo,
    adjustments,
    styles,
    lineItems,
    attachments,
    orderOverview,
    reviewPricing,
    acknowledgment,
  ] = [
    'Basic info',
    'Adjustments',
    'Styles',
    'Line items',
    'Attachments',
    'Order overview',
    'Review pricing',
    'Acknowledgment',
  ];

  const isActive = (page: OrderTabsEnums) => {
    return location.pathname.split('/').includes(page) ? 'active' : '';
  };

  const preserveLocationState =
    (page: OrderTabsEnums | string) => (loc: Location) => ({
      ...loc,
      pathname: `${editModeBaseUrl}/${page}`,
      state: location.state,
    });

  const showACKTab = () => {
    // 1. is csr user and order is submited
    // 2. is dealer or sr user and order is acknowleged or special order approval exists
    return (
      (isUserCSR && isOrderSubmitted) ||
      ((isUserDealerOrSalesRep || isUserSalesRep) &&
        (isOrderAcknowledged ||
          order?.specialOrderApproval ||
          order?.acknowledgementId))
    );
  };

  return (
    <OrderTabsContainer className="order-tabs">
      <Container>
        <OrderTabLink
          className={isActive(OrderTabsEnums.OVERVIEW)}
          to={preserveLocationState(
            editMode ? OrderTabsEnums.OVERVIEW : '/order/new'
          )}
        >
          <LinkText text={basicInfo} />
        </OrderTabLink>

        {order && !withoutStyling && (
          <OrderTabLink
            className={isActive(OrderTabsEnums.STYLES)}
            to={preserveLocationState(OrderTabsEnums.STYLES)}
          >
            <LinkText text={styles} />
          </OrderTabLink>
        )}

        {order && withoutStyling && isUserCSR && (
          <OrderTabLink
            className={isActive(OrderTabsEnums.ADJUSTMENTS)}
            to={preserveLocationState(OrderTabsEnums.ADJUSTMENTS)}
          >
            <LinkText text={adjustments} />
          </OrderTabLink>
        )}

        <OrderTabLink
          className={isActive(OrderTabsEnums.LINE_ITEMS)}
          to={preserveLocationState(OrderTabsEnums.LINE_ITEMS)}
        >
          <LinkText text={lineItems} />
        </OrderTabLink>

        {order && (
          <OrderTabLink
            className={isActive(OrderTabsEnums.ATTACHMENTS)}
            to={preserveLocationState(OrderTabsEnums.ATTACHMENTS)}
          >
            <LinkText text={attachments} />
          </OrderTabLink>
        )}

        {order && !withoutStyling && (
          <OrderTabLink
            className={isActive(OrderTabsEnums.ORDER_OVERVIEW)}
            to={preserveLocationState(OrderTabsEnums.ORDER_OVERVIEW)}
          >
            <LinkText text={orderOverview} />
          </OrderTabLink>
        )}

        <OrderTabLink
          className={isActive(OrderTabsEnums.REVIEW_PRICING)}
          to={preserveLocationState(OrderTabsEnums.REVIEW_PRICING)}
        >
          <LinkText text={reviewPricing} />
        </OrderTabLink>

        {showACKTab() && (
          <OrderTabLink
            className={isActive(OrderTabsEnums.ACKNOWLEDGMENT)}
            to={preserveLocationState(OrderTabsEnums.ACKNOWLEDGMENT)}
          >
            <LinkText text={acknowledgment} />
          </OrderTabLink>
        )}
      </Container>
    </OrderTabsContainer>
  );
};

export default OrderTabs;
