import styled from 'styled-components';
import { FC } from 'react';

import { OrderStatusEnums } from 'order/enums/orderEnums';

import { poloBlue } from 'shared/config/Colors';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { Badge } from './Badge';
import { Spacer } from './Layout';
import { Wrapper } from './Wrapper';

const PerFactoryBadgesContainer = styled(Wrapper)`
  width: 120px;
`;

interface PerFactoryBadgesProps {
  perDrawing?: boolean;
  perFactoryDrawing?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

const PerFactoryBadges: FC<PerFactoryBadgesProps> = ({
  perDrawing,
  perFactoryDrawing,
  orientation,
}) => {
  const perDrawingUpdatePermission = useHasPermissions(
    userPermissionsValues.PER_DRAWING_UPDATE
  );

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  return perDrawingUpdatePermission || isOrderSubmitted ? (
    <PerFactoryBadgesContainer flex flexWrap justifyEnd>
      {perDrawing && (
        <Badge
          marginBottom={
            orientation === 'vertical' &&
            perFactoryDrawing &&
            !isUserDealerOrSalesRep
              ? 5
              : undefined
          }
        >
          Per Drawing
        </Badge>
      )}

      {perDrawing &&
      perFactoryDrawing &&
      orientation === 'horizontal' &&
      !isUserDealerOrSalesRep ? (
        <Spacer w="20px" />
      ) : null}

      {perFactoryDrawing && !isUserDealerOrSalesRep && (
        <Badge bgColor={poloBlue}>Per Factory Drawing</Badge>
      )}
    </PerFactoryBadgesContainer>
  ) : null;
};

PerFactoryBadges.defaultProps = {
  orientation: 'vertical',
  perDrawing: false,
  perFactoryDrawing: false,
};

export default PerFactoryBadges;
