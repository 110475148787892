import { ChangeEvent, useEffect, useRef } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { FormLabel } from 'shared/components/FormLabel';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { RadioButton } from 'shared/components/RadioButton';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';

import { FormElement } from '../../../../../../../shared/components/FormElement';
import { Input } from '../../../../../../../shared/components/Input';
import { setSpecsOptionsByOptionsKey } from '../../../store/specifications/orderStylesSpecificationActions';
import { Spacer } from '../../../../../../../shared/components/Layout';
import { useScrollToElement } from '../../../../../../../shared/hooks/useScrollToElement';
import { VarnishLabelsEnum } from '../../../../enums/VarnishLabelsEnum';
import { upchargeValidation } from '../../../../../../../shared/validations/validations';

const VarnishSheenContainer = styled.div``;

const RadioButtonStyled = styled(RadioButton)`
  margin-right: 20px;
  margin-bottom: 5px;
`;

const VarnishSheen = () => {
  const dispatch = useAppDispatch();

  const { control, formState, setValue, watch, clearErrors, register } =
    useFormContext<StyleSpecifications>();

  const varnishSheenWatched = watch('varnishSheen');
  const woodOrMaterialWatched = watch('woodOrMaterial');

  const varnishSheenOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.varnishSheenOptions
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onVarsnihSheenChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (varnishSheenOptions) {
      setValue(
        'varnishSheen',
        (varnishSheenOptions.find(
          (vso) => vso.value === e.target.value
        ) as SelectOptionProps) ?? null,
        {
          shouldDirty: true,
        }
      );

      clearErrors('varnishSheen');
    }
  };

  const isVarnishSheenWatchedNone =
    varnishSheenWatched?.label === VarnishLabelsEnum.NONE;

  useEffect(() => {
    if (varnishSheenWatched) {
      const upchargeValue = isVarnishSheenWatchedNone
        ? '0'
        : varnishSheenWatched.upcharge?.toString() ?? '0';

      UtilService.withDecimal<StyleSpecifications>(
        'varnishUpcharge',
        upchargeValue,
        setValue
      );
    } else {
      UtilService.withDecimal<StyleSpecifications>(
        'varnishUpcharge',
        '0',
        setValue
      );
    }
  }, [varnishSheenWatched]);

  useEffect(() => {
    if (
      UtilService.dirtyOrTouched(formState, 'materialGroup') ||
      UtilService.dirtyOrTouched(formState, 'woodOrMaterial')
    ) {
      setValue('varnishSheen', null);
    }
  }, [woodOrMaterialWatched]);

  useEffect(() => {
    if (
      varnishSheenOptions &&
      varnishSheenWatched &&
      UtilService.shouldShowTooltip(varnishSheenOptions, varnishSheenWatched) &&
      !varnishSheenOptions.some((option) => option.isDisabled)
    ) {
      const newVarnishSheenOptions = [
        ...varnishSheenOptions,
        { ...varnishSheenWatched, isDisabled: true },
      ] as SelectOptionProps[];

      dispatch(
        setSpecsOptionsByOptionsKey({
          options: newVarnishSheenOptions.sort((a, b) =>
            UtilService.sortBy(a.label, b.label)
          ),
          optionsKey: 'varnishSheenOptions',
        })
      );
    }
  }, [varnishSheenOptions, varnishSheenWatched]);

  useEffect(() => {
    // preselect single option varnish
    if (
      (UtilService.dirtyOrTouched(formState, 'materialGroup') ||
        UtilService.dirtyOrTouched(formState, 'woodOrMaterial')) &&
      varnishSheenOptions &&
      (varnishSheenOptions?.length ?? 0) === 1
    ) {
      setValue('varnishSheen', varnishSheenOptions[0]);
    }
  }, [varnishSheenOptions]);

  const varnishSheenRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: formState.errors,
    error: formState.errors.varnishSheen,
    ref: varnishSheenRef,
    fieldName: 'varnishSheen',
  });

  return (
    <VarnishSheenContainer>
      <Wrapper flex between>
        <FormLabel>Varnish Sheen</FormLabel>
        <FormLabel>Upcharge %</FormLabel>
      </Wrapper>

      <Wrapper flex between middle>
        <Wrapper flex justifyStart maxWidth={480} flexWrap>
          {!varnishSheenOptions && (
            <P
              fontSize={14}
              color={nevada}
              className={formState.errors.varnishSheen ? 'invalid' : ''}
            >
              Please select wood or material to get varnish sheen options.
            </P>
          )}

          {varnishSheenOptions && !varnishSheenOptions?.length && (
            <P className={formState.errors.varnishSheen ? 'invalid' : ''}>
              There are no varsnish sheen options for selected wood.
            </P>
          )}

          <Controller
            name="varnishSheen"
            rules={{ required: true }}
            control={control}
            render={() => (
              <Wrapper
                flex
                justifyStart
                maxWidth={480}
                flexWrap
                ref={varnishSheenRef}
              >
                {varnishSheenOptions?.map((varnishSheenOption) => (
                  <WarningTooltip
                    key={varnishSheenOption.value}
                    fieldName="Varnish Sheen"
                    withTooltip={
                      !varnishSheenOption.isDisabled &&
                      UtilService.shouldShowTooltip(
                        varnishSheenOptions,
                        varnishSheenWatched
                      )
                    }
                  >
                    <RadioButtonStyled
                      key={varnishSheenOption.value}
                      value={varnishSheenOption.value}
                      aria-invalid={
                        formState.errors.varnishSheen ? 'true' : 'false'
                      }
                      id={varnishSheenOption.value}
                      title={varnishSheenOption.label}
                      checked={
                        varnishSheenOption.value === varnishSheenWatched?.value
                      }
                      onChange={onVarsnihSheenChangeHandler}
                      disabled={!canEdit || varnishSheenOption.isDisabled}
                    />
                  </WarningTooltip>
                ))}
              </Wrapper>
            )}
          />
        </Wrapper>

        <Spacer w="24px" />
        <FormElement maxWidth={82}>
          <Input
            {...register('varnishUpcharge', upchargeValidation())}
            onBlur={(e) =>
              UtilService.withDecimal<StyleSpecifications>(
                'varnishUpcharge',
                e.target.value,
                setValue
              )
            }
            type="text"
            readOnly={
              !varnishSheenWatched || isVarnishSheenWatchedNone || !canEdit
            }
            placeholder="0.00"
            data-test="input-varnishUpcharge"
            aria-invalid={formState.errors.varnishUpcharge ? 'true' : 'false'}
          />
          <FormError
            label="Varnish Upcharge"
            error={formState.errors.varnishUpcharge as FieldError}
            validationSchema={upchargeValidation()}
          />
        </FormElement>
      </Wrapper>

      <FormError
        label="Varnish Sheen"
        error={formState.errors.varnishSheen as FieldError}
        validationSchema={{ required: true }}
      />
    </VarnishSheenContainer>
  );
};

export default VarnishSheen;
