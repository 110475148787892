import { put, call, takeEvery } from 'redux-saga/effects';

import { setBoardOrders } from 'overview/orders/store/ordersActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { PaginatedItems } from 'shared/interface/PaginatedItems';

import {
  CsrOrderTableRequest,
  OrderItem,
  OrderTableRequest,
} from 'shared/interface/OrderRow';

import { QuotesActions, setBoardQuotes } from './quotesActions';

function* getDealerQuotes(action: Action<OrderTableRequest>) {
  try {
    const {
      searchTerm,
      type,
      status,
      productLineId,
      shipWeekStartDate,
      shipWeekEndDate,
      page,
      itemsPerPage,
      sortColumn,
      sortDirection,
      dealerTab,
      collaboratingOn,
      shipWeekFilterType,
      excludeOrdersWithoutStyle,
      dealershipId,
      showNewCollaboration,
    } = action.payload!;

    const queryParams = new URLSearchParams();
    queryParams.append('searchTerm', searchTerm);
    queryParams.append('page', page.toString());
    queryParams.append('itemsPerPage', itemsPerPage.toString());
    queryParams.append('sortColumn', sortColumn);
    queryParams.append('sortDirection', sortDirection);
    queryParams.append(
      'collaboratingOn',
      (collaboratingOn ?? false).toString()
    );
    queryParams.append(
      'showNewCollaboration',
      (showNewCollaboration ?? false).toString()
    );

    if (excludeOrdersWithoutStyle) {
      queryParams.append(
        'excludeOrdersWithoutStyle',
        excludeOrdersWithoutStyle.toString()
      );
    }

    if (status) {
      queryParams.append('status', status.toString());
    }

    if (type) {
      queryParams.append('type', type.toString());
    }

    if (productLineId) {
      queryParams.append('productLineId', productLineId.toString());
    }
    if (shipWeekStartDate) {
      queryParams.append('shipWeekStartDate', shipWeekStartDate.toDateString());
    }
    if (shipWeekEndDate) {
      queryParams.append('shipWeekEndDate', shipWeekEndDate.toDateString());
    }

    if (dealerTab) {
      queryParams.append('dealerTab', dealerTab.toString());
    }

    if (shipWeekFilterType) {
      queryParams.append('shipWeekFilterType', shipWeekFilterType.toString());
    }

    if (dealershipId) {
      queryParams.append('dealershipId', dealershipId.toString());
    }

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      '/api/order/orders/dealer-quotes',
      {
        params: queryParams,
      }
    );

    yield put(setBoardQuotes(orders, page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCsrQuotes(action: Action<CsrOrderTableRequest>) {
  try {
    const queryParams = UtilService.getQueryParams(action.payload!);

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      '/api/order/orders/csr-quotes',
      {
        params: queryParams,
      }
    );

    yield put(setBoardQuotes(orders, action.payload!.page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getQuotesForImport(action: Action<CsrOrderTableRequest>) {
  try {
    const queryParams = UtilService.getQueryParams(action.payload!);

    const orders: PaginatedItems<OrderItem> = yield call(
      ApiService.get,
      '/api/order/orders/all-orders',
      {
        params: queryParams,
      }
    );

    yield put(setBoardOrders(orders, action.payload!.page > 1));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* quotesSagas() {
  yield takeEvery(QuotesActions.GET_DEALER_QUOTES, getDealerQuotes);
  yield takeEvery(QuotesActions.GET_CSR_QUOTES, getCsrQuotes);
  yield takeEvery(QuotesActions.GET_QUOTES_FOR_IMPORT, getQuotesForImport);
}
