export enum OrderStyleSpecificationsTestTextAreaEnum {
  STYLE_NOTES = 'style-notes-textarea',
  WOOD_NOTES = 'wood-notes-textarea',
  FINISH_NOTES = 'finish-notes-textarea',
}

export enum OrderStyleSpecificationsTestComboBoxEnum {
  WOOD_CB = 'wood-cb',
  WOOD_EFFECT_CB = 'wood-effect-cb',
  WOOD_INTERIOR_CB = 'wood-interior-cb',
  FINISH_EFFECT_CB = 'finish-effect-cb',
}

export enum OrderStyleSpecificationsTestRadioButtonEnum {
  CONFIGURE_WOOD_RB = 'configure-wood-rb',
  SELECT_IMPRESSION_RB = 'select-impression-rb',
}

export enum OrderStyleSpecificationsTestInputEnum {
  CABINET_BOX_MATERIAL_UPCHARGE_INPUT = 'cabinet-box-material-upcharge-input',
}
