import { OrderFilterValues } from 'order/interfaces/OrderFilterValues';
import { FC } from 'react';
import { Checkbox } from 'shared/components/Checkbox';
import { H3 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { DealerTabsEnum } from 'shared/enum/DealerTabsEnum';
import { Spacer } from 'shared/components/Layout';
import styled from 'styled-components';
import { OrdersTable, OrdersTableProps } from './OrdersTable';

const CheckboxWrapper = styled(Wrapper)`
  padding: 10px;
`;

type DealerOrdersTableProps = Pick<
  OrdersTableProps,
  'isLoading' | 'setSearch' | 'search' | 'isQuoteBoard'
>;

export const DealerOrdersTable: FC<DealerOrdersTableProps> = ({
  isLoading,
  setSearch,
  search,
  isQuoteBoard,
}: DealerOrdersTableProps) => {
  const onCollaboratingOnChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      collaboratingOn: !(search as OrderFilterValues).collaboratingOn,
      page: 1,
    });
  };

  const onShowCancelledOrdersChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      showCancelledOrders: !(search as OrderFilterValues).showCancelledOrders,
      page: 1,
    });
  };

  const onShowNewCollaborationChangeHandler = () => {
    setSearch({
      ...(search as OrderFilterValues),
      showNewCollaboration: !(search as OrderFilterValues).showNewCollaboration,
      page: 1,
    });
  };

  return (
    <>
      <Wrapper flex>
        <CheckboxWrapper flex flexGrow>
          <H3>{isQuoteBoard ? 'Custom Quotes' : 'Orders'}</H3>
        </CheckboxWrapper>
        <CheckboxWrapper flex justifyEnd>
          {(search as OrderFilterValues).tab ===
            DealerTabsEnum['Not Submitted'] &&
            !isQuoteBoard && (
              <Checkbox
                title="Hide Cancelled orders"
                id="showCancelledOrders"
                checked={
                  (search as OrderFilterValues).showCancelledOrders ?? false
                }
                onChange={onShowCancelledOrdersChangeHandler}
              />
            )}
          <Spacer w="16px" />
          <Checkbox
            title="Collaborating on"
            id="collaboratingOn"
            checked={(search as OrderFilterValues).collaboratingOn ?? false}
            onChange={onCollaboratingOnChangeHandler}
          />

          <>
            <Spacer w="32px" />

            <Checkbox
              title="New collaboration"
              id="showNewCollaboration"
              checked={
                (search as OrderFilterValues).showNewCollaboration ?? false
              }
              onChange={onShowNewCollaborationChangeHandler}
            />
          </>
        </CheckboxWrapper>
      </Wrapper>
      <OrdersTable
        isLoading={isLoading}
        setSearch={setSearch}
        search={search}
        isQuoteBoard={isQuoteBoard}
      />
    </>
  );
};
