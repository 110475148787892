import styled from 'styled-components';
import { RootState } from 'store';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import OrderStylesOverview from 'order/wizard/orderStyles/productLines/steps/OrderStylesOverview/OrderStylesOverview';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylesDoorBuilder } from 'order/wizard/orderStyles/productLines/steps/OrderStylesDoorBuilder/OrderStylesDoorBuilder';
import { OrderStylesProductLine } from 'order/wizard/orderStyles/shared/steps/OrderStylesProductLine/OrderStylesProductLine';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { getOrderStyles } from 'order/store/orderActions';

import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Container } from 'shared/components/Container';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useGetOrderMetaNames } from 'shared/hooks/useGetOrderMetaNames';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  Stepper,
  StepperList,
  Step,
  StepPanel,
} from 'shared/components/Stepper';

import { CurtainSidebar } from 'curtain/CurtainSidebar';
import OrderStylesAdjustments from './productLines/steps/OrderStylesAdjustments/OrderStylesAdjustments';
import OrderStylesSpecifications from './productLines/steps/OrderStylesSpecifications/OrderStylesSpecifications';
import { ICSDoorBuilder } from './productLines/ics/ICSDoorBuilder';
import { ICSStylesOverview } from './productLines/ics/ICSStylesOverview';
import { ICSStylesSpecifications } from './productLines/ics/ICSStylesSpecifications';
import { Inovae2ODoorBuilder } from './productLines/inovae2O/Inovae2ODoorBuilder';
import { Inovae2OStylesOverview } from './productLines/inovae2O/Inovae2OStylesOverview';
import { Inovae2OStylesSpecifications } from './productLines/inovae2O/Inovae2OStylesSpecifications';
import { StylesStepsEnum } from './enums/StylesStepsEnum';
import { setStyleDoorBuilderData } from './productLines/store/doorBuilder/orderStylesDoorBuilderActions';
import { setStyleSpecsData } from './productLines/store/specifications/orderStylesSpecificationActions';

import {
  changeStylesStep,
  clearStyles,
} from './productLines/store/orderStylesActions';
import OverrideDisclamer from './shared/OverrideDisclamer/OverrideDisclamer';

const CloseButton = styled(ButtonIcon)`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  path {
    fill: ${({ theme }) => theme.mineShaft};
  }
`;

const OrderMetaNames = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  font-size: 14px;
  font-weight: 600;
`;

const StyledStepPanel = styled(StepPanel)`
  &.react-tabs__tab-panel--selected {
    height: calc(100vh - 216px);
  }
`;

const StylesModalHeader = styled(Wrapper)`
  background-color: ${({ theme }) => theme.white};
  padding: 55px 0 30px 0;
  position: relative;
`;

const StylesModalBody = styled(Wrapper)`
  padding: 0;
  width: 96.8%;
`;

export const OrderStylesModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [leaveStyleModalOpened, setLeaveStyleModalOpened] = useState(false);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const currentStep = useSelector(
    (state: RootState) => state.orderStylesReducer.currentStep
  );

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const isStepDirty = useSelector(
    (state: RootState) => state.orderStylesReducer.isStepDirty
  );

  const { orderNumber, jobName, orderName, styleName } = useGetOrderMetaNames();

  const onStepChangeHandler = (index: number) => {
    dispatch(changeStylesStep((index + 1).toString() as StylesStepsEnum));
  };

  const closeStylesModal = () => {
    dispatch(clearStyles());
  };

  const getComponentsBasedOnProductLine = (
    productLineName: string | undefined
  ) => {
    switch (productLineName) {
      case ProductLineEnums.PRODUCT_LINE_INOVAE2O:
      case ProductLineEnums.PRODUCT_LINE_REVOLAE:
        return {
          specs: <Inovae2OStylesSpecifications />,
          doorBuilder: <Inovae2ODoorBuilder />,
          overview: <Inovae2OStylesOverview />,
        };
      case ProductLineEnums.PRODUCT_LINE_ICS:
        return {
          specs: <ICSStylesSpecifications />,
          doorBuilder: <ICSDoorBuilder />,
          overview: <ICSStylesOverview />,
        };
      default:
        return {
          specs: <OrderStylesSpecifications />,
          doorBuilder: <OrderStylesDoorBuilder />,
          overview: <OrderStylesOverview />,
        };
    }
  };

  const componentsBasedOnProductLine = useMemo(
    () => getComponentsBasedOnProductLine(selectedProductLine?.name),
    [selectedProductLine?.name]
  );

  const onCloseStylesModalClickHandler = () => {
    if (isStepDirty) {
      setLeaveStyleModalOpened(true);
    } else {
      closeStylesModal();
    }
  };

  useEffect(() => {
    return () => {
      UtilService.onPopupClose();
      history.replace(`${location.pathname}`, location.state);
      dispatch(getOrderStyles(orderId));
    };
  }, []);

  useEffect(() => {
    if (
      !storedStyle?.finishImpression &&
      storedStyle &&
      (storedStyle.woodMaterial !== null || (storedStyle?.step ?? 0) > 2)
    ) {
      dispatch(setStyleSpecsData());
    }

    if (
      storedStyle &&
      (storedStyle.doorOverlay !== null || (storedStyle?.step ?? 0) > 3)
    ) {
      dispatch(setStyleDoorBuilderData());
    }
  }, [storedStyle]);

  return (
    <>
      <Stepper
        selectedIndex={(+currentStep - 1) as number}
        onSelect={onStepChangeHandler}
      >
        <StylesModalHeader flex middle flexGrow>
          <OrderMetaNames>
            <Container>
              {orderNumber}
              {jobName && <> - {jobName}</>}
              {orderName && <> - {orderName}</>}
              {styleName && <> - {styleName}</>}
            </Container>
          </OrderMetaNames>

          <StepperList>
            <Step
              className={
                (storedStyle?.step as number) > 1 ? 'step-checked' : ''
              }
            >
              Product Line
            </Step>

            <Step
              disabled={(storedStyle?.step as number) < 2 || !storedStyle}
              readOnly
              className={
                (storedStyle?.step as number) > 2 &&
                (+currentStep as number) > 2 &&
                storedStyle
                  ? 'step-checked'
                  : ''
              }
            >
              Specifications
            </Step>

            <Step
              disabled={(storedStyle?.step as number) < 3 || !storedStyle}
              readOnly
              className={
                (storedStyle?.step as number) > 3 &&
                (+currentStep as number) > 3 &&
                storedStyle
                  ? 'step-checked'
                  : ''
              }
            >
              Door Builder
            </Step>

            <Step
              disabled={(storedStyle?.step as number) < 4 || !storedStyle}
              readOnly
              className={
                isUserCSR && (+currentStep as number) === 5 && storedStyle
                  ? 'step-checked'
                  : ''
              }
            >
              Style Overview
            </Step>

            {isUserCSR && <Step readOnly>Adjustments</Step>}
          </StepperList>

          <CloseButton onClick={onCloseStylesModalClickHandler}>
            <SVG icon={<CloseIcon />} />
          </CloseButton>
        </StylesModalHeader>

        <StylesModalBody>
          <StyledStepPanel>
            <ScrollbarsCustom
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <OverrideDisclamer />
              <Container>
                <OrderStylesProductLine />
              </Container>
            </ScrollbarsCustom>
          </StyledStepPanel>

          <StyledStepPanel>
            <ScrollbarsCustom
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <OverrideDisclamer />
              <Container>{componentsBasedOnProductLine.specs}</Container>
            </ScrollbarsCustom>
          </StyledStepPanel>

          <StyledStepPanel>
            <ScrollbarsCustom
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <OverrideDisclamer />
              <Container>{componentsBasedOnProductLine.doorBuilder}</Container>
            </ScrollbarsCustom>
          </StyledStepPanel>

          <StyledStepPanel>
            <ScrollbarsCustom
              autoHide
              autoHideTimeout={500}
              autoHideDuration={300}
            >
              <OverrideDisclamer />
              <Container>{componentsBasedOnProductLine.overview}</Container>
            </ScrollbarsCustom>
          </StyledStepPanel>

          {isUserCSR && (
            <StyledStepPanel>
              <ScrollbarsCustom
                autoHide
                autoHideTimeout={500}
                autoHideDuration={300}
              >
                <OverrideDisclamer />
                <Container>
                  <OrderStylesAdjustments />
                </Container>
              </ScrollbarsCustom>
            </StyledStepPanel>
          )}
        </StylesModalBody>
      </Stepper>
      <CurtainSidebar top={145} />

      <ConfirmationModal
        title="Are you sure?"
        opened={leaveStyleModalOpened}
        confirm={() => closeStylesModal()}
        cancel={() => setLeaveStyleModalOpened(false)}
        message='Changes in the current page will not be saved until you hit the "Next" or "Back" button located in the footer. Closing this page will result in loss of current changes to this page.'
        buttonText="Discard"
      />

      <div id="style-loader-container"> </div>
    </>
  );
};
