import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';

const ModificationsModalHeaderContainer = styled.div`
  margin-top: -12px;
  margin-bottom: 24px;
`;

const ModificationsModalHeader = () => {
  const modificationsModalTitle = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.modalTitle
  );

  const modificationsModalDesc = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.modalDesc
  );

  return (
    <ModificationsModalHeaderContainer>
      <H2>{modificationsModalTitle}</H2>
      <Spacer h="2px" />
      <P color={nevada}>{modificationsModalDesc}</P>
    </ModificationsModalHeaderContainer>
  );
};

export default ModificationsModalHeader;
