import styled from 'styled-components';
import { Location } from 'history';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { SortDirection, TableColumns } from 'order/enums/orderTableEnums';

import {
  OrderFilterValues,
  OrderFilterValuesRipped,
} from 'order/interfaces/OrderFilterValues';

import { CsrOrdersTable } from 'overview/components/CsrOrdersTable';
import { DasboardLocationStateProps } from 'overview/interface/DasboardLocationStateProps';
import { DealerOrdersTable } from 'overview/components/DealerOrdersTable';
import { ImediateActionOrders } from 'overview/components/ImediateActionOrders';
import { OrderTableFilters } from 'overview/components/OrderTableFilters';

import {
  getCsrOrders,
  getDealerOrders,
  getImmediateActionsForOdrers,
  resetOrders,
} from 'overview/orders/store/ordersActions';

import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';

import MetaTitle from 'shared/components/MetaTitle';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonPrimary } from 'shared/components/Button';
import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { DealerTabsEnum } from 'shared/enum/DealerTabsEnum';
import { H1 } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Spacer, ContentWrapper } from 'shared/components/Layout';
import { StyledTabs, TabProps } from 'shared/components/StyledTabs';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  CsrOrderTableRequest,
  ImmediateActionsForOrdersTableRequest,
  OrderTableRequest,
} from 'shared/interface/OrderRow';
import { useBrowserStorage } from 'shared/hooks/useBrowserStorage';

const WhiteBox = styled.div`
  padding: 10px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.white};
  ${BoxShadowCSS};
`;

export const DashboardPage = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const location: Location<DasboardLocationStateProps> = useLocation();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const isUserDealer = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
  ]);

  const [showKickedOutModal, setShowKickedOutModal] = useState(false);
  const [onlyMine, setOnlyMine] = useState(
    window.localStorage.getItem('onlyMine')
      ? window.localStorage.getItem('onlyMine') === 'true'
      : true
  );

  const [search, setSearch] = useState<
    OrderFilterValues | OrderFilterValuesRipped
  >({
    order: '',
    orderType: null,
    status: null,
    productLine: null,
    shipWeek: null,
    endShipWeek: null,
    dealership: null,
    assignee: null,
    targetDate: null,
    endTargetDate: null,
    sortColumn: TableColumns.NUMBER,
    sortDirection: SortDirection.DESC,
    page: 1,
    redTag: null,
    collaboratingOn: false,
    showCancelledOrders: true,
    showNewCollaboration: false,
  });

  const [loading, setLoading] = useState(false);

  const canCreateOrderDraft = useHasPermissions(
    userPermissionsValues.ORDER_CREATE_DRAFT
  );

  const { setItem, value } = useBrowserStorage({
    key: 'orders',
    storageType: 'localStorage',
  });

  const getShipWeeksDates = (data: OrderFilterValues) => {
    const shipWeekStartDate: Date | null = data.shipWeek
      ? new Date(data.shipWeek.value)
      : null;

    const shipWeekEndDate: Date | null = data.endShipWeek
      ? new Date(data.endShipWeek.value)
      : null;

    return {
      shipWeekStartDate,
      shipWeekEndDate,
    };
  };

  const loadOrders = () => {
    setLoading(true);

    const data = search as OrderFilterValues;

    const shipWeekDates = getShipWeeksDates(data);

    const isNotSubmittedTab = UtilService.isNotSubmittedTab(
      isUserCSR || isUserSalesRep,
      search
    );

    const payload: OrderTableRequest = {
      page: data.page,
      itemsPerPage: 10,
      searchTerm: data.order,
      status: data.status ? +data.status.value : undefined,
      productLineId: data.productLine?.value,
      type: data.orderType ? +data.orderType.value : undefined,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection,
      dealerTab: data.tab,
      collaboratingOn: data.collaboratingOn,
      dealershipId: data.dealership?.value ?? undefined,
      ...(!isNotSubmittedTab && {
        shipWeekFilterType: data.shipWeekFilterType ?? undefined,
        shipWeekEndDate: shipWeekDates?.shipWeekEndDate ?? undefined,
        shipWeekStartDate: shipWeekDates?.shipWeekStartDate ?? undefined,
      }),
      showCancelledOrders: isNotSubmittedTab ? !data.showCancelledOrders : true,
      showNewCollaboration: data.showNewCollaboration,
    };

    dispatch(getDealerOrders(payload, setLoading));
  };

  const loadCsrOrders = () => {
    setLoading(true);

    const data = search as OrderFilterValues;
    const shipWeekDates = getShipWeeksDates(data);

    const isNotSubmittedTab = UtilService.isNotSubmittedTab(
      isUserCSR || isUserSalesRep,
      search
    );

    const canSeeTargetDateFilter = UtilService.canSeeTargetDateFilter(
      isUserCSR,
      search
    );

    const payload: CsrOrderTableRequest = {
      page: data.page,
      itemsPerPage: 10,
      searchTerm: data.order,
      status: data.status ? +data.status.value : undefined,
      productLineId: data.productLine?.value,
      type: data.orderType ? +data.orderType.value : undefined,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection,
      csrTab: data.tab,
      assigneeId: data.assignee?.value,
      dealershipId: data.dealership?.value,
      redTag: data.redTag ?? undefined,
      collaboratingOn: data.collaboratingOn,
      ...(!isNotSubmittedTab && {
        shipWeekFilterType: data.shipWeekFilterType ?? undefined,
        shipWeekEndDate: shipWeekDates?.shipWeekEndDate ?? undefined,
        shipWeekStartDate: shipWeekDates?.shipWeekStartDate ?? undefined,
      }),
      ...(canSeeTargetDateFilter && {
        targetDateEndDate: data.endTargetDate ?? undefined,
        targetDateFilterType: data.targetDateFilterType ?? undefined,
        targetDateStartDate: data.targetDate ?? undefined,
      }),
      showCancelledOrders: isNotSubmittedTab ? !data.showCancelledOrders : true,
      showNewCollaboration: data.showNewCollaboration,
    };

    dispatch(getCsrOrders(payload, setLoading));

    history.replace(location.pathname);
  };

  const loadImmediateActionsForOrders = () => {
    setLoading(true);

    const payload: ImmediateActionsForOrdersTableRequest = {
      page: 1,
      itemsPerPage: 1000,
      sortColumn: '',
      sortDir: SortDirection.DESC,
      onlyMine,
    };

    dispatch(getImmediateActionsForOdrers(payload, setLoading));
  };

  const getUserOrders = () => {
    if (isUserCSR || isUserSalesRep) {
      loadCsrOrders();
    }

    if (isUserDealer) {
      loadOrders();
    }
  };

  const onTabClickHandler = (tab: string) => {
    const selectedTab =
      isUserCSR || isUserSalesRep
        ? CsrTabsEnum[tab as keyof typeof CsrTabsEnum]
        : DealerTabsEnum[tab as keyof typeof DealerTabsEnum];

    setSearch((prevState) => ({
      ...prevState,
      status: undefined,
      page: 1,
      tab: selectedTab,
      collaboratingOn: (prevState as OrderFilterValues).collaboratingOn,
      sortColumn:
        selectedTab > 1
          ? TableColumns.SUBMISSION_DATE
          : TableColumns.CREATED_DATE,
      showCancelledOrders: true,
      showNewCollaboration: (prevState as OrderFilterValues)
        .showNewCollaboration,
    }));

    dispatch(resetOrders());
  };

  const tabs: TabProps[] = Object.keys(
    isUserCSR || isUserSalesRep ? CsrTabsEnum : DealerTabsEnum
  )
    .filter((k) => Number.isNaN(+k))
    .map((key) => {
      return {
        text: key,
        onClick: () => onTabClickHandler(key),
      };
    });

  const imediateActionOrders = useSelector(
    (state: RootState) => state.ordersReducer.imediateActionOrders
  );

  useEffect(() => {
    loadImmediateActionsForOrders();

    if (location.state?.kickedOut) {
      setShowKickedOutModal(true);
    }

    if (value) {
      const val = value as OrderFilterValues;
      val.page = 1;
      setSearch(val as OrderFilterValues);
    }

    return () => {
      dispatch(resetOrders());
    };
  }, []);

  // trigger api call with saved tab
  useEffect(() => {
    if (location.state?.backToTab) {
      setSearch((prevState) => ({
        ...prevState,
        tab: location.state.backToTab,
      }));

      const backToTabRemoved = UtilService.omit(location.state, ['backToTab']);

      setTimeout(() => {
        history.replace(
          location.pathname,
          Object.keys(backToTabRemoved).length ? backToTabRemoved : null
        );
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    if ((isUserCSR || isUserSalesRep) && !location.state?.backToTab && !value) {
      setSearch((prevState) => ({
        ...prevState,
        tab: CsrTabsEnum['Orders to Write'],
        sortColumn: TableColumns.SUBMISSION_DATE,
      }));
    }
  }, [isUserCSR, isUserSalesRep]);

  useEffect(() => {
    if (isUserDealer && !location.state?.backToTab && !value) {
      setSearch((prevState) => ({
        ...prevState,
        tab: DealerTabsEnum['Not Submitted'],
        sortColumn: TableColumns.CREATED_DATE,
      }));
    }
  }, [isUserDealer]);

  useEffect(() => {
    if ((search as OrderFilterValues).tab) {
      getUserOrders();
      setItem(search);
    }
  }, [search, isUserCSR, isUserDealer, isUserSalesRep]);

  return (
    <>
      <MetaTitle title="Order Board | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft>
          <H1>Order Board</H1>

          <Wrapper flex middle>
            {canCreateOrderDraft && (
              <ButtonPrimary
                type="button"
                onClick={() => history.push('/order/new')}
              >
                New Draft
              </ButtonPrimary>
            )}
          </Wrapper>
        </OverviewHeaderLeft>
      </OverviewHeader>

      <StyledTabs tabs={tabs} activeIndex={(value as OrderFilterValues)?.tab} />

      <Spacer h="16px" />

      <ContentWrapper>
        <WhiteBox>
          <ScrollbarsCustom
            autoHeight
            autoHeightMin={0}
            autoHeightMax={330}
            autoHide
            autoHideDuration={300}
            autoHideTimeout={500}
          >
            <ImediateActionOrders
              search={search}
              onlyMine={onlyMine}
              setOnlyMine={setOnlyMine}
              orderList={imediateActionOrders}
              isUserCSR={isUserCSR}
            />
          </ScrollbarsCustom>
        </WhiteBox>

        <Spacer h="16px" />

        <OrderTableFilters
          search={search as OrderFilterValues}
          setSearch={setSearch}
        />

        <Spacer h="16px" />

        <WhiteBox>
          {isUserCSR || isUserSalesRep ? (
            <CsrOrdersTable
              isLoading={loading}
              setSearch={setSearch}
              search={search}
            />
          ) : (
            <DealerOrdersTable
              isLoading={loading}
              setSearch={setSearch}
              search={search}
            />
          )}
        </WhiteBox>
      </ContentWrapper>

      <Spacer h="50px" />

      <ModalWrapper
        closeOnEscape={false}
        lockScroll
        modal
        onClose={() => {
          setShowKickedOutModal(false);
          history.replace(
            location.pathname,
            UtilService.omit(location.state, ['kickedOut'])
          );
        }}
        open={showKickedOutModal}
      >
        {(close: () => void) => (
          <Modal close={close} title="Order session expired.">
            You have been kicked out from the order due to inactivity.
          </Modal>
        )}
      </ModalWrapper>
    </>
  );
};
