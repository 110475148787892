import { AdditionalField } from '../interfaces/AdditionalField';

export enum AdditionalFieldActiontTypes {
  GET_ADDITIONAL_FIELDS = 'GET_ADDITIONAL_FIELDS',
  SET_ADDITIONAL_FIELDS = 'SET_ADDITIONAL_FIELDS',
}

export const getAdditionalFields = () => ({
  type: AdditionalFieldActiontTypes.GET_ADDITIONAL_FIELDS,
});

export const setAdditionalFields = (data: AdditionalField[] | null) => ({
  type: AdditionalFieldActiontTypes.SET_ADDITIONAL_FIELDS,
  payload: data,
});
