import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
  getDealership,
  getDealershipSalesRep,
} from 'overview/dealership/store/dealershipActions';

export const useGetDealershipInfo = (dealershipId?: string) => {
  const dispatch = useDispatch();

  const storedDealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const storedSalesRep = useSelector(
    (state: RootState) => state.dealershipReducer.salesRepresentative
  );

  useEffect(() => {
    if (dealershipId && !storedDealership) {
      dispatch(
        getDealership({
          dealershipId,
        })
      );
    }
  }, [dealershipId, storedDealership]);

  useEffect(() => {
    if (storedDealership?.salesRepresentativeId && !storedSalesRep) {
      dispatch(
        getDealershipSalesRep({
          dealershipId: storedDealership.id,
        })
      );
    }
  }, [storedDealership, storedSalesRep]);
};
