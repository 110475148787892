import { RootState } from 'store';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';
import { StyleFieldOption } from 'order/wizard/orderStyles/interface/StyleFieldOption';

import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

type FieldNameAndOptionsObject = Record<
  keyof StyleDoorBuilder,
  SelectOptionProps[] | StyleFieldOption[] | null
>;

export const useAutoSetSingleOptionList = (
  methods: UseFormReturn<StyleDoorBuilder>,
  options: Partial<FieldNameAndOptionsObject>
) => {
  const storedDoorBuilder = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilder
  );

  Object.entries(options).forEach(([key, value]) => {
    useEffect(() => {
      if (!storedDoorBuilder && value) {
        methods.setValue(key as keyof StyleDoorBuilder, value[0]);
      }
    }, [value?.length === 1]);
  });
};
