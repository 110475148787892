import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';

import { nevada, silverSandTwo } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { RadioColor } from 'shared/components/RadioColor';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import ImgModal from 'shared/components/ImgModal';
import SVG from 'shared/components/SVG';
import WarningTooltip from 'shared/components/WarningTooltip';
import UtilService from 'shared/services/util.service';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';

const HingeColorContainer = styled.div``;
const HingeColor = () => {
  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const hingeColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.hingeColorOptions
  );

  const methodsContext = useFormContext<StyleDoorBuilder>();

  const hingeTypeWatched = methodsContext.watch('hingeType');
  const hingeColorWatched = methodsContext.watch('hingeColor');

  const resetField = (field: keyof StyleDoorBuilder) => {
    methodsContext.setValue(field, '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onClickHandler = (option: SelectOptionProps) => {
    if (option.value !== hingeColorWatched?.value) {
      methodsContext.setValue('hingeColor', option, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      resetField('hingeColor');
    }
  };

  return (
    <HingeColorContainer>
      <Wrapper flex middle flexWrap>
        {!hingeTypeWatched && (
          <P color={nevada}>
            Please select hinge type to get hinge color options.
          </P>
        )}

        {hingeTypeWatched &&
          !hingeColorOptions?.[hingeTypeWatched.value]?.length && (
            <P color={nevada} fontSize={14}>
              There are no Hinge Colors for selected Hinge Type.
            </P>
          )}

        {hingeTypeWatched && hingeColorOptions && (
          <Controller
            control={methodsContext.control}
            rules={{ required: true }}
            name="hingeColor"
            render={({ field: { ref } }) => (
              <>
                {hingeColorOptions[hingeTypeWatched.value]?.map(
                  (hingeColorOption) => (
                    <WarningTooltip
                      key={hingeColorOption.value}
                      fieldName="Hinge Color"
                      position="right"
                      withTooltip={
                        !hingeColorOption.isDisabled &&
                        UtilService.shouldShowTooltip(
                          hingeColorOptions[hingeTypeWatched.value],
                          hingeColorWatched
                        )
                      }
                    >
                      <RadioColor
                        type="button"
                        key={hingeColorOption.value}
                        id={hingeColorOption.value}
                        name={hingeColorOption.label}
                        aria-invalid={
                          methodsContext.formState.errors.hingeColor
                            ? 'true'
                            : 'false'
                        }
                        ref={ref}
                        className={
                          hingeColorWatched?.value === hingeColorOption.value
                            ? 'active'
                            : ''
                        }
                        onClick={() => onClickHandler(hingeColorOption)}
                        isDisabled={!canEdit}
                      >
                        {/* if has image */}
                        {hingeColorOption.imageUrl && (
                          <ImgModal
                            height={32}
                            hover
                            position="right center"
                            key={hingeColorOption.value}
                            label={hingeColorOption.label}
                            nested
                            src={hingeColorOption.imageUrl}
                            width={32}
                            alt={`Hinge Color - ${hingeColorOption.label}`}
                          />
                        )}

                        {/* if has not image */}
                        {!hingeColorOption.imageUrl && (
                          <SVG icon={<PlatoLogoIcon />} color={silverSandTwo} />
                        )}
                      </RadioColor>
                    </WarningTooltip>
                  )
                )}
              </>
            )}
          />
        )}
      </Wrapper>

      <FormError
        label="Hinge color"
        error={methodsContext.formState.errors.hingeColor as FieldError}
        validationSchema={{ required: true }}
      />
    </HingeColorContainer>
  );
};

export default HingeColor;
