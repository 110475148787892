import { RootState } from 'store';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { ButtonPrimary } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { drawingOptionEnums } from 'shared/enum/drawingOptionEnums';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';

import {
  modificationDescriptionValidation,
  onlyNumberValidation,
  onlyWholeDigitValidation,
} from 'shared/validations/validations';

import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useAdditionalFields } from 'shared/hooks/useAdditionalFields';
import { IModification } from './interface/IModification';
import { ModificationBox } from './ModificationBox';

import { ModificationModalStepsEnums } from './enums/ModificationModalStepsEnums';

import {
  replaceLineItemModification,
  setIsLineItemsStepDirty,
  updateLineItemModification,
} from '../../store/orderLineItemsActions';

import {
  setSelectedModification,
  updateModalModification,
  updateModificationsModal,
} from './store/lineItemModificationsActions';

const ModificationFormContainer = styled.div``;

interface ModificationFormProps {
  closeModal?: () => void;
}

const ModificationForm: FC<ModificationFormProps> = ({ closeModal }) => {
  const dispatch = useAppDispatch();

  const { onFormLoad, PopupModal } = usePopupMessage();
  const { onModificationLoad, FormModal, answers } = useAdditionalFields();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const perDrawingUpdatePermission = useHasPermissions(
    userPermissionsValues.PER_DRAWING_UPDATE
  );

  const activeStep = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.activeStep
  );

  const updateMod =
    activeStep === ModificationModalStepsEnums.UPDATE_MODIFICATION;

  const selectedMod = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.currentSelectedModification
  );

  const selectedMods = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.selectedModifications
  );

  const replaceModification = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.replaceModification
  );

  const modificationToReplace = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.modificationToReplace
  );

  const [modIndex, setModIndex] = useState(0);
  const [modsLength, setModsLength] = useState(0);

  const getDefaultValues = () => {
    return {
      ...selectedMod,
      note: modificationToReplace?.note ?? selectedMod?.note ?? '',
      additionalNote:
        modificationToReplace?.additionalNote ??
        selectedMod?.additionalNote ??
        '',
      quantity: modificationToReplace?.quantity ?? selectedMod?.quantity ?? 1,
      description: selectedMod?.description ?? '',
    };
  };

  const methods = useForm<IModification>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: IModification) => {
    dispatch(setIsLineItemsStepDirty(true));
    const nextMod = selectedMods[modIndex + 1];

    const updatedMod = {
      ...data,
      price: +data.price,
      quantity: +data.quantity,
      completed: true,
      description: data.description,
    } as IModification;

    if (replaceModification && modificationToReplace) {
      dispatch(
        replaceLineItemModification(updatedMod, modificationToReplace?.id)
      );
      toast.success('You have successfully replaced a Modification.');
      return;
    }

    // if closeModal function exists
    // edit mod is started from line item
    if (closeModal) {
      dispatch(updateLineItemModification(updatedMod));
      closeModal();
    } else {
      // update modal modification
      dispatch(updateModalModification(updatedMod));
    }

    if (nextMod && !updateMod) {
      dispatch(setSelectedModification(nextMod));
    } else {
      dispatch(
        updateModificationsModal(
          ModificationModalStepsEnums.COMPLETE_MODIFICATIONS,
          ModificationModalStepsEnums.COMPLETE_MOD_TITLE,
          ModificationModalStepsEnums.COMPLETE_MOD_DESC
        )
      );
    }
  };

  useEffect(() => {
    if (selectedMod) {
      methods.reset(getDefaultValues());

      onFormLoad(selectedMod);
      if (!selectedMod.completed) {
        onModificationLoad(selectedMod);
      }
    }
  }, [selectedMod, modificationToReplace]);

  useEffect(() => {
    if (selectedMods && selectedMod) {
      setModIndex(selectedMods.findIndex((smod) => smod.id === selectedMod.id));
      setModsLength(selectedMods.length);
    }
  }, [selectedMods, selectedMod]);

  const getActionModalText = () => {
    if (replaceModification) {
      return 'Replace';
    }
    if (updateMod) {
      return 'Save';
    }
    return `Add ${modIndex + 1}/${modsLength}`;
  };

  useEffect(() => {
    if (answers.length === 1) {
      methods.setValue('note', answers[0].answer);
    }

    if (answers.length > 1) {
      methods.setValue('note', answers[0].answer);
      methods.setValue('additionalNote', answers[1].answer);
    }
  }, [answers]);

  return (
    <ModificationFormContainer>
      {selectedMod && <ModificationBox modification={selectedMod} />}
      <Spacer h="30px" />

      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Wrapper flex alignStart>
          <FormElement maxWidth={80}>
            <FormLabel>Quantity</FormLabel>
            <Input
              {...methods.register(
                'quantity',
                onlyWholeDigitValidation({ required: true }, 'Quantity')
              )}
              aria-invalid={
                methods.formState.errors.quantity ? 'true' : 'false'
              }
            />
            <FormError
              label="Quantity"
              validationSchema={onlyWholeDigitValidation(
                { required: true },
                'Quantity'
              )}
              error={methods.formState.errors.quantity}
            />
          </FormElement>
          <Spacer w="30px" />
          <FormElement maxWidth={80}>
            <FormLabel>Price</FormLabel>
            <Input
              {...methods.register(
                'price',
                onlyNumberValidation({ required: true }, 'Price')
              )}
              aria-invalid={methods.formState.errors.price ? 'true' : 'false'}
            />
            <FormError
              label="Price"
              validationSchema={onlyNumberValidation(
                { required: true },
                'Price'
              )}
              error={methods.formState.errors.price}
            />
          </FormElement>
          <Spacer w="30px" />

          {perDrawingUpdatePermission && (
            <Wrapper>
              <Spacer h="32px" />

              <Wrapper flex>
                <Checkbox
                  id={`modDrawingOption--${drawingOptionEnums.PER_DRAWING}`}
                  {...methods.register('perDrawing')}
                  title="Per Drawing"
                />
                <Spacer w="20px" />
                <Checkbox
                  id={`modDrawingOption--${drawingOptionEnums.PER_FACTORY_DRAWING}`}
                  {...methods.register('perFactoryDrawing')}
                  title="Per Factory Drawing"
                />
              </Wrapper>
            </Wrapper>
          )}
        </Wrapper>

        {isUserCSR && (
          <FormElement>
            <FormLabel>Custom Modification Description</FormLabel>

            <Input
              {...methods.register(
                'description',
                modificationDescriptionValidation()
              )}
              aria-invalid={
                methods.formState.errors.description ? 'true' : 'false'
              }
            />

            <FormError
              label="Custom Modification Description"
              validationSchema={modificationDescriptionValidation()}
              error={methods.formState.errors.description}
            />
          </FormElement>
        )}

        <FormElement>
          <FormLabel>Note</FormLabel>

          <Input
            placeholder="Add a note"
            {...methods.register('note', { maxLength: 40 })}
            aria-invalid={methods.formState.errors.note ? 'true' : 'false'}
          />

          <FormError
            label="Note"
            validationSchema={{ maxLength: 40 }}
            error={methods.formState.errors.note}
          />

          <Spacer h="8px" />

          <Input
            placeholder="Add a note"
            {...methods.register('additionalNote', { maxLength: 40 })}
            aria-invalid={
              methods.formState.errors.additionalNote ? 'true' : 'false'
            }
          />
          <FormError
            label="Note"
            validationSchema={{ maxLength: 40 }}
            error={methods.formState.errors.additionalNote}
          />
        </FormElement>

        <Wrapper flex justifyEnd>
          <ButtonPrimary type="submit">{getActionModalText()}</ButtonPrimary>
        </Wrapper>
      </Form>

      {PopupModal}
      {FormModal}
    </ModificationFormContainer>
  );
};

export default ModificationForm;
