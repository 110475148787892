import { RootState } from 'store';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Order } from 'order/interfaces/Order';

import {
  setOrderAckId,
  setOrderAckStatus,
  setSpecialOrderApproval,
  updateSpecialOrderApprovalStatus,
} from 'order/store/orderActions';

import { Action } from 'shared/interface/Action';
import { BaseField } from 'shared/interface/BaseField';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { IAttachment } from 'shared/interface/IAttachment';

import {
  Acknowledgement,
  ACKSnapshot,
  CollectDrawings,
} from '../interface/Acknowledgement';

import { ACKStatusEnum } from '../enums/ACKStatusEnum';
import { CollectDrawingsStatusEnum } from '../enums/CollectDrawingsStatusEnum';
import { SpecialOrderApprovalStatusEnum } from '../enums/SpecialOrderApprovalStatusEnum';

import {
  ACKActions,
  ApproveACKRequest,
  ApproveSpecialOrderApprovalRequest,
  changeCollectDrawingsStatus,
  ChangeRequestACKRequest,
  ChangeRequestFinishACKRequest,
  CollectDrawingsRequest,
  CreateACKRequest,
  DisapproveSpecialOrderApprovalRequest,
  ExtendACKExpirationDateRequest,
  GenerateOrderSnapshotRequest,
  setACKAttachments,
  setACKVersions,
  setCollectDrawingsStatus,
  setLatestACK,
  setLatestACKSnapshot,
  SpecialOrderApprovalRequest,
  updateACKStatus,
} from './ACKActions';

function* createACK(action: Action<CreateACKRequest>) {
  try {
    const { orderId, ...rest } = action.payload!;

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/acknowledgements`,
      rest
    );

    const latestACK: Acknowledgement = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/acknowledgements/latest`
    );

    yield put(setOrderAckId(latestACK.id));
    yield put(setOrderAckStatus(latestACK.status));
    yield put(setLatestACK(latestACK));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLatestACK(action: Action<string>) {
  try {
    const ACK: Acknowledgement = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/acknowledgements/latest`
    );

    yield put(setLatestACK(ACK));

    const order: Order = yield select(
      (state: RootState) => state.orderReducer.order
    );

    if (order) {
      yield put(setOrderAckId(ACK.id));
      yield put(setOrderAckStatus(ACK.status));
    }

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getACKVersions(action: Action<string>) {
  try {
    const ACKVersions: BaseField[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/acknowledgements/versions`
    );

    yield put(setACKVersions(ACKVersions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLatestACKSnapshot(action: Action<string>) {
  try {
    const ACKS: ACKSnapshot = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/snapshots/latest`
    );

    yield put(setLatestACKSnapshot(ACKS));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* approveACK(action: Action<ApproveACKRequest>) {
  try {
    const { acknowledgementId, orderId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/acknowledgements/${acknowledgementId}/approve`,
      {}
    );

    yield put(updateACKStatus(ACKStatusEnum.APPROVED));
    yield put(setOrderAckStatus(ACKStatusEnum.APPROVED));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changesRequest(action: Action<ChangeRequestACKRequest>) {
  try {
    const { acknowledgementId, orderId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/acknowledgements/${acknowledgementId}/request-changes`,
      {}
    );

    yield put(updateACKStatus(ACKStatusEnum.CHANGES_REQUESTED));
    yield put(setOrderAckStatus(ACKStatusEnum.CHANGES_REQUESTED));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changesRequestFinsih(action: Action<ChangeRequestFinishACKRequest>) {
  try {
    const { acknowledgementId, orderId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/acknowledgements/${acknowledgementId}/request-changes-finish`,
      {}
    );

    yield put(updateACKStatus(ACKStatusEnum.FINISHED_REQUESTING_CHANGES));
    yield put(setOrderAckStatus(ACKStatusEnum.FINISHED_REQUESTING_CHANGES));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* sendSpecialOrderApproval(
  action: Action<SpecialOrderApprovalRequest>
) {
  try {
    const { note, orderId } = action.payload!;

    const specialOrderApprovalId: string = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/specialorderapprovals`,
      { note }
    );

    yield put(
      setSpecialOrderApproval({
        id: specialOrderApprovalId,
        note,
        status: SpecialOrderApprovalStatusEnum.PENDING,
        orderId,
        approvedOnUtc: new Date().toISOString(),
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* approveSpecialOrderApproval(
  action: Action<ApproveSpecialOrderApprovalRequest>
) {
  try {
    const { specialOrderApprovalId, orderId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/specialOrderApprovals/${specialOrderApprovalId}/approve`,
      {}
    );

    yield put(
      updateSpecialOrderApprovalStatus(SpecialOrderApprovalStatusEnum.APPROVED)
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* disapproveSpecialOrderApproval(
  action: Action<DisapproveSpecialOrderApprovalRequest>
) {
  try {
    const { specialOrderApprovalId, orderId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/specialOrderApprovals/${specialOrderApprovalId}/disapprove`,
      {}
    );

    yield put(
      updateSpecialOrderApprovalStatus(
        SpecialOrderApprovalStatusEnum.DISAPPROVED
      )
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* startCollectDrawings(action: Action<CollectDrawingsRequest>) {
  try {
    const { orderId } = action.payload!;

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/collect-drawings/start`,
      {}
    );

    yield put(changeCollectDrawingsStatus(CollectDrawingsStatusEnum.Started));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* confirmResultCollectDrawings(action: Action<CollectDrawingsRequest>) {
  try {
    const { orderId } = action.payload!;

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/collect-drawings/confirm-result`,
      {}
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* generateOrderSnapshot(action: Action<GenerateOrderSnapshotRequest>) {
  try {
    const { orderId } = action.payload!;

    yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/acknowledgements/generate-order-snapshot`,
      {}
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getCollectDrawingsStatus(action: Action<string>) {
  try {
    const CDS: CollectDrawings = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/collect-drawings/status`
    );

    yield put(setCollectDrawingsStatus(CDS));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getACKAttachments(action: Action<string>) {
  try {
    const ACKAttachments: IAttachment[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/acknowledgements/attachments`
    );

    yield put(setACKAttachments(ACKAttachments));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* extendACKExpirationDate(
  action: Action<ExtendACKExpirationDateRequest>
) {
  try {
    const { orderId, acknowledgementId, extendedDateTime } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/acknowledgements/${acknowledgementId}/extend-expiration-date`,
      { extendedDateTime }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* ACKSagas() {
  yield takeEvery(ACKActions.CREATE_ACK, createACK);
  yield takeEvery(ACKActions.GET_LATEST_ACK, getLatestACK);
  yield takeEvery(ACKActions.GET_ACK_VERSIONS, getACKVersions);
  yield takeEvery(ACKActions.GET_LATEST_ACK_SNAPSHOT, getLatestACKSnapshot);
  yield takeEvery(ACKActions.APPROVE_ACK, approveACK);
  yield takeEvery(ACKActions.CHANGES_REQUEST, changesRequest);
  yield takeEvery(ACKActions.CHANGES_REQUEST_FINISH, changesRequestFinsih);
  yield takeEvery(
    ACKActions.SEND_SPECIAL_ORDER_APPROVAL,
    sendSpecialOrderApproval
  );
  yield takeEvery(
    ACKActions.APPROVE_SPECIAL_ORDER_APPROVAL,
    approveSpecialOrderApproval
  );

  yield takeEvery(
    ACKActions.DISAPPROVE_SPECIAL_ORDER_APPROVAL,
    disapproveSpecialOrderApproval
  );
  yield takeEvery(ACKActions.START_COLLECT_DRAWINGS, startCollectDrawings);
  yield takeEvery(
    ACKActions.CONFIRM_RESULT_COLLECT_DRAWINGS,
    confirmResultCollectDrawings
  );
  yield takeEvery(ACKActions.GENERATE_ORDER_SNAPSHOT, generateOrderSnapshot);
  yield takeEvery(
    ACKActions.GET_COLLECT_DRAWINGS_STATUS,
    getCollectDrawingsStatus
  );
  yield takeEvery(ACKActions.GET_ACK_ATTACHMENTS, getACKAttachments);
  yield takeEvery(
    ACKActions.EXTEND_ACK_EXPIRATION_DATE,
    extendACKExpirationDate
  );
}
