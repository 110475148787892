import styled from 'styled-components';
import { RootState } from 'store';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { H2 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { Inovae20OverviewMaterial } from './components/Inovae20OverviewMaterial';
import { StylesStepsEnum } from '../../enums/StylesStepsEnum';
import { changeStylesStep, clearStyles } from '../store/orderStylesActions';

const OrderStylesOverviewContainer = styled.div``;

const ProductLineImage = styled.img`
  margin-left: auto;
`;

export const Inovae2OStylesOverview = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const styleName = useSelector(
    (state: RootState) => state.orderStylesReducer.name
  );

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.DOOR_BUILDER));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.ADJUSTMENTS));
    }
  };

  const onFinishClickHandler = () => {
    dispatch(clearStyles());
    history.replace(`${location.pathname}`, location.state);
  };

  return (
    <OrderStylesOverviewContainer>
      <Spacer h="49px" />

      <Wrapper flex middle>
        <H2>{styleName}</H2>
        {selectedProductLine && (
          <ProductLineImage src={selectedProductLine.imageUrl} />
        )}
      </Wrapper>

      <Spacer h="30px" />

      <Inovae20OverviewMaterial />

      <Spacer h="30px" />
      <OrderFooter>
        <Wrapper flex middle between>
          <ButtonSecondary onClick={() => redirect(true)} type="button">
            Back
          </ButtonSecondary>

          {isUserCSR ? (
            <ButtonPrimary
              data-test="button-StylesSpecsSubmit"
              type="button"
              onClick={() => redirect(false)}
            >
              Next
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              onClick={onFinishClickHandler}
              data-test="button-StyleSubmit"
              type="button"
            >
              Finish
            </ButtonPrimary>
          )}
        </Wrapper>
      </OrderFooter>
    </OrderStylesOverviewContainer>
  );
};
