import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { OrderStatusEnums } from 'order/enums/orderEnums';

import { BaseField } from 'shared/interface/BaseField';

export interface OrderInStatusOptions {
  status: OrderStatusEnums;
  greaterThen?: boolean;
  orderStatus?: BaseField;
}

export const useIsOrderInStatus = (options: OrderInStatusOptions) => {
  const [value, setValue] = useState<boolean | null>(null);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  useEffect(() => {
    if (order) {
      if (options.greaterThen === undefined) {
        setValue((order.status?.id ?? 0) === options.status);
      }

      if (typeof options.greaterThen === 'boolean') {
        setValue(
          options.greaterThen
            ? (order.status?.id ?? 0) > options.status
            : (order.status?.id ?? 0) < options.status
        );
      }
    }
  }, [order]);

  return value;
};
