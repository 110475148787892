import { IAttachment } from 'shared/interface/IAttachment';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

export enum orderAttachmentsActions {
  GET_ORDER_ATTACHMENTS = 'GET_ORDER_ATTACHMENTS',
  SET_ORDER_ATTACHMENTS = 'SET_ORDER_ATTACHMENTS',
  DELETE_ORDER_ATTACHMENT = 'DELETE_ORDER_ATTACHMENT',
  SAVE_ORDER_ATTACHMENTS = 'SAVE_ORDER_ATTACHMENTS',
  UPDATE_ORDER_ATTACHMENT_NOTE = 'UPDATE_ORDER_ATTACHMENT_NOTE',
  GET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS = 'GET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS',

  SET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS = 'SET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS',

  GET_COLLABORATION_ATTACHMENTS = 'GET_COLLABORATION_ATTACHMENTS',
  SET_COLLABORATION_ATTACHMENTS = 'SET_COLLABORATION_ATTACHMENTS',
}

export const getOrderAttachments = (
  orderId: string,
  loading?: (isLoading: boolean) => void,
  onFailed?: () => void
) => ({
  type: orderAttachmentsActions.GET_ORDER_ATTACHMENTS,
  payload: orderId,
  loading,
  onFailed,
});

export const getAttachmentsForCheckedLineItems = (
  payload: { orderId: string; lineItemIds: string[] },
  loading?: (isLoading: boolean) => void,
  onFailed?: () => void
) => ({
  type: orderAttachmentsActions.GET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS,
  payload,
  loading,
  onFailed,
});

export const setOrderAttachments = (
  orderAttachments: IAttachment[] | null
) => ({
  type: orderAttachmentsActions.SET_ORDER_ATTACHMENTS,
  payload: orderAttachments,
});

export const setAttachmentsForCheckedLineItems = (
  orderAttachments: IAttachment[]
) => ({
  type: orderAttachmentsActions.SET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS,
  payload: orderAttachments,
});

export interface SaveAttachmentsRequestObject {
  attachments: IAttachment[];
  orderId: string;
}

export const saveOrderAttachments = (
  data: SaveAttachmentsRequestObject,
  onSuccess?: () => void,
  onFailed?: (filesToBeDeleted: IAttachment[]) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderAttachmentsActions.SAVE_ORDER_ATTACHMENTS,
  payload: data,
  onSuccess,
  onFailed,
  loading,
});

export interface AttachmentDeleteRequest {
  orderId: string;
  attachmentId: string;
}

export const deleteOrderAttachment = (
  data: AttachmentDeleteRequest,
  onSuccess?: (attachmentId: string) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderAttachmentsActions.DELETE_ORDER_ATTACHMENT,
  payload: data,
  onSuccess,
  onFailed,
});

export interface AttachmentNoteUpdateRequest {
  attachmentId: string;
  orderId: string;
  note: string;
}

export const updateOrderAttachmentNote = (
  data: AttachmentNoteUpdateRequest,
  onSuccess?: () => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderAttachmentsActions.UPDATE_ORDER_ATTACHMENT_NOTE,
  payload: data,
  onSuccess,
  onFailed,
});

export const getCollaborationAttachments = (
  orderId: string,
  loading?: (isLoading: boolean) => void,
  onFailed?: () => void
) => ({
  type: orderAttachmentsActions.GET_COLLABORATION_ATTACHMENTS,
  payload: orderId,
  loading,
  onFailed,
});

export const setCollaborationAttachments = (
  attachments: IAttachment[] | null
) => ({
  type: orderAttachmentsActions.SET_COLLABORATION_ATTACHMENTS,
  payload: attachments,
});
