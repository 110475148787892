import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import UtilService from 'shared/services/util.service';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { getDealership, setDealership } from '../store/dealershipActions';

interface SelectDealershipForm {
  dealership: SelectOptionProps | null;
}

interface SelectDealershipProps {
  setIsLoading?: (loading: boolean) => void;
}

const SelectDealership: FC<SelectDealershipProps> = ({ setIsLoading }) => {
  const dispatch = useAppDispatch();

  const dealership = useSelector(
    (state: RootState) => state.dealershipReducer.dealership
  );

  const methods = useForm<SelectDealershipForm>({
    defaultValues: {
      dealership: null,
    },
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const availableDealerships = useSelector((state: RootState) =>
    !isUserCSR
      ? state.dealershipReducer.availableDealerships.sort((a, b) =>
          UtilService.sortBy(a.label, b.label)
        )
      : state.dealershipReducer.filteredDealerships
          .map((item) => {
            return {
              label: item.name,
              value: item.id.toString(),
            };
          })
          .sort((a, b) => UtilService.sortBy(a.label, b.label))
  );

  const dealershipWatched = methods.watch('dealership');

  useEffect(() => {
    if (dealership && !dealershipWatched) {
      methods.setValue(
        'dealership',
        availableDealerships.find(
          (availableDealership) => availableDealership.value === dealership.id
        ) ?? null,
        { shouldDirty: false }
      );
    }
  }, [dealership]);

  useEffect(() => {
    if (dealershipWatched) {
      if (setIsLoading) setIsLoading(true);
      dispatch(getDealership({ dealershipId: dealershipWatched.value }));
    }
  }, [dealershipWatched]);

  return (
    <>
      <Spacer w="30px" />

      <Wrapper minWidth={300}>
        <Controller
          control={methods.control}
          name="dealership"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Select available dealership..."
              options={availableDealerships}
              isClearable
              onChange={(val: SelectOptionProps) => {
                field.onChange(val);
                if (!val) dispatch(setDealership(null));
              }}
            />
          )}
        />
      </Wrapper>
    </>
  );
};

export default SelectDealership;
