import { FC } from 'react';
import SVG from 'shared/components/SVG';

import { ReactComponent as CheckIcon } from 'assets/icons/check-dark.svg';

import { lynch } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';

const ApprovedACKIcon: FC = (props) => {
  return (
    <Wrapper {...props} inlineFlex middle>
      <Spacer w="20px" />
      <SVG icon={<CheckIcon width={20} height={15} />} color={lynch} />
    </Wrapper>
  );
};

export default ApprovedACKIcon;
