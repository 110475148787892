import { useFormContext } from 'react-hook-form';

import { anzac } from 'shared/config/Colors';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Input } from 'shared/components/Input';
import { SPAN } from 'shared/components/Typography';
import { pwwUsernameValidation } from 'shared/validations/validations';
import { Spacer } from 'shared/components/Layout';
import FormError from 'shared/components/FormError';

const PWWUsername = () => {
  const methodsContext = useFormContext<{ pwwUsername?: string }>();

  return (
    <FormElement>
      <FormLabel>
        PWW Username:{' '}
        <SPAN color={anzac} fontSize={14}>
          This field is used for System synchronization purposes, please do not
          edit this field unless you know what you are doing.
        </SPAN>
      </FormLabel>

      <Spacer h="10px" />

      <Spacer h="16px" />

      <Input
        placeholder="Enter your PWW username here..."
        data-test="input-pww-username"
        autoComplete="pww-autocomplete-turn-off"
        aria-invalid={
          methodsContext.formState.errors.pwwUsername ? 'true' : 'false'
        }
        {...methodsContext.register(
          'pwwUsername',
          pwwUsernameValidation({ required: false })
        )}
      />

      <FormError
        label="PWW Username"
        error={methodsContext.formState.errors.pwwUsername}
        validationSchema={pwwUsernameValidation({ required: false })}
      />
    </FormElement>
  );
};

export default PWWUsername;
