import { IChannel } from '../../../../curtain/interfaces/IChannel';
import { ICurtainTopic } from '../../../../curtain/interfaces/ICurtainTopic';
import { GetChannelsAndTopicsParams } from './TopicSagas';

export enum TopicActions {
  CLEAR_TOPIC_REDUCER = 'CLEAR_TOPIC_REDUCER',
  TOPIC_GET_CHANNELS_AND_TOPICS = 'TOPIC_GET_CHANNELS_AND_TOPICS',
  TOPIC_SET_STYLE_CHANNELS = 'TOPIC_SET_STYLE_CHANNELS',
  TOPIC_SET_LINE_ITEM_CHANNELS = 'TOPIC_SET_LINE_ITEM_CHANNELS',
  TOPIC_SET_STYLE_TOPICS = 'TOPIC_SET_STYLE_TOPICS',
  TOPIC_SET_LINE_ITEM_TOPICS = 'TOPIC_SET_LINE_ITEM_TOPICS',
  TOPIC_SET_OTHER_TOPICS = 'TOPIC_SET_OTHER_TOPICS',
}

export const clearTopicReducer = () => ({
  type: TopicActions.CLEAR_TOPIC_REDUCER,
});

export const getChannelsAndTopics = (payload: GetChannelsAndTopicsParams) => ({
  type: TopicActions.TOPIC_GET_CHANNELS_AND_TOPICS,
  payload,
});

export const setStyleChannels = (payload: IChannel[]) => ({
  type: TopicActions.TOPIC_SET_STYLE_CHANNELS,
  payload,
});

export const setLineItemChannels = (payload: IChannel[]) => ({
  type: TopicActions.TOPIC_SET_LINE_ITEM_CHANNELS,
  payload,
});

export const setStyleTopics = (payload: ICurtainTopic[]) => ({
  type: TopicActions.TOPIC_SET_STYLE_TOPICS,
  payload,
});
export const setLineItemTopics = (payload: ICurtainTopic[]) => ({
  type: TopicActions.TOPIC_SET_LINE_ITEM_TOPICS,
  payload,
});

export const setOtherTopics = (payload: ICurtainTopic[]) => ({
  type: TopicActions.TOPIC_SET_OTHER_TOPICS,
  payload,
});
