import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';
import MetaTitle from 'shared/components/MetaTitle';
import { H2 } from 'shared/components/Typography';
import { EditUserDetailsForm } from './components/EditUserDetailsForm';

export const EditUserDetailsPage = () => {
  return (
    <>
      <MetaTitle title="Update Your Details | Plato Connect" />
      <OverviewHeader>
        <OverviewHeaderLeft>
          <H2>Update your account details</H2>
        </OverviewHeaderLeft>
      </OverviewHeader>
      <EditUserDetailsForm />
    </>
  );
};
