import { FC, Fragment, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonPrimary } from 'shared/components/Button';
import { IFile } from 'shared/interface/IFile';
import { Spacer } from 'shared/components/Layout';
import { uploadFiles } from 'shared/store/sharedActions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import UtilService from 'shared/services/util.service';

import { AttachmentModalBox } from './AttachmentModalBox';
import { generateAttachmentSections } from './enums/AttachmentModalSectionEnums';
import { AttachmentsModalStepsEnums } from './enums/AttachmentsModalStepsEnums';
import { H4 } from '../Typography';
import { IAttachment } from '../../interface/IAttachment';
import DragAndDropFiles from '../DragAndDropFiles';
import Loader from '../Loader';
import ScrollbarsCustom from '../ScrollbarsCustom';

import {
  setSelectedAttachments,
  updateAttachmentsModal,
} from './store/attachmentsModalActions';

const AttachmentsCompleteContainer = styled(Wrapper)`
  min-height: 395px;
`;

const AttachmentsCompleteActions = styled(Wrapper)`
  margin-top: auto;
`;

const AttachmentsInner = styled.div`
  margin: 5px 0 5px 5px;
`;

interface AttachmentsCompleteProps {
  closeModal: () => void;
  onModalClose: (files: IFile[], selectedAttachments: IAttachment[]) => void;
  lineItemConfiguration?: boolean;
}

const AttachmentsComplete: FC<AttachmentsCompleteProps> = ({
  closeModal,
  onModalClose,
  lineItemConfiguration,
}) => {
  const dispatch = useAppDispatch();

  const [filesUploading, setFilesUploading] = useState(false);

  const selectedAttachments = useSelector(
    (state: RootState) => state.attachmentsModalReducer.selectedAttachments
  );

  const onFilesUploadSuccessHandler = (files: IFile[]) => {
    onModalClose(files, [...selectedAttachments]);

    setTimeout(() => {
      closeModal();
    }, 0);
  };

  const onAttachmentModalFinishClickHandler = () => {
    const files = selectedAttachments.map((selectedAtt) => selectedAtt.file!);

    if (files.length) {
      setFilesUploading(true);

      dispatch(
        uploadFiles(
          files,
          onFilesUploadSuccessHandler,
          undefined,
          setFilesUploading
        )
      );
    } else {
      closeModal();
    }
  };

  const onSelectedFilesHandler = (files: File[] | null) => {
    if (files) {
      const hasFiles = !!files.length;

      if (hasFiles) {
        dispatch(
          setSelectedAttachments(UtilService.mapFilesToIAttachment(files))
        );

        dispatch(
          updateAttachmentsModal(
            AttachmentsModalStepsEnums.ADD_ATTACHMENTS,
            AttachmentsModalStepsEnums.ADD_ATT_TITLE,
            AttachmentsModalStepsEnums.ADD_ATT_DESC
          )
        );
      }
    }
  };

  return (
    <AttachmentsCompleteContainer flex column>
      <DragAndDropFiles inline selectedFiles={onSelectedFilesHandler} />

      <Wrapper>
        <ScrollbarsCustom
          autoHeight
          autoHeightMax={327}
          autoHeightMin={327}
          autoHide
          autoHideDuration={300}
          autoHideTimeout={500}
        >
          <AttachmentsInner>
            {!selectedAttachments.length && (
              <EmptyState title="No selected attachments" />
            )}

            {generateAttachmentSections().map((section, sectionIndex) => {
              const attachmentInSection = selectedAttachments.filter(
                (selectedAttachment) =>
                  selectedAttachment.section === +section.value
              );

              if (attachmentInSection.length > 0) {
                return (
                  <Fragment key={section.value}>
                    {sectionIndex === 0 && <Spacer h="20px" />}
                    {!lineItemConfiguration && <H4>{section.label}</H4>}
                    <Spacer h="24px" />

                    {attachmentInSection.map((att) => (
                      <AttachmentModalBox
                        editModeEnabled
                        key={att.id}
                        attachment={att}
                      />
                    ))}
                  </Fragment>
                );
              }

              return null;
            })}
          </AttachmentsInner>
        </ScrollbarsCustom>
      </Wrapper>

      <Spacer h="30px" />

      <AttachmentsCompleteActions flex justifyEnd>
        <ButtonPrimary
          onClick={onAttachmentModalFinishClickHandler}
          disabled={filesUploading}
        >
          Finish
          <Loader insideButton size={16} noSpacing hidden={!filesUploading} />
        </ButtonPrimary>
      </AttachmentsCompleteActions>
    </AttachmentsCompleteContainer>
  );
};

AttachmentsComplete.defaultProps = {
  lineItemConfiguration: false,
};

export default AttachmentsComplete;
