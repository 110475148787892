import { RootState } from 'store';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as PlatoIcon } from 'assets/icons/plato-logo.svg';

import { ButtonPrimary } from 'shared/components/Button';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { useInputSearch } from 'shared/hooks/useInputSearch';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SearchInput from 'shared/components/SearchInput';

import { ModificationBox } from './ModificationBox';
import { IModification } from './interface/IModification';

import {
  searchModifications,
  setSearchedModifications,
  setSelectedModifications,
  updateModificationsModal,
} from './store/lineItemModificationsActions';

import { OrderLineItemModalParams } from '../../interface/OrderLineItemModalParams';
import { IConfigureLineItemForm } from '../../interface/IConfigureLineItem';
import { ModificationModalStepsEnums } from './enums/ModificationModalStepsEnums';

const ModificationsSearchContainer = styled.div`
  // select scrollable container and override scroll behavior
  .ModificationListContainer > div {
    scroll-behavior: auto;
  }
`;

const ModificationsSearchActions = styled(Wrapper)`
  margin-top: 30px;
`;

const ModificationList = styled(Wrapper)`
  padding: 5px;
  min-height: 340px;
  height: auto !important;
`;

const MiddleContainer = styled.div`
  &:only-child {
    margin: auto 0;
  }
`;

const ModificationsSearch = () => {
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'catalogLineItemId',
    'styleId',
  ]);

  const [querySearch, onSearchModificationHandler, searching, setSearching] =
    useInputSearch();

  const { watch } = useFormContext<IConfigureLineItemForm>();

  const lineItemWidthWatched = watch('width');
  const lineItemHeightWatched = watch('height');
  const lineItemDepthWatched = watch('depth');
  const lineItemListPriceWatched = watch('listPrice');

  const [modificationListRef, setModificationListRef] =
    useState<Scrollbars | null>(null);

  const [selectedModifications, setLocallySelectedModifications] = useState<
    IModification[]
  >([]);

  const searchedModifications = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.searchedModifications
  );

  const replaceModification = useSelector(
    (state: RootState) => state.lineItemModificationsReducer.replaceModification
  );

  const lineItemStyle = useSelector(
    (state: RootState) => state.orderLineItemsReducer.style
  );

  const selectedMods = useSelector(
    (state: RootState) =>
      state.lineItemModificationsReducer.selectedModifications
  );

  const isModificationSelected = (modification: IModification) => {
    return selectedModifications
      .filter((selectedModification) => !selectedModification.completed)
      .some(
        (selectedModification) =>
          (modification.catalogModificationId ?? selectedModification.id) ===
          modification.id
      );
  };

  const notCompletedModsExists = () => {
    return isModificationSelected.length > 0;
  };

  const modificationBoxClickHandler = (modification: IModification) => {
    if (replaceModification) {
      return setLocallySelectedModifications([modification]);
    }
    return setLocallySelectedModifications((prevState) => {
      if (prevState.some((prevSome) => prevSome.id === modification.id)) {
        return prevState.filter(
          (prevFilter) => prevFilter.id !== modification.id
        );
      }

      return [...prevState, modification];
    });
  };

  // store selected modifications to redux
  const onAddModificationsHandler = () => {
    // go to modification form if not completed mods are selected
    if (notCompletedModsExists()) {
      dispatch(
        setSelectedModifications(
          selectedModifications.map((mod) => ({
            ...mod,
            catalogModificationId: mod.id,
            id: uuidv4(),
            withTempId: true,
          }))
        )
      );
      dispatch(
        updateModificationsModal(
          ModificationModalStepsEnums.ADD_MODIFICATIONS,
          replaceModification
            ? ModificationModalStepsEnums.REPLACE_MOD_TITLE
            : ModificationModalStepsEnums.ADD_MOD_TITLE,
          ModificationModalStepsEnums.ADD_MOD_DESC
        )
      );
    } else {
      // go to modification completion step
      dispatch(
        updateModificationsModal(
          ModificationModalStepsEnums.COMPLETE_MODIFICATIONS,
          ModificationModalStepsEnums.COMPLETE_MOD_TITLE,
          ModificationModalStepsEnums.COMPLETE_MOD_DESC
        )
      );
    }
  };

  const loadModifications = (
    appendItems: boolean = false,
    pageNum: string = '1'
  ) => {
    if (queryFields) {
      dispatch(
        searchModifications(
          {
            styleId: queryFields.styleId ?? '',
            productLineId: queryFields.productLineId,
            catalogLineItemId: queryFields.catalogLineItemId,
            itemsPerPage: '6',
            appendItems,
            lineItemDepth: lineItemDepthWatched?.toString() ?? '0',
            lineItemHeight: lineItemHeightWatched?.toString() ?? '0',
            lineItemPrice: lineItemListPriceWatched?.toString() ?? '0',
            lineItemWidth: lineItemWidthWatched?.toString() ?? '0',
            page: pageNum,
            doorOverlayId: lineItemStyle?.doorOverlay?.id,
            searchTerm: querySearch,
            ...(lineItemStyle?.cabinetBoxMaterial?.id && {
              cabinetBoxMaterialId: lineItemStyle.cabinetBoxMaterial.id,
            }),
          },
          setSearching
        )
      );
    }
  };

  const { page, handleUpdate, setScrollInProgress, setPage } =
    useInfiniteScroll({
      hasNextPage: searchedModifications?.hasNextPage ?? false,
      loadList: () => loadModifications(true, page.toString()),
      loading: searching,
      setLoading: setSearching,
      scrollToTop: () => modificationListRef?.scrollTop(0),
    });

  useEffect(() => {
    setSearching(true);
    loadModifications();
  }, [queryFields]);

  useEffect(() => {
    setPage(1);
    loadModifications(undefined, '1');
  }, [querySearch]);

  useEffect(() => {
    searchInputRef.current?.focus();

    return () => {
      setPage(1);
      dispatch(setSearchedModifications(null));
    };
  }, []);

  useEffect(() => {
    if (!notCompletedModsExists()) {
      setLocallySelectedModifications(selectedMods);
    }
  }, [selectedMods]);

  const getModalActionText = () => {
    if (replaceModification) {
      return 'Next';
    }
    if (notCompletedModsExists() || !selectedMods.length) {
      return 'Add';
    }
    return 'Preview';
  };

  return (
    <ModificationsSearchContainer>
      <SearchInput
        data-role="search"
        ref={searchInputRef}
        data-loading={searching}
        data-test="input-modificationsSearch"
        placeholder="Search by..."
        onSearchQueryChanged={(e) =>
          onSearchModificationHandler(e.target.value)
        }
      />

      <Spacer h="32px" />

      <ScrollbarsCustom
        autoHeight
        autoHeightMax={420}
        autoHeightMin={420}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        className="ModificationListContainer"
        onScrollStart={() => setScrollInProgress(true)}
        onScrollStop={() => setScrollInProgress(false)}
        onUpdate={handleUpdate}
        ref={(ref) => setModificationListRef(ref)}
      >
        <ModificationList flex column>
          <MiddleContainer>
            {searching && !searchedModifications ? <Loader size={60} /> : null}

            {!searching &&
              searchedModifications &&
              searchedModifications.items.length < 1 && (
                <EmptyState
                  marginTop={0}
                  title="Sorry, we couldn’t find any results"
                  icon={<PlatoIcon />}
                />
              )}
          </MiddleContainer>

          {searchedModifications &&
            searchedModifications.items.length > 0 &&
            searchedModifications.items.map((modification) => (
              <ModificationBox
                key={`modification-box--${modification.id}`}
                modification={modification}
                hoverModeEnabled
                // disabled={isCompleted(modification)}
                selected={isModificationSelected(modification)}
                onClick={() => modificationBoxClickHandler(modification)}
              />
            ))}
        </ModificationList>
      </ScrollbarsCustom>

      <ModificationsSearchActions flex justifyEnd>
        <ButtonPrimary
          disabled={!selectedModifications.length}
          onClick={onAddModificationsHandler}
        >
          {getModalActionText()}
        </ButtonPrimary>
      </ModificationsSearchActions>
    </ModificationsSearchContainer>
  );
};

export default ModificationsSearch;
