import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import {
  saveLineItemAttachments,
  appendSavedLineItemAttachment,
} from 'order/wizard/orderLineItems/store/orderLineItemsActions';

import { AttachmentBox } from 'shared/components/AttachmentBox';
import { Divider, Spacer } from 'shared/components/Layout';
import { fileTypesAllowed, globalMaxFileSize } from 'shared/config/Variables';
import { H2, P } from 'shared/components/Typography';
import { IAttachment } from 'shared/interface/IAttachment';
import { IFile } from 'shared/interface/IFile';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { nevada } from 'shared/config/Colors';
import { setSelectedAttachments } from 'shared/components/AttachmentsModal/store/attachmentsModalActions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import AttachmentsModal from 'shared/components/AttachmentsModal/AttachmentModal';
import DragAndDropFiles from 'shared/components/DragAndDropFiles';
import UtilService from 'shared/services/util.service';

import { getOrderAttachments } from 'order/wizard/orderAttachments/store/orderAttachmentsActions';
import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';

const LineItemAttachmentsContainer = styled.div``;

const LineItemAttachments = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'lineItemId',
  ]);

  const [lineItemEditMode, setLineItemEditMode] = useState(
    queryFields?.lineItemId ?? false
  );

  const [attachmentModalOpened, setAttachmentModalOpened] = useState(false);

  const lineItemAttachments = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemAttachments
  );

  const isCurrentUserViewer = useSelector(
    (state: RootState) => state.orderReducer.isCurrentUserViewer
  );

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const saveLineItemAttachmentsSuccessHandler = (attCount: number = 0) => {
    if (attCount) {
      toast.success(
        `You have successfully added new attachment${
          attCount !== 1 ? 's' : ''
        } to the line item.`
      );
      dispatch(getOrderAttachments(orderId));
    }
  };

  const onModalCloseHandler = (
    files: IFile[],
    selectedAttachments: IAttachment[]
  ) => {
    const attachments = selectedAttachments.map(
      (selectedAtt, index) =>
        ({
          ...selectedAtt,
          fileId: files[index].id,
          size: files[index].size,
          name: files[index].name,
          url: files[index].url,
          extension: files[index].extension,
          createdOnUtc: new Date().toISOString(),
        } as IAttachment)
    ) as IAttachment[];

    if (lineItemEditMode) {
      dispatch(
        saveLineItemAttachments(
          {
            attachments,
            orderId,
            orderLineItemId: queryFields?.lineItemId ?? '',
          },
          saveLineItemAttachmentsSuccessHandler
        )
      );
    } else {
      dispatch(appendSavedLineItemAttachment(attachments));
    }
  };

  const onSelectedFilesHandler = (files: File[] | null) => {
    if (files) {
      const hasFiles = !!files.length;
      setAttachmentModalOpened(hasFiles);

      if (hasFiles) {
        dispatch(
          setSelectedAttachments(UtilService.mapFilesToIAttachment(files))
        );
      }
    }
  };

  useEffect(() => {
    setLineItemEditMode(queryFields?.lineItemId ?? false);
  }, [queryFields?.lineItemId]);

  return (
    <>
      <Spacer h="40px" />
      <Divider />
      <Spacer h="48px" />
      <LineItemAttachmentsContainer>
        <H2>Attachments</H2>

        <Spacer h="48px" />

        <DragAndDropFiles
          inline
          disabled={isOrderSubmitted && isUserCSR ? false : isCurrentUserViewer}
          selectedFiles={onSelectedFilesHandler}
        />

        <ModalWrapper
          modal
          nested
          closeOnDocumentClick={false}
          open={attachmentModalOpened}
          closeOnEscape={false}
        >
          {(close: () => void) => (
            <Modal close={close} noHeader withoutFooter={1}>
              <AttachmentsModal
                closeModal={close}
                lineItemConfiguration
                onModalClose={onModalCloseHandler}
              />
            </Modal>
          )}
        </ModalWrapper>

        {lineItemAttachments.length > 0 && <Spacer h="20px" />}

        {lineItemAttachments.map((lineItemAttachment) => (
          <AttachmentBox
            lineItemConfiguration
            key={lineItemAttachment.id}
            attachment={lineItemAttachment}
          />
        ))}

        <Spacer h="20px" />

        <Wrapper flex between>
          <P color={nevada} fontSize={14}>
            Files Supported: {Object.values(fileTypesAllowed).join(', ')}
          </P>
          <P color={nevada} fontSize={14}>
            Maximum size: {globalMaxFileSize}MB
          </P>
        </Wrapper>
      </LineItemAttachmentsContainer>

      <Spacer h="48px" />
      <Divider />
      <Spacer h="230px" />
    </>
  );
};

export default LineItemAttachments;
